<form  [formGroup]="searchVouchersOrgPaymentPacksListForm">
    <div class="ibox-content m-b-sm border-bottom">
        <div  class="row" style="padding: 10px 15px;">
            <div class="row">
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{panelOpenState ? 'לחץ לסגירת החיפוש...': 'לחץ להרחבת החיפוש...'}}
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="col-sm-12">
                          
                            <mat-form-field>
                                <mat-select apply-enter (selectionChange)="selectionChanged($event)" placeholder="אמצעי תשלום..." [formControl]="searchVouchersOrgPaymentPacksListForm.controls.vouchersOrgPaymentPackOptions" multiple>
                                    <mat-option [value]="1">אשראי</mat-option>
                                    <mat-option [value]="2">מזומן</mat-option>
                                    <mat-option [value]="3">שיקים</mat-option>
                                    <mat-option [value]="4">הוראות קבע</mat-option>
                                    <mat-option [value]="5">העברה בנקאית</mat-option>
                                    <mat-option [value]="6">קופה רושמת</mat-option>
                                    <mat-option [value]="7">Vouchers</mat-option>
                                    <mat-option [value]="8">תוכנה חיצונית</mat-option>
                                    <mat-option [value]="9">שווי כסף</mat-option>
                                </mat-select>
                            </mat-form-field>
                            &nbsp;&nbsp;
                            <mat-form-field>
                                <mat-select apply-enter (selectionChange)="selectionChanged($event)" placeholder="חשבון" [formControl]="searchVouchersOrgPaymentPacksListForm.controls.accountLocations" multiple>
                                    <mat-option *ngFor="let al of servicesData.AccountLocations" [value]="al.value">
                                        {{al.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            &nbsp;&nbsp;
                            <date-input-range [placeHolderStart]="'מתאריך גביה:'" [placeHolderEnd]="'עד לתאריך גביה:'" [dateRangeCtrl]="searchVouchersOrgPaymentPacksListForm.controls.billingDateRange">Loading...
                            </date-input-range>
                            &nbsp;&nbsp;
                            <auto-complete apply-enter [placeholder]="'ארגון שוברים...'" (onSelect)="selectionChanged($event)" #vouchersOrganizationID [controllerName]="'VouchersOrganizations'" [formGroupModel]="searchVouchersOrgPaymentPacksListForm" [idControl]="searchVouchersOrgPaymentPacksListForm.controls.vouchersOrganizationID"
                                [nameControl]="searchVouchersOrgPaymentPacksListForm.controls.organizationName">
                            </auto-complete>
                            &nbsp;&nbsp;

                            <div style="text-align:left" class="example-button-row">

                                <button mat-mini-fab color="primary" title="סנן" (click)="getVouchersOrgPaymentPacks()"> <i
                                        class="fa fa-filter"></i></button> &nbsp;
                                <button mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"> <i class="material-icons">clear</i></button> &nbsp;
                                <button mat-mini-fab color="primary" title="ייצוא לאקסל" (click)="getVouchersOrgPaymentPacksReport()">
                                    <i class="fa fa-file-excel-o"></i></button>

                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div> 
    </div>
    <div class="example-container">
        <div class="bounds">
            <div class="content" style="height: 70%; overflow: auto">
                <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)" class="example-table" matSort matSortDisableClear matSortDirection="asc">
                    <!-- <ng-container matColumnDef="buttons">
                        <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <button mat-icon-button (click)="openDetails(row);" aria-label="הצג עוד...">
                                    <mat-icon style="color: gray;" >remove_red_eye</mat-icon>
                                </button>
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="vouchersOrgPaymentPackID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מזהה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="מזהה" fxHide fxShow.gt-xs>
                            <a [routerLink]="['/vouchersOrgPaymentPacks', {outlets: {popup: [row.vouchersOrgPaymentPackID]}}]">
                                {{ row.vouchersOrgPaymentPackID }}
                            </a>                          
                        </mat-cell>
                    </ng-container>
                 
                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> סכום </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="סכום">
                             {{ row.amount | number}} {{ row.currencyIcon }} 
                            </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="billingDate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך לגביה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="תאריך לגביה"> {{ row.billingDate | date:'dd/MM/yyyy' }} <br>{{ row.jewishBillingDate }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="doneOn">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> תאריך ביצוע
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="תאריך ביצוע" fxHide fxShow.gt-xs> {{ row.doneOn | date:'dd/MM/yyyy' }} <br>{{ row.jewishDoneOn}} </mat-cell>
                    </ng-container>



                    <ng-container matColumnDef="payMethodID">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> באמצעות
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="באמצעות">
                            <div *ngIf="row.payMethodID">
                                <a *ngIf="row.payMethodID != 88 && row.payMethodID != 89 && row.payMethodID != 420 && row.payMethodID != 1310 && row.payMethodID != 3580 && row.payMethodID != 6232 && row.payMethodID != 6990" [routerLink]="[ '', {outlets: {popup: [ 'payMethods', row.payMethodID]}}]">
                                    {{ row.payMethodTitle }}</a>
                                <span *ngIf="row.payMethodID == 88">מזומן</span>
                                <span *ngIf="row.payMethodID == 89">שיקים</span>
                                <span *ngIf="row.payMethodID == 420">העברה בנקאית</span>
                                <span *ngIf="row.payMethodID == 1310">קופה רושמת</span>
                                <span *ngIf="row.payMethodID == 3580">Vouchers</span>
                                <span *ngIf="row.payMethodID == 6232">תוכנה חיצונית</span>
                                <span *ngIf="row.payMethodID == 6990">שווי כסף</span>
                            </div>
                        </mat-cell>
                    </ng-container>

                

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.vouchersOrgPaymentPackID)">
                    </mat-row>
                </mat-table>
            </div>
        </div>
        <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false" [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
    </div>
</form>