
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { AlertService } from '../components/alert/alert.svc';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { filter } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { TransferTasksData } from './transfer-tasks.data';
import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { TransferTaskConseContactDlg } from './transfer-task/transfer-task-conse-contact-dlg.cmp';
import { IndexService } from '../services/index-service';
import { SubscriptionLike } from 'rxjs';



@Component({
  selector: 'transfer-tasks-list',
  templateUrl: 'transfer-tasks-list.tmpl.html',
})



export class TransferTasksList implements OnInit {
  constructor(
    private transferTasksData: TransferTasksData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/transferTasks') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  displayedColumns: string[] = ['buttons', 'name', 'valueDate', 'bankNumber', 'bankName', 'branchNumber', 'amount', 'type', 'asmachta', 'comment', 'contactName', 'paymentID'];
  indexService: IndexService;
  searchTransferTasksListForm: FormGroup;
  defaultvalues;
  @Input('contactID') public contactID?: number;
  stopLoadingPoints: boolean = false;



  exportExcel(){
    this.transferTasksData.exportExcel(this.searchTransferTasksListForm.value)
    .subscribe(blob => {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `דוח העברות.xlsx`;
      link.click();
    });
  }


  private initForm() {
    this.searchTransferTasksListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      hasDone: [false],
      valueDateRange:[],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });


    let frm = this.searchTransferTasksListForm;
    this.defaultvalues = Object.keys(this.searchTransferTasksListForm.value)
      .filter(key => frm.controls[key].validator == Validators.required)
      .reduce((obj, key) => {
        obj[key] = frm.controls[key].value;
        return obj;
      }, {});
  }

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();

    this.searchTransferTasksListForm.updateValueAndValidity();
    let params = [
      { key: "contactID", value: this.contactID }
    ];

    this.indexService = new IndexService(this.searchTransferTasksListForm, params, this.transferTasksData, this.router, this.defaultvalues);

    this.indexService.getList();

  }





  conseContact(row) {
    setTimeout(() => {
      const dialogRef = this._matDialog.open(TransferTaskConseContactDlg, {
        data: { transferTaskID: row.transferTaskID }
      });


      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.openPayment(result);
        }
      });
    }, 200);
  }

  openPayment(result: any) {
    var rec = result.value;
    this.router.navigate(['', { outlets: { popup: ['payments', 'addTransferPayment',  rec.contactID, rec.transferTaskID  ] } }]);
  }

}


