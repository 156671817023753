import { ServicesData } from 'src/app/services/services.data'; 
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { Location } from '@angular/common'; 
import { CampaignAttachedsData } from '../campaign-attacheds.data';
import { CampaignAttached } from './campaign-attached';


@Component({
    
    selector: 'campaign-attached-edit',
    templateUrl: './campaign-attached-edit.tmpl.html'
})

export class CampaignAttachedEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private campaignAttachedsData: CampaignAttachedsData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData,
  
    ) {
    }

    campaignAttached: CampaignAttached;
    id: number;
    campaignID: number;

    
    contactId: number;
    campaignAttachedForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.campaignAttachedsData.getEdit(this.id || 0)
                .subscribe((c:any)  => {
                    this.campaignAttached = c;
                    this.campaignAttachedForm.patchValue(c);
                });   
        } else {
            this.campaignAttachedsData.getAdd(this.campaignID)
                .subscribe((c:any)  => {
                    this.campaignAttached = c;
                    this.campaignAttachedForm.patchValue(c);
                });
        }
    }

    private initForm() {
        this.campaignAttachedForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            campaignAttachedID: [this.id || 0, Validators.required],
            attachedName: [, Validators.required],
            count:[, Validators.required],
            countPaid:[], 
            contactID:[],
            campaignID:[, Validators.required],
            comment: [],
        });
    }



    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['campaignAttachedID'];
            this.campaignID = +params['campaignID'];
        });
        this.initForm();
        this.load();
    }
}



