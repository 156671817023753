import { MatDialog } from '@angular/material/dialog';
import { AddressesData } from '../addresses.data';
import { Component, Input, EventEmitter, Output } from '@angular/core';

import { Address } from './address';

@Component({
    selector: 'address-details',
    templateUrl: './address-details.tmpl.html'
})

export class AddressDetails {

    constructor(
        public dialog: MatDialog
    ) {

    }
    @Output() addressChange = new EventEmitter<any>();
    @Input('address') public address: Address;

    addressChangeEvent(address) {
        this.addressChange.emit(address);
    }
}



