import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { DefaultTermRowComponent } from './country/defaultTerm-row.cmp';
import { MaterialModule } from './../material.module';
import { CountryEditDlg } from './country/country-edit-dlg.cmp';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { CountriesRoutes } from './countries.routing';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';

import { CountriesData } from './countries.data';
import { CountriesList as CountryList } from './countries-list.cmp';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
	imports: [
		CommonModule,		
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		RouterModule.forChild(CountriesRoutes),
		FooterEditButtonsMdl,
		ColorPickerModule,
		MaterialModule,
MainFormModalModule
	],
	declarations: [
		CountryList,
		CountryEditDlg,
		DefaultTermRowComponent
	],
	exports: [
		CountryList,
		CountryEditDlg,
	],
	providers: [
		CountriesData
	],
	entryComponents:[
		DefaultTermRowComponent
	]
})
export class CountriesMdl { }

   