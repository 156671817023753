import { TishPlaceMap } from './tishPlace/tishPlace-map.cmp';

import { TishPlaceMain } from './tishPlace/tishPlace-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { TishPlacesList } from './tishPlaces-list.cmp'; 


export const TishPlacesRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'tishPlaces', component: TishPlacesList, data: {  roles: "tishPlaces",  title: 'מקומות' } },
    { canActivate: [AuthGuard], path: 'tishPlaces/add', component: TishPlaceMain, data: { mode: 'edit', roles: "tishPlaces", title: 'הוסף מקום' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'tishPlaces/:tishPlaceID', component: TishPlaceMain, data: {  roles: "tishPlaces",  title: 'פרטי מקום' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'tishPlaces/:tishPlaceID/edit', component: TishPlaceMain, data: { mode: 'edit', roles: "tishPlaces",  title: 'עריכת פרטי מקום' }, outlet: 'popup' },

    { canActivate: [AuthGuard], path: 'tishPlaces/:tishPlacID/map', component: TishPlaceMap, data: {  roles: "tishPlaces",  title: 'מפת מקום' } }
]; 
