import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef } from '@angular/core';
import { TransferTasksData } from '../transfer-tasks.data';
import { ServicesData } from 'src/app/services/services.data';
import { AlertService } from 'src/app/services/alert.service';



@Component({
  templateUrl: './transfer-task-conse-contact-dlg.tmpl.html'
})
export class TransferTaskConseContactDlg implements OnInit {

  public formData: FormGroup;

  shtibelMode: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private transferTasksData: TransferTasksData,
    public servicesData: ServicesData,
    private cdRef: ChangeDetectorRef,
    private alertSvc: AlertService,
  ) { }

  selectionChanged($event: any) {
    console.log($event);
  }

  ngOnInit() {
    this.initForm();
    if (this.data) {
      this.formData.controls['transferTaskID'].setValue(this.data.transferTaskID);
    }
  }


  private initForm(): void {
    this.formData = this.formBuilder.group({
      contactID: [, Validators.required],
      contactName: [, Validators.required],
      transferTaskID: [, Validators.required]
    });
  }

  public isValid(): boolean {
    return (this.formData && this.formData.valid);
  }




}
