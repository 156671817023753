
import { LocationStrategy, PlatformLocation, Location, CommonModule } from '@angular/common';
import { singleTab } from './single-tab.cmp';
import { NgModule, AfterContentInit, ContentChildren, QueryList, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'tabs',
    template: `
        <div class="tabs-container">
        	<ul class="nav nav-tabs">
        		<li style="float: right" *ngFor="let singleTab of tabsList" (click)="selectTab(singleTab)" [class.active]="singleTab.active">
        			<a>{{singleTab.title}}</a>
        		</li>
        	</ul>
        	<div class="tab-content">
        		<ng-content></ng-content>
        	</div>
        </div>`
})

export class Tabs implements AfterContentInit {
    constructor(
        private location: Location,
    ) { }
    @ContentChildren(singleTab) tabsList: QueryList<singleTab>;

    @Output() onSelectTab = new EventEmitter<number>();

perfix = "tabs_";
    // contentChildren are set
    ngAfterContentInit() {
        let pathStr = `${this.perfix}${this.location.path()}`;
        // get all active tabs
        let activeTabs = this.tabsList.filter((tab) => tab.active);
        //get active from refresh, else first tab selected
        let selectabIndex = sessionStorage.getItem(`${pathStr}`) || 0;
        this.selectTab(this.tabsList.toArray()[selectabIndex]);
    }

    selectTab(singleTab: singleTab) {
        if (singleTab) {
            let pathStr = `${this.perfix}${this.location.path()}`;
            // deactivate all tabs
            this.tabsList.toArray().forEach(singleTab => singleTab.active = false);
            //store selected tab index in sessionStorage
            let selectabIndex = this.tabsList.toArray().indexOf(singleTab);
            sessionStorage.setItem(`${pathStr}`, `${selectabIndex}`);
            // activate the tab the user has clicked on.
            singleTab.active = true;
            this.onSelectTab.emit(singleTab.tabIndex);
        }
    }

}