<div fxLayout="row">

  <div fxLayout="column" fxFlex="70" [formGroup]="searchTishTablesListForm">

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="column" style="padding-top: 12px; overflow: auto">
        <div fxLayout="row" class="scrolling-wrapper">

          <auto-complete apply-enter [placeholder]="'חיפוש שולחן...'" (onSelect)="selectionChanged($event)"
            [smallTemplate]="true" fxLayout="row" [controllerName]="'TishTables/' + tishEventID"
            [formGroupModel]="searchTishTablesListForm" [idControl]="searchTishTablesListForm.controls.tishTableID"
            [nameControl]="searchTishTablesListForm.controls.tishTableName"></auto-complete>
          &nbsp;&nbsp;
        </div>
      </div>
      <div class="example-button-row" style="white-space: nowrap;"
        style="position: sticky; float: left; left: 5px;  z-index: 9999;">
        <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן" (click)="selectionChanged($event)"><i
            class="fa fa-filter"></i></button>
        <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
            class="material-icons">clear</i></button>
      </div>

    </div>
    <div class="example-container">
      <div class="bounds">
        <div class="content" style="height: 70%; overflow: auto" loading-status
          [stopLoadingWhen]="stopLoadingPoints || sourceList">
          <cdk-virtual-scroll-viewport itemSize="10" style="height:calc(100vh - 330px); width: 100%;" >
            <mat-table [dataSource]="sourceList" style="overflow-x: auto; width: 100%;" (matSortChange)="sortData($event)"
              class="example-table" matSort matSortDisableClear matSortDirection="asc">

              <ng-container matColumnDef="buttons">
                <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <a mat-menu-item [routerLink]="['', {outlets: {popup: ['tishTables', row.tishTableID]}}]">
                      <mat-icon>pageview</mat-icon>
                      <span>פרטים מלאים</span>
                    </a>

                    <a mat-menu-item [routerLink]="['', {outlets: {popup: ['tishTables', row.tishTableID, 'edit']}}]">
                      <mat-icon>edit</mat-icon>
                      <span>עריכה</span>
                    </a>
                    <a mat-menu-item>
                      <mat-icon>information</mat-icon>
                      <model-info style="padding: 4px" [dataProvider]="tishTablesData" [id]="row.tishTableID"
                        [title]="'מידע רשומה'">
                      </model-info>
                    </a>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="tishTableID">
                <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="מזהה">
                  <a [routerLink]="['', {outlets: {popup: ['tishTables', row.tishTableID]}}]">
                    {{ row.tishTableID }}
                  </a>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="tishTableName">
                <mat-header-cell *matHeaderCellDef mat-sort-header> שם שולחן
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="שם שולחן"> {{row.tishTableName}}
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="countPlaces">
                <mat-header-cell *matHeaderCellDef mat-sort-header> מקומות </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="מקומות"  [ngStyle]="{'color':(row.countPlaces >= row.countInviteds ? 'green' : 'red' )}">
                  {{row.countPlaces}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="countInviteds">
                <mat-header-cell *matHeaderCellDef mat-sort-header> משובצים </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="משובצים">
                  {{row.countInviteds}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="tishEventTitle">
                <mat-header-cell *matHeaderCellDef mat-sort-header> אירוע
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="אירוע"> {{row.tishEventTitle}}
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="comment">
                <mat-header-cell *matHeaderCellDef> הערה </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="הערה"><span>
                    {{row.comment}}</span> </mat-cell>
              </ng-container>



              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.tishTableID)"
                (click)="setSelectedRow(row)">
              </mat-row>
            </mat-table>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
      <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100, 200, 300]"></mat-paginator>
    </div>
  </div>
  <div fxLayout="column" fxFlex *ngIf="selectedRow">

    <tishTable-static-map *ngIf="selectedRow && viwSelectedRow" style="position: initial; margin: 0 auto;zoom: 200%;" [tishTableID]="selectedRow.tishTableID"
      [tishEventID]="selectedRow.tishEventID">
      Loading...
    </tishTable-static-map>
  </div>
</div>
