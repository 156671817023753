import { DirectivesMdl } from '../../components/directives/directives-module';

import { MaterialModule } from '../../material.module';
import { ContactsSelectPick } from './contacts-select-pick.cmp';
import { ContactAddAction } from './contact-add-action.cmp';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoCompleteModule } from 'src/app/components/‏‏auto-complete/auto-complete.mdl';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        MaterialModule,

        DirectivesMdl,
        AutoCompleteModule
       ],
    declarations: [
        ContactAddAction,
        ContactsSelectPick
    ],
    exports: [
        ContactAddAction,
        ContactsSelectPick
    ],

    entryComponents: [
        ContactAddAction,
        ContactsSelectPick
    ]
})
export class ContactsSelectPickMdl { }


