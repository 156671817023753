import { RouterModule } from '@angular/router';


import { MaterialModule } from './../material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { CommitmentTitleComponent } from './commitment-title.component';

@NgModule({
    imports: [
        CommonModule, 
        NgbModule,   
        RouterModule, 
        MaterialModule,        
         
    ],
    declarations: [
        CommitmentTitleComponent,
    ],
    exports: [
        CommitmentTitleComponent,
    ]
})
export class CommitmentTitleModule {

}
