<div *ngIf="formData">
    <form class="example-form" [formGroup]="formData" novalidate>
        <div matDialogTitle class="modal-header">
            <h2>
                <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור"
                    class="btn  btn-circle.btn-lg">
                    <i class="fa fa-times"></i>
                </button>
                <small> {{ formData.controls.contactName.value }}</small>
                <br>
                <br>
                סליקת אשראי ע"ס {{ formData.controls.amount.value | number }}{{ formData.controls.currencyIcon.value }}
            </h2>
        </div>
        <mat-dialog-content style="padding:0px 24px 24px 24px">
            <div fxFlex="100" fxLayout="column">

                <div class="form-row" fxLayout="row" style="white-space: nowrap;">
                    <div class="input-group mb-3" style="white-space: nowrap;">
                        <label>אמצעי תשלום:&nbsp;&nbsp;
                            <i *ngIf="!payMethodEdit" style="color: #c8c8c8" (click)="payMethodEdit = !payMethodEdit"
                                class="fa fa-pencil"></i>
                            <i *ngIf="payMethodEdit" style="color: #c8c8c8" (click)="payMethodEdit = !payMethodEdit"
                                class="fa fa-check"></i>
                        </label>

                        <div class="input-group-prepend">
                            <span *ngIf="!payMethodEdit">{{ formData.controls.payMethodTitle.value }}</span>
                            <payMethods-select-pick *ngIf="payMethodEdit" [withLabel]="false"
                                (payMethodSelect)="payMethodSelection($event)"
                                (payMethodChange)="payMethodChangeEvent($event)"
                                (payMethodAdd)="payMethodChangeEvent($event)" [contactID]="data.contactID"
                                [placeHolder]="'אמצעי תשלום'" [loadOnlyCc]="true"
                                [payMethodIdControl]="formData.controls.payMethodID"
                                [fromRecordType]="'Payment'"  [fromRecordID]="formData.controls.paymentID.value">
                            </payMethods-select-pick>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="form-row" fxLayout="row">
                    <div class="input-group mb-3" style="white-space: nowrap;">
                        <label>מסוף:&nbsp;&nbsp;
                            <i *ngIf="!termEdit" style="color: #c8c8c8" (click)="termEdit = !termEdit"
                                class="fa fa-pencil"></i>
                            <i *ngIf="termEdit" style="color: #c8c8c8" (click)="termEdit = !termEdit"
                                class="fa fa-check"></i>
                        </label>
                        <div class="input-group-prepend">
                            <span style="vertical-align: middle"
                                *ngIf="!termEdit">{{formData.controls.termName.value}}</span>
                            <select apply-enter *ngIf="termEdit" (change)="selectTerm($event)" class="form-control"
                                placeholder="מסוף:" formControlName="termID">
                                <option *ngFor="let api of terms" [value]="api.id">
                                    {{api.title}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="form-row" fxLayout="row">
                    <div class="input-group mb-3">
                        <label>מס' אישור:</label>
                        <input type="text" class="form-control" formControlName="authorizationNumber" />
                    </div>
                </div>
                <hr>


            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="modal-footer" align="center">
            <div style="text-align: center;">
                <a *ngIf="!hasPublishNow && !afterPublished" class="btn btn-success btn-rounded"
                    (click)="publish()">סליקה</a>

                <a *ngIf="(!hasPublishNow && afterPublished)" class="btn btn-success btn-rounded"
                    (click)="publish()">נסה שנית</a>
                <br>
                <div *ngIf="hasPublishNow">
                    <div loading-status [stopLoadingWhen]="!hasPublishNow">
                        מבצע סליקה מול חברת האשראי...
                    </div>
                </div>
                <br>
                <span style="color:blue" #successResult></span>
                <span style="color:red" #errorResult></span>
                <br>
                <!-- <i *ngIf="showCloseButton" (click)="closeModal()" style="color: #c8c8c8; float: left; font-size: 14px" class="fa fa-sign-undo"></i>

                <br> -->


            </div>
        </mat-dialog-actions>
    </form>
</div>
