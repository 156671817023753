import { ToraxHttp } from './../../utils/custom-http.svc';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardSecretaryDataService {
  private url = 'api/dashboardSecretary';
  constructor(
    private toraxHttp: ToraxHttp

  ) { }

  exportExcel() {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, {});
  }



  //This method gets the details of one client.
  public getDocument() {
    return this.toraxHttp.getWithResponce(`${this.url}/printReceiptsForTest`);
  }


  getContactsByGroup(groupID: number) {
    return this.toraxHttp.get(`${this.url}/getContactsByGroup/${groupID}`);
  }


  createAndPrintReceipt(paymentID: number, doDownload: boolean = false) {
    if (doDownload)
      return this.toraxHttp.getWithResponceToDownload(`${this.url}/createAndPrintReceipt?paymentID=${paymentID}&doDownload=${doDownload}`);
    else
      return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintReceipt?paymentID=${paymentID}&doDownload=${doDownload}`);
  }

  printWaitingReceipts(accountLocation, commitmentSubject, registrationEventType, withPaymentsPlan) {
    return this.toraxHttp.getWithResponce(`${this.url}/printWaitingReceipts/${accountLocation}/${commitmentSubject}/${registrationEventType}/${withPaymentsPlan}`);
  }
  printWaitingUSAReceipts() {
    return this.toraxHttp.getWithResponce(`${this.url}/printWaitingUSAReceipts`);
  }
  printWaitingUKReceipts() {
    return this.toraxHttp.getWithResponce(`${this.url}/printWaitingUKReceipts`);
  }
  printWaitingIchudIlReceipts() {
    return this.toraxHttp.getWithResponce(`${this.url}/printWaitingIchudIlReceipts`);
  }
  createWaitingIL_Year_Receipts() {
    return this.toraxHttp.getWithResponce(`${this.url}/createWaitingIL_Year_Receipts`);
  }
  printIL_Year_Receipts(year) {
    return this.toraxHttp.getWithResponce(`${this.url}/printIL_Year_Receipts/${year}`);
  }


  //   createAndPrintWaitingIL_Year_Receipts() {
  //     return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintWaitingIL_Year_Receipts`);
  // }
  createAndPrintWaitingIchudIL_Year_Receipts() {
    return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintWaitingIchudIL_Year_Receipts`);
  }
  createAndNonPrintWaitingReceipts(accountLocation, commitmentSubject, registrationEventType, withPaymentsPlan) {
    return this.toraxHttp.getWithResponce(`${this.url}/createAndNonPrintWaitingReceipts/${accountLocation}/${commitmentSubject}/${registrationEventType}/${withPaymentsPlan}`);
  }
  createAndPrintWaitingUSAReceipts() {
    return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintWaitingUSAReceipts`);
  }
  createAndPrintWaitingUKReceipts() {
    return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintWaitingUKReceipts`);
  }
  createAndPrintWaitingIchudIlReceipts() {
    return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintWaitingIchudIlReceipts`);
  }
  cancelILReceiptsPrint(receiptsIdsList: number[]) {
    return this.toraxHttp.postWithResponce(`${this.url}/cancelILReceiptsPrint`, receiptsIdsList);
  }
  cancelUSAReceiptsPrint(receiptsIdsList: number[]) {
    return this.toraxHttp.postWithResponce(`${this.url}/cancelUSAReceiptsPrint`, receiptsIdsList);
  }
  cancelUKReceiptsPrint(receiptsIdsList: number[]) {
    return this.toraxHttp.postWithResponce(`${this.url}/cancelUKReceiptsPrint`, receiptsIdsList);
  }
  cancelIchudIlReceiptsPrint(receiptsIdsList: number[]) {
    return this.toraxHttp.postWithResponce(`${this.url}/cancelIchudIlReceiptsPrint`, receiptsIdsList);
  }



  // getPaymentCommitmentsInfo(paymentCommitmentsIdsList: number[]) {
  //     return this.toraxHttp.post(`${this.url}/getPaymentCommitmentsInfo`, paymentCommitmentsIdsList);
  // }


  get() {
    return this.toraxHttp.get(`${this.url}/secretaryModel`);
  }
}
