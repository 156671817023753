<form *ngIf="payment" class="example-form" [formGroup]="paymentForm" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      <small>{{ (id > 0 ? 'עריכת פרטי' : 'הוספת') }} תשלום</small>
      <br>
      {{ data.organizationName }} [{{ payment.contactName }}]
    </h2>
  </div>
  <mat-dialog-content>
    <fieldset class="form-group">
      <legend>פרטי תשלום</legend>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <amount-and-currency-input fxFlex [firstFocus]="true" (amountChange)="amountChangeEvent($event)"
          [idControl]="paymentForm.controls.paymentID" [shekelValueControl]="paymentForm.controls.calculatedAmountInILS"
          [amountControl]="paymentForm.controls.amount" [currencyControl]="paymentForm.controls.currency"
          [shekelRateControl]="paymentForm.controls.ilsRate">
        </amount-and-currency-input>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <payMethods-select-pick [withLabel]="true" fxFlex (payMethodSelect)="payMethodSelection($event)"
          (payMethodChange)="payMethodChangeEvent($event)" (payMethodAdd)="payMethodChangeEvent($event)"
          [contactID]="payment.contactID" [placeHolder]="'אמצעי תשלום'"
          [payMethodIdControl]="paymentForm.controls.payMethodID" [fromRecordType]="'Payment'"
          [fromRecordID]="paymentForm.controls.paymentID.value">
        </payMethods-select-pick>
      </div>
      <div *ngIf="([88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(+payMethodID) == -1) && terms" class="form-row"
        fxLayout="row" fxLayout.xs="column">
        <div matTooltip="יש לבחור מסוף לסליקת אשראי, לאחר בחירת נושא ההתחייבות" class="input-group mb-3">
          <label>מסוף:</label>
          <mat-select class="form-control" placeholder="מסוף:" formControlName="termID">
            <mat-option [value]="">...</mat-option>
            <mat-option *ngFor="let api of terms" [value]="api.id">
              {{api.title}}
            </mat-option>
          </mat-select>
        </div>
        <h4 *ngIf="paymentForm.controls.termID.invalid" style="color:red; text-align: end;" fxFlex>
          יש לעדכן מסוף לסליקת אשראי, לאחר בחירת נושא ההתחייבות
        </h4>
      </div>

      <div class="form-row" fxLayout="row" fxLayout.xs="column"
        *ngIf="payMethodID && ([88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(+payMethodID) > -1)">
        <div class="input-group mb-3">
          <label>עבור חשבון:</label>
          <mat-select matTooltip="בחירת חשבון עמותה הכרחית לצורך הנפקת קבלה מתאימה" class="form-control"
            formControlName="accountLocation">
            <mat-option [value]="">...</mat-option>
            <mat-option *ngFor="let cs of servicesData.AccountLocations" [value]="cs.value">
              {{cs.name}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column" style="height: auto">
        <div style="width: 250px !important;" class="input-group mb-3" *ngIf="payMethodID && payMethodID == 6232">
          <label>פרטי אמצעי תשלום בתוכנה חיצונית:</label>
          <input autocomplete="off" class="form-control" placeholder="פרטי אמצעי תשלום בתוכנה חיצונית..." apply-enter
            formControlName="externalSoftwarePayMethod">
        </div>
        <div style="width: 250px !important;" class="input-group mb-3" *ngIf="payMethodID && (payMethodID == 6990)">
          <label>תיאור שווי כסף:</label>
          <input autocomplete="off" class="form-control" apply-enter placeholder="תיאור שווי כסף"
            formControlName="moneyValueDescription">
        </div>

      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="paymentForm.controls.accountLocation.value == 4">
        <div class="input-group mb-3">
          <label>מס' חשבונית בחשבשבת:</label>
          <input autocomplete="off" class="form-control" apply-enter formControlName="hashReceiptNumber"
            placeholder="מס' חשבונית בחשבשבת...">
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="payMethodID && (payMethodID == 6990)">
        <div class="input-group mb-3">
          <label>מס' חשבונית:</label>
          <input autocomplete="off" class="form-control" apply-enter placeholder="מס' חשבונית"
            formControlName="invoiceNumberForMoneyValue">
        </div>&nbsp;
        <date-input [placeholder]="'תאריך המופיע על החשבונית'"
          [dateControl]="paymentForm.controls.invoiceDateForMoneyValue">
          Loading...
        </date-input>

      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <date-input [placeholder]="(payMethodID == 89 ? 'תאריך':  'תאריך לגביה:')"
          [dateControl]="paymentForm.controls.billingDate">
          Loading...</date-input>&nbsp;
        <date-input *ngIf="payMethodID && (payMethodID == 89)" [placeholder]="'תאריך לפירעון המופיע עג השיק:'"
          [dateControl]="paymentForm.controls.checkDate">
          Loading...</date-input>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column"
        *ngIf="payMethodID && (payMethodID == 89 || payMethodID == 420)">
        <auto-complete [placeholder]="'בנק'" [controllerName]="'Banks'" (onSelect)="itemSelect($event)"
          [formGroupModel]="paymentForm" [idControl]="paymentForm.controls.bankNumber"
          [nameControl]="paymentForm.controls.bankName">
        </auto-complete>&nbsp;
        <auto-complete [placeholder]="'סניף'" #bankBranchesCmp [controllerName]="'BankBranches'"
          [formGroupModel]="paymentForm" [idControl]="paymentForm.controls.bankBranchNumber"
          [nameControl]="paymentForm.controls.bankBranchName">
        </auto-complete>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column"
        *ngIf="payMethodID && [89, 420, 3580, 6232, 6990].indexOf(payMethodID) > -1">
        <div class="input-group mb-3">
          <label>מס' חשבון:</label>
          <input class="form-control" formControlName="bankAcountNumber" apply-enter />
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="payMethodID && payMethodID == 420">
        <div class="input-group mb-3">
          <label>מס' אסמכתא:<small style="color: blue;">&nbsp;בהעברה בנקאית יש להכניס מס' אסמכתא
              המופיע בחשבון העמותה</small>:</label>
          <input class="form-control" formControlName="transferReference" apply-enter />
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="payMethodID && payMethodID == 420">
        <div class="input-group mb-3">
          <label>מס' חשבון בעמותה:</label>
          <input class="form-control" formControlName="associationAccountNumber" apply-enter />
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="payMethodID && (payMethodID == 89)">
        <div class="input-group mb-3">
          <label>מס' שיק:</label>
          <input class="form-control" formControlName="chicNumber" apply-enter />
        </div>&nbsp;
        <div class="input-group mb-3">
          <label>שם שיק:</label>
          <input class="form-control" formControlName="nameOfChic" apply-enter />
        </div>&nbsp;
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">

        <date-input [placeholder]="(doneOnLabel != '' ? doneOnLabel + ':' : 'תאריך תשלום - ביצוע:')"
          *ngIf="payMethodID && [89, 420, 3580, 6232, 6990].indexOf(payMethodID) > -1"
          [dateControl]="paymentForm.controls.doneOn">Loading...</date-input>
        &nbsp;
        <div *ngIf="payMethodID && [89,3580].indexOf(payMethodID) > -1" class="input-group mb-3">
          <label>סטטוס:</label>
          <mat-select apply-enter class="form-control" placeholder="סטטוס" formControlName="statusPayment">
            <mat-option *ngFor="let cs of servicesData.StatusPayments" [value]="cs.value">
              {{cs.name}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="payMethodID && (payMethodID == 3580)">
        <div class="input-group mb-3">
          <label>מס' voucher:</label>
          <input apply-enter class="form-control" formControlName="voucherNumber" />
        </div>&nbsp;
        <auto-complete [placeholder]="'שם ארגון - voucher:'" [controllerName]="'VouchersOrganizations'"
          [formGroupModel]="paymentForm" [idControl]="paymentForm.controls.vouchersOrganizationID"
          [nameControl]="paymentForm.controls.vouchersOrganizationName">
        </auto-complete>
      </div>

    </fieldset>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <button [disabled]="paymentForm.invalid" class="btn btn-primary navbar-btn" apply-enter
      [mat-dialog-close]="paymentForm"><i class="fa fa-save"></i> שמור </button>
    <button class="btn btn-warning navbar-btn" *ngIf="payment.paymentID > 0 && payment.isActive"
      [mat-dialog-close]="paymentForm" (click)="removePayment()"><i class="fa fa-warning"></i> ביטול
    </button>
    <!-- <button mat-button mat-dialog-close [mat-dialog-close]="paymentForm" class="btn btn-danger navbar-btn"><i
                class="fa fa-trash-o"></i> מחק לנצח
        </button> -->
  </mat-dialog-actions>

</form>
