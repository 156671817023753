import { TaskDetails } from './task/task-details.cmp';
import { TasksList } from './tasks-list.cmp';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';

import { TasksData } from './tasks.data';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule
    ],
    declarations: [
        TaskDetails,
        TasksList
    ],
    exports: [
        TaskDetails,
        TasksList],
    providers: [
        TasksData
    ]
})
export class TasksMdl { }

