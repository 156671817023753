import { RouterModule } from '@angular/router';
import { GeneralTableComponent } from './general-table.component';
import { MaterialModule } from './../../material.module';

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MatNativeDateModule } from '@angular/material/core';

// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { CommonModule } from '@angular/common';


@NgModule({
    imports: [
        CommonModule,
        // //BrowserModule,
        // BrowserAnimationsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MaterialModule,
    ],
    declarations: [GeneralTableComponent],
    exports: [GeneralTableComponent],
    bootstrap: [GeneralTableComponent]
})
export class GeneralTableModule { }
 