import { CommitmentsSelectPick } from '../../commitments/commitments-select-pick/commitments-select-pick.cmp';
import { Commitment } from './../../commitments/commitment/commitment';
import { FormGroup, FormArray, RequiredValidator, ValidatorFn, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ServicesData } from 'src/app/services/services.data';


@Component({
    selector: '[pay-plan-commitment-edit]',
    templateUrl: 'pay-plan-commitment-edit.component.html',
    styleUrls: ['pay-plan-commitment-edit.component.scss']
})
export class PayPlanCommitmentEditComponent implements OnInit {

    constructor(
        public servicesData: ServicesData,
        private cdRef: ChangeDetectorRef) {
    }

    subject: number;
    terms;
    commitmentReasons; 
    commitment: Commitment;

    @Input('ix') public ix: number;
    @Input('contactID') public contactID: number;
    @Input('paymentsPlanFormGroup') public paymentsPlanFormGroup: FormGroup;
    @Input('payPlanCommitmentFormGroup') public payPlanCommitmentFormGroup: FormGroup;
    @Input('openNewPayPlanCommitment') public openNewPayPlanCommitment: boolean = false;
    
    @Output() reloadSubjectTerms = new EventEmitter<any>();


    showComponent = true;
    commitmentChangeEvent(commitment) {
        this.showComponent = false;
        let index = this.ix;
        setTimeout(x => {
            this.showComponent = true;
            this.reloadSubjectTerms.emit({ ix: index, subject: commitment.commitment.commitmentSubject });
        });
     //this.payPlanCommitmentFormGroup.patchValue(commitment.commitment);
     this.payPlanCommitmentFormGroup.controls['commitmentID'].setValue(commitment.id);
     this.payPlanCommitmentFormGroup.controls['commitmentSubject'].setValue(commitment.commitment.commitmentSubject);
     this.payPlanCommitmentFormGroup.controls['commitmentReasonID'].setValue(commitment.commitment.commitmentReasonID);
     this.payPlanCommitmentFormGroup.controls['designation'].setValue(commitment.commitment.designation);
     this.payPlanCommitmentFormGroup.controls['designationDetails'].setValue(commitment.commitment.designationDetails);
     this.payPlanCommitmentFormGroup.controls['hebYear'].setValue(commitment.commitment.hebYear);
     //        this.contactID = commitment.contactID;
 }

    removepayPlanCommitment(i: number) {
        const control = <FormArray>this.paymentsPlanFormGroup.controls['payPlanCommitments'];
        control.removeAt(i);
    }

    public selectSubject(e: any) {

        if (e && e.value) {
            this.subject = +e.value;
        } else {
            this.subject = +e;
        }

        if (this.subject) {
            if (this.subject == 3) {
            this.payPlanCommitmentFormGroup.controls['designation'].setValidators(Validators.required);

            this.payPlanCommitmentFormGroup.controls['designation'].enable();
            this.payPlanCommitmentFormGroup.controls['designationDetails'].enable();

            this.payPlanCommitmentFormGroup.controls['designation'].updateValueAndValidity();
        } else {
            this.payPlanCommitmentFormGroup.controls['designation'].setValidators(null);

            this.payPlanCommitmentFormGroup.controls['designation'].disable();
            this.payPlanCommitmentFormGroup.controls['designationDetails'].disable();

            this.payPlanCommitmentFormGroup.controls['designation'].updateValueAndValidity();
        } 
        
        this.payPlanCommitmentFormGroup.controls['commitmentSubject'].setValue(this.subject);
        
    } else {
        this.subject = null;
        this.payPlanCommitmentFormGroup.controls['commitmentSubject'].setValue(null);
    }
}



private loadReasons() {
    let subject = this.payPlanCommitmentFormGroup.controls['commitmentSubject'].value;
    if (subject) {
        this.servicesData.getList(`CommitmentReasons/${subject}`).subscribe(c => {
            this.commitmentReasons = c,
                error => { }
        });
    }
}

    
    commitmentSelect(commitment) {
        if (commitment) {
            this.commitment = commitment;

            let sbjct = this.commitment.commitmentSubject;
            if (sbjct && sbjct > 0) {
                this.selectSubject(sbjct)
            }
        }
    }
 
    ngOnInit(): void {
        let sbjct = this.payPlanCommitmentFormGroup.controls['commitmentSubject'].value;
        this.payPlanCommitmentFormGroup.controls['commitmentSubject'].valueChanges.subscribe(x => {
            if (this.openNewPayPlanCommitment) {
                this.loadReasons();

                this.payPlanCommitmentFormGroup.updateValueAndValidity();
                this.cdRef.detectChanges();
            }
        });
        if (sbjct && sbjct > 0) {
            this.selectSubject(sbjct);
        }
         
    }



}