<div class="model-section" >
  <dl class="dl-horizontal" *ngFor="let item of subjects">
    <dt>{{item.subjectName}}</dt>
    <dd>
      <span>
      <span  *ngIf="item.balance; else loading">  {{item.balance | number:'1.0-0'}} ₪</span>
        <a  matTooltip="מעבר לרשימה" *ngIf="item.subject != 6; else orderIvrTmpl" [routerLink]="['/commitmentsTreatment', item.subject]"
          class="btn btn-circle  btn-outline" style="color:blue">
          &nbsp;<i class="fa fa-hand-o-left"></i></a>
          <ng-template #orderIvrTmpl>
            <a  matTooltip="מעבר לרשימה"    [routerLink]="['/registrations', 'groupRegistrations']"
          class="btn btn-circle  btn-outline" style="color:blue">
          &nbsp;<i class="fa fa-hand-o-left"></i></a>
          </ng-template>
      </span>
    </dd>
    <hr>
  </dl>
</div>

<!-- <div class="row">
  <div class="col-lg-2" *ngFor="let item of subjects">
    <div class="ibox ">
      <div class="ibox-title">
        <div class="ibox-tools">
          <span class="label label-danger float-right">יתרות לגביה</span>
        </div>
        <h5>{{item.subjectName}}</h5>
      </div>
      <div class="ibox-content">
        <h1 class="no-margins" *ngIf="item.balance; else loading">{{item.balance | number:'1.0-0'}} ₪ </h1>
        <div class="stat-percent font-bold text-success">

          <a *ngIf="item.subject != 6; else orderIvrTmpl" [routerLink]="['/commitmentsTreatment', item.subject]"
            fxLayoutAlign="stert center">
            לרשימה <i class="fa fa-arrow-left"></i>
          </a>
          <ng-template #orderIvrTmpl>
            <a  [routerLink]="['/registrations', 'groupRegistrations']"
              fxLayoutAlign="stert center">
              לרשימה <i class="fa fa-arrow-left"></i>
            </a>
          </ng-template>
        </div>
        <small> &nbsp;&nbsp;</small>
      </div>

    </div>
  </div>
</div> -->
<ng-template #loading>
  <span>
    טוען...
  </span>
</ng-template>
