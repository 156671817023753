<div class="input-group md-3" *ngIf="!smallTemplate; else smallTemplateView" [formGroup]="formGroupModel">
  <label *ngIf="withLabel">{{placeholder}}:</label>
  <!-- <input apply-enter [ngStyle]="{'width': width}" class="form-control" *ngIf="setFocus" #txtSearch
    cdkFocusInitial [matAutocomplete]="auto" [placeholder]="placeholder"
    (cdkAutofill)="isAutofilled = $event.isAutofilled" [formControl]="nameControl" /> -->
  <!-- <div style="padding:0; margin: 0;"  fxLayout="row"> -->
  <input apply-enter [ngStyle]="{'width': width}" class="form-control"
    [required]="(idControl.validator && !idControl.valid)" [first-focus]="firstFocus" #txtSearch
    [matAutocomplete]="auto" [placeholder]="placeholder" (cdkAutofill)="isAutofilled = $event.isAutofilled"
    [formControl]="nameControl" />
   <mat-icon *ngIf="!id2 && (controllerName == 'Contacts' || idControl.name == 'contactID') && idControl.value"
    [ngStyle]="{'top':  (withLabel == true? '31': '3') +'px'}"
    style="font-size:70%;position: absolute;top: 31px;z-index: 99;left: 4px; color: gray;" [matTooltip]="'מבט'"
    (click)="openConactDetails(idControl.value);">insert_comment</mat-icon>
  <span *ngIf="id2 && (controllerName == 'Contacts' || idControl.name == 'contactID') && idControl.value"
    [ngStyle]="{'top':  (withLabel == true? '31': '3') +'px'}"
    style="font-size: 70%; position: absolute; top: 21px; z-index: 99; left: 4px; color: black; /* border: solid; */ border-width: 0 1px 1px 1px; border-radius: 3px; padding: 1px 4px; background: #80808026; font-weight: bold;" [matTooltip]="'מבט'"
    (click)="openConactDetails(idControl.value);">
    {{id2}}
  </span>


  <!-- <mat-icon style="font-size:50%;color: gray;">remove_red_eye</mat-icon> -->
  <!-- </div> -->
  <mat-autocomplete [panelWidth]="auto" [autoActiveFirstOption]="true" #auto="matAutocomplete" #matAutocomplete>
    <!-- <mat-option  value="">...</mat-option> -->
    <!-- <mat-option fxLayout="row" *ngIf="searchResults && searchResults.length > 0"
      style="border-bottom: solid; border-bottom-width:1px; font-weight: 500; white-space: nowrap;" value="">
      <div class="row" style="white-space: nowrap;">
        <span fclass="column" style="padding-left: 10px">מזהה</span>
        <span style="padding-left: 10px;" class="column">תיאור</span>
      </div>
    </mat-option> -->
    <mat-option style="white-space: nowrap;" *ngFor="let item of searchResults"
      (onSelectionChange)="setActive($event, item)" [ngClass]="{active: isActive(item)}" [value]="item.title"
      class="row">
      <div class="row" style="white-space: nowrap">
        <!-- <div fxFlex="20" fxLayout="column">{{ commitment.contactName }}</div> -->
        <span class="column" style="padding-left: 10px;">
          <small style="font-size: small;">{{item.id}}</small>
        </span>
        <span class="column" style="padding-left: 10px;">
          {{ item.title }}
          <span *ngIf="withSubTitle">
            <br>
            <span class="column" style="padding-left: 10px; font-weight: bold;">
              {{item.id2}}
            </span>
            <span  *ngIf="item.subTitle" class="column" style="padding-left: 10px;">
              {{item.subTitle}}
            </span> </span>
        </span>
      </div>
    </mat-option>
    <!-- <mat-option *ngFor="let item of searchResults" (onSelectionChange)="setActive($event, item)"
      [ngClass]="{active: isActive(item)}" [value]="item.title">
      [{{item.id}}] {{ item.title }}
    </mat-option> -->
  </mat-autocomplete>
</div>


<ng-template #smallTemplateView style="width: inherit;" [formGroup]="formGroupModel">
  <input apply-enter [ngStyle]="{'width': width}" class="form-control small-input" [first-focus]="firstFocus" #txtSearch
    [required]="(idControl?.validator && !idControl?.valid)" [matAutocomplete]="auto" [placeholder]="placeholder"
    (cdkAutofill)="isAutofilled = $event.isAutofilled" [formControl]="nameControl" />
  <mat-autocomplete [panelWidth]="auto" [autoActiveFirstOption]="true" #auto="matAutocomplete" #matAutocomplete>
    <mat-option *ngFor="let item of searchResults" (onSelectionChange)="setActive($event, item)"
      [ngClass]="{active: isActive(item)}" [value]="item.title">
      <span class="column" style="padding-left: 10px;">
        {{ item.title }}
        <span *ngIf="withSubTitle">
          <br>
          <span class="column" style="padding-left: 10px; font-weight: bold;">
            {{item.id2}}
          </span>
          <span  *ngIf="item.subTitle" class="column" style="padding-left: 10px;">
            {{item.subTitle}}
          </span> </span>
      </span>


      <!-- <span> [{{item.id}}] {{ item.title }} </span>
      <br *ngIf="withSubTitle">
      <small *ngIf="withSubTitle">{{item.subTitle}}</small> -->
    </mat-option>
  </mat-autocomplete>
</ng-template>
