﻿<div mat-line fxLayout="column">


  <div class="top-whaitings" fxLayout="column" *ngIf="topWaitingsList">
    <h3 fxLayout="row" style="text-decoration: underline;font-weight: bold; padding-right: 2px;"><i>סכום תשלומים ממתינים לתהליך גביה</i></h3>
    <div fxLayout="row">
      <div fxLayout="column" fxFlex *ngFor="let tw of topWaitingsList">
        <div class="row-state" *ngIf="tw.accountLocation == 1">ישראל:
          <span> {{tw.amount | number }}</span>
        </div>
        <div class="row-state" *ngIf="tw.accountLocation == 2">ארה"ב:
          <span>{{tw.amount | number }}</span>
        </div>
        <div class="row-state" *ngIf="tw.accountLocation == 3">לונדון:
          <span>{{tw.amount | number }}</span>
        </div>
        <div class="row-state" *ngIf="tw.accountLocation == 4">איחוד:
          <span>{{tw.amount | number }}</span>
        </div>
      </div>
    </div>
  </div>


  <mat-toolbar color="primary">
    משימות גביה בהמתנה
  </mat-toolbar>


  <div class="basic-container">
    <mat-nav-list *ngIf="list" class="mat-list">
      <!-- <mat-divider></mat-divider> -->
      <span *ngFor="let cb of list">
        <mat-list-item class="mat-list-item" style="background-color: inherit;">
          <div mat-line fxLayout="row" style="justify-content: flex-end;">
            <div fxLayout="column" fxFlex style="text-align: right;justify-content: flex-end;color: rgb(31 24 31);">
              {{cb.collectionDate |
              date:'dd.MM.yyyy'}}<br>{{cb.jewishCollectionDate}}
            </div>
            <div fxLayout="column" fxFlex
              style="text-align: center;font-size: 26px;font-weight: bold;justify-content: flex-end;color: rgb(31 24 31);">
              {{cb.count}}
              <br>
              <small style="font-size: 33%;">רשומות</small>
            </div>
            <div fxLayout="column" fxFlex style="text-align: left;font-size: 36px;justify-content: center"> <a
                [routerLink]="['', {outlets: {popup: ['collectionBuilders', cb.collectionBuilderID]}}]">
                <i class="fa fa-hand-o-left" style="color:#3f51b5"></i>
              </a>
            </div>
          </div>
        </mat-list-item>
        <!-- <mat-divider></mat-divider> -->
      </span>
    </mat-nav-list>
  </div>
