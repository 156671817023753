import { FormBuilder, FormGroup } from '@angular/forms';
import { ServicesData } from './../services/services.data';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { ObjectsListResult } from '../services/objects-list-results';

@Injectable()

export class ContactsLotteriesData {
    public url = 'api/lotteries';

    constructor(
        private toraxHttp: ToraxHttp,
    ) { }


    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    getPledgesList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/pledgesList`, baseQuery);
    }

    exportExcel(baseQuery: any){
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}
