<form  *ngIf="objectsListResult" [formGroup]="searchTishPlacesListForm" novalidate>
    <panel-box title="מקומות" [showClose]="false" [showCollapse]="false">
        <span class="panel-tools">
            <panel-box-btn (btnClick)="getTishPlaces()" icon="fa-refresh"></panel-box-btn>
            <!-- <a [routerLink]="[{outlets: {popup: ['add']}}]">
                <i class="fa fa-plus"></i>
            </a> -->
        </span>
        <div class="panel-content">
            <table class="table table-striped no-margins">
                <thead>
                    <tr>
                        <th>...</th>
                        <th [hidden]="tableID > 0">אירוע</th>
                        <th [hidden]="tableID > 0">שולחן</th>
                        <th>מקום</th>
                        <th>מוזמן</th>
                        <th>הערה</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tg of objectsListResult.list">
                        <td>
                            <a *ngIf="tableID == null" [routerLink]="['/tishPlaces', {outlets: {popup:  [tg.tishPlaceID]}}]">
                                <i class="fa fa-folder-open-o"></i>
                            </a>
                            <a *ngIf="tableID > 0" [routerLink]="['places', tg.tishPlaceID]">
                                <i class="fa fa-folder-open-o"></i>
                            </a>
                        </td>
                        <td [hidden]="tableID > 0">{{ tg.tishEventTitle }}</td>
                        <td [hidden]="tableID > 0">{{ tg.tishTableName }}</td>
                        <td>{{ tg.placeNumber }}</td>
                        <td>{{ tg.tishInvitedName }}</td>
                        <td>{{ tg.comment }}</td>
                    </tr>
                </tbody>
            </table>
            <pagination [rowsCount]="objectsListResult.rowsCount" [currentPage]="objectsListResult.page" [pagesCount]="objectsListResult.pagesCount"
                (change)="changePage($event)"></pagination>

        </div>
    </panel-box>
</form>