import { ToraxHttp } from './../../utils/custom-http.svc';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardGroupsSpecialDataService {

    private url = 'api/dashboardGroupsSpecials';
    constructor(
        private toraxHttp: ToraxHttp

    ) { }

    getCampignGroups(campignID:number) {
      return this.toraxHttp.get(`${this.url}/campignGroups/${campignID}`);
    }
    get(groupId)  {
        return this.toraxHttp.get(`${this.url}/${groupId}`);
    }
}
