<form [formGroup]="yarzeitForm" novalidate (ngSubmit)="save()" *ngIf="yarzeit">
  <div class="modal-header">
    <h1>עריכת פרטי יארצייט [{{ (id > 0 ? id : 'חדש') }}] </h1>
  </div>
  <div class="modal-body">
    <div *ngIf="yarzeit" class="example-form">
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי נפטר</legend>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div style="float: left; position: absolute; top: -3px; left: 48px; border: solid; border-width: 1px; background: #fafafa; padding: 7px; border-radius: 5px;" class="input-group mb-3">

              <auto-complete  apply-enter [controllerName]="'Contacts'" [placeholder]="'שיוך לכרטיס...'"
              [formGroupModel]="yarzeitForm"
              [idControl]="yarzeitForm.controls.contactID"
              [nameControl]="yarzeitForm.controls.contactName">
            </auto-complete>

            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">

            <div class="input-group mb-3">
              <label>שם פרטי ומשפחה:</label>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                <input class="form-control narrow" placeholder="תואר" apply-enter formControlName="perfix" />

                &nbsp;&nbsp;
                <input class="form-control" placeholder="שם פרטי" apply-enter formControlName="firstName" />

                &nbsp;&nbsp;
                <input class="form-control" placeholder="שם משפחה" apply-enter formControlName="lastName" />

                &nbsp;&nbsp;
                <input class="form-control narrow" placeholder="סיומת" apply-enter formControlName="suffix" />
              </div>
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>שם האב:</label>
              <input class="form-control" placeholder="שם האב" apply-enter formControlName="fatherName" />
            </div>
            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>שם האם:</label>
              <input class="form-control" placeholder="שם האם" apply-enter formControlName="motherName" />
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>שם בן הזוג:</label>
              <input class="form-control" placeholder="שם בן הזוג" apply-enter formControlName="spouseName" />
            </div>
            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>שם חותן:</label>
              <input class="form-control" placeholder="שם חותן" apply-enter formControlName="chotenName" />
            </div>
          </div>


          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <date-input [placeholder]="'תאריך פטירה'" [dateControl]="yarzeitForm.controls.deathDate">Loading...
            </date-input>
            &nbsp;&nbsp;
            <date-input [placeholder]="'תאריך קבורה'" [dateControl]="yarzeitForm.controls.burialDate">Loading...
            </date-input>
          </div>


          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>עיר מגורים:</label>
              <input class="form-control" placeholder="עיר מגורים" apply-enter formControlName="hometown" />
            </div>
            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>עיר מוצא:</label>
              <input class="form-control" placeholder="עיר מוצא" apply-enter formControlName="originCity" />
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>מקום קבורה:</label>
              <input class="form-control" placeholder="מקום קבורה" apply-enter formControlName="burialPlace" />
            </div>
            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>מיגדר:</label>
              <select class="form-control narrow" formControlName="gender">
                <option [value]="true">זכר</option>
                <option [value]="false">נקבה</option>
              </select>
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <textarea class="comment" apply-enter rows="2" cols="4" placeholder="הערה..."
              formControlName="comment"></textarea>
          </div>


        </fieldset>
      </div>
    </div>
  </div>
  <footer-edit-buttons [dataProvider]="yarzeitsData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
    [modelFormGroup]="yarzeitForm"></footer-edit-buttons>

</form>
