import { PayMethodMain } from './payMethod/payMethod-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { PayMethodsList } from './payMethods-list.cmp';

export const PayMethodsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'payMethods', component: PayMethodsList, data: {  roles: "payMethods",  title: 'אמצעי תשלום' } },
    { canActivate: [AuthGuard], path: 'payMethods/add', component: PayMethodMain, data: { mode: 'edit', roles: "payMethods", title: 'הוסף אמצעי תשלום' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'payMethods/:payMethodID', component: PayMethodMain, data: {  roles: "payMethods",  title: 'פרטי אמצעי תשלום' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'payMethods/:payMethodID/edit', component: PayMethodMain, data: { mode: 'edit', roles: "payMethods",  title: 'עריכת פרטי אמצעי תשלום' }, outlet: 'popup' },
];

