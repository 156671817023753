import { PaymentsPlansData } from './../paymentsPlans.data';
import { ServicesData } from '../../services/services.data';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Input, EventEmitter, Output, Inject } from '@angular/core';
import { ClosedPaymentsPlanMdl } from './paymentsPlan';

@Component({
    selector: 'paymentsPlan-closed-dlg',
    templateUrl: './paymentsPlan-closed-dlg.tmpl.html'
})

export class PaymentsPlanClosedDlg {

    constructor(
        public dialogRef: MatDialogRef<PaymentsPlanClosedDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        private paymentsPlansData: PaymentsPlansData
    ) { }

    public formData: FormGroup;
    closedDetails: ClosedPaymentsPlanMdl;



    ngOnInit(): void {
        this.initForm();
        this.loadClosedDetails();
    }

    loadClosedDetails() {
        this.paymentsPlansData.getPaymentsPlanClosedDetails(this.data.paymentsPlanID)
            .subscribe((c: ClosedPaymentsPlanMdl) => {
                this.closedDetails = c;
                this.formData.patchValue(c);
            });
    }

    validateDate = c => {
        // tslint:disable-next-line:max-line-length
        if (this.formData != null && this.formData.controls.closedStatus.value != ""
            && this.formData.controls.closedStatus.value != null && (!c.value || c.value === 'undefined')) {
            return { required: true };
        }
        return null;
    };
    validateStatus = c => {
        // tslint:disable-next-line:max-line-length
        if (this.formData != null && this.formData.controls.closedDate.value != ""
            && this.formData.controls.closedDate.value != null && (!c.value || c.value === 'undefined')) {
            return { required: true };
        }
        return null;
    };

    formValidation = frm => {
        // tslint:disable-next-line:max-line-length
        if (frm
            &&
            (this.formData.controls.closedDate.value && this.formData.controls.closedStatus.value)
            ||
            (!this.formData.controls.closedDate.value && !this.formData.controls.closedStatus.value)
        ) {
            return null;
        }
        return { required: true };
    };


    private initForm(): void {
        this.formData = this.formBuilder.group({
            paymentsPlanID: [, Validators.required],
            closedComment: [],
            closedDate: [null, this.validateDate],
            closedBy: [],
            closedStatus: [null, this.validateStatus]
        });
        this.formData.setValidators(this.formValidation);
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}



