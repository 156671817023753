import { ContactsLotteriesPledgesComponent } from './contacts-pledges-lotteries/contacts-lotteries-pledges.component'; 
import { AutoCompleteInputModule } from './../components/auto-complete-input/auto-complete-input.mdl';
 
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { AuthGuardMdl } from './../_guards/auth.guard.mdl';
import { PageHeadingModule } from './../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from './../components/panel-box/panel-box.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
//import { ContactsMdl } from './../contacts/contacts.mdl';
import { contactsLotteriesRoutings } from './contacts-lotteries.routing';
import { RouterModule } from '@angular/router';
import { ContactsSearchInputModule } from './../components/contacts-search-input/contacts-search-input.module';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { ContactsLotteriesData } from './contacts-lotteries.data';
import { MaterialModule } from './../material.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardPanelBoxModule } from './../components/dashboard-panel-box/dashboard-panel-box.mdl';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ContactsLotteriesListComponent } from './contacts-lotteries-list.component';

@NgModule({
    imports: [
        CommonModule,
        //BrowserModule,
        //BrowserAnimationsModule,
        DashboardPanelBoxModule,
        FormsModule,
        PaginationModule,
        PanelBoxModule,
        NoopAnimationsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        AutoCompleteModule,
        AuthGuardMdl,
        DateInputModule,
        RouterModule.forChild(contactsLotteriesRoutings), 
        MaterialModule, 
        AutoCompleteInputModule, 
        ContactsSearchInputModule,
    ],
    declarations: [
        ContactsLotteriesListComponent,
        ContactsLotteriesPledgesComponent
    ],
    exports: [
        ContactsLotteriesListComponent,
        ContactsLotteriesPledgesComponent
    ],
    providers: [
        ContactsLotteriesData
    ]
})
export class ContactsLotteriesModule { 

}
  