
<mat-button-toggle-group *ngIf="weekDays">
  <mat-button-toggle (click)="prev()" value="null" style="height: 100%; margin: auto">
    <div class="day-calendar">
      <div class="day-header">
        <i style="padding: 3px;" class="fa fa-arrow-right"></i>
      </div>
    </div>
  </mat-button-toggle>

  <mat-button-toggle *ngFor="let day of weekDays" [value]="day" (click)="selectDay(day)">
    <div class="day-calendar">
      <div class="day-header">
        <h1>{{(day.getHebrewDayOfWeek())}}</h1>
        <h2>{{(day.dayName)}} {{(day.monthName)}}</h2>
      </div>
    </div>
  </mat-button-toggle>

  <mat-button-toggle (click)="next()" value="null" style="height: 100%; margin: auto">
    <div class="day-calendar">
      <div class="day-header">
        <i style="padding: 3px;" class="fa fa-arrow-left"></i>
      </div>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>

