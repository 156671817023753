<div fxLayout="row" style="padding: 5px 10px 0;">
  <span class="example-fill-remaining-space"></span>
  <button (click)="loadData()" mat-button matTooltip="רענן" title="רענן" style="border:solid 1px">רענן&nbsp;&nbsp;<i
      class="fa fa-refresh"></i></button>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
  <div fxFlex="1 50" style="border-bottom:solid; border-color:solid rgb(192 192 192)">
    <div *ngIf="model; else loading" class="model-section">
      <div fxLayout="column" style="margin: 15px 15px 15px 0px;">
        <h1 style="text-align: right;">סיכום יחידות</h1>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
          <dl class="dl-horizontal">
            <dt>משתתפים</dt>
            <dd>
              <span>
                {{ model.participants }}
              </span>
            </dd>
            <hr>
            <dt>ס"ה יחידות</dt>
            <dd>
              {{ model.units }}
              <a (click)="openDlg()" class="btn  btn-success dim" style="margin: 0 !important; padding: 0 !important;">
                פילוח &nbsp;
                <i class="fa fa-hand-o-left"></i>
              </a>
            </dd>
            <hr>
            <dt>ממוצע יחידות למשפחה</dt>
            <dd>{{ model.averagePerFamilies }}</dd>
            <hr>
          </dl>
        </div>

      </div>
    </div>
  </div>
  <div *authGuardDirective="'marbeToreMoneis'" fxFlex="1 50"
    style="border-bottom:solid; border-color:solid rgb(192 192 192)">
    <dashboard-marbe-monies #moniesCharts></dashboard-marbe-monies>
  </div>
</div>
<!-- <div>
  <div fxLayout="column" *ngIf="model">
    <div *ngFor="let item of model.top10Shtibels" class="item-container-r">
      <div fxFlexFill class="item-body-r" fxLayout="row" [style.background]="styleObject(item)">
        <div class="item-body-title-r">
          {{ item.name }} <span> {{ item.value }} </span>
        </div>
      </div>
    </div>
  </div>

</div> -->

<div fxLayout="row" fxLayoutWrap style="padding: 30px;border-bottom:solid; border-color:solid rgb(192 192 192)">
  <dashboard-marbe-cities #citiesCharts></dashboard-marbe-cities>
</div>

<div fxLayout="row" fxLayoutWrap style="padding: 30px;border-bottom:solid; border-color:solid rgb(192 192 192)">
  <div style="max-height: 180px;">
    <div>
      <h1>התקדמות יחידות יומי</h1>

      <div fxLayout="row">
        <div [style.min-width.px]="lineChartViewWidth" style="margin:5px;">
          <!-- <ngx-charts-line-chart [view]="lineChartView" [scheme]="colorScheme" [results]="lineChartModel"
            [gradient]="true" [xAxis]="true" [yAxis]="true" [showXAxisLabel]="true" [showYAxisLabel]="true"
            [xAxisTickFormatting]="xAxisTickFormatting">
            <ng-template #tooltipTemplate let-model="model">
              {{ ( model.value > 0 ? + model.value + ' יחידות ל - ' + xAxisTickFormatting(model.name) : 'לא עודכנו
              יחידות ביום זה') }}
            </ng-template>

            <ng-template #seriesTooltipTemplate let-model="model">
              {{ ( model.value > 0 ? + model.value + ' יחידות ל - ' + xAxisTickFormatting(model.name) : '') }}
            </ng-template>
          </ngx-charts-line-chart> -->
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים
    <i style="color:royalblue" class="fa fa-refresh fa-spin"></i>
  </h3>
</ng-template>
