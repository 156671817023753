
import { NotFoundComponent } from './components/not-found/not-found.cmp';
import { LoginComponent } from './login/login.component';
import { Routes } from '@angular/router';
import { DashboardLocatorComponent } from './dashboard/dashboard-locator/dashboard-locator.cmp';
import { AuthGuard } from './_guards';
import { ScreenComponent } from './screen/screen.component';


export const appRoutes: Routes = [
  { path: 'dashboard', pathMatch: 'full', component: DashboardLocatorComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login-mobile', component: LoginComponent },
  { path: 'screenIndex', component: ScreenComponent },

  // { path: 'tishEvents', loadChildren: () => import(`./tishEvents/tishEvents.mdl`).then(m => m.TishEventsMdl) },
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: '**', pathMatch: 'full', component: NotFoundComponent },

  // // { canActivate: [AuthGuard], path: 'commitments/:commitmentID', component: CommitmentDetails, data: { roles: "commitments", title: 'פרטי התחייבות/הסכם' }, outlet: 'popup' },
  // // { canActivate: [AuthGuard], path: 'commitments/:commitmentID/edit', component: CommitmentEditWin, data: { roles: "commitments", title: 'עריכת פרטי התחייבות/הסכם' }, outlet: 'popup' },

  // { canActivate: [AuthGuard], path: 'paymentsPlans', loadChildren: () => import(`./paymentsPlans/paymentsPlans.mdl`).then(m => m.PaymentsPlansMdl) },
  // { canActivate: [AuthGuard], path: 'payments', loadChildren: () => import(`./payments/payments.mdl`).then(m => m.PaymentsMdl) },
  // { canActivate: [AuthGuard], path: 'payMethods', loadChildren: () => import(`./payMethods/payMethods.mdl`).then(m => m.PayMethodsMdl) },
  // { canActivate: [AuthGuard], path: 'holdings', loadChildren: () => import(`./holdings/holdings.mdl`).then(m => m.HoldingsMdl) },
  // { canActivate: [AuthGuard], path: 'shtibels', loadChildren: () => import(`./shtibels/shtibels.mdl`).then(m => m.ShtibelsMdl) },

  // { canActivate: [AuthGuard], path: 'ivrAssignmentUserTasks', loadChildren: () => import(`./ivrAssignmentUserTasks/ivr-assignment-user-tasks.mdl`).then(m => m.IvrAssignmentUserTasksMdl) },
  // { canActivate: [AuthGuard], path: 'ivrDonationUserTasks', loadChildren: () => import(`./ivrDonationUserTasks/ivr-donation-user-tasks.mdl`).then(m => m.IvrDonationUserTasksMdl) },

  // { canActivate: [AuthGuard], path: 'honorsSets', loadChildren: () => import(`./honorsSets/honors-sets.mdl`).then(m => m.HonorsSetsMdl) },
  // { canActivate: [AuthGuard], path: 'honors', loadChildren: () => import(`./honors/honors.mdl`).then(m => m.HonorsMdl) },
  // { canActivate: [AuthGuard], path: 'honorTypes', loadChildren: () => import(`./honor-types/honor-types.mdl`).then(m => m.HonorTypesMdl) },

  // { canActivate: [AuthGuard], path: 'groupRegistrations', component: RegistrationsGroupList, data: { title: 'רשימות מקובץ לאנשים וסוגים' } },

  // { canActivate: [AuthGuard], path: 'registrationEvents', loadChildren: () => import(`./registrationEvents/registrationEvents.mdl`).then(m => m.RegistrationEventsMdl) },

  // { canActivate: [AuthGuard], path: 'tishEvents', loadChildren: () => import(`./tishEvents/tishEvents.mdl`).then(m => m.TishEventsMdl) },

  // { canActivate: [AuthGuard], path: 'lotteries', loadChildren: () => import(`./contacts-lotteries/contacts-lotteries.module`).then(m => m.ContactsLotteriesModule) },
  // { canActivate: [AuthGuard], path: 'users', loadChildren: () => import(`./users/users.mdl`).then(m => m.UsersMdl) },
  // { canActivate: [AuthGuard], path: 'groups', loadChildren: () => import(`./groups/groups.mdl`).then(m => m.GroupsMdl) },
  // { canActivate: [AuthGuard], path: 'processTypes', loadChildren: () => import(`./processTypes/processTypes.mdl`).then(m => m.ProcessTypesMdl) },
  // { canActivate: [AuthGuard], path: 'letterSettings', loadChildren: () => import(`./letterSettings/letterSettings.mdl`).then(m => m.LetterSettingsMdl) },
  // { canActivate: [AuthGuard], path: 'commitmentReasons', loadChildren: () => import(`./commitment-reasons/commitmentReasons.mdl`).then(m => m.CommitmentReasonsMdl) },
  // { canActivate: [AuthGuard], path: 'vouchersOrganizations', loadChildren: () => import(`./vouchersOrganizations/vouchersOrganizations.mdl`).then(m => m.VouchersOrganizationsMdl) },
  // { canActivate: [AuthGuard], path: 'collectionBuilders', loadChildren: () => import(`./collectionBuilders/collection-builders.mdl`).then(m => m.CollectionBuildersMdl) },
  // { canActivate: [AuthGuard], path: 'transactions', loadChildren: () => import(`./transactions/transactions.mdl`).then(m => m.TransactionsMdl) },

  // { canActivate: [AuthGuard], path: 'cities', loadChildren: () => import(`./cities/cities.mdl`).then(m => m.CitiesMdl) },
  // { canActivate: [AuthGuard], path: 'terms', loadChildren: () => import(`./terms/terms.mdl`).then(m => m.TermsMdl) },
  // { canActivate: [AuthGuard], path: 'countries', loadChildren: () => import(`./countries/countries.mdl`).then(m => m.CountriesMdl) },




];
