<panel-box title="משימות ישנות" [showClose]="false" [showCollapse]="true">
    <span class="panel-tools">
    </span>
    <div class="panel-content">
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <th>מזהה</th>
                        <th>שם</th>
                        <th>בוצע בתאריך</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ca of activities">
                        <td>{{ ca.activityID }}</td>
                        <td>{{ ca.activityTitle }}</td>
                        <td>{{ ca.doneOn }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</panel-box>