<form style="white-space: nowrap;" [formGroup]="formData" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      בחר איש לשיוך העברה
      <br>

    </h2>
  </div>
  <mat-dialog-content style="padding:0px 24px 24px 24px">
    <div fxLayout="column" style="display: block;">
      <div class="panel-content">
        <div class="form-row" fxLayout="column">
          <auto-complete apply-enter [placeholder]="'שם איש...'" (onSelect)="selectionChanged($event)"
            [controllerName]="'Contacts'" [formGroupModel]="formData" [idControl]="formData.controls.contactID"
            [nameControl]="formData.controls.contactName"></auto-complete>

        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">

    <button [disabled]="formData.invalid" class="btn btn-primary navbar-btn" [mat-dialog-close]="formData"><i
        class="fa fa-download"></i> פתח תשלום </button>
  </mat-dialog-actions>
</form>
