
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    
    selector: 'pagination',
    templateUrl: './pagination.tmpl.html'
})
export class PaginationComponent {
    @Input() currentPage: number;
    @Input() pagesCount: number;
    @Input() pageRows: number = 30;

    private _rowsCount: number;
    @Input() get rowsCount(): number {
        return this._rowsCount;
    }
    set rowsCount(value: number) {
        this._rowsCount = value;
        if (this.pageRows === 0) {
            this.pageRows = 20;
        }
        let pc = Math.ceil(this._rowsCount / this.pageRows);
        if (pc === 0) {
            pc = 1;
        }
        this.pagesCount = pc;
    }

    @Output() change = new EventEmitter<number>();

    private pagePadding = 5;

    changePage(newPage: number) {
        this.currentPage = newPage;
        this.change.emit(this.currentPage);
    }

    pages(): number[] {
        let result: number[] = [];
        for (let i = this.startPaging(); i <= this.endPaging(); i++) {
            result.push(i);
        }
        return result;
    }

    previousPage() {
        if (this.currentPage > 1) {
            this.changePage(this.currentPage - 1);
        }
    }
    nextPage() {
        if (this.currentPage < this.pagesCount) {
            this.changePage(this.currentPage + 1);
        }
    }

    startPaging() {
        let result = this.currentPage - this.pagePadding;

        if (result > this.pagesCount - (this.pagePadding * 2)) {
            result = this.pagesCount - (this.pagePadding * 2);
        }
        if (result < 1) {
            result = 1;
        }
        return result;

    }

    endPaging() {
        let result = this.currentPage + this.pagePadding;

        if (result < (this.pagePadding * 2) + 1) {
            result = (this.pagePadding * 2) + 1;
        }
        if (result >= this.pagesCount) {
            result = this.pagesCount;
        }

        return result;
    }
}
