import { ToraxHttp } from './../utils/custom-http.svc';
import { FormGroup } from '@angular/forms';
import { Injectable, EventEmitter } from '@angular/core';
import { Registration } from './registration/registration';

@Injectable()

export class RegistrationsData {
  private url = 'api/registrations';

  constructor(
    private toraxHttp: ToraxHttp
  ) { }

  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  getGroupRegistrationsList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/groupRegistrationsList`, baseQuery);
  }

  getRegistrationsDebitBalance(id: number) {
    return this.toraxHttp.getWithResponce(`${this.url}/getRegistrationsDebitBalance/${id || 0}`);
  }



  exportExcel(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
  }

  exportExcelWithTotals(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExlsWithTotals`, baseQuery);
  }

  exportGroupRegistrationsExcel(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadGroupRegistrationsExls`, baseQuery);
  }


  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }


  getEventType(evntID: number) {
    return this.toraxHttp.get(`${this.url}/getEventType/${evntID || 0}`);
  }

  getTopUpdatesInCashRegister(eventId: number) {
    return this.toraxHttp.get(`${this.url}/getTopUpdatesInCashRegister/${eventId || 0}`);
  }

  getRegistrationRow(registrationID: number, contactID: number) {
    return this.toraxHttp.getWithResponce(`${this.url}/getRegistrationRow/${registrationID || 0}/${contactID || 0}`);
  }

  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  update(registration: Registration) {
    return this.toraxHttp.update(this.url, registration);
  }

  getAdd(contactId: number) {
    return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
  }

  postUpdateCashRegister(updateCashRegisterForm: FormGroup) {
    return this.toraxHttp.update(`${this.url}/addCashRegisterPayment`, updateCashRegisterForm);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}

