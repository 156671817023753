import { MatTabGroup } from '@angular/material/tabs';
import { Subscription, interval } from 'rxjs';
import { Location } from '@angular/common';
import { DashboardChartsDataService } from './dashboard-charts.data';
import { ChartsChartModel } from './dashboard-charts-chart-model';
import { AfterViewInit, Component, OnInit, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { CLOSE_SQUARE_BRACKET } from '@angular/cdk/keycodes';

declare const Hebcal: any;


@Component({
  selector: 'dashboard-charts-sub-tabs',
  templateUrl: 'dashboard-charts-sub-tabs.component.html',
  styleUrls: ['dashboard-charts.component.scss']  
})
export class DashboardChartsSubTabsComponent implements OnInit {

  constructor(
    private cdref: ChangeDetectorRef, 
    private location: Location,
    private dashboardChartsDataService: DashboardChartsDataService
  ) {
  }
  @ViewChild('tabs', { static: true }) tabs: MatTabGroup;

  @Input('grp') public grp: string;

  activeTabIndex;
  perfix = "tabs_";
  grpSums;





  public tabSelect(event) {
    this.activeTabIndex = event.index;
    this.selectGrp();

    sessionStorage.setItem(`${this.pathStr}`, `${this.activeTabIndex}`);
    this.cdref.detectChanges();
  }

  selectGrp() {
    // switch (this.activeTabIndex) {
    //   case (0):
    //     this.grp = "פילוח כרטיסים";
    //     break;
    //   case (1):
    //     this.grp = "פילוח משתתפים";
    //     break;
    //   default:
    //     break;
    // }
  }

  get pathStr() {
    return `${this.perfix}${this.location.path()}`;
  }

 

  ngOnInit() {
    this.activeTabIndex = sessionStorage.getItem(`${this.pathStr}`) || 0;
    this.selectGrp(); 
  }
}