import { DateMonthInput } from './dateMonth-input.cmp';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortPipeMdl } from '../pipes/sort-pipe';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, 
        SortPipeMdl],
    exports: [DateMonthInput],
    declarations: [DateMonthInput]
})
export class DateMonthInputModule { }

