import { ProcessesType } from './processes-type/processes-type';
import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';



@Injectable()

export class ProcessesTypesData {
    private url = 'api/processTypes';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }



    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(processType: ProcessesType) {
        return this.toraxHttp.update(this.url, processType);
    }
}