
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { ContactEventEditDlg } from '../contactEvent-edit-dlg.cmp';
import { Yarzeit } from 'src/app/yarzeits/yarzeit/yarzeit';

@Component({
    selector: 'contact-event-yarzeits-details',
    templateUrl: './contact-event-yarzeits-details.tmpl.html'
})

export class ContactEventYarzeitDetails {

    constructor(
        public dialog: MatDialog
    ) {

    }
    @Output() yarzeitChange = new EventEmitter<number>();
    @Output() onEditClick = new EventEmitter<number>();
    @Input('yarzeit') public yarzeit: Yarzeit;

    editYarzeit(cg) {
        this.onEditClick.emit(cg);
    }

    private openAddOrEditYarzeit(yarzeitID: number = 0): void {
        // const dialogRef = this.dialog.open(ContactEventEditDlg, {
        //     data: { contactId: this.yarzeit.contactID, id: this.yarzeit.yarzeitID, yarzeit: this.yarzeit }
        // });


        // dialogRef.afterClosed().subscribe(result => {
        //     if (result && result.valid) {
        //         this.saveYarzeit(result);
        //     }
        // });
    }

    // private saveYarzeit(yarzeitForm: FormGroup) {
    //     this.contactsData.update(yarzeitForm.value)
    //         .subscribe((pm: any) => {
    //             return true;
    //         })
    // }

}



