import { FormGroup } from '@angular/forms';
import { AlertService } from '../../components/alert/alert.svc';
import { FormBuilder } from '@angular/forms';
import { CommitmentEditDlg } from '../commitment/commitment-edit-dlg.cmp';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommitmentsData } from '../commitments.data';
import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { Commitment } from '../commitment/commitment';


@Component({
    selector: 'commitment-add-action',
    templateUrl: './commitment-add-action.tmpl.html'
})
 
export class CommitmentAddAction implements AfterViewInit {

    constructor(
        private commitmentsData: CommitmentsData,
        private formBuilder: FormBuilder,
        private alertSvc: AlertService,
        public dialog: MatDialog
    ) {

    }
    ngAfterViewInit(): void {
        // console.log("commitment",this.commitment);

    }
    
    @Input('contactID') public contactID: number;
    @Input('commitmentID') public commitmentID: number;
    @Input('commitment') public commitment: Commitment;
    @Input('editIcon') public editIcon: false;
    @Output() commitmentChange = new EventEmitter<any>(); 

    // onEditClick(obj: Commitment) {
    //     this.openAddOrEditCommitment(obj);
    // }

    public openAddOrEditCommitment(): void {
        setTimeout(() => {
            if (!this.commitment) {
                this.commitment = new Commitment();
                this.commitment.contactID = this.contactID;
                this.commitment.commitmentID = this.commitmentID;
            }

            const dialogRef = this.dialog.open(CommitmentEditDlg, {
                data: { contactId: this.commitment.contactID | this.contactID, id: this.commitment.commitmentID | this.commitmentID, commitment: this.commitment }
            });


            dialogRef.afterClosed().subscribe(result => {
                if (result && result.valid) {
                    this.saveCommitment(result);
                }
            });
        }, 200);
    }  


    private saveCommitment(commitmentForm: FormGroup) {
        this.commitmentsData.update(commitmentForm.value)
            .subscribe((x: any) => {
                //let subject = commitmentForm.value.commitmentSubject;
                let commitment  = {id : x.commitment.commitmentID,  commitment : x.commitment  }
                this.commitmentChange.emit(commitment)
                return true;
            })
    }

}



