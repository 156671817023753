export class SgnlContent {
  constructor(
    public type: string,
    public model: any
  ) { }
}

export class Message {

  constructor(
    public messageText: string
  ) { }
}