
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesData } from 'src/app/services/services.data';
import { EmergencySquadMembersData } from '../emergency-squad-members.data';
import { EmergencySquadMember } from './emergency-squad-member-queue';

@Component({
  selector: 'emergency-squad-member-edit',
  templateUrl: './emergency-squad-member-edit.tmpl.html'
})

export class EmergencySquadMemberEdit implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private emergencySquadMembersData: EmergencySquadMembersData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
  ) {
  }

  emergencySquadMember: EmergencySquadMember;
  id: number;
  contactID: number;
  emergencySquadMemberForm: FormGroup;

  public load(): void {
    if (this.id > 0) {
      this.emergencySquadMembersData.getEdit(this.id || 0)
        .subscribe((c: any) => {
          this.emergencySquadMember = c;
          this.emergencySquadMemberForm.patchValue(c);
        });
    } else {
      this.emergencySquadMembersData.getAdd(this.contactID)
        .subscribe((c: any) => {
          this.emergencySquadMember = c;
          this.emergencySquadMemberForm.patchValue(c);
        });
    }
  }



  private initForm() {
    this.emergencySquadMemberForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      emergencySquadMemberID: [this.id || 0, Validators.required],
      contactID: [, Validators.required],
      contactName: [],
     // relationTypeID: [19, Validators.required],
    });
  }



  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['emergencySquadMemberID'];
      this.contactID = +params['contactID'];
    });
    this.initForm();
    this.load();
  }
}



