<div [formGroup]="modelFormGroup" class="modal-footer">

    <div fxLayoutAlign="start center" class="btn-group" style="width: max-content;">

        <span class="example-fill-remaining-space"></span>
        <h4 *ngIf="errorMsg" style="color:red; text-align: end;">
            {{errorMsg}}
        </h4>
        <!-- <button type="button"  (click)="getFormValidationErrors()" class="btn btn-primary navbar-btn">
            <i class="fa fa-save"></i> בדיקת טופס
        </button> -->

        <ng-container *ngTemplateOutlet="customHtmlButtons">
        </ng-container>
        <button  *ngIf="displaySaveButton" type="button" [disabled]="!modelFormGroup.valid || disableSave" (click)="save()"
            class="btn btn-primary navbar-btn">
            <i class="fa fa-save"></i> שמור
        </button>
        <button type="button" *ngIf="displayDeleteButton"  [disabled]="disabledDeleteButton" class="btn btn-danger navbar-btn" (click)="delete()">
            <i class="fa fa-ban"></i> מחק
        </button>
        <button type="button" class="btn btn-warning navbar-btn" (click)="cancel()">
            <i class="fa fa-warning"></i> ביטול
        </button>
    </div>
</div>
