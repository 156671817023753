<form  [formGroup]="tishTableForm" novalidate (ngSubmit)="save()" *ngIf="tishTable">
    <div class="modal-header">
        <h1>הגדרות שולחן [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">
                <panel-box title="הגדרות שולחן" [showClose]="false" [showCollapse]="false">
                    <span class="panel-tools"></span>
                    <div class="panel-content">
                        <div class="form-horizontal">
                            <div class="form-group">
                                <label class="control-label col-sm-2">כותרת אירוע:</label>
                                <div class="col-sm-10">
                                    <h3> {{ tishTable.tishEventTitle }}</h3>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2">שם שולחן:</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" formControlName="tishTableName" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="tishAreaID">איזור (ברירת מחדל):</label>
                                <div class="col-sm-10">
                                    <auto-complete apply-enter [controllerName]="'TishAreas/' + tishEventID" [formGroupModel]="tishTableForm" [idControl]="tishTableForm.controls.tishAreaID"
                                        [nameControl]="tishTableForm.controls.tishAreaName"></auto-complete>
                                        <auto-complete apply-enter [controllerName]="'TishGroups/' + tishEventID" [formGroupModel]="tishTableForm" [idControl]="tishTableForm.controls.tishGroupID"
                                        [nameControl]="tishTableForm.controls.tishGroupName"></auto-complete>
                                        <div class="form-group">
                                            <label class="control-label col-sm-2" for="countPlaces">כמות מקומות:</label>
                                            <div class="col-sm-10">
                                                <input type="number" class="form-control" formControlName="countPlaces" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="control-label col-sm-2" for="comment">הערה:</label>
                                            <div class="col-sm-10">
                                                <input type="text" class="form-control" formControlName="comment" />
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="tishGroupID">קבוצה (ברירת מחדל):</label>
                                <div class="col-sm-10">
                                    <auto-complete apply-enter [controllerName]="'TishGroups/' + tishEventID" [formGroupModel]="tishTableForm" [idControl]="tishTableForm.controls.tishGroupID"
                                        [nameControl]="tishTableForm.controls.tishGroupName"></auto-complete>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="countPlaces">כמות מקומות:</label>
                                <div class="col-sm-10">
                                    <input type="number" class="form-control" formControlName="countPlaces" />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="control-label col-sm-2" for="comment">הערה:</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" formControlName="comment" />
                                </div>
                            </div>
                        </div>
                    </div>
                </panel-box>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="tishTablesData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0" [modelFormGroup]="tishTableForm"></footer-edit-buttons>

</form>
