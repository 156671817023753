 import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';
import { AutoCompleteInputComponent } from './../../components/auto-complete-input/auto-complete-input.cmp';
import { ServicesData } from '../../services/services.data';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ShtibelsData } from '../shtibels.data';
import { Shtibel } from './shtibel'; 

@Component({
    
    selector: 'shtibel-edit',
    templateUrl: './shtibel-edit.tmpl.html'
})

export class ShtibelEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public shtibelsData: ShtibelsData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData,
    ) {
    }

    shtibel: Shtibel;
    id: number;
    shtibelForm: FormGroup;

    
    @ViewChild('cityCmp') cityCmp: AutoCompleteComponent;
    @ViewChild('streetInput') streetInput: AutoCompleteInputComponent;


    public load(): void {
        if (this.id) {
            this.shtibelsData.getEdit(this.id)
                .subscribe((c:any) => {
                    this.shtibel = c;
                    this.shtibelForm.patchValue(c);
                });
        }
    }

    public save(): void {
        console.log('save run');
        if (this.shtibelForm.valid) {
            this.shtibelsData.update(this.shtibelForm.value)
                .subscribe((c: any) => {
                    this.router.navigateByUrl(`/shtibels/${c.id}`);
                },
            error => { });
        } else {
            console.log(this.shtibelForm.errors);
        }
    }

    private initForm() {
        this.shtibelForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            shtibelID: [this.id || 0, Validators.required],
            shtibelName: ['', Validators.required],
            street: [],
            houseNum: [],
            aptNum: [],
            entry: [],
            zip: [],
            pob: [], 
            cityID: [],
            cityName: [],
            stateID: [],
            stateName: [],
            countryID: [],
            countryName: [],
            gabbaiID1: [''],
            gabbaiName1: [''],
            gabbaiID2: [''],
            gabbaiName2: [''],
            gabbaiID3: [''],
            gabbaiName3: [''],
          
            holdingsResponsibleID1: [''],
            holdingsResponsibleName1: [''],
            holdingsResponsibleID2: [''],
            holdingsResponsibleName2: [''],
            holdingsResponsibleID3: [''],
            holdingsResponsibleName3: [''],
           
            holdingsResponsibleExternalID: [''],
            holdingsResponsibleExternalName: [''],
          
            representativeID: [],
            representativeName: [],
            codeIchudForShtibel: [],
            commitmentPercentage: [],

            holdingsTitle:[]
        });
    }
    public cityIDItemSelect(cityID: number) {
        this.streetInput.sourceList = `Streets/${cityID}`;
    }
    public countryIDItemSelect(countryID: number) {
        this.cityCmp.sourceList = `Cities/${countryID}`;
    }
    public cancel(): void {
        this.location.back();
    }

    ngOnInit() {
        this.initForm();
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['shtibelID'];
            if (this.id > 0) {
                this.load();
            } else {
                this.shtibel = new Shtibel();
                this.shtibel.shtibelID = 0;
            }
        });
    }
}



