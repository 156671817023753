import { MaterialModule } from './../material.module';
import { YarzeitMain } from './yarzeit/yarzeit-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';

import { YarzeitsRoutes } from './yarzeits.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { YarzeitsData } from './yarzeits.data';
import { YarzeitEdit } from './yarzeit/yarzeit-edit.cmp';
import { YarzeitDetails } from './yarzeit/yarzeit-details.cmp';
import { YarzeitsList } from './yarzeits-list.cmp';
import { AmountAndCurrencyInputModule } from '../components/amount-and-currency-input/amount-and-currency-input.module';
import { ModelInfoModule } from '../components/model-info/model-info.mdl';
import { DateInputRangeModule } from '../components/dateInput-range/date-input-range.mdl';
import { YarzeitAffiliationsList } from './yarzeit-affiliations/yarzeit-affiliations-list.cmp';
import { YarzeitsMain } from './yarzeits-main.cmp';
import { YarzeitAffiliationsMinList } from './yarzeit-affiliations/yarzeit-affiliations-min-list.cmp';
import { ContactsMdl } from '../contacts/contacts.mdl';
import { JewishWeekDaysPickerModule } from '../components/jewish-week-days-picker/jewish-week-days-picker.module';


@NgModule({
	imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		DateInputModule,
    DateInputRangeModule,
		AutoCompleteModule,
		RouterModule.forChild(YarzeitsRoutes),
		MaterialModule,
		MainFormModalModule,
		AmountAndCurrencyInputModule,
    ModelInfoModule,
    ContactsMdl,
    JewishWeekDaysPickerModule

	],
	declarations: [
    YarzeitsMain,
		YarzeitMain,
		YarzeitEdit,
		YarzeitDetails,
		YarzeitsList,
		YarzeitAffiliationsList,
    YarzeitAffiliationsMinList
	],
	exports: [
    YarzeitsMain,
		YarzeitMain,
		YarzeitEdit,
		YarzeitDetails,
		YarzeitsList,
		YarzeitAffiliationsList,
		YarzeitAffiliationsMinList,
	],
	providers: [
		YarzeitsData
	]
})
export class YarzeitsMdl { }

