//import { ActivitiesData } from './../activities/activities.data';

import { Observable} from 'rxjs';
//import { Activity } from './../activities/activity';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    
    selector: 'contact-details-contactActivities',
    templateUrl: './contact-details-contactActivities.tmpl.html'
})

export class ContactDetailsActivities implements OnInit {
    activities: any[];
    pagesCount: number;
    page: number;
    @Input('contactID') public contactID: number;
    constructor(
        //      private activitiesData: ActivitiesData
    ) {

    }


    ngOnInit() {
        /*this.activitiesData.getList(1, this.contactID.toString(), 1).subscribe(
            (r:any) => {
                this.activities = r.list;
            },
            error => { });*/
    }



}