﻿<div *ngIf="process">
    <div class="col-sm-6">
        <div>
            <panel-box title="פרטי עיר: {{ id }}" [showClose]="false" [showCollapse]="false">
                <span class="panel-tools">
                    <a [routerLink]="['/processes', {outlets: {popup: [ id, 'edit']}}]"><i class="fa fa-pencil"></i></a>
                </span>
                <div class="panel-content">
                    <div>
                        <label>{{ process.processID }}</label>
                        <label>{{ process.description }}</label>
                    </div>
                </div>
            </panel-box>
        </div>
    </div>
</div>