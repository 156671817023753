import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { CollectionBuildersData } from './collection-builders.data';
import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';
import { IndexService } from '../services/index-service';
import { SubscriptionLike } from 'rxjs';


@Component({
  selector: 'collection-builders-list',
  templateUrl: 'collection-builders-list.tmpl.html'
})

export class CollectionBuildersList implements OnInit {
  constructor(
    private collectionBuildersData: CollectionBuildersData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/honors') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }
  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  indexService: IndexService;
  displayedColumns: string[] = ['collectionBuilderID', 'termID', 'collectionDate', 'plannedCount', 'plannedSum', 'succeededCount', 'succeededSum', 'exludesCount', 'exludesSum', 'contactID', 'paymentID'];
  defaultvalues: any[];
  resultsLength = 0;
  isRateLimitReached = false;

  @Input('termID') public termID?: number | null = null;
  @Input('collectionDateRange') public collectionDateRange?: Date | null = null;
  //@Input('statusResponse') public statusResponse?: number | null = null;

  @Output() selectedRowsChange = new EventEmitter<any>();

  togleTT = false;
  showPmTrans() {
    this.togleTT = this.togleTT
  }


  searchCollectionBuildersListForm: FormGroup;
  actualMinusHeight = '195';
  selection = new SelectionModel<number>(true, []);

  addNew() {
    this.router.navigate(['', { outlets: { popup: ['collectionBuilders', 'add'] } }]);
  }




  private initForm() {
    this.searchCollectionBuildersListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      collectionDateRange: [],
      termIDs: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchCollectionBuildersListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.collectionBuilderID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {
    this.initForm();

    if (this.toraxHttp.previousUrl == "/dashboard/posMode") {
      let item = sessionStorage.getItem("dashboard/collectionBuilders/dbCollectionBuilders");
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem("dashboard/collectionBuilders/dbCollectionBuilders") || "");
        this.searchCollectionBuildersListForm.patchValue(frm);
      }
    }
    else {
      let item = sessionStorage.getItem(this.router.url);
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
        this.searchCollectionBuildersListForm.patchValue(frm);
      }
    }

    this.searchCollectionBuildersListForm.updateValueAndValidity();
    let params = [
      { key: "termID", value: this.termID },
    ];
    this.indexService = new IndexService(this.searchCollectionBuildersListForm, params, this.collectionBuildersData, this.router, this.defaultvalues, '', '', '');
    this.indexService.getList();


  }
}

