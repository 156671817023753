
import { MainFormModalModule } from '../components/main-form-modal/main-form-modal.mdl';

import { DateInputModule } from '../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { MaterialModule } from '../material.module';
import { ModelInfoModule } from '../components/model-info/model-info.mdl';
import { DepositEdit } from './deposit/deposit-edit.cmp';
import { DepositDetails } from './deposit/deposit-details.cmp';
import { DepositMain } from './deposit/deposit-main.cmp';
import { DepositsData } from './deposits-data';
import { DepositsRoutes } from './deposits.routing';
import { PaymentsMdl } from '../payments/payments.mdl';
import { DepositsMain } from './deposits-main.cmp';
import { DepositsList } from './deposits-list.cmp';
import { DepositEditDlg } from './deposit/deposit-edit-dlg.cmp';
import { DepositsWaitingsWarnings } from './deposits-waitings-wornings.cmp';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PageHeadingModule,
    PanelBoxModule,
    PaginationModule,
    FooterEditButtonsMdl,
    DateInputModule,
    RouterModule.forChild(DepositsRoutes),
    MainFormModalModule,
    MaterialModule,
    ModelInfoModule,
    PaymentsMdl
  ],
  declarations: [
    DepositsMain,
    DepositsList,
    DepositEdit,
    DepositDetails,
    DepositMain,
    DepositEditDlg,
    DepositsWaitingsWarnings
  ],
  exports: [
    DepositsList,
    DepositEdit,
    DepositDetails,
    DepositMain,
    DepositsWaitingsWarnings
  ],
  entryComponents: [
    DepositEditDlg
  ]
  ,
  providers: [
    DepositsData
  ]
})
export class DepositsMdl { }

