<form [formGroup]="registrationForm" novalidate (ngSubmit)="save()" *ngIf="registration">
  <div class="modal-header">
    <h1>עריכת פרטי רישום [{{ (id > 0 ? id : 'חדש') }}] </h1>
  </div>
  <div class="modal-body">
    <div *ngIf="registration" class="example-form">
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי רישום</legend>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>שם איש:</label>
              <input style="font-weight: bold;" type="text" class="form-control" [disabled]="true" [value]="registration.contactName">
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <auto-complete apply-enter (onSelect)="loadEventType($event)" [controllerName]="'RegistrationEvents'"
              [placeholder]="'אירוע'" [formGroupModel]="registrationForm"
              [idControl]="registrationForm.controls.registrationEventID"
              [nameControl]="registrationForm.controls.registrationEventTitle">
            </auto-complete>
            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>מס' מקומות:</label>
              <input apply-enter type="text" class="form-control narrow" formControlName="quantity" />
            </div>
          </div>
          <div *ngIf="isAronit == true" class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>מס' ארונית:</label>
              <input type="text" class="form-control" formControlName="aronitNumber" />
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <payMethods-select-pick fxLayout="row"
              [ngClass]="{'has-error': registrationForm.controls.payMethodID.invalid }"
              (payMethodAdd)="payMethodsAddEvent($event)" (payMethodChange)="payMethodsChangeEvent($event)"
              [contactID]="registration.contactID" [placeHolder]="'אמצעי תשלום'"
              [payMethodIdControl]="registrationForm.controls.payMethodID" [fromRecordType]="'Registration'"
              [fromRecordID]="registrationForm.controls.registrationID.value">
            </payMethods-select-pick>
          </div>
          <input-info class="form-row" fxLayout="row" [alwaysShow]="true"
            [control]="registrationForm.controls.payMethodID">
            אמצעי תשלום ברישום *לא מחייב*
          </input-info>

        </fieldset>
        <fieldset class="form-group" fxLayout="column">
          <legend>נתונים ממערכת הרישום</legend>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>כינוי שם פרטי:</label>
              <input type="text" class="form-control" formControlName="nickFirstName" />
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>קבוצת גיל:</label>
              <input type="text" class="form-control" formControlName="ageGroup" />
            </div>
            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>רמת מקום:</label>
              <input type="text" class="form-control" formControlName="placeLevel" />
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>מחיר מוצר:</label>
              <input type="text" class="form-control" formControlName="productPrice" />
            </div>
            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>סה"כ:</label>
              <input type="text" class="form-control" formControlName="totalPrice" />
            </div>

          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">

            <div class="input-group mb-3">
              <label>הערת מערכת 1:</label>
              <input type="text" class="form-control" formControlName="ivrComment1" />
            </div>
            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>הערת מערכת 2:</label>
              <input type="text" class="form-control" formControlName="ivrComment2" />
            </div>
          </div>

        </fieldset>
      </div>
    </div>
  </div>
  <footer-edit-buttons [dataProvider]="registrationsData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
    [modelFormGroup]="registrationForm"></footer-edit-buttons>

</form>
