
<button (click)="close()" mat-button style="float:left">X</button>
  <!-- <ngx-charts-pie-chart  [scheme]="colorScheme" [results]="data" [legendPosition]="legendPosition"
    [legend]="true" [view]="view" [gradient]="false" [tooltipDisabled]="false" [labels]="true">
  </ngx-charts-pie-chart> -->

<ng-template #loading>
  <h4>
    <i style="color:royalblue" class="fa fa-refresh fa-spin"></i>
  </h4>
</ng-template>
