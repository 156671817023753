<div loading-status [stopLoadingWhen]="model"  fxLayout="row" fxLayout.xs="column" fxFlexFill>
  <panel-box style="margin:5px" fxFlex="25" fxFlex.xs="25" *ngFor="let statusGrp of model.trackingStatusModels"  [title]="getTitle(statusGrp.relevantStatusPayment)" [showClose]="false" [showCollapse]="false">
    <span class="panel-tools"> 
      <mat-icon *ngIf="statusGrp.relevantStatusPayment == 1" matPerfix style="color: rgb(240, 206, 14)">
        warning
      </mat-icon>
       <mat-icon *ngIf="statusGrp.relevantStatusPayment == 2" matPerfix style="color: green;">
        check_circle_outline</mat-icon>
        <mat-icon *ngIf="statusGrp.relevantStatusPayment == 3" matPerfix style="color: red;">close
        </mat-icon>
      </span>
    <div class="panel-content" style="background-color: white; margin: -5px;">
      <div class="row">
        <div *ngFor="let accountGrp of statusGrp.trackingAccountModels">
          <dashboard-tracking-account [accountGrp]="accountGrp">
          </dashboard-tracking-account>
        </div> 
      </div> 
    </div>
  </panel-box> 
</div>