



      <mat-table #table [dataSource]="dataSource">
        <ng-content></ng-content>
         <!-- <ng-container *ngFor="let col of displayedColumns"  [cdkColumnDef]="col">
          <mat-header-cell *cdkHeaderCellDef> {{col}} </mat-header-cell>
          <mat-cell *cdkCellDef="let row"> {{row[col]}} </mat-cell>
        </ng-container> -->

      </mat-table>

