
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { ObjectsListResult } from '../services/objects-list-results';
import { CampaignAttached } from './campaign-attached/campaign-attached';

@Injectable()

export class CampaignAttachedsData {

    private url = 'api/campaignAttacheds';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }


    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }


    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }


    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(campaignId: number) {
        return this.toraxHttp.get(`${this.url}/add/${campaignId || 0}`);
    }

    update(campaignAttached: CampaignAttached) {
        return this.toraxHttp.update(this.url, campaignAttached);
    }

    // setDefaultCampaignAttachedID(contactID: number, campaignAttachedID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/setDefaultCampaignAttachedID?ContactID=${contactID}&CampaignAttachedID=${campaignAttachedID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;

    // }

    setCampaignAttachedPriority(contactID: number, prevCampaignAttachedID: number, curCampaignAttachedID: number, newCurPriority: number) {
        let ret = this.toraxHttp.postWithResponce(`${this.url}/setCampaignAttachedPriority?contactID=${contactID}&prevCampaignAttachedID=${prevCampaignAttachedID}&curCampaignAttachedID=${curCampaignAttachedID}&newCurPriority=${newCurPriority}`, null);
        // this.onUpdated(contactID);
        return ret;
    }

    // removeDefaultCampaignAttachedID(contactID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/removeDefaultCampaignAttachedID?ContactID=${contactID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;
    // }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();

    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

