
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { CampaignsData } from './campaigns.data';

import { Component, OnInit, ViewChild, Input, } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';


@Component({
	selector: 'campaigns-list',
	templateUrl: 'campaigns-list.tmpl.html'
})

export class CampaignsList implements OnInit {
	constructor(
		private campaignsData: CampaignsData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private router: Router,
	) {
	}

	displayedColumns: string[] = ['buttons', 'campaignName', 'destination', 'visibleDestination', 'campaignStartDate'];
	data: any[] = [];

	resultsLength = 0;
	isRateLimitReached = false;

	@ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	searchCampaignsListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;

	public clerFilter() {
		this.initForm();
		this.getCampaigns();
	}

	addNew() {
		this.router.navigate([{ outlets: { popup: ['add'] } }]);
	}

	openSelectedRow(campaignID) {
		this.router.navigate(['campaigns', campaignID]);
	}


	private getCampaigns() {
		this.searchCampaignsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);

		sessionStorage.setItem(this.router.url, JSON.stringify(this.searchCampaignsListForm.value));

		return this.campaignsData!.getList(this.searchCampaignsListForm.value)
			.subscribe((data: any) => {
				this.data = data;
				this.sourceList = data.list;
				this.page = data.list.length;
				this.pageRows = data.list.length;
				this.rowsCount = data.rowsCount;


				this.isRateLimitReached = false;

			});
	}

	private initForm() {
		this.searchCampaignsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			campaignName: [],
			campaignNameEn: [],
			selectedRows:[],
			orders: this.formBuilder.array([])
		});


	}


	panelOpenState;
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;
	ngOnInit() {
		this.initForm();
		let item = sessionStorage.getItem(this.router.url);
		if (item) {
			let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
			this.searchCampaignsListForm.patchValue(frm);
		}

		this.getCampaigns();
	}
	selectionChanged(e) {
		this.getCampaigns();
	}

	pageChange(e) {
		this.searchCampaignsListForm.controls['pageRows'].setValue(e.pageSize || 20);
		this.selectionChanged(e);
	}
	sortData(e) {
		this.addOrder(e.active, e.direction != "asc");
		this.getCampaigns();
	}
	public search() {
	}
	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchCampaignsListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
	}
	pageEvent: PageEvent;
	pageSizeOptions;
	setPageSizeOptions(setPageSizeOptionsInput: string) {
		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}
	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}
	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchCampaignsListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}
	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}
}