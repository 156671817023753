﻿<div [formGroup]="searchTransferTasksListForm">
  <div *ngIf="!contactID" class="example-container" style="background-color: white">
    <div class="column">
      <div fxLayout="row" class="scrolling-wrapper">
        <auto-complete apply-enter [placeholder]="'שם איש...'" (onSelect)="indexService.selectionChanged($event)"
          [controllerName]="'Contacts'" [formGroupModel]="searchTransferTasksListForm"
          [idControl]="searchTransferTasksListForm.controls.contactID"
          [nameControl]="searchTransferTasksListForm.controls.contactName"></auto-complete>
        &nbsp;&nbsp;
        <div class="input-group mb-3">
          <label>סטטוס...</label>
          <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)" placeholder="סטטוס..."
            formControlName="hasDone">
            <mat-option [value]="null">הכל</mat-option>
            <mat-option [value]="true">בוצע</mat-option>
            <mat-option [value]="false">לא-בוצע</mat-option>
          </mat-select>
        </div>
        &nbsp;&nbsp;
        <date-input-range [placeHolderStart]="'החל מתאריך:'" [placeHolderEnd]="'עד לתאריך:'"
          [dateRangeCtrl]="searchTransferTasksListForm.controls.valueDateRange">Loading...
        </date-input-range>

        <div style="margin: 20px 20px 0px; text-align:left; white-space: nowrap;" class="example-button-row">
          <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן"
            (click)="indexService.selectionChanged($event)">
            <i class="fa fa-filter"></i></button>
          <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים"
            (click)="indexService.clerFilter()">
            <i class="material-icons">clear</i></button>
          <button style="margin: 3px;" mat-mini-fab color="primary" title="ייצוא לאקסל" (click)="exportExcel()">
            <i class="fa fa-file-excel-o"></i></button>
        </div>

      </div>
    </div>
  </div>

  <div class="example-container" *ngIf="indexService">
    <cdk-virtual-scroll-viewport testCDK [full-height]="true" #test (cdkObserveContent)="indexService?.changed($event)"
      itemSize="10" style="height:calc(100vh - 230px)">
      <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
        [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
        matSort matSortDisableClear matSortDirection="asc">


        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a mat-menu-item (click)="conseContact(row);" *ngIf="!row.paymentID">
                <mat-icon>edit</mat-icon>
                <span>צור תשלום</span>
              </a>
              <a mat-menu-item>
                <mat-icon>information</mat-icon>
                <model-info style="padding: 4px" [dataProvider]="transferTasksData" [id]="row.transferTaskID"
                  [title]="'מידע רשומה'">
                </model-info>
              </a>
            </mat-menu>


          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> שם
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="שם">
            {{row.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="valueDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך-ערך
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תאריך-ערך">
            {{ row.valueDate | date:'dd/MM/yyyy' }}<br>{{ row.jewishValueDate }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="bankNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> בנק
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="בנק">
            {{row.bankNumber}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="bankName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> שם בנק
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="שם בנק">
            {{row.bankName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="branchNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> סניף
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סניף">
            {{row.branchNumber}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header> סכום
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סכום">
            {{row.amount | number}} {{ row.currencyIcon }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>סוג
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סוג">
            {{row.type}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="asmachta">
          <mat-header-cell *matHeaderCellDef mat-sort-header> אסמכתא
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="אסמכתא">
            {{row.asmachta}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
          <mat-header-cell *matHeaderCellDef mat-sort-header> הערה
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="הערה">
            {{row.comment}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="contactName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> שם איש </mat-header-cell>
          <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">
            <a [routerLink]="[ '/contacts', row.contactID]">{{ row.contactName }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="paymentID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> תשלום
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תשלום">
            <a [routerLink]="[ '', {outlets: {popup: [ 'payments', row.paymentID]}}]">
              {{ row.paymentID }}
            </a>
          </mat-cell>
        </ng-container>



        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>
    </cdk-virtual-scroll-viewport>
  </div>

  <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
    [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
  </mat-paginator>
</div>
