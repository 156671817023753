import { Campaign } from './campaign/campaign';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { ObjectsListResult } from '../services/objects-list-results';

@Injectable()

export class CampaignsData {

    private url = 'api/campaigns';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }


    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }


    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(campaignId: number) {
        return this.toraxHttp.get(`${this.url}/add/${campaignId || 0}`);
    }

    update(campaign: Campaign) {
        return this.toraxHttp.update(this.url, campaign);
    }

    // setDefaultCampaignID(contactID: number, campaignID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/setDefaultCampaignID?ContactID=${contactID}&CampaignID=${campaignID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;

    // }

    setCampaignPriority(contactID: number, prevCampaignID: number, curCampaignID: number, newCurPriority: number) {
        let ret = this.toraxHttp.postWithResponce(`${this.url}/setCampaignPriority?contactID=${contactID}&prevCampaignID=${prevCampaignID}&curCampaignID=${curCampaignID}&newCurPriority=${newCurPriority}`, null);
        // this.onUpdated(contactID);
        return ret;
    }

    // removeDefaultCampaignID(contactID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/removeDefaultCampaignID?ContactID=${contactID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;
    // }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();

    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

