
import { CollectionBuildersData } from './collection-builders.data';
import { Component, OnInit } from '@angular/core';
import { ToraxHttp } from '../utils/custom-http.svc';
import { CollectionBuilder } from './collection-builder/collection-builder';


@Component({
	selector: 'collection-builders-small-list',
	templateUrl: 'collection-builders-small-list.tmpl.html',
	styleUrls:['./collection-builders.scss']
})

export class CollectionBuildersSmallList implements OnInit {
	constructor(
		private collectionBuildersData: CollectionBuildersData,
	) {
	}

	list: CollectionBuilder[];
  topWaitingsList: any[];
	ngOnInit() {

		this.collectionBuildersData.topCollectionBuilders().subscribe((x: any[]) => {
			this.list = x;
		});

		this.collectionBuildersData.topWaitingPayments().subscribe((x: any[]) => {
			this.topWaitingsList = x;
		});
	}
}
