import { ContactEventsData } from './../contactEvents.data';
import { ContactsData } from './../../contacts.data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ContactEvent } from './contactEvent';
import { ServicesData } from 'src/app/services/services.data';

@Component({
    selector: 'contactEvent-edit-dlg',
    templateUrl: './contactEvent-edit-dlg.tmpl.html'
})

export class ContactEventEditDlg implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ContactEventEditDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private contactEventsData: ContactEventsData,
        private formBuilder: FormBuilder,
        private servicesData: ServicesData
    ) {

    }
    @Input('id') public id: number;
    communicationType;
    contactEvent: ContactEvent;
    contactId: number;

    contactEventForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.contactEventsData.get(this.id || 0)
                .subscribe((p: any) => {
                    this.contactEvent = p;
                    this.fillForm(p);
                });
        }
        else if(this.contactEvent.contactID){
            this.contactEventsData.getAdd(this.contactEvent.contactID)
                .subscribe((p: any) => {
                    this.contactEvent = p;
                    this.fillForm(this.contactEvent);
                });
        }
    }

    private fillForm(p: ContactEvent): void {
        this.contactEventForm.patchValue(p);
    }

    private initForm() {
        this.contactEventForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            contactEventID: [, Validators.required],
            contactID: [this.contactId, Validators.required],
            contactName:[],
            yarzeitID:[],
            repeatsEveryYear:[false, Validators.required],
            contactEventType:[,Validators.required],
            description:[,Validators.required],
            fromDateTime: [new Date(), Validators.required],

        });
    }

    delete(){}

    ngOnInit() {
        // this.activatedRoute.params.forEach((params: Params) => {
        //     this.id = +params['contactEventID'] || 0;
        //     this.contactId = +params['contactID'];
        // });
        this.id = this.data.contactEvent.contactEventID;
        this.contactId = this.data.contactEvent.contactID;
        this.contactEvent = this.data.contactEvent;
        this.initForm();
        this.load();
    }
}



