<fieldset  class="fieldset" *ngIf="paymentForm.controls.chicDetails">
<legend> {{(paymentForm.controls.chicDetails.controls.length > 1 ? 'פרטי שיקים' : 'פרטי שיק' )}}
</legend>
<div [formGroup]="paymentForm" style="margin: 0 auto;">
  <table class="table table-border">
    <thead class="text-nowrap">
      <th>סכום</th>
      <th>תאריך שיק</th>
      <th>מס' בנק</th>
      <th>מס' סניף</th>
      <th>מס' חשבון</th>
      <th>מס' שיק</th>
      <th>שם שיק</th>
      <th>תאריך הפקדה</th>
      <th>סטטוס</th>
      <th>...</th>

    </thead>
    <tbody>
      <ng-container formArrayName="chicDetails" *ngFor="let group of getFormControls.controls ; let i=index">
        <tr [formGroupName]="i">
          <td>
            <input class="form-control narrow" formControlName="amount" apply-enter />
          </td>
           <td>
            <date-input apply-enter  [withJewishDate]="false" [withLabel]="false" [placeholder]="'תאריך שיק:'" [dateControl]="group.controls.checkDate">Loading...</date-input>
          </td>
          <td>
            <input class="form-control narrow" formControlName="bankNumber" apply-enter />
          </td>
          <td>
            <input class="form-control narrow" formControlName="bankBranchNumber" apply-enter />
          </td>
          <td>
            <input class="form-control narrow" formControlName="bankAcountNumber" apply-enter />
          </td>
          <td>
            <input class="form-control narrow" formControlName="chicNumber" apply-enter />
          </td>
          <td>
            <input class="form-control" formControlName="nameOfChic" apply-enter />
          </td>
          <td>
            <date-input apply-enter  [withJewishDate]="false" [withLabel]="false"  [placeholder]="'תאריך הפקדה'" [dateControl]="paymentForm.controls.doneOn" >Loading...</date-input>
          </td>
          <td>
            <mat-select apply-enter class="form-control" placeholder="סטטוס"
            formControlName="statusPayment">
            <mat-option *ngFor="let cs of servicesData.StatusPayments" [value]="cs.value">
                {{cs.name}}</mat-option>
        </mat-select>

      </td>
          <td>
            <mat-icon class="delete" (click)="deleteRow(i)">delete_forever</mat-icon>
            <!-- <mat-icon class="done" (click)="doneRow(group)">done</mat-icon> -->
          </td>
        </tr>

      </ng-container>
    </tbody>
  </table>
  <div class="action-container">
    <button style="color: blue;" matTooltip="לחץ כדי להוסיף שיק לסידרה" apply-enter color="primary"  (click)="addRow()"><i class="fa fa-plus"></i></button>
  </div>
</div>
</fieldset>
