import { ObjectsListResult } from './../services/objects-list-results';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { IvrDonationUserTasksData } from './ivr-donation-user-tasks.data';

import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { IndexService } from '../services/index-service';

@Component({
  selector: 'ivr-donation-user-tasks-list',
  templateUrl: 'ivr-donation-user-tasks-list.tmpl.html',
})

export class ivrDonationUserTasksList implements OnInit  {
  constructor(
    public ivrDonationUserTasksData: IvrDonationUserTasksData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private cdref: ChangeDetectorRef) {
  }

  displayedColumns: string[] = ['buttons', 'createDate', 'contactName', 'amount', 'doneByUserName', 'doneDate', 'comment'];
  searchIvrDonationUserTasksListForm: FormGroup;
  defaultvalues;
  stopLoadingPoints: boolean = false;
  groupingColumn = '';
  groupingOrderColumn = '';
  indexService: IndexService;

  public selection = new SelectionModel<number>(true, []);
  @Output() selectedRowsChange = new EventEmitter<any>();

  openSelectedRow(ivrDonationUserTaskID) {
    this.router.navigate(['ivrDonationUserTasks', ivrDonationUserTaskID]);
  }

  private initForm() {
    this.searchIvrDonationUserTasksListForm = this.formBuilder.group({
      page: [1],
      pageRows: [30],
      query: [],
      contactID: [],
      hasDone: [false],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

      this.selection.changed.subscribe(x => {
      this.searchIvrDonationUserTasksListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.ivrDonationUserTaskID));
      this.selectedRowsChange.emit(x.source.selected);
    });



    let frm = this.searchIvrDonationUserTasksListForm;
    this.defaultvalues = Object.keys(this.searchIvrDonationUserTasksListForm.value)
      .filter(key => frm.controls[key].validator == Validators.required)
      .reduce((obj, key) => {
        obj[key] = frm.controls[key].value;
        return obj;
      }, {});
  }



  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.ivrDonationUserTaskID === row.ivrDonationUserTaskID);
    if (found) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();


    //this.ivrDonationUserTasksData.initForm();
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchIvrDonationUserTasksListForm.patchValue(frm);
    }

    this.searchIvrDonationUserTasksListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
    ];




    this.indexService = new IndexService(this.searchIvrDonationUserTasksListForm, params, this.ivrDonationUserTasksData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);

    // הגרות פוקוס לקו אמצע
    // this.topOpenings();
    // this.indexService.OnLoaddedEnd.subscribe(x => this.setFocus(x));

    this.indexService.getList();
  }
}
