<form class="example-form" [formGroup]="formData" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="יציאה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      <small>דו"ח תוכניות תשלום רשימות אנ"ש</small>
    </h2>
  </div>
  <mat-dialog-content *ngIf="formData" style="padding:0px 24px 24px 24px">
    <div fxLayout="column" style="display: block;">
      <label class="form-row" fxLayout="row" style="align-self: end; color: blue;">
        שים לב!<br>
        כאשר לא יבחר סינון ברמת התוכניות או תשלומים<br>יציג את כל האנשים מאותה הקבוצה (-עיר/שטיבל)
      </label>
      <div class="form-row" fxLayout="row">
        <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
          [listControl]="formData.controls.commitmentSubjects" [placeholder]="'נושא...'"
          [list]="servicesData.CommitmentSubjects">
        </mat-select-multiple-dropdown>
      </div>
      <div class="form-row" fxLayout="row">
        <div>
          <mat-select-multiple-dropdown [keyProperty]="'id'" [titleProperty]="'title'" *ngIf="servicesData.Cities"
            [listControl]="formData.controls.cityIDs" [placeholder]="'עיר...'" [list]="servicesData.Cities">
          </mat-select-multiple-dropdown>
        </div>

        &nbsp;&nbsp;
        <auto-complete apply-enter placeholder="שטיבל..." #shtibelID [controllerName]="'Shtibels'"
          [formGroupModel]="formData" [idControl]="formData.controls.shtibelID"
          [nameControl]="formData.controls.shtibelName"></auto-complete>
          &nbsp;&nbsp;
          <div class="input-group mb-3">
            <label>שיוך לשטיבל...</label>
            <mat-select class="form-control"  [disabled]="formData.controls.shtibelID.value != null"
              placeholder="שיוך לשטיבל..." formControlName="friendsInStiebel">
              <mat-option [value]="">כולם</mat-option>
              <mat-option [value]="true">חברים בשטיבל</mat-option>
              <mat-option [value]="false">ללא שטיבל</mat-option>
            </mat-select>
          </div> &nbsp;&nbsp;
        <div class="input-group mb-3" style="white-space: nowrap;">
          <label>מיון לשטיבלך:</label>
          <mat-checkbox fxLayout="row" class="example-margin" formControlName="orderByShtibel">
          </mat-checkbox>
        </div>
      </div>
      <div class="form-row" fxLayout="row">
        <date-input-range [placeHolderStart]="'החל מתאריך:'" [placeHolderEnd]="'עד לתאריך:'"
          [dateRangeCtrl]="formData.controls.fromDateRange">Loading...
        </date-input-range>
      </div>
      <div class="form-row" fxLayout="row">
        <amount-input-range [placeHolderMin]="'מסכום חו\'...'" [placeHolderMax]="'עד ל...'"
          [amountRangeCtrl]="formData.controls.pulseAmountRange">Loading...
        </amount-input-range>
        &nbsp;&nbsp;
        <div class="input-group mb-3">
          <label>תאריך סיום...</label>
          <mat-select class="form-control" placeholder="תאריך סיום..." formControlName="withTillDate">
            <mat-option [value]="">הכל</mat-option>
            <mat-option [value]="true">כולל</mat-option>
            <mat-option [value]="false">לא כולל</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="form-row" fxLayout="row">

        <div class="input-group mb-3">
          <label>תשלומים לטיפול...</label>
          <mat-select class="form-control" placeholder="תשלומים לטיפול..." formControlName="withPaymentsForTreatment">
            <mat-option [value]="">הכל</mat-option>
            <mat-option [value]="true">רק לטיפול</mat-option>
            <mat-option [value]="false">רק לא לטיפול</mat-option>
          </mat-select>
        </div>

        &nbsp;&nbsp;
        <div class="input-group mb-3">
          <label>תוכניות פעילות...</label>
          <mat-select class="form-control" placeholder="תוכניות פעילות..." formControlName="activePaymentsPlans">
            <mat-option [value]="">הכל</mat-option>
            <mat-option [value]="true">רק פעילים</mat-option>
            <mat-option [value]="false">רק לא פעילים</mat-option>
          </mat-select>
        </div>

        &nbsp;&nbsp;
        <div  class="input-group mb-3">
          <label></label>
          <input apply-enter type="text" class="form-control" formControlName="query">
      </div>

      </div>



    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <button apply-enter [disabled]="formData.invalid" class="btn btn-default navbar-btn" (click)="exportExcel()"><i
        class="fa fa-save"></i> אקסל</button>
    <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn" (click)="exportPdf()"><i
        class="fa fa-save"></i> PDF </button>
  </mat-dialog-actions>

</form>
