 
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router'; 
import { CampaignAttachedsData } from '../campaign-attacheds.data';
import { CampaignAttached } from './campaign-attached';

@Component({
	
	selector: 'campaign-attached-details',
	templateUrl: './campaign-attached-details.tmpl.html' 
})

export class CampaignAttachedDetails implements OnInit {  
	constructor(
		private campaignAttachedsData: CampaignAttachedsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {  
			if (id > 0 && this.campaignAttached && id === this.campaignAttached.campaignAttachedID) {
				this.loadCampaignAttached();
			}
		});
	}

	public campaignAttached: CampaignAttached;
	private id: number;

 

	public loadCampaignAttached(): void {
		this.campaignAttachedsData.get(this.id)
			.subscribe((c:any) => this.campaignAttached = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['campaignAttachedID'];
			this.loadCampaignAttached();
		});
	}
}
