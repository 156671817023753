import { ContactGroupsData } from './../contactGroups.data';
import { ContactsData } from './../../contacts.data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ContactGroup } from './contactGroup';
import { ServicesData } from 'src/app/services/services.data';

@Component({
  selector: 'contactGroup-edit-dlg',
  templateUrl: './contactGroup-edit-dlg.tmpl.html'
})

export class ContactGroupEditDlg implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ContactGroupEditDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contactGroupsData: ContactGroupsData,
    private formBuilder: FormBuilder,
    private servicesData: ServicesData
  ) {

  }
  @Input('id') public id: number;
  communicationType;
  contactGroup: ContactGroup;
  contactId: number;

  contactGroupForm: FormGroup;

  public contactGroupStatuses: any[];

  public load(): void {
    if (this.id > 0) {
      this.contactGroupsData.get(this.id || 0)
        .subscribe((p: any) => {
          this.contactGroup = p;
          this.fillForm(p);
        });
    }
    else if (this.contactGroup.contactID) {
      this.contactGroupsData.getAdd(this.contactGroup.contactID)
        .subscribe((p: any) => {
          this.contactGroup = p;
          this.fillForm(this.contactGroup);
        });
    }
  }

  private fillForm(p: ContactGroup): void {
    this.contactGroupForm.patchValue(p);
  }

  private initForm() {
    this.contactGroupForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      contactGroupID: [, Validators.required],
      contactID: [this.contactId, Validators.required],
      groupID: [, Validators.required],
      contactGroupStatusID: [],
      priority: [1, Validators.required],
      comment: [],
      destinationContactAmount:[],
      commitmentID: [],
      commitmentTitle: [],
      deleted: [false, Validators.required]
    });

    this.contactGroupForm.controls['groupID'].valueChanges.subscribe(x => {

      this.servicesData.getList(`ContactGroupStatuses/${x}`)
        .subscribe(
          (c: any) => {
            this.contactGroupStatuses = c;
          },
          (x) => {
            //console.log("xxxxxxxx", x);
          });
    });
  }

  loadContactGroupStatuses() {
    //   let gId = this.contactGroupForm.controls['groupID'].value;
    //  this.servicesData.getList(`ContactGroupStatuses/${gId}`)
    //      .subscribe(
    //          (c: any) => {
    //              this.contactGroupStatuses = c;
    //          },
    //          (x) => {
    //              //console.log("xxxxxxxx", x);
    //          });
  }
  delete() {
    this.contactGroupForm.controls['deleted'].setValue(true);
  }

  ngOnInit() {
    // this.activatedRoute.params.forEach((params: Params) => {
    //     this.id = +params['contactGroupID'] || 0;
    //     this.contactId = +params['contactID'];
    // });
    this.id = this.data.contactGroup.contactGroupID;
    this.contactId = this.data.contactGroup.contactID;
    this.contactGroup = this.data.contactGroup;
    this.initForm();
    this.load();
    this.loadContactGroupStatuses();
  }

}



