import { LetterSettingMain } from './letter-setting/letter-setting-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';

import { LetterSettingsList } from './letter-settings-list.cmp'; 
 
export const LetterSettingsRoutes: Routes = [
    
        { canActivate: [AuthGuard], path: 'letterSettings', component: LetterSettingsList, data: {  roles: "letterSettings",  title: 'תבניות מכתבים' } },
        { canActivate: [AuthGuard], path: 'letterSettings/add', component: LetterSettingMain, data: { mode: 'edit', roles: "letterSettings", title: 'הוסף תבנית' }, outlet: 'popup' },           
        { canActivate: [AuthGuard], path: 'letterSettings/:letterSettingID', component: LetterSettingMain, data: {  roles: "letterSettings",  title: 'תבנית מכתב' } , outlet: 'popup'  },
        { canActivate: [AuthGuard], path: 'letterSettings/:letterSettingID/edit', component: LetterSettingMain, data: { mode: 'edit', roles: "letterSettings",  title: 'עריכת תבנית ' }, outlet: 'popup' },
    
];

