import { City } from './city/city';
import { ToraxHttp } from './../utils/custom-http.svc';
import { ObjectsListResult } from './../services/objects-list-results';
import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from "rxjs";

@Injectable()

export class CitiesData {
    private url = 'api/cities';


    constructor(
        private toraxHttp: ToraxHttp
    ) { }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    createAndPrintCityLetter(cityID: number) {
        return this.toraxHttp.get (`${this.url}/createAndPrintCityLetter?cityID=${cityID}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(city: City) {
        return this.toraxHttp.update(this.url, city);
    }

    getAdd(contactId: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}
