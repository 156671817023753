import { MaterialModule } from './../../material.module';
import { DirectivesMdl } from './../../components/directives/directives-module';
import { DateInputModule } from './../../components/dateInput/date-input.mdl';
import { ModelInfoModule } from './../../components/model-info/model-info.mdl';
import { FooterEditButtonsMdl } from './../../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../../components/pagination/pagination.mdl';
import { PanelBoxModule } from './../../components/panel-box/panel-box.mdl';
import { PageHeadingModule } from './../../components/page-heading/page-heading.mdl';
import { ContactEventEditDlg } from './contactEvent/contactEvent-edit-dlg.cmp';

import { ContactEventsList } from './contactEvents-list.cmp';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactEventsData } from './contactEvents.data';
//import { TextMaskModule } from 'angular2-text-mask';
import { ContactEventDetails } from './contactEvent/contactEvent-details.cmp';
import { ContactEventYarzeitDetails } from './contactEvent/contact-event-yarzeit/contact-event-yarzeits-details.cmp';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,
        MaterialModule,
        ModelInfoModule,
        DateInputModule,
        DirectivesMdl,
    ],
    declarations: [
        ContactEventsList,
        ContactEventDetails,
        ContactEventEditDlg,
        ContactEventYarzeitDetails
    ],
    exports: [
        ContactEventsList


    ],
    providers: [
        ContactEventsData
    ],
    entryComponents: [
        ContactEventEditDlg,
    ]
})
export class ContactEventsMdl { }



