<div class="example-form" *ngIf="ivrDonationUserTaskForm" [formGroup]="ivrDonationUserTaskForm" enter-tab>
    <div style="padding: 0 15px;">
        <div class="form-group">
            
            <form  [formGroup]="ivrDonationUserTaskForm" novalidate (ngSubmit)="save()" *ngIf="ivrDonationUserTask">
    <div class="modal-header">
        <h1>עריכת פרטי שיחת טלפון [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">
                <panel-box title="פרטי שיחת טלפון" [showClose]="false" [showCollapse]="false">
                    <span class="panel-tools"></span>
                    <div class="panel-content">
                        <div class="form-horizontal">
                            <!-- <div class="form-group">
                                <label class="control-label col-sm-2">שם מתקשר:</label>
                                <div class="col-sm-10">
                                    <h3> {{ ivrDonationUserTask.contactName }}</h3>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </panel-box>
            </div>
        </div>
    </div>
    <footer-edit-buttons [onSaveGoToBack]="true" [dataProvider]="ivrDonationUserTasksData" [router]="router" [id]="id" [disabledDeleteButton]="false"
        [modelFormGroup]="ivrDonationUserTaskForm"></footer-edit-buttons>
</form>