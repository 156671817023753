import { ToraxHttp } from './../utils/custom-http.svc';
import { ObjectsListResult } from './../services/objects-list-results';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { HonorType } from './honor-type/honor-type';

@Injectable()

export class HonorTypesData {
    private url = 'api/honorTypes';


    constructor(
        private toraxHttp: ToraxHttp
    ) { }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(honorType: HonorType) {
        return this.toraxHttp.update(this.url, honorType);
    }

    // getAdd() {
    //     return this.toraxHttp.get(`${this.url}/add`);
    // }


  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}
