import { MatDialog } from '@angular/material/dialog';
import { Component, Input, EventEmitter, Output } from '@angular/core';

import { Phone } from './phone';

@Component({
    selector: 'phone-details',
    templateUrl: './phone-details.tmpl.html'
})

export class PhoneDetails {

    constructor( 
        public dialog: MatDialog
    ) {

    }
    @Output() phoneChange = new EventEmitter<number>();
    @Output() onEditClick = new EventEmitter<number>();
    @Input('phone') public phone: Phone;

    editPhone(p) {
        this.onEditClick.emit(p);
    }
 
}



