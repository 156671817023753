<div class="input-group md-3" style="width: inherit;" [ngClass]="{'has-error': commitmentIdControl.invalid }">
    <label *ngIf="withLabel">{{placeHolder}}:</label>
    <div *ngIf="commitments" style="display: flex;">
        <mat-select #commitmentsSelect apply-enter style="width: 100% !IMPORTANT;" panelClass="panel-selection-width"
            class="form-control" (selectionChange)="commitmentSelectEvent($event.value)"
            [formControl]="commitmentIdControl" [placeholder]="placeHolder">
            <mat-option *ngIf="!commitmentsSelect.panelOpen" value="">...</mat-option>
            <mat-option *ngIf="commitmentsSelect.panelOpen"
                style="border-bottom: solid; border-bottom-width:1px; font-weight: 500;" value="">
                <div fxFlex="100" fxLayout="row" style="white-space: nowrap">
                    <span fxFlex="10" fxLayout="column">מזהה</span>
                    <span fxFlex="75" fxLayout="column">תיאור</span>
                    <span fxFlex="20" fxLayout="column">מתאריך</span>
                    <span fxFlex="10" fxLayout="column">סכום</span>
                    <span fxFlex="0 40" fxLayout="column"></span>
                    <span fxFlex="5" fxLayout="column">...</span>
                </div>
            </mat-option>
            <mat-option style="white-space: nowrap;" *ngFor="let commitment of commitments"
                [value]="commitment.commitmentID" fxLayout="row">
                <div fxFlex="100" fxLayout="row" style="white-space: nowrap">
                    <!-- <div fxFlex="20" fxLayout="column">{{ commitment.contactName }}</div> -->
                    <span style="padding-left: 10px;" fxFlex="10" fxLayout="column">
                        {{ commitment.commitmentID }}
                    </span>
                    <span style="padding-left: 10px;" fxFlex="75" fxLayout="column">
                        {{ commitment.subjectName }}{{(commitment.commitmentReasonName ? ',' : '')}}
                        {{ commitment.commitmentReasonName}} - {{commitment.donationTypeName}} {{ commitment.hebYear }}
                    </span>
                    <span style="padding-left: 10px;" fxFlex="20" fxLayout="column">
                        {{commitment.fromDate | date:'dd/MM/yyyy' }} </span>
                    <span style="padding-left: 10px;" fxFlex="10" fxLayout="column" *ngIf="commitment.globalAmount">
                        {{commitment.globalAmount | number }} {{ commitment.currencyIcon }}</span>
                    <span style="padding-left: 10px;" fxFlex="10" fxLayout="column" *ngIf="commitment.pulseAmount">
                        {{commitment.pulseAmount | number }} {{ commitment.currencyIcon }} חודשי</span>
                    <span style="padding-left: 10px;" *ngIf="commitment.registrationEventTitle" fxFlex="0 20"
                        fxLayout="column">
                        {{commitment.registrationEventTitle}} </span>
                    <span style="padding-left: 10px;" *ngIf="commitment.honorTitle" fxFlex="0 20" fxLayout="column">
                        {{ commitment.honorTitle }}
                    </span>
                    <span fxFlex="0 40" fxLayout="column"></span>
                    <div fxFlex="5" fxLayout="column">
                        <commitment-add-action [commitment]="commitment" [contactID]="contactID"
                            (commitmentChange)="commitmentChangeEvent($event)">
                        </commitment-add-action>
                    </div>
                </div>
            </mat-option>
        </mat-select>
        <commitment-add-action [contactID]="contactID" (commitmentChange)="commitmentChangeEvent($event)">
        </commitment-add-action>
    </div>
</div>