import { NgModule }         from '@angular/core';
import { BrowserModule }    from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ToraxHttp } from './custom-http.svc';

@NgModule({
    imports: [
        HttpClientModule,
    ],
    exports: [

    ],
    declarations: [

    ],
    providers: [
        ToraxHttp
    ],
})
export class UtilsModule { }
