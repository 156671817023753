import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { VouchersOrgPaymentPacksData } from './vouchersOrgPaymentPacks.data';

import { Component, OnInit, ViewChild, Input, } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';

import { ToraxHttp } from '../utils/custom-http.svc';


@Component({
	selector: 'vouchersOrgPaymentPacks-list',
	templateUrl: 'vouchersOrgPaymentPacks-list.tmpl.html'
})

export class VouchersOrgPaymentPacksList implements OnInit {
	constructor(
		private vouchersOrgPaymentPacksData: VouchersOrgPaymentPacksData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private router: Router,
		public _matDialog: MatDialog,
		private toraxHttp: ToraxHttp
	) {
	}

	displayedColumns: string[] = ['vouchersOrgPaymentPackID', 'amount', 'billingDate', 'doneOn', 'payMethodID'];
	data: any[] = [];

	resultsLength = 0;
	isRateLimitReached = false;

	@ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@Input('vouchersOrganizationID') public vouchersOrganizationID?: number | null = null;
	@ViewChild('account') account: MatSelect


	searchVouchersOrgPaymentPacksListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;

	public clerFilter() {
		this.initForm();
		this.getVouchersOrgPaymentPacks();
	}

	addNew() {
		this.router.navigate([{ outlets: { popup: ['add'] } }]);
	}

	openSelectedRow(vouchersOrgPaymentPackID) {
		this.router.navigate(['', { outlets: { popup: ['vouchersOrgPaymentPacks', vouchersOrgPaymentPackID] } }]);
	}

	getVouchersOrgPaymentPacksReport(): void {
		this.vouchersOrgPaymentPacksData.exportExcel(this.searchVouchersOrgPaymentPacksListForm.value)
			.subscribe(blob => {
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `דוח יחידות תשלום.xlsx`;
				link.click();
			});
	}


	getVouchersOrgPaymentPacks() {
		this.searchVouchersOrgPaymentPacksListForm.controls['page'].setValue(this.paginator.pageIndex + 1);

		if (this.vouchersOrganizationID && this.vouchersOrganizationID > 0) {
			this.searchVouchersOrgPaymentPacksListForm.controls['vouchersOrganizationID'].setValue(this.vouchersOrganizationID);
		}

		sessionStorage.setItem(this.router.url, JSON.stringify(this.searchVouchersOrgPaymentPacksListForm.value));

		return this.vouchersOrgPaymentPacksData!.getList(this.searchVouchersOrgPaymentPacksListForm.value)
			.subscribe((data: any) => {
				this.data = data;
				this.sourceList = data.list;
				this.page = data.list.length;
				this.pageRows = data.list.length;
				this.rowsCount = data.rowsCount;


				this.isRateLimitReached = false;

			});
	}

	private initForm() {
		this.searchVouchersOrgPaymentPacksListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			vouchersOrgPaymentPacksForTreatment: [false],
			vouchersOrgPaymentPackOptions: [null],
			billingDateRange: [null],
			doneOnRange: [null],
			vouchersOrganizationID: [null],
			organizationName: [],
			voucherNumber: [null],
			accountLocations: [],
			selectedRows: [],
			orders: this.formBuilder.array([])
		});


	}


	panelOpenState;
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;
	ngOnInit() {
		this.initForm();

		let item = sessionStorage.getItem(this.router.url);
		if (item) {
			let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
			this.searchVouchersOrgPaymentPacksListForm.patchValue(frm);
		}
		this.getVouchersOrgPaymentPacks();
	}
	selectionChanged(e) {
		this.getVouchersOrgPaymentPacks();
	}

	pageChange(e) {
		this.searchVouchersOrgPaymentPacksListForm.controls['pageRows'].setValue(e.pageSize || 20);
		this.selectionChanged(e);
	}
	sortData(e) {
		this.addOrder(e.active, e.direction != "asc");
		this.getVouchersOrgPaymentPacks();
	}
	public search() {
	}
	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchVouchersOrgPaymentPacksListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
	}
	pageEvent: PageEvent;
	pageSizeOptions;
	setPageSizeOptions(setPageSizeOptionsInput: string) {
		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}
	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}
	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchVouchersOrgPaymentPacksListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}
	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}
}