<div class="row">
    <div class="col-lg-3" *ngFor="let grp of model.groupsToShowOnDashboard">
        <div class="widget style1 lazur-bg" [ngStyle]="{'background-color': grp.groupColor}">
            <div class="row">
                <div>
                    <a style="color: white;"
                        [routerLink]="['/contacts', 'groupContacts', grp.groupID, grp.groupSubjectsAsStr]">

                        <div class="col-xs-4"><i class="fa fa-group fa-5x"></i></div>
                        <div class="col-xs-8 text-right"><span>&nbsp;קבוצות</span>
                            <h2 class="font-bold">&nbsp;{{grp.groupName}}</h2>
                            <br>

                            <div class="text-right"
                                style="white-space: nowrap; overflow: overlay; background-color: transparent; border: none;">
                                <span *ngFor="let kvp of grp.groupSubjectsKvp" href="" class="btn btn-xs btn-primary">
                                    {{kvp.value}}</span>
                            </div>
                            <!-- <mat-chip-list>
                                <mat-chip  *ngFor="let kvp of grp.groupSubjectsKvp">
                                     <span>
                                        {{kvp.value}}
                                    </span>
                                </mat-chip>
                            </mat-chip-list> -->

                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>