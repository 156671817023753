<mat-card *ngIf="model" fxLayout="column" class="example-card">
    <mat-card-header fxLayout="row">
        <mat-card-title fxLayout="row">
            <h3>מעקב הרצת גביה אירוע מס' {{model.eventID}}</h3>
            <span class="example-fill-remaining-space"></span>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content style="padding: 0 !important;">
        <dl style="padding: 20px; white-space: nowrap;" class="dl-horizontal">
            <dt>רשומות</dt>
            <dd> {{model.currentIndex}}/{{model.count}}</dd>
            <hr>
            <dt>מזהה איש</dt>
            <dd> {{model.contactID}}</dd>
            <hr>
            <dt>סכום</dt>
            <dd> {{model.amount}}</dd>
            <hr>
            <dt>אמצעי תשלום</dt>  
            <dd> {{model.payMethodID}}</dd>
            <hr>
            <dt>סטטוס</dt>
            <dd> {{model.statusMessage}}</dd>
            <hr>
            <hr style="border: 3px solid;">
            <hr>
            <dt>סכום כולל שנגבה</dt>
            <dd> {{model.sumAmountSucceeded}}</dd>
            <hr>
            <dt>סכום כולל שנכשל</dt>
            <dd> {{model.sumAmountFails}}</dd>
            <hr>
        </dl>
        </mat-card-content>
    </mat-card> 