import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router'; 
import { ProcessesTypesData } from '../processes-types.data';
import { ProcessesType } from './processes-type';

@Component({
	
	selector: 'processes-type-details',
	templateUrl: './processes-type-details.tmpl.html'
})

export class ProcessesTypeDetails implements OnInit {
	constructor(
		private processesTypesData: ProcessesTypesData,
		private activatedRoute: ActivatedRoute
	) {
	}

	public processType: ProcessesType;
	private id: number;

	public loadProcess(): void {
		this.processesTypesData.get(this.id)
			.subscribe((c:any) => this.processType = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['processID'];
			this.loadProcess();
		});
	}
}
