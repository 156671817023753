﻿<div *ngIf="deposit">
  <div class="modal-header">
    <div class="row">
      <div style="padding: 0px;" class="col-sm-12">
        <div class="col-sm-5">
          <h1>
            [{{ deposit.depositID }}] {{ deposit.depositDate }}
          </h1>

        </div>
        <div class="col-sm-7 text-left">
          <!-- <button class="btn btn-outline btn-success  dim" type="button" style="font-weight: bold"
            *ngFor="let p of groupedDeposits" (click)="openAddOrEditDeposit(p)">
            צור הפקדה בסכום {{p.amount | number}} {{p.currencyIcon}}
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" style="resize: both; overflow: auto;" fxLayout="row" fxLayoutAlign="start start"
    fxLayoutGap="15px">
    <!-- <mat-tab-group style="overflow: hidden;  width: -webkit-fill-available;" [selectedIndex]="activeTabIndex"
      (selectedTabChange)="itemSelect($event)">
      <mat-tab #pendings>
        <ng-template mat-tab-label>
          תשלומים ממתינים להפקדה
        </ng-template>
        <payments-list *ngIf="activeTabIndex == 0" style="overflow: auto;"
          (selectedRowsChange)="selectedPaymentsRowsChange($event)"
          [depositID]="deposit.depositID" [statusPayments]="[1,3]">
          Loading...</payments-list>
      </mat-tab>
      <mat-tab #accepteds>
        <ng-template mat-tab-label>
          תשלומים שהופקדו
        </ng-template>
        <payments-list *ngIf="activeTabIndex == 1" style="overflow: auto;"
          (selectedRowsChange)="selectedPaymentsRowsChange($event)"
          [depositID]="deposit.depositID">Loading...</payments-list>
      </mat-tab>
    </mat-tab-group> -->

  </div>
</div>
