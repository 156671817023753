import { CitiesData } from './../cities.data';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { City } from './city';

@Component({
	
	selector: 'city-details',
	templateUrl: './city-details.tmpl.html'
})

export class CityDetails implements OnInit {
	constructor(
		private citiesData: CitiesData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.city && id === this.city.cityID) {
				this.loadCity();
			}
		});
	}

	public city: City;
	private id: number;


	public createAndPrintCityLetter(): void {
		this.citiesData.createAndPrintCityLetter(this.id)
			.subscribe(data => {
					let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
					popupWinindow.document.open();
					popupWinindow.document.write('<html><head><style type="text/css">.MsoNormal { margin-top: 0px; margin-bottom: 0px;} @page { size: letter; margin: 0; } @media print { } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
					popupWinindow.document.close();
			});
	};


	public loadCity(): void {
		this.citiesData.get(this.id)
			.subscribe((c:any) => this.city = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['cityID'];
			this.loadCity();
		});
	}
}
