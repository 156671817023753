<form *ngIf="formData" class="example-form" [formGroup]="formData" novalidate>
    <div matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>טיפול בתשלומים לאחר עריכת תוכנית תשלום</small>
        </h2>
    </div>
    <mat-dialog-content style="padding:0px 24px 24px 24px">
      שים לב, רשומות תשלומים שנערכו/שונו ישארו במקומם ולא ירועננו בהתאם לפרטי העריכה העדכניים
      עליך לעדכן אותם באופן פרטי לאחמ'כ
      האם להמשיך בכל זאת?
    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="end">
        <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn"
            [mat-dialog-close]="formData"><i class="fa fa-save"></i> שמור </button>
    </mat-dialog-actions>

</form>
