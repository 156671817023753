<div [formGroup]="formData" class="form-horizontal">

    <div class="form-group" [ngClass]="{'has-error': formData.controls.billingDate.invalid}">
        <label class="control-label col-sm-3">תאריך חיוב:</label>
        <div class="col-sm-9">
            <date-input [dateControl]="formData.controls.billingDate">Loading...</date-input>
        </div>
    </div>
    <div class="form-group" [ngClass]="{'has-error': formData.controls.makeAsSucceeded.invalid}">
        <label class="control-label col-sm-3">סימון תשלומים 'בהצלחה':</label>
        <div class="col-sm-9">
            <input type="checkbox" formControlName="makeAsSucceeded" />
        </div>
    </div>
</div>