import { AddressEditDlg } from './address/address-edit-dlg.cmp';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AlertService } from './../components/alert/alert.svc';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressesData } from './addresses.data';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Address } from './address/address';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'addresses-list',
	templateUrl: './addresses-list.tmpl.html'
})
export class AddressesList implements OnInit {


	constructor(
		private addressesData: AddressesData,
		private formBuilder: FormBuilder,
		private alertSvc: AlertService,
		public dialog: MatDialog
	) {
	}


	addresses: Address[];
	searchAddressesListForm: FormGroup;
//	tempNewEmail;
	viwHistory = false;
	@Input('contactID') public contactID: number;


	addressChangeEvent() {
		this.getAddresses();
	}

	

	public getAddresses(): void {
		this.searchAddressesListForm.controls['contactID'].setValue(this.contactID);
		this.addressesData!.getList(this.searchAddressesListForm.value)
			.subscribe((x: Address[]) => {
				this.addresses = x;
			}
			); 
	}




	loadAll() {
		this.viwHistory = !this.viwHistory;
		this.searchAddressesListForm.controls['isActive'].setValue(this.viwHistory);

		this.getAddresses();
	}

	private initForm() {
		this.searchAddressesListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			contactID: [this.contactID, Validators.required],
			selectedRows:[],
			orders: this.formBuilder.array([]),
			isActive: [true, Validators.required],
		});

	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(this.addresses, event.previousIndex, event.currentIndex);
			let p = event.item.data;
			if (p) {
				//יש להוסיף ערך 1 כדי לשמור ב-DB 
				let priorityToSave = (event.currentIndex + 1);
				let previousAddress = this.addresses[event.previousIndex];

				this.addressesData.setAddressPriority(p.contactID, previousAddress.addressID, p.addressID, priorityToSave).subscribe(x => {
					this.alertSvc.info((<any>x).value);
					this.getAddresses();

				});
			}
		}
	}


	ngOnInit(): void {		
		this.initForm();
		this.getAddresses();
	}



}