<form style="white-space: nowrap;" [formGroup]="formData" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      הדפסת רשומות החזקת ביהמ"ד
      <br>
      <span *ngIf="formData && shtibelMode">
        <small>עבור שטיבל:</small> {{formData.controls.shtibelName.value}}
      </span>
    </h2>
  </div>
  <mat-dialog-content style="padding:0px 24px 24px 24px">
    <div fxLayout="column" style="display: block;">
      <div *ngIf="!formData || !shtibelMode">
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <auto-complete apply-enter [placeholder]="'עיר'" [controllerName]="'Cities'" [formGroupModel]="formData"
          [idControl]="formData.controls.cityID" [nameControl]="formData.controls.cityName">
        </auto-complete>
        &nbsp;&nbsp;
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>שיוך לשטיבל...</label>
              <mat-select class="form-control" placeholder="שיוך לשטיבל..." formControlName="friendsInStiebel" [disabled]="formData.controls.shtibelID.value != null" >
                <mat-option [value]="">כולם</mat-option>
                <mat-option [value]="true">חברים בשטיבל</mat-option>
                <mat-option [value]="false">ללא שטיבל</mat-option>
              </mat-select>
            </div>
          </div>

        </div>
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <auto-complete apply-enter [placeholder]="'שטיבל'" [controllerName]="'Shtibels'" [formGroupModel]="formData"
              [idControl]="formData.controls.shtibelID" [nameControl]="formData.controls.shtibelName">
            </auto-complete>
            &nbsp;&nbsp;
             <auto-complete apply-enter [placeholder]="'אחראי'" [controllerName]="'Contacts'"
              [formGroupModel]="formData" [idControl]="formData.controls.responsibleID"
              [nameControl]="formData.controls.responsibleName">
            </auto-complete>
          </div>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <dateMonth-range [fromIsRequired]="true" [tillIsRequired]="true" [fromPlaceholder]="'החל מ...'"
          [tillPlaceholder]="'עד ל...'" [dateMonthRangeCtrl]="formData.controls.dateMonthsRange">
          Loading...</dateMonth-range>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3" style="width:140px;">
          <label>הודפס:</label>
          <select class="form-control" formControlName="isPrint">
            <option [value]="false">לא הודפס</option>
            <option [value]="true">הודפס</option>
            <!-- <option [value]="">הכל</option> -->
          </select>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3" style="width:140px;"> <label>סימון רשומות שהודפסו:</label>
          <select class="form-control" formControlName="markPrinted">
            <option [value]="null">...</option>
            <option [value]="true">כן</option>
            <option [value]="false">לא</option>
          </select>
        </div>
      </div>
      <mat-checkbox apply-enter fxLayout="row" formControlName="includeOldRecords">
        כלול חובות ישנים:
      </mat-checkbox>


      <div [formGroup]="formData">
        <h4 style="color:red" *ngIf="formData.controls.isPrint.value !== false">לצורך הדפסה סופית יש לכלול רק
          רשומות שלא הודפסו בעבר!</h4>
        <h4 style="color:red"
          *ngIf="formData.controls.markPrinted.value === true && formData.controls.isPrint.value  === true">
          שים לב! אתה מסמן להדפסה רשומות שכבר הודפסו בעבר</h4>



      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">

    <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn"
      (click)="printExludedHoldingsHtml(formData.value)"><i class="fa fa-print"></i> הדפס רשימת לא מוכללים </button>

    <button apply-enter [disabled]="formData.invalid" class="btn btn-info navbar-btn"
      (click)="printHoldingsHtml(formData.value)"><i class="fa fa-print"></i> הדפס רשימה </button>

  </mat-dialog-actions>
</form>
