import { CountryEditDlg } from './country/country-edit-dlg.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { CountriesList } from './countries-list.cmp';

export const CountriesRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'countries', pathMatch: 'full', component: CountriesList, data: { roles: "countries", title: 'רשימת מדינות' } },
    { canActivate: [AuthGuard], path: 'countries/add', component: CountryEditDlg, data: { mode: 'edit', roles: "countries", title: 'הוסף מדינה' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'countries/:countryID/edit', component: CountryEditDlg, data: { mode: 'edit', roles: "countries", title: 'עריכת פרטי מדינה' }, outlet: 'popup' }
];

