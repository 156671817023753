
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { EmergencySquadMembersData } from '../emergency-squad-members.data';
import { EmergencySquadMember } from './emergency-squad-member-queue';

@Component({

	selector: 'emergency-squad-member-details',
	templateUrl: './emergency-squad-member-details.tmpl.html'
})

export class EmergencySquadMemberDetails implements OnInit {
	constructor(
		private emergencySquadMembersData: EmergencySquadMembersData,
		private toraxHttp: ToraxHttp,
		private activatedRoute: ActivatedRoute
	) {

		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.emergencySquadMember && id === this.emergencySquadMember.emergencySquadMemberID) {
				this.loadEmergencySquadMember();
			}
		});
	}


	public emergencySquadMember: EmergencySquadMember;
	private id: number;


	public loadEmergencySquadMember(): void {
		this.emergencySquadMembersData.get(this.id)
			.subscribe((c:any) => this.emergencySquadMember = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['emergencySquadMemberID'];
			this.loadEmergencySquadMember();
		});
	}
}
