<div class="row" *ngIf="model">
    <div class="row">
        <div class="col-sm-12">
            <panel-box [showTitle]="false">
                <div class="row">
                    <div class="col-sm-8">
                        <h2>
                            {{ userName }}
                        </h2>
                    </div>

                </div>
            </panel-box>
        </div>
    </div>

    <div>
        <dashboard-panel-box title="תרומות אחרונות" [backgroundColor]="'#26c281'" (onSelect)="groupedHighPayments($event)" [showCollapse]="true">
            <div *ngFor="let group of model.groupedHighPayments">
                <div class="ibox-content ibox-heading" style="margin-bottom: 10px; padding-bottom: 30px; padding-top: 5px;">
                    <h4 class="pull-left">{{ group.doneOnJewishDate }}</h4>
                    <h4 class="pull-right">{{ group.doneOnDate | date:'dd/MM/yyyy' }}</h4>
                </div>
                <div class="feed-activity-list">
                    <div class="feed-element" *ngFor="let item of group.highPaymentsGroups">
                        <div class="pull-left">
                            <button (click)="openPaymentsView(item.paymentCommitmentsIds)" class="btn btn-primary btn-circle" type="button"><i class="fa fa-list"></i></button>
                        </div>
                        <div class="media-body">
                            <strong><a [routerLink]="['/contacts', item.contactID]">{{ item.firstName }} {{ item.lastName }}</a></strong>
                            <br> {{ item.shortAddressTitle }}
                            <br> {{ item.amount  | number}}{{ item.currencyIcon }}
                            <small *ngIf="item.paymentCommitmentsIds.length > 1"> [{{ item.paymentCommitmentsIds.length }} תשלומים]</small><br>
                            <small class="text-muted">{{ item.methodTitle }}</small>
                        </div>
                    </div>
                </div>

            </div>
        </dashboard-panel-box>
    </div>
    <div>
        <dashboard-panel-box title="התחייבויות לפני סיום תוקף" [backgroundColor]="'#578ebe'" (onSelect)="getCommitmentsAboutToExpire($event)" [showCollapse]="true">
            <div *ngFor="let group of model.grpCommitmentsAboutToExpire">
                <div class="ibox-content ibox-heading" style="margin-bottom: 10px; padding-bottom: 30px; padding-top: 5px;">
                    <h4 class="pull-left">{{ group.tillDateJewishDate }}</h4>
                    <h4 class="pull-right">{{ group.tillDate | date:'dd/MM/yyyy' }}</h4>
                </div>
                <div class="feed-activity-list">
                    <div class="feed-element" *ngFor="let item of group.commitments">
                        <div class="pull-left">
                            <button (click)="openCommitmentView(item)" style="background-color: #578ebe; color:white" class="btn btn-circle" type="button"><i class="fa fa-list"></i></button>
                        </div>
                        <div class="media-body">
                            <strong><a [routerLink]="['/contacts', item.contactID]">{{ item.firstName }} {{ item.lastName }}</a> {{ item.shtibelName }}</strong>
                            <strong>נושא: {{item.commitmentSubjectName}} </strong>
                            <h5 *ngIf="item.commitmentPulseAmount > 0">סכום <u>חודשי</u>: {{item.commitmentPulseAmount | number}}{{item.commitmentCurrencyIcon}} </h5>
                            <h5 *ngIf="item.commitmentGlobalAmount > 0">סכום התחייבות: {{item.commitmentGlobalAmount | number}}{{item.commitmentCurrencyIcon}} </h5>
                            <span>שולם עד כעת:
                                    <span *ngIf="item.totalPaidDollars">{{ item.totalPaidDollars  | number}}$</span>
                            <span *ngIf="item.totalPaidShekels">{{ item.totalPaidShekels  | number}}₪</span>
                            <span *ngIf="item.totalPaidEurosy">{{ item.totalPaidEurosy | number }}€</span>
                            <span *ngIf="item.totalPaidPounds">{{ item.totalPaidPounds | number }}£</span>
                            <span *ngIf="item.totalPaidFrancs">{{ item.totalPaidFrancs  | number}}+</span>
                            <span *ngIf="item.totalPaidCadDollar">{{ item.totalPaidCadDollar  | number}}+</span>
                            </span>
                            <strong>סוג: {{ item.commitmentDonationTypeName }}</strong>
                            <strong>תאריך סיום: {{item.commitmentTillStrDate}} </strong>
                        </div>
                    </div>
                </div>

            </div>
        </dashboard-panel-box>
    </div>
    <div>
        <dashboard-panel-box title="התחייבויות שנסגרו" [backgroundColor]="'#44b6ae'" (onSelect)="getClosedCommitments($event)" [showCollapse]="true">
            <div *ngFor="let group of model.groupClosedCommitments">
                <div class="ibox-content ibox-heading" style="margin-bottom: 10px; padding-bottom: 30px; padding-top: 5px;">
                    <h4 class="pull-left">{{ group.closedDateJewishDate }}</h4>
                    <h4 class="pull-right">{{ group.closedDate | date:'dd/MM/yyyy' }}</h4>
                </div>
                <div class="feed-activity-list">
                    <div class="feed-element" *ngFor="let item of group.commitments">
                        <div class="pull-left">
                            <button (click)="openCommitmentView(item)" style="background-color: #44b6ae; color:white" class="btn btn-circle" type="button"><i class="fa fa-list"></i></button>
                        </div>
                        <div class="media-body">
                            <strong><a [routerLink]="['/contacts', item.contactID]">{{ item.firstName }} {{ item.lastName }}</a> {{ item.shtibelName }}</strong>
                            <strong>נושא: {{item.commitmentSubjectName}} </strong>
                            <h5 *ngIf="item.commitmentPulseAmount > 0">סכום התחייבות <b>חודשי</b>: {{item.commitmentPulseAmount  | number}}{{item.commitmentCurrencyIcon}} </h5>
                            <h5 *ngIf="item.commitmentGlobalAmount > 0">סכום התחייבות: {{item.commitmentGlobalAmount  | number}}{{item.commitmentCurrencyIcon}} </h5>
                            <span>שולם עד כעת:
                            <span *ngIf="item.totalPaidDollars">{{ item.totalPaidDollars  | number}}$</span>
                            <span *ngIf="item.totalPaidCadDollars">{{ item.totalPaidCadDollars  | number}}$c</span>
                            <span *ngIf="item.totalPaidShekels">{{ item.totalPaidShekels  | number}}₪</span>
                            <span *ngIf="item.totalPaidEurosy">{{ item.totalPaidEurosy  | number}}€</span>
                            <span *ngIf="item.totalPaidPounds">{{ item.totalPaidPounds  | number}}£</span>
                            <span *ngIf="item.totalPaidFrancs">{{ item.totalPaidFrancs  | number}}+</span>
                            </span>
                            <strong>סוג: {{ item.commitmentDonationTypeName }},</strong>
                            <strong>תאריך סגירה: {{item.commitmentClosedStrDate}} </strong>
                            <strong>סטטוס סגירת התחייבות: {{ item.commitmentClosedStatusName }}</strong>
                        </div>
                    </div>
                </div>

            </div>
        </dashboard-panel-box>
    </div>
</div>
