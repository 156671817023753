import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { ProcessesRoutes } from './processes.routing';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';

import { ProcessesData } from './processes.data';
import { ProcessesList } from './processes-list.cmp';
import { ProcessEdit } from './process/process-edit.cmp';
import { ProcessDetails } from './process/process-details.cmp';
import { PaginationModule } from "../components/pagination/pagination.mdl";
import { ProcessMain } from './process/process-main.cmp';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		RouterModule.forChild(ProcessesRoutes),
		MainFormModalModule
	],
	declarations: [
		ProcessesList,
		ProcessEdit,
		ProcessDetails,
		ProcessMain
	],
	exports: [
		ProcessesList,
		ProcessEdit,
		ProcessDetails,
		ProcessMain
	],
	providers: [
		ProcessesData
	]
})
export class ProcessesMdl { }

