import { ToraxHttp } from './../../utils/custom-http.svc';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardMarbesDataService {

    private url = 'api/dashboardMarbe';
    constructor(
        private toraxHttp: ToraxHttp

    ) { }

    get() {
      return this.toraxHttp.get(`${this.url}`);
  }

  getMoneySums() {
    return this.toraxHttp.get(`${this.url}/getMoneySums`);
}
getSiteChartModel(grp: string) {
  return this.toraxHttp.get(`${this.url}/getSiteChartModel/${grp}`);
}


    getGrpSums() {
        return this.toraxHttp.get(`${this.url}/getGrpSums`);
    }

    getGlobalInfoControlModel(grp: string) {
        return this.toraxHttp.get(`${this.url}/getGlobalInfoControlModel/${grp}`);
    }

    getExpectPaymentsInMonths(grp: string) {
        return this.toraxHttp.get(`${this.url}/getExpectPaymentsInMonths/${grp}`);
    }

    getLineChartModel() {
        return this.toraxHttp.get(`${this.url}/getLineChartModel`);
    }

    getCitiesChartModel() {
        return this.toraxHttp.get(`${this.url}/getCitiesChartModel`);
    }

    getShtibelsChartModel(cityID?) {
        return this.toraxHttp.get(`${this.url}/getShtibelsChartModel/${cityID}`);
    }

    getNewShtibelsChartModel(cityID?) { //cityID?, cityName: string = ""
        return this.toraxHttp.get(`${this.url}/getNewShtibelsChartModel/${cityID}`);///${cityID}/${cityName}
    }
}
