import { ServicesData } from './../../services/services.data';
import { CommitmentsData } from './../commitments.data';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Commitment } from './commitment';
import { DatePipe } from '@angular/common';

declare const Hebcal: any;

@Component({
    selector: 'commitment-edit',
    templateUrl: './commitment-edit.tmpl.html',
    styleUrls: ['./../commitments.scss'],

})

export class CommitmentEdit implements OnInit {
    public commitment: Commitment;
    public donationType: number;
    public amountWay: string = 'globalAmount';
    public commitmentForm: FormGroup;
    public honors: any[];
    public registrations: any[];
    public subject: number;

    @ViewChild('tillDateMsg') public tillDateMsg: ElementRef;

    constructor(
         private commitmentsData: CommitmentsData,
        private formBuilder: FormBuilder,
        private cdRef: ChangeDetectorRef,
        public servicesData: ServicesData,
        private datePipe: DatePipe
    ) {
    }

    @Input('id') public id: number;
    @Input('contactId') public contactId: number;

    transformDate(date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    setTillDateMsg(date: Date) {
        let dt = new Date(date);
        if (this.commitment.commitmentID == 0 && dt) {
            let jewishYear = Hebcal.HDate(dt.getFullYear(), dt.getMonth()).year + 1; //TODO 1 ?????
            let lastDayInYear = Hebcal.HDate(`כט אלול ${jewishYear}`).greg();

            // let tillDate = new Date(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
            let strDate = this.transformDate(lastDayInYear);
            this.commitmentForm.controls["tillDate"].setValue(strDate);
            if (this.tillDateMsg && this.tillDateMsg.nativeElement) {
                this.tillDateMsg.nativeElement.textContent = `שים לב! תום תקופת ההתחייבות בסיום שנה זו בתאריך כ"ט אלול ${strDate},\nלשינוי, בחר בתאריך סיום אחר.`;
            }
            this.cdRef.detectChanges();
        }
    }

    deleteTillDateMsg(e) {
        if (this.tillDateMsg && this.tillDateMsg.nativeElement) {
            this.tillDateMsg.nativeElement.remove();
        }
    }

    // public getPayMethods(): void {
    //     let pmID = this.commitmentForm.controls['payMethodID'].value;
    //     this.servicesData.getList(`PayMethods/${this.contactId}/${false}/${pmID}`)
    //         .subscribe(
    //             (c: any) => {
    //                 this.payMethods = c;
    //             },
    //             () => { });
    // }

    public getHonors(loadAll: boolean = false): void {
        this.servicesData.getList(`HonorsKvp/${this.contactId}/${loadAll}`)
            .subscribe(
                (c: any) => this.honors = c,
                () => { });
    }


    public getRegistrations(): void {
        let cid = this.contactId > 0 ? this.contactId : this.commitmentForm.controls["contactID"].value;
        let rID = this.commitmentForm.controls['registrationID'].value;
        this.servicesData.getList(`RegistrationsToPay/${cid}/${rID}`).subscribe(
            (r: any) => this.registrations = r,
            error => { });
    }




   public load(): void {
        if (this.id > 0) {
            this.commitmentsData.getEdit(this.id)
                .subscribe((c: any) => {
                    this.commitment = c;
                    this.fillForm(c);
                })
            error => { console.error(error); };
        } else {
            this.commitmentsData.getAdd(this.contactId, this.donationType)
                .subscribe((c: any) => {
                    this.commitment = c;
                    this.fillForm(c);
                });
        }
    }


    private fillForm(c: Commitment): void {
        this.commitmentForm.patchValue(c);
        this.payMethodTitle = c.payMethodTitle;
        this.subject = c.commitmentSubject;
        this.donationType = c.donationType;
        this.commitmentForm.controls['donationType'].setValue(this.donationType);
        //  this.way = c.donationType;
        this.qty = +this.commitmentForm.controls['qty'].value;
        //this.commitmentForm.controls['dayOfMonth'].setValue(c.hebFromDay);
        //this.commitmentForm.controls['holdingCurrency'].setValue(c.currency);
        if (this.commitmentForm.controls['pulseAmount'].value) {
            this.amountWay = 'pulseAmount';
        } else {
            this.amountWay = 'globalAmount';
        }

        this.contactId = c.contactID;
        if (c.honorID > 0) {
            this.getHonors(true);
        }
        if(c.registrationID > 0){
            this.getRegistrations();
        }
        //this.getPayMethods();
        this.getCommitmentReasons()
        this.isFundraisingCommitment = c.commitmentFundraisingID && c.commitmentFundraisingID > 0;
    }

    getCommitmentReasons() {
        let cs = this.commitmentForm.controls['commitmentSubject'].value;
        this.servicesData.getList(`CommitmentReasons/${cs}`)
            .subscribe(
                (c: any) => {
                    this.commitmentReasons = c;
                },
                (x) => {
                    //console.log("xxxxxxxx", x);
                });
    }

    public selectSubject(e: any) {
        if (e && e.target) {
            this.getCommitmentReasons();
            this.subject = +e.target.value;
            if ((this.subject != 1 && this.subject != 2) || this.donationType == 5) {
                // this.commitmentForm.controls['holdingAmount'].setValue(null);
                // this.commitmentForm.controls['holdingSpecialtyMonthsAmount'].setValue(null);
                // this.commitmentForm.controls['holdingSpecialtyMonthsAmount'].validator = Validators.required;
                // this.commitmentForm.controls['holdingFromMonth'].setValue(null);
                // this.commitmentForm.controls['holdingFromYear'].setValue(null);
                // this.commitmentForm.controls['holdingContactID'].setValue(null);
            }
            if (this.subject == 3) {
                this.commitmentForm.controls['designation'].setValidators(Validators.required);
                this.commitmentForm.controls['designation'].updateValueAndValidity();
            }
            if (this.subject == 5 && !this.honors) {
                this.getHonors();
            }

            if (this.subject == 6 && !this.registrations) {
                this.getRegistrations();
            }


            this.commitmentForm.updateValueAndValidity();
            this.cdRef.detectChanges();
        }
    }

    selectHonor() {
        if (event && event.target) {
            let honorID = +(<HTMLSelectElement>event.target).value;
            let h = this.honors.filter(x => x.honorID == honorID);
            if (h.length > 0 && this.id == 0) {
                this.commitmentForm.controls['globalAmount'].setValue(h[0].contributionAmount);
                this.commitmentForm.controls['currency'].setValue(h[0].currency);
            }
            // console.log(h);
        }
    }


    public qtyDescription;
    public lastQtyDescription;
    public currencyIcon = '';
    public qty;
    public monthlyPay;
    public lastPay;
    public commitmentReasons: any[];

    qtyCalculator() {
        let amount = +this.commitmentForm.controls['globalAmount'].value || 0;

        if (amount === 0) {
            return;
        }

        if (+this.commitmentForm.controls['currency'].value > 0) {
            let crncy = this.servicesData.Currencies[+this.commitmentForm.controls['currency'].value - 1];
            this.currencyIcon = crncy ? crncy.name : '';
        }

        this.qty = +this.commitmentForm.controls['qty'].value || null;
        if (!this.qty) {
            return;
        }

        this.monthlyPay = +Math.ceil(amount / this.qty);
        this.lastPay = (amount % this.monthlyPay);
        // var currency = this.currencyIcon;
        this.qtyDescription = `${this.lastPay > 0 ? this.qty - 1 : this.qty} תשלומים בסך ${this.monthlyPay}${this.currencyIcon}`;
        this.lastQtyDescription = `${this.lastPay > 0 ? `תשלום אחרון בסך ${this.lastPay}${this.currencyIcon}` : ``}`;
    }


    payMethodTitle = '';
    public selectPayMethod() {
        if (event && event.target) {
            this.payMethodTitle = (<HTMLSelectElement>event.target).selectedOptions[0].label;
            if (this.payMethodTitle !== 'שיקים' && this.payMethodTitle !== 'מזומן' && this.payMethodTitle != null) {
                this.payMethodTitle = 'אשראי';
            }
        }
    }
    addressAddEvent(address) {
        this.commitmentForm.controls['defaultReceiptAddressID'].setValue(address.addressID);
    }
    addressChangeEvent(address) {
        this.commitmentForm.controls['defaultReceiptAddressID'].setValue(address.addressID);
    }


    donationTypeName = 'כללי';
    public selectType(tn: number) {
        if (tn === 0) {
            this.donationTypeName = 'כללי';
        }
        // else if (tn === 1) {
        //     this.donationTypeName = 'חודשי';
        // } else if (tn === 2) {
        //     this.donationTypeName = 'שנתי';
        // } else if (tn === 3) {
        //     this.donationTypeName = 'שבועי';
        // }
        else if (tn === 4) {
            this.donationTypeName = 'התחייבות';
        } else if (tn === 5) {
            this.donationTypeName = 'התחייבות מקושרת';
        }
    }


    // public changePulseAmount(event: any) {
    //     let amount = +event.target.value;
    //     let holdingAmount = +this.commitmentForm.controls['holdingAmount'].value || 0;
    //     if (this.id == 0 && holdingAmount == 0) {
    //         this.commitmentForm.controls['holdingAmount'].setValue(amount);
    //     }
    // }

    // public changeSpecialtyMonthsAmount(event: any) {
    //     let amount = +event.target.value;
    //     let holdingSpecialtyMonthsAmount = +this.commitmentForm.controls['holdingSpecialtyMonthsAmount'].value || 0;
    //     if (this.id == 0 && holdingSpecialtyMonthsAmount == 0) {
    //         this.commitmentForm.controls['holdingSpecialtyMonthsAmount'].setValue(amount);
    //     }
    // }

    currencyWarning = false;
    // public changeCurrency(event: any) {
    //     let currecy = +event.target.value;
    //     let holdingCurrency = +this.commitmentForm.controls['holdingCurrency'].value;
    //     if (this.id == 0 && (holdingCurrency == null || holdingCurrency == 0)) {
    //         this.commitmentForm.controls['holdingCurrency'].setValue(currecy);
    //     } else if ((this.subject == 1 || this.subject == 2) && holdingCurrency != currecy) {
    //         this.currencyWarning = true;
    //     } else if (holdingCurrency == currecy) {
    //         this.currencyWarning = false;
    //     }
    // }

    // public changeHoldingCurrency() {
    //     this.currencyWarning = false;
    // }

    currencyValidation = c => {
        if (!c.value || c.value === 0 || c.value === 'undefined') {
            return { required: true };
        }
        return null;
    };


    pulseAmountValidation = c => {
        if (this.commitmentForm && (!c.value && this.commitmentForm.controls['donationType'].value == 4 && !this.commitmentForm.controls['globalAmount'].value)) {
            return { required: true };
        }
        return null;
    };

    globalAmountValidation = c => {
        if (this.commitmentForm && (!c.value && this.commitmentForm.controls['donationType'].value == 4 && !this.commitmentForm.controls['pulseAmount'].value)) {
            return { required: true };
        }
        return null;
    };



    repeatsValidation = c => {
        if (!c.value || c.value == 0 || c.value === 'undefined') {
            let dt = this.commitmentForm.controls['donationType'].value;
            if (dt === '2' || dt === '1' || dt === '3') {
                return { required: true };
            } else {
                return null;
            }
        }
        return null;
    };

    commitmentFundraisingValidations = c => {
        // tslint:disable-next-line:max-line-length
        if (this.donationType == 5 && (!c.value || c.value === 0 || c.value === 'undefined')) {
            return { required: true };
        }
        return null;
    };

    private initForm() {
        this.commitmentForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            commitmentID: [this.id || 0, Validators.required],
            contactID: [this.contactId, Validators.required],
            payMethodID: [],
            commitmentSubject: [, Validators.required],
            commitmentReasonID: [],
            pulseAmount: [, this.pulseAmountValidation],
            globalAmount: [, this.globalAmountValidation],
            globalAmountValueInILS: [],
            pulseAmountValueInILS: [],
            donationType: ['', Validators.required],
            fromDate: [, Validators.required],
            hebrewDate: [true, Validators.required],
            repeats: ['1', this.repeatsValidation],
            qty: [null],
            currency: [null, this.currencyValidation],
            tillDate: [null],
            dayOfMonth: [],
            pladaSaleID: [],
            pladaHorkID: [],
            specialtyMonthsAmount: [],
            // holdingContactID: [],
            // holdingContactName: [],
            // holdingFromMonth: [],
            // holdingFromYear: [],
            // holdingAmount: [],
            // holdingCurrency: [null, Validators.required],
            // holdingSpecialtyMonthsAmount: [],
            defaultReceiptAddressID: [],
            // defaultReceiptAddressTitle: [],
            defaultDetailsLetterAsReceipt: [false, Validators.required],
            defaultContactTitleForReceipts: [],
            commitmentFundraisingID: [null, this.commitmentFundraisingValidations],
            commitmentFundraisingTitle: [],
            isFundraising: [false, Validators.required],
            honorID: [],
            comment: [],
            closedDate: [],
            closedBy: [],
            closedStatus: [],
            closedComment: [],
            registrationID: [],
            defaultTermID: [],
            operation: [],
            hebYear: [],
            designation: [],
            designationDetails: [],
            visibleInRep: [],
            //paymentCommitments: this.formBuilder.array([]),


            //trick
            needRefreshFuturePayments: [false]
        });
        this.commitmentForm.controls['donationType'].valueChanges.subscribe(x => {
            this.selectType(x);
        }
        );
        this.commitmentForm.controls['qty'].valueChanges.subscribe(() => {
            this.qtyCalculator();
        });

        this.commitmentForm.controls['pulseAmount'].valueChanges.subscribe(x => {
            if (x) {
                this.commitmentForm.controls['globalAmount'].setValue(null);
            }
        });

        this.commitmentForm.controls['globalAmount'].valueChanges.subscribe(x => {
            if (x) {
                this.commitmentForm.controls['pulseAmount'].setValue(null);
                this.qtyCalculator();
            }
        });
    }

    // dropDownRequired = () => {
    //     if (this.donationType === 0 && this.commitmentForm.controls['paymentCommitments'].value.length === 0) {
    //         return { required: true };
    //     }
    //     return null;
    // };

    openFundraisingCommitments() {
        this.isFundraisingCommitment = !this.isFundraisingCommitment;
        if (this.isFundraisingCommitment) {
            this.donationType = 5;
            this.commitmentForm.controls['donationType'].setValue(this.donationType);
        } else {
            this.donationType = 4;
            this.commitmentForm.controls['donationType'].setValue(this.donationType);
            this.commitmentForm.controls['commitmentFundraisingID'].setValue(null);
        }
        this.cdRef.detectChanges();
    }

    isFundraisingCommitment = false;

    public fundraisingCommitmentSelect(commitmentID) {
        if (commitmentID) {
            this.isFundraisingCommitment = true;
            this.commitmentForm.controls['commitmentFundraisingID'].setValue(commitmentID);
            this.updateFundraisingValidators();
        }
    }

    updateFundraisingValidators() {
        if (this.isFundraisingCommitment) {
            this.commitmentForm.controls['donationType'].setValue(5);

            this.commitmentForm.controls['commitmentReasonID'].setValidators(null);
            this.commitmentForm.controls['commitmentSubject'].setValidators(null);
            this.commitmentForm.controls['globalAmount'].setValidators(null);
        } else {
            this.commitmentForm.controls['donationType'].setValue(4);
        }
    }



    ngOnInit() {
        // this.activatedRoute.params.forEach((params: Params) => {
        //     this.id = +params['commitmentID'] || 0;
        //     this.contactId = +params['contactID'];
        //     // this.donationType = +params['donationType'];
        // });

        this.initForm();

        this.donationType = 4;
        //   this.selectType(this.donationType);
        // this.getAddresses();
        this.load();
        //this.commitmentForm.controls['paymentCommitments'].validator = this.dropDownRequired;
        if (this.donationType == 5) {
            this.isFundraisingCommitment = true;
            this.updateFundraisingValidators();
        }


    }
}



