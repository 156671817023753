import { NgModule } from '@angular/core';

import { TabsLarge } from './tabs-large.cmp';
import { singleTabLarge } from './single-tab-large.cmp';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    exports: [singleTabLarge, TabsLarge],
    declarations: [singleTabLarge, TabsLarge]
})
export class TabsLargeMdl { }
