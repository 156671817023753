<form style="white-space: nowrap;" [formGroup]="formData" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      ייצוא דוחו"ת לפי חודשים
      <br>
      <span *ngIf="formData && shtibelMode">
        <small>עבור שטיבל:</small> {{formData.controls.shtibelName.value}}
      </span>




    </h2>
  </div>
  <mat-dialog-content style="padding:0px 24px 24px 24px">
    <div fxLayout="column" style="display: block;">

      <div *ngIf="(!formData || !shtibelMode)  && !contactMode" class="form-row" fxLayout="row" fxLayout.xs="column">
        <auto-complete apply-enter [placeholder]="'עיר'" [controllerName]="'Cities'" [formGroupModel]="formData"
          [idControl]="formData.controls.cityID" [nameControl]="formData.controls.cityName">
        </auto-complete>
        &nbsp;&nbsp;
        <auto-complete apply-enter [placeholder]="'שטיבל'" [controllerName]="'Shtibels'" [formGroupModel]="formData"
          [idControl]="formData.controls.shtibelID" [nameControl]="formData.controls.shtibelName">
        </auto-complete>
        &nbsp;&nbsp;

        <div class="input-group mb-3">
          <label>שיוך לשטיבל...</label>
          <mat-select class="form-control" [disabled]="formData.controls.shtibelID.value != null"
            placeholder="שיוך לשטיבל..." formControlName="friendsInStiebel">
            <mat-option [value]="">כולם</mat-option>
            <mat-option [value]="true">חברים בשטיבל</mat-option>
            <mat-option [value]="false">ללא שטיבל</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="formData && contactMode">
        <div class="input-group mb-3">
          <label>שם איש:</label>
          <input style="font-weight: bold;" type="text" class="form-control" [disabled]="true"
            [value]="formData.controls.contactName.value">
        </div>
      </div>

      <hr>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <dateMonth-range [fromIsRequired]="true" [tillIsRequired]="true" [fromPlaceholder]="'החל מ...'"
          [tillPlaceholder]="'עד ל...'" [dateMonthRangeCtrl]="formData.controls.dateMonthsRange">
          Loading...</dateMonth-range>
      </div>
      <hr>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>הודפס:</label>
          <select class="form-control" formControlName="isPrint">
            <option [value]="false">לא הודפס</option>
            <option [value]="true">הודפס</option>
            <!-- <option [value]="">הכל</option> -->
          </select>
        </div>
        &nbsp;&nbsp;
        <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
          *ngIf="servicesData.StatusPayments" [listControl]="formData.controls.statusPayments"
          [placeholder]="'סטטוס...'" [list]="servicesData.StatusPayments">
        </mat-select-multiple-dropdown>

      </div>


    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">

    <button apply-enter *ngIf="!contactMode" [disabled]="formData.invalid " class="btn btn-primary navbar-btn"
      (click)="getPivotHoldingsExls(formData.value)"><i class="fa fa-exclamation"></i> אקסל חודשים</button>


    <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn"
      (click)="getPivotHoldingsHtml(formData.value)"><i class="fa fa-exclamation"></i> PDF חודשים</button>

  </mat-dialog-actions>
</form>
