<form  [formGroup]="commitmentReasonForm" novalidate (ngSubmit)="save()">
    <div class="modal-header">
        <h1>עריכת סיבת התחייבות [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <panel-box title="עריכת סיבת התחייבות" [showClose]="false" [showCollapse]="false">
            <span class="panel-tools"></span>
            <div class="panel-content">
                <div class="form-horizontal">
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="commitmentSubject">משוייך לנושא:</label>
                        <div class="col-sm-10 form-inline">
                            <mat-form-field>
                                <mat-select apply-enter placeholder="נושא:"
                                    [formControl]="commitmentReasonForm.controls.commitmentSubject">
                                    <mat-option *ngFor="let cs of sd.CommitmentSubjects" [value]="cs.value">
                                        {{cs.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="reason">סיבה:</label>
                        <div class="col-sm-10 form-inline">
                            <mat-form-field>
                                <input matInput apply-enter  formControlName="reason" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </panel-box>
    </div>
    <footer-edit-buttons [dataProvider]="commitmentReasonsData" [router]="router" [id]="id"
        [disabledDeleteButton]="id <= 0" [modelFormGroup]="commitmentReasonForm"></footer-edit-buttons>

</form>