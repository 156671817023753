import { MaterialModule } from './../material.module';

import { DateInputModule } from '../components/dateInput/date-input.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { PeleCardTransactionsData } from './pele-card-transactions.data';
import { PelecardTransactionsRoutes } from './pele-card-transactions.routing';
import { PelecardTransactionsList } from './pele-card-transactions-list.cmp';
import { CurrencyPipeMdl } from '../components/pipes/currency-pipe';


@NgModule({
    imports: [
        CommonModule,
         FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        DateInputModule,
        RouterModule.forChild(PelecardTransactionsRoutes),
        MaterialModule,
        CurrencyPipeMdl
    ],
    declarations: [
      PelecardTransactionsList,
    ],
    exports: [
      PelecardTransactionsList,
    ],
    providers: [
      PeleCardTransactionsData
    ]
})
export class PeleCardTransactionsMdl { }

