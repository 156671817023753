import { CityMain } from './city/city-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { MaterialModule } from './../material.module';
import { CitiesList } from './cities-list.cmp';
import { CityDetails } from './city/city-details.cmp';
import { CityEdit } from './city/city-edit.cmp';

import { CitiesRoutes } from './cities.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';

import { CitiesData } from './cities.data';


@NgModule({
	imports: [
		CommonModule, 
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		DateInputModule,
		AutoCompleteModule,
		RouterModule.forChild(CitiesRoutes),
		 MaterialModule,
		 MainFormModalModule
	],
	declarations: [
		CitiesList,
		CityMain,
		CityEdit,
		CityDetails
	],
	exports: [
		CitiesList,
		CityMain,
		CityEdit,
		CityDetails
	],
	providers: [
		CitiesData
	]
})
export class CitiesMdl { }

