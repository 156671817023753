import { ToraxHttp } from './../../utils/custom-http.svc';
import { TishAreasData } from './../tishAreas.data';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router'; 
import { TishArea } from './tishArea'; 

@Component({
	
	selector: 'tishArea-details',
	templateUrl: './tishArea-details.tmpl.html'
})

export class TishAreaDetails implements OnInit {
	constructor(
		private TishAreasData: TishAreasData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.tishArea && id === this.tishArea.tishAreaID) {
				this.loadTishArea();
			}
		});
	}

	public tishArea: TishArea;
	private id: number;

	public loadTishArea(): void {
		this.TishAreasData.get(this.id)
			.subscribe((tg:any) => this.tishArea = tg);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['tishAreaID'];
			this.loadTishArea();
		});
	}
}

