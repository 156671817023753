import { ContactCompensationsData } from './../contactCompensations.data';
import { ContactsData } from './../../contacts.data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ContactCompensation } from './contactCompensation';
import { ServicesData } from 'src/app/services/services.data';

@Component({
    selector: 'contactCompensation-edit-dlg',
    templateUrl: './contactCompensation-edit-dlg.tmpl.html'
})

export class ContactCompensationEditDlg implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ContactCompensationEditDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private contactCompensationsData: ContactCompensationsData,
        private formBuilder: FormBuilder,
        private servicesData: ServicesData
    ) {

    }
    @Input('id') public id: number;
    communicationType;
    contactCompensation: ContactCompensation;
    contactId: number;

    contactCompensationForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.contactCompensationsData.get(this.id || 0)
                .subscribe((p: any) => {
                    this.contactCompensation = p;
                    this.fillForm(p);
                });
        }
        else if(this.contactCompensation.contactID){
            this.contactCompensationsData.getAdd(this.contactCompensation.contactID)
                .subscribe((p: any) => {
                    this.contactCompensation = p;
                    this.fillForm(this.contactCompensation);
                });
        }
    }

    private fillForm(p: ContactCompensation): void {
        this.contactCompensationForm.patchValue(p);
    }

    private initForm() {
        this.contactCompensationForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            contactCompensationID: [, Validators.required],
            contactID: [this.contactId, Validators.required], 
            contactName:[],           
            compensationDate:[new Date(), Validators.required],           
            description:[,Validators.required],
            subject: [, Validators.required], 

        });
    }

    
    // onNoClick(): void {
    //     this.matDialogRef.close();
    // }


    delete() {
        this.contactCompensationsData.delete(this.data.contactCompensation.contactCompensationID)
            .subscribe((c: any) => {
                return true;
                //this.matDialogRef.close();
            })
    }
    ngOnInit() {
        // this.activatedRoute.params.forEach((params: Params) => {
        //     this.id = +params['contactCompensationID'] || 0;
        //     this.contactId = +params['contactID'];
        // });
        this.id = this.data.contactCompensation.contactCompensationID;
        this.contactId = this.data.contactCompensation.contactID;
        this.contactCompensation = this.data.contactCompensation;
        this.initForm();
        this.load();
    }
}



