import { ServicesData } from './../../services/services.data';
import { TishTablesData } from './../tishTables.data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

const dropDownRequired = c => {
  if (!c.value || c.value === 'undefined') {
    return { required: true };
  }
  return null;
};

@Component({
  templateUrl: './tishTable-edit-dlg.tmpl.html'
})
export class TishTableDlg implements OnInit {
  public formData: FormGroup;

  //  public data: any;

  constructor(
    public dialogRef: MatDialogRef<TishTableDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private tishTablesData: TishTablesData
  ) { }
  ngOnInit(): void {
    if (this.data.tishTableID > 0) {
      this.load();
    } else {
      this.formData = this.tishTablesData.initForm();
      this.formData.patchValue(this.data);
    }

  }

  load() {
    if (this.data.tishTableID) {
      this.tishTablesData.getEdit(this.data.tishTableID)
        .subscribe((c: any) => {
          this.formData = this.tishTablesData.initForm();
          this.data = c;
          this.formData.patchValue(this.data);
        });
    }

  }




  public isValid(): boolean {
    return (this.formData && this.formData.valid);
  }


}
