<form style="white-space: nowrap;" [formGroup]="formData" novalidate enter-tab>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      קליטה אחת ולדורות
    </h2>
  </div>
  <mat-dialog-content *ngIf="data" style="padding:0px 24px 24px 24px">
    <div fxLayout="column" style="display: block;">

      <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="formData">
        <div class="input-group mb-3">
          <!-- <label>שם איש:</label> -->
          <h1 style="font-weight: bold;" >
            {{data.firstName + ' ' + data.lastName}} <small *ngIf="data.fatherName">ב"ר {{data.fatherName}}</small>
          </h1>
          <h4>
            <b>{{data.cityName}}</b> שטיבל - {{data.shtibelName}}
          </h4>
        </div>
      </div>

      <hr>

      <mat-slide-toggle (selectChange)="slideChange($event)" formControlName="commitmentOnly">
        התחייבות בלבד
      </mat-slide-toggle>

      <div class="row m-t-lg">

        <div class="col-sm-6">
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <mat-radio-group apply-enter formControlName="commitmentReasonID"
              style="display: flex; flex-direction: column; margin: 15px 0; align-items: flex-start;">
              <mat-radio-button style="background-color: #dcdcdc3b; width: 100%; padding: 4px;" [value]="113">מטר בנוי
                לדורות (10,000)</mat-radio-button>
              <mat-radio-button style="background-color: white; width: 100%; padding: 4px;" [value]="114">יחידת דורות
                (5,000)</mat-radio-button>
              <mat-radio-button style="background-color: #dcdcdc3b; width: 100%; padding: 4px;" [value]="115">נזכרים
                לדורות (120,000)</mat-radio-button>
              <mat-radio-button style="background-color: white; width: 100%; padding: 4px;" [value]="116">רשומים בשמך
                (36,000)</mat-radio-button>
              <mat-radio-button style="background-color: #dcdcdc3b; width: 100%; padding: 4px;"
                [value]="117">משתתף</mat-radio-button>
            </mat-radio-group>
            <span style="color: red; font-size: 10px;">*</span>
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>סכום:
                <!-- <span *ngIf="formData.controls.commitmentOnly.value == true" style="color: blue;"><U>כולל</U> של התחייבות</span> -->
                <span style="color: red; font-size: 10px;">*</span>
              </label>
              <input class="form-control" apply-enter formControlName="amount" placeholder="סכום">
            </div>&nbsp;
            <div class="input-group mb-3">
              <label>מטבע:
                <span style="color: red; font-size: 10px;">*</span>
              </label>
              <select apply-enter class="form-control narrow" formControlName="currency" placeholder="מטבע">
                <option [value]="null">...</option>
                <option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                  {{c.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>תשלומים:
                <span *ngIf="formData.controls.commitmentOnly.value == false" style="color: red; font-size: 10px;">*</span>
              </label>
              <input class="form-control narrow" apply-enter formControlName="qty" placeholder="מס תשלומים:">
            </div>
          </div>

          <div class="form-row" *ngIf="formData.controls.commitmentOnly.value == false" fxLayout="row" fxLayout.xs="column">
            <mat-button-toggle-group formControlName="dayInMonth" style="height: min-content;">
              <mat-button-toggle apply-enter [value]="10">
                <mat-icon style="vertical-align: sub; font-size: 18px;"
                  *ngIf="formData.controls.dayInMonth.value == 10">radio_button_checked</mat-icon>
                <mat-icon style="vertical-align: sub; font-size: 18px;"
                  *ngIf="formData.controls.dayInMonth.value == 20">radio_button_unchecked</mat-icon>
                10 לחודש
              </mat-button-toggle>
              <mat-button-toggle apply-enter [value]="20">
                <mat-icon style="vertical-align: sub; font-size: 18px;"
                  *ngIf="formData.controls.dayInMonth.value == 20">radio_button_checked</mat-icon>
                <mat-icon style="vertical-align: sub; font-size: 18px;"
                  *ngIf="formData.controls.dayInMonth.value == 10">radio_button_unchecked</mat-icon>
                20 לחודש
              </mat-button-toggle>
            </mat-button-toggle-group>
            <span style="color: red; font-size: 10px;">*</span>

          </div>
        </div>

      </div>

      <mat-tab-group enter-tab *ngIf="formData.controls.commitmentOnly.value == false" fxFlexFill [selectedIndex]="activeTabIndex" (selectedTabChange)="tabItemSelect($event)">
        <mat-tab [disabled]="this.data.payMethodID == null || this.data.payMethodID == 0">
          <ng-template mat-tab-label>
            אמצעי תשלום קיים
          </ng-template>
          <div style="padding: 10;">
            <mat-checkbox  (change)="selectionChanged($event)" apply-enter fxLayout="row">
              אשראי המסתיים ב: {{data.last4Digits}}
              <span style="color: red; font-size: 10px;">*</span>
            </mat-checkbox>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            אשראי
          </ng-template>
          <div class="col-sm-12">
            <fieldset class="form-group" fxLayout="column">
              <!-- <legend>פרטי אשראי</legend> -->
              <div fxLayout="column">
                <div class="form-row" fxLayout="row" fxLayout.xs="column">
                  <div class="input-group mb-3">
                    <label>מספר כרטיס:
                      <span style="color: red; font-size: 10px;">*</span>
                    </label>
                    <input apply-enter style="direction: ltr;" placeholder="מספר כרטיס..." class="form-control"
                      formControlName="creditNumber" />
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="input-group mb-3">
                    <label>תוקף (MMYY):
                      <span style="color: red; font-size: 10px;">*</span>
                    </label>
                    <input class="form-control narrow" apply-enter mask="00/00" formControlName="expiryStr" type="text" />
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="input-group mb-3">
                    <label>ספרות בגב הכרטיס:</label>
                    <input class="form-control narrow" apply-enter formControlName="cvv" type="text" />
                  </div>
                </div>
              </div>
            </fieldset>

          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            הוראת קבע
          </ng-template>
          <fieldset class="form-group" fxLayout="column">

            <div fxLayout="column">
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                  <label>שם בעל החשבון:</label>
                  <input type="text" apply-enter class="form-control" formControlName="bankAcountOwner" />
                </div>
              </div>
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                  <label>בנק: <span style="color: red; font-size: 10px;">*</span>
                    <span style="color: red; font-size: 10px;">*</span>
                  </label>
                  <input class="form-control narrow" apply-enter formControlName="bankNumber" type="text" />
                  <!-- <auto-complete apply-enter [controllerName]="'Banks'" [placeholder]="'בנק'"
                    (onSelect)="itemSelect($event)" [formGroupModel]="formData"
                    [idControl]="formData.controls.bankNumber"
                    [nameControl]="formData.controls.bankName"></auto-complete> -->
                </div>
                &nbsp;
                <div class="input-group mb-3">
                  <label>סניף: <span style="color: red; font-size: 10px;">*</span></label>
                  <input class="form-control narrow" apply-enter formControlName="bankBranchNumber" type="text" />

                  <!-- <auto-complete apply-enter #bankBranchesCmp [controllerName]="'BankBranches'" [placeholder]="'סניף'"
                    [formGroupModel]="formData" [idControl]="formData.controls.bankBranchNumber"
                    [nameControl]="formData.controls.bankBranchName"></auto-complete> -->
                </div>
                &nbsp;
                <div class="input-group mb-3">
                  <label>מס' חשבון: <small style="color: red;">*</small></label>
                  <input type="text" class="form-control" apply-enter formControlName="bankAcountNumber" />
                </div>
              </div>
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                  <label>הגבלת סכום (בהו"ק בנקאית):</label>
                  <input type="text" class="form-control" apply-enter formControlName="amountLimit" />
                </div>
                &nbsp;

                <date-input [placeholder]="'הגבלת תאריך (בהוק בנקאית):'" apply-enter [dateControl]="formData.controls.dateLimit">
                  Loading...</date-input>
              </div>
            </div>
          </fieldset>

        </mat-tab>

      </mat-tab-group>

      <hr>

      <div class="form-row" fxLayout="row" fxLayout.xs="column"></div>


      <textarea rows="2" class="comment" placeholder="הערה להתחייבות/הסכם..." apply-enter formControlName="comment"></textarea>

    </div>
  </mat-dialog-content>
  <!-- {{inSaveMode}} -->
  <mat-dialog-actions class="modal-footer" align="end">
    <button [disabled]="loading" apply-enter  class="btn btn-primary navbar-btn" (click)="saveAchasIledoros()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    שמירה
</button>

<!-- <button apply-enter  class="btn btn-primary navbar-btn"
      (click)="saveAchasIledoros()"><i class="fa fa-exclamation"></i> שמירה</button> -->
  </mat-dialog-actions>
</form>
