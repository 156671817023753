<form [formGroup]="honorTypeForm" novalidate (ngSubmit)="save()" *ngIf="honorType">
    <div class="modal-header">
        <h1>עריכת סוג כיבוד [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div  class="example-form">
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>סוג כיבוד</legend>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>תחום:</label>
                            <mat-select placeholder="תחום..." class="form-control" formControlName="honorDepartmentID">
                                <mat-option *ngFor="let hd of servicesData.HonorDepartments" [value]="hd.id">
                                    {{hd.name}}
                                </mat-option>
                            </mat-select> 
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>תיאור:</label>
                            <input apply-enter class="form-control" formControlName="honorTypeDescription">
                        </div>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>טקסט לשילוב במכתב:</label>
                            <input apply-enter class="form-control" formControlName="letterText">
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>תבנית מכתב:</label>
                            <mat-select placeholder="תבנית מכתב..." class="form-control" formControlName="letterSettingID">
                                <mat-option *ngFor="let ls of servicesData.LetterSettings" [value]="ls.id">
                                    {{ls.title}}
                                </mat-option>
                            </mat-select>  
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [onSaveGoToBack]="true" [navigateAfterSaving]="'honorTypes'" [dataProvider]="honorTypesData"
        [router]="router" [id]="id" [disabledDeleteButton]="false" [modelFormGroup]="honorTypeForm">
    </footer-edit-buttons>
</form>