import { Component, Input, AfterViewInit } from '@angular/core';

@Component({
    selector: 'message-details',
    templateUrl: './message-details.tmpl.html'
})

export class MessageDetails implements AfterViewInit {

    @Input() model: any; 

    ngAfterViewInit(): void {
       // console.log("order-charge-details model", this.model);
    }
 
}



