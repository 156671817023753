import { PaymentsData } from './../../payments/payments.data';
import { ServicesData } from './../../services/services.data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Output, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Payment, PaymentCommitment } from 'src/app/payments/payment/payment';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';


@Component({
  selector: 'vouchers-org-payment-edit-dlg',
  templateUrl: './vouchers-org-payment-edit-dlg.tmpl.html'
})

export class VouchersOrgPaymentEditDlg implements OnInit, AfterViewInit {


  constructor(
    public dialogRef: MatDialogRef<VouchersOrgPaymentEditDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentsData: PaymentsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private cdRef: ChangeDetectorRef,
  ) {

  }

  @Output() paymentChange = new EventEmitter<any>();
  @ViewChild('bankBranchesCmp') bankBranchesCmp: AutoCompleteComponent;


  public itemSelect(id: number) {
    this.bankBranchesCmp.sourceList = `BankBranches/${id}`;
  }

  id: number;
  payment: Payment;
  contactId: number;
  paymentForm: FormGroup;
  vouchersOrganizationID: number;
  payMethodID?: number;
  doneOnLabel = '';
  isCash = false;
  isCheck = false;
  isVoucher = false;
  isMoneyValue = false;
  isCashRegister = false;
  isBankTransfer = false;
  isExternalSoftware = false;
  statuPayment: number | null = 1;
  payOption = '';
  terms;

  // setting this is the key to initial select.
  public load(): void {
    this.paymentsData.getAddVouchersOrgPayment(this.vouchersOrganizationID || 0)
      .subscribe((p: any) => {
        this.payment = p;
        this.fillForm(this.payment);
      });
    //}
  }

  private fillForm(p: Payment): void {
    //עדכון התשלום והמטבע לאחר הטעינה מהשרת
    p.amount = this.data.payment.amount;
    p.currency = this.data.payment.currency;
    p.voucherPaymentIDs = this.data.payment.voucherPaymentIDs;

    this.paymentForm.patchValue(p);

    //הוספת קישור התשלום להתחייבות
    let pc = new PaymentCommitment();
    pc.partialAmount = p.amount;
    pc.paymentID = p.paymentID;
    pc.commitmentID = 0;

    this.addPaymentCommitmentControl(pc);

    this.paymentForm.controls['billingDate'].setValue(p.billingDate);
    this.cdRef.detectChanges();

  }

  amountChangeEvent(e) {
    //      this.updatePartialAmount();
  }

  // updatePartialAmount() {
  //     const paymentCommitmentsControl = <FormArray>this.paymentForm.controls['paymentCommitments'];
  //     if (paymentCommitmentsControl.length === 1 && this.id == 0) {
  //         let val = +this.paymentForm.controls['amount'].value;
  //         (<any>paymentCommitmentsControl.controls[0]).controls['partialAmount'].setValue(val);
  //     }
  // }

  payMethodAddEvent(payMethod) {
    this.paymentForm.controls['payMethodID'].setValue(payMethod.payMethodID);
    this.setPayOption(payMethod.payMethodID, payMethod.paymentOption);
    this.cdRef.detectChanges();
  }

  payMethodChangeEvent(payMethod) {
    this.paymentForm.controls['payMethodID'].setValue(payMethod.payMethodID);
    this.setPayOption(payMethod.payMethodID, payMethod.paymentOption);
    this.cdRef.detectChanges();
  }

  payMethodSelection(payMethod) {
    this.setPayOption(payMethod.payMethodID, payMethod.paymentOption);
    this.cdRef.detectChanges();
  }


  setPayOption(payMethodID: number, payMethodOption: string = null) {
    if ([88, 89, 420, 1310, 3580, 6232, 6990].indexOf(payMethodID) == -1) {
      switch (payMethodOption) {
        case 'אשראי':
          this.payOption = 'cc';
          this.doneOnLabel = 'תאריך תשלום (ביצוע)';
          this.loadTerms();
          break;
        case 'הוראת קבע':
          this.payOption = 'hok';
          this.doneOnLabel = 'תאריך תשלום (ביצוע)'
          break;
        default:
          this.terms = null;
          break;
      }
    }
    else if (payMethodID == 88) {
      this.payOption = 'Cash';
      this.doneOnLabel = 'תאריך תשלום (ביצוע)';
      //    this.checkValidation;
    }
    else if (payMethodID == 89) {
      this.payOption = 'Check';
      this.doneOnLabel = 'תאריך הפקדת השיק בבנק בפועל'
    }
    else if (payMethodID == 420) {
      this.payOption = 'BankTransfer';
      this.doneOnLabel = 'בהעברה בנקאית יש להזין את תאריך ההעברה בפועל'
    }
    else if (payMethodID == 1310) {
      this.payOption = 'cashRegister';
      this.doneOnLabel = 'תאריך תשלום (ביצוע)'
    }
    else if (payMethodID == 3580) {
      this.payOption = 'voucher';
      this.doneOnLabel = 'תאריך הפקדה מחברת הוורצ\'רס'
    }
    else if (payMethodID == 6232) {
      this.payOption = 'externalSoftware';
      this.doneOnLabel = 'תאריך תשלום (ביצוע)'
    }
    else if (payMethodID == 6990) {
      this.payOption = 'moneyValue';
      this.doneOnLabel = 'בתשלום ע"י שווי כסף יש להכניס תאריך ביצוע'
    }
    else if (!payMethodID || payMethodID == 0) {
      this.payOption = '';
      this.doneOnLabel = 'תאריך תשלום (ביצוע)'
    }
  }

  loadTerms() {
    // throw new Error("Method not implemented.");
  }



  addPaymentCommitmentControl(pc: PaymentCommitment) {
    const paymentCommitmentsControl = <FormArray>this.paymentForm.controls['paymentCommitments'];
    paymentCommitmentsControl.push(this.initPaymentCommitment(pc));
  }

  initPaymentCommitment(pc: PaymentCommitment) {
    let paymentCommitmentFormGroup: FormGroup = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      paymentID: [pc.paymentID, Validators.required],
      commitmentID: [pc.commitmentID || 0, Validators.required],
      partialAmount: [pc.partialAmount, Validators.required],
      partialValueInCommitment: [],
      lockPartialValueInCommitment: [false, Validators.required]
    });
    if (pc.commitmentID == 0) {
      paymentCommitmentFormGroup.addControl('commitmentSubject', new FormControl(5, Validators.required)); //שוטף
      paymentCommitmentFormGroup.addControl('commitmentReasonID', new FormControl(54, Validators.required)); //voucher company
      //paymentCommitmentFormGroup.addControl('designation', new FormControl());
    }
    return paymentCommitmentFormGroup;
  }



  payMethodValidation = c => {
    if (!c.value || c.value === 0 || c.value === 'undefined') {
      return { required: true };
    }
    return null;
  };

  termIDValidation = c => {
    if ((!c.value || c.value === 0 || c.value === 'undefined') && this.payOption === 'cc') {
      return { required: true };
    }
    return null;
  };

  amountValidation = c => {
    if (!c.value || c.value === 0 || c.value === 'undefined') {
      return { required: true };
    }
    return null;
  };

  externalSoftwareValidation = c => {
    if (this.payMethodID && (this.payMethodID == 6232) && (!c.value || c.value == '' || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };

  doneOnValidation = c => {
    if (this.payMethodID && ((this.payMethodID == 420) || (this.payMethodID == 6232) || (this.payMethodID == 6990)) && (!c.value || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };


  voucherValidation = c => {
    // tslint:disable-next-line:max-line-length
    if (this.payMethodID && ((this.payMethodID == 3580)) && (!c.va) && (!c.value || c.value === 0 || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };


  checkValidation = c => {
    // tslint:disable-next-line:max-line-length
    if (this.payMethodID && ((this.payMethodID == 89)) && (!c.value || c.value === 0 || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };

  bankDetailsValidation = c => {
    // tslint:disable-next-line:max-line-length
    if (this.payMethodID && ((this.payMethodID == 420)) && (!c.value || c.value === 0 || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };

  accountLocationValidation = c => {
    // tslint:disable-next-line:max-line-length
    if ((!c.value || c.value === 'undefined') && (this.payMethodID && [88, 89, 420, 6232, 6990].indexOf(this.payMethodID) > -1)) {
      return { required: true };
    }
    return null;
  };

  transferReferenceValidation = c => {
    // tslint:disable-next-line:max-line-length
    if (this.payMethodID && (this.payMethodID == 420) && (!c.value || c.value === 0 || c.value === 'undefined')) {
      return { required: true };
    }
    return null;
  };

  moneyValueValidation = c => {
    if ((!c.value || c.value === 0 || c.value === 'undefined') && (this.payMethodID && this.payMethodID == 6990)) {
      return { required: true };
    }
    return null;
  };

  currencyValidation = c => {
    if (!c.value || c.value === 0 || c.value === 'undefined') {
      return { required: true };
    }
    return null;
  };

  private initForm() {
    this.paymentForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      ilsRate: [],
      calculatedAmountInILS: [],
      paymentID: [this.id | 0, Validators.required],
      contactID: [this.contactId, Validators.required],
      payMethodID: [null, this.payMethodValidation],
      externalSoftwarePayMethod: [, this.externalSoftwareValidation],
      isActive: [true, Validators.required],
      doneOn: [, this.doneOnValidation],
      transactinID: [],
      depositID: [],
      amount: [, Validators.required],
      billingDate: [new Date(), Validators.required],
      checkDate: [new Date(), this.payMethodID == 89 ? this.checkValidation : null],
      statusPayment: [, Validators.required],
      chicNumber: [, this.checkValidation],
      voucherNumber: [, this.voucherValidation],
      vouchersOrganizationID: [, this.voucherValidation],
      vouchersOrganizationName: [],
      vouchersOrgPaymentID: [],
      bankNumber: [], // , this.bankDetailsValidation הוסר בגלל בנקים בחו"ל
      bankName: [],
      bankBranchNumber: [], // , this.bankDetailsValidation הוסר בגלל בנקים בחו"ל
      bankBranchName: [],
      bankAcountNumber: [], // , this.bankDetailsValidation הוסר בגלל בנקים בחו"ל
      transferTaskID: [],
      nameOfChic: [],
      currency: [null, this.currencyValidation],
      paymentOption: [1, Validators.required],
      receiptAddressID: [],
      comment: [],
      receiptComment: [],
      contactTitleForReceipts: [],
      detailsLetterAsReceipt: [false, Validators.required],
      accountLocation: [null, this.accountLocationValidation],
      // accountLocationVM: [null, this.accountLocationValidation],
      transferReference: ['', this.transferReferenceValidation],
      moneyValueDescription: ['', this.moneyValueValidation],
      invoiceNumberForMoneyValue: ['', this.moneyValueValidation],
      invoiceDateForMoneyValue: [, this.moneyValueValidation],
      termID: [null, this.termIDValidation],
      paymentsPlanID: [],
      hashReceiptNumber: [],
      voucherPaymentIDs: [this.data.payment.voucherPaymentIDs, this.moneyValueValidation],

      paymentCommitments: this.formBuilder.array([]),
    });
    //this.paymentForm.controls['isActive'].valueChanges.subscribe(x => this.updateInactiveDate(x));

  }

  ngOnInit(): void {
    this.vouchersOrganizationID = this.data.vouchersOrganizationID;
    this.payment = this.data.payment;
    this.id = this.data.payment.id;
    this.initForm();
    this.load();


    //לאחר טעינת המודל
    this.paymentForm.controls['payMethodID'].valueChanges.subscribe(x => {
      this.payMethodID = x;
      this.loadTerms();
    });
  }

  ngAfterViewInit() {
  }

  removePayment() {
  }

}



