import { Subscription } from 'rxjs';
import { RegistrationEventsData } from './../registrationEvents.data'; 
import { ToraxHttp } from '../../utils/custom-http.svc';
import {  FormBuilder } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from '../../components/alert/alert.svc';  
import { ServicesData } from '../../services/services.data'; 

@Component({
    selector: 'registrationEvent-details',
    templateUrl: './registrationEvent-details.tmpl.html'
})

export class RegistrationEventDetails implements OnInit {
    id: number;
    registrationEvent: any;
    signalRSubscription: Subscription; 

    constructor( 
        private activatedRoute: ActivatedRoute, 
        public servicesData: ServicesData, 
        private registrationsEventData: RegistrationEventsData,
        private alert: AlertService,
        ) { 
    } 

    public loadRegistrationEvent(): void {
        this.registrationsEventData.get(this.id)
            .subscribe((x: any) => {
                this.registrationEvent = x; 
            });

    }


     ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = + params['registrationEventID'];
        }); 
        this.loadRegistrationEvent();
    }

 
     
    btnDisabled = false;
    runCharge() {
        this.btnDisabled = true;
        this.alert.info("שים לב: מצב הגביה יופיע בחלונית המוניטור מימין"); 
        this.registrationsEventData.chargePayments({ eventID: this.registrationEvent.registrationEventID })
            .subscribe((c: any) => {
                //this.btnDisabled = false;
            });
    }
}
