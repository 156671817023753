import { style } from '@angular/animations';
import { AmountInput } from '../amount-input/amount-input.cmp';

import { Component, OnInit, ViewChild, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AmountRangeClass } from './amount-input-range.class';
import { MatInput } from '@angular/material/input';

@Component({

    selector: 'amount-input-range',
    templateUrl: 'amount-input-range.tmpl.html'
})



export class AmountInputRange implements OnInit {
    constructor(
        private formBuilder: FormBuilder
    ) { }

    @Input('amountRangeCtrl') amountRangeCtrl: FormControl;
    @Input('placeHolderMin') placeHolderMin?: string = "החל מ...";
    @Input('placeHolderMax') placeHolderMax?: string = "עד ל...";
    @ViewChild('minInput') minInput: AmountInput
    @ViewChild('maxInput') maxInput: AmountInput

    amountRangeForm: FormGroup;
    amountRangeClass = new AmountRangeClass();


    private initForm() {
        let min = this.amountRangeCtrl.value ? this.amountRangeCtrl.value.min : null;
        let max = this.amountRangeCtrl.value ? this.amountRangeCtrl.value.max : null;
        let includeNulls = this.amountRangeCtrl.value ? this.amountRangeCtrl.value.includeNulls : false;
        this.amountRangeForm = this.formBuilder.group({
            min: [min],
            max: [max],
            includeNulls: [includeNulls, Validators.required]
        });
    }

    ngOnInit() {
        this.initForm();

        this.amountRangeCtrl.valueChanges.subscribe(x => {
            if (x == null) {
                this.amountRangeClass = new AmountRangeClass();
                this.initForm();
            }
        });


        this.amountRangeForm.valueChanges.subscribe(arg => {
            if (arg) {
                this.amountRangeClass.min = arg.min;
                this.amountRangeClass.max = arg.max;
                this.amountRangeClass.includeNulls = arg.includeNulls;
                this.amountRangeCtrl.setValue(this.amountRangeClass);
            }
        });
    }

}

