//import { MatRowKeyboardSelectionDirective } from './mat-row-keyboard-selection.directive';

import { NgModule } from '@angular/core';
import { ScrollableItemDirective } from './scrollable-item.directive';
import { LoadingStatusBarDirective } from './loading-status-bar.directive';
import { EnterTabDirective, ApplyEnterDirective } from './enter-tab.directive';
import { OptionalMatSelectDirective } from './optional-mat-select.directive';
import { FirstFocusDirective } from './first-focus-directive';
import { TestCDKDirective } from './test-directive.directive.ts';
import { CreditCardDirective } from './credit-card.directive';
import { NgxCopyPasteDirective } from './ngx-copypaste.directive';

@NgModule({
    imports: [],
    exports: [NgxCopyPasteDirective, EnterTabDirective, ApplyEnterDirective, OptionalMatSelectDirective, FirstFocusDirective, LoadingStatusBarDirective, ScrollableItemDirective, TestCDKDirective, CreditCardDirective],
    declarations: [NgxCopyPasteDirective, EnterTabDirective, ApplyEnterDirective, OptionalMatSelectDirective, FirstFocusDirective, LoadingStatusBarDirective, ScrollableItemDirective, TestCDKDirective, CreditCardDirective],
})
export class DirectivesMdl { }
