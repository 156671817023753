import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params  } from '@angular/router';
import { GroupsData } from '../groups.data';
import { Group } from './group';
import { ToraxHttp } from '../../utils/custom-http.svc';

@Component({	
	selector: 'group-details',
	templateUrl: './group-details.tmpl.html'
})

export class GroupDetails implements OnInit {
	constructor(
		private groupsData: GroupsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
            if (id > 0 && this.group && id === this.group.groupID) {
                this.loadGroup();
            }
        });
	}

	public group: Group;
	private id: number;

	public loadGroup(): void {
		this.groupsData.get(this.id)
			.subscribe((c:any) => this.group = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['groupID'];
			this.loadGroup();
		});
	}
}
