import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IvrAssignmentUserTasksData } from './ivr-assignment-user-tasks.data';
import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ServicesData } from '../services/services.data';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';
import { AlertService } from '../services/alert.service';
import { SubscriptionLike, distinctUntilChanged } from 'rxjs';
import { IndexService } from '../services/index-service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BreakpointObserver } from '@angular/cdk/layout';
import { GlobalHelper } from '../_helpers/global-helper';

@Component({
  selector: 'ivr-assignment-user-tasks-list',
  templateUrl: 'ivr-assignment-user-tasks-list.tmpl.html',
})

export class ivrAssignmentUserTasksList implements OnInit , AfterViewInit {
  modeType: string ='tablet';

  constructor(
    public ivrAssignmentUserTasksData: IvrAssignmentUserTasksData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
    private alert: AlertService,
    private observer: BreakpointObserver,
    public gh: GlobalHelper
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/honors') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }
  ngAfterViewInit(): void {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      this.modeType = '';
    });
    //console.log("111", this.gh.screenVHHeight);
  }


  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  displayedColumns: string[] = ['select', 'buttons', 'createDate', 'subject', 'comment', 'userName', 'contactName', 'doneByUserName', 'doneDate'];

  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  searchIvrAssignmentUserTasksListForm: FormGroup;

  actualMinusHeight = '185';

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  isChecked(row: any): boolean {
    const found = this.selection.selected.find(
      (el: any) => el.honorID === row.honorID
    );
    if (found) {
      return true;
    }
    return false;
  }



  openSelectedRow(ivrAssignmentUserTaskID) {     this.router.navigate(['', {outlets: {popup: ['ivrAssignmentUserTasks', ivrAssignmentUserTaskID, 'edit']}}]);
   }
  addNew() {
    this.router.navigate(['', {outlets: {popup: ['ivrAssignmentUserTasks',  'add']}}]);
  }

  exportToExcel() {
    this.ivrAssignmentUserTasksData.exportToExcel(this.searchIvrAssignmentUserTasksListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `דוח משימות.xlsx`;
        link.click();
      });
  }

  public downloadPdf(): void {

    this.ivrAssignmentUserTasksData.downloadPdf(this.searchIvrAssignmentUserTasksListForm.value)
      .subscribe(blob => {
        // saveAs(blob, 'testing.pdf');
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `PDF.pdf`;
        link.click();
        // const data = new Blob([blob], { type: 'application/pdf' });
        // saveAs(data);
        // console.log(data);
      });
  }

  private initForm() {
    this.searchIvrAssignmentUserTasksListForm = this.formBuilder.group({
      page: [1],
      pageRows: [30],
      query: [],
      contactID: [],
      hasDone: [false],
      subjects: [],
      userNames: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe((x) => {
      this.searchIvrAssignmentUserTasksListForm.controls["selectedRows"].setValue(
        x.source.selected.map((x: any) => x.honorID)
      );
      this.selectedRowsChange.emit(x.source.selected);
    });

  }


  // public getIvrAssignmentUserTasks() {
  //   let selectedsCtrl = this.searchIvrAssignmentUserTasksListForm.controls['selectedRows'];
  //   if (selectedsCtrl && selectedsCtrl.value) {
  //     let ivrAssignmentUserTaskIDs = selectedsCtrl.value.map(x => x).join();
  //   }

  //   if (this.paginator) {
  //     this.searchIvrAssignmentUserTasksListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
  //   }
  //   //this.searchIvrAssignmentUserTasksListForm.controls['pageRows'].setValue(this.paginator.pageSize);
  //   this.isLoadingResults = true;
  //   sessionStorage.setItem(this.router.url, JSON.stringify(this.searchIvrAssignmentUserTasksListForm.value));
  //   return this.ivrAssignmentUserTasksData!.getList(this.searchIvrAssignmentUserTasksListForm.value)
  //     .subscribe((data: any) => {
  //       this.data = data;
  //       this.sourceList = data.list;
  //       this.page = data.list.length;
  //       this.pageRows = data.list.length;
  //       this.rowsCount = data.rowsCount;

  //       this.isLoadingResults = false;
  //       this.isRateLimitReached = false;
  //     });
  // }

  ngOnInit() {
    this.initForm();

    this.activatedRoute.params.forEach((params: Params) => {

    });

    //this.ivrAssignmentUserTasksData.initForm();
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchIvrAssignmentUserTasksListForm.patchValue(frm);
    }

    this.searchIvrAssignmentUserTasksListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
      // { key: "contactID", value: this.contactID },
    ];
    this.indexService = new IndexService(this.searchIvrAssignmentUserTasksListForm, params, this.ivrAssignmentUserTasksData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();



  }

}
