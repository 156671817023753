import { Modal } from './modal.class';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ModalService {

	constructor() { }

	public OpenModel = new EventEmitter<Modal>();

	public show(modal: Modal): Function {

		this.OpenModel.emit(modal);
		return () => { };
	}
}
