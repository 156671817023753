import { VouchersOrganization } from './vouchersOrganization/vouchersOrganization';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { ObjectsListResult } from '../services/objects-list-results';

@Injectable()

export class VouchersOrganizationsData {
	  private url = 'api/vouchersOrganizations';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }



    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(vouchersOrganization: VouchersOrganization) {
        return this.toraxHttp.update(this.url, vouchersOrganization);
    }

    saveNewOrgPayment(payment: any) {
        return this.toraxHttp.update(`${this.url}/saveNewOrgPayment`, payment);
    }


    getAdd(contactID: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactID || 0}`);
    }


     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}
