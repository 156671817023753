import { MaterialModule } from '../../material.module';
import { MainFormModalModule } from '../../components/main-form-modal/main-form-modal.mdl';
import { AutoCompleteModule } from '../../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputModule } from '../../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from '../../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from '../../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../../components/panel-box/panel-box.mdl';
import { AmountAndCurrencyInputModule } from '../../components/amount-and-currency-input/amount-and-currency-input.module';
import { ModelInfoModule } from '../../components/model-info/model-info.mdl';
import { EmergencySquadQueuesRoutes } from './emergency-squad-queues.routing';
import { EmergencySquadQueueMain } from './emergency-squad-queue/emergency-squad-queue-main.cmp';
import { EmergencySquadQueuesList } from './emergency-squad-queues-list.cmp';
import { EmergencySquadQueueEdit } from './emergency-squad-queue/emergency-squad-queue-edit.cmp';
import { EmergencySquadQueueDetails } from './emergency-squad-queue/emergency-squad-queue-details.cmp';
import { EmergencySquadQueuesData } from './emergency-squad-queues.data';
import { PhonePipeMdl } from 'src/app/components/pipes/phone-pipe';
import { DateTimeInputModule } from 'src/app/components/dateTimeInput/date-time-input.mdl';
import { TimeInputModule } from 'src/app/components/timeInput/time-input.mdl';


@NgModule({
	imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		DateInputModule,
		AutoCompleteModule,
		RouterModule.forChild(EmergencySquadQueuesRoutes),
		MaterialModule,
		MainFormModalModule,
		AmountAndCurrencyInputModule,
    ModelInfoModule,
    PhonePipeMdl,
    DateTimeInputModule,

	],
	declarations: [
		EmergencySquadQueueMain,
		EmergencySquadQueuesList,
		EmergencySquadQueueEdit,
		EmergencySquadQueueDetails,
	],
	exports: [
		EmergencySquadQueuesList,
		EmergencySquadQueueMain,
		EmergencySquadQueueEdit,
		EmergencySquadQueueDetails,
	],
	providers: [
		EmergencySquadQueuesData
	]
})
export class EmergencySquadQueuesMdl { }

