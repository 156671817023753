import { ToraxHttp } from '../../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { EmergencySquadMember } from './emergency-squads-member/emergency-squad-member-queue';

@Injectable()

export class EmergencySquadMembersData {
  private url = 'api/emergencySquadMembers';


  constructor(
    private toraxHttp: ToraxHttp
  ) { }

  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }



  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  update(emergencySquadMember: EmergencySquadMember) {
    return this.toraxHttp.update(this.url, emergencySquadMember);
  }

  getAdd(emergencySquadMemberId: number) {
    return this.toraxHttp.get(`${this.url}/add/${emergencySquadMemberId || 0}`);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}
