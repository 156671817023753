
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';
import { CampaignAttachedsData } from './campaign-attacheds.data';
import { IndexService } from '../services/index-service';


@Component({
	selector: 'campaign-attacheds-list',
	templateUrl: 'campaign-attacheds-list.tmpl.html'
})

export class CampaignAttachedsList implements OnInit {
	constructor(
		private campaignAttachedsData: CampaignAttachedsData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private router: Router,
		private toraxHttp: ToraxHttp,
		private cdref: ChangeDetectorRef,
	) {
		this.toraxHttp.updatedEvent.subscribe((obj: any) => {
			if (obj.id > 0) { //TODO אולי יתרענן מדי הרבה
			  this.indexService.getList();
			  this.cdref.detectChanges();
			}
		  });
	}

	@Input('campaignID') public campaignID?: number | null = null;
	indexService: IndexService;

	displayedColumns: string[] = ['buttons', 'attachedName', 'count', 'comment', 'contactID'];
	data: any[] = [];

	resultsLength = 0;
	isRateLimitReached = false;

	@ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	searchCampaignAttachedsListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;

	public clerFilter() {
		this.initForm();
	}



	addNew() {
		this.router.navigate(['campaigns', { outlets: { popup: ['campaignAttacheds', this.campaignID ,'add'] } }]);
	  }

	openSelectedRow(campaignAttachedID) {
		this.router.navigate(['', { outlets: { popup: ['campaignAttacheds', campaignAttachedID] } }]);
	}


	// private getCampaignAttacheds() {

	// 	if (this.campaignID && this.campaignID > 0) {
	// 		this.searchCampaignAttachedsListForm.controls['campaignID'].setValue(this.campaignID);
	// 	}

	// 	this.searchCampaignAttachedsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);

	// 	sessionStorage.setItem(this.router.url, JSON.stringify(this.searchCampaignAttachedsListForm.value));

	// 	return this.campaignAttachedsData!.getList(this.searchCampaignAttachedsListForm.value)
	// 		.subscribe((data: any) => {
	// 			this.data = data;
	// 			this.sourceList = data.list;
	// 			this.page = data.list.length;
	// 			this.pageRows = data.list.length;
	// 			this.rowsCount = data.rowsCount;


	// 			this.isRateLimitReached = false;

	// 		});
	// }
	defaultvalues: any[];

	private initForm() {
		this.searchCampaignAttachedsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			campaignID: [],
			selectedRows:[],
			orders: this.formBuilder.array([])
		});


		let frm = this.searchCampaignAttachedsListForm;
		this.defaultvalues = Object.keys(this.searchCampaignAttachedsListForm.value).map(function (k) {
			let x = frm.controls[k];
			if (x.validator == Validators.required) {
				k = x.value;
			}
		});


	}


	panelOpenState;
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;
	ngOnInit() {
		this.initForm();

		// let item = sessionStorage.getItem(this.router.url);
		// if (item) {
		// 	let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
		// 	this.searchCampaignAttachedsListForm.patchValue(frm);
		// }




		//this.searchCampaignAttachedsListForm.updateValueAndValidity();
		let params = [
			{ key: "campaignID", value: this.campaignID },
		];

		this.indexService = new IndexService(this.searchCampaignAttachedsListForm, params, this.campaignAttachedsData, this.router, this.defaultvalues);

		// if (this.indexService) {
		// 	this.indexService.getList();
		// }



	}




}
