<form  *ngIf="objectsListResult" [formGroup]="searchVouchersOrganizationsListForm" novalidate>
    <panel-box title="ארגונים" [showClose]="false" [showCollapse]="false">
        <span class="panel-tools">
            <panel-box-btn (btnClick)="getVouchersOrganizations()" icon="fa-refresh"></panel-box-btn>

        </span>
        <div class="panel-content">
            <table class="table table-striped no-margins">
                <thead>
                    <tr>
                        <th>...</th>
                        <th>שם ארגון</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vo of objectsListResult.list">
                        <td>
                            <a [routerLink]="[ '', {outlets: {popup: [ 'vouchersOrganizations', vo.vouchersOrganizationID]}}]">
                                <i class="fa fa-folder-open"></i>
                            </a>  
                        </td>
                        <td>{{ vo.organizationName }}</td>
                    </tr>
                </tbody>
            </table>
            <pagination [rowsCount]="objectsListResult.rowsCount" [currentPage]="objectsListResult.page" [pagesCount]="objectsListResult.pagesCount"
                (change)="changePage($event)"></pagination>

        </div>
    </panel-box>
</form>