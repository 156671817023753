<div *ngIf="data">

    <div class="ibox-content no-padding">
        <ul class="list-group">
            <li *ngFor="let p of paymentCommitmentsList" class="list-group-item">
                <p> <b>{{ p.contactName }}</b>
                    <!-- <a [routerLink]="['contacts', p.contactID]" NavigationStart="closePopup()"></a> -->
                    <br>
                    <small> {{ p.shortAddressTitle }}</small>
                </p>
                <p style="display: flow-root;">
                    <strong class="pull-left">{{ p.partial | number }}{{ p.currencyIcon }} <br> <small>{{ p.methodTitle }}</small> </strong>
                    <span class="pull-right">{{ p.commitmentReasonName }} <br> {{ p.commitmentSubjectName }}</span>
                </p>
                <p>
                    <small class="block text-muted">
                    טיפול בקבלה:<br>
                     <span *ngIf="p.receiptCreatedBy || p.receiptCreatedDate">{{ p.receiptCreatedBy }} {{ p.receiptCreatedDate | date:'dd/MM/yyyy' }} {{p.receiptCreatedJewishDate}}</span><br>
                     <span *ngIf="p.receiptChangedBy || p.receiptChangedDate">{{ p.receiptChangedBy }} {{ p.receiptChangedDate | date:'dd/MM/yyyy' }} {{p.receiptChangedJewishDate}}</span>
            </small>
                </p>
            </li>
        </ul>
    </div>

    <!--
    <table class="table table-striped no-margins">
        <tbody>
            <tr *ngFor="let p of paymentCommitmentsList">
                <td>{{ p.doneOn | date:'dd/MM/yyyy' }} {{ p.jewishDoneOn}}</td>
                <td *ngIf="p.payMethodID != 88 && p.payMethodID != 89" class="ltr text-right">
                    <a [routerLink]="[ '', {outlets: {popup: [ 'payMethods', p.payMethodID]}}]">{{ p.payMethodTitle }}</a>
                </td>
                <td *ngIf="p.payMethodID == 88">
                    מזומן
                </td>
                <td *ngIf="p.payMethodID == 89">
                    שיק
                </td>
                <td>{{ p.partialAmount }}{{ p.currencyIcon }}</td>
                <td>{{ p.commitmentSubjectName }}</td>
                <td>{{ p.designation }}</td>
            </tr>
        </tbody>
    </table> -->
</div>
