import { VouchersOrganizationsData } from './../vouchersOrganizations.data';
import { VouchersOrgPaymentEditDlg } from './../vouchers-org-payment/vouchers-org-payment-edit-dlg.cmp';
import { AlertService } from './../../services/alert.service';
import { PaymentsData } from './../../payments/payments.data';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { groupBy, toArray, map, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VouchersOrganization } from './vouchersOrganization';
import { FormBuilder, FormGroup } from '@angular/forms';
import { from } from "rxjs";
import * as _ from 'lodash';
import { Location } from '@angular/common';

@Component({
	selector: 'vouchersOrganization-details',
	templateUrl: './vouchersOrganization-details.tmpl.html'
})


export class VouchersOrganizationDetails implements OnInit {
	groupedVouchers: {
		currency: number;
		currencyIcon: string;
		amount: number;
		voucherPaymentIDs: number[];
	}[];


	constructor(
		private vouchersOrganizationsData: VouchersOrganizationsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp,
		private formBuilder: FormBuilder,
		private cdref: ChangeDetectorRef,
		private location: Location,
		private paymentsData: PaymentsData,
		private alertSvc: AlertService,
		private router: Router,
		public dialog: MatDialog,

	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (this.vouchersOrganization && id === this.vouchersOrganization.vouchersOrganizationID) {
				this.loadVouchersOrganization();
			}
		});
	}

	public vouchersOrganization: VouchersOrganization;
	private id: number;
	vouchersOrganizationForm: FormGroup;
	tempNewPayment;
	selectedPayments;
	waitingAndFailing = [1, 3];
	succeeded = [2];

	data = [
		{ name: "A", price: 100, qty: 5 },
		{ name: "B", price: 75, qty: 15 },
		{ name: "A", price: 100, qty: 5 },
		{ name: "A", price: 100, qty: 5 },
		{ name: "C", price: 10, qty: 25 },
		{ name: "B", price: 50, qty: 15 }
	];
	groupedData: any = [];

	selectedPaymentsRowsChange($event) {
		from($event)
			.pipe(
				groupBy(// Group them by category and return the appropriate Arrays
					(val: any) => val.currency
				),
				mergeMap(group => {
					return group.pipe(toArray());
				}),
				mergeMap((array) => {// Take each from above array and group each array by manDate
					return from(array).pipe(groupBy(
						val => val.manDate,
					),
						mergeMap(group => {
							return group.pipe(toArray()); // return the group values as Arrays
						})
					);
				}),
				map((val: any) => { //For each array returned , calculate the sum and map it to the Object you wanted
					let amount = 0;
					val.map(v => {
						amount = amount + v.amount;
					});
					return {
						currency: val[0].currency,//grouped them by currency			
						currencyIcon: val[0].currencyIcon, // using lodash to sum quantity
						voucherPaymentIDs: val.map((p: any) => p.paymentID),
						amount: _.sumBy(val, 'amount'), // using lodash to sum Amount
					}
				}),
				toArray() //finally combine all returned objects to an array
			).subscribe(
				val => {
					console.log(val);
					this.groupedVouchers = val;
				}
			);

		//   from($event).pipe(
		// 		groupBy((x: any) => x.currency),
		// 		switchMap(group => {
		// 			console.log("group", group);
		// 			return group; //group.toArray()
		// 		}),
		// 		map(g => {// mapping 
		// 			console.log("g", g);
		// 			return {
		// 				currency: g.currency,//grouped them by currency			
		// 				currencyIcon: g.currencyIcon, // using lodash to sum quantity
		// 				voucherPaymentIDs: g.map((p: any) => p.paymentID),
		// 				amount: _.sumBy(g, 'amount'), // using lodash to sum Amount
		// 			}
		// 		}),
		// 		toArray()
		// 	)
		// 		.subscribe(d => {
		// 			console.log("d", d);
		// 			this.groupedData = d;
		// 		}
		// 		);


		// return 	from($event).pipe(
		// 		groupBy((x:any) => x.currency), // using groupBy from Rxjs
		// 		 mergeMap(group => {
		// 			 console.log("group",group);
		// 			 return group;
		// 			}),// GroupBy dont create a array object so you have to flat it
		// 		map((g:any) => {// mapping 
		// 		console.log(g);
		// 			return {
		// 			currency: g[0].currency,//grouped them by currency			
		// 					currencyIcon: g[0].currencyIcon, // using lodash to sum quantity
		// 					voucherPaymentIDs: g.map((p:any) => p.paymentID),
		// 					amount: _.sumBy(g, 'amount'), // using lodash to sum Amount
		// 		  }
		// 		}),
		// 		toArray(), //.toArray because I guess you want to loop on it with ngFor      
		// 		//do(sum => console.log('sum:', sum)) // just for debug


		// 	).subscribe(d => {
		// 		console.log("d", d);
		// 		this.groupedVouchers = d;
		// 	}); 



		// .groupBy((x: any) => x.currency) // using groupBy from Rxjs
		// 	.flatMap(group => {
		// 		return group.toArray();
		// 	})// GroupBy dont create a array object so you have to flat it
		// 	.map(g => {// mapping 
		// 		return {
		// 			currency: g[0].currency,//grouped them by currency			
		// 			currencyIcon: g[0].currencyIcon, // using lodash to sum quantity
		// 			voucherPaymentIDs: g.map(p => p.paymentID),
		// 			amount: _.sumBy(g, 'amount'), // using lodash to sum Amount
		// 		}
		// 	})
		// 	.toArray() //.toArray because I guess you want to loop on it with ngFor      
		// 	.do(sum => {
		// 		console.log('sum:', sum);
		// 	}
		// 	) // just for debug
		// 	.subscribe(d => {
		// 		this.groupedVouchers = d;
		// 	}
		// 	);

	}

	openAddOrEditPayment(payment): void {

		//console.log("payment", payment);
		setTimeout(() => {
			const dialogRef = this.dialog.open(VouchersOrgPaymentEditDlg, {
				data: { contactId: payment.contactID | this.vouchersOrganization.contactID, organizationName: this.vouchersOrganization.organizationName, vouchersOrganizationID: this.id, payment: payment }
			});


			dialogRef.afterClosed().subscribe(result => {
				if (result && result.valid) {
					this.savePayment(result);
				}
			});
		}, 200);
	}

	private savePayment(paymentForm: FormGroup) {
		this.vouchersOrganizationsData.saveNewOrgPayment(paymentForm.value)
			.subscribe((pm: any) => {
				//this.getPayments();
				return true;
			})
	}



	public loadVouchersOrganization(): void {
		this.vouchersOrganizationsData.get(this.id)
			.subscribe((c: any) => {
				this.vouchersOrganization = c;
				// this.tempNewPayment = new Payment();
				// this.tempNewPayment.contactID = this.vouchersOrganization.contactID;
			});
	}


	private initForm() {
		this.vouchersOrganizationForm = this.formBuilder.group({
			vouchersOrganizationID: [this.id || 0]
		});
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['vouchersOrganizationID'];
			this.loadVouchersOrganization();
		});

		this.setPosAttr(0);
		this.initForm();
	}

	activeTabIndex = 0;
	public itemSelect(event) {
		this.activeTabIndex = event.index;
		this.setPosAttr(event.index);

		this.cdref.detectChanges();
	}

	setPosAttr(ix) {
	// 	let pos = "";
	// 	switch (ix) {
	// 		case 0:
	// 			pos = "pendings";
	// 			break;
	// 		case 1:
	// 			pos = "paids";
	// 			break;
	// 		case 2:
	// 			pos = "accepteds";
	// 			break;
	// 	}
	// 	this.location.replaceState(`${this.router.url}#${pos}`);
	 }
}

