
import { DashboardMarbesDataService } from './dashboard-marbe.data';
import {  Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-marbe-monies',
  templateUrl: 'dashboard-marbe-monies.component.html',
  styleUrls: ['dashboard-marbe.component.scss'],
  // encapsulation: ViewEncapsulation.None

})
export class DashboardMarbeMoniesComponent implements OnInit {
  constructor(
    private dashboardMarbesDataService: DashboardMarbesDataService
  ) { }

  ngOnInit() {
    this.loadMoneySums();
  }
  model: any;


  public loadMoneySums() {
    this.dashboardMarbesDataService.getMoneySums()
      .subscribe((x: any) => {
        this.model = x;
      });
  }

}
