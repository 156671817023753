﻿ <div>
    <div class="example-container"  *ngIf="indexService" style="background-color: white; border-bottom: 10px solid rgb(243, 243, 244)">
        <div class="example-button-row" style="white-space: nowrap;" fxLayout="row"
            style="position: sticky; float: left; left: 5px;  z-index: 9999;">
            <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן" (click)="indexService.selectionChanged($event)"><i
                    class="fa fa-filter"></i></button>
            <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
                    class="material-icons">clear</i></button>
        </div>
        <div class="column" style="padding-top: 12px; overflow: auto">
            <div fxLayout="row" class="scrolling-wrapper">
                <contacts-search-input fxFlex="1 1 40%" #searchInp [query]="searchContactsListForm.controls.query"
                    (onChangeTxt)="searchTxt($event)"></contacts-search-input>

                &nbsp;&nbsp;
                <auto-complete style="background-color: white;" [smallTemplate]="true" fxLayout="row" apply-enter
                    #cityID [placeholder]="'עיר...'" (onSelect)="indexService.selectionChanged($event)" [controllerName]="'Cities'"
                    [formGroupModel]="searchContactsListForm" [idControl]="searchContactsListForm.controls.cityID"
                    [nameControl]="searchContactsListForm.controls.cityTitle">
                </auto-complete>
                &nbsp;&nbsp;
                <mat-select *ngIf="servicesData.ContactGroupPriorities" class="form-control small-input" apply-enter
                    (selectionChange)="selectionChanged($event)" placeholder="דירוג..."
                    [formControl]="searchContactsListForm.controls.groupPriorities" multiple>
                    <mat-option *ngFor="let g of servicesData.ContactGroupPriorities" [value]="g.value">{{g.anme}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    <mat-drawer-container>
        <mat-drawer [mode]="'side'" [opened]="selectedContact != null" [position]="'end'" [disableClose]="true"
            #matDrawer>
            <contact-dashboard-report [contact]="selectedContact" [groupSubjects]="groupSubjectsAsStr">
            </contact-dashboard-report>
        </mat-drawer>

        <mat-drawer-content>
          <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
            <div fxFlex>
                <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="100" [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
                  <div *cdkVirtualFor="let row of indexService.sourceList" class="example-item">
                      <div fxFlex="100" class="feed-element">
                          <div class="example-full-width" fxLayout="row" fxLayoutAlign="start center" style="text-align: right; font-size: 20px; padding: 10px"
                              fxLayoutAlign="start center" fxFlex="8">
                              <a>
                                  <i *ngIf="row.isFavorite" class="fa fa-star isFavorite-icon"
                                      (click)="toogleContactFavorite(row)"
                                      matTooltip="לחץ כדי להסיר ממועדפים"></i>
                                  <i *ngIf="!row.isFavorite" class="fa fa-star-o isFavorite-icon"
                                      (click)="toogleContactFavorite(row)"
                                      matTooltip="לחץ כדי להוסיף למועדפים"></i>
                              </a>
                          </div>
                          <div fxFlex class="media-body" style="padding-right: 10px; width: min-content;" (click)="goToContact(row)">
                              <h2 class="font-bold no-margins">
                                  <small>{{row.perfix}}</small>
                                  <br *ngIf="row.perfix">
                                  {{row.firstName}} {{row.lastName}}
                                  <br>
                                  <small>
                                      <small>
                                          {{row.streetLine}}
                                          <br *ngIf="row.streetLine">
                                          {{row.cityName}}
                                      </small>
                                  </small>
                              </h2>
                          </div>
                      </div>
                  </div>
                </cdk-virtual-scroll-viewport>
              </div>
              <div fxFlex="15">
                <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
                  [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
                </mat-paginator>
              </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
