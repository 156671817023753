<div *ngIf="citiesModel" style="height: 100%;">
  <div>
    <h1>{{citiesModel.title}} <span  class="label label-primary">לחץ <small>(והחזק)</small> על עיר כדי להציג מצב שטיבלך ונתונים נוספים</span> </h1>
    <div fxLayout="row" style="max-height: 360px; background-color: rgb(139 139 139 / 53%);">
      <div (click)="loadShtibls(clmn.extra.cityID)" *ngFor="let clmn of citiesModel.columnModels" class="item-container"
        customToolTip [contentTemplate]="template">
        <div class="item-head">{{ (clmn.value / 100) | number }} </div>
        <div fxFlexFill class="item-body" fxLayout="column" [style.background]="styleObject(clmn)">
          <div class="item-body-title">
            {{clmn.name}}
          </div>
        </div>
        <ng-template #template>
          <div style="padding:6px;background-color: #e7eaec3b">
            <h2 style="text-decoration: underline; font-weight: bold;  text-align: center; line-height: 24px;">
              {{clmn.extra.cityNameH}} ({{(clmn.value / 100) | number }})</h2>
            מס' יחידות: {{clmn.extra.cityUnitsSum}}
            <br>
            אחוז יחידות: {{ clmn.extra.percents }}%
            <br>
            מס' המשתתפים: {{clmn.extra.cityPart}}
            <br>
            אחוז משתתפים: {{clmn.extra.partPercents }}%
            <br>
          </div>
        </ng-template>
      </div>
    </div>
    <dashboard-marbe-shtibels *ngIf="shtibelsModel" [shtibelsModel]="shtibelsModel"></dashboard-marbe-shtibels>
  </div>
</div>
