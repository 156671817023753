
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SubscriptionLike } from "rxjs";
import { ServicesData } from "src/app/services/services.data";
import { ToraxHttp } from "src/app/utils/custom-http.svc";
import { AlertService } from "src/app/components/alert/alert.svc";
import { IndexService } from "src/app/services/index-service";
import { EmergencySquadMembersData } from "./emergency-squad-members.data";

@Component({
  selector: "emergency-squad-members-list",
  templateUrl: "emergency-squad-members-list.tmpl.html",
})
export class EmergencySquadMembersList implements OnInit, OnDestroy {
  constructor(
    private emergencySquadMembersData: EmergencySquadMembersData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
    private alert: AlertService
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/emergencySquadMembers') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  displayedColumns: string[] = ["select", "buttons", "emergencySquadMemberID", "contactName", "cityName", "shtibelName", "phoneNumber1", "phoneNumber2"];

  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  searchEmergencySquadMembersListForm: FormGroup;

  actualMinusHeight = '185';
  //@Input("contactID") public contactID?: number | null = null;

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  isChecked(row: any): boolean {
    const found = this.selection.selected.find(
      (el: any) => el.emergencySquadMemberID === row.emergencySquadMemberID
    );
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  addNew() {
    this.router.navigate([{ outlets: { popup: ["add"] } }]);
  }

  openSelectedRow(emergencySquadMemberID) {
    this.router.navigate(["", { outlets: { popup: ["emergencySquadMembers", emergencySquadMemberID] } }]);
  }


  private initForm() {
    this.searchEmergencySquadMembersListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      selectedRows: [],
      orders: this.formBuilder.array([]),
    });

    this.selection.changed.subscribe((x) => {
      this.searchEmergencySquadMembersListForm.controls["selectedRows"].setValue(
        x.source.selected.map((x: any) => x.emergencySquadMemberID)
      );
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {
    this.initForm();

    this.activatedRoute.params.forEach((params: Params) => {
      //this.contactID = +params["contactID"] || null;
    });
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchEmergencySquadMembersListForm.patchValue(frm);
    }

    this.searchEmergencySquadMembersListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
     // { key: "contactID", value: this.contactID },

    ];
    this.indexService = new IndexService(this.searchEmergencySquadMembersListForm, params, this.emergencySquadMembersData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();


    // if (this.contactID) {
    //   this.actualMinusHeight = '362';
    // }
  }
}
