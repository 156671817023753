<mat-toolbar matDialogTitle class="mat-accent m-0">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
    <h1 mat-dialog-title>עריכת פרטי איש</h1>
  </mat-toolbar-row>
</mat-toolbar>
<div mat-dialog-content *ngIf="formData" [formGroup]="formData">
  <div fxLayout="column" class="form-vertical">
    <fieldset class="form-group">
      <legend>פרטים אישיים</legend>
      <div style="padding-left: 5px" fxLayout="column">
        <auto-complete-input apply-enter cdkFocusInitial [placeholder]="'תואר'" [controllerName]="'Perfixes'"
          [stringValueControl]="formData.controls.perfix" [formGroupModel]="formData">
        </auto-complete-input>&nbsp;
        <div class="input-group mb-3">
          <label>שם פרטי:</label>
          <input type="text" class="form-control" apply-enter formControlName="firstName" placeholder="שם פרטי...">
        </div>&nbsp;
        <div class="input-group mb-3">
          <label>שם משפחה:</label>
          <input type="text" class="form-control" apply-enter formControlName="lastName" lastName="שם משפחה...">
        </div>&nbsp;
        <auto-complete-input apply-enter [placeholder]="'סיומת'" [controllerName]="'Suffixes'"
          [stringValueControl]="formData.controls.suffix" [formGroupModel]="formData">
        </auto-complete-input>
        &nbsp;
        <auto-complete apply-enter [placeholder]="'אב...'" [controllerName]="'Contacts'" [formGroupModel]="formData"
          [idControl]="formData.controls.fatherID" [nameControl]="formData.controls.fatherName">
        </auto-complete>
        &nbsp;

        <div class="input-group mb-3">
          <label>שיוך לגור:</label>
          <mat-select class="form-control" apply-enter formControlName="affiliation" placeholder="שיוך לגור..."
            required>
            <option [value]="">...</option>
            <mat-option *ngFor="let affiliation of servicesData.ContactAffiliations" [value]="affiliation.value">
              {{affiliation.name}}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </fieldset>
<label class="label">שים לב: לעריכת נתונים נוספים יש להיכנס לפרטי איש דרך הכרטסת</label>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="delete()">מחק</button>
    <button mat-button (click)="onNoClick()">ביטול</button>
    <button mat-button [mat-dialog-close]="formData">שמור</button>
  </div>
</div>
