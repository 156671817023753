import { AuthenticationService } from './auth.service';
import { AuthGuardDirective } from './auth.guard.diractive';
import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';




//@Injectable({ providedIn: 'root' })
@NgModule({
	imports: [
		CommonModule,
     	],
	declarations: [
		AuthGuardDirective
	],
	exports: [
		AuthGuardDirective
	],
	providers: [
		AuthenticationService
	]
})
export class AuthGuardMdl { }

