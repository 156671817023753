import { DatePipe } from '@angular/common';
import { style } from '@angular/animations';
import { Component, OnInit, ViewChild, HostListener, ElementRef, Input, Output, AfterViewInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
    selector: 'date-input',
    templateUrl: 'date-input.tmpl.html',
    styleUrls: ['./date-input.css']
})



export class DateInput implements AfterViewInit {
    dt?;
    setControlDateValue: any;

    constructor(private changeDetector: ChangeDetectorRef, private datepipe: DatePipe) { }

    @Input('dateControl') public dateControl: FormControl;
    @Input('withBorder') withBorder: Boolean = false;
    @Input('rangeTemplate') rangeTemplate?: boolean = false;
    @Input('placeholder') placeholder?: string = 'בחר תאריך...';
    @Input('disabled') disabled?: boolean = false;

    @Input('withJewishDate') withJewishDate: Boolean = true;
    @ViewChild('ndt') ndt: ElementRef;
    @ViewChild('ndtJewish') ndtJewish: ElementRef;
    @Input('withLabel') public withLabel: boolean = true;


    @Output() dateChange = new EventEmitter<Date>();
    public obserDate: Observable<ElementRef> = new Observable<ElementRef>();


    hidePicker(e) {
        e.preventDefault();
        if (this.ndt) {
            let inpt = this.ndt.nativeElement;

            let mn: any;
            mn = jQuery(inpt);
            this.setControlDateValue(inpt.value);
            mn.flexcal("hide");
        }

    }


    public selectText(event) { event.target.select(); }


    ngAfterViewInit(): void {

        let dateChangeEvent = this.dateChange;
        setTimeout(() => {
            let dateCtrl = this.dateControl;

            let formatInputs = this.formatInputsFunc;

            let f = (dateStr: string) => {
                let items: string[];
                if (dateStr) {
                    items = dateStr.split('/');
                }
                if (dateStr && items && items.length == 3) {
                    dateCtrl.setValue(`${items[2]}-${items[1]}-${items[0]}`);
                } else {
                    dateCtrl.setValue(null);
                }
            }

            this.setControlDateValue = f;
            dateCtrl.updateValueAndValidity();

            dateCtrl.valueChanges.subscribe(x => {
                dateChangeEvent.emit(x);
                if (x == "" || x == null) {
                    this.ndt.nativeElement.value = "";
                    $(this.ndt.nativeElement).siblings("#jewishDate").text("");
                }
            });

            if (dateCtrl.value != null) {
                if (new Date(dateCtrl.value) > new Date('01-01-01')) {
                    this.dt = dateCtrl.value;
                }

                if (this.disabled == true) {
                    dateCtrl.disable();
                } else {
                    dateCtrl.enable();
                }
            }

            let heJewish = (<any>$).bililite.flexcal.tol10n(['he-jewish', { dateFormat: 'dddd MM YYYY' }]);
            let georgi = (<any>$).bililite.flexcal.tol10n(['he', { dateFormat: 'dd/mm/yyyy' }]);
            let mn: any;
            mn = jQuery(this.ndt.nativeElement);
            mn.removeClass('hasDatepicker');
            mn.flexcal({
                calendars: [
                    [
                        'he',
                        'לועזי',
                        {
                            closeText: "סגור",
                            prevText: "&#x3C;הקודם",
                            nextText: "הבא&#x3E;",
                            currentText: "היום",
                            monthNames: ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
                            monthNamesShort: ["ינו", "פבר", "מרץ", "אפר", "מאי", "יוני", "יולי", "אוג", "ספט", "אוק", "נוב", "דצמ"],
                            dayNames: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
                            dayNamesShort: ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "שבת"],
                            dayNamesMin: ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "שבת"],
                            dateFormat: "dd/mm/yyyy",
                            isRTL: true,
                        }
                    ],
                    [
                        'he-jewish',
                        'עברי',
                        {
                            currentText: "היום",
                            isRTL: true,
                        }
                    ],
                ],
                position: 'br',
                duration: 1,
                changeMonth: true,
                changeYear: true,
                // buttons: ['today commit'],
                set: this.formatDate(heJewish, georgi, dateCtrl, dateChangeEvent),
                commit: this.formatDate(heJewish, georgi, dateCtrl, dateChangeEvent, true),
                hidden: function (e, d) {
                    f(e.target.value);
                    // if (dateCtrl.value == "" || dateCtrl.value == null) {
                    //     $(this).siblings("#jewishDate").text("");
                    // } else {
                    formatInputs(dateCtrl, heJewish, georgi, e);
                    // let date = new Date(dateCtrl.value);
                    // let j = (<any>$(this)).flexcal('format', date, heJewish);
                    // $(this).siblings("#jewishDate").text(j);
                    // let g = (<any>$(this)).flexcal('format', date, georgi);
                    // e.target.value = g;
                    //}
                },
                create: function (e, d) {
                    // if (dateCtrl && dateCtrl.value != null && dateCtrl.value != "") {
                    formatInputs(dateCtrl, heJewish, georgi, e);
                    // }
                    // if (f) {
                    //     f("");
                    // }
                },
            });
            this.changeDetector.detectChanges();
        }, 220);



    }

    private formatInputsFunc(dateCtrl: FormControl, heJewish: any, georgi: any, e: any) {
        let input = e.target;
        if (dateCtrl.value == "" || dateCtrl.value == null) {
            $(input).siblings("#jewishDate").text("");
        } else {
            let date = new Date(dateCtrl.value);
            let j = (<any>$(input)).flexcal('format', date, heJewish);
            $(input).siblings("#jewishDate").text(j);
            let g = (<any>$(input)).flexcal('format', date, georgi);
            e.target.value = g;
        }
    }

    private formatDate(heJewish: any, georgi: any, dateCtrl: FormControl, dcc: any, emitChangeEvent: boolean = false) {
        let f = this.setControlDateValue;
        //     let date = dateCtrl;
        return function (e, d) {
            if (this.value != "") {
                let j = (<any>$(this)).flexcal('format', d, heJewish);
                $(this).siblings("#jewishDate").text(j);
                let g = (<any>$(this)).flexcal('format', d, georgi);
                if (f) {
                    f(g);
                }
            } else {
                if (f) {
                    f("");
                }
            }
        };
    }

    // public mask = {
    //     guide: true,
    //     showMask: true,
    //     mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
    // };
}

