import { TishTable } from './tishTable/tishTable';
import { Injectable, EventEmitter } from '@angular/core';
import { ToraxHttp } from '../utils/custom-http.svc';
import { Validators, FormBuilder } from '@angular/forms';


@Injectable()

export class TishTablesData {
    private url = 'api/tishTables';

    constructor(
        private toraxHttp: ToraxHttp,
        private formBuilder: FormBuilder,
    ) { }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number){
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number){
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getMap(id: number){
        return this.toraxHttp.get(`${this.url}/${id || 0}/map`);
    }

    update(TishTable: TishTable){
        return this.toraxHttp.postWithResponce(this.url, TishTable);
    }

    moveTable(TishTable: any) {
        return this.toraxHttp.postWithResponce(`${this.url}/moveTable`, TishTable);
    }

    getAdd(tishEventID: number){
        return this.toraxHttp.get(`${this.url}/add/${tishEventID || 0}`);
    }

    public initForm() {
        return this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            tishTableID: [0, Validators.required],
            tishEventID: [, Validators.required],
            tishEventTitle: [],
            tishAreaID: [],
            tishAreaName: [],
            tishGroupID: [],
            tishGroupName: [],
            countPlaces: [, Validators.required],
            tishTableName: ['', Validators.required],
            comment: [],
            tishTableWidth: [],
            tishTableHeight: [],
            tishTableLeft: [],
            tishTableTop: [],
            tishPlaces: this.formBuilder.array([]),
            chainingLocation: [],
            chainingRoundAndSize: [],
            narrowTable: [false, Validators.required],
            transformRotate: []
        });
    }


     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }

}
