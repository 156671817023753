import { Honor } from '../../honors/honor/honor';
import { ServicesData } from '../../services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { HonorsSetsData } from './../honors-sets.data';
import { HonorsSet } from './honors-set';

@Component({

  selector: 'honors-set-edit',
  templateUrl: './honors-set-edit.tmpl.html'
})

export class HonorsSetEdit implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private honorsSetsData: HonorsSetsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
  ) {
  }

  honorsSet: HonorsSet;
  id: number;
  honorsSetForm: FormGroup;

  public load(): void {
    if (this.id > 0) {
      this.honorsSetsData.getEdit(this.id || 0)
        .subscribe((c: any) => {
          this.honorsSet = c;
          this.honorsSetForm.patchValue(c);
          this.fillHonors(c.honors);
        });
    } else {
      this.honorsSetsData.getAdd()
        .subscribe((c: any) => {
          this.honorsSet = c;
          this.honorsSetForm.patchValue(c);
          this.fillHonors(c.honors);
        });
    }
  }

  save(honorsSetForm: FormGroup) {
    if (honorsSetForm.valid) {
      this.honorsSetsData.update(honorsSetForm.getRawValue())
        .subscribe((c: any) => {
          this.router.navigateByUrl('honorsSets');
        },
          error => { });
    } else {
      console.log(honorsSetForm.errors);
    }
  }


  private initForm() {
    this.honorsSetForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      honorsSetID: [this.id || 0, Validators.required],
      honorsSetDate: [new Date().toJSON(), Validators.required],
      description: [, Validators.required],
      honors: this.formBuilder.array([]),
    });
  }

  private fillHonors(honors: Honor[]) {
    const honorsArray = <FormArray>this.honorsSetForm.controls['honors'];
    honors.forEach((hnr, i) => {
      let hnrFrm = this.initHonor(hnr);
      honorsArray.push(hnrFrm);
      hnrFrm.patchValue(hnr);
    });
    //this.commitmentForm.controls['payments'].setValue(paymentsArray);
  }

  addHonor() {
    const honorsArray = <FormArray>this.honorsSetForm.controls['honors'];
    let hnr = new Honor();
    hnr.honorsSetID = this.id;
    hnr.honorDate = this.honorsSetForm.controls['honorsSetDate'].value || new Date().toJSON();
    let hnrFrm = this.initHonor(hnr)
    honorsArray.push(hnrFrm);
    hnrFrm.patchValue(hnr);
  }
  deleteItem(index: number) {
    let honors = this.honorsSetForm.get('honors') as FormArray;
    let form = (<FormGroup>honors.controls[index]);
    form.disable();
    form.controls["isDeleted"].setValue(true);
  }
  unDeleteItem(index: number) {
    let honors = this.honorsSetForm.get('honors') as FormArray;
    let form = (<FormGroup>honors.controls[index]);
    form.enable();
    form.controls["isDeleted"].setValue(false);
  }


  // deleteItem(ix: number) {
  //     let hnrFrm = <FormGroup>(<FormArray>this.honorsSetForm.controls['honors']).controls[ix];
  //     Object.keys(hnrFrm.controls)
  //         .filter(key => key != 'isDeleted' && key != 'honorID')
  //         .forEach(key => {
  //             hnrFrm.controls[key].disable();
  //         });
  //     hnrFrm.controls['isDeleted'].enable();
  //     hnrFrm.controls['isDeleted'].setValue(true);
  // }
  // unDeleteItem(ix: number) {
  //     let hnrFrm = <FormGroup>(<FormArray>this.honorsSetForm.controls['honors']).controls[ix];

  //     Object.keys(hnrFrm.controls)
  //         .filter(key => key != 'isDeleted' && key != 'honorID')
  //         .forEach(key => {
  //             hnrFrm.controls[key].enable();
  //         });
  //     hnrFrm.controls['isDeleted'].setValue(false);
  // }


  initHonor(honor: any) {
    let fb = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      honorID: [honor.honorID || 0, Validators.required],
      honorsSetID: [honor.honorsSetID, Validators.required],
      contactID: [honor.contactID || null, Validators.required],
      contactName: [],
      receivingContactID: [],
      receivingContactName: [],
      relationTypeID: [19, Validators.required],
      honorTypeID: [honor.honorTypeID, Validators.required],
      honorTypeDescription: [],
      honorDate: [honor.honorDate || new Date().toJSON(), Validators.required],
      contributionAmount: [honor.contributionAmount],
      currency: [honor.currency],
      //payMethodID: [],
      doOpenCommitment: [false],
      doCreateLetter: [true, Validators.required],
      commitmentID: [honor.commitmentID || 0],
      contactLetterID: [honor.contactLetterID || 0],
      isDeleted: [false, Validators.required],
      reasonComment: []
    });
    fb.controls['contributionAmount'].valueChanges.subscribe(x => {
      let amnt = +x;
      if (amnt) {
        fb.controls['currency'].setValidators(Validators.required);
        fb.controls['doOpenCommitment'].setValue(true);
      } else {
        fb.controls['currency'].setValidators(null);
      }
      fb.updateValueAndValidity();
    });
    fb.controls['receivingContactID'].valueChanges.subscribe(x => {
      this.relationTypeCalc(fb);
    });
    fb.controls['contactID'].valueChanges.subscribe(x => {
      this.relationTypeCalc(fb);
    });
    return fb;
  }

  payMethods;
  private relationTypeCalc(fb: FormGroup) {
    let cid = +fb.controls['contactID'].value;
    let rcid = +fb.controls['receivingContactID'].value;
    let rtid = +fb.controls['relationTypeID'].value;

     if (rcid && cid != rcid && rtid && rtid == 19 ) {
      fb.controls['relationTypeID'].setValue(null);
    }
    fb.updateValueAndValidity();
  }

  public getPayMethods(contactId: number): void {
    this.servicesData.getList(`PayMethods/${contactId}/${false}`).subscribe(
      c => this.payMethods = c,
      error => { });
  }

  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['honorsSetID'];
    });
    this.initForm();
    this.load();
  }
}



