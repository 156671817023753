
import { Component, Input } from '@angular/core';


@Component({
    selector: 'commitment-row-details-toPrint',
    templateUrl: './commitment-row-details-toPrint.tmpl.html'
})


export class CommitmentRowDetailsToPrint   {
    constructor(   
    ) { }
    
    @Input('commitment') public commitment: any; 
}

