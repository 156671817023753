import { CommonModule } from '@angular/common';
import { ModelInfoModule } from './../model-info/model-info.mdl';
import { NgModule } from '@angular/core';
import { PanelBoxComponent } from './panel-box.cmp';
import { PanelBoxBtnComponent } from './panel-box-btn.cmp';

@NgModule({
    imports: [
         CommonModule,
        ModelInfoModule
    ],
    exports: [
        PanelBoxComponent,
        PanelBoxBtnComponent,
        //PanelBoxBtnInfoComponent
    ],
    declarations: [
        PanelBoxComponent,
        PanelBoxBtnComponent,
        //PanelBoxBtnInfoComponent
    ],
    providers: [],
})
export class PanelBoxModule { }
