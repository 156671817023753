<div [formGroup]="searchYarzeitsListForm">
  <div class="ibox-content border-bottom">
    <div class="row" style="padding: 10px 15px;">
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
        [listControl]="searchYarzeitsListForm.controls.yarzeitTypes" [placeholder]="'יחס...'"
        [list]="servicesData.YarzeitTypes">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <date-input-range [placeHolderStart]="'מתאריך:'" [placeHolderEnd]="'עד לתאריך:'"
        [dateRangeCtrl]="searchYarzeitsListForm.controls.yarzeitsDateRange">
        Loading...
      </date-input-range>
      &nbsp;&nbsp;
 <!--

 <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
       (selectChange)="selectionChanged($event)"
       [listControl]="searchYarzeitsListForm.controls.yarzeitTypes"
       [placeholder]="'סוג...'" [list]="servicesData.YarzeitTypes">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;

      -->
      <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש...'"
        (onSelect)="indexService.selectionChanged($event)" [formGroupModel]="searchYarzeitsListForm"
        [idControl]="searchYarzeitsListForm.controls.contactID"
        [nameControl]="searchYarzeitsListForm.controls.contactName">
      </auto-complete>
      &nbsp;&nbsp;
      <span fxFlex class="example-fill-remaining-space"></span>
      <div *ngIf="!contactID" fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
        <button class="btn btn-outline btn-success  dim" type="button"
          style="width: min-content; font-weight: 500; color:#ff4081"
          *ngIf="selection && selection.selected.length >= 1" (click)="createAndPrintYarzeitsLetters()">
          הדפס מכתבים ל-{{selection.selected.length}} רשומות נבחרות
        </button>
        &nbsp; <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
            class="fa fa-filter"></i></button>
        &nbsp;
        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
            class="material-icons">clear</i></button>
      </div>
    </div>
    <h3 style="color: blue; position: absolute; left:20px" class="label">שים לב! רשומות מוצגים עד לטווח של שנה קדימה
      מהיום הנוכחי</h3>
  </div>
  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">

          <!-- <ng-container matColumnDef="select">
            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
              <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox apply-enter (click)="$event.stopPropagation()" (change)="$event ? rowToggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container> -->

          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'yarzeits', row.yarzeitID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['yarzeits', row.yarzeitID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="yarzeitsData" [id]="row.yarzeitID"
                    [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="yarzeitID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה">
              <a [routerLink]="[ '', {outlets: {popup: [ 'yarzeits', row.yarzeitID]}}]">
                {{ row.yarzeitID }}
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="relevantLastName">
            <mat-header-cell *matHeaderCellDef>שם</mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם">
              <a [routerLink]="[ '/contacts', row.relevantContactID]">{{ row.relevantLastName }} {{
                row.relevantFirstName }}</a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="relevantCityName">
            <mat-header-cell *matHeaderCellDef>עיר</mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="עיר">
              {{ row.relevantCityName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="nextYarzeitDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך יארצייט</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך יארצייט">
              {{row.jewishDay}} {{row.jewishMonth}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="yarzeitType">
            <mat-header-cell *matHeaderCellDef> יחס</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="יחס">
              יא"צ {{ row.yarzeitType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="yarzeitsDetails">
            <mat-header-cell *matHeaderCellDef> פרטים</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="פרטים">
              <span style="font-weight: bold;"> {{ row.firstName }} {{ row.lastName }}</span>
              <span *ngIf="row.motherName && (row.yarzeitType  == 'שוויגער' || row.yarzeitType  == 'אמא')">
                בת ר' {{
                row.motherName}}</span>
              <span *ngIf="row.fatherName && (row.yarzeitType  == 'אבא' || row.yarzeitType  == 'שווער')"> בן
                ר' {{
                row.fatherName}}</span>
              נלב"ע
              {{ row.jewishDeathDate }}
              <span *ngIf="row.burialPlace && (row.yarzeitType  == 'שוויגער' || row.yarzeitType  == 'אמא')">ומנוחתה
                כבוד {{ row.burialPlace }}</span>
              <span *ngIf="row.burialPlace && (row.yarzeitType  == 'אבא' || row.yarzeitType  == 'שווער')">ומנוחתו
                כבוד {{ row.burialPlace }}</span>

            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.yarzeitID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
