<div style="cursor: initial" *ngIf="contactGroup" fxLayout="column">
    <div class="example-full-width" fxLayoutAlign="start center" fxLayout="row">
        <a fxLayout="column" style="padding: 4px" matTooltip="'עריכה'" (click)="editContactGroup(contactGroup)">
            <i class="fa fa-pencil"></i>
        </a> &nbsp;
        <model-info [modelInfo]="contactGroup"></model-info>
        &nbsp;
        <i fxLayout="column" class="fa fa-circle text-navtext-navy"
            [ngStyle]="{'color': contactGroup.group.groupColor}"></i> &nbsp;&nbsp;
        <div style="padding-right:7px" fxLayout="column" fxFlex>
            <div fxLayout="row">
                <h3 style="font-weight: bold;">{{ contactGroup.group.groupName }}&nbsp;
                  <b *ngIf="contactGroup.contactGroupStatus" style="background: #dbe965">{{contactGroup.contactGroupStatus}}</b>
                  <small *ngIf="contactGroup.priorityName"> ({{contactGroup.priorityName}})</small></h3>
            </div>
            <div fxLayout="row"><small>{{ contactGroup.comment }}</small></div>
        </div>
    </div>
</div>
