<form class="example-form" [formGroup]="contactGroupForm" novalidate>
  <div *ngIf="contactGroup" matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      <small>{{ (id > 0 ? 'עריכת פרטי' : 'הוספת') }} שיוך לקבוצה</small>
      <br>
      {{ contactGroup.contactName }}
    </h2>

  </div>
  <mat-dialog-content style="padding:0px 24px 24px 24px">
    <div *ngIf="contactGroup" fxLayout="column" style="display: block;">
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>קבוצה:</label>
          <mat-select class="form-control" formControlName="groupID" placeholder="קבוצה...">
            <mat-option *ngFor="let cs of servicesData.Groups" [value]="cs.groupID">
              <i class="fa fa-circle text-navtext-navy" [ngStyle]="{'color': cs.groupColor}"></i>
              &nbsp;&nbsp;
              {{cs.groupTitle}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>סטטוס:</label>
          <mat-select *ngIf="contactGroupStatuses" class="form-control" apply-enter placeholder="סטטוס..."
            formControlName="contactGroupStatusID">
            <mat-option *ngFor="let cgs of contactGroupStatuses" [value]="cgs.contactGroupStatusID">
              {{cgs.name}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>סכום יעד:</label>
          <input apply-enter class="form-control" placeholder="סכום יעד..." formControlName="destinationContactAmount">

        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <auto-complete apply-enter [placeholder]="'קישור להתחייבות... (חפש לפי מזהה)'"
            [controllerName]="'Commitments'"
            [formGroupModel]="contactGroupForm" [idControl]="contactGroupForm.controls.commitmentID"
            [nameControl]="contactGroupForm.controls.commitmentTitle">
        </auto-complete>
    </div>
      <!-- <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>דירוג:</label>
          <mat-select class=" form-control" apply-enter placeholder="דירוג..." formControlName="priority">
            <mat-option *ngFor="let lt of servicesData.ContactGroupPriorities" [value]="lt.value">
              {{lt.name}}
            </mat-option>
          </mat-select>
        </div>
      </div> -->

      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3" style="width: 100%;">
          <!-- <label>הערה:</label> -->
          <textarea class="form-control comment" style="min-width: 100%" rows="2" placeholder="הערה..."
            formControlName="comment"></textarea>
        </div>
      </div>



    </div>


  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <button [disabled]="contactGroupForm.invalid" class="btn btn-primary navbar-btn"
      [mat-dialog-close]="contactGroupForm"><i class="fa fa-save"></i> שמור </button>
    <button class="btn btn-danger navbar-btn" *ngIf="contactGroup.contactGroupID > 0"
      [mat-dialog-close]="contactGroupForm" (click)="delete()"><i class="fa fa-trash"></i> מחק
    </button>
    <!-- <button mat-button mat-dialog-close [mat-dialog-close]="contactGroupForm" class="btn btn-danger navbar-btn"><i
                class="fa fa-trash-o"></i> מחק לנצח
        </button> -->

  </mat-dialog-actions>
