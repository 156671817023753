<div class="input-group md-3" style="width: inherit;" [ngClass]="{'has-error': payMethodIdControl.invalid }">
    <label *ngIf="withLabel">{{placeHolder}}:</label>
    <div style="display: flex;">
        <mat-select *ngIf="payMethods" apply-enter [(ngModel)]="selectedPayMethod" panelClass="panel-selection-width"
            class="form-control" (selectionChange)="payMethodSelectEvent($event)" [placeholder]="placeHolder">
            <mat-option [value]="">...</mat-option>
            <mat-option *ngFor="let payMethod of payMethods" [value]="payMethod" [disabled]="payMethod.isOneTime == true">
                <span style="float: right">{{payMethod.name}}</span>
                <span class="example-fill-remaining-space"></span>
                <payMethod-add-action *ngIf="payMethod.isEditable" style="padding: 10px; float: left"
                  [payMethod]="payMethod" [contactID]="contactID" [fromRecordType]="fromRecordType"  [fromRecordID]="fromRecordID" (payMethodChange)="payMethodChangeEvent($event)">
                </payMethod-add-action>
            </mat-option>
        </mat-select>
        <payMethod-add-action [contactID]="contactID" [fromRecordType]="fromRecordType"  [fromRecordID]="fromRecordID"  (payMethodChange)="payMethodChangeEvent($event)">
        </payMethod-add-action>
    </div>
</div>
