<div *ngIf="dataContact; else loading" class="dialog-content-wrapper">
    <div  *ngIf="dataContact" matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            [{{dataContact.contactID}}] {{dataContact.firstName}} {{dataContact.lastName}}
            <br>
            <small> <a  style="padding: 4px;cursor: pointer" target="_blank"
                href="http://www.google.com/maps/place/{{dataContact.latitude}},{{dataContact.longitude}}">
                <i style="padding: 4px" matTooltip="גוגל מאפס"  class="fa fa-map-marker"></i>&nbsp;{{ dataContact.shortAddressTitle }}
            </a>

            </small>
        </h2>
    </div>

    <!-- <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="end center">
            <button mat-mini-fab title="סגור" (click)="matDialogRef.close()" aria-label="סגור">
                    <i class="fa fa-remove"></i>
                </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="toolbar-bottom py-16" fxLayout="column" fxLayoutAlign="center center">
            <div class="contact-name mt-8">[{{dataContact.contactID}}] {{dataContact.firstName}} {{dataContact.lastName}}</div>
        </mat-toolbar-row>
    </mat-toolbar> -->

    <mat-dialog-content style="padding:0px 24px 24px 24px">
      <div  fxLayout="column" style="display: block;">
            <div>
                <dl class="dl-horizontal" style="margin-bottom: 0;">
                    <dt *ngIf="dataContact.phoneNumber1">טלפון</dt>
                    <dd *ngIf="dataContact.phoneNumber1">{{ dataContact.phoneNumber1 | phone}}</dd>
                    <hr *ngIf="dataContact.phoneNumber1">
                    <dt *ngIf="dataContact.phoneNumber2">טלפון 2</dt>
                    <dd *ngIf="dataContact.phoneNumber2">{{ dataContact.phoneNumber2 | phone}}</dd>
                    <hr *ngIf="dataContact.phoneNumber2">
                    <dt *ngIf="dataContact.phoneNumber3">טלפון 3</dt>
                    <dd *ngIf="dataContact.phoneNumber3">{{ dataContact.phoneNumber3 | phone}}</dd>
                    <hr *ngIf="dataContact.phoneNumber3">
                    <dt *ngIf="dataContact.phoneNumber4">טלפון 4</dt>
                    <dd *ngIf="dataContact.phoneNumber4">{{ dataContact.phoneNumber4 | phone}}</dd>
                    <hr *ngIf="dataContact.phoneNumber4">
                    <dt *ngIf="dataContact.phoneNumber5">טלפון 5</dt>
                    <dd *ngIf="dataContact.phoneNumber5">{{ dataContact.phoneNumber5 | phone}}</dd>
                    <hr *ngIf="dataContact.phoneNumber5">
                    <dt *ngIf="dataContact.phoneNumber6">טלפון 6</dt>
                    <dd *ngIf="dataContact.phoneNumber6">{{ dataContact.phoneNumber6 | phone}}</dd>
                    <hr *ngIf="dataContact.phoneNumber6">
                </dl>
                <contact-short-details [contact]="dataContact"></contact-short-details>

            </div>
        </div>
    </mat-dialog-content>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <!-- <button *ngIf="action === 'edit'"
                mat-button
                class="mr-8"
                (click)="matDialogRef.close(['delete',contactForm])"
                aria-label="Delete"
                matTooltip="Delete">
            DELETE
        </button>

        <button *ngIf="action !== 'edit'"
                mat-button
                class="save-button"
                (click)="matDialogRef.close(contactForm)"
                [disabled]="contactForm.invalid"
                aria-label="SAVE">
            ADD
        </button>

        <button *ngIf="action === 'edit'"
                mat-button
                class="save-button"
                (click)="matDialogRef.close(['save',contactForm])"
                [disabled]="contactForm.invalid"
                aria-label="SAVE">
            SAVE
        </button> -->

    </div>
</div>

<ng-template #loading>
    <h1>
        <i style="color: gray" class="fa fa-refresh fa-spin"></i>
    </h1>
</ng-template>
