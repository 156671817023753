export class Country {
    countryID: number;
    countryName: string;
    countryNameEn: string;
}


export class DefaultTerm {
    id:number;
    countryID: number;
    commitmentSubject: number;
    termID: number;
}