import { Component} from '@angular/core';

@Component({
    selector: 'tishTable-main',
    templateUrl: './tishTable-main.tmpl.html'
})

export class TishTableMain {
    editMode: boolean = null;

    public setVal(val:boolean) {
        this.editMode = val;
    }
}
