import { MaterialModule } from './../material.module';
import { UsersList } from './users-list.cmp';
import { UsersRoutes } from './users.routing';
import { RouterModule } from '@angular/router';
import { UserEditDlg } from './user/user-edit-dlg.cmp';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { UsersData } from './users.data';
//import { TextMaskModule } from 'angular2-text-mask';
import { UserAddDlg } from './user/user-add-dlg.cmp';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
		RouterModule.forChild(UsersRoutes),
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,

        MaterialModule
    ],
    declarations: [
        UsersList,
        UserEditDlg,
        UserAddDlg
    ],
    providers: [
        UsersData
    ],
    entryComponents: [
        UserEditDlg,
        UserAddDlg
    ]
})
export class UsersMdl { }



