import { FormGroup } from '@angular/forms';
import { AlertService } from '../../components/alert/alert.svc';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ContactsData } from '../contacts.data';
import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { Contact } from '../contact/contact';
import { ContactEditPersonalMinDlg } from '../contact/contact-edit-personal-min-dlg.cmp';


@Component({
    selector: 'contact-add-action',
    templateUrl: './contact-add-action.tmpl.html'
})

export class ContactAddAction implements AfterViewInit {

    constructor(
        private contactsData: ContactsData,
        private formBuilder: FormBuilder,
        private alertSvc: AlertService,
        public dialog: MatDialog
    ) {

    }
    ngAfterViewInit(): void {
        // console.log("contact",this.contact);

    }

    @Input('contactID') public contactID: number;

    @Input('contact') public contact: Contact;
    @Input('editIcon') public editIcon: false;
    @Output() contactChange = new EventEmitter<any>();

    // onEditClick(obj: Contact) {
    //     this.openAddOrEditContact(obj);
    // }

    public openAddOrEditContact(): void {
        setTimeout(() => {
            if (!this.contact) {
                this.contact = new Contact();
                this.contact.contactID = this.contactID;
                this.contact.contactID = this.contactID;
            }

            const dialogRef = this.dialog.open(ContactEditPersonalMinDlg, {
                data: { contactId: this.contact.contactID | this.contactID, id: this.contact.contactID | this.contactID, contact: this.contact }
            });


            dialogRef.afterClosed().subscribe(result => {
              console.log("result",result);
                if (result && result.valid) {
                    this.saveContact(result);
                }
            });
        }, 200);
    }


    private saveContact(contactForm: FormGroup) {
        this.contactsData.saveQuickContact(contactForm.value)
            .subscribe((x: Contact) => {
                //let subject = contactForm.value.contactSubject;
               // let contact  = {id : x.contactID,  contact : x  }
                this.contactChange.emit(x)
                return true;
            })
    }

}



