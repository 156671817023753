<div [formGroup]="formData">
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-sm-5" for="cancellationReason">סיבת ביטול קבלה</label>
            <div class="col-sm-7">
                <input type="text" class="form-control" formControlName="cancellationReason" />
                <small *ngIf="!formData.controls.cancellationReason.valid" class="text-danger">*</small>
            </div>
        </div>
    </div>
</div>        