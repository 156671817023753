import { ToraxHttp } from './../../utils/custom-http.svc';
import { Injectable } from '@angular/core';

//import 'rxjs/Rx';

@Injectable()
export class DashboardDirectorDataService {
    
    private url = 'api/dashboardDirector';
    constructor(
        private toraxHttp: ToraxHttp

    ) { }

    getGroupedHighPayments(){
        return this.toraxHttp.get(`${this.url}/getGroupedHighPayments`);
    }

    getCommitmentsAboutToExpire(){
        return this.toraxHttp.get(`${this.url}/getCommitmentsAboutToExpire`);
    }

    getClosedCommitments(){
        return this.toraxHttp.get(`${this.url}/getClosedCommitments`);
    }

    // getPaymentCommitmentsInfo(paymentCommitmentsIdsList: number[]) {
    //     return this.toraxHttp.post(`${this.url}/getPaymentCommitmentsInfo`, paymentCommitmentsIdsList);
    // }


    getPosModeByPayments(chartModelQuery: any)  {
        return this.toraxHttp.post(`${this.url}/getPosModeByPayments`, chartModelQuery);
    }
    getPosModeByCommitments(chartModelQuery: any)  {
        return this.toraxHttp.post(`${this.url}/getPosModeByCommitments`, chartModelQuery);
    }

}
