<div style="display: inline-block;width:  80px" class="form-group">
    <label class="control-label">{{placeholder}}</label>
    <div style="width:min-content;" class="input-group md-3">
        <input class="form-control" autocomplete="off" style="width:  80px !important; min-width: auto;" [ngClass]="{'has-error': !amountControl.valid }" #amountInp
        [ngModel]="amount | number" [formControl]="amountControl" [placeholder]="placeholder">
    </div>
</div>
<!-- <mat-form-field style="width:  80px" [ngClass]="{'has-error': !amountControl.valid }">
    <input autocomplete="off" style="width:  80px" [ngClass]="{'has-error': !amountControl.valid }" #amountInp
        [ngModel]="amount | number" [formControl]="amountControl" [placeholder]="placeholder">
</mat-form-field> -->