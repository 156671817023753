
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HoldingsData } from './holdings.data';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { AlertService } from '../components/alert/alert.svc';
import { IndexService } from '../services/index-service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SubscriptionLike } from 'rxjs';
import { ToraxHttp } from '../utils/custom-http.svc';

@Component({
  selector: 'holdings-prints-list',
  templateUrl: 'holdings-prints-list.tmpl.html',
})

export class HoldingsPrintsList implements OnInit, OnDestroy {

  constructor(
    private toraxHttp: ToraxHttp,
    private holdingsData: HoldingsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private alert: AlertService
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/completeHoldings') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  displayedColumns: string[] = ['buttons', 'shtibelName', 'period', 'includeOldRecords', 'printDate', 'holdingsSeriePrintID', 'count'];
  data: any[] = [];
  @Input('shtibelID') public shtibelID?: number | null = null;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  orderButtonIsChicked: boolean = false;
  public selection = new SelectionModel<number>(true, []);
  stopLoadingPoints: boolean = false;
  defaultvalues;
  groupingColumn = '';
  groupingOrderColumn = '';
  searchHoldingsPrintListForm: FormGroup;
  indexService: IndexService;
  actualMinusHeight = '230';


  public exportExcel(): void {
    this.holdingsData.downloadviwHoldingsSeriePrintExls(this.searchHoldingsPrintListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `דוח הדפסות.xlsx`;
        link.click();
      });
  }


  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() selectedRowsChange = new EventEmitter<any>();




  canceloldingsPrint(holdingsSeriePrintID: number) {
    let cnfrm = confirm("האם לבטל הדפסה?\nלא ניתן לשחזר את הפעולה");
    if (cnfrm) {
      this.alert.info("המתן בבקשה,\nמבטל הדפסת רשימות...");
      this.holdingsData.cancelHoldingsPrint(holdingsSeriePrintID)
        .subscribe(data => {
          this.alert.success("הדפסת רשימות בוטלה");
          //  this.getHoldingsPrintedGroup();
        });
    }
  }


  private initForm() {
    this.searchHoldingsPrintListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      shtibelID: [],
      shtibelName: [],
      // responsibleID: [],
      // responsibleName: [],
      statusPayments: [],
      isPrint: [],
      commitmentID: [],
      dateMonthsRange: [null],
      includeOldRecords: [false, Validators.required],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });



    let frm = this.searchHoldingsPrintListForm;
    this.defaultvalues = Object.keys(this.searchHoldingsPrintListForm.value)
      .filter(key => frm.controls[key].validator == Validators.required)
      .reduce((obj, key) => {
        obj[key] = frm.controls[key].value;
        return obj;
      }, {});
  }

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchHoldingsPrintListForm.patchValue(frm);
    }

    this.searchHoldingsPrintListForm.updateValueAndValidity();

    let params = [
      { key: "shtibelID", value: this.shtibelID },
    ];


    this.indexService = new IndexService(this.searchHoldingsPrintListForm, params, this.holdingsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn, "getHoldingsPrintedGroup");

    this.indexService.getList();


  }
}
