import { MatPaginator } from '@angular/material/paginator';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { timer } from 'rxjs';
import { ModalService } from './../components/modal/modal.svc';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, AfterViewInit, ElementRef, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TishInvitedsData } from './tishInviteds.data';
import { TishInvited } from './tishInvited/tishInvited';
import { ToraxHttp } from '../utils/custom-http.svc';
import { SubscriptionLike } from 'rxjs';
import { ServicesData } from '../services/services.data';
declare var jQuery: any;




@Component({

  selector: 'tishInviteds-list-map',
  templateUrl: './tishInviteds-list-map.tmpl.html',
  styleUrls: ['./tishInviteds.scss', 'tishInviteds-list-map.scss']
})

export class TishInvitedsListMap implements OnInit, AfterViewInit {
  statusFilter: string;
  groupFilter: string;
  isPlacesFilter: string;
  doSendlettersFilter: string;
  invitedConfirmedsFilter: string;
  notEmbeddedFilter: string;
  viwFilters: boolean = false;
  constructor(
    private tishInvitedsData: TishInvitedsData,
    private activatedRoute: ActivatedRoute,
    private modal: ModalService,
    private formBuilder: FormBuilder,
    private toraxHttp: ToraxHttp,
    private servicesData: ServicesData
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (i.type.indexOf('/tishInviteds') > -1) {
        this.getTishInviteds();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  @Input('tishEventID') public tishEventID: number;
  @Input('tishEvent') public tishEvent: number;
  @Input('eventStatus') public eventStatus: number;


  @ViewChild('groupCmp') groupCmp: AutoCompleteComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChildren('invitedsGabch') invitedsGabchs: QueryList<ElementRef>;
  @Output() onStartInvitedDragging = new EventEmitter<any>();
  @Output() onDropedInvited = new EventEmitter<boolean>();
  @Output() loadEventStatusDelgt = new EventEmitter<number>();

  page; //מס' עמוד נוכחי
  pageRows = 20; //שורות לעמוד
  rowsCount; //סך שורות כולל
  pagesCount; //מס' עמודים כולל
  stopLoadingPoints: boolean = false;
  isLoadingResults = false;
  isRateLimitReached = false;
  searchContactsListForm: FormGroup;
  sourceList;

  searchTishInvitedsListForm: FormGroup;
  clearSerch() {
    if (this.searchTishInvitedsListForm) {
      this.searchTishInvitedsListForm.controls.query.setValue('');
      this.getTishInviteds();
    }
  }
  public clerFilter() {
    this.initForm();
    this.getTishInviteds();
    this.statusFilter = '';
    this.groupFilter = '';
    this.isPlacesFilter = '';
    this.doSendlettersFilter = '';
    this.invitedConfirmedsFilter = '';
    this.notEmbeddedFilter = '';
  }

  onDragInvited(e: boolean) {
    this.onStartInvitedDragging.emit(e);
  }
  loadEventStatus() {
    this.loadEventStatusDelgt.emit(this.tishEventID);
  }

  onDropInvited(e: boolean) {
    this.onDropedInvited.emit(e);
  }


  statusFilterChanged(e) {
    this.statusFilter = `${e.source.triggerValue}`;
  }
  groupFilterChanged(e) {
    this.groupFilter = `${e.source.triggerValue}`;
  }
  isPlacesFilterChanged(e) {
    this.isPlacesFilter = `${e.source.triggerValue}`;
  }
  doSendlettersFilterChanged(e) {
    this.doSendlettersFilter = `${e.source.triggerValue}`;
  }
  invitedConfirmedsFilterChanged(e) {
    this.invitedConfirmedsFilter = `${e.source.triggerValue}`;
  }
  notEmbeddedFilterChanged(e) {
    this.notEmbeddedFilter = `${e.source.triggerValue}`;
  }


  public itemSelect(id: number) {
    this.getTishInviteds();

  }


  private initForm() {
    this.searchTishInvitedsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [15],
      query: [],
      tishEventID: [],
      areaID: [],
      groupID: [],
      tishGroupName: [],
      tishAreaName: [],
      contactID: [],
      hostContactName: [],
      hostInvitedID: [],
      notEmbedded: [null],
      tishInvitedID: [],
      tishInvitedName: [],
      isPlaces: [true],
      doSendletters: [null],
      invitedConfirmeds: [true],
      invitedStatus: [],
      contributorRating:[],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

  }


  pageChange(e) {
    this.searchTishInvitedsListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.getTishInviteds();
  }
  //public getTishInviteds(searchTishInvitedsListForm: FormGroup = this.searchTishInvitedsListForm || null): void {
  public getTishInviteds(): void {
    if (this.paginator) {
      this.searchTishInvitedsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    }
    this.searchTishInvitedsListForm.controls['tishEventID'].setValue(this.tishEventID);
    //כלול רק את מי שלא שובץ
    this.searchTishInvitedsListForm.controls['notEmbedded'].setValue(true);
    this.tishInvitedsData.getViewList(this.searchTishInvitedsListForm.value).subscribe(
      (data: any) => {
        this.sourceList = data.list;
        this.page = data.page;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;
        this.pagesCount = data.pagesCount;

        this.isLoadingResults = false;
        this.isRateLimitReached = false;

        //trick
        this.stopLoadingPoints = false;
      },
      error => { });
  }

  ngOnInit(): void {
    this.activatedRoute.params.forEach((params: Params) => {
      this.tishEventID = +params['tishEventID'];
    });

    if (this.tishEventID > 0) {
      this.initForm();
      this.getTishInviteds();
    }

    this.loadEventGroups();
    this.loadTishInvitedStatuses();
    this.loadTishContributorRatings();
  }

  searchTxt(txt: string) {
    this.getTishInviteds();
  }
  ngAfterViewInit() {
    var tis = this;

    // var tishInvitedsData = this.tishInvitedsData;
    // var getTishInviteds = this.getTishInviteds;
    // var searchTishInvitedsListForm = this.searchTishInvitedsListForm;


    this.invitedsGabchs.forEach(element => {

      let invited = jQuery(element.nativeElement);
      invited.sortable({
        revert: true
      });
      invited.disableSelection();

      invited.droppable({
        drop: function (e, ui) {
          $(this).width('100%');
          var data = ui.draggable.data('srcObj');
          if (data) {
            let srcTishInvited;
            if (data.tishInvited) {
              let tishInvitedJsn: string = data.tishInvited;
              srcTishInvited = <TishInvited>jQuery.parseJSON(tishInvitedJsn);

              if (srcTishInvited && srcTishInvited.tishInvitedID) {
                tis.tishInvitedsData.detachInvited(srcTishInvited.tishInvitedID)
                  .subscribe((c: any) => {
                    tis.getTishInviteds();
                    ui.helper.remove();
                    tis.loadEventStatus();
                  });
              } else {
                console.log(srcTishInvited.errors);
              }
            }
          }
        }
      });
    });

    const tmr = timer(100000000)
      .pipe()
      .subscribe(z => tis.getTishInviteds());
  }


  tishContributorRatings: [];
  loadTishContributorRatings() {
    this.servicesData.getTishContributorRatings(this.tishEventID).subscribe((x: any) => {
      this.tishContributorRatings = x;
    });
  }
  tishInvitedStatuses: [];
  loadTishInvitedStatuses() {
    this.servicesData.getTishInvitedStatuses(this.tishEventID).subscribe((x: any) => {
      this.tishInvitedStatuses = x;
    });
  }
  tishGroups: [];
  loadEventGroups() {
    this.servicesData.getTishGroups(this.tishEventID).subscribe((x: any) => {
      this.tishGroups = x;
    });
  }

}
