import { RegistrationEventDetailsDlg } from './registrationEvent/registrationEvent-details-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationEventsData } from './registrationEvents.data';
import { Component, OnInit, ViewChild, } from '@angular/core';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';
import { RegistrationEvent } from './registrationEvent/registrationEvent';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { SubscriptionLike } from 'rxjs';
import { IndexService } from '../services/index-service';

@Component({
  selector: 'registrationEvents-list',
  templateUrl: 'registrationEvents-list.tmpl.html'
})

export class RegistrationEventsList implements OnInit {
  constructor(
    private registrationEventsData: RegistrationEventsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/payments') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  displayedColumns: string[] = ['buttons', 'registrationEventID', 'title', 'registrationEventType', 'date', 'price', 'sumDoses', 'sumRegistrations', 'sumPrice', 'sumAmountPaid', 'sumAmountToPay', 'percentToPay']; //, 'sumRegistrations', 'sumDoses', 'sumAmount', 'sumAmountPaid', 'sumAmountToPay'
  @ViewChild('registrationEventID') registrationEventID: AutoCompleteComponent;
  indexService: IndexService;
  actualMinusHeight = '185';
  searchRegistrationEventsListForm: FormGroup;
  stopLoadingPoints: boolean = false;
  defaultvalues;
  groupingColumn = '';
  groupingOrderColumn = '';

  openSelectedRow(registrationEventID) {
    this.router.navigate(['', { outlets: { popup: ['registrationEvents', registrationEventID] } }]);
  }
  openDetails(row: RegistrationEvent) {
    this._matDialog.open(RegistrationEventDetailsDlg, {
      data: row
    });
  }


  public downloadExcel() {
    if (!this.searchRegistrationEventsListForm.controls.registrationEventTypes.value) {
      alert('יש לבחור סוג רשומה לפני ייצוא');
      return;
    }
    this.registrationEventsData.downloadExcel(this.searchRegistrationEventsListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `אירועים סטנציה.xlsx`;
        link.click();
      });
  }

  public downloadHeavyExcel() {
    if (!this.searchRegistrationEventsListForm.controls.registrationEventTypes.value) {
      alert('יש לבחור סוג רשומה לפני ייצוא');
      return;
    }
    this.registrationEventsData.downloadHeavyExcel(this.searchRegistrationEventsListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `אירועים סטנציה מלא.xlsx`;
        link.click();
      });
  }

  registrationEventsDebitBalance = 0;

  private initForm() {
    this.searchRegistrationEventsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      registrationEventID: [],
      registrationEventTitle: [],
      registrationEventTypes: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });
  }

  initUpdateCashRegisterModel(): FormGroup {
    return this.formBuilder.group({
      contactID: [, Validators.required],
      commitmentID: [, Validators.required],
      amount: [, Validators.required]
    });
  }


  panelOpenState;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchRegistrationEventsListForm.patchValue(frm);
    }
    let params = [
      { key: "registrationEventID", value: this.registrationEventID }
    ];




    this.indexService = new IndexService(this.searchRegistrationEventsListForm, params, this.registrationEventsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);

    this.indexService.getList();

  }

}
