import { CommitmentsData } from './commitments.data';
import { Component, OnInit, Inject, Input } from '@angular/core';


@Component({
    selector: 'commitments-details-toPrint',
    templateUrl: './commitments-details-toPrint.tmpl.html'
})
export class CommitmentsDetailsToPrint {  
    constructor(   
        private commitmentsData: CommitmentsData
    ) { }
    
    @Input('commitments') public commitments: any[];
 
}

           