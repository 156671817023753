<form [formGroup]="userEditForm" novalidate *ngIf="userEditForm && user">
  <div class="modal-header">
    <h1><small>עריכת פרטי משתמש</small>
      <br>
      {{ user.userName }}
  </h1>
  </div>
  <mat-dialog-content fxLayout="row">
    <div class="modal-body">
      <div class="example-form"> 
          <fieldset class="form-group" fxLayout="column">
            <legend>פרטי משתמש</legend>
            <div class="col-sm-12">              
              <div class="col-sm-6">
                <div class="form-row" fxLayout="row" fxLayout.xs="column">                
                  <div class="input-group mb-3"> 
                    <label>שם פרטי:</label>
                    <input type="text" class="form-control" formControlName="firstName" />
                  </div>&nbsp;
                  
                  <div class="input-group mb-3">
                    <label>שם משפחה:</label>
                    <input type="text" class="form-control" formControlName="lastName" />
                  </div>
                </div>
                <hr>
                <div class="form-row" fxLayout="row" fxLayout.xs="column">
                  <div class="input-group mb-3">
                    <label>מחלקה/מדינה:</label>
                    <mat-select class="form-control" apply-enter placeholder="מחלקה/מדינה..." formControlName="department">
                      <mat-option [value]="">...</mat-option>
                      <mat-option *ngFor="let ud of servicesData.UserDepartments" [value]="ud.value">
                        {{ud.name}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
                <hr>
                <div class="form-row" fxLayout="row" fxLayout.xs="column">
                  <div class="input-group mb-3">
                    <label>משוייך לנושאים:</label>
                    <mat-select apply-enter (selectionChange)="subjectsSelectionChange($event)"
                      placeholder="משוייך לנושאים..." [formControl]="userEditForm.controls.userCommitmentSubjects" multiple>
                      <mat-option *ngFor="let cs of subjects" [value]="cs.value">
                        {{cs.name}}</mat-option>
                    </mat-select>
                  </div>
                </div>
                <hr>
                <div class="form-row" fxLayout="row" fxLayout.xs="column">
                  <div class="input-group mb-3">
                    <label>קטגורית הזמנת מקומות:</label>
                    <mat-select apply-enter (selectionChange)="registrationEventTypesSelectionChange($event)"
                      placeholder="קטגורית הזמנת מקומות..." [formControl]="userEditForm.controls.userRegistrationEventTypes"
                      multiple>
                      <mat-option *ngFor="let cs of registrationEventTypes" [value]="cs.value">
                        {{cs.name}}</mat-option>
                    </mat-select>
                  </div>
                </div>
                <hr>
              </div>
              <div class="col-sm-6">
                <div fxLayout="column" style="margin-right: 10px;">
                  <h3>תפקידים מורשים למשתמש:</h3>
                  <div fxLayout="row" style="margin-top: 10px;">
                    <button (click)="selectAll()">בחר הכל
                    </button>
                    <button (click)="unSelectAll()">הסר הכל
                    </button>
                    <button (click)="refresh()">בטל
                    </button>
                  </div>
                  <div fxLayout="row" style="overflow-y: auto; margin-top:20px;" *ngIf="user.kvpUserRoles">
                       <mat-selection-list  #selectionList [formControl]="userEditForm.controls.userRoles" multiple>
                        <mat-list-option *ngFor="let rol of user.kvpUserRoles" [value]="rol.key.name"
                          [selected]='rol.value == true'>
                          {{rol.key.hebRoleName }} [{{rol.key.name }} ]
                        </mat-list-option>
                      </mat-selection-list> 
                    
                  </div>
                </div>
              </div>
            </div>
          </fieldset> 
      
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <button [disabled]="userEditForm.invalid" class="btn btn-primary navbar-btn" [mat-dialog-close]="userEditForm"><i
        class="fa fa-save"></i> שמור </button>
        <button mat-button mat-dialog-close class="btn btn-warning navbar-btn"><i class="fa fa-warning"></i> ביטול
        </button>
        <button class="btn btn-danger navbar-btn" *ngIf="user.userName"
          [mat-dialog-close]="userEditForm" (click)="delete(user.userName)"><i class="fa fa-warning"></i> מחק
      </button>
   
  </mat-dialog-actions>
</form>