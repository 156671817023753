import { TishAreaMain } from './tishArea/tishArea-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { TishAreasList } from './tishAreas-list.cmp';

export const TishAreasRoutes: Routes = [
  { canActivate: [AuthGuard], path: 'tishAreas', component: TishAreasList, data: {  roles: "tishAreas",  title: 'איזורים באירוע' } },
  { canActivate: [AuthGuard], path: 'tishEvents/:tishEventID/tishAreas/add', component: TishAreaMain, data: { mode: 'edit', roles: "tishAreas", title: 'הוסף איזור' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'tishAreas/:tishAreaID', component: TishAreaMain, data: {  roles: "tishAreas",  title: 'פרטי איזור' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'tishAreas/:tishAreaID/edit', component: TishAreaMain, data: { mode: 'edit', roles: "tishAreas",  title: 'עריכת פרטי איזור' }, outlet: 'popup' },
];
