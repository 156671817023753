import { ProcessesTypeMain } from './processes-type/processes-type-main.cmp';
import { ProcessesTypesRoutes } from './processes-types.routing';
import { ProcessesTypesData } from './processes-types.data';
import { ProcessesTypeDetails } from './processes-type/processes-type-details.cmp';
import { ProcessesTypeEdit } from './processes-type/processes-type-edit.cmp';
import { MainFormModalModule } from '../components/main-form-modal/main-form-modal.mdl';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from "../components/pagination/pagination.mdl";
import { ProcessesTypesList } from './processes-types-list.cmp';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		RouterModule.forChild(ProcessesTypesRoutes),
		MainFormModalModule
	],
	declarations: [
		ProcessesTypesList,
		ProcessesTypeEdit,
		ProcessesTypeDetails,
		ProcessesTypeMain
	],
	exports: [
		ProcessesTypesList,
		ProcessesTypeEdit,
		ProcessesTypeDetails,
		ProcessesTypeMain
	],
	providers: [
		ProcessesTypesData
	]
})
export class ProcessesTypesMdl { }

