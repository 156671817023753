import { ServicesData } from './../../services/services.data';
import { TishGroupsData } from './../tishGroups.data'; 
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core'; 

const dropDownRequired = c => {
    if (!c.value || c.value === 'undefined') {
        return { required: true };
    }
    return null;
};

@Component({
    
    templateUrl: './tishGroup-edit-dlg.tmpl.html'
})
export class TishGroupDlg implements OnInit {
    public formData: FormGroup;
 

    constructor(
        public dialogRef: MatDialogRef<TishGroupDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        private tishGroupsData: TishGroupsData,
    
    ) { }

    ngOnInit(): void {
        this.formData = this.tishGroupsData.initForm();
        this.formData.patchValue(this.data);
    }



    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}
