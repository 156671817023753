<form  [formGroup]="searchCountriesListForm">
    <div class="ibox-content m-b-sm border-bottom">

        <div class="example-container">

        </div>
        <div class="example-container">
            <div class="bounds">
                <div class="content" style="height: 70%; overflow: auto">
                    <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                        class="example-table" matSort matSortDisableClear matSortDirection="asc">


                        <ng-container matColumnDef="buttons">
                            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <button mat-icon-button (click)="openDetails(row);" aria-label="עריכה...">
                                    <i style="color: gray;" matTooltip="עריכה" class="fa fa-pencil"></i>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="countryName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>שם עיר</mat-header-cell>
                            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם עיר">
                                {{ row.countryName }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="countryNameEn">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>שם עיר אנ.</mat-header-cell>
                            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם עיר אנ.">
                                {{ row.countryNameEn }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="region">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>איזור</mat-header-cell>
                            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="איזור">
                                {{ row.region }}</mat-cell>
                        </ng-container>

                        
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                            (dblclick)="openSelectedRow(row.countryID)">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
            <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
                [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
        </div>
    </div>

</form>