<div fxLayout="column" fxLayoutGap="8px" style="background-color: WHITE; white-space: nowrap;">
  <div fxLayout="row" fxLayoutAlign="start end">
    <div style=" padding: 4px; font-weight: bold; font-size: large; width: 100%; color: #1ab394; border-top: solid #1ab394;">
      ממתין להפקדה
      <small style="font-size: x-small;">ערך שקלי</small>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="stretch">
    <div fxLayout="column" *ngIf="depositBookModelsShotef" style="padding:4px 12px 4px 4px">
      <h2 style="background-color: WHITE; color:gray; margin: 0;">שוטף</h2>
      <div *ngFor="let item of depositBookModelsShotef" class="row">
        <div class="row" style="border-bottom: solid #e5e5e5; width: 100%; text-align: right;">
          <span>{{item.depositTypeName}}: </span>
          <span>{{item.ilsAmount | number:'1.0-0'}} {{item.currencyIcon}}</span>
        </div>
      </div>
    </div>

    <div fxLayout="column" *ngIf="depositBookModelsKerenHabinyan" style="padding: 4px">
      <h2 style="background-color: WHITE; color:gray; margin: 0">קרן הבנין</h2>
      <div *ngFor="let item of depositBookModelsKerenHabinyan" class="row">
        <div class="row" style="border-bottom: solid #e5e5e5; width: 100%; text-align: right;">
          <span>{{item.depositTypeName}}: </span>
          <span>{{item.ilsAmount |number}} {{item.currencyIcon}}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div fxLayout="row" fxLayoutAlign="end end" style="padding: 4px">
    <a style="cursor: pointer"
    [routerLink]="['/deposits-main']">
      <i class="fa fa-arrow-circle-o-left" style="padding: 4px" matTooltip="מעבר לספר קופה"></i>
    </a>
  </div> -->
</div>

<!-- <div class="loading-icon">
  <mat-icon>notifications_none</mat-icon>
</div> -->
