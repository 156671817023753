<div *ngIf="shtibelsModel" style="height: 100%;">
  <div>
    <h1> שטיבלך {{shtibelsModel.subTitle}} <span  class="label label-primary">לחץ <small>(והחזק)</small> על שטיבל כדי להציג את נתוני השטיבל</span> </h1>
    <div   fxLayout="row" style="max-height: 200px; background-color: rgb(192 192 192 / 36%);">
      <div  *ngFor="let clmn of shtibelsModel.columnModels" class="item-container"  customToolTip [contentTemplate]="template">
        <div class="item-head">{{ clmn.value | number }} </div>
        <div
          fxFlexFill
          class="item-body"
          fxLayout="column"
          [style.background]="styleObject(clmn.value * 100)"

        >
          <div class="item-body-title" style="font-size: 16px  !important; white-space: initial; ">
            {{clmn.name}}
          </div>
        </div>
        <div class="item-foter" style="position:relative; top:-60px">
          <hr/>

          <span *ngIf="clmn.extra.blc  > 0; else distBlc" style="font-weight: bold;  -webkit-text-stroke: 0.5px black; font-family: sans; color: red;">
            חסר ליעד
            <br>
            {{clmn.extra.blc}}</span>
          <ng-template  #distBlc >
           <span style="font-weight: bold;  -webkit-text-stroke: 0.5px black; font-family: sans; color: green;">יעד<br>הושלם</span>
          </ng-template>
        </div>
        <ng-template #template>
             <div style="padding:6px;background-color: #e7eaec3b; text-align: right; padding: 6px; line-height: 24px;" >
              <h2 style="text-decoration: underline; font-weight: bold;  text-align: center;"> {{clmn.name}} ({{clmn.value | number }})</h2>
              מתפללים בשטיבל: {{clmn.extra.shtiblQty}}
              <br>
              יחידות בשטיבל: {{clmn.extra.unitsSum}}
              <br>
               אחוז יחידות: {{ clmn.extra.percents }}%
              <br>
              מס' המשתתפים: {{clmn.extra.shtibelPart}}
              <br>
              אחוז משתתפים: {{clmn.extra.partPercents }}%
              <br>
              <span *ngIf="clmn.extra.blc  > 0; else hushlam" style="color:red;font-weight: bold;">חסר ליעד {{clmn.extra.blc}} יחידות </span>
              <ng-template  #hushlam >
               <span style="color:green;font-weight: bold;">יעד נוכחי הושלם</span>
              </ng-template>
              <br>
          </div>
        </ng-template>
      </div>

    </div>
  </div>
</div>
