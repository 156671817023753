
import { ContactsData } from './../contacts.data';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Contact } from './contact';


@Component({
    selector: 'contact-edit',
    templateUrl: './contact-edit.tmpl.html',
    styleUrls: ['./contact-edit.scss']
})

export class ContactEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public contactsData: ContactsData,
        private formBuilder: FormBuilder,
    ) {

    }

    contact: Contact;
    id: number;
    contactForm: FormGroup;


    public load(): void {
        if (this.id) {
            this.contactsData.getEdit(this.id)
                .subscribe((c: any) => {
                    this.contact = c;
                    this.fillForm(c);
                });
        } else {
            this.contactsData.getAdd()
                .subscribe((c: any) => {
                    this.contact = c;
                    this.fillForm(c);
                });
        }
    }
    
    private fillForm(c: Contact): void {
        this.contactForm.patchValue(c);
    }

    private initForm() {
        this.contactForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            contactID: [this.id || 0, Validators.required],
            firstName: [],
            lastName: ['', Validators.required],
            codeIchud: [],
            passport: [],
            perfix: ['הרה"ח'],
            suffix: ['הי"ו'],
            localPerfix: [],
            localFirstName: [],
            localLastName: [],
            localSuffix: [],
            comment: [],
            shtibelID: [''],
            shtibelName: [''],
            fatherID: [''],
            fatherName: [''],
            fatherViewName:[''],
            chotenID: [''],
            chotenName: [''],
            kvitelFirstName: [],
            kvitelLastName: [],
            kvitelMotherName: [],
            motherName: [],
            defaultAddressID: [],
            defaultEmailID: [],
            defaultPhoneID: [],
            responsibleID: [],
            responsibleName: [],
            endDate: [],
            wifeName: [],
            pladaID:[],
            isActive: [true, Validators.required],
            affiliation: [, Validators.required],
            personalStatus: [],
            team: [], // כהן/לוי/ישראל
            language: [1, Validators.required],
            contactType: [, Validators.required],
            defaultContactTitleForReceipts: [],
            defaultReceiptsAddressID: [],
            birthDate: [],
            bornAfterDark: [],
            birthDateInaccurate: [],
            holdingsShtibelID:[],
            holdingsShtibelName:[],
            appendixToShtibel:[],
            defaultDetailsLetterAsReceipt: [false, Validators.required],
            isFavorite: [false, Validators.required],
            addresses: this.formBuilder.array([]),
            phones: this.formBuilder.array([]),
            emails: this.formBuilder.array([])
        });
    }

    ngOnInit() {
        this.initForm();
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['contactID'];
        });

        this.load();


    }
}



