
//import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DateTimeInput } from './date-time-input.cmp';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DateInputModule } from "../dateInput/date-input.mdl";
import { TimeInputModule } from '../timeInput/time-input.mdl';


@NgModule({
    imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    DateInputModule,
    TimeInputModule
],
    exports: [
        DateTimeInput
    ]
    ,
    declarations: [
        DateTimeInput
    ]
})
export class DateTimeInputModule { }

