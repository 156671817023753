import { Injectable } from '@angular/core';

@Injectable()
export class AlertCenterService {

  public alertCenterList: AlertCenter[] = [];

  // public success(msg: string, ttl: number = 5) {
  //     this.addAlert(msg, '', '', 'success', ttl);
  // }
  public info(msg: string, ttl: number = 3, index = null) {
    this.addAlert(msg, '', '', 'info', ttl, index);
  }
  public error(err: any, ttl: number = 100) {
    if (err) {
      this.addAlert(err.Message || err.message || err, err.ActionPath || '', err.StatusCode || '', 'danger', ttl);
    }
  }

  public success(successInfo: any, ttl: number = 3) {
    if (successInfo) {
      this.addAlert(successInfo.Message || successInfo.message || successInfo, successInfo.ActionPath || '', successInfo.StatusCode || '', 'success', ttl);
    }
  }

  public addAlert(msg: string, path: string = '', statusCode: string = '', type: string = 'info', ttl: number = 5, index: number = null) {
    const alert = new AlertCenter(msg, path, statusCode, type, ttl, index);
    // if (alert.ttl) {
    //     this.removeOnTtl(alert);
    // }

    this.alertCenterList.push(alert);
  }
  public removeByAlert(alert) {
    const ix = this.alertCenterList.indexOf(alert);
    this.removeByIndex(ix);
  }
  private removeByIndex(ix) {
    if (ix >= 0) {
      this.alertCenterList.splice(ix, 1);
    }
  }

  public removeOnTtl(alert: AlertCenter) {
    setTimeout(() => {
      this.removeByAlert(alert);
    }, alert.ttl * 1000);
  }

  constructor() { }
}

export class AlertCenter {
  constructor(
    public msg: string,
    public path: string = '',
    public statusCode: string = '',
    public type: string,
    public ttl: number,
    public index?: number
  ) { }
}
