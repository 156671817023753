import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';


@Component({    
    templateUrl: './createMasavFileDlg.tmpl.html'
})
export class CreateMasavFileDlg implements OnInit {
    public formData: FormGroup;
    public data: any;

    constructor(
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.initForm();
    }


    private initForm(): void {
        this.formData = this.formBuilder.group({
            billingDate: [new Date(), Validators.required],
            makeAsSucceeded: [true, Validators.required]

        });
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}
