<form [formGroup]="searchHoldingsListForm">
    <div class="example-container">
        <div class="bounds">
            <div class="content" style="height: 70%; overflow: auto">
                <button style="background-color: navy; color: white; float: left;" *ngIf="paymentCommitmentID" (click)="editPaymentHoldingsDlg()">ערוך רשומות&nbsp;<i class="fa fa-pencil"></i></button>
                <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                    class="example-table" matSort matSortDisableClear matSortDirection="asc">
                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> סכום
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="סכום">
                            {{row.amount}} {{row.currencyIcon}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="month">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>חודש
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="חודש">
                            {{row.monthName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="year">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> שנה
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" data-label="שנה">
                          {{row.yearName}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="holdingContactName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> איש לזיכוי
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="איש לזיכוי">
                        {{row.holdingContactName}} </mat-cell>
                </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                </mat-table>
            </div>
        </div>
    </div>

</form>
