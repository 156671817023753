import { ContactGroupsData } from './../contactGroups.data';
import { ContactsData } from './../../contacts.data';
import { AlertService } from './../../../services/alert.service';
import { ContactGroupEditDlg } from './contactGroup-edit-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ContactGroup } from './contactGroup';
import { Observable } from 'rxjs';

@Component({
    selector: 'contactGroup-details',
    templateUrl: './contactGroup-details.tmpl.html'
})

export class ContactGroupDetails {

    constructor(
        private contactGroupsData: ContactGroupsData,
        public dialog: MatDialog
    ) {

    }
    @Output() contactGroupChange = new EventEmitter<number>();
    @Output() onEditClick = new EventEmitter<number>();
    @Input('contactGroup') public contactGroup: ContactGroup;

    editContactGroup(cg) {
        this.onEditClick.emit(cg);
    }

    // private openAddOrEditContactGroup(contactGroupID: number = 0): void {      
    //     const dialogRef = this.dialog.open(ContactGroupEditDlg, {
    //         data: { contactId: this.contactGroup.contactID, id: this.contactGroup.contactGroupID, contactGroup: this.contactGroup }
    //     });


    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result && result.valid) {
    //             this.saveContactGroup(result);
    //         }
    //     });
    // }

    // private saveContactGroup(contactGroupForm: FormGroup) {
    //     this.contactsData.update(contactGroupForm.value)
    //         .subscribe((pm: any) => {
    //             return true;
    //         })
    // }

}



