﻿<div *ngIf="city">
    <div class="row">
        <div class="col-sm-12">
            <panel-box [showTitle]="false">
                <div class="row">
                    <div class="col-sm-6">
                        <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', city.contactID]}}]">
                            <h2>{{ city.cityName }} </h2>
                        </a>
                         </div>
                 
                </div>
            </panel-box>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <panel-box title="פרטי עיר: {{ id }}" [showClose]="false" [showCollapse]="false">
                <span class="panel-tools">
                    <a [routerLink]="['/cities', {outlets: {popup: [ id, 'edit']}}]"><i class="fa fa-pencil"></i></a>
                </span>
                <div class="panel-content">
                    <dl class="dl-horizontal">
                        <dt>שם עיר אנגלית</dt>
                        <dd> {{ city.cityNameEn }}</dd>
                        <hr>
                    </dl>
                </div>
            </panel-box>
        </div>
    </div>
</div>