import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
    templateUrl: './tishPlace-move-dlg.tmpl.html'
})


export class TishPlaceMoveDlg implements OnInit {
    public formData: FormGroup;

    constructor(
      public dialogRef: MatDialogRef<TishPlaceMoveDlg>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.formData =   this.formBuilder.group({
          tishTableID: [this.data.tishTableID,Validators.required],
          fromPlace: [this.data.placeNumber , Validators.required],
          tillPlace: [, Validators.required],
          places: [, Validators.required],
      });
    }



    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}
