import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class LoaderBarService {
  // @Output() public showLoaderBarEvent = new EventEmitter<boolean>();

  public visibility: boolean = false;

  constructor() {
    this.visibility = false;
  }

  show() {
       this.visibility = true;
  }

  hide() {
    this.visibility = false; 
  }
}
