<form [formGroup]="paymentForm" novalidate (ngSubmit)="save()" *ngIf="payment">
  <div class="modal-header">
    <h1 style="margin: 0;"> <small>עריכת פרטי תשלום [{{ (id > 0 ? id : 'חדש') }}] </small>
      <br>
      {{ payment.contactName }}{{ payment.lastName }}
      <i matTooltip="תשלום לא פעיל" class="fa fa-window-close" style="float: left;color: red; font-size: 75%;"
        *ngIf="paymentForm.controls.isActive.value != true && paymentForm.controls.statusPayment.value != 2"></i>
    </h1>
  </div>
  <div class="modal-body">
    <div class="example-form">
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי תרומה/תשלום</legend>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <amount-and-currency-input [firstFocus]="true" (shekelValueChange)="shekelValueChange($event)"
              (amountChange)="amountChangeEvent($event)" [idControl]="paymentForm.controls.paymentID"
              [shekelValueControl]="paymentForm.controls.calculatedAmountInILS"
              [amountControl]="paymentForm.controls.amount" [currencyControl]="paymentForm.controls.currency"
              [shekelRateControl]="paymentForm.controls.ilsRate">
            </amount-and-currency-input>
          </div>
          <h4 style="color:red; text-align: end;" fxLayout="row" fxFlex *ngIf="!unequalAmount">סכום התשלום
            וההתחייבויות
            אינו זהה
          </h4>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <payMethods-select-pick [withLabel]="true" (payMethodSelect)="payMethodSelection($event)"
              (payMethodChange)="payMethodChangeEvent($event)" (payMethodAdd)="payMethodChangeEvent($event)"
              [contactID]="payment.contactID" [placeHolder]="'אמצעי תשלום'"
              [payMethodIdControl]="paymentForm.controls.payMethodID" [fromRecordType]="'Payment'"
              [fromRecordID]="paymentForm.controls.paymentID.value">
            </payMethods-select-pick>
            &nbsp;
            <div *ngIf="([88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(+payMethodID) == -1)"
              matTooltip="יש לבחור מסוף לסליקת אשראי, לאחר בחירת נושא ההתחייבות" class="input-group mb-3">
              <label>מסוף:</label>
              <mat-select class="form-control" apply-enter placeholder="מסוף:" formControlName="termID">
                <mat-option [value]="">...</mat-option>
                <mat-option *ngFor="let api of terms" [value]="api.id">
                  {{api.title}}
                </mat-option>
              </mat-select>
            </div>
            <input-info fxLayout="row" style="align-self: end;"
            [control]="paymentForm.controls.termID">
            יש לעדכן מסוף לסליקת אשראי,<br>לאחר בחירת נושא ההתחייבות
          </input-info>

            <mat-error fxLayout="row" style="color:blue" *ngIf="payMethodAmountNotification">
            {{payMethodAmountNotification}}
          </mat-error>
          <mat-error fxLayout="row" style="color:blue" *ngIf="payMethodDateNotification">
            {{payMethodDateNotification}}
          </mat-error>
            <div class="input-group mb-3"
              *ngIf="payMethodID && ([88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(+payMethodID) > -1)">
              <label>עבור חשבון:</label>
              <mat-select apply-enter matTooltip="בחירת חשבון עמותה הכרחית לצורך הנפקת קבלה מתאימה" class="form-control"
                formControlName="accountLocation">
                <mat-option [value]="">...</mat-option>
                <mat-option *ngFor="let cs of servicesData.AccountLocations" [value]="cs.value">
                  {{cs.name}}</mat-option>
              </mat-select>
            </div>
            &nbsp;
            <div class="input-group mb-3" *ngIf="paymentForm.controls.accountLocation.value == 4">
              <label>מס' חשבונית בחשבשבת:</label>
              <input autocomplete="off" class="form-control" apply-enter formControlName="hashReceiptNumber"
                placeholder="מס' חשבונית בחשבשבת...">
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column" style="height: auto">
            <date-input [placeholder]="'תאריך:'" [dateControl]="paymentForm.controls.billingDate">
              Loading...
            </date-input>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column" style="height: auto">
            <div style="width: 250px !important;" class="input-group mb-3" *ngIf="payMethodID && (payMethodID == 6990)">
              <label>תיאור שווי כסף:</label>
              <input autocomplete="off" class="form-control" apply-enter placeholder="תיאור שווי כסף"
                formControlName="moneyValueDescription">
            </div>

          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="payMethodID && (payMethodID == 6990)">
            <div class="input-group mb-3">
              <label>מס' חשבונית:</label>
              <input autocomplete="off" class="form-control" apply-enter placeholder="מס' חשבונית"
                formControlName="invoiceNumberForMoneyValue">
            </div>&nbsp;
            <date-input [placeholder]="'תאריך המופיע על החשבונית'"
              [dateControl]="paymentForm.controls.invoiceDateForMoneyValue">
              Loading...
            </date-input>

          </div>

          <payment-chic-details *ngIf="payMethodID && payMethodID == 89" [paymentID]="payment.paymentID"
            [chicDetails]="payment.chicDetails" [paymentForm]="paymentForm" fxLayout="row">
          </payment-chic-details>
          <h4 style="color:red; text-align: end;" fxLayout="row" fxFlex *ngIf="!unequalChicDetailsAmount">סכום
            התשלום
            אינו תואם את סכום השיקים
          </h4>
          <payment-bankTransfer-details *ngIf="payMethodID && payMethodID == 420" [paymentForm]="paymentForm"
            fxLayout="row">
          </payment-bankTransfer-details>
          <payment-externalSoftware-details *ngIf="payMethodID && payMethodID == 6232" [paymentForm]="paymentForm"
            fxLayout="row">
          </payment-externalSoftware-details>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">

            <date-input [placeholder]="(doneOnLabel != '' ? doneOnLabel + ':' : 'תאריך תשלום - ביצוע:')"
              *ngIf="payMethodID && [ 3580,  6990].indexOf(payMethodID) > -1"
              [dateControl]="paymentForm.controls.doneOn">Loading...</date-input>
            &nbsp;
            <div *ngIf="payMethodID && [3580].indexOf(payMethodID) > -1" class="input-group mb-3">
              <label>סטטוס:</label>
              <mat-select apply-enter class="form-control" placeholder="סטטוס" formControlName="statusPayment">
                <mat-option *ngFor="let cs of servicesData.StatusPayments" [value]="cs.value">
                  {{cs.name}}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column" *ngIf="payMethodID && (payMethodID == 3580)">
            <div class="input-group mb-3">
              <label>מס' voucher:</label>
              <input apply-enter class="form-control" formControlName="voucherNumber" />
            </div>&nbsp;
            <auto-complete [placeholder]="'שם ארגון - voucher:'" [controllerName]="'VouchersOrganizations'"
              [formGroupModel]="paymentForm" [idControl]="paymentForm.controls.vouchersOrganizationID"
              [nameControl]="paymentForm.controls.vouchersOrganizationName">
            </auto-complete>
          </div>
          <hr>
          <label class="header-label">שיוך להתחייבויות:</label>
          <div style="position: relative; width: 100%; top: -36px; text-align: center;" class="form-row">
            <span class="example-fill-remaining-space"></span>
            <div style="background: white; float: left;">

              <h4 style="color: blue; margin: 4px;" *ngIf="payment.isOpenOtherCommitmentsForContact">
                <span style="font-size: 22px;">☜</span> שים לב! קיימות התחייבויות נוספות פתוחות לאיש
                <span style="font-size: 22px;">☞</span>
                <!-- 🢠 -->
              </h4>
              <button class="btn btn-circle btn-success"
                *ngIf="id == 0 && paymentForm.controls.amount.value && paymentForm.controls.paymentCommitments.length > 1"
                matTooltip="חשב התחייבויות באופן שווה" (click)="calculatePaymentCommitmentsAmounts()">
                <i class="fa fa-calculator"></i>
              </button>
              &nbsp;
              <!-- *ngIf="payment.isOpenOtherCommitmentsForContact" -->
              <button apply-enter type="button" (click)="addPaymentCommitmentControl(null,false)">
                הוסף מתוך התחייבויות פתוחות
              </button>
              &nbsp;
              <button type="button" (click)="addPaymentCommitmentControl(null,true)">
                הוסף התחייבות חדשה
              </button>

            </div>
          </div>
          <table class="table table-border" style="margin-top: -16px;"
            *ngIf="paymentForm.controls.paymentCommitments.length > 0">
            <thead class="text-nowrap">

              <th class="narrow"> ... </th>
              <th>נושא</th>
              <!-- <th>שנה</th> -->
              <th>סיבה</th>
              <th class="narrow">סכום</th>
              <th class="narrow">ייעוד מיוחד</th>
              <th class="narrow">תיאור ייעוד</th>
              <th class="narrow">ערך בהתחייבות</th>
              <th class="narrow">...</th>

            </thead>
            <tbody formArrayName="paymentCommitments">
              <ng-container
                *ngFor="let paymentCommitmentFormGroup of paymentForm.controls.paymentCommitments.controls; let i=index"
                [formGroupName]="i">
                <tr payment-commitment-edit (subjectUpdate)="newCommitmentSubjectUpdate($event)"
                  (reloadSubjectTerms)="reloadSubjectTermsEvent($event)"
                  [openNewPaymentCommitment]="paymentCommitmentFormGroup.controls.openNewPaymentCommitment.value"
                  [contactID]="contactId" [ix]="i" [paymentFormGroup]="paymentForm"
                  [paymentCommitmentFormGroup]="paymentCommitmentFormGroup">
                </tr>
              </ng-container>
            </tbody>
          </table>
        </fieldset>
      </div>
      <div class="col-sm-12" style="padding: 0; margin-top: 20px;">
        <div class="col-sm-7">
          <fieldset class="form-group">
            <legend>פרטים לקבלה</legend>
            <div fxLayout="column">
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                  <label>הערה לקבלה:</label>
                  <input type="text" class="form-control" formControlName="receiptComment" placeholder="הערה לקבלה...">
                </div>&nbsp;
                <div class="input-group mb-3">
                  <label>שם לקבלה:</label>
                  <input type="text" class="form-control" formControlName="contactTitleForReceipts"
                    placeholder="שם לקבלה...">
                </div>
              </div>
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <addresses-select-pick (addressChange)="addressChangeEvent($event)"
                  (addressAdd)="addressAddEvent($event)" [contactID]="paymentForm.controls.contactID.value"
                  [placeHolder]="'כתובת לקבלה'" [addresIdControl]="paymentForm.controls.receiptAddressID">
                </addresses-select-pick>&nbsp;
              </div>
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" formControlName="detailsLetterAsReceipt">
                  <label class="custom-control-label" for="detailsLetterAsReceipt">הצג שם זה גם במכתב
                    תודה:</label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-5">
          <fieldset class="form-group">
            <legend>שונות</legend>
            <div class="input-group mb-3">
              <label>מצב...</label>
              <mat-select #activSelect class="form-control" placeholder="מצה..." formControlName="isActive">
                <mat-select-trigger>
                  <span style="color: green;" *ngIf="activSelect.value == true">פעיל</span>
                  <span style="color: red;" *ngIf="activSelect.value != true">לא פעיל</span>
                </mat-select-trigger>
                <mat-option style="color: green;" [value]="true">תשלום פעיל</mat-option>
                <mat-option style="color: red;" [value]="false">לא פעיל</mat-option>
              </mat-select>
            </div>
            &nbsp;&nbsp;

            <!-- <div fxLayout="row" fxLayout.xs="column">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" formControlName="isActive">
                                <label class="custom-control-label" for="isActive">תשלום פעיל:</label>
                            </div>
                        </div> -->
            <div class="input-group mb-3" style="width: 100%;">
              <!-- <label>הערה:</label> -->
              <textarea class="form-control comment" style="min-width: 100%" rows="2" placeholder="הערה..."
                formControlName="comment"></textarea>
            </div>
          </fieldset>
        </div>

      </div>
    </div>
  </div>
  <footer-edit-buttons [onSaveGoToBack]="true" [navigateAfterSaving]="'payments'" [dataProvider]="paymentsData"
    [router]="router" [id]="id" [disabledDeleteButton]="false" [modelFormGroup]="paymentForm">
    <!-- כפתור בוטל עקב שגורם לגביות כפולות -->
    <!-- <ng-template #customHtmlButtons>

        <button *ngIf="ccPayment()" type="button" apply-enter [disabled]="!paymentForm.valid || disableSave" (click)="saveAndClaring(paymentForm)"
                class="btn btn-primary navbar-btn">
                <i class="fa fa-save"></i> שמירה וסליקה
            </button>
        </ng-template>
-->
  </footer-edit-buttons>
</form>
