import { RegistrationEventMain } from './registrationEvent/registrationEvent-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { RegistrationEventEdit } from './registrationEvent/registrationEvent-edit.cmp';
import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { CurrencyPipeMdl } from './../components/pipes/currency-pipe';
import { CommitmentTitleModule } from './../commitment-title/commitment-title.module';
import { AmountAndCurrencyInputModule } from './../components/amount-and-currency-input/amount-and-currency-input.module';
import { MaterialModule } from './../material.module';
//import { PayMethodsMdl } from './../payMethods/payMethods.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { RegistrationEventShortDetails } from './registrationEvent/registrationEvent-short-details.cmp';
import { RegistrationEventDetailsDlg } from './registrationEvent/registrationEvent-details-dlg.cmp';
import { PhonePipeMdl } from '../components/pipes/phone-pipe';
import { RegistrationEventsRoutes } from './registrationEvents.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';

import { RegistrationEventsData } from './registrationEvents.data';
import { RegistrationEventsList } from './registrationEvents-list.cmp';
import { RegistrationEventDetails } from './registrationEvent/registrationEvent-details.cmp';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(RegistrationEventsRoutes),
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        DateInputModule,
        FooterEditButtonsMdl,
        AutoCompleteModule,
        MaterialModule,
        AmountAndCurrencyInputModule,
        CommitmentTitleModule,
        CurrencyPipeMdl,
        ModelInfoModule,
        AutoCompleteModule,
        PhonePipeMdl,

		MainFormModalModule
    ],
    declarations: [
        RegistrationEventsList,
        RegistrationEventDetailsDlg,
        RegistrationEventShortDetails,
        RegistrationEventDetails,
        RegistrationEventEdit,
        RegistrationEventMain
    ],
    exports: [
        RegistrationEventsList,
        RegistrationEventMain
    ],
    providers: [
        RegistrationEventsData
    ],
    entryComponents: [
        RegistrationEventDetailsDlg,
    ]
})
export class RegistrationEventsMdl { }



