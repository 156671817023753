import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class PreviousRouteService {

  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router, private location: Location) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public goToPrev() {
    if (window.history.length > 1) {
      let burl = this.getPreviousUrl();
      if (window.history.length <= 4 && burl.indexOf("/login?returnUrl=") > -1) {
        let baseUrl = burl.replace("/login?returnUrl=", "");
        let rx = new RegExp("\(popup:[^)(]+\)");
        baseUrl = baseUrl.replace(rx, "").replace("(", "").replace(")", "");
        this.router.navigateByUrl(baseUrl);
      } else {
        this.location.back();
      }
    } else {
      let url = this.location.path();
      let rx = new RegExp("\(popup:[^)(]+\)");
      let baseUrl = url.replace(rx, "").replace("(", "").replace(")", "");
      this.router.navigateByUrl(baseUrl);
    }
  }
}