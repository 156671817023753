
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { ContactMain } from './contact/contact-main.cmp';
import { ContactDetailsDlg } from './contacts-details-dlg.cmp';
import { CompensationsMdl } from './../compensations/compensations.mdl';
import { PhonesMdl } from './../phones/phones.mdl';
import { AddressesMdl } from './../addresses/addresses.mdl';
import { ContactCompensationsMdl } from './contactCompensations/contactCompensations.mdl';
import { ContactEventsMdl } from './contactEvents/contactEvents.mdl';
import { ContactGroupsMdl } from './contactGroups/contactGroups.mdl';
import { CommitmentsMdl } from './../commitments/commitments.mdl';
import { PaymentsMdl } from './../payments/payments.mdl';
import { contactsRoutings } from './contacts.routing';
import { RegistrationsMdl } from './../registrations/registrations.mdl';
import { GeneralTableModule } from './../components/general-table/general-table.module';
import { DataCollectionModule } from './../components/data-table/data-table.module';
import { ContactReportAction } from './contact/contact-report-action.cmp';
import { ContactDashboardReportComponent } from './contact/contact-dashboard-report.cmp';
import { ContactEdit } from './contact/contact-edit.cmp';
import { ContactDetails } from './contact/contact-details.cmp';
import { MaterialModule } from './../material.module';
import { ContactsSearchInputModule } from './../components/contacts-search-input/contacts-search-input.module';
import { AutoCompleteInputModule } from './../components/auto-complete-input/auto-complete-input.mdl';
import { DateInputRangeModule } from './../components/dateInput-range/date-input-range.mdl';
import { ContactReportModelDlg } from './contactReports/contact-report-model-dlg.cmp';
import { ContactShortDetails } from './contact/contact-short-details/contact-short-details.cmp';
import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { AuthGuardMdl } from './../_guards/auth.guard.mdl';
import { ContactReciptsDlg } from './contacts-recipts-dlg.cmp';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { TabsMdl } from './../components/tabs/tabs.mdl';
import { PageHeadingModule } from './../components/page-heading/page-heading.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { PhonePipeMdl } from './../components/pipes/phone-pipe';
import { PanelBoxModule } from './../components/panel-box/panel-box.mdl';
import { ContactDetailsActivities } from './contact-details-contactActivities.cmp';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactEditPersonal } from './contact/contact-edit-personal.cmp';
import { ContactsData } from './contacts.data';
import { RouterModule } from '@angular/router';
import { ContactsListDashboard } from './contacts-list-dashboard.cmp';
import { ContactDetailsDashboard } from './contact/contact-details-dashboard.cmp';
import { ContactsList } from './contacts-list.cmp';
import { PayMethodsMdl } from '../payMethods/payMethods.mdl';
import { TasksMdl } from '../tasks/tasks.mdl';
import { HonorsMdl } from '../honors/honors.mdl';
import { TishInvitedsMdl } from '../tishInviteds/tishInviteds.mdl';
import { HoldingsMdl } from '../holdings/holdings.mdl';
import { ProcessesMdl } from '../processes/processes.mdl';
import { PaymentsPlansMdl } from '../paymentsPlans/paymentsPlans.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { ReceiptsMdl } from '../receipts/receipts.mdl';
import { ContactsGroupList } from './contacts-group-list.cmp';
import { ContactAppointmentsMdl } from './contactAppointments/contactAppointments.mdl';
import { DirectivesMdl } from '../components/directives/directives-module';
import { ContactTreeComponent } from './contact-tree/contact-tree.component';
import {  ContactEditPersonalMinDlg } from './contact/contact-edit-personal-min-dlg.cmp';
import { ContactsSelectPickMdl } from './contacts-select-pick/contacts-select-pick.mdl';
import { AchasIledorosDlg } from './contact/achas-iledoros/achas-iledoros-dlg.cmp';
import { BrowserModule } from '@angular/platform-browser';
import { ContactsMinList } from './contacts-min-list.cmp';
import { ShortYearInfoDlg } from './contactReports/short-year-info/short-year-info-dlg.com';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    PanelBoxModule,
    ContactGroupsMdl,
    ContactEventsMdl,
    ContactCompensationsMdl,
    PhonePipeMdl,
    PageHeadingModule,
    AutoCompleteModule,
    TabsMdl,
    AuthGuardMdl,
    DateInputModule,
    AddressesMdl,
    PhonesMdl,
    FooterEditButtonsMdl,
    MaterialModule,
    RouterModule.forChild(contactsRoutings),
    CommitmentsMdl,
    PaymentsMdl,
    RegistrationsMdl,
    PayMethodsMdl,
    TasksMdl,
    HoldingsMdl,
    ProcessesMdl,
    CompensationsMdl,
    ContactAppointmentsMdl,
    ModelInfoModule,
    DateInputRangeModule,
    AutoCompleteInputModule,
    ContactsSearchInputModule,
    PaymentsPlansMdl,
    DateInputModule,
    DataCollectionModule,
    GeneralTableModule,
    ReceiptsMdl,
    HonorsMdl,
    MainFormModalModule,
    DirectivesMdl,
    TishInvitedsMdl,
    ContactsSelectPickMdl,
		BrowserModule,
  ],
  exports: [
    ContactMain,
    ContactsList,
    ContactsMinList,
    ContactEdit,
    ContactDetails,
    ContactReciptsDlg,
    ContactShortDetails,
    ContactDetailsDlg,
    ContactReportAction,
    ContactEditPersonalMinDlg,
    ShortYearInfoDlg,

  ],
  declarations: [
    ContactMain,
    ContactsList,
    ContactsMinList,
    ContactsGroupList,
    ContactDetails,
    ContactEdit,
    ContactEditPersonal,
    ContactDetailsActivities,
    ContactDetailsDlg,
    ContactsListDashboard,
    ContactDetailsDashboard,
    ContactReciptsDlg,
    ContactShortDetails,
    ContactReportModelDlg,
    ContactDashboardReportComponent,
    ContactReportAction,
    ContactsList,
    ContactTreeComponent,
    ContactEditPersonalMinDlg,
    AchasIledorosDlg,
    ShortYearInfoDlg
  ],
  providers: [
    ContactsData,
  ],

  entryComponents: [
    ContactDetailsDashboard,
    ContactDetailsDlg,
    ContactReciptsDlg,
    ContactShortDetails,
    ContactReportModelDlg,
    ContactDashboardReportComponent,
    ContactEditPersonalMinDlg,
    ShortYearInfoDlg
  ]
})

export class ContactsMdl { }

