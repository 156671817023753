<div [formGroup]="searchPaymentsPlansListForm">
  <div class="multi-search" *ngIf="!contactID && !commitmentID" [hidden]="!panelOpenState">
    <div class="form-row" fxLayout="row">
      <date-input-range [placeHolderStart]="'החל מתאריך:'" [placeHolderEnd]="'עד לתאריך:'"
        [dateRangeCtrl]="searchPaymentsPlansListForm.controls.fromDateRange">Loading...
      </date-input-range>
      &nbsp;&nbsp;
      <amount-input-range [placeHolderMin]="'מסכום חו\'...'" [placeHolderMax]="'עד ל...'"
        [amountRangeCtrl]="searchPaymentsPlansListForm.controls.pulseAmountRange">Loading...
      </amount-input-range>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>תאריך סיום...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="תאריך סיום..." formControlName="withTillDate">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">כולל</mat-option>
          <mat-option [value]="false">לא כולל</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="form-row" fxLayout="row">



      <div class="input-group mb-3">
        <label>תשלומים לטיפול...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="תשלומים לטיפול..." formControlName="withPaymentsForTreatment">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">רק לטיפול</mat-option>
          <mat-option [value]="false">רק לא לטיפול</mat-option>
        </mat-select>
      </div>

      &nbsp;&nbsp;

      <div class="input-group mb-3">
        <label>תוכניות פעילות...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
          placeholder="תוכניות פעילות..." formControlName="activePaymentsPlans">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="true">רק פעילים</mat-option>
          <mat-option [value]="false">רק לא פעילים</mat-option>
        </mat-select>
      </div>


      &nbsp;&nbsp;
   <mat-select-multiple-dropdown [keyProperty]="'id'" [titleProperty]="'title'" *ngIf="servicesData.Cities"
        (selectChangeEmt)="indexService.selectionChanged($event)"
        [listControl]="searchPaymentsPlansListForm.controls.cityIDs" [placeholder]="'עיר...'"
        [list]="servicesData.Cities">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <auto-complete apply-enter   #shtibelID
        (onSelect)="indexService.selectionChanged($event)" [controllerName]="'Shtibels'" [placeholder]="'שטיבל...'"
        [formGroupModel]="searchPaymentsPlansListForm" [idControl]="searchPaymentsPlansListForm.controls.shtibelID"
        [nameControl]="searchPaymentsPlansListForm.controls.shtibelName"></auto-complete>
      &nbsp;&nbsp;

      <div class="input-group mb-3">
        <label>שיוך לשטיבל...</label>
        <mat-select class="form-control" (selectionChange)="indexService.selectionChanged($event)"   [disabled]="searchPaymentsPlansListForm.controls.shtibelID.value != null"
          placeholder="שיוך לשטיבל..." formControlName="friendsInStiebel">
          <mat-option [value]="">כולם</mat-option>
          <mat-option [value]="true">חברים בשטיבל</mat-option>
          <mat-option [value]="false">ללא שטיבל</mat-option>
        </mat-select>
      </div>
      <!-- <div style="margin: 20px 20px 0px; text-align:left; white-space: nowrap;" class="example-button-row">
            <button mat-mini-fab color="primary" title="ייצוא לאקסל" (click)="getPaymentsPlansReport()">
            <i class="fa fa-file-excel-o"></i></button> &nbsp;
            <button mat-mini-fab color="primary" title="ייצוא לאקסל - פלוס" (click)="getPaymentsPlansPlusReport()">
              <i class="fa fa-file-excel-o"></i></button> &nbsp;
              <button style="margin: 3px;" mat-mini-fab color="primary" title="הורדה" (click)="printReport()"><i
            class="fa fa-file-pdf-o"></i></button>
      </div> -->
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center" class="min-action-header">
    <span class="example-fill-remaining-space"></span>
    <div *ngIf="!contactID && !commitmentID">
      <button class="btn" (click)="toogleState()">
        {{(panelOpenState ? 'הסתר' : 'חיפוש מורחב')}}
        <i *ngIf="!panelOpenState" class="fa fa-arrow-up"></i>
        <i *ngIf="panelOpenState" class="fa fa-arrow-down"></i>
      </button>
    </div>
    <div>
      <mat-select-multiple-dropdown [withLabel]="false" [keyProperty]="'value'" [titleProperty]="'name'"
        (selectChangeEmt)="indexService.selectionChanged($event)"
        [listControl]="searchPaymentsPlansListForm.controls.commitmentSubjects" [placeholder]="'נושא...'"
        [list]="servicesData.CommitmentSubjects">
      </mat-select-multiple-dropdown>
    </div>


    <div style="text-align:left; white-space: nowrap;" class="example-button-row">
      <button mat-mini-fab class="mini" color="primary" title="סנן" (click)="indexService.getList()">
        <i class="fa fa-filter"></i></button>
      <button mat-mini-fab class="mini" color="primary" title="נקה סינונים" (click)="indexService.clerFilter()">
        <i class="material-icons">clear</i></button>
    </div>
  </div>
  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">

              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <!-- <a mat-menu-item (click)="openDetails(row);">
                                        <mat-icon>remove_red_eye</mat-icon>
                                        <span>תצוגה מהירה</span>
                                    </a> -->
                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['paymentsPlans', row.paymentsPlanID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['paymentsPlans', row.paymentsPlanID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item [disabled]="row.closedDate"
                  [routerLink]="['', {outlets: {popup: ['paymentsPlans', row.paymentsPlanID, 'payments', 'add']}}]">
                  <mat-icon>payment</mat-icon>
                  <span>הוסף תשלום</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="paymentsPlansData" [id]="row.paymentsPlanID"
                    [title]="'מידע רשומה'"></model-info>

                </a>
              </mat-menu>

            </mat-cell>

          </ng-container>
          <ng-container matColumnDef="paymentsPlanID">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.gt-xs> מזהה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה" fxHide fxShow.gt-xs>

              <a [routerLink]="[ '', {outlets: {popup: [ 'paymentsPlans', row.paymentsPlanID]}}]">
                {{ row.paymentsPlanID }}
                <i style="color: black" *ngIf="row.isFundraising" class="fa fa fa-link"></i>
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="contactName">
            <mat-header-cell *matHeaderCellDef> שם איש </mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">
              <a [routerLink]="[ '/contacts', row.contactID]">{{
                row.contactName }}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="paymentsPlanTypeName">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> סוג
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סוג" fxHide fxShow.gt-xs>
              {{row.paymentsPlanTypeName}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="pulseAmount">
            <mat-header-cell *matHeaderCellDef mat-sort-header>סכום חו' </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סכום חו'">
              <span *ngIf="row.payPlanCommitments.length == 1; else ppcsAmount">
                {{ row.pulseAmount | number}} {{ row.currencyIcon }}</span>
              <ng-template #ppcsAmount>
                <span style="text-decoration: underline; font-weight: 700;">{{ row.pulseAmount |
                  number}}
                  {{ row.currencyIcon }}</span><br>
                <span style="font-size: 90%;" *ngFor="let ppc of row.payPlanCommitments">
                  ({{ ppc.partialPulseAmount | number }}{{ row.currencyIcon }})<br>
                </span>
              </ng-template>
            </mat-cell>
          </ng-container>

          <!-- <ng-container matColumnDef="pulseAmount">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> סכום חו' </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="סכום חו'"> {{ row.pulseAmount | number }}
                                {{row.currencyIcon}}
                            </mat-cell>
                        </ng-container> -->
          <ng-container matColumnDef="qty">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מס' תשלומים
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מס' תשלומים" fxHide fxShow.gt-xs>
              {{row.qty}}
              <span *ngIf="row.qty && row.succeededPayments != null"><small>(נותרו:{{row.qty -
                  row.succeededPayments}})</small></span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actualDayOfMonth">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> יום בחודש
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="יום בחודש" fxHide fxShow.gt-xs>
              {{ (row.actualDayOfMonth)}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="fromDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מתאריך
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מתאריך" fxHide fxShow.gt-xs>
              {{row.fromDate | date:'dd/MM/yyyy' }} <br>{{ row.jewishFromDate }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="payMethodID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> באמצעות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="באמצעות">
              <div *ngIf="row.payMethodID">
                <a *ngIf="isLink(row.payMethodID)"
                  [routerLink]="[ '', {outlets: {popup: [ 'payMethods', row.payMethodID]}}]">
                  {{ row.methodTitle }}</a>
                <span *ngIf="!isLink(row.payMethodID)">
                  {{ row.methodTitle }}</span>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tillDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>תאריך סיום (מתוכנן)
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך סיום (מתוכנן)" fxHide fxShow.gt-xs>
              <span *ngIf="row.tillDate; else planDt"> {{ row.tillDate | date:'dd/MM/yyyy' }} <br>{{ row.jewishTillDate
                }} </span>
              <ng-template #planDt> <span [matTooltip]="'תאריך מחושב'" style="color: gray; font-weight: bold;">{{
                  row.plannedTillDate | date:'dd/MM/yyyy' }} <br>{{ row.jewishPlannedTillDate }}</span>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="closedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>תאריך סגירה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך סגירה" fxHide fxShow.gt-xs style="white-space: nowrap;">
              {{ row.closedDate | date:'dd/MM/yyyy' }} <br> {{ row.jewishClosedDate}}
              <i *ngIf="row.closedStatus == 1" [matTooltip]="('נסגר ' +row.closedStatusName)" matSuffix
                style="color: rgb(148 241 0)" class="fa fa-times-circle-o">
              </i>
              <i *ngIf="row.closedStatus == 2" [matTooltip]="('נסגר ' +row.closedStatusName)" matSuffix
                style="color: #f4433673;" class="fa fa-times">
              </i>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="commitmentID">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> התחייבות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="התחייבות" fxHide fxShow.gt-xs>
              <div *ngIf="row.payPlanCommitments.length == 1; else pcsPayments">
                <a contacts
                  [routerLink]="['', {outlets: {popup: ['commitments', row.payPlanCommitments[0].commitmentID]}}]">{{
                  row.payPlanCommitments[0].commitmentID }}</a>
                <a style="display: inline; color: blue;" customToolTip [contentTemplate]="fundraisingTemplate"
                  *ngIf="row.payPlanCommitments[0].commitmentFundraisingID > 0"
                  [routerLink]="['', {outlets: {popup: ['commitments', row.payPlanCommitments[0].commitmentFundraisingID]}}]">
                  {{ row.payPlanCommitments[0].commitmentFundraisingID }}
                  <i style="font-size: 12px;" class="fa fa fa-link"></i>
                </a>

              </div>
              <ng-template #pcsPayments>
                <br>
                <span *ngFor="let pc of row.payPlanCommitments">
                  <span
                    *ngIf="commitmentID == pc.commitmentID || commitmentID == pc.commitmentFundraisingID || expandedRowID == row.paymentID">
                    <a *ngIf="pc.commitmentID"
                      [routerLink]="['', {outlets: {popup: ['commitments', pc.commitmentID]}}]">{{
                      pc.commitmentID }} </a>
                    <a style="display: inline; color: blue;" customToolTip [contentTemplate]="fundraisingTemplate"
                      *ngIf="pc.commitmentFundraisingID > 0"
                      [routerLink]="['', {outlets: {popup: ['commitments', pc.commitmentFundraisingID]}}]">
                      {{ pc.commitmentFundraisingID }}
                      <i style="font-size: 12px;" class="fa fa fa-link"></i>
                    </a>
                    <br>
                  </span>
                </span>
              </ng-template>
              <ng-template #fundraisingTemplate>
                <mat-card style="white-space: nowrap;">
                  <mat-card-content>
                    <commitment-fundraising-info [commitmentID]="row.commitmentFundraisingID">
                      Loading...
                    </commitment-fundraising-info>
                  </mat-card-content>
                </mat-card>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="commitmentSubject">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> תיאור התחיבות (הסכם)
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תיאור התחיבות (הסכם)" fxHide fxShow.gt-xs>
              <div *ngIf="row.payPlanCommitments.length == 1; else pcsDescription">
                <b>{{ row.payPlanCommitments[0]?.subjectName }}</b>
                <span *ngIf="row.payPlanCommitments[0].registrationEventTitle; else commtmtTtle">
                  <br>
                  {{ row.payPlanCommitments[0].registrationEventTitle }}
                </span>
                <br *ngIf="row.payPlanCommitments[0].commitmentReasonName">
                <span>{{ row.payPlanCommitments[0].commitmentReasonName }}</span>
                &nbsp;
                <i style="color: black" *ngIf="row.isFundraising" class="fa fa fa-link"></i>
                &nbsp;
              </div>
              <ng-template #commtmtTtle>
                - {{row.payPlanCommitments[0].donationTypeName}}
                {{ row.payPlanCommitments[0].hebYear }}
              </ng-template>
              <ng-template #pcsDescription>
                <b>{{ row.payPlanCommitments[0]?.subjectName }}</b>
                <br>
                <span *ngFor="let pc of row.payPlanCommitments">
                  <span
                    *ngIf="(commitmentID == pc.commitmentID || commitmentID == pc.commitmentFundraisingID || expandedRowID == row.paymentID) ; else pcCommtmtTtle">
                    <span *ngIf="pc.registrationEventTitle">{{ pc.registrationEventTitle
                      }}</span>
                    <span *ngIf="pc.commitmentReasonName"> {{ pc.commitmentReasonName}} </span>
                    &nbsp;
                    <i style="color: black" *ngIf="row.isFundraising" class="fa fa fa-link"></i>
                    &nbsp;

                    <i *ngIf="pc.isClosedCommitments == true" [matTooltip]="'התחייבות נסגרה'" style="color: #f4433673;"
                      class="fa fa-times">
                    </i>
                    <br>
                  </span>
                  <ng-template #pcCommtmtTtle>
                    <b>{{ pc.subjectName }}</b> - {{ pc.donationTypeName}} {{ pc.hebYear }}
                  </ng-template>
                </span>
              </ng-template>

            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.paymentsPlanID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>


  <ng-template #loading>
    <h3 style="text-align: center;">
      טוען נתונים...
    </h3>
  </ng-template>
