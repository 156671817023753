<div fxLayout="row" fxLayoutAlign="start stretch">
  <div fxFlex="40" fxLayout="column" fxLayoutAlign="center start">
    <div fxLayout="row">
      <div fxLayout="row" fxLayout.xs="column">
        <!-- <a class="minimalize-styl-2 btn btn-info" (click)="toggleNavigationE()">
                    <i style="padding: 3px;" class="fa fa-bars"></i>
                </a> -->
        <a class="minimalize-styl-2 btn btn-primary" (click)="backClickedE()" matTooltip="חזור אחורה">
          <i style="padding: 3px;" class="fa fa-arrow-right"></i>
        </a>
      </div>
      <div fxLayout.xs="row" *ngIf="searchContactsListForm">
        <auto-complete *authGuardDirective="'contacts'" #searcAc [smallTemplate]="true"
          (onSelect)="searchSelect($event)" [placeholder]="'חיפוש ומעבר מהיר ברשימות אנ\'\'ש...'"
          [controllerName]="'Contacts'" [formGroupModel]="searchContactsListForm" [withSubTitle]="true"
          [idControl]="searchContactsListForm.controls.contactID"
          [nameControl]="searchContactsListForm.controls.contactName">
        </auto-complete>
      </div>

    </div>
  </div>
  <div fxLayoutAlign="center center" fxFlex="20" fxLayout="column">
    <h1 class="header-h1">
      {{ title }}
    </h1>
  </div>
  <div fxLayoutAlign="center end" fxFlex="40" fxLayout="column">
    <div fxLayout="row">



      <div class="bar-circles" *ngIf="spinnerService.visibility | async">
        <div class="primary" style="border: solid blue 1.5px;"></div>
        <div class="primary" style="border: solid blue 1.5px;"></div>
        <div class="primary" style="border: solid blue 1.5px;"></div>
      </div>

      <!-- <mat-progress-spinner *ngIf="spinnerService.visibility | async"  diameter="40"
mode="determinate" value="100"></mat-progress-spinner> -->

      <!-- <ul class="nav navbar-top-links navbar-left" *ngIf="waitingDeposits" >
        <li *ngFor="let deps of waitingDeposits">
          <div class="dropdown-messages-box">
            <a class="dropdown-toggle count-info" data-toggle="dropdown" href="#">
              <i class="fa fa-bell" style="color:#e86e30; scale: 1.5;"  customToolTip [contentTemplate]="ttTemplate"></i>
            </a>

            <ng-template #ttTemplate>
              <mat-card style="white-space: nowrap;">
                <mat-card-content>
                 {{deps.depositTypeName}}
                 <br>
                 {{deps.amount | number }} {{ deps.currencyIcon }}
                </mat-card-content>
              </mat-card>
            </ng-template>
          </div>
        </li>

      </ul> -->

      <a id="logoutBtn" (click)="logoutE()" class="minimalize-styl-2 btn btn-info" matTooltip="התנתק">
        <i style="padding: 3px; transform: scale(-1, 1)" class="fa fa-sign-out"></i>
      </a>
    </div>
  </div>
</div>
<!--
<div class="row border-bottom">
    <nav fxLayout="column" class="navbar navbar-static-top scrolling-wrapper" style="margin-bottom: 0">
        <div style="background-color: white;" class="navbar-header" fxLayout="row">
            <div fxLayout.xs="column" fxLayout="row">
                <a class="minimalize-styl-2 btn btn-info" (click)="toggleNavigationE()">
                    <i style="padding: 3px;" class="fa fa-bars"></i>
                </a>
                <a class="minimalize-styl-2 btn btn-primary" (click)="backClickedE()" matTooltip="חזור אחורה">
                    <i style="padding: 3px;" class="fa fa-arrow-right"></i>
                </a>
            </div>
            <div fxFlex fxLayout="row">
                <h1 class="header-h1">
                    {{ title }}
                </h1>
            </div>
            <div fxLayout.xs="column" fxLayout="row">
                <a id="logoutBtn" (click)="logoutE()" class="minimalize-styl-2 btn btn-info" matTooltip="התנתק">
                    <i style="padding: 3px; transform: scale(-1, 1)" class="fa fa-sign-out"></i>
                </a>

            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" style="background-color: white;">
            <div fxFlex fxLayout="row" *ngIf="searchContactsListForm"
                style=" float: right; margin: 10px auto;">
                <auto-complete *authGuardDirective="'contacts'" #searcAc [smallTemplate]="true"
                    (onSelect)="searchSelect($event)" [placeholder]="'חיפוש ומעבר מהיר ברשימות אנ\'\'ש...'"
                    [controllerName]="'Contacts'" [formGroupModel]="searchContactsListForm"
                    [idControl]="searchContactsListForm.controls.contactID"
                    [nameControl]="searchContactsListForm.controls.contactName">
                </auto-complete>
            </div>
            </div>
    </nav>
</div> -->
