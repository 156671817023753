import { AlertService } from './../components/alert/alert.svc';
import { AddressesData } from './addresses.data';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ServicesData } from '../services/services.data';


@Component({
    selector: 'addresses-select-pick',
    templateUrl: './addresses-select-pick.tmpl.html'
})

export class AddressesSelectPick implements OnInit  {

    constructor(
        private addressesData: AddressesData,
        private formBuilder: FormBuilder,
        private alertSvc: AlertService,
        private servicesData: ServicesData,
        public dialog: MatDialog
    ) {

    }
    @Input('contactID') public contactID: number;
    @Input('addresIdControl') public addresIdControl: FormControl;
    @Input('placeHolder') public placeHolder: string;
    @Output() addressAdd = new EventEmitter<any>();
    @Output() addressChange = new EventEmitter<any>();

    addresses;
    public getAddresses(): void {
        this.servicesData.getList(`Addresses/${this.contactID}`).subscribe(
            c => this.addresses = c,
            error => { });
    }

    addressAddEvent(address) {
      console.log("adr" , address);
      this.getAddresses();
        this.addressAdd.emit(address);
    }

    addressChangeEvent(address) {
        this.getAddresses();
        this.addressAdd.emit(address);
    }

    ngOnInit(){
        this.getAddresses();
    }
}



