<div class="ibox float-e-margins">
    <div class="ibox-title" [hidden]="!showTitle">
        <h5>{{ title }}</h5>
        <ng-content select=".panel-title"></ng-content>
        <div class="ibox-tools">
            <ng-content select=".panel-tools"></ng-content>

            <panel-box-btn [hidden]="!showCollapse" (btnClick)="collapse()" icon="fa-chevron-up"></panel-box-btn>
            <panel-box-btn [hidden]="!showClose" (btnClick)="close()" icon="fa-times"></panel-box-btn>
            <model-info *ngIf="modelInfo" [modelInfo]="modelInfo"></model-info>  
        </div>
    </div>
    <div class="ibox-content">
        <ng-content></ng-content> 
    </div>
</div>