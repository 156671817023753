import { ToolTipRendererDirective } from './tool-tip-renderer.directive';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { OverlayModule } from '@angular/cdk/overlay';
import { CustomToolTipComponent } from './custom-tool-tip.component';

@NgModule({
  imports: [BrowserModule, FormsModule, OverlayModule],
  declarations: [CustomToolTipComponent, ToolTipRendererDirective],
  exports: [ToolTipRendererDirective],
  entryComponents: [CustomToolTipComponent]
})
export class CustomToolTipModule { }