import { Component, Input, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModelInfoDlg } from './model-info-dlg.cmp';

@Component({
  selector: 'model-info',
  templateUrl: 'model-info.tmpl.html',
  styleUrls: ['model-info.cmp.scss']
})
export class ModelInfo {
  constructor(
    public dialog: MatDialog,
  ) {
  }

  @Input('dataProvider') public dataProvider?: any;
  @Input('id') public id: number;
  @Input() modelInfo: any = null;
  @Input() title: string = "";


  showInfo() {
    this.dataProvider.recIn(this.id).subscribe(x => {
      this.modelInfo = x;
      const dialogRef = this.dialog.open(ModelInfoDlg, {
        data: this.modelInfo
      });
    });


  }
}
