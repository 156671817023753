 <div matDialogTitle class="modal-header" style="min-width: 850px;">
    <div *ngIf="commitmentEdit &&  commitmentEdit.commitment">
        <h1>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>עריכת פרטי התחייבות [{{ (commitmentEdit.id > 0 ? commitmentEdit.id : 'חדש') }}] </small>
            <br>
            {{ commitmentEdit.commitment.contactName }}
        </h1>
        <div style="background-color: #337ab7; float: left;  position: relative; left: 50px;  top: -72px;">
            <span style="background-color: inherit;" class="label label-primary"> {{ commitmentEdit.donationTypeName }}</span>
        </div>
    </div>
</div>
<mat-dialog-content>
    <commitment-edit [contactId]="data.contactId" [id]="data.id" #commitmentEdit></commitment-edit>
</mat-dialog-content>

<mat-dialog-actions *ngIf="commitmentEdit && commitmentEdit.commitment && commitmentEdit.commitmentForm"
    class="modal-footer" align="end">
    <button apply-enter [disabled]="commitmentEdit.commitmentForm.invalid" class="btn btn-primary navbar-btn"
        [mat-dialog-close]="commitmentEdit.commitmentForm"><i class="fa fa-save"></i> שמור </button>
    <!-- <button class="btn btn-warning navbar-btn" *ngIf="commitmentEdit.commitment.commitmentID > 0 && commitmentEdit.commitment.isActive"
        [mat-dialog-close]="commitmentEdit.commitmentForm" ><i class="fa fa-warning"></i> מחק
    </button> -->
</mat-dialog-actions>