import { ContactAppointmentsData } from './contactAppointments.data';
import { ContactsData } from './../contacts.data';
import { AlertService } from './../../components/alert/alert.svc';
import { ContactAppointmentEditDlg } from './contactAppointment/contactAppointment-edit-dlg.cmp';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { ContactAppointment } from './contactAppointment/contactAppointment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'contactAppointments-list',
	templateUrl: './contactAppointments-list.tmpl.html'
})
export class ContactAppointmentsList implements OnInit {


	constructor(
		private contactAppointmentsData: ContactAppointmentsData,
		private formBuilder: FormBuilder,
		private alertSvc: AlertService,
		public dialog: MatDialog
	) {
	}

	contactAppointments: any[];
	searchContactAppointmentsListForm: FormGroup;
	tempNewEmail;
	tempNewContactAppointment;

	viwHistory = false;
	@Input('contactID') public contactID: number;
	//@Input('contactAppointments') public contactAppointments: ContactAppointment[];


	contactAppointmentChangeEvent() {
		this.getContactAppointments();
	}

	onEditClick(obj: ContactAppointment) {
		this.openAddOrEditContactAppointment(obj);
	}

	public getContactAppointments(): void {
		this.searchContactAppointmentsListForm.controls['contactID'].setValue(this.contactID);
		this.contactAppointmentsData!.getContactAppointments(this.contactID)
			.subscribe((x: any[]) => {
				this.contactAppointments = x;
			}
			);
	}

	openAddOrEditContactAppointment(contactAppointment?: ContactAppointment): void {
		setTimeout(() => {
			if (!contactAppointment) {
			contactAppointment = new ContactAppointment();
			contactAppointment.contactID = this.contactID;
		}
		const dialogRef = this.dialog.open(ContactAppointmentEditDlg, {
			data: { contactId: contactAppointment.contactID, id: contactAppointment.appointmentID, contactAppointment: contactAppointment },
			// width: '90%'

		});


		dialogRef.afterClosed().subscribe(result => {
			if (result && result.valid) {
				this.saveContactAppointment(result);
			}
		});
	},200);
	}

	private saveContactAppointment(contactAppointmentForm: FormGroup) {
		this.contactAppointmentsData.update(contactAppointmentForm.value)
			.subscribe((pm: any) => {
				this.getContactAppointments();
				return true;
			})
	}


	private initForm() {
		this.searchContactAppointmentsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			contactID: [this.contactID, Validators.required],
			selectedRows:[],
			orders: this.formBuilder.array([]),
		});

	}

	ngOnInit(): void {
		this.initForm();
		this.getContactAppointments();
	}




}
