import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';


@Injectable()

export class TransferTasksData {




  private url = 'api/transferTasks';

  constructor(
    private toraxHttp: ToraxHttp
  ) { }




  getList(searchHoldingsListForm: any) {
    return this.toraxHttp.post(`${this.url}/List`, searchHoldingsListForm);
  }


  exportExcel(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
  }

  saveFile(fd: FormData, bankName: string, fileName: string, associationAccountNumber: string) {
    return this.toraxHttp.postWithResponce(`${this.url}/postFiles/${bankName}/${fileName}/${associationAccountNumber}`, fd);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();

  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}

