import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';  

@Directive()
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
   @Output() public showProgressBarEvent = new EventEmitter<boolean>();

  public visibility: BehaviorSubject<boolean>;

  constructor() {
    this.visibility = new BehaviorSubject(false);
  }

  show() {
    // this.visibility.next(true);
    this.showProgressBarEvent.emit(true);
  }

  hide() {
    // this.visibility.next(false);
    this.showProgressBarEvent.emit(false);
  }
}
