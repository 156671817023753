import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { observable, Observable } from 'rxjs';
import { Payment } from '../payments/payment/payment';
import { CcChargeData } from './cc-charge.data';
import { ServicesData } from './../services/services.data';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, ChangeDetectorRef, Inject } from '@angular/core';
import { ModalService } from '../components/modal/modal.svc';
import { CcChargeModel } from './cc-charge-model';
import { AlertService } from '../components/alert/alert.svc';

@Component({
  templateUrl: './cc-charge-dlg.tmpl.html'
})

export class CcChargeDlg implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<CcChargeDlg>,
    @Inject(MAT_DIALOG_DATA) public data: CcChargeModel,
    private formBuilder: FormBuilder,
    private ccChargeData: CcChargeData,
    private alertSvc: AlertService,
    public servicesData: ServicesData,
    private cdRef: ChangeDetectorRef,
  ) { }

  @ViewChild('barGif') barGif: ElementRef;
  @ViewChild('successResult') successResult: ElementRef;
  @ViewChild('errorResult') errorResult: ElementRef;
  //  @ViewChild('exBtn', { static: false }) exBtn: ElementRef;
  //@ViewChild('showAuthorizationNumberDiv', { static: false }) showAuthorizationNumberDiv: ElementRef;

  @Output() hasPublishNowEE = new EventEmitter<boolean>();


  hasPublishNow: boolean = false;
  showCloseButton: boolean = false;
  afterPublished: boolean = false;
  payMethodEdit: boolean = false;
  termEdit: false;
  successResultTxt = '';
  errorResultTxt = '';
  public payMethods;
  public terms;
  public formData: FormGroup;



  public publish(): void {
    this.hasPublishNow = true;
    this.afterPublished = true;
    let scssElmnt = this.successResult.nativeElement;
    let errElmnt = this.errorResult.nativeElement;
    //  let showAuthorizationNumberDivElmnt = this.showAuthorizationNumberDiv.nativeElement;
    //let exBtnElmnt = this.exBtn.nativeElement;

    this.ccChargeData.chargePayment(this.formData.value)
      .subscribe((res: any) => {
        this.hasPublishNowEE.emit(false);
        let ret = res.ret;
        let sttsRspns = +ret.statusResponse;

        // tslint:disable-next-line:curly
        if (sttsRspns == 1) {
          this.successResultTxt = `מס' אישור ${+ret.debitApproveNumber} \n${ret.message} \n${ret.error}`;
          errElmnt.innerText = "";
          scssElmnt.innerText = this.successResultTxt;
          this.matDialogRef.close();
          this.alertSvc.success(this.successResultTxt);

          this.hasPublishNow = false;
          // this.moda
        }
        else if (([128, 132, 134, 322, 341, 599]).indexOf(+ret.debitApproveNumber) > -1) {  //למנוע מצב של גביה כפולה בעת שמנסה להחליף מסוף ולבצע גביה מחדש ברקע
          this.successResultTxt = `מטפל בסליקה ברקע, אין לסלוק שוב, סגור חלונית ובדוק מאוחר יותר`;
          errElmnt.innerText = "";
          scssElmnt.innerText = this.successResultTxt;
          this.matDialogRef.close();
          this.alertSvc.success(this.successResultTxt);

          this.hasPublishNow = true;
        }
        else {
          this.errorResultTxt = `תגובת אשראי: ${ret.debitApproveNumber} \n${ret.message} \n${ret.error}`;
          scssElmnt.innerText = "";
          errElmnt.innerText = this.errorResultTxt;

          this.hasPublishNow = false;
        }

        this.showCloseButton = true;
      },
        error => {
          this.errorResultTxt = error;
          scssElmnt.innerText = "";
          errElmnt.innerText = this.errorResultTxt;

          this.hasPublishNow = false;
        });
  };

  public refundPayment(): void {
    // console.log('refundPayment run');
    // this.ccChargeData.refundPayment(this.paymentForm.value)
    //     .then((c: any) => {
    //         let debitApproveNumber = +c.debitApproveNumber;
    //         // tslint:disable-next-line:curly
    //         if (debitApproveNumber > 1)
    //             this.alertSvc.success(`תגובת אשראי: \nמס' אישור${debitApproveNumber} \n${c.message} \n${c.error}`);
    //         else {
    //             this.alertSvc.error(`תגובת אשראי: \n${c.message} \n${c.error}`);
    //         }
    //     })
    //     .catch();
  };



  private getPayMethods(): void {
    this.servicesData.getList(`PayMethods/${this.formData.controls['contactID'].value}/true`).subscribe(
      c => this.payMethods = c,
      error => { });
  }

  selectPM(e: any) {
    let pmId = e.target.value;
    let pmTitle = e.target.selectedOptions[0].label;
    this.formData.controls.PayMethodID.patchValue(pmId);
    this.formData.controls.PayMethodTitle.patchValue(pmTitle);
  }

  selectTerm(e: any) {
    let tId = e.target.value;
    let tTitle = e.target.selectedOptions[0].label;
    this.formData.controls.termID.patchValue(tId);
    this.formData.controls.termName.patchValue(tTitle);
  }


  payMethodAddEvent(payMethod) {
    this.formData.controls['payMethodID'].setValue(payMethod.payMethodID);
    this.cdRef.detectChanges();
  }

  payMethodChangeEvent(payMethod) {
    this.formData.controls['payMethodID'].setValue(payMethod.payMethodID);
    this.cdRef.detectChanges();
  }

  payMethodSelection(payMethod) {
    this.cdRef.detectChanges();
  }


  ngOnInit(): void {
    //this.data = (<any>this.data).ccChargeModel;
    this.initForm();
    this.getPayMethods();
    this.loadTerms();
  }


  private initForm(): void {
    this.formData = this.formBuilder.group({
      contactID: [this.data.contactID],
      payMethodID: [this.data.payMethodID],
      payMethodTitle: [this.data.payMethodTitle],
      paymentID: [this.data.paymentID],
      amount: [this.data.amount],
      currency: [this.data.currency],
      termID: [this.data.termID],
      termName: [this.data.termName],
      authorizationNumber: [this.data.authorizationNumber],
      currencyIcon: [this.data.currencyIcon],
      contactName: [this.data.contactName],
    });
  }


  loadTerms() {
    let subjects = this.data.paymentCommitments.map(x => x.commitmentSubject).join();

    if (this.data.contactID && subjects && subjects != '') {
      //רק באשראי
      if ([0, 88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(this.data.payMethodID | 0) == -1) {
        this.servicesData.getList(`SubjectAndOptionTermsAndDefault/${this.data.contactID}/${subjects}/${this.data.paymentOption}`)
          .subscribe((x: any) => {
            this.formData.controls['termID'].setValue(this.data.payMethodID);


            this.terms = x.value;
            let tid = this.formData.controls['termID'].value;
            if (x.value && x.value.map(y => y.id).indexOf(tid) == -1) {
              this.formData.controls['termID'].setValue(null);
            }
          });
      }
    }
  }

  public isValid(): boolean {
    return (this.formData && this.formData.valid);
  }
}
