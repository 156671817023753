import { ToraxHttp } from './../../utils/custom-http.svc';
import { ContactGroup } from './contactGroup/contactGroup';

import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";

@Injectable()

export class ContactGroupsData {

    private url = 'api/contactGroups';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }

    getContactGroups(contactID: number) {
        return this.toraxHttp.get(`${this.url}/getContactGroups/${contactID}`);
    }

    get(contactGroupID: number) {
        return this.toraxHttp.get(`${this.url}/${contactGroupID || 0}`);
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }


    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(contactId: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
    }

    update(contactGroup: ContactGroup) {
        return this.toraxHttp.update(this.url, contactGroup);
    }

    setContactGroupPriority(contactID: number, prevContactGroupID: number, curContactGroupID: number, newCurPriority: number) {
        let ret = this.toraxHttp.postWithResponce(`${this.url}/setContactGroupPriority?contactID=${contactID}&prevContactGroupID=${prevContactGroupID}&curContactGroupID=${curContactGroupID}&newCurPriority=${newCurPriority}`, null);
        // this.onUpdated(contactID);
        return ret;
    }

    // removeDefaultContactGroupID(contactID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/removeDefaultContactGroupID?ContactID=${contactID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;
    // }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();

    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

