import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { HonorsSetMain } from './honors-set/honors-set-main.cmp';
import { MaterialModule } from './../material.module';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { HonorsSetsData } from './honors-sets.data';
import { HonorsSetsList } from './honors-sets-list.cmp';
import { HonorsSetEdit } from './honors-set/honors-set-edit.cmp';
import { HonorsSetDetails } from './honors-set/honors-set-details.cmp';
import { HonorsSetsRoutes } from './honors-sets.routing';
import { HonorsMdl } from '../honors/honors.mdl';
import { ModelInfoModule } from '../components/model-info/model-info.mdl';
import { DateInputRangeModule } from './../components/dateInput-range/date-input-range.mdl';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(HonorsSetsRoutes),
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,
        DateInputModule,
        AutoCompleteModule,
        MaterialModule,
        MainFormModalModule,
        HonorsMdl,
        ModelInfoModule,
        DateInputRangeModule,
    ],
    declarations: [
        HonorsSetsList,
        HonorsSetEdit,
        HonorsSetDetails,
        HonorsSetMain
    ],
    exports: [
        HonorsSetsList,
        HonorsSetEdit,
        HonorsSetDetails,
        HonorsSetMain
    ],
    providers: [
        HonorsSetsData
    ]
})
export class HonorsSetsMdl { }

