<form>
    <div class="modal-header" *ngIf="registrationEvent">
        <div>
            <h1 style="margin: 0;"> <small>עריכת פרטי אירוע [{{ (id > 0 ? id : 'חדש') }}] </small>
                <br>
                {{ registrationEvent.title }}
            </h1>
        </div>
    </div>
    <div class="modal-body" *ngIf="registrationEvent">
        <div class="example-form" [formGroup]="registrationEventForm" novalidate>
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>פרטי אירוע</legend>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">

                        <div class="input-group mb-3">
                            <label>סוג:</label>
                            <mat-select class="form-control" apply-enter formControlName="registrationEventType"
                                placeholder="סוג...">
                                <mat-option value="">...</mat-option>
                                <mat-option *ngFor="let team of servicesData.RegistrationEventTypes"
                                    [value]="team.value">
                                    {{team.name}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <date-input [placeholder]="'תאריך'" [dateControl]="registrationEventForm.controls.date">Loading...</date-input>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">

                        <div class="input-group mb-3">
                            <label>מחיר:</label>
                            <input type="text" class="form-control narrow"  [disabled]="true"  [value]="registrationEventForm.controls.price.value" />
                        </div>
                        &nbsp; <div class="input-group mb-3">
                            <label>מטבע:</label>
                            <mat-select class="form-control  narrow" placeholder="מטבע..." formControlName="currency">
                                <mat-option value=""></mat-option>
                                <mat-option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                                    {{c.name}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>מסוף:</label>
                            <mat-select class="form-control" placeholder="מסוף:" formControlName="termID">
                                <mat-option [value]="">...</mat-option>
                                <mat-option *ngFor="let api of servicesData.Apis" [value]="api.value">
                                    {{api.name}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>

                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [onSaveGoToBack]="true" [navigateAfterSaving]="'registrationEvents'"
        [dataProvider]="registrationEventsData" [router]="router" [id]="id" [disabledDeleteButton]="false"
        [modelFormGroup]="registrationEventForm">
    </footer-edit-buttons>
</form>