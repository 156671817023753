import { ServicesData } from './../../services/services.data';
import { TishInvitedsData } from './../tishInviteds.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { TishInvited } from './tishInvited';
import { MatDialog } from '@angular/material/dialog';
import { ContactDetailsDlg } from 'src/app/contacts/contacts-details-dlg.cmp';
import { PreviousRouteService } from 'src/app/_helpers/previous-route.service';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
export class PaymentOption { }
export class Api { }

@Component({
  selector: 'tishInvited-edit',
  templateUrl: './tishInvited-edit.tmpl.html'
})

export class TishInvitedEdit implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private tishInvitedsData: TishInvitedsData,
    private formBuilder: FormBuilder,
    private location: Location,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private previousRouteService: PreviousRouteService,
    private toraxHttp: ToraxHttp
  ) {

  }


  tishInvited: TishInvited;
  id: number;
  tishEventID: number;
  contactId: number;
  tishInvitedForm: FormGroup;




  openConactDetails(row) {
    this._matDialog.open(ContactDetailsDlg, {
      // panelClass: 'contact-details',
      data: row,
      // width: '90%'
    });
  }


  public load(): void {
    if (this.id > 0) {
      this.tishInvitedsData.getEdit(this.id)
        .subscribe((invited: any) => {
          this.fillData(invited);
        });
    } else if (this.id == 0 && this.contactId) {
      this.tishInvitedsData.getAddInvited(this.tishEventID, this.contactId)
        .subscribe((invited: any) => {
          this.fillData(invited);
        });
    } else {
      this.tishInvitedsData.getAdd(this.tishEventID)
        .subscribe((invited: any) => {
          this.fillData(invited);
        });
    }
  }

  public tishGroups: any[];
  private fillData(invited: any) {
    this.tishInvited = invited;
    this.tishEventID = this.tishEventID || invited.tishEventID;
    this.contactId = this.contactId || invited.contactID;
    this.id = this.id || invited.id;
    this.fillForm(invited);

  }

  loadEventGroups() {
    let eid = this.tishInvitedForm.controls.tishEventID.value;
    console.log(eid)
    if (eid == 0) return;

    this.servicesData.getTishGroups(eid).subscribe((x: any) => {
      this.tishGroups = x;
    });
  }

  private fillForm(invited: TishInvited): void {
    this.tishInvitedForm.patchValue(invited);
  }

  public save(tishInvitedForm: FormGroup): void {
    if (tishInvitedForm.valid) {
      this.tishInvitedsData.update(tishInvitedForm.getRawValue())
        .subscribe((c: any) => {
          this.location.back();
          //this.router.navigate(['tishInviteds', c.id], { relativeTo: this.activatedRoute });
        });
    } else {
      console.log(this.tishInvitedForm.errors);
    }
  }

  public cancel(): void {
    this.location.back();
  }

  private initForm() {
    this.tishInvitedForm = this.tishInvitedsData.initForm();
  }
  defaultLastNameToUpdateChilds;
  selectionChanged(e) {
    let lnVal = this.tishInvitedForm.controls['lastName'];
    if (!lnVal.value)
      lnVal.setValue(e?.subTitle);
  }


  public delete(): void {
    this.tishInvitedsData.delete(this.id).subscribe(
      (x) => {
        if (x.message.indexOf("מוזמן משובץ") > -1) {
          let confirmMsg = false;
          confirmMsg = confirm(`${confirmMsg}\nהאם ברצונך למחוק רשומה בכל זאת`);
          if (confirmMsg == true) {
            this.tishInvitedsData.deleteAnyway(this.id, true).subscribe(
              (y) => {
                this.previousRouteService.goToPrev();
              }, error => {
                this.toraxHttp.handleError(error);
              });
          }
        }
      },
      error => {
        this.toraxHttp.handleError(error);
      });
  }

  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['tishInvitedID'] || 0;
      this.tishEventID = +params['tishEventID'] || 0;
      this.contactId = +params['contactID'] || 0;
    });
    //this.servicesData.getList("TishEvents").subscribe(e => { this.tishEvents = e; }, error => { });

    this.initForm();

    this.load();
    this.loadEventGroups();
  }


}





