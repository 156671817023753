import { ServicesData } from 'src/app/services/services.data';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Component, Input, ElementRef, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';

@Component({
    selector: 'amount-and-currency-input',
    templateUrl: 'amount-and-currency-input.component.html',
    styleUrls: ['amount-and-currency-input.component.scss']
})

export class AmountAndCurrencyInputComponent implements AfterViewInit {
    //  amount?;
    constructor(public servicesData: ServicesData) {

    }
    currencyObjontrol: FormControl = new FormControl();
    isInEditShekelValue: boolean = false;
    isInEditShekelRate: boolean = false;


    @Input('idControl') public idControl: FormControl;
    @Input('amountControl') public amountControl: FormControl;
    @Input('currencyControl') public currencyControl: FormControl;
    @Input('shekelRateControl') public shekelRateControl: FormControl;
    @Input('shekelValueControl') public shekelValueControl: FormControl;
    @Input('firstFocus') public firstFocus: boolean = false;
    // @Input('dollarValueControl') dollarValueControl: FormControl;

    
    @Input('placeholderAmount') placeholderAmount?: string = 'סכום';
    @Input('placeholderCurrency') placeholderCurrency?: string = 'מטבע';

    // @ViewChild('amountInp', { static: false }) ndt: ElementRef;


    @Output() amountChange = new EventEmitter<number>();
    @Output() currencyChange = new EventEmitter<number>();
    @Output() shekelRateChange = new EventEmitter<number>();
    @Output() shekelValueChange = new EventEmitter<number>();
    //  @Output() dollarValueChange = new EventEmitter<number>();
    // public obserAmount: Observable<ElementRef> = new Observable<ElementRef>();



    currencySelectionChange(e) {
        //console.log(e);
    }


    ngAfterViewInit(): void {
        if (this.currencyControl.value && this.servicesData.Currencies) {
            let curncyObj = this.servicesData.Currencies.find(x => x.value == this.currencyControl.value);
            this.currencyObjontrol.setValue(curncyObj);

        }


        //    if (this.idControl && this.idControl.value == 0) {  //רק במקרה שתשלום חדש


        this.currencyObjontrol.valueChanges.subscribe(x => {
            if (x) {
                if (!this.currencyControl || !this.shekelRateControl) {
                 //   console.log(this.currencyControl);
                 //   console.log(this.shekelRateControl);
                }
                this.currencyControl.setValue(x.value); //הכנסת מטבע
                this.shekelRateControl.setValue(x.ilsRate); // הכנסת ערך המטבע בשקל
            }
        });

        this.amountControl.valueChanges.subscribe(x => {
            this.amountChange.emit(x);
            if (this.shekelRateControl.value) {
                this.calculateShekelValue();
            }
        });

        this.shekelRateControl.valueChanges.subscribe(x => {
            if (this.amountControl.value) {
                this.calculateShekelValue();
            }
        });

        //   }
        // this.shekelValueControl.valueChanges.subscribe(x => {
        //     this.calculateShekelRate();
        // });


    }

    calculateShekelRate() {
        let amount = this.amountControl.value;
        let shekelValue = +this.shekelValueControl.value;

        if (amount && shekelValue) {
            let rateValue = (shekelValue / amount);
            this.shekelRateControl.setValue(rateValue);
        }
    }

    calculateShekelValue() {
        let amount = this.amountControl.value;
        let shekelRate = this.shekelRateControl.value;

        if (amount && shekelRate) {
            let shekelValue = (amount * shekelRate);
            this.shekelValueControl.setValue(shekelValue);
        }
    }

}
