<div class="example-form" *ngIf="ivrAssignmentUserTaskForm" [formGroup]="ivrAssignmentUserTaskForm" enter-tab>
    <div style="padding: 0 15px;">
        <div class="form-group">
            <div class="form-row" fxLayout="row">
                <auto-complete-input apply-enter [placeholder]="'נושא'" [controllerName]="'AssignmentUserTasksSubjects'"
                    [stringValueControl]="ivrAssignmentUserTaskForm.controls.subject"
                    [formGroupModel]="ivrAssignmentUserTaskForm">
                </auto-complete-input>

            </div>
            <div class="form-row">
                <label>תיאור:</label>
                <textarea rows="4" apply-enter class="comment" placeholder="תיאור..."
                    formControlName="comment"></textarea>
            </div>
            <div class="form-row" fxLayout="row">
                <auto-complete-input apply-enter [placeholder]="'אחראי ביצוע'"
                    [controllerName]="'AssignmentUserTasksUserNames'"
                    [stringValueControl]="ivrAssignmentUserTaskForm.controls.userName"
                    [formGroupModel]="ivrAssignmentUserTaskForm">
                </auto-complete-input>
            </div>
            <div class="form-row" fxLayout="row">
                <date-input apply-enter [placeholder]="'תאריך טיפול:'"
                    [dateControl]="ivrAssignmentUserTaskForm.controls.doneDate">Loading...
                </date-input>
            </div>
        </div>
    </div>
    <div class="col-sm-6">
        <div class="form-group">
            <div class="form-row" fxLayout="column">
                <div fxLayout="column" style="display: block;">
                    <img *ngIf="imgUrl" [src]="imgUrl" height="200"> <br />
                    <input type='file' class="form-control" (change)="onSelectFile($event)">
                </div>
            </div>
        </div>
    </div>
</div>