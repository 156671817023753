import { HoldingsPrintHtmlDlg } from './holdings-print-dlg.cmp';

import { MatDialog } from '@angular/material/dialog';
import { AlertService } from './../services/alert.service';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { ModalService } from './../components/modal/modal.svc';
import { ServicesData } from 'src/app/services/services.data';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { HoldingsData } from './holdings.data';
import { HoldingsPrintDetailsMdl } from './holding/holding';
import { HoldingsExlsDlg } from './holdings-exls-dlg.cmp';

@Component({
    selector: 'holdings-main',
    templateUrl: './holdings-main.tmpl.html',
    styleUrls: ['./holdings.scss']
})

export class HoldingsMain {
    constructor(

        public router: Router,
        private holdingsData: HoldingsData,
        public servicesData: ServicesData,
        public dialog: MatDialog,
        private toraxHttp: ToraxHttp,
    ) {
    }

    viwHistoryList:boolean = false;
    viwHoldingsList:boolean = false;

    openHoldingsPrintDlg(): void {
      const dialogRef = this.dialog.open(HoldingsPrintHtmlDlg, {
          //data: this.id,
          // width: '80%'
      });
  }
  openHoldingsExlsDlg(): void {
     const dialogRef = this.dialog.open(HoldingsExlsDlg, {
         //data: this.id,
         // width: '80%'
     });
 }

    // private printHoldingsHtml = (holdingsPrintHtmlDlg: any): void => {
    //     if (holdingsPrintHtmlDlg) {
    //         this.holdingsData.printHoldingsHtml(<HoldingsPrintDetailsMdl>holdingsPrintHtmlDlg)
    //             .subscribe(data => {
    //                     let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    //                     popupWinindow.document.open();
    //                     popupWinindow.document.write((<any>data).value);
    //                     popupWinindow.document.close();
    //                     return true;
    //             });
    //     }
    // }



}
