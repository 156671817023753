
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HonorTypesData } from './honor-types.data';
import { Component, OnInit } from '@angular/core';
import { ServicesData } from '../services/services.data';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { ToraxHttp } from '../utils/custom-http.svc';
import { IndexService } from '../services/index-service';


@Component({
  selector: 'honor-types-list',
  templateUrl: 'honor-types-list.tmpl.html'
})

export class HonorTypesList implements OnInit {
  constructor(
    private honorTypesData: HonorTypesData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/honors') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  displayedColumns: string[] = ['buttons', 'honorTypeID', 'honorDepartmentName', 'honorTypeDescription', 'letterText', 'letterSettingTitle'];
  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  searchHonorTypesListForm: FormGroup;
  actualMinusHeight = '185';

  addNew() {
    this.router.navigate(['', { outlets: { popup: ['honorTypes', 'add'] } }]);
  }

  openSelectedRow(honorTypeID) {
    this.router.navigate(['', { outlets: { popup: ['honorTypes', honorTypeID] } }]);
  }

  private initForm() {
    this.searchHonorTypesListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      honorDepartmentID: [],
      honorDepartmentName: [],
      selectedRows: [],
      orders: this.formBuilder.array([]),
    });
  }


  ngOnInit() {
    this.initForm();

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchHonorTypesListForm.patchValue(frm);
    }

    this.searchHonorTypesListForm.updateValueAndValidity();
    //this.selection.isSelected = this.isChecked.bind(this);
    let params = [
      // { key: "contactID", value: this.contactID },
    ];
    this.indexService = new IndexService(this.searchHonorTypesListForm, params, this.honorTypesData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();
  }


}
