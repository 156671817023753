<div *ngIf="!screenMode || screenMode == ''" style="margin: 0 !important;padding: 0 !important;">
  <!-- <span   style="background: white;color: blue; font-weight: bold; position: fixed;left: 50px"> {{isMobile}} {{ gh.screenVHHeight }} </span> -->
  <mat-toolbar *ngIf="currentUser"
    style="width: 100%; height: 30px; font-family: Rubik; border-bottom: solid; border-color: #A5A5A5;">
    <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
      <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
      <mat-icon *ngIf="sidenav.opened">close</mat-icon>
    </button>
    <navbar-top style="width: 100%;" [ngStyle]="{'margin-right': topNavMargin}" #navBarTop [title]="title"
      (toggleNavigation)="toggleNavigation(e)" (backClicked)="backClicked(e)" (logout)="logoutBtn(e)"
      (reLoadAllLists)="reLoadAllLists(e)">
    </navbar-top>
  </mat-toolbar>
  <mat-sidenav-container  style="z-index: 0;">
    <mat-sidenav #sidenav="matSidenav" style="z-index: 1;"
      [ngStyle]="{'width': (sidenav.mode === 'over' && !currentUser ? '0px' : '200px')}">
      <ul *ngIf="currentUser && navItems" class="nav" id="side-menu">
        <li class="nav-header">
          <div class="dropdown profile-element">
            <span>
              <img alt="image" class="img-circle" style="width: 100%; border-radius:0"
                src="./assets/img/html_logo.png" />
            </span>
            <a data-toggle="dropdown" href="#">
              <span class="clear">
                <span class="block m-t-xs">
                  <strong class="font-bold">{{ userName }}</strong>
                </span>
              </span>
            </a>
            <ul class="dropdown-menu animated fadeInRight m-t-xs">
              <li><a (click)="logoutE()">התנתק</a></li>
            </ul>
          </div>
          <div class="logo-element">
            G|X
          </div>
        </li>
        <li>
          <mat-nav-list style="padding: 0;">
            <app-menu-list-item *ngFor="let item of navItems  | filterMenu:userRoles" [item]="item">
            </app-menu-list-item>
          </mat-nav-list>
        </li>
        <!--
            <li style="background-color: unset I !important;">
                <a (click)="sendEmail()">
                    <i class="fa fa-envelope-open-o"></i>
                    <span class="nav-label"> &nbsp;דוא"ל לתמיכה</span>
                </a>
            </li>
          -->
        <li *authGuardDirective="'signalr'">
          <signalr-main></signalr-main>
        </li>
      </ul>
    </mat-sidenav>
    <mat-sidenav-content style="height: calc(100vh - 50px);" >
      <div style="height: 100%; overflow-y:auto;">
        <router-outlet></router-outlet>
        <!-- <div style="height: 30px; background-color: red; width: 100%; position: fixed; bottom: 0;">
        ערכי מטבעות:<label>111111</label>
        ערכי מטבעות:<label>111111</label>

      </div> -->
      </div>
      <alert-global></alert-global>
      <alert-center-global></alert-center-global>
      <div class='modal' routerModal>
        <div class="modal-dialog" cdkDrag>
          <div class="modal-content"
            style="max-width: 100%; z-index: 2050; height: fit-content; width: min-content; margin: 0 auto;">
            <div cdkDragHandle class="hndl-top"></div>
            <router-outlet name="popup"></router-outlet>
            <div cdkDragHandle class="hndl-buttom"></div>
          </div>
        </div>
        <div class="modal-backdrop"></div>
      </div>
      <modal-container></modal-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <div id="area-print" style="overflow: visible;"></div>
  <div id="contentToConvert"></div>
</div>
<div *ngIf="screenMode === 'screenIndex'"
  style="margin: 0 !important;padding: 0 !important; background:white; position:absolute; top:0px; right:0px; bottom:0px; left:0px;">
  <screen-page></screen-page>
</div>
