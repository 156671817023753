import { Directive, HostListener, OnInit, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
    selector: 'mat-select'
})
export class OptionalMatSelectDirective {

    constructor(private matSelect: MatSelect, private elementRef: ElementRef,
        private renderer: Renderer2) { }

    @HostListener('openedChange', ['$event'])
    onOpenedChange(isOpened: boolean) { 
        if ((isOpened && <any>event).key == "Enter") {
            this.matSelect.close();
            return;
         //   event.preventDefault;
        } 
    }
}

