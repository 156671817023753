import { ServicesData } from '../../services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HonorsData } from '../honors.data';
import { Honor } from './honor';

@Component({
  selector: 'honor-edit',
  templateUrl: './honor-edit.tmpl.html'
})

export class HonorEdit implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private honorsData: HonorsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
  ) {
  }

  honor: Honor;
  id: number;
  contactId: number;
  honorForm: FormGroup;

  public load(): void {
    if (this.id > 0) {
      this.honorsData.getEdit(this.id || 0)
        .subscribe((c: any) => {
          this.honor = c;
          this.honorForm.patchValue(c);
        });
    } else {
      this.honorsData.getAdd(this.contactId)
        .subscribe((c: any) => {
          this.honor = c;
          this.honorForm.patchValue(c);
        });
    }
  }


  payMethods;
  public getPayMethods(): void {
    this.servicesData.getList(`PayMethods/${this.contactId}/${false}`).subscribe(
      c => this.payMethods = c,
      error => { });
  }

  private initForm() {
    this.honorForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      honorID: [this.id || 0, Validators.required],
      honorsSetID: [],
      honorsSetTitle: [],
      contactID: [this.contactId, Validators.required],
      contactName: [],
      receivingContactID: [null],
      receivingContactName: [null],
      relationTypeID: [19, Validators.required],
      honorTypeID: [null, this.honorTypeValidation],
      honorTypeDescription: [null],
      honorDate: [new Date().toJSON(), Validators.required],
      contributionAmount: [],
      currency: [],
      //payMethodID: [],
      doOpenCommitment: [false],
      commitmentID: [],
      doCreateLetter: [false],
      contactLetterID: [],
      reasonComment: []
    });
    this.honorForm.controls['receivingContactID'].valueChanges.subscribe(x => {
      this.relationTypeCalc(this.honorForm);
    });
    this.honorForm.controls['contactID'].valueChanges.subscribe(x => {
      this.relationTypeCalc(this.honorForm);
    });
    //this.honorForm.setValidators(this.receivingContactValidation);
  }
  private relationTypeCalc(fb: FormGroup) {
    let cid = +fb.controls['contactID'].value;
    let rcid = +fb.controls['receivingContactID'].value;
    let rtid = +fb.controls['relationTypeID'].value;

     if (rcid && cid != rcid && rtid && rtid == 19 ) {
      fb.controls['relationTypeID'].setValue(null);
    }
    fb.updateValueAndValidity();
  }
  public honorTypeValidation = c => {

    if (this.honorForm) {

      let honorTypeID = this.honorForm.controls['honorTypeID'].value;
      let isValid = honorTypeID > 0;
      if (!isValid) {
        return { required: true };
      }
      return null;
    }
  };
  // public receivingContactValidation = c => {
  //   if (this.honorForm) {
  //     let receivingContactID = this.honorForm.controls['receivingContactID'].value;
  //     let receivingRelationTypeID = this.honorForm.controls['receivingRelationTypeID'].value;
  //     if ((receivingContactID == null && receivingRelationTypeID == null) || (receivingContactID > 0 && receivingRelationTypeID > 0)) {
  //       return null;
  //     } else {
  //       return { required: true };
  //     }
  //   }
  // };


  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['honorID'];
      this.contactId = +params['contactID'];
    });
    this.initForm();
    this.load();
    this.getPayMethods();
  }
}



