
import { Component, OnInit, ViewChild,  ElementRef, Input, Output,  EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
    selector: 'time-input',
    templateUrl: 'time-input.tmpl.html'
})



export class TimeInput implements OnInit  {
   // dt?;
    time?;
    constructor() { }

    @Input('withBorder') withBorder: Boolean = false;

    //@Input('dateControl') dateControl: FormControl;
    @Input('timeControl') timeControl: FormControl;
    @Input('rangeTemplate') rangeTemplate?: boolean = false;
    @Input('timePlaceholder') timePlaceholder?: string = 'שעה...';
    @Input('datePlaceholder') datePlaceholder?: string = 'תאריך...';
    @Input('withLabel') public withLabel: boolean = true;

    // @ViewChild('ndt') ndt: ElementRef;
    // @ViewChild('ndtJewish') ndtJewish: ElementRef;

    @Output() dateChange = new EventEmitter<Date>();

    // public onChange(e) {
    //     if (e) {
    //         e.preventDefault();
    //     }
    //     // this.dateChange.emit(this.dateControl.value);
    // }

    //public obserDate: Observable<ElementRef> = new Observable<ElementRef>();
    timeSet(e){
        this.timeControl.setValue(e);
        console.log(this.timeControl.value);
        //  let tempTime = new Date(this.timeControl.value);

        //  var timeParts = e.split(':');
        //   tempDate.setHours(parseInt(timeParts[0], 10));
        //   tempDate.setMinutes(parseInt(timeParts[1], 10));
        //   tempDate.setSeconds(parseInt(timeParts[2], 10));

    }

    ngOnInit() {


    }

    // ngAfterViewInit(): void {
    //     let dateChangeEvent = this.dateChange;
    //     let dateCtrl = this.dateControl;
    //     let heJewish = (<any>$).bililite.flexcal.tol10n(['he-jewish', { dateFormat: 'dddd MM YYYY' }]);
    //     let georgi = (<any>$).bililite.flexcal.tol10n(['en', { dateFormat: 'dd/mm/yyyy' }]);
    //     let mn: any;
    //     mn = jQuery(this.ndt.nativeElement);
    //     mn.removeClass('hasDatepicker');
    //     mn.flexcal({
    //         calendars: [
    //             [
    //                 'he',
    //                 'לועזי',
    //                 {
    //                     closeText: "סגור",
    //                     prevText: "&#x3C;הקודם",
    //                     nextText: "הבא&#x3E;",
    //                     currentText: "היום",
    //                     monthNames: ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
    //                     monthNamesShort: ["ינו", "פבר", "מרץ", "אפר", "מאי", "יוני", "יולי", "אוג", "ספט", "אוק", "נוב", "דצמ"],
    //                     dayNames: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
    //                     dayNamesShort: ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "שבת"],
    //                     dayNamesMin: ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "שבת"],
    //                     dateFormat: "dd/mm/yyyy",
    //                     isRTL: true,
    //                 }
    //             ],
    //             [
    //                 'he-jewish',
    //                 'עברי',
    //                 {
    //                     currentText: "היום",
    //                     isRTL: true,
    //                 }
    //             ],
    //         ],
    //         position: 'br',
    //         duration: 1,
    //         changeMonth: true,
    //         changeYear: true,
    //         // buttons: ['today commit'],
    //         set: this.formatDate(heJewish, georgi, dateCtrl, dateChangeEvent),
    //         commit: this.formatDate(heJewish, georgi, dateCtrl, dateChangeEvent, true),
    //         hidden: function (e, d) {
    //             if (e.target.value == "") {
    //                 $(this).siblings("#jewishDate").text("");
    //             }
    //             //dcc.emit(e.target.value);
    //         },
    //         create: function (e, d) {
    //             if (e.target.value && e.target.value != "") {
    //                 d = new Date(e.target.value);
    //                 let j = (<any>$(this)).flexcal('format', d, heJewish);
    //                 $(this).siblings("#jewishDate").text(j);
    //                 let g = (<any>$(this)).flexcal('format', d, georgi);

    //             }
    //         },
    //     });
    //     //  this.ndt.nativeElement.click();


    // }



    // private formatDate(heJewish: any, georgi: any, dateCtrl: FormControl, dcc: any, emitChangeEvent: boolean = false) {
    //     return function (e, d) {
    //         let j = (<any>$(this)).flexcal('format', d, heJewish);
    //         $(this).siblings("#jewishDate").text(j);
    //         let g = (<any>$(this)).flexcal('format', d, georgi);
    //         dateCtrl.setValue(g);
    //         if (emitChangeEvent) {
    //             dcc.emit(g);
    //         }
    //     };
    // }
}

