
import { LocationStrategy, PlatformLocation, Location, CommonModule } from '@angular/common';
import { singleTabLarge } from './single-tab-large.cmp';
import { NgModule, AfterContentInit, ContentChildren, QueryList, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'tabs-large',
    template: `
    <div class="tabs-container">
    <ul style="color: white; margin-left: 15px; margin-right: 15px;" class="nav nav-tabs">
       <li style="float: right" *ngFor="let singleTab of tabsList" (click)="selectTab(singleTab)" [class.active]="singleTab.active">
          <a style ="font-size:30px;font-weight: 100;color: white;background-color: rgb(87, 142, 190);">{{singleTab.title}}</a>
       </li>
    </ul>
    <div class="tab-content">
        <ng-content></ng-content>
    </div>
    </div>
  `
})

export class TabsLarge implements AfterContentInit {
    constructor(
        private location: Location,
    ) { }
    @ContentChildren(singleTabLarge) tabsList: QueryList<singleTabLarge>;

    @Output() onSelectTab = new EventEmitter<number>();


    // contentChildren are set
    ngAfterContentInit() {
        // get all active tabs
        let activeTabs = this.tabsList.filter((tab) => tab.active);
        //get active from refresh, else first tab selected
        let selectabIndex = sessionStorage.getItem(`${this.location.path}`) || 0;
        this.selectTab(this.tabsList.toArray()[selectabIndex]);
    }

    selectTab(singleTabLarge: singleTabLarge) {
        // deactivate all tabs
        this.tabsList.toArray().forEach(singleTab => singleTab.active = false);
        //store selected tab index in sessionStorage
        let selectabIndex = this.tabsList.toArray().indexOf(singleTabLarge);
        sessionStorage.setItem(`${this.location.path}`, `${selectabIndex}`);
        // activate the tab the user has clicked on.
        singleTabLarge.active = true;

        this.onSelectTab.emit(singleTabLarge.tabIndex);
    }

}