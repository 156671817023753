﻿<div [formGroup]="searchIvrAssignmentUserTasksListForm">
  <div class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">
      <div class="input-group mb-3">
        <label>סטטוס...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)" placeholder="סטטוס..."
          formControlName="hasDone">
          <mat-option [value]="null">הכל</mat-option>
          <mat-option [value]="true">בוצע</mat-option>
          <mat-option [value]="false">לא-בוצע</mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchIvrAssignmentUserTasksListForm.controls.userNames" [placeholder]="'אחראים...'"
        [list]="servicesData.AssignmentUserTasksUserNamesVms">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
        (selectChange)="indexService.selectionChanged($event)" [listControl]="searchIvrAssignmentUserTasksListForm.controls.subjects"
        [placeholder]="'נושא...'" [list]="servicesData.AssignmentUserTasksSubjectsVms">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <span fxFlex class="example-fill-remaining-space"></span>
      <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">

        <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
            class="fa fa-filter"></i></button>&nbsp;
        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
            class="material-icons">clear</i></button>&nbsp;
        <button mat-mini-fab color="primary" title="ייצוא לאקסל" (click)="exportToExcel()"><i
            class="fa fa-file-excel-o"></i></button>&nbsp;
        <button mat-mini-fab color="primary" title="הורדה" (click)="downloadPdf()"><i
            class="fa fa-file-pdf-o"></i></button>&nbsp;
        <button mat-mini-fab color="primary" title="הוסף" (click)="addNew()" aria-label="הוסף חדש">
          <i class="material-icons">add</i>
        </button>

      </div>
    </div>
  </div>
  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <!-- [ngStyle]="{'height':  'calc('+ gh.getScreenHeight() +'vh - ' + actualMinusHeight + 'px)' }"> -->

      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc('+ gh.ScreenVHHeight  +'vh - ' + actualMinusHeight + 'px)' }">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="select">
            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
              <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox apply-enter (click)="$event.stopPropagation()" (change)="$event ? indexService.rowToggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['ivrAssignmentUserTasks', row.ivrAssignmentUserTaskID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="ivrAssignmentUserTasksData" [id]="row.ivrAssignmentUserTaskID"
                    [title]="'מידע רשומה'">
                  </model-info>
                </a>

              </mat-menu>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="createDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך">
              {{ row.createDate | date: 'dd/MM/yyyy hh:mm' }} <br>{{ row.jewishCreateDate }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="subject">
            <mat-header-cell *matHeaderCellDef mat-sort-header> נושא </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="נושא">
              <span style="font-weight: bold;"> {{ row.subject }} </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="userName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> אחראי ביצוע </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="אחראי ביצוע">
              {{ row.userName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="contactName">
            <mat-header-cell *matHeaderCellDef> שם מתקשר </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שם מתקשר">
              {{ (row.contactName ? row.contactName : row.identifiedContactName) }}<br>
              {{row.phoneNumber | phone}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="doneByUserName">
            <mat-header-cell *matHeaderCellDef> טופל ע"י </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="טופל ע''י">
              {{ row.doneByUserName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="doneDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך טיפול </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך טיפול">
              {{ row.doneDate | date: 'dd/MM/yyyy hh:mm' }} <br>{{ row.jewishDoneDate }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef> תיאור </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תיאור">
              {{row.comment}}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.ivrAssignmentUserTaskID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
