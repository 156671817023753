
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { ModalService } from '../components/modal/modal.svc';
import { ServicesData } from 'src/app/services/services.data';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { YarzeitsData } from './yarzeits.data';

@Component({
    selector: 'yarzeits-main',
    templateUrl: './yarzeits-main.tmpl.html',
})

export class YarzeitsMain {
    constructor(

        public router: Router,
        private yarzeitsData: YarzeitsData,
        public servicesData: ServicesData,
    ) {
    }

    viwYarzeitsList:boolean = false;
    viwYarzeitAffiliationsList:boolean = false;




}
