import { platformBrowserDynamicTesting } from '@angular/platform-browser-dynamic/testing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
declare var window: any;
const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamicTesting(providers).bootstrapModule(AppModule).catch(err => console.log(err));
