<div style=" cursor: initial;" *ngIf="address"
    [ngStyle]="{'color': (address.isActive) ? 'inherit' : 'red'}">
    <div class="example-full-width" fxLayout="row" fxLayoutAlign="start center">
       <address-add-action (addressChange)="addressChangeEvent(address)" fxLayout="column"  [address]="address" [contactID]="address.contactID"></address-add-action>
       <!-- <a fxLayout="column" style="padding: 4px" (click)="editAddress(address)">
            <i matTooltip="עריכה ופרטים נוספים" class="fa fa-pencil"></i>
        </a> --> &nbsp;
        <model-info fxLayout="column" style="padding: 4px" [modelInfo]="address"></model-info>
        <a  style="padding: 4px;cursor: pointer" target="_blank"
            href="http://www.google.com/maps/place/{{address.latitude}},{{address.longitude}}">
            <i style="padding: 4px" matTooltip="גוגל מאפס" fxLayout="column" class="fa fa-map-marker"></i>
        </a>
       <div style="padding-right:7px" fxLayout="column" fxFlex>
            <div fxLayout="row">
                <div>{{ address.shortAddressTitle }}</div>
            </div>
            <div fxLayout="row"><small>{{ address.comment }}</small></div>
        </div>
         <i style="padding: 4px" matTooltip="למשלוח דואר" *ngIf="address.forPost" fxLayout="column" class="fa fa-envelope-o"></i>
        <i style="padding: 4px" matTooltip="כתובת נבדקה" *ngIf="address.confirmed" fxLayout="column" class="fa fa-check"></i>
    </div>
</div>
