
import { ServicesData } from '../../services/services.data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Output, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Deposit } from 'src/app/deposits/deposit/deposit';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';
import { DepositsData } from '../deposits-data';


@Component({
  selector: 'deposit-edit-dlg',
  templateUrl: './deposit-edit-dlg.tmpl.html'
})

export class DepositEditDlg implements OnInit, AfterViewInit {


  constructor(
    public dialogRef: MatDialogRef<DepositEditDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private depositsData: DepositsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private cdRef: ChangeDetectorRef,
  ) {

  }

  @Output() depositChange = new EventEmitter<any>();

  id: number;
  deposit: Deposit;
  depositForm: FormGroup;
  depositID;

  // setting this is the key to initial select.
  public load(): void {
    // this.depositsData.getEdit(this.depositID || 0)
    //   .subscribe((p: any) => {
    //     this.deposit = p;
    //     this.fillForm(this.deposit);
    //   });
  }

  private fillForm(d: Deposit): void {
    this.depositForm.patchValue(d);
    this.cdRef.detectChanges();
  }




  private initForm() {
    this.depositForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      depositID: [0, Validators.required],
      depositDate: [null, Validators.required],
      depositType: [null, Validators.required],
      amount: [null, Validators.required],
      currency: [null, Validators.required],
      comment: [],
      organizationBankAccountID:[null,Validators.required],
      paymentsIDs: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.deposit = this.data.deposit;
    this.id = this.data.deposit.id;
    this.initForm();

    this.fillForm(this.deposit);
  }

  ngAfterViewInit() {
  }
  removeDeposit(){

  }

}



