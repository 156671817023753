<!--
<input class="form-control narrow" [format]="24" [ngxMatTimepicker]="pickerA" placeholder="00:00"/>
<ngx-mat-timepicker #pickerA enableKeyboardInput (timeSet)="timeSet($event)"></ngx-mat-timepicker> -->


<div style="display: inline-block; vertical-align: middle;" class="form-group"  [ngClass]="{'has-error': timeControl.invalid}">
  <label *ngIf="withLabel" class="control-label">{{timePlaceholder}}</label>
  <div style="width:min-content;display: table;" class="input-group mt-2">
    <input class="form-control narrow" [format]="24" [ngxMatTimepicker]="pickerA" placeholder="00:00"/>
    <ngx-mat-timepicker #pickerA enableKeyboardInput (timeSet)="timeSet($event)"></ngx-mat-timepicker>
  </div>
</div>
