<form *ngIf="data" class="example-form" [formGroup]="formData" novalidate>
    <div matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            שינוי מיקום שולחן<br>
            <small>אירוע: <b>{{ data.tishEventTitle }}</b></small> </h2>
    </div>
    <mat-dialog-content>
        <div fxLayout="column" style="display: block;">

            <div [formGroup]="formData" *ngIf="data" class="panel-content">

                   <auto-complete #searcAc [smallTemplate]="true"
                      [placeholder]="'חיפוש...'" [controllerName]="'TishTables/' + data.tishEventID" [formGroupModel]="formData"
                      [idControl]="formData.controls.tishTableID" [nameControl]="formData.controls.tishTableName" >



                    </auto-complete>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions style="float: left; padding: 20px;">
      <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn"
            [mat-dialog-close]="formData"><i class="fa fa-save"></i> שמור </button>
        <button class="btn btn-warning navbar-btn" *ngIf="data.tishTableID > 0" mat-dialog-close><i
                class="fa fa-warning"></i> ביטול
        </button>

    </mat-dialog-actions>

</form>
