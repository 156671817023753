import { VouchersOrganizationMain } from './vouchersOrganization/vouchersOrganization-main.cmp';
 
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { VouchersOrganizationsList } from './vouchersOrganizations-list.cmp';


export const VouchersOrganizationsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'vouchersOrganizations', component: VouchersOrganizationsList, data: {  roles: "vouchersOrganizations",  title: 'ארגוני שוברים' } },
    { canActivate: [AuthGuard], path: 'vouchersOrganizations/add', component: VouchersOrganizationMain, data: { mode: 'edit', roles: "vouchersOrganizations", title: 'הוסף ארגון' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'vouchersOrganizations/:vouchersOrganizationID', component: VouchersOrganizationMain, data: {  roles: "vouchersOrganizations",  title: 'פרטי ארגון' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'vouchersOrganizations/:vouchersOrganizationID/edit', component: VouchersOrganizationMain, data: { mode: 'edit', roles: "vouchersOrganizations",  title: 'עריכת פרטי ארגון' }, outlet: 'popup' },

]

// export const VouchersOrganizationsRoutes: Routes = [
//     {
//         canActivate: [AuthGuard], path: 'vouchersOrganizations', data: {  roles: "vouchersOrganizations",  title: 'ארגוני שוברים' },
//         children: [
//             { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: VouchersOrganizationsList, data: { title: 'ארגון' } },
//             { canActivate: [AuthGuard], path: 'add', component: VouchersOrganizationEdit, data: { title: 'הוסף ארגון' }, outlet: 'popup' },
//             {
//                 canActivate: [AuthGuard], path: ':vouchersOrganizationID',
//                 children: [
//                     { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: VouchersOrganizationDetails, data: { title: 'פרטי ארגון' } },
//                     { canActivate: [AuthGuard], path: 'edit', component: VouchersOrganizationEdit, data: { title: 'ערוך פרטי ארגון' }, outlet: 'popup' },
//                                      {
//                         canActivate: [AuthGuard], path: 'payments',
//                         children: [
//                             { canActivate: [AuthGuard], path: 'add', component: VouchersOrgPaymentPackEdit, data: { title: 'הוסף תשלום עבור Vouchers' }, outlet: 'popup' },
//                             {
//                                 canActivate: [AuthGuard], path: ':paymentID',
//                                 children: [
//                                     { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: VouchersOrgPaymentPackDetails, data: { title: 'פרטי תשלום עבור Vouchers' } },
//                                     { canActivate: [AuthGuard], path: 'edit', component: VouchersOrgPaymentPackEdit, data: { title: 'ערוך פרטי תשלום עבור Vouchers' }, outlet: 'popup' },
//                                 ]
//                             }
//                         ]
//                     },
//                 ]
//             }]
//     }
// ];

