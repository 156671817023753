import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardChartsDataService } from './dashboard-charts.data';
import { AfterViewInit, Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';


@Component({
  selector: 'dashboard-charts-control',
  templateUrl: 'dashboard-charts-control.component.html',
  styleUrls: ['dashboard-charts.component.scss', 'dashboard-charts-control.component.scss']
})
export class DashboardChartsControlComponent implements OnInit, AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<DashboardChartsControlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private dashboardChartsDataService: DashboardChartsDataService

  ) {
  }
  legendPosition: string = 'below';
  view: any[] = [250, 120];
  colorScheme = {
    domain: ['red', 'green']
  };
  pieChartSourse: any[];
 
  goToBalancesList() {
    event.preventDefault();
    this.dialogRef.close();
    this.router.navigate(['/charts', { grp: this.data.grp, balances: true }]);
  }

  goToOverPaidsList() {
    event.preventDefault();
    this.dialogRef.close();
    this.router.navigate(['/charts', { grp: this.data.grp, overPaids: true }]);
  }

  
  goToCommitmentTicketsList() {
    event.preventDefault();
    this.dialogRef.close();
    this.router.navigate(['/charts/pledgesTickets']);
  }

  
  ngOnInit() {
    this.pieChartSourse = [
      {
        "name": "יתרה לתשלום",
        "value": this.data.balance
      },
      {
        "name": "נתקבל",
        "value": this.data.paid
      }
    ]
    // this.loadGlobalChart();
  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }
  loadGlobalChart() {
    this.dashboardChartsDataService.getGlobalInfoControlModel(this.data.grp)
      .subscribe((x: any) => {
        this.data = x;
        this.pieChartSourse = [
          {
            "name": "יתרה לתשלום",
            "value": x.balance
          },
          {
            "name": "נתקבל",
            "value": x.paid
          }
        ]
      });
  }
}