import { HebrewCalendar, HDate } from '@hebcal/core';


export class JewishWeekDay {
  private _georgDate: Date;
  private _hDate: HDate;
  private _dayName: string;
  private _monthName: string;
  private _year: number;
  private _yearName: string;
  private _dayInweekName: string;



  constructor(georgDate: Date) {
    this._georgDate = georgDate;
    this._hDate = new HDate(this._georgDate);

    this._dayInweekName = this.numberToHebrewLetters(this._hDate.getDay() + 1);
    this._dayName = this.numberToHebrewLetters(this._hDate.getDate());
    this._monthName = this.getHebrewMonthName();
    this._yearName = this.numberToHebrewLetters(this._hDate.getFullYear());
    this._year = this._hDate.getFullYear();

  }

  public getHebrewMonthName(): string {
    const monthsInHebrew = ["ניסן", "אייר", "סיון", "תמוז", "אב", "אלול", "תשרי", "חשון", "כסלו", "טבת", "שבט", "אדר"];
    const monthsInHebrewLapYear = ["ניסן", "אייר", "סיון", "תמוז", "אב", "אלול", "תשרי", "חשון", "כסלו", "טבת", "שבט", "אדר א", "אדר ב"];
    // const hd = new HDate(new Date(2008, 10, 13)); // 15 Cheshvan 5769
    let isLap = this._hDate.isLeapYear();

    if (isLap)
      return monthsInHebrewLapYear[this._hDate.getMonth()-1];
    else
      return monthsInHebrew[this._hDate.getMonth()-1];
  }
  get dayInweekName(): string {
    return this._dayInweekName;
  }

  set dayInweekName(value: string) {
    this._dayInweekName = value;
  }

  get dayName(): string {
    return this._dayName;
  }

  set dayName(value: string) {
    this._dayName = value;
  }

  get monthName(): string {
    return this._monthName;
  }

  set monthName(value: string) {
    this._monthName = value;
  }

  get yearName(): string {
    return this._yearName;
  }

  set yearName(value: string) {
    this._yearName = value;
  }

  public getHebrewDayOfWeek(): string {
    const daysOfWeekInHebrew = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"];

    return daysOfWeekInHebrew[this._hDate.getDay()];
  }

  getJewishDayInMonth(dt) {
    return this.numberToHebrewLetters(this._hDate.getDate()); // מחזיר את התאריך העברי כמחרוזת
  }

  numberToHebrewLetters(num: number): string {
    const letters = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת'];
    const tens = Math.floor(num / 10);
    const ones = num % 10;
    let result = '';

    if (num === 15) { // ט"ו
      return 'ט"ו';
    } else if (num === 16) { // ט"ז
      return 'ט"ז';
    }

    if (tens > 0) {
      if (tens === 1) {
        result += 'י';
      } else if (tens === 2) {
        result += 'כ';
      } else if (tens === 3) {
        result += 'ל';
      } else {
        result += letters[tens - 1] + '"';
      }
    }

    if (ones > 0) {
      result += letters[ones - 1];
    }

    return result;
  }

}
