﻿<table *ngIf="data; else loading">
    <caption>תנועות אחרונות</caption>
    <tr>
        <td>סכום</td>
        <td>תאריך ביצוע</td>
        <td>סטטוס</td>
        <td>תגובה/הודעה</td>
    </tr>
    <tr *ngFor="let t of data">
        <td>{{t.amount}}{{t.currencyIcon}}</td>
        <td>{{t.executionDate | date: 'hh:mm dd-MM-yyyy'}}</td>
        <td>{{t.statusResponseName}}</td>
        <td>{{t.messages}}<br *ngIf="t.messages && t.errors"/>{{t.errors}}</td>
    </tr>
</table>


<ng-template #loading>
    <h1>
        <i style="color: gray" class="fa fa-refresh fa-spin"></i>
    </h1>
</ng-template>