
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionsData } from './transactions.data';
import { ServicesData } from '../services/services.data';
import { ToraxHttp } from '../utils/custom-http.svc';
import { IndexService } from '../services/index-service';

@Component({
  selector: 'transactions-list',
  templateUrl: './transactions-list.tmpl.html',
  styleUrls: ['./transactions.scss'],
})

export class TransactionsList implements OnInit {

  constructor(
    public transactionsData: TransactionsData,
    public formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public router: Router,
    public _matDialog: MatDialog,
    public toraxHttp: ToraxHttp,
  ) {


  }
  indexService: IndexService;
  displayedColumns: string[] = ['buttons', 'transactionID', 'amountToCollect', 'executionDate', 'statusResponse', 'messages', 'severalAttempts', 'responseTransId', 'collectionBuilderID', 'payMethodID', 'termID', 'paymentsIds', 'sourceTransactionIDWhenRefund'];
  searchTransactionsListForm: FormGroup;

  @Input('payMethodID') public payMethodID?: number;
  @Input('contactID') public contactID?: number;
  @Input('collectionBuilderID') public collectionBuilderID?: number;

  groupingColumn = '';
  groupingOrderColumn = '';
  defaultvalues;

  ngOnInit() {
    this.initForm();
    if (this.payMethodID == null) {
      let item = sessionStorage.getItem(this.router.url);
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
        this.searchTransactionsListForm.patchValue(frm);
      }
    }


    let params = [{ key: "payMethodID", value: this.payMethodID }, { key: "contactID", value: this.contactID }, { key: "collectionBuilderID", value: this.collectionBuilderID }];
    this.indexService = new IndexService(this.searchTransactionsListForm, params, this.transactionsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);



    //  this.topOpenings();
    //   this.indexService.OnLoaddedEnd.subscribe(x => this.setFocus(x));

    this.indexService.getList();

  }

  topOpenings() {
    // this.orderButtonIsChicked = true;
    // this.indexService.addOrder(this.groupingOrderColumn, true);
  }

  addNew() {
    //this.router.navigate([{ outlets: { popup: ['add'] } }]);
  }

  openSelectedRow(transactionID) {
    //this.router.navigate(['transactions', transactionID]);
  }
  openDetails(row) {
    // this._matDialog.open(PaymentDetailsDlg, {
    // 	panelClass: 'payment-details',
    // 	data: row.paymentCommitmentID
    // });
  }


  public currentIndex(e): void {
    console.log(e);
  }


  private initForm() {
    this.searchTransactionsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      termID: [],
      statusResponses: [null],
      payMethodGroup: [null],
      payMethodID: [null],
      collectionBuilderID: [null],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });
  }

}
