
import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'emergency-squads-main',
  templateUrl: './emergency-squads-main.tmpl.html',
  //styleUrls: ['./emergency-squads.scss']
})

export class EmergencySquadsMain {
  constructor(
    public router: Router,

  ) {
  }

  viwMembersList: boolean = false;
  viwQueuesList: boolean = false;





}
