




import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';


import { ContactsData } from './../contacts.data';
import { Contact } from './contact';
import { ServicesData } from '../../services/services.data';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'contact-edit-personal-min-dlg',
  templateUrl: './contact-edit-personal-min-dlg.tmpl.html'
})



export class ContactEditPersonalMinDlg implements OnInit {
    public formData: FormGroup;
    constructor(
        public matDialogRef: MatDialogRef<ContactEditPersonalMinDlg>,
        @Inject(MAT_DIALOG_DATA) public data: Contact, //contactID
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        public contactsData: ContactsData,
        public activatedRoute: ActivatedRoute
    ) {

    }

   // contact: Contact;
    id: number;
    contactId: number;


    onNoClick(): void {
        this.matDialogRef.close();
    }


    delete() {
        this.contactsData.delete(this.data.contactID)
            .subscribe((c: any) => {
                return true;
                //this.matDialogRef.close();
            })
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }

    public load(): void {
        if (this.id > 0) {
            this.contactsData.getEdit(this.id || 0)
                .subscribe((p:any) => {
                    this.data = p;
                    this.fillForm(this.data);
                });
        } else {
            this.fillForm(this.data);
            // this.contactsData.getAdd(this.contactId)
            // .subscribe((p:any) => {
            //         this.data = p;
            //         this.fillForm(this.data);
            //     });
        }
    }

    private fillForm(p: Contact): void {
        this.formData.patchValue(p);
    }

    private initForm() {
      this.formData = this.formBuilder.group({
        contactID: [this.id || 0, Validators.required],
        firstName: [],
        lastName: ['', Validators.required],
        perfix: ['הרה"ח'],
        suffix: ['הי"ו'],         fatherID: [''],
        fatherName: [''],
        contactType: [1, Validators.required],
        affiliation: [, Validators.required],
        comment: [],


    });
    }

    ngOnInit() {
        // this.activatedRoute.params.forEach((params: Params) => {
        //     this.id = +params['contactID'] || 0;
        //     this.contactId = +params['contactID'];
        // });
        this.id = this.data.contactID || 0;
        this.initForm();
        this.load();
    }
}



