<div *ngIf="data; else loading">
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button> בקרת תשלומים והתחייבויות
    </h2>
  </div>
  <mat-dialog-content>
    <div>

      <div>
        <dl class="dl-horizontal">
          <dt>כרטיסים שנרכשו</dt>   
          <dd>{{ data.countPayTickets }}</dd>
          <hr>
          <dt>ערך הכרטיסים</dt>
          <dd>{{ data.price | number:'1.0-0' }}  {{data.currency | currency }} <!--:(data.currency == 1 ? 'USD': (data.currency == 2 ? 'GBP': 'ILS')):true:'1.0-0' }} -->
          <hr>
          <dt>תרומות נוספות</dt>
          <dd style="color: green;">
            <span (click)="goToOverPaidsList()">
              <a class="btn btn-outline btn-success dim" style="margin: 0 !important; padding: 2px !important;">
                <i class="fa fa-external-link fa-rotate-270"></i></a>
                {{ data.overPaid | number:'1.0-0'  }}  {{data.currency | currency }}  <!--:(data.currency == 1 ? 'USD': (data.currency == 2 ? 'GBP': 'ILS')):true:'1.0-0' }} -->
            </span> 
          </dd>
          
          <hr>
          <dt>סך הכל</dt>
           <dd style="font-weight: bold;"> {{ data.sumPriceAndOverPaid | number:'1.0-0' }}  {{data.currency | currency }} <!--:(data.currency == 1 ? 'USD': (data.currency == 2 ? 'GBP': 'ILS')):true:'1.0-0' }} -->
          </dd>
          <hr>
          <dt >נתקבל</dt>
          <dd  style="color: green;"> {{ data.paid | number:'1.0-0' }} {{data.currency | currency }} </dd>
          <hr>
          <dt>יתרה לגביה</dt>
          <dd style="color: red;">
            <span (click)="goToBalancesList()">
              <a class="btn btn-outline btn-success dim" style="margin: 0 !important; padding:  2px !important;">
                <i class="fa fa-external-link fa-rotate-270"></i></a>
                {{ data.balance | number:'1.0-0' }}  {{data.currency | currency }} <!--:(data.currency == 1 ? 'USD': (data.currency == 2 ? 'GBP': 'ILS')):true:'1.0-0' }} -->
              </span>  
          </dd>
          <hr>
          <dt>כרטיסים בהתחייבות</dt>
          <dd style="color: blue;">
            <span (click)="goToCommitmentTicketsList()">
              <a class="btn btn-outline btn-success dim" style="margin: 0 !important; padding:  2px !important;">
                <i class="fa fa-external-link fa-rotate-270"></i></a>
                {{ data.countPledgeTickets }}
              </span>  
          </dd>
          <hr>
          <dt *ngIf="data.needsClarification > 0">טעון בירור</dt>
          <dd *ngIf="data.needsClarification > 0" style="color: red;">
            {{ data.needsClarification | number:'1.0-0' }}  {{data.currency | currency }} <!--:(data.currency == 1 ? 'USD': (data.currency == 2 ? 'GBP': 'ILS')):true:'1.0-0' }} -->
          <hr *ngIf="data.needsClarification > 0">
        </dl>
      </div>
      <div #containerRef>
        <ngx-charts-pie-chart [view]="[containerRef.offsetWidth - 50, containerRef.offsetHeight]" [scheme]="colorScheme"
          [results]="pieChartSourse" [legendPosition]="'below'" [legend]="true" [gradient]="false"
          [tooltipDisabled]="false" [labels]="false">
        </ngx-charts-pie-chart>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="center">
    <button class="btn btn-warning navbar-btn" [mat-dialog-close]="">
      <i style="padding: 3px;" class="fa fa-arrow-right"></i>
      <span class="example-fill-remaining-space"></span>
      חזור
    </button>
  </mat-dialog-actions>


</div>
<ng-template #loading>
  <h4>
    <i style="color:royalblue" class="fa fa-refresh fa-spin"></i>
  </h4>
</ng-template>