﻿<div *ngIf="group">
    <div class="col-sm-6">
        <div>
            <panel-box title="פרטי קבוצה: {{ id }}" [showClose]="false" [showCollapse]="false">
                <span class="panel-tools">
                    <a [routerLink]="['/groups', {outlets: {popup: [ id, 'edit']}}]"><i class="fa fa-pencil"></i></a>
                </span>
                <div class="panel-content">
                        <dl class="dl-horizontal">
                            <dt>שם קבוצה</dt>
                            <dd> {{  group.groupName }} </dd>
                            <hr>                            
                            <dt>צבע קבוצה</dt>
                            <dd [ngStyle]="{'color': group.groupColor}"> {{ group.groupColor  }} </dd>
                            <!-- <hr>
                            <dt>לתצוגה ב</dt>
                            <dd> {{  group.groupDepartmentName }} </dd> -->
                            <hr>                            
                            <dt>לתצוגה בלוח הבקרה</dt>
                            <dd>
                                <i *ngIf="group.showOnDashboard" class="fa fa-check-square"></i>
                                <i *ngIf="!group.showOnDashboard" class="fa fa-window-close-o"></i>
                            </dd> 
                            <hr> 
                            <dt>מורשים</dt>
                            <dd> <span style="font-weight: bold" *ngFor="let gu of group.groupUsers"> {{ gu.gxUserName }}, </span></dd>
                            <hr> 
                            <dt>משויך לנושאים</dt>
                            <dd> <span style="font-weight: bold" *ngFor="let subject of group.groupSubjectsVM"> {{ subject }}, </span></dd>
                            <hr> 
                            
                           
                            
                        </dl>
                </div>
            </panel-box>
        </div>
    </div>
</div>