import { Params, ActivatedRoute } from '@angular/router';
import { CommitmentEdit } from './commitment-edit.cmp';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';


@Component({
    selector: 'commitment-edit-win',
    templateUrl: './commitment-edit-win.tmpl.html',
    styleUrls: ['./../commitments.scss'],
})

export class CommitmentEditWin implements OnInit  {

    constructor(
      private activatedRoute: ActivatedRoute,
    ) {

    } 
    @ViewChild('commitmentEdit', { static: true }) commitmentEdit: CommitmentEdit;
    public id: number;
    public contactId: number;

    ngOnInit() {
      this.activatedRoute.params.forEach((params: Params) => {
          this.id = +params['commitmentID'] || 0;
          this.contactId = +params['contactID']; 
      });
  }

}






