import { TishAreaMain } from './tishArea/tishArea-main.cmp';
import { TishAreaDetails } from './tishArea/tishArea-details.cmp';
import { TishAreaEdit } from './tishArea/tishArea-edit.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { MaterialModule } from './../material.module';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { TishAreasData } from './tishAreas.data';
import { TishAreasList } from './tishAreas-list.cmp';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { TishAreasRoutes } from './tishAreas.routing';
import { ModelInfoModule } from '../components/model-info/model-info.mdl';
import { InputInfoModule } from '../components/input-info/input-info.module';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';
import { TishAreaDlg } from './tishArea/tishArea-edit-dlg.cmp';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(TishAreasRoutes),
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PaginationModule,
		PanelBoxModule,
		AutoCompleteModule,
		FooterEditButtonsMdl,
		MaterialModule,
		MainFormModalModule,
    ModelInfoModule,
    InputInfoModule,
    CustomToolTipModule,
    ColorPickerModule
	],
	declarations: [
		TishAreasList,
    TishAreaDlg,
		TishAreaEdit,
		TishAreaDetails,
		TishAreaMain
	],
	exports: [
		TishAreasList,
		TishAreaEdit,
    TishAreaDlg,
		TishAreaDetails,
		TishAreaMain
	],
	providers: [
		TishAreasData
	]
})
export class TishAreasMdl { }

