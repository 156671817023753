import { MaterialModule } from './../material.module';

import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl'; 
import { CampaignAttachedsList } from './campaign-attacheds-list.cmp';
import { CampaignAttachedDetails } from './campaign-attached/campaign-attached-details.cmp';
import { CampaignAttachedsData } from './campaign-attacheds.data';
import { CampaignAttachedMain } from './campaign-attached/campaign-attached-main.cmp';
import { CampaignAttachedEdit } from './campaign-attached/campaign-attached-edit.cmp';
import { MainFormModalModule } from '../components/main-form-modal/main-form-modal.mdl';
import { RouterModule } from '@angular/router';
import { CampaignAttachedsRoutes } from './campaign-attacheds.routing';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(CampaignAttachedsRoutes),
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl, 
        ModelInfoModule, 
        MaterialModule,
		MainFormModalModule,
	   ],
    declarations: [         
        CampaignAttachedMain,
        CampaignAttachedEdit,
        CampaignAttachedsList,
        CampaignAttachedDetails, 
    ],
    exports: [       
        CampaignAttachedMain,
        CampaignAttachedEdit,
        CampaignAttachedsList,
        CampaignAttachedDetails, 
    ],
    providers: [
        CampaignAttachedsData
    ],
    entryComponents: [ 
    ]
}) 
export class CampaignAttachedsMdl { }



 