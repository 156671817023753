<mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
        <h1 mat-dialog-title>עדכון תגמול</h1>
    </mat-toolbar-row>
</mat-toolbar>
<div mat-dialog-content *ngIf="formData" [formGroup]="formData">
    <div fxLayout="column" class="form-vertical">
        <h3> {{ data.contactName }}</h3>
        <mat-form-field placeholder="תיאור" class="example-full-width">
            <input matInput apply-enter  placeholder="תיאור" formControlName="description">
        </mat-form-field>
        <date-input [placeholder]="'תאריך שליחה'" [dateControl]="formData.controls.compensationDate">Loading...</date-input>
        <mat-form-field placeholder="תיאור הזדמנות" class="example-full-width">
            <input autocomplete="off" matInput apply-enter  placeholder="תיאור הזדמנות" formControlName="subject">
        </mat-form-field>


    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button (click)="delete()">מחק</button>
        <button mat-button (click)="onNoClick()">ביטול</button>
        <button mat-button [mat-dialog-close]="formData">שמור</button>
    </div>
</div>