<div [formGroup]="formData" *ngIf="closedDetails">
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-sm-2">תאריך סגירה:</label>
            <div class="col-sm-10 form-inline">
                <date-input [dateControl]="formData.controls.closedDate">Loading...</date-input>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-2">סטטוס:</label>
            <div class="col-sm-10 form-inline">
                <select class="form-control" formControlName="closedStatus">
                    <option value="" >...</option>
                                    <option *ngFor="let cs of servicesData.ClosedStatuses"
                                            [value]="cs.value">
                                        {{cs.name}}
                                    </option>
                                </select>
                <small *ngIf="!formData.controls.closedStatus.valid" class="text-danger">*</small>
            </div>
        </div>
        <div class="form-group">
            <div class="col-sm-12 form-inline">
                <textarea rows="4" class="comment" placeholder="הערת סגירת התחייבות..." formControlName="closedComment"></textarea>
            </div>
        </div>
    </div>
</div>