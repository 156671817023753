import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()

export class ReportsCenterData {

  public url = 'api/reportCenter';

  constructor(
    private toraxHttp: ToraxHttp
  ) { }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }


  exportExcel(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExlsPlus`, baseQuery);
  }

  getListReport(baseQuery: any): any {
    return this.toraxHttp.postWithResponce(`${this.url}/getListReport`, baseQuery);
  }

  getPaymentsForTreatmentsReport(baseQuery: any): any {
    return this.toraxHttp.postWithResponce(`${this.url}/getPaymentsForTreatmentsReport`, baseQuery);
  }



  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}
