﻿<form *ngIf="ivrDonationUserTask" [formGroup]="ivrDonationUserTaskForm" novalidate>

  <div class="modal-header">
    <div fxFlex="100">
      <h3 fxLayout="row" style="color: blue;">משימה [{{ (id > 0 ? id : 'חדשה') }}] </h3>
      <div fxLayout="row">
        <div fxLayout="row">
          <h2 *ngIf="!ivrDonationUserTask.contactTitle" fxFlex style="margin-top: 0;">
            <small>מתקשר זוהה ע"י המערכת<br>כ - </small>{{ ivrDonationUserTask.identifiedContactTitle ?
            ivrDonationUserTask.identifiedContactTitle
            : 'לא ידוע' }}
          </h2>
          <h2 *ngIf="ivrDonationUserTask.contactTitle" fxFlex style="margin-top: 0;">
            {{ ivrDonationUserTask.contactTitle }}
          </h2>
          <audio style="position: absolute; left: 20px;" #audioFile id="audioFile" controls></audio>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" style="resize: both; overflow: auto; min-width: 850px; width: fit-content;" fxFlexFill>
    <div fxLayout="row" fxFlexFill  fxLayoutGap="15px"  fxLayout="row" fxLayout.lt-md="column" *ngIf="ivrDonationUserTask">

      <div fxFlex style="overflow:inherit">
        <mat-card class="example-card" style="white-space: nowrap;">
          <mat-card-header fxLayout="row">
            <mat-card-title fxLayout="row">
              <h3>פרטי שיחה: [{{ ivrDonationUserTask.ivrDonationUserTaskID }}]</h3>
              <span class="example-fill-remaining-space"></span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content style="width: 100%;">
            <dl class="dl-horizontal">
              <dt>מספר מזוהה</dt>
              <dd>{{ ivrDonationUserTask.ivrDonationDetail.phoneNumber | phone}}</dd>
              <hr>
              <dt>שם מתקשר</dt>
              <dd *ngIf="!contactEdit" style="margin-right: 157px !important;">
                <i style="color: #c8c8c8" (click)="contactEdit = !contactEdit" class="fa fa-pencil"></i>
                &nbsp;
                <span>{{ (ivrDonationUserTask.contactTitle ?
                  ivrDonationUserTask.contactTitle :
                  ivrDonationUserTask.identifiedContactTitle) }}</span>
              </dd>
              <dd *ngIf="contactEdit" style="margin-right: 110px !important; display:flex;">
                <i style="color: #c8c8c8" (click)="contactEdit = !contactEdit" class="fa fa-check"></i>
                &nbsp;
                <auto-complete [withLabel]="false" *ngIf="contactEdit" apply-enter [controllerName]="'Contacts'"
                  [formGroupModel]="ivrDonationUserTaskForm" [placeholder]="'שם מתקשר:'"
                  [idControl]="ivrDonationUserTaskForm.controls.contactID"
                  [nameControl]="ivrDonationUserTaskForm.controls.contactTitle">
                </auto-complete>
              </dd>
              <hr>
              <dt>תאריך שיחה</dt>
              <dd>
                {{ ivrDonationUserTask.createDate | date: 'dd/MM/yyyy' }} <br>
                {{ ivrDonationUserTask.jewishCreateDate }}
              </dd>
              <hr>
              <dt>שעת שיחה</dt>
              <dd> {{ ivrDonationUserTask.createDate | date:'shortTime' }}
              </dd>
              <hr>
              <dt>אשראי</dt>
              <dd>{{ ivrDonationUserTask.ivrDonationDetail.creditNumber }}</dd>
              <hr>
              <dt>תוקף</dt>
              <dd>{{ ivrDonationUserTask.ivrDonationDetail.creditExp }}</dd>
              <hr>
              <dt>סכום</dt>
              <dd>{{ ivrDonationUserTask.ivrDonationDetail.amount | number}}{{
                ivrDonationUserTask.ivrDonationDetail.currency
                | currency }}
              </dd>
              <hr>
              <dt>תשלומים</dt>
              <dd>{{ ivrDonationUserTask.ivrDonationDetail.qty }}</dd>
              <hr>
              <dt>טופל ע"י</dt>
              <dd>{{ ivrDonationUserTask.doneByUserName }}
              </dd>
              <hr>
              <dt>תאריך טיפול</dt>
              <dd>{{ ivrDonationUserTask.doneDate | date: 'dd/MM/yyyy' }}<br>
                {{ ivrDonationUserTask.jewishDoneDate }}
              </dd>
              <hr>
            </dl>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex="1 1 50">
        <mat-card class="example-card">
          <mat-card-header fxLayout="row">
            <mat-card-title fxLayout="row">
              <h3>תיעוד טיפול במשימה</h3>
              <span class="example-fill-remaining-space"></span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="form-group">
              <div class="form-row" fxLayout="row">
                <auto-complete apply-enter [controllerName]="'Contacts'" [formGroupModel]="ivrDonationUserTaskForm"
                  [placeholder]="'שם מתקשר'" [idControl]="ivrDonationUserTaskForm.controls.contactID"
                  [nameControl]="ivrDonationUserTaskForm.controls.contactTitle">
                </auto-complete>
              </div>
              <div class="form-row" fxLayout="row">
                <date-input [placeholder]="'תאריך טיפול:'" [dateControl]="ivrDonationUserTaskForm.controls.doneDate">
                  Loading...
                </date-input>
              </div>
              <div class="form-row">
                <label>הערה:</label>
                <textarea rows="4" class="comment" placeholder="הערה..." formControlName="comment"></textarea>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

    </div>
  </div>
  <footer-edit-buttons [onSaveGoToBack]="true" [dataProvider]="ivrDonationUserTasksData" [router]="router" [id]="id"
    [disabledDeleteButton]="false" [modelFormGroup]="ivrDonationUserTaskForm">
  </footer-edit-buttons>
</form>
