import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'; 
import { NgModule } from '@angular/core'; 
import { ContactsSearchInput } from './contacts-search-input.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule
    ],
    declarations: [
        ContactsSearchInput,
    ],
    exports: [
        ContactsSearchInput,
    ]
})
export class ContactsSearchInputModule {

}
