
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SubscriptionLike } from "rxjs";
import { YarzeitsData } from "../yarzeits.data";
import { ServicesData } from "src/app/services/services.data";
import { AlertService } from "src/app/services/alert.service";
import { ToraxHttp } from "src/app/utils/custom-http.svc";
import { IndexService } from "src/app/services/index-service";
import { ViewEncapsulation } from "@angular/compiler";

@Component({
  selector: "yarzeit-affiliations-min-list",
  templateUrl: "yarzeit-affiliations-min-list.tmpl.html",
})


export class YarzeitAffiliationsMinList implements OnInit, OnDestroy {
  constructor(
    private yarzeitsData: YarzeitsData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData ,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
    private alert: AlertService
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/yarzeits') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  displayedColumns: string[] = ["buttons", "yarzeitID", "relevantLastName", "relevantCityName", "nextYarzeitDate", "yarzeitType", "yarzeitsDetails"];

  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  searchYarzeitsListForm: FormGroup;

  actualMinusHeight = '260';
  @Input("contactID") public contactID?: number | null = null;
  @Input("yarzeitID") public yarzeitID?: number | null = null;

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  isChecked(row: any): boolean {
    const found = this.selection.selected.find(
      (el: any) => el.yarzeitID === row.yarzeitID
    );
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  addNew() {
    this.router.navigate([{ outlets: { popup: ["add"] } }]);
  }

  openSelectedRow(yarzeitID) {
    this.router.navigate(["", { outlets: { popup: ["yarzeits", yarzeitID] } }]);
  }

  public createAndPrintYarzeitsLetters(): void {
    let ids = this.searchYarzeitsListForm.controls["selectedRows"].value
      .map((x) => x)
      .join();
    if (!ids) {
      alert("לא נבחרו רשומות להדפסה");
    } else {
      this.yarzeitsData
        .createAndPrintYarzeitsLetters(ids)
        .subscribe((data) => {
          let popupWinindow: any = window.open(
            "",
            "_blank",
            "scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
          );
          popupWinindow.document.open();
          popupWinindow.document.write(
            '<html><head><style type="text/css">.MsoNormal { margin-top: 0px; margin-bottom: 0px;} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { page-break-after: always; } }  </style></head><body  onload="window.print()"><div>' +
            (<any>data).value +
            "</div></body></html>"
          );
          popupWinindow.document.close();
        });
    }
  }


  private initForm() {
    this.searchYarzeitsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      yarzeitTypes:[],
      yarzeitsDateRange:[null],
      selectedRows: [],
      orders: this.formBuilder.array([]),
    });

    this.selection.changed.subscribe((x) => {
      this.searchYarzeitsListForm.controls["selectedRows"].setValue(
        x.source.selected.map((x: any) => x.yarzeitID)
      );
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {
    this.initForm();

    this.activatedRoute.params.forEach((params: Params) => {
      //this.contactID = +params["contactID"] || null;
    });
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchYarzeitsListForm.patchValue(frm);
    }

    this.searchYarzeitsListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
     // { key: "contactID", value: this.contactID },
    ];
    this.indexService = new IndexService(this.searchYarzeitsListForm, params, this.yarzeitsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn,  'getYarzeitAffiliationsList');
    this.indexService.getList();


  }
}
