export class AutoComplete {   

    public get displayValue(): string {    
        return `[${this.id}] ${this.title}`;
    }

    constructor(
        public id: number,
        public title: string) {
        // this.displayValue = `[${this.contactID}] ${this.lastName} ${this.firstName}`;
    }
}
 