import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatList } from '@angular/material/list';
import { ContactsData } from 'src/app/contacts/contacts.data'; 
import { ServicesData } from 'src/app/services/services.data';




@Component({
    templateUrl: './print-recipts-dlg.tmpl.html'
})

export class PrintReciptsDlg implements OnInit {
    public formData: FormGroup;
    payments: any[];
    mergePayments: boolean = false; 
    //public data: any;
    @ViewChild('paymentsList') paymentsList: MatList;


    constructor(
        public matDialogRef: MatDialogRef<PrintReciptsDlg>,
        @Inject(MAT_DIALOG_DATA) public data: number, //accountLocation        
        private formBuilder: FormBuilder,
        public servicesData: ServicesData) { }

 

    ngOnInit(): void {
        this.initForm();       
    }
    
    onNoClick(): void {
        this.matDialogRef.close();
    }

    
   


    private initForm(): void {
        this.formData = this.formBuilder.group({
            accountLocation: [this.data, Validators.required],
            commitmentSubject: [null],
            registrationEventType: [null],
            withPaymentsPlan: [null]
        });
    } 

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}

