<div class="row">
    <div class="col-lg-12" *ngIf="commitment"  style="padding: 0 !important;">
        <div class="ibox"
            style="box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 11%), 0px 1px 0px 0px rgb(0 0 0 / 12%);">
            <div class="ibox-title">
                <h2>
                    {{commitment.commitmentSubjectName}}
                    <small>
                        {{commitment.commitmentReasonName}} {{commitment.honorTitle}}
                        <br>
                        <span *ngIf="commitment.globalAmount">
                            סכום {{commitment.commitmentDonationTypeName}}
                            <br>
                            <b>{{ commitment.globalAmount| number:'1.0-0'}}{{commitment.currency | currency }}</b>
                        </span>
                        <span *ngIf="commitment.pulseAmount">
                            סכום חודשי {{commitment.pulseAmount | number:'1.0-0'}}{{commitment.currency | currency }}
                            <br>
                            <b>(שנתי :{{(commitment.pulseAmount * 12 ) | number:'1.0-0' }})</b>
                        </span>
                    </small>
                </h2>
                <p>
                    {{ commitment.commitmentOrAppointmentStrDate }}
                    <!-- {{ (commitment.hebYear ? commitment.hebYear : commitment.jewishCommitmentOrAppointmentDate )}} -->
                    <span *ngIf="commitment.scopeCommitmentInYears">
                        <br>
                        למשך {{ commitment.scopeCommitmentInYears }}

                    </span>
                </p>
                <div style="position: absolute; top: 15px; left: 15px;" class="pull-right ibox-tools">
                    <button (click)="downloadOneCommitmentReport(commitment.contactID,commitment.commitmentID)"
                        class="btn btn-circle" matTooltip="הורד" title="הורד"><i class="fa fa-file-pdf-o"></i></button>
                    <button (click)="isOpen = !isOpen" class="btn btn-circle">
                        <i *ngIf="isOpen" matTooltip="כווץ" title="כווץ" class="fa fa-chevron-up"></i>
                        <i *ngIf="!isOpen" matTooltip="הרחב" title="הרחב" class="fa fa-chevron-down"></i>
                    </button>
                </div>
                <section *ngIf="commitment.globalAmount">
                    <mat-progress-bar mode="determinate"  [value]="(commitment.sumSucceededPartialValueInCommitment / (commitment.globalAmount / 100))">
                    </mat-progress-bar>
                    <div> {{(commitment.sumSucceededPartialValueInCommitment / (commitment.globalAmount / 100))  | number }}% </div>
                  </section>
                <div class="text-right"
                                style="position: absolute; bottom: 10px; left: 15px;">
                                <span *ngIf="commitment.isInTarget" class="btn btn-xs btn-primary">
                                עומד ביעד</span>
                                <span *ngIf="!commitment.isInTarget" class="btn btn-xs btn-danger">
                                    אינו עומד ביעד</span>
              </div>
            </div>
            <div [hidden]="!isOpen" class="ibox-content">
                <div style="padding-top: 10px;">

                    <dl class="dl-horizontal">

                        <dt>תאריך סיום התחייבות (מתוכנן)</dt>
                        <dd>{{ commitment.commitmentTillStrDate }}</dd>
                        <hr>
                        <dt>שולם עד כעת</dt>
                        <dd>
                        <span *ngIf="commitment.sumSucceededPartialValueInCommitment">
                            משוקלל: {{ commitment.sumSucceededPartialValueInCommitment | number }}{{ commitment.commitmentCurrencyIcon}}
                        </span>
                        </dd>
                        <hr>
                        <dt *ngIf="commitment.commitmentBalance > 0">יתרת התחייבות</dt>
                        <dd><span style="color: red;" *ngIf="commitment.commitmentBalance > 0"> {{
                                commitment.commitmentBalance
                                |
                                number }}{{commitment.commitmentCurrencyIcon}}</span></dd>
                        <hr *ngIf="commitment.commitmentBalance > 0">


                        <dt *ngIf="commitment.balancePlannedForNow">יעד לחודש נוכחי</dt>
                        <dd *ngIf="commitment.balancePlannedForNow">
                            <span style="color: green; font-weight: bold"
                                *ngIf="commitment.isInTarget">עומד
                                ביעד</span>
                            <span style="color: red; font-weight: bold"
                                *ngIf="!commitment.isInTarget">אינו עומד ביעד<br>
                                יתרה ליעד: {{ commitment.balancePlannedForNow | number:'1.0-0' }}{{commitment.currency | currency }}</span>
                        </dd>
                        <hr *ngIf="commitment.balancePlannedForNow">

                        <dt *ngIf="commitment.commitmentClosedStrDate">תאריך סגירה/סיום</dt>
                        <dd *ngIf="commitment.commitmentClosedStrDate">{{ commitment.commitmentClosedStrDate }}<br>
                            <label>סטטוס:</label> {{ commitment.commitmentClosedStatusName }}<br>
                            <label *ngIf="commitment.commitmentClosedComment">הערה:</label> {{ commitment.commitmentClosedComment }}
                        </dd>
                        <hr *ngIf="commitment.commitmentClosedStrDate && commitment.commitmentClosedComment">

                        <dt>אופן תשלום</dt>
                        <dd>{{ commitment.commitmentDonationTypeName }} <span
                                *ngIf="commitment.pulseAmount > 0">סכום-חודשי:
                                {{ commitment.pulseAmount | number }}{{ commitment.commitmentCurrencyIcon }}</span></dd>
                        <hr>

                        <dt>מספר תשלומים (עד כעת)</dt>
                        <dd>{{ commitment.countPayments }}</dd>
                        <hr>

                        <dt>תאריך תשלום ראשון</dt>
                        <dd>{{ commitment.firstPaymentStrDate }}</dd>
                        <hr>

                        <dt>תאריך תשלום אחרון (עד כעת)</dt>
                        <dd>{{ commitment.lastPaymentStrDate }} סטטוס: {{ commitment.lastPaymentStatusName }}</dd>
                        <hr>

                        <dt>תאריך תשלום הקרוב</dt>
                        <dd>{{ commitment.nextPaymentStrhDate }}</dd>
                        <hr>

                        <dt>הערה</dt>
                        <dd>{{ commitment.commitmentComment }}</dd>
                        <hr>

                    </dl>

                    <button (click)="viwPayments()">{{(!showPayments ? 'הצג תשלומים' : 'הסתר תשלומים')}}</button>
                    <div *ngIf="showPayments" style="padding-top:3px" class="table-responsive">
                        <h3>פירוט תשלומים</h3>
                        <hr>
                        <table loading-status [stopLoadingWhen]="commitment.paymentCommitments.length >= 0"
                            class="table table-striped">
                            <thead>
                                <tr>
                                    <th>...</th>
                                    <th>סכום</th>
                                    <th>ערך</th>
                                    <th>תאריך</th>
                                    <th>סטטוס</th>
                                    <th>באמצעות</th>
                                    <th>חשבון</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let p of commitment.paymentCommitments">
                                    <td>
                                        <span style="font-weight:bold; color: blue"
                                            *ngIf="p.contactID != commitment.contactID"><i class="fa fa fa-link"></i>
                                            &nbsp;&nbsp; ע"י {{ p.contactName }}</span>
                                    </td>
                                    <td><span *ngIf="p.partialAmount != p.amount"> {{
                                            p.partialAmount | number }} מתוך </span> {{
                                        p.amount | number }} {{ p.currency | currency }}</td>
                                    <td style="font-weight: bold;"> {{ p.partialValueInCommitment | number }}
                                        {{ commitment.currency |
                                        currency }}</td>
                                    <td>{{ p.doneOn | date:'dd/MM/yyyy' }} {{ p.jewishDoneOn}}</td>
                                    <td>{{ p.statusPaymentName }}</td>
                                    <td>{{ p.payMethodTitle }}</td>
                                    <td>{{ p.accountLocationName }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
