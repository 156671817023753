import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { CustomToolTipModule } from './../custom-tool-tip/custom-tool-tip.mdl';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelInfo } from './model-info.cmp';
import { ModelInfoDlg } from './model-info-dlg.cmp';


@NgModule({
    imports: [
        CommonModule,
        CustomToolTipModule,
        MatCardModule,
        MatIconModule
    ],
    declarations: [
        ModelInfo,
        ModelInfoDlg
    ],
    exports: [
        ModelInfo,
    ]
})
export class ModelInfoModule {

}
