<form class="example-form" [formGroup]="formData" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="יציאה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      <small>דו"ח תשלומים שנכשלו</small>
    </h2>
  </div>
  <mat-dialog-content *ngIf="formData" style="padding:0px 24px 24px 24px">
    <div fxLayout="column" style="display: block;">

      <div class="form-row" fxLayout="row">

        <div class="input-group mb-3">
          <label>ימים אחורה...</label>
          <input class="form-control" formControlName="daysBack">
        </div>
      </div>
      <mat-button-toggle-group style="height: fit-content; margin-top: 23px;" formControlName="accountLocation">
        <mat-button-toggle [value]="1">
          <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="formData.controls.accountLocation.value == 1">
            radio_button_checked</mat-icon>
          <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="formData.controls.accountLocation.value != 1">
            radio_button_unchecked</mat-icon>
          ישראל
        </mat-button-toggle>
        <mat-button-toggle [value]="2">
          <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="formData.controls.accountLocation.value == 2">
            radio_button_checked</mat-icon>
          <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="formData.controls.accountLocation.value != 2">
            radio_button_unchecked</mat-icon>
          ארה"ב
        </mat-button-toggle>
      </mat-button-toggle-group>


    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <!-- <button apply-enter [disabled]="formData.invalid" class="btn btn-default navbar-btn" (click)="exportExcel()"><i
        class="fa fa-save"></i> אקסל</button> -->
    <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn" (click)="exportPdf()"><i
        class="fa fa-save"></i> PDF </button>
  </mat-dialog-actions>

</form>
