<ng-container [formGroup]="paymentCommitmentFormGroup">
    <td style="padding: 1px;">
        <commitment-add-action [editIcon]="true" [commitment]="commitment"
            [commitmentID]="paymentCommitmentFormGroup.controls.commitmentID.value"
            [contactID]="paymentCommitmentFormGroup.controls.contactID.value"
            (commitmentChange)="commitmentChangeEvent($event)">
        </commitment-add-action>
        &nbsp; &nbsp;
        {{ (paymentCommitmentFormGroup.controls.commitmentID.value  > 0 ? paymentCommitmentFormGroup.controls.commitmentID.value : (openNewPaymentCommitment ? '[חדש]' : 'בחר...')) }}
    </td>
    <td style="padding: 1px;" *ngIf="!openNewPaymentCommitment && showComponent" colspan="2">
        <commitments-select-pick [withLabel]="false" (commitmentSelect)="commitmentSelect($event)"
            [includeIsFundraisings]="false" (commitmentChange)="commitmentChangeEvent($event)"
            (commitmentAdd)="commitmentChangeEvent($event)"
            [contactID]="paymentCommitmentFormGroup.controls.contactID.value" [placeHolder]="'התחייבויות'"
            [commitmentIdControl]="paymentCommitmentFormGroup.controls.commitmentID">
        </commitments-select-pick>

    </td>

    <td style="padding: 1px;" *ngIf="openNewPaymentCommitment"
        [ngClass]="{'has-error': paymentCommitmentFormGroup.controls.commitmentSubject.invalid}">
        <mat-select apply-enter class="form-control" style="width: 100% !important;"
            (selectionChange)="selectSubject($event)" formControlName="commitmentSubject">
            <mat-option [value]="null">...</mat-option>
            <mat-option *ngFor="let cs of servicesData.CommitmentSubjects" [value]="cs.value">
                {{cs.name}}
            </mat-option>
        </mat-select>
    </td>
    <!-- <td style="padding: 1px;" *ngIf="openNewPaymentCommitment"
        [ngClass]="{'has-error': paymentCommitmentFormGroup.controls.hebYear.invalid}">
        <mat-select style="width: 84px; min-width: 84px;" class="form-control" formControlName="hebYear">
            <mat-option value="''">...</mat-option>
            <mat-option value="תשעו">תשעו</mat-option>
            <mat-option value="תשעז">תשעז</mat-option>
            <mat-option value="תשעח">תשעח</mat-option>
            <mat-option value="תשעט">תשעט</mat-option>
            <mat-option value="תשפ">תשפ</mat-option>
            <mat-option value="תשפא">תשפא</mat-option>
            <mat-option value="תשפב">תשפב</mat-option>
            <mat-option value="תשפג">תשפג</mat-option>
            <mat-option value="תשפד">תשפד</mat-option>
            <mat-option value="תשפה">תשפה</mat-option>
            <mat-option value="תשפו">תשפו</mat-option>
            <mat-option value="תשפז">תשפז</mat-option>
            <mat-option value="תשפח">תשפח</mat-option>
            <mat-option value="תשפט">תשפט</mat-option>
            <mat-option value="תשצ">תשצ</mat-option>
            <mat-option value="תשצא">תשצא</mat-option>
            <mat-option value="תשצב">תשצב</mat-option>
            <mat-option value="תשצג">תשצג</mat-option>
        </mat-select>

    </td> -->

    <td style="padding: 1px;" *ngIf="openNewPaymentCommitment"
        [ngClass]="{'has-error': paymentCommitmentFormGroup.controls.commitmentReasonID.invalid}">
        <mat-select apply-enter class="form-control" style="width: 100% !important;"
            formControlName="commitmentReasonID">
            <mat-option [value]="">...</mat-option>
            <mat-option *ngFor="let cr of commitmentReasons" [value]="cr.commitmentReasonID">
                {{cr.name}}
            </mat-option>
        </mat-select>
    </td>
    <td style="padding: 1px;" [ngClass]="{'has-error': paymentCommitmentFormGroup.controls.partialAmount.invalid}">
        <input apply-enter type="text" class="form-control narrow" style="width: 100% !important;" placeholder="סכום..."
            formControlName="partialAmount" />
    </td>
    <td style="padding: 1px;" [ngClass]="{'has-error': paymentCommitmentFormGroup.controls.designation.invalid}">
        <input apply-enter type="text" class="form-control" formControlName="designation"
            style="width: 100% !important;" placeholder="ייעוד מיוחד..." />
    </td>
    <td style="padding: 1px;" [ngClass]="{'has-error': paymentCommitmentFormGroup.controls.designationDetails.invalid}">
        <input apply-enter [disabled]="!paymentCommitmentFormGroup.controls.designation.value" type="text"
            class="form-control" formControlName="designationDetails" style="width: 100% !important;"
            placeholder="תיאור ייעוד..." />
    </td> 
    <td style="padding: 1px; display: flex;"  [ngClass]="{'has-error': paymentCommitmentFormGroup.controls.partialValueInCommitment.invalid || paymentCommitmentFormGroup.controls.lockPartialValueInCommitment.invalid}">
        <input apply-enter  type="text"
        class="form-control narrow" style="width: 100% !important;" formControlName="partialValueInCommitment"
            placeholder="ערך בהתחייבות..." />
            <input  apply-enter  type="checkbox"
            formControlName="lockPartialValueInCommitment" 
            placeholder="נעילת ערך בהתחייבות..." />
    </td> 
           
    <td style="padding: 1px; margin: 0 auto;">
        <mat-icon class="delete" (click)="removepaymentCommitment(ix)">delete_forever</mat-icon> 
    </td>
</ng-container> 