
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SubscriptionLike } from "rxjs";
import { EmergencySquadQueuesData } from "./emergency-squad-queues.data";
import { ServicesData } from "src/app/services/services.data";
import { ToraxHttp } from "src/app/utils/custom-http.svc";
import { AlertService } from "src/app/components/alert/alert.svc";
import { IndexService } from "src/app/services/index-service";

@Component({
  selector: "emergency-squad-queues-list",
  templateUrl: "emergency-squad-queues-list.tmpl.html",
})
export class EmergencySquadQueuesList implements OnInit, OnDestroy {
  constructor(
    private emergencySquadQueuesData: EmergencySquadQueuesData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
    private alert: AlertService
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/emergencySquadQueues') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  displayedColumns: string[] = ["select", "buttons", "emergencySquadQueueID"];

  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  searchEmergencySquadQueuesListForm: FormGroup;

  actualMinusHeight = '185';
  @Input("emergencySquadMemberID") public emergencySquadMemberID?: number | null = null;

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  isChecked(row: any): boolean {
    const found = this.selection.selected.find(
      (el: any) => el.emergencySquadQueueID === row.emergencySquadQueueID
    );
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  addNew(emergencySquadMemberID) {

    this.router.navigate(['', { outlets: { popup: ['emergencySquadQueues',  "add", emergencySquadMemberID] } }]);
    this.router.navigate([{ outlets: { popup: ["add"] } }]);
  }

  openSelectedRow(emergencySquadQueueID) {
    this.router.navigate(["", { outlets: { popup: ["emergencySquadQueues", emergencySquadQueueID] } }]);
  }



  private initForm() {
    this.searchEmergencySquadQueuesListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      emergencySquadMemberID: [],
      emergencySquadMemberName: [],
      selectedRows: [],
      orders: this.formBuilder.array([]),
    });

    this.selection.changed.subscribe((x) => {
      this.searchEmergencySquadQueuesListForm.controls["selectedRows"].setValue(
        x.source.selected.map((x: any) => x.emergencySquadQueueID)
      );
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {
    this.initForm();

    this.activatedRoute.params.forEach((params: Params) => {
      this.emergencySquadMemberID= +params["emergencySquadMemberID"] || null;
    });
    if(this.emergencySquadMemberID > 0){
      this.actualMinusHeight = '480';
    }

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchEmergencySquadQueuesListForm.patchValue(frm);
    }

    this.searchEmergencySquadQueuesListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
      { key: "emergencySquadMemberID", value: this.emergencySquadMemberID},
      { key: "emergencySquadMemberID", value: this.emergencySquadMemberID },
    ];
    this.indexService = new IndexService(this.searchEmergencySquadQueuesListForm, params, this.emergencySquadQueuesData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();

    if (this.emergencySquadMemberID) {
      this.actualMinusHeight = '362';
    }
  }
}
