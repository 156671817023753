import { ToraxHttp } from './../../utils/custom-http.svc';
import { ContactCompensation } from './contactCompensation/contactCompensation';

import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";

@Injectable()

export class ContactCompensationsData {

    private url = 'api/contactCompensations';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }

    getContactCompensations(contactID: number) {
        return this.toraxHttp.get(`${this.url}/getContactCompensations/${contactID}`);
    }

    get(contactCompensationID: number) {
        return this.toraxHttp.get(`${this.url}/${contactCompensationID || 0}`);
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }


    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(contactId: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
    }

    update(contactCompensation: ContactCompensation) {
        return this.toraxHttp.update(this.url, contactCompensation);
    }

    setContactCompensationPriority(contactID: number, prevContactCompensationID: number, curContactCompensationID: number, newCurPriority: number) {
        let ret = this.toraxHttp.postWithResponce(`${this.url}/setContactCompensationPriority?contactID=${contactID}&prevContactCompensationID=${prevContactCompensationID}&curContactCompensationID=${curContactCompensationID}&newCurPriority=${newCurPriority}`, null);
        // this.onUpdated(contactID);
        return ret;
    }

    // removeDefaultContactCompensationID(contactID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/removeDefaultContactCompensationID?ContactID=${contactID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;
    // }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();

    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

