<form class="example-form" [formGroup]="phoneForm" novalidate>
    <div *ngIf="phone" matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>{{ (id > 0 ? 'עריכת פרטי' : 'הוספת') }} אמצעי קשר</small>
            <br>
            {{ phone.contactName }}
        </h2>

    </div>
    <mat-dialog-content style="padding:0px 24px 24px 24px">
        <div *ngIf="phone" fxFlex="100" fxLayout="column">
            <div fxLayout="row" class="example-margin">
                <span *ngIf="!phone.isActive">
                    נמחק בתאריך {{ phone.inactiveJewishDate }} ({{phone.inactiveDateStr}})
                    <a (click)="clearInactiveDate()">לחץ כדי לשחזר</a> לאחר מכן שמור שינויים
                </span>
                <mat-icon style="float: left; color: rgb(255, 94, 0)" *ngIf="!phone.isActive">cancel</mat-icon>
            </div>
            <div [ngSwitch]="phone.communicationType" fxLayout="row">
                <div *ngSwitchCase="'phoneType'" class="form-row" fxLayout="row">
                    <div class="input-group mb-3">
                        <label>מספר:</label>
                        <input class="form-control" apply-enter cdkFocusInitial formControlName="number"
                            placeholder="מספר..." />
                    </div>&nbsp;
                    <div class="input-group mb-3">
                        <label>סוג:</label>
                        <select class="form-control" apply-enter placeholder="סוג..." formControlName="type">
                            <option [value]="1"> <i class="fa fa-phone"></i>&nbsp;&nbsp;טלפון</option>
                            <option [value]="2"><i class="fa fa-mobile"></i>&nbsp;&nbsp;נייד</option>
                            <option [value]="3"><i class="fa fa-fax"></i>&nbsp;&nbsp;פקס</option>
                            <option [value]="4"><i class="fa fa-briefcase"></i>&nbsp;&nbsp;עבודה</option>
                            <option [value]="5"><i class="fa fa-car"></i>&nbsp;&nbsp;רכב</option>
                            <option [value]="6"><i class="fa fa-home"></i>&nbsp;&nbsp;גב'</option>
                        </select>
                    </div>



                </div>
                <div *ngSwitchCase="'emailType'" class="form-row" fxLayout="row">
                    <div class="input-group mb-3">
                        <label>כתובת דוא"ל:</label>
                        <input class="form-control" apply-enter cdkFocusInitial formControlName="number"
                            pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$">
                        <span *ngIf="!phoneForm.controls.number.valid" class="text-danger">כתובת לא תקינה!</span>
                    </div>&nbsp;
                    <div class="input-group mb-3">
                        <label>סוג:</label>
                        <select class="form-control" placeholder="סוג..." apply-enter formControlName="type">
                            <option [value]="11"><i class="fa fa-envelope-o"></i>&nbsp;&nbsp; דוא"ל</option>

                        </select>
                    </div>
                </div>
            </div>

            <mat-checkbox apply-enter fxLayout="row" class="example-margin" formControlName="private">
                חסוי
            </mat-checkbox>
            <mat-checkbox apply-enter fxLayout="row" class="example-margin" formControlName="notToAlfon">
                בחר כדי לא לפרסם באלפון
            </mat-checkbox>
            <mat-checkbox apply-enter fxLayout="row" class="example-margin" formControlName="noLtzintok">
                לא לצינתוקים
            </mat-checkbox>
            <div class="input-group mb-3" style="width: 100%;" fxLayout="row">
                <textarea class="form-control comment" style="min-width: 100%" apply-enter rows="2"
                    placeholder="הערה..." formControlName="comment"></textarea>
            </div>

        </div>


    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="end">
        <button [disabled]="phoneForm.invalid" class="btn btn-primary navbar-btn" [mat-dialog-close]="phoneForm"><i
                class="fa fa-save"></i> שמור </button>
        <button class="btn btn-warning navbar-btn" *ngIf="phone.phoneID > 0 && phone.isActive"
            [mat-dialog-close]="phoneForm" (click)="setInactiveDate()"><i class="fa fa-warning"></i> מחק
        </button>
        <!-- <button mat-button mat-dialog-close [mat-dialog-close]="phoneForm" class="btn btn-danger navbar-btn"><i
                class="fa fa-trash-o"></i> מחק לנצח
        </button> -->

    </mat-dialog-actions>
</form>
