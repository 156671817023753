import { UserEditDlg } from './user/user-edit-dlg.cmp';
import { SelectionModel } from '@angular/cdk/collections';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { UsersData } from './users.data';

import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';

import { ToraxHttp } from '../utils/custom-http.svc';
import { UserAddDlg } from './user/user-add-dlg.cmp';


@Component({
	selector: 'users-list',
	templateUrl: 'users-list.tmpl.html'
})

export class UsersList implements OnInit {
	constructor(
		private usersData: UsersData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private router: Router,
		public _matDialog: MatDialog,
		private toraxHttp: ToraxHttp
	) {
	}

	displayedColumns: string[] = ['buttons', 'userName', 'firstName', 'lastName', 'departmentName'];
	data: any[] = [];

	resultsLength = 0;
	isRateLimitReached = false;

	@ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	@Output() selectedRowsChange = new EventEmitter<any>();

	togleTT = false;
	showPmTrans() {
		this.togleTT = this.togleTT
	}


	searchUsersListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	//page;
	selection = new SelectionModel<number>(true, []);

	public clerFilter() {
		this.initForm();
		this.getUsers();
	}

	addNew() {
		this.openAdd({ userName: '' });
	}


	openAdd(row) {
		setTimeout(() => {
			const dialogRef = this._matDialog.open(UserAddDlg, {
				data: row.userName
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result && result.valid) {
					this.addUser(result);
				}
			});
		}, 200);
	}

	openSelectedRow(row) {
		this.openDetails(row);
	}

	openDetails(row) {
		setTimeout(() => {
			const dialogRef = this._matDialog.open(UserEditDlg, {
				data: row.userName
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result && result.valid) {
					this.saveUser(result);
				}
			});
		}, 200);
	}

	private saveUser(userEditForm: FormGroup) {
		this.usersData.updateUserDetails(userEditForm.value)
			.subscribe((pm: any) => {
				this.getUsers();
				return true;
			})
	}

	private addUser(userEditForm: FormGroup) {
		this.usersData.update(userEditForm.value)
			.subscribe((pm: any) => {
				this.getUsers();
				return true;
			})
	}



	private getUsers() {
		this.searchUsersListForm.controls['page'].setValue(1);

		sessionStorage.setItem(this.router.url, JSON.stringify(this.searchUsersListForm.value));

		return this.usersData!.getList(this.searchUsersListForm.value)
			.subscribe((data: any) => {
				this.data = data;
				this.sourceList = data;
				//this.page = data.list.length;
				this.pageRows = data.length;
				this.rowsCount = data.length;
				this.isRateLimitReached = false;

			});
	}

	private initForm() {
		this.searchUsersListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			contactID: [],
			orders: this.formBuilder.array([])
		});

		this.selection.changed.subscribe(x => {
			this.searchUsersListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.userID));
			this.selectedRowsChange.emit(x.source.selected);
		});


	}

	isLink(pmId) {
		return this.exludePayMethodsIDs.indexOf(pmId);
	}


	exludePayMethodsIDs = [88, 89, 420, 1310, 3580, 6232, 6990, 42340];
	panelOpenState;
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;
	ngOnInit() {
		this.initForm();
		let item = sessionStorage.getItem(this.router.url);
		if (item) {
			let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
			this.searchUsersListForm.patchValue(frm);
		}
		this.getUsers();
	}
	selectionChanged(e) {
		this.getUsers();
	}

	// pageChange(e) {
	// 	this.searchUsersListForm.controls['pageRows'].setValue(e.pageSize || 20);
	// 	this.selectionChanged(e);
	// }
	sortData(e) {
		this.addOrder(e.active, e.direction != "asc");
		this.getUsers();
	}
	public search() {
	}
	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchUsersListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
	}
	pageEvent: PageEvent;
	pageSizeOptions;
	setPageSizeOptions(setPageSizeOptionsInput: string) {
		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}
	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}
	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchUsersListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}
	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}
}
