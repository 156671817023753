<div style="cursor: initial" *ngIf="contactEvent">
    <div class="example-full-width" fxLayout="row" fxLayoutAlign="start center">
        <a fxLayout="column" style="padding: 4px" (click)="editContactEvent(contactEvent)">
            <i class="fa fa-pencil"></i>
        </a> &nbsp;&nbsp;
        <model-info fxLayout="column" style="padding: 4px" [modelInfo]="contactEvent" [dataProvider]="contactEventsData"></model-info>
        &nbsp;&nbsp;
         <div style="padding-right:7px" fxLayout="column" fxFlex>
            <div fxLayout="row">
                <h3>{{ contactEvent.description }}</h3>
            </div>
            <div fxLayout="row"><span>{{ contactEvent.fromDateTime | date:'dd/MM/yyyy' }} {{contactEvent.fromTimeStr}}
                {{contactEvent.fromJewishDate}}</span> - <span
                *ngIf="contactEvent.tillDateTime">{{ contactEvent.tillDateTime | date:'dd/MM/yyyy' }}
                {{contactEvent.tillTimeStr}} {{contactEvent.tillJewishDate}}</span></div>
        </div>
    </div>
</div>
