import { Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { DashboardChartsDataService } from './dashboard-charts.data';
import { ChartsChartModel } from './dashboard-charts-chart-model';
import { AfterViewInit, Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as d3Scale from 'd3-scale';
import { MatDialog } from '@angular/material/dialog';
import { DashboardChartsControlComponent } from './dashboard-charts-control.component';

declare const Hebcal: any;

@Component({
    selector: 'dashboard-charts-main',
    templateUrl: 'dashboard-charts-main.component.html',
    styleUrls: ['dashboard-charts.component.scss']
})
export class DashboardChartsMainComponent implements OnInit, AfterViewInit, OnDestroy {

    animal: string;
    name: string;
    routeQueryParams$: Subscription;
    pieChartSourse: { name: string; value: any; }[];
    constructor(
        public dialog: MatDialog,
        private router: Router,
        private dashboardChartsDataService: DashboardChartsDataService,
    ) {
    }

    percentageYAxisTickFormatting(value) {
        return value + '%';
    }

    formatDataLabel(value) {
        return value + '% משתתפים';
    }


    ticketsFormatDataLabel(value) {
        return value + '%';
    }

    @Input('grp') public grp: string;

    loadAllCitiesParameter = false;
    lineChartModel;
    shtibelsChartModel: ChartsChartModel;
    shtibelsNewChartModel: ChartsChartModel;
    citiesChartModel: ChartsChartModel;
    globalInfoControlModel: ChartsChartModel;
    monthModel: any[] = [];
    siteChartModel;

    colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };

    loadExpectPaymentsInDays() {
        this.dashboardChartsDataService.getExpectPaymentsInDays(1, '1,4,5,6', 2022, 2)
            .subscribe((x: any) => {
                let lst = [];
                this.monthModel = x;
                //this.batModel =  new Map( x.subjectTotals.map(sbjct => [ sbjct.statusPaymentNane, sbjct.statusTotals.map(stts => ((sbjct.subjectsSumTotal/100)*stts.sumTotal))])); 

                for (let sbjct of x.subjectTotals) {
                    // let item = sbjct.statusTotals.map((stts:any) => [stts.statusPaymentNane, ((100/sbjct.subjectsSumTotal)*stts.sumTotal)]);
                    // lst.push(item);
                    //   sbjct.customStatusses = item;

                    for (let stts of sbjct.statusTotals) {

                        switch (stts.statusPayment) {
                            case 1:
                                sbjct.waitingAmount = stts.sumTotal;
                                sbjct.waiting = ((100 / sbjct.subjectsSumTotal) * stts.sumTotal);
                                break;
                            case 2:
                                sbjct.succeededAmount = stts.sumTotal;
                                sbjct.succeeded = ((100 / sbjct.subjectsSumTotal) * stts.sumTotal);
                                break;
                            case 3:
                                sbjct.failedAmount = stts.sumTotal;
                                sbjct.failed = ((100 / sbjct.subjectsSumTotal) * stts.sumTotal);
                                break;
                        } 
                    }
                }
                console.log(" this.batModel", this.monthModel);
                // this.pieChartSourse = [
                //     {
                //       "name": "הצליח",
                //       "value": this.monthModel.succeededSums
                //     },
                //     {
                //       "name": "ממתין",
                //       "value": this.monthModel.waitingSums
                //     },
                //     {
                //       "name": "נכשל",
                //       "value":  this.monthModel.failedSums
                //     }
                //   ]
            });
    }
    subscription: Subscription;
    intervalId;
    ngOnInit() {
        this.loadData();
    }

    ngAfterViewInit() {
        // This is METHOD 1
        const source = interval(600000);
        this.subscription = source.subscribe(() => this.loadData());

        // This is METHOD 2
        this.intervalId = setInterval(() => {
        }, 600000);
    }

    ngOnDestroy() {
        this.subscription && this.subscription.unsubscribe();

        // For method 2
        clearInterval(this.intervalId);
    }

    loadData() {
        this.loadExpectPaymentsInDays();
    }
}