import { AlertCenter, AlertCenterService } from './alert-center.svc';
import { Component, OnInit, Input } from '@angular/core';

@Component({

    selector: 'alert-center-item',
    templateUrl: './alert-center-item.tmpl.html'
})
export class AlertCenterItemComponent implements OnInit {
    constructor(private svc: AlertCenterService) { }

    @Input() public alert: AlertCenter;

    public remove(): void {
        this.svc.removeByAlert(this.alert);
    }
    ngOnInit() { }
}
