 
import { filter, tap, take } from 'rxjs/operators';
import { Router, RouterEvent, NavigationStart } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'; 
import { Component, Inject } from '@angular/core';
import { RegistrationEvent } from './registrationEvent';


@Component({
    selector: 'registrationEvent-details-dlg',
    templateUrl: './registrationEvent-details-dlg.tmpl.html'
})
export class RegistrationEventDetailsDlg {
  constructor(
    public matDialogRef: MatDialogRef<RegistrationEventDetailsDlg>,        
    @Inject(MAT_DIALOG_DATA)
    public data: RegistrationEvent ,        
    router: Router
) {  

    // Close dialog ref on route changes
    router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      tap(() => this.matDialogRef.close()),
      take(1),
    ).subscribe();
  }
  
  
}

