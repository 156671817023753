import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { ivrDonationUserTasksList } from './ivr-donation-user-tasks-list.cmp';
import { IvrDonationUserTaskEdit } from './ivr-donation-user-task/ivr-donation-user-task-edit.cmp';  
import { MaterialModule } from './../material.module'; 
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { PhonePipeMdl } from './../components/pipes/phone-pipe';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl'; 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl'; 
import { IvrDonationUserTasksData } from './ivr-donation-user-tasks.data';
import { CurrencyPipeMdl } from '../components/pipes/currency-pipe';
import { IvrDonationUserTasksRoutes } from './ivr-donation-user-tasks.routing';
import { IvrDonationUserTaskDetails } from './ivr-donation-user-task/ivr-donation-user-task-details.cmp';
import { IvrDonationUserTaskMain } from './ivr-donation-user-task/ivr-donation-user-task-main.cmp';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(IvrDonationUserTasksRoutes), 
		AutoCompleteModule,  
        PageHeadingModule, 
		PaginationModule,
		PanelBoxModule, 
		FooterEditButtonsMdl,
		PhonePipeMdl,
		CurrencyPipeMdl,
		DateInputModule,
		MaterialModule,
		MainFormModalModule
	],
	declarations: [
		ivrDonationUserTasksList,
		IvrDonationUserTaskEdit,
		IvrDonationUserTaskDetails,
		IvrDonationUserTaskMain
	],
	exports: [
		ivrDonationUserTasksList,
		IvrDonationUserTaskEdit,
		IvrDonationUserTaskDetails,
		IvrDonationUserTaskMain
	],
	providers: [
		IvrDonationUserTasksData
	]
})
export class IvrDonationUserTasksMdl { }

