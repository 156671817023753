import { Contact } from './../../contact/contact';


export class ContactAppointment {
  appointmentID: number;
  contactID: number;
  contactName: string;
  contactAppointmentType: number;
  subject:string;
  description: string;
  fromDateTime: Date;
  tillDateTime?: Date;
  fromTime?: number;
  tillTime?: number;
  fromJewishDate: string;
  tillJewishDate: string;
  eventTypeName: string;
  fromTimeStr: string;
  tillTimeStr: string;

}



export class AppointmentUser{
  appointmentID :number;
  gxUserName:string;
}
