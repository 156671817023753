import { MatDialog } from '@angular/material/dialog';
import { Component, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';

@Component({
    selector: 'order-charge-details',
    templateUrl: './order-charge-details.tmpl.html'
})

export class OrderChargeDetails implements AfterViewInit {

    @Input() model: any; 
  
    ngAfterViewInit(): void {
       // console.log("order-charge-details model", this.model);
    }

}



