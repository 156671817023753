<div *ngIf="model" fxLayout="row wrap" style="align-content: flex-start" fxLayout.md="column" fxLayout.sm="column"
  fxLayout.xs="column">
  <div fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column" fxFlex.gt-md="0 1 75" fxLayoutGap="10px"
    fxLayout="row wrap" style="align-content: flex-start">

    <!-- <mat-card *authGuardDirective="'screenIndex'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color:#fef5e2;">
            <mat-card-content>
                <a [routerLink]="['/screenIndex']" style="color: #662a37;" fxLayoutAlign="stert center">
                    <div style="text-align: center" class="col-xs-12 text-center;">
                        <h1 class="font-bold" style="font-size: 40px">
                           פורום שלוחי קודש
                        </h1>
                        <span>
                            <small>
                                <i class="fa fa-bar-chart"></i>
                                פרוייקט קמחא דפסחא תשפ"ב
                                <i class="fa fa-pie-chart"></i>
                            </small>
                        </span>
                    </div>
                </a>
            </mat-card-content>
          </mat-card> -->

    <!-- <a *authGuardDirective="'marbetore'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item-c" [routerLink]="['/marbe']" fxLayoutAlign="stert center">
    </a> -->

    <mat-card *authGuardDirective="'contacts'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color: WHITE;">
      <mat-card-content>
        <a [routerLink]="['/contacts']"
          style="color: #4e4040fa;  width: 100% !important; white-space: nowrap !important; margin: 0 !important;"
          fxLayoutAlign="stert center">
          <div class="row" fxLayout="row" style="width:100%">
            <div fxLayout="column" fxFlex>
              <span class="font-bold" style="font-size: 40px; white-space: normal;font-family: system-ui; line-height: 48px;">
                רשימות<br>אנ"ש
              </span>
            </div>
            <div  fxLayout="column">
              <span class="example-fill-remaining-space"></span>

              <i style="font-size: 100px;" class="fa fa-address-book-o"></i>

            </div>
          </div>
        </a>
      </mat-card-content>
    </mat-card>
 <a *authGuardDirective="'groupsSpecials'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item item-b" [routerLink]="['/dashboard', 'groupsSpecials']"
      fxLayoutAlign="stert center">
    </a>
    <!-- <mat-card *authGuardDirective="'Lottery'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color:#470b43;">
            <mat-card-content>
                <a [routerLink]="['/lottery']" style="color: #baa276;" fxLayoutAlign="stert center">
                    <div style="text-align: center" class="col-xs-12 text-center;">
                        <h1 class="font-bold" style="font-size: 40px">
                            מרבה תורה
                        </h1>
                        <span>
                            <small>
                                <i class="fa fa-bar-chart"></i>
                                עדכונים עוקבים על מצב הקמפיין
                                <i class="fa fa-pie-chart"></i>
                            </small>
                        </span>
                    </div>
                </a>
            </mat-card-content>
        </mat-card> -->
    <!-- <mat-card *authGuardDirective="'Charts'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color:#470b43;">
            <mat-card-content>
                <a [routerLink]="['/charts']" style="color: #baa276;" fxLayoutAlign="stert center">
                    <div style="text-align: center" class="col-xs-12 text-center;">
                        <h1 class="font-bold" style="font-size: 40px">
                            מעקב התחייבויות ותרומות
                        </h1>
                        <span>
                            <small>
                                <i class="fa fa-bar-chart"></i>
                                עדכונים עוקבים על קמפיינים התחייבויות ותשלומים שוטפים
                                <i class="fa fa-pie-chart"></i>
                            </small>
                        </span>
                    </div>
                </a>
            </mat-card-content>
        </mat-card>  -->


    <mat-card *authGuardDirective="'directorReports'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color: rgb(53 152 155);">
      <mat-card-content>
        <a [routerLink]="['/directorReports']" style="color: #baa276;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px;     font-size: 40px;
                BORDER: SOLID; border-width: 0 1px;">
              התחייבויות לגביה
            </h1>
            <span>
              <small>
                <i class="fa fa-dollar"></i>
                סיכום התחייבויות שלא הושלמו
                <i class="fa fa-dollar"></i>
              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card>

    <mat-card *authGuardDirective="'ivrDonationUserTasks'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color: rgb(37 90 30);">
      <mat-card-content>
        <a [routerLink]="['/ivrDonationUserTasks']" style="color: #baa276;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px">
              משימות תרומה
            </h1>
            <span>
              <small>
                {{ model.countIvrDonationUserTask }} משימות ממתינות
              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card>


    <!-- <mat-card *authGuardDirective="'tree'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(13%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color: red">
      <mat-card-content>
        <a [routerLink]="['/dashboard/tree']" style="color: black;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px">
             בדיקה
            </h1>
            <span>
              <small>
                בדיקה
              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card> -->

    <mat-card *authGuardDirective="'ivrAssignmentUserTasks'" fxFlex.sm="0 1 calc(50%-10px)"
      fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" class="item"
      style="background-color: rgb(32 7 93);">
      <mat-card-content>
        <a [routerLink]="['/ivrAssignmentUserTasks']" style="color: #baa276;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px">
              משימות תחזוקה
            </h1>
            <span>
              <small>
                {{ model.countIvrAssignmentUserTask }} משימות ממתינות
              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card>

    <mat-card *authGuardDirective="'groups'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color:rgb(55 92 93);">
      <mat-card-content>
        <a [routerLink]="['/dashboard', 'groups']" style="color: #baa276;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px">
              קבוצות
            </h1>
            <span>
              <small>
                <i class="fa fa-group"></i>
                ניהול ומעקב קבוצות
                <i class="fa fa-group"></i>
              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card>

    <mat-card *authGuardDirective="'trackingAcconts'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color: #1e7b10;">
      <mat-card-content>
        <a [routerLink]="['/dashboard', 'trackingAcconts']" style="color: #baa276;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px">
              מעקב תשלומים
            </h1>
            <span>
              <small>
                <i class="fa fa-calendar"></i>
                תשלומים בלוח השנה
                <i class="fa fa-calendar-check-o"></i>
              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card>

    <mat-card *authGuardDirective="'dashboardSecretary'" fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)"
      fxFlex.gt-md="0 1 calc(25%-10px)" class="item" style="background-color: #dcdcfd94;">
      <mat-card-content>
        <a [routerLink]="['/dashboard', 'secretary']" style="color: #baa276;" fxLayoutAlign="stert center">
          <div style="text-align: center" class="col-xs-12 text-center;">
            <h1 class="font-bold" style="font-size: 40px">
              שונות
              <small style="color:inherit;"></small>
            </h1>
            <span>
              <small>
                (מזכירות)
              </small>
            </span>
          </div>
        </a>
      </mat-card-content>
    </mat-card>
  </div>
  <div *authGuardDirective="'collectionBuilders'" fxFlex.gt-md="0 1 25">
    <div class="item">
      <collection-builders-small-list></collection-builders-small-list>
    </div>
  </div>
</div>
