import { ContactAppointmentsData } from './../contactAppointments.data';
import { ContactsData } from './../../contacts.data';
import { AlertService } from './../../../services/alert.service';
import { ContactAppointmentEditDlg } from './contactAppointment-edit-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ContactAppointment } from './contactAppointment';
import { Observable } from 'rxjs';

@Component({
    selector: 'contactAppointment-details',
    templateUrl: './contactAppointment-details.tmpl.html'
})

export class ContactAppointmentDetails {

    constructor(
        private contactAppointmentsData: ContactAppointmentsData,
        public dialog: MatDialog
    ) {

    }
    @Output() contactAppointmentChange = new EventEmitter<number>();
    @Output() onEditClick = new EventEmitter<number>();
    @Input('contactAppointment') public contactAppointment: ContactAppointment;

    editContactAppointment(cg) {
        this.onEditClick.emit(cg);
    }

    // private openAddOrEditContactAppointment(contactAppointmentID: number = 0): void {
    //     const dialogRef = this.dialog.open(ContactAppointmentEditDlg, {
    //         data: { contactId: this.contactAppointment.contactID, id: this.contactAppointment.contactAppointmentID, contactAppointment: this.contactAppointment }
    //     });


    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result && result.valid) {
    //             this.saveContactAppointment(result);
    //         }
    //     });
    // }

    // private saveContactAppointment(contactAppointmentForm: FormGroup) {
    //     this.contactsData.update(contactAppointmentForm.value)
    //         .subscribe((pm: any) => {
    //             return true;
    //         })
    // }

}



