import { MaterialModule } from '../../material.module';
import { MainFormModalModule } from '../../components/main-form-modal/main-form-modal.mdl';
import { AutoCompleteModule } from '../../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputModule } from '../../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from '../../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from '../../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../../components/panel-box/panel-box.mdl';
import { AmountAndCurrencyInputModule } from '../../components/amount-and-currency-input/amount-and-currency-input.module';
import { ModelInfoModule } from '../../components/model-info/model-info.mdl';
import { EmergencySquadMembersRoutes } from './emergency-squad-members.routing';
import { EmergencySquadMemberMain } from './emergency-squads-member/emergency-squad-member-main.cmp';
import { EmergencySquadMembersList } from './emergency-squad-members-list.cmp';
import { EmergencySquadMemberEdit } from './emergency-squads-member/emergency-squad-member-edit.cmp';
import { EmergencySquadMemberDetails } from './emergency-squads-member/emergency-squad-member-details.cmp';
import { EmergencySquadMembersData } from './emergency-squad-members.data';
import { PhonePipeMdl } from 'src/app/components/pipes/phone-pipe';
import { EmergencySquadQueuesMdl } from '../emergency-squad-queues/emergency-squad-queues.mdl';
import { DateTimeInputModule } from 'src/app/components/dateTimeInput/date-time-input.mdl';


@NgModule({
	imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		DateInputModule,
		AutoCompleteModule,
		RouterModule.forChild(EmergencySquadMembersRoutes),
		MaterialModule,
		MainFormModalModule,
		AmountAndCurrencyInputModule,
    ModelInfoModule,
    PhonePipeMdl,
    EmergencySquadQueuesMdl,
    DateTimeInputModule

	],
	declarations: [
		EmergencySquadMemberMain,
		EmergencySquadMembersList,
		EmergencySquadMemberEdit,
		EmergencySquadMemberDetails,
	],
	exports: [
		EmergencySquadMembersList,
		EmergencySquadMemberMain,
		EmergencySquadMemberEdit,
		EmergencySquadMemberDetails,
	],
	providers: [
		EmergencySquadMembersData
	]
})
export class EmergencySquadMembersMdl { }

