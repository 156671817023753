import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()

export class UsersData {


    private url = 'api/users';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }


    get(userName: string) {
        return this.toraxHttp.get(`${this.url}/getUser/${userName}`);
    }

    getSubjects() {
        return this.toraxHttp.get(`api/services/getCommitmentSubjects/${true}`);
    }

    getRegistrationEventTypes() {
        return this.toraxHttp.get(`api/services/getRegistrationEventTypes/${true}`);
    }




    delete(userName) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${userName}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    getRoles() {
        return this.toraxHttp.get(`${this.url}/getRoles`);
    }


    addRole(roleName) {
        return this.toraxHttp.get(`${this.url}/addRole?roleName=${roleName}`);
    }

    // addUserToRole() {
    //     return this.toraxHttp.get(`${this.url}/addUserToRole`);
    // }

    // removeUserFromRole() {
    //     return this.toraxHttp.get(`${this.url}/removeUserFromRole`);
    // }

    updateUserDetails(formData: any) {
        return this.toraxHttp.update(`${this.url}/updateUserDetails`, formData);
    }

    update(user: any) {
        return this.toraxHttp.update(this.url, user);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();

}

