import { TishInvited } from './../../tishInviteds/tishInvited/tishInvited';

export class TishPlace {
    narrowTable;
    tishPlaceID: number;
    tishInvited?: TishInvited;
    tishInvitedID: number;
    tishTableID;
    tishTableName;
    placeNumber;
    areaColor:string;
    tishEventID:number;
}

