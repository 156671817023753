<div>
    <form  *ngIf="objectsListResult" [formGroup]="searchProcessTypesListForm" novalidate>
        <panel-box [showClose]="false" [showCollapse]="false">
            <span class="panel-tools">
		<panel-box-btn (btnClick)="getProcessTypes()" icon="fa-refresh"></panel-box-btn>       
		<a [routerLink]="[{outlets: {popup: ['add']}}]"><i class="fa fa-plus"></i></a>
	</span>
            <div class="panel-content">
                <table class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>
                                <div>
                                    <span>
                                        <span (click)="addOrder('title', false)" >כותרת-נושא</span>
                                    <i (click)="addOrder('title', false)" [ngClass]="{'fa fa-arrow-up': orderType('title') == true }"></i>
                                    <i (click)="addOrder('title', true)" [ngClass]="{'fa fa-arrow-down': orderType('title') == false }"></i>
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of objectsListResult.list">
                            <td>
                                <a [routerLink]="['/processTypes', {outlets: {popup:  [c.processTypeID]}}]">{{ c.processTypeID }}</a>
                            </td>
                            <td>
                                {{ c.title }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination [currentPage]="objectsListResult.page" [pagesCount]="objectsListResult.pagesCount" (change)="changePage($event)"></pagination>
            </div>
        </panel-box>
    </form>

</div>