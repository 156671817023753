import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { DashboardMarbesDataService } from './dashboard-marbe.data';
import { AfterViewInit, Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import * as d3Scale from 'd3-scale';
import { MatDialog } from '@angular/material/dialog';
import { ColumnsChartModel } from './dashboard-marbe-chart-model';

declare const Hebcal: any;

@Component({
  selector: 'dashboard-marbe-shtibels',
  templateUrl: 'dashboard-marbe-shtibels.component.html',
  styleUrls: ['dashboard-marbe-cities.scss'], //'dashboard-marbe.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DashboardMarbeShtibelsComponent implements OnInit {
  constructor(

    private dashboardMarbesDataService: DashboardMarbesDataService,
  ) { }

  @Input('shtibelsModel') public shtibelsModel: ColumnsChartModel;
  ngOnInit() {
   // this.loadShtibelsChart();
  }

  styleObject(item) {
    let color = "";
    if (item > 70) {
      color = "rgb(73, 158, 45)";
    } else if (item > 50) {
      color = "rgb(139 189 122)";
    } else {
      color = "#f0e0c3";
    }
    let ret = `-webkit-linear-gradient(bottom, ${color} ${item}%  ${item
      }%, #fff ${item}%)`;
    return ret;
  }






  public loadShtibelsChart() {
    // this.dashboardMarbesDataService.getShtibelsChartModel()
    //   .subscribe((x: ColumnsChartModel) => {
    //     this.shtibelsModel = x;
    //   });
  }

}
