import { Address } from './address/address';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';


@Injectable()

export class AddressesData {

    private url = 'api/addresses';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(contactId: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
    }

    update(Address: Address) {
        return this.toraxHttp.update(this.url, Address);
    }

    setAddressPriority(contactID: number, prevAddressID: number, curAddressID: number, newCurPriority: number) {
        let ret = this.toraxHttp.postWithResponce(`${this.url}/setAddressPriority?contactID=${contactID}&prevAddressID=${prevAddressID}&curAddressID=${curAddressID}&newCurPriority=${newCurPriority}`, null);
        // this.onUpdated(contactID);
        return ret;
    }

    public updatedEvent = new EventEmitter<number>();

    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

