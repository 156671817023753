import { Contact } from './../../contacts/contact/contact';
import { AlertService } from './../../services/alert.service';
import { AuthenticationService } from './../../_guards/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ServicesData } from 'src/app/services/services.data';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef, ContentChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TableColumn } from './interfaces';
import { TableBtn } from './interfaces';

/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
  selector: 'general-table',
  templateUrl: 'general-table.component.tmpl.html',
  styleUrls: ['./general-table.component.css']
})
export class GeneralTableComponent implements AfterViewInit {
  constructor(
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private authService: AuthenticationService,
    private alert: AlertService,
    private cdref: ChangeDetectorRef) {

  }

  @Input() columns: TableColumn[] = [];
  // @Input() displayedColumns: string[];
  @Input() buttons: TableBtn[] = [];
  @Input() public dataProvider?: any;
  @Input() public searchListForm?: FormGroup;
  @Input() filter: boolean = false;
  @Input() filterPlaceholder: string = 'Filter';
  @Input() footer: string = null;
  @Input() pagination: number[] = [];
  @Input() pageSize: number;
  @Input() tableMinWidth: number = 500;
  @Output() filteredData = new EventEmitter<any[]>();
  @Output() buttonClick = new EventEmitter<string[]>();

  @ContentChild('matTable') matTable: TemplateRef<MatTable<Contact>>;

  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() selectedRowsChange = new EventEmitter<any>();
  orderButtonIsChicked: boolean = false;
  selection = new SelectionModel<number>(true, []);
  resultsLength = 0;
  stopLoadingPoints: boolean = false;
  isLoadingResults = false;
  isRateLimitReached = false;
  //sourceList;
  pageRows = 25;
  rowsCount;
  page;
  panelOpenState: boolean = false;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;
  pageSizeOptions;
  data: any[] = [];

  openSelectedRow(id) { }

  public getList() {
    if (this.paginator) {
      this.searchListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    }
    this.isLoadingResults = true;
    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchListForm.value));

    this.displayedColumns = [...this.columns.map(c => c.columnDef)];
    return this.dataProvider!.getList(this.searchListForm.value)
      .subscribe((data: any) => {
        this.data = data.list;

        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        if (this.buttons && this.buttons.length > 0) this.displayedColumns = [...this.displayedColumns, 'actions'];

        // this.sourceList = data.list;

        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isLoadingResults = false;
        this.isRateLimitReached = false;

        //trick
        this.stopLoadingPoints = false;
      });
  }


  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.contactID === row.contactID);
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  pageChange(e) {
    this.searchListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged(e);
  }

  selectionChanged(e) {
    this.getList();
  }

  public clerFilter() {
    this.initFormProps();
    this.getList();
  }

  initFormProps() {
    this.searchListForm.addControl('page', new FormControl());
    this.searchListForm.addControl('pageRows', new FormControl());
    this.searchListForm.addControl('selectedRows', new FormControl());
    this.searchListForm.addControl('orders', this.formBuilder.array([]));

    this.selection.changed.subscribe(x => {
      this.searchListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.contactID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngAfterViewInit() {
    this.stopLoadingPoints = true;
    if (this.searchListForm) {
      this.initFormProps();
    }

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchListForm.patchValue(frm);
    }

    this.getList();

    this.selection.isSelected = this.isChecked.bind(this);
  }


  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getList();
  }

  public search() {
  }

  removeOrder(propertyName: string) {
    const ordersControl = <FormArray>this.searchListForm.controls['orders'];
    let i = null;
    ordersControl.controls.forEach((ctrl, ix) => {
      if ((<any>ctrl).value.key == propertyName) {
        i = ix;
      }
    });
    if (i >= 0) {
      ordersControl.removeAt(i);
    }
  }

  addOrder(propertyName: string, desc: boolean = false, priorityOrder: number = null) {
    const ordersControl = <FormArray>this.searchListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      if (priorityOrder >= 1) {
        ordersControl.insert(priorityOrder - 1, ord);
      } else {

        ordersControl.push(ord);
      }
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}


