
import { DirectivesMdl } from './../directives/directives-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmountAndCurrencyInputComponent } from './amount-and-currency-input.component';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    imports: [
        CommonModule,
		FormsModule,
		ReactiveFormsModule,
        DirectivesMdl,

    MaterialModule
    ],
    declarations: [
        AmountAndCurrencyInputComponent,
    ],
    exports: [
        AmountAndCurrencyInputComponent,
    ]
})


export class AmountAndCurrencyInputModule {

}
