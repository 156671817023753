﻿<form novalidate (ngSubmit)="save()" *ngIf="collectionBuilder">
    <div class="modal-header">
        <h1>עריכת פרטי גביה [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="example-form" [formGroup]="collectionBuilderForm" novalidate>
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>פרטי גביה</legend>

                    <div class="form-row" fxLayout="row">
                        <date-input [placeholder]="'תאריך גביה'"
                            [dateControl]="collectionBuilderForm.controls.collectionDate">Loading...
                        </date-input>
                    </div>
                    <div class="form-row" fxLayout="row">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" formControlName="collectionDateIsTill">
                            <label class="custom-control-label" for="collectionDateIsTill">&nbsp;כלול תאריכים מוקדמים בחודש
                                זה:</label>
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row">
                        <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש לחיוב'"
                            [formGroupModel]="collectionBuilderForm"
                            [idControl]="collectionBuilderForm.controls.contactID"
                            [nameControl]="collectionBuilderForm.controls.contactName"></auto-complete>
                    </div>
                    <div class="form-row" fxLayout="row">
                        <div class="input-group mb-3">
                            <label for="paymentID">מזהה תשלום לחיוב:</label>
                            <input type="number" class="form-control" formControlName="paymentID" />
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row">
                        <div class="input-group mb-3">
                            <label for="termID">מסוף:</label>
                            <select class="form-control" formControlName="termID">
                                <option value="">...</option>
                                <option *ngFor="let api of servicesData.Apis" [value]="api.value">
                                    {{api.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row">
                        <div class="input-group mb-3">
                            <label for="dollar">ערך הדולר:</label>
                            <input type="number" class="form-control" formControlName="dollar" />
                        </div>
                        &nbsp;
                        <div class="input-group mb-3">
                            <label for="cadDollar">ערך דולר קנדי:</label>
                            <input type="number" class="form-control" formControlName="cadDollar" />
                        </div>
                        &nbsp;
                        <div class="input-group mb-3">
                            <label for="euro">ערך היורו:</label>
                            <input type="number" class="form-control" formControlName="euro" />
                        </div>
                        &nbsp;
                        <div class="input-group mb-3">
                            <label for="franc">ערך הפרנק:</label>
                            <input type="number" class="form-control" formControlName="franc" />
                        </div>
                    </div>

                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="collectionBuildersData" [router]="router" [id]="id"
        [disabledDeleteButton]="id <= 0" [modelFormGroup]="collectionBuilderForm"></footer-edit-buttons>

</form>