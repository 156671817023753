
import { PaymentsPlanMain } from './paymentsPlan/paymentsPlan-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { InputInfoModule } from './../components/input-info/input-info.module';
import { PaymentsPlanHoldingsSetting } from './paymentsPlan/paymentsPlan-holdings-setting.cmp';
import { PaymentsPlanHoldingsSettingDlg } from './paymentsPlan/paymentsPlan-holdings-setting-dlg.cmp';
import { CommitmentTitleModule } from './../commitment-title/commitment-title.module';
import { CommitmentsSelectPickMdl } from '../commitments/commitments-select-pick/commitments-select-pick.mdl';
import { PayPlanCommitmentEditComponent } from './pay-plan-commitment-edit/pay-plan-commitment-edit.component';
import { PaymentsPlanDetails } from './paymentsPlan/paymentsPlan-details.cmp';
import { MaterialModule } from './../material.module';
import { PaymentsPlansRoutes } from './paymentsPlans.routing';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputRangeModule } from './../components/dateInput-range/date-input-range.mdl';
import { DateMonthInputModule } from './../components/dateMonth-input/dateMonth-input.mdl';
import { PaymentsPlansList } from './paymentsPlans-list.cmp';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { AmountInputRangeModule } from './../components/amountInput-range/amount-input-range.mdl';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { PaymentsPlansData } from './paymentsPlans.data';
import { PaymentsPlanEdit } from './paymentsPlan/paymentsPlan-edit.cmp';
import { CommonModule } from '@angular/common';
import { PaymentsMdl } from '../payments/payments.mdl';
import { PayMethodsMdl } from '../payMethods/payMethods.mdl';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';
import { HoldingsMdl } from '../holdings/holdings.mdl';
import { PaymentsPlanClosedDlg } from './paymentsPlan/paymentsPlan-closed-dlg.cmp';
import { PaymentsPlanPaymentsChangesDlg } from './paymentsPlan/paymentsPlan-payments-changes-dlg.cmp';
import { AddressesMdl } from '../addresses/addresses.mdl';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(PaymentsPlansRoutes),
        AmountInputRangeModule,
        DateInputModule,
        DateMonthInputModule,
        DateInputRangeModule,
        FooterEditButtonsMdl,
        AutoCompleteModule,
        MaterialModule,
        CustomToolTipModule,
        CommitmentsSelectPickMdl,
        CommitmentTitleModule,
        InputInfoModule,
        ModelInfoModule,
        MainFormModalModule,
        PaymentsMdl,
        PayMethodsMdl,
        HoldingsMdl,
        AddressesMdl
    ],
    declarations: [
        PaymentsPlanMain,
        PaymentsPlanEdit,
        PaymentsPlanDetails,
        PaymentsPlansList,
        PayPlanCommitmentEditComponent,
        PaymentsPlanHoldingsSettingDlg,
        PaymentsPlanHoldingsSetting,
        PaymentsPlanClosedDlg,
        PaymentsPlanPaymentsChangesDlg

    ],
    exports: [
        PaymentsPlanMain,
        PaymentsPlansList,
        PaymentsPlanEdit,
        PaymentsPlanDetails,
        PaymentsPlanClosedDlg,
        PaymentsPlanPaymentsChangesDlg,
    ],
    providers: [
        PaymentsPlansData
    ]
})
export class PaymentsPlansMdl { }



