import { of } from 'rxjs';
import { Observable, Observer } from 'rxjs';
import { AuthenticationService } from '../_guards/auth.service';
import { AlertService } from './../components/alert/alert.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { ToraxHttp } from '../utils/custom-http.svc';



@Injectable({
  providedIn: 'root',
})

export class ServicesData {
  constructor(
    public toraxHttp: ToraxHttp,
    private authService: AuthenticationService,
    private alertService: AlertService
  ) {

  }

  public url = 'api/services';


  public getRandomColor(groupID: number) {
    var color = Math.floor(0x1000000 * (groupID / 20)).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  public loadAllLists(): void {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.authService.isAuthorized("SystemAdministrator,Services")) {
        this.cities = null; ///<= זמני
        this.teams = null;
        this.locationTypes = null;
        this.personalStatuses = null;
        this.accountLocations = null;
        this.gabaiPriorities = null;
        this.statusPayments = null;
        this.currencies = null;
        this.marginTypes = null;

        this.commitmentSubjects = null;
        this.languages = null;
        this.contactTypes = null;
        this.donationTypes = null;
        this.paymentsPlanTypes = null;
        this.fundraisingTypes = null;
        this.addressesTypes = null;
        this.apis = null;
        this.statusResponses = null;
        this.yarzeitTypes = null;
        // //this.loadactivityTypes();
        // //this.loadGxUsers();
        // //this.loadProcessTypes();
        this.honorDepartments = null;
        this.honorTypes = null;
        this.letterSettings = null;
        this.receiptsLetterSettings = null;
        this.relationTypes = null;
        this.closedStatuses = null;
        this.vouchersOrganizations = null;
        this.organizationBankAccounts = null;
        this.groups = null;
        this.contactEventTypes = null;
        this.groupPriorities = null;
        this.registrationEventTypes = null;
        this.contactAffiliations = null;
        this.perfixes = null;
        this.suffixes = null;
        this.localPerfixes = null;
        this.localSuffixes = null;
        this.userDepartments = null;
        this.paymentOptions = null;
        this.streets = null;
        // this.loadbanks();
        // // this.loadBankBranches();
        // // this.loadDayOptions();
        // // this.loadJewishDayOptions();
      } else {
        this.alertService.info("רשומות לא נטענו! \nלא נמצאו הרשאות מתאימות");
      }
    }

  }


  getNotificationsAlerts(): any  {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
          this.getList('NotificationsAlerts').subscribe((x ) => {return x;});
    }
  }

  isLoadingDepositTypes: boolean = false;
  private depositTypes: Array<any>;
  get DepositTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.depositTypes) {
        return this.depositTypes;
      } else if (!this.isLoadingDepositTypes) {
        this.isLoadingDepositTypes = true;
        this.getList('DepositTypes').subscribe((x: any) => {
          this.depositTypes = x;
          return this.depositTypes;
        });
      }
    }
  }

  isLoadingTeams: boolean = false;
  private teams: Array<any>;
  get Teams(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.teams) {
        return this.teams;
      } else if (!this.isLoadingTeams) {
        this.isLoadingTeams = true;
        this.getList('Teams').subscribe((x: any) => {
          this.teams = x;
          return this.teams;
        });
      }
    }
  }

  isLoadingLocationTypes: boolean = false;
  private locationTypes: Array<any>;
  get LocationTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.locationTypes) {
        return this.locationTypes;
      } else if (!this.isLoadingLocationTypes) {
        this.isLoadingLocationTypes = true;
        this.getList('LocationTypes').subscribe((x: any) => {
          this.locationTypes = x;
          return this.locationTypes;
        });
      }
    }
  }

  isLoadingPersonalStatuses: boolean = false;
  private personalStatuses: Array<any>;
  get PersonalStatuses(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.personalStatuses) {
        return this.personalStatuses;
      } else if (!this.isLoadingPersonalStatuses) {
        this.isLoadingPersonalStatuses = true;
        this.getList('PersonalStatuses').subscribe((x: any) => {
          this.personalStatuses = x;
          return this.personalStatuses;
        });
      }
    }
  }

  isLoadingAccountLocations: boolean = false;
  private accountLocations: Array<any>;
  get AccountLocations(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.accountLocations) {
        return this.accountLocations;
      } else if (!this.isLoadingAccountLocations) {
        this.isLoadingAccountLocations = true;
        this.getList('AccountLocations').subscribe((x: any) => {
          this.accountLocations = x;
          return this.accountLocations;
        });
      }
    }
  }

  isLoadingGabaiPriorities: boolean = false;
  private gabaiPriorities: Array<any>;
  get GabaiPriorities(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.gabaiPriorities) {
        return this.gabaiPriorities;
      } else if (!this.isLoadingGabaiPriorities) {
        this.isLoadingGabaiPriorities = true;
        this.getList('GabaiPriorities').subscribe((x: any) => {
          this.gabaiPriorities = x;
          return this.gabaiPriorities;
        });
      }
    }
  }

  isLoadingStatusPayments: boolean = false;
  private statusPayments: Array<any>;
  get StatusPayments(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.statusPayments) {
        return this.statusPayments;
      } else if (!this.isLoadingStatusPayments) {
        this.isLoadingStatusPayments = true;
        this.getList('StatusPayments').subscribe((x: any) => {
          this.statusPayments = x;
          return this.statusPayments;
        });
      }
    }
  }

  isLoadingCurrencies: boolean = false;
  private currencies: Array<any>;
  get Currencies(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.currencies) {
        return this.currencies;
      } else if (!this.isLoadingCurrencies) {
        this.isLoadingCurrencies = true;
        this.getList('Currencies').subscribe((x: any) => {
          this.currencies = x;
          return this.currencies;
        });
      }
    }
  }

  isLoadingMarginTypes: boolean = false;
  private marginTypes: Array<any>;
  get MarginTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.marginTypes) {
        return this.marginTypes;
      } else if (!this.isLoadingMarginTypes) {
        this.isLoadingMarginTypes = true;
        this.getList('MarginTypes').subscribe((x: any) => {
          this.marginTypes = x;
          return this.marginTypes;
        });
      }
    }
  }

  isLoadingCommitmentSubjects: boolean = false;
  private commitmentSubjects: Array<any>;
  get CommitmentSubjects(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.commitmentSubjects) {
        return this.commitmentSubjects;
      } else if (!this.isLoadingCommitmentSubjects) {
        this.isLoadingCommitmentSubjects = true;
        this.getList('CommitmentSubjects').subscribe((x: any) => {
          this.commitmentSubjects = x;
          return this.commitmentSubjects;
        });
      }
    }
  }

  isLoadingLanguages: boolean = false;
  private languages: Array<any>;
  get Languages(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.languages) {
        return this.languages;
      } else if (!this.isLoadingLanguages) {
        this.isLoadingLanguages = true;
        this.getList('Languages').subscribe((x: any) => {
          this.languages = x;
          return this.languages;
        });
      }
    }
  }

  isLoadingContactTypes: boolean = false;
  private contactTypes: Array<any>;
  get ContactTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.contactTypes) {
        return this.contactTypes;
      } else if (!this.isLoadingContactTypes) {
        this.isLoadingContactTypes = true;
        this.getList('ContactTypes').subscribe((x: any) => {
          this.contactTypes = x;
          return this.contactTypes;
        });
      }
    }
  }

  isLoadingDonationTypes: boolean = false;
  private donationTypes: Array<any>;
  get DonationTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.donationTypes) {
        return this.donationTypes;
      } else if (!this.isLoadingDonationTypes) {
        this.isLoadingDonationTypes = true;
        this.getList('DonationTypes').subscribe((x: any) => {
          this.donationTypes = x;
          return this.donationTypes;
        });
      }
    }
  }

  isLoadingPaymentsPlanTypes: boolean = false;
  private paymentsPlanTypes: Array<any>;
  get PaymentsPlanTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.paymentsPlanTypes) {
        return this.paymentsPlanTypes;
      } else if (!this.isLoadingPaymentsPlanTypes) {
        this.isLoadingPaymentsPlanTypes = true;
        this.getList('PaymentsPlanTypes').subscribe((x: any) => {
          this.paymentsPlanTypes = x;
          return this.paymentsPlanTypes;
        });
      }
    }
  }
  isLoadingFundraisingTypes: boolean = false;
  private fundraisingTypes: Array<any>;
  get FundraisingTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.fundraisingTypes) {
        return this.fundraisingTypes;
      } else if (!this.isLoadingFundraisingTypes) {
        this.isLoadingFundraisingTypes = true;
        this.getList('FundraisingTypes').subscribe((x: any) => {
          this.fundraisingTypes = x;
          return this.fundraisingTypes;
        });
      }
    }
  }
  isLoadingAddressesTypes: boolean = false;
  private addressesTypes: Array<any>;
  get AddressesTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.addressesTypes) {
        return this.addressesTypes;
      } else if (!this.isLoadingAddressesTypes) {
        this.isLoadingAddressesTypes = true;
        this.getList('AddressesTypes').subscribe((x: any) => {
          this.addressesTypes = x;
          return this.addressesTypes;
        });
      }
    }
  }

  isLoadingApis: boolean = false;
  private apis: Array<any>;
  get Apis(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.apis) {
        return this.apis;
      } else if (!this.isLoadingApis) {
        this.isLoadingApis = true;
        this.getList('Apis').subscribe((x: any) => {
          this.apis = x;
          return this.apis;
        });
      }
    }
  }

  isLoadingStatusResponses: boolean = false;
  private statusResponses: Array<any>;
  get StatusResponses(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.statusResponses) {
        return this.statusResponses;
      } else if (!this.isLoadingStatusResponses) {
        this.isLoadingStatusResponses = true;
        this.getList('StatusResponses').subscribe((x: any) => {
          this.statusResponses = x;
          return this.statusResponses;
        });
      }
    }
  }

  // isLoadingGxUsers: boolean = false;
  // private gxUsers: Array<any>;
  // get GxUsers(): Array<any> {
  //     if(this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")){if (this.gxUsers) {
  //         return this.gxUsers;
  //     } else if (!this.isLoadingGxUsers) {
  //         this.isLoadingGxUsers = true;
  //         this.getList('GxUsers').subscribe((x: any) => {
  //             this.gxUsers = x;
  //             return this.gxUsers;
  //         });
  //     }
  // }}
  // //



  isLoadingAssignmentUserTasksSubjectsVms: boolean = false;
  private assignmentUserTasksSubjectsVms: Array<any>;
  get AssignmentUserTasksSubjectsVms(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.assignmentUserTasksSubjectsVms) {
        return this.assignmentUserTasksSubjectsVms;
      } else if (!this.isLoadingAssignmentUserTasksSubjectsVms) {
        this.isLoadingAssignmentUserTasksSubjectsVms = true;
        this.getList('AssignmentUserTasksSubjectsVms').subscribe((x: any) => {
          this.assignmentUserTasksSubjectsVms = x;
          return this.assignmentUserTasksSubjectsVms;
        });
      }
    }
  }


  isLoadingYarzeitTypes: boolean = false;
  private yarzeitTypes: Array<any>;
  get YarzeitTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.yarzeitTypes) {
        return this.yarzeitTypes;
      } else if (!this.isLoadingYarzeitTypes) {
        this.isLoadingYarzeitTypes = true;
        this.getList('YarzeitTypes').subscribe((x: any) => {
          this.yarzeitTypes = x;
          return this.yarzeitTypes;
        });
      }
    }
  }

  isLoadingGxUsers: boolean = false;
  private gxUsers: Array<any>;
  get GxUsers(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.gxUsers) {
        return this.gxUsers;
      } else if (!this.isLoadingGxUsers) {
        this.isLoadingGxUsers = true;
        this.getList('GxUsers').subscribe((x: any) => {
          this.gxUsers = x;
          return this.gxUsers;
        });
      }
    }
  }


  isLoadingAssignmentUserTasksUserNamesVms: boolean = false;
  private assignmentUserTasksUserNamesVms: Array<any>;
  get AssignmentUserTasksUserNamesVms(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.assignmentUserTasksUserNamesVms) {
        return this.assignmentUserTasksUserNamesVms;
      } else if (!this.isLoadingAssignmentUserTasksUserNamesVms) {
        this.isLoadingAssignmentUserTasksUserNamesVms = true;
        this.getList('AssignmentUserTasksUserNamesVms').subscribe((x: any) => {
          this.assignmentUserTasksUserNamesVms = x;
          return this.assignmentUserTasksUserNamesVms;
        });
      }
    }
  }

  isLoadingHonorDepartments: boolean = false;
  private honorDepartments: Array<any>;
  get HonorDepartments(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.honorDepartments) {
        return this.honorDepartments;
      } else if (!this.isLoadingHonorDepartments) {
        this.isLoadingHonorDepartments = true;
        this.getList('HonorDepartments').subscribe((x: any) => {
          this.honorDepartments = x;
          return this.honorDepartments;
        });
      }
    }
  }

  isLoadingHonorTypes: boolean = false;
  private honorTypes: Array<any>;
  get HonorTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.honorTypes) {
        return this.honorTypes;
      } else if (!this.isLoadingHonorTypes) {
        this.isLoadingHonorTypes = true;
        this.getList('HonorTypes').subscribe((x: any) => {
          this.honorTypes = x;
          return this.honorTypes;
        });
      }
    }
  }

  isLoadingLetterSettings: boolean = false;
  private letterSettings: Array<any>;
  get LetterSettings(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.letterSettings) {
        return this.letterSettings;
      } else if (!this.isLoadingLetterSettings) {
        this.isLoadingLetterSettings = true;
        this.getList('LetterSettings').subscribe((x: any) => {
          this.letterSettings = x;
          return this.letterSettings;
        });
      }
    }
  }

  isLoadingReceiptsLetterSettings: boolean = false;
  private receiptsLetterSettings: Array<any>;
  get ReceiptsLetterSettings(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.receiptsLetterSettings) {
        return this.receiptsLetterSettings;
      } else if (!this.isLoadingReceiptsLetterSettings) {
        this.isLoadingReceiptsLetterSettings = true;
        this.getList('ReceiptsLetterSettings').subscribe((x: any) => {
          this.receiptsLetterSettings = x;
          return this.receiptsLetterSettings;
        });
      }
    }
  }

  isLoadingRelationTypes: boolean = false;
  private relationTypes: Array<any>;
  get RelationTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.relationTypes) {
        return this.relationTypes;
      } else if (!this.isLoadingRelationTypes) {
        this.isLoadingRelationTypes = true;
        this.getList('RelationTypes').subscribe((x: any) => {
          this.relationTypes = x;
          return this.relationTypes;
        });
      }
    }
  }

  isLoadingClosedStatuses: boolean = false;
  private closedStatuses: Array<any>;
  get ClosedStatuses(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.closedStatuses) {
        return this.closedStatuses;
      } else if (!this.isLoadingClosedStatuses) {
        this.isLoadingClosedStatuses = true;
        this.getList('ClosedStatuses').subscribe((x: any) => {
          this.closedStatuses = x;
          return this.closedStatuses;
        });
      }
    }
  }

  isLoadingVouchersOrganizations: boolean = false;
  private vouchersOrganizations: Array<any>;
  get VouchersOrganizations(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.vouchersOrganizations) {
        return this.vouchersOrganizations;
      } else if (!this.isLoadingVouchersOrganizations) {
        this.isLoadingVouchersOrganizations = true;
        this.getList('VouchersOrganizations').subscribe((x: any) => {
          this.vouchersOrganizations = x;
          return this.vouchersOrganizations;
        });
      }
    }
  }

  isLoadingOrganizationBankAccounts: boolean = false;
  private organizationBankAccounts: Array<any>;
  get OrganizationBankAccounts(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.organizationBankAccounts) {
        return this.organizationBankAccounts;
      } else if (!this.isLoadingOrganizationBankAccounts) {
        this.isLoadingOrganizationBankAccounts = true;
        this.getList('OrganizationBankAccounts').subscribe((x: any) => {
          this.organizationBankAccounts = x;
          return this.organizationBankAccounts;
        });
      }
    }
  }

  isLoadingGroups: boolean = false;
  private groups: Array<any>;
  get Groups(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.groups) {
        return this.groups;
      } else if (!this.isLoadingGroups) {
        this.isLoadingGroups = true;
        this.getList('Groups').subscribe((x: any) => {
          this.groups = x;
          return this.groups;
        });
      }
    }
  }

  public getTishGroups(tishEventID: number) {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      return this.getList(`TishGroups/${tishEventID}`);
    }
  }

  public getTishInvitedStatuses(tishEventID: number) {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      return this.getList(`TishInvitedStatuses/${tishEventID}`);
    }
  }


  public getTishContributorRatings(tishEventID: number) {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      return this.getList(`TishContributorRatings/${tishEventID}`);
    }
  }


  isLoadingTishGroups: boolean = false;
  private tishGroups: Array<any>;
  get TishGroups(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.tishGroups) {
        return this.tishGroups;
      } else if (!this.isLoadingTishGroups) {
        this.isLoadingTishGroups = true;
        this.getList('TishGroups').subscribe((x: any) => {
          this.tishGroups = x;
          return this.tishGroups;
        });
      }
    }
  }

  isLoadingContactEventTypes: boolean = false;
  private contactEventTypes: Array<any>;
  get ContactEventTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.contactEventTypes) {
        return this.contactEventTypes;
      } else if (!this.isLoadingContactEventTypes) {
        this.isLoadingContactEventTypes = true;
        this.getList('ContactEventTypes').subscribe((x: any) => {
          this.contactEventTypes = x;
          return this.contactEventTypes;
        });
      }
    }
  }

  isLoadingContactGroupPriorities: boolean = false;
  private groupPriorities: Array<any>;
  get ContactGroupPriorities(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.groupPriorities) {
        return this.groupPriorities;
      } else if (!this.isLoadingContactGroupPriorities) {
        this.isLoadingContactGroupPriorities = true;
        this.getList('ContactGroupPriorities').subscribe((x: any) => {
          this.groupPriorities = x;
          return this.groupPriorities;
        });
      }
    }
  }

  isLoadingContactGroupStatuses: boolean = false;
  private groupStatuses: Array<any>;
  get ContactGroupStatuses(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services,Groups,Contacts")) {
      if (this.groupStatuses) {
        return this.groupStatuses;
      } else if (!this.isLoadingContactGroupStatuses) {
        this.isLoadingContactGroupStatuses = true;
        this.getList('ContactGroupStatuses').subscribe((x: any) => {
          this.groupStatuses = x;
          return this.groupStatuses;
        });
      }
    }
  }

  isLoadingContactAffiliations: boolean = false;
  private contactAffiliations: Array<any>;
  get ContactAffiliations(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.contactAffiliations) {
        return this.contactAffiliations;
      } else if (!this.isLoadingContactAffiliations) {
        this.isLoadingContactAffiliations = true;
        this.getList('ContactAffiliations').subscribe((x: any) => {
          this.contactAffiliations = x;
          return this.contactAffiliations;
        });
      }
    }
  }

  isLoadingPerfixes: boolean = false;
  private perfixes: Array<any>;
  get Perfixes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.perfixes) {
        return this.perfixes;
      } else if (!this.isLoadingPerfixes) {
        this.isLoadingPerfixes = true;
        this.getList('Perfixes').subscribe((x: any) => {
          this.perfixes = x;
          return this.perfixes;
        });
      }
    }
  }

  isLoadingSuffixes: boolean = false;
  private suffixes: Array<any>;
  get Suffixes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.suffixes) {
        return this.suffixes;
      } else if (!this.isLoadingSuffixes) {
        this.isLoadingSuffixes = true;
        this.getList('Suffixes').subscribe((x: any) => {
          this.suffixes = x;
          return this.suffixes;
        });
      }
    }
  }

  isLoadingLocalPerfixes: boolean = false;
  private localPerfixes: Array<any>;
  get LocalPerfixes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.localPerfixes) {
        return this.localPerfixes;
      } else if (!this.isLoadingLocalPerfixes) {
        this.isLoadingLocalPerfixes = true;
        this.getList('LocalPerfixes').subscribe((x: any) => {
          this.localPerfixes = x;
          return this.localPerfixes;
        });
      }
    }
  }

  isLoadingRegistrationEventTypes: boolean = false;
  private registrationEventTypes: Array<any>;
  get RegistrationEventTypes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.registrationEventTypes) {
        return this.registrationEventTypes;
      } else if (!this.isLoadingRegistrationEventTypes) {
        this.isLoadingRegistrationEventTypes = true;
        this.getList('RegistrationEventTypes').subscribe((x: any) => {
          this.registrationEventTypes = x;
          return this.registrationEventTypes;
        });
      }
    }
  }


  isLoadingLocalSuffixes: boolean = false;
  private localSuffixes: Array<any>;
  get LocalSuffixes(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.localSuffixes) {
        return this.localSuffixes;
      } else if (!this.isLoadingLocalSuffixes) {
        this.isLoadingLocalSuffixes = true;
        this.getList('LocalSuffixes').subscribe((x: any) => {
          this.localSuffixes = x;
          return this.localSuffixes;
        });
      }
    }
  }

  isLoadingUserDepartments: boolean = false;
  private userDepartments: Array<any>;
  get UserDepartments(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.userDepartments) {
        return this.userDepartments;
      } else if (!this.isLoadingUserDepartments) {
        this.isLoadingUserDepartments = true;
        this.getList('UserDepartments').subscribe((x: any) => {
          this.userDepartments = x;
          return this.userDepartments;
        });
      }
    }
  }

  isLoadingPaymentOptions: boolean = false;
  private paymentOptions: Array<any>;
  get PaymentOptions(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.paymentOptions) {
        return this.paymentOptions;
      } else if (!this.isLoadingPaymentOptions) {
        this.isLoadingPaymentOptions = true;
        this.getList('PaymentOptions').subscribe((x: any) => {
          this.paymentOptions = x;
          return this.paymentOptions;
        });
      }
    }
  }


  isLoadingStreets: boolean = false;
  private streets: Array<any>;
  get Streets(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.streets) {
        return this.streets;
      } else if (!this.isLoadingStreets) {
        this.isLoadingStreets = true;
        this.getList('Streets').subscribe((x: any) => {
          this.streets = x;
          return this.streets;
        });
      }
    }
  }


  isLoadingCities: boolean = false;
  private cities: Array<any>;
  get Cities(): Array<any> {
    if (this.authService.currentUserValue && this.authService.isAuthorized("SystemAdministrator,Services")) {
      if (this.cities) {
        return this.cities;
      } else if (!this.isLoadingCities) {
        this.isLoadingCities = true;
        this.getList('Cities').subscribe((x: any) => {
          this.cities = x;
          return this.cities;
        });
      }
    }
  }



  //public ProcessTypes: Array<any>;
  //public ActivityTypes: Array<any>;
  //public Contacts: Array<any>;
  //public Shtibels: Array<any>;
  //public RegistrationEvents: Array<any>;
  // public Banks: Array<any>;
  // public BankBranches: Array<any>;


  // public DayOptions: Array<any>;
  // public JewishDayOptions: Array<any>;
  // resolve() {
  //     this
  //     return Observable.of('Hello Alligator!').delay(10);
  // }

  // public getConnectedUserName() {
  //     return this.toraxHttp.get(`${this.url}/getConnectedUserName`);
  // }

  // public getConnectedUid() {
  //     return this.toraxHttp.get(`${this.url}/getConnectedUid`);
  // }

  // public logConsole(error) {
  //     const userAgent = window.navigator.userAgent;
  //     if (userAgent.indexOf("Android") > -1) {
  //         let errorMessage = "Error: ";
  //         if (error != undefined) {
  //             if (error.error) {  // instance of ErrorEvent
  //                 errorMessage = error.error.message; //JSON.parse(error.error);
  //             } else if (typeof error === "string") {
  //                 errorMessage += error;
  //             } else if (error.status || error.message) {
  //                 errorMessage += `Code: [${error.status}], Message: [${error.message}]`;
  //             } else {
  //                 errorMessage += error.toString();
  //             }
  //         }

  //         let errorToSend = {
  //             message: errorMessage,
  //             clientUserName: "android"
  //         }

  //         return this.toraxHttp.post(`${this.url}/logConsole`, error);
  //     }
  //     else {
  //         this.toraxHttp.handleError(error);
  //     }
  // }

  public getContactName(id: number) {
    return this.toraxHttp.get(`${this.url}/getContactName/${id}`);
  }

  public dayOptions: Array<any>;
  get DayOptions() {
    if (!this.dayOptions || this.dayOptions.length === 0) {
      this.getDayOptions();
    }
    return this.dayOptions;
  }

  public jewishDayOptions: Array<any>;
  get JewishDayOptions() {
    if (!this.jewishDayOptions || this.jewishDayOptions.length === 0) {
      this.getJewishDayOptions();
    }
    return this.jewishDayOptions;
  }

  public jewishYearOptions: Array<any>;
  get JewishYearOptions() {
    if (!this.jewishYearOptions || this.jewishYearOptions.length === 0) {
      this.getJewishYearOptions();
    }
    return this.jewishYearOptions;
  }




  public getDayOptions() {
    this.dayOptions = [
      { value: '', label: '...' },
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
      { value: 8, label: '8' },
      { value: 9, label: '9' },
      { value: 10, label: '10' },
      { value: 11, label: '11' },
      { value: 12, label: '12' },
      { value: 13, label: '13' },
      { value: 14, label: '14' },
      { value: 15, label: '15' },
      { value: 16, label: '16' },
      { value: 17, label: '17' },
      { value: 18, label: '18' },
      { value: 19, label: '19' },
      { value: 20, label: '20' },
      { value: 21, label: '21' },
      { value: 22, label: '22' },
      { value: 23, label: '23' },
      { value: 24, label: '24' },
      { value: 25, label: '25' },
      { value: 26, label: '26' },
      { value: 27, label: '27' },
      { value: 28, label: '28' },
      { value: 29, label: '29' },
      { value: 30, label: '30' },
      { value: 31, label: '31' },
    ];
  }

  public getJewishDayOptions() {
    this.jewishDayOptions = [
      { value: '', label: '...' },
      { value: 1, label: 'א' },
      { value: 2, label: 'ב' },
      { value: 3, label: 'ג' },
      { value: 4, label: 'ד' },
      { value: 5, label: 'ה' },
      { value: 6, label: 'ו' },
      { value: 7, label: 'ז' },
      { value: 8, label: 'ח' },
      { value: 9, label: 'ט' },
      { value: 10, label: 'י' },
      { value: 11, label: 'יא' },
      { value: 12, label: 'יב' },
      { value: 13, label: 'יג' },
      { value: 14, label: 'יד' },
      { value: 15, label: 'טו' },
      { value: 16, label: 'טז' },
      { value: 17, label: 'יז' },
      { value: 18, label: 'יח' },
      { value: 19, label: 'יט' },
      { value: 20, label: 'כ' },
      { value: 21, label: 'כא' },
      { value: 22, label: 'כב' },
      { value: 23, label: 'כג' },
      { value: 24, label: 'כד' },
      { value: 25, label: 'כה' },
      { value: 26, label: 'כו' },
      { value: 27, label: 'כז' },
      { value: 28, label: 'כח' },
      { value: 29, label: 'כט' },
      { value: 30, label: 'ל' },
    ];
  }




  getJewishYearOptions() {
    this.jewishYearOptions = [
      { value: '', label: '...' },
      { value: 5771, label: 'תשעא' },
      { value: 5772, label: 'תשעב' },
      { value: 5773, label: 'תשעג' },
      { value: 5774, label: 'תשעד' },
      { value: 5775, label: 'תשעה' },
      { value: 5776, label: 'תשעו' },
      { value: 5777, label: 'תשעז' },
      { value: 5778, label: 'תשעח' },
      { value: 5779, label: 'תשעט' },
      { value: 5780, label: 'תשפ' },
      { value: 5781, label: 'תשפא' },
      { value: 5782, label: 'תשפב' },
      { value: 5783, label: 'תשפג' },
      { value: 5784, label: 'תשפד' },
      { value: 5785, label: 'תשפה' },
      { value: 5786, label: 'תשפו' },
      { value: 5787, label: 'תשפז' },
      { value: 5788, label: 'תשפח' },
      { value: 5789, label: 'תשפט' },
      { value: 5790, label: 'תשצ' }
    ];
  }


  public getList(listName: string) {
    return this.toraxHttp.get(`${this.url}/get${listName}`);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  public onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}
