
import { MaterialModule } from '../material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReportsCenterComponent } from './reports-center.component';
import { ReportsCenterRoutes } from './reports-center.routing';
import { ReportsCenterData } from './reports-center.data';
import { PaymentsPlansReportsDlg } from './rc-dialogs/rc-payments-plans.cmp';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { AmountInputRangeModule } from '../components/amountInput-range/amount-input-range.mdl';
import { DateInputModule } from '../components/dateInput/date-input.mdl';
import { DateMonthInputModule } from '../components/dateMonth-input/dateMonth-input.mdl';
import { DateInputRangeModule } from '../components/dateInput-range/date-input-range.mdl';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';
import { AuthGuardMdl } from '../_guards/auth.guard.mdl';
import { ContactsPlansAndPaymentsReportsDlg } from './rc-dialogs/rc-contacts-payments-plans.cmp';
import { InputInfoModule } from '../components/input-info/input-info.module';
import { PaymentsTreatReportsDlg } from './rc-dialogs/rc-payments-treat.cmp';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ReportsCenterRoutes),
    PageHeadingModule,
    PanelBoxModule,
    PaginationModule,
    AmountInputRangeModule,
    DateInputModule,
    DateMonthInputModule,
    DateInputRangeModule,
    FooterEditButtonsMdl,
    AutoCompleteModule,
    CustomToolTipModule,
    MaterialModule,
    AuthGuardMdl,

    InputInfoModule
  ],
  declarations: [
    ReportsCenterComponent,
    PaymentsPlansReportsDlg,
    PaymentsTreatReportsDlg,
    ContactsPlansAndPaymentsReportsDlg
  ],
  exports: [
  ],
  providers: [
    ReportsCenterData
  ],
  entryComponents: [
  ]
})
export class ReportsCenterMdl { }

