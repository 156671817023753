<form  *ngIf="objectsListResult" [formGroup]="searchGroupsListForm" novalidate>
    <panel-box title="קבוצות" [showClose]="false" [showCollapse]="false">
        <span class="panel-tools">
		<panel-box-btn (btnClick)="getGroups()" icon="fa-refresh"></panel-box-btn>
		<a [routerLink]="[{outlets: {popup: ['add']}}]"><i class="fa fa-plus"></i></a>
	</span>
        <div class="panel-content">
            <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>שם קבוצה</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of objectsListResult.list">
                        <td>
                            <a 
                            [routerLink]="['', {outlets: {popup: ['groups', c.groupID]}}]">{{ c.groupID }}</a>
                        </td>
                        <td>
                            {{ c.groupName }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination [currentPage]="objectsListResult.page" [pagesCount]="objectsListResult.pagesCount" (change)="changePage($event)"></pagination>
        </div>
    </panel-box>
</form>