import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertCenterService } from './alert-center.svc';
import { AlertCenterComponent } from './alert-center.cmp';
import { AlertCenterItemComponent } from './alert-center-item.cmp';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    imports: [CommonModule, MaterialModule],
    exports: [AlertCenterComponent],
    declarations: [AlertCenterComponent, AlertCenterItemComponent],
    providers: [AlertCenterService],
})
export class AlertCenterModule { }
