
import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ServicesData } from './../../services/services.data';

import { IvrAssignmentUserTask } from './ivr-assignment-user-task';

export class PaymentOption { }
export class Api { }



@Component({
    selector: 'ivr-assignment-user-task-edit',
    templateUrl: './ivr-assignment-user-task-edit.tmpl.html'
})

export class IvrAssignmentUserTaskEdit implements AfterViewInit {
    constructor(
        public router: Router,
        public servicesData: ServicesData,
        private sanitizer: DomSanitizer,
        private cdRef:ChangeDetectorRef

    ) {

    }

    imageFile: File;
    imgUrl;

    @Input('contactID') public contactID?: number;
    @Input('ivrAssignmentUserTask') public ivrAssignmentUserTask: IvrAssignmentUserTask;
    @Input('ivrAssignmentUserTaskID') public id: number;
    @Input('ivrAssignmentUserTaskForm') public ivrAssignmentUserTaskForm: FormGroup;




    onSelectFile(event) {
        const file = (event.target as HTMLInputElement).files[0];
        this.ivrAssignmentUserTaskForm.patchValue({
            imageFile: file
        });
        this.cdRef.detectChanges();
        this.ivrAssignmentUserTaskForm.get('imageFile').updateValueAndValidity();
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          this.imgUrl = event.target.result;
        } 
    }
 
    ngAfterViewInit(): void {
        setTimeout(() => {   
            let ib = this.ivrAssignmentUserTaskForm.controls['imageBytes'];
            if(ib){                
                this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${ib.value}`);
            }
        }, 200);

        //  throw new Error('Method not implemented.');
    }


}





