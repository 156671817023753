import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { TishInvitedsList } from './tishInviteds-list.cmp';
import { TishInvitedMain } from './tishInvited/tishInvited-main.cmp';
import { TishInvitedDetails } from './tishInvited/tishInvited-details.cmp';


export const TishInvitedsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'tishInviteds', component: TishInvitedsList, data: {  roles: "tishInviteds",  title: 'רשימת זימונים לאירוע' } },
    { canActivate: [AuthGuard], path: 'tishInviteds/add', component: TishInvitedMain, data: { mode: 'edit', roles: "tishInviteds", title: 'הוסף זימון' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'tishInviteds/:tishInvitedID', component: TishInvitedMain, data: { mode: 'edit',  roles: "tishInviteds",  title: 'פרטי זימון' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'tishInviteds/:tishInvitedID/edit', component: TishInvitedMain, data: { mode: 'edit', roles: "tishInviteds",  title: 'עריכת פרטי זימון' }, outlet: 'popup' }
];


