import { TransferTasksMain } from './transfer-tasks-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { PaymentMain } from '../payments/payment/payment-main.cmp';

export const TransferTasksRoutes: Routes = [
  { canActivate: [AuthGuard], path: 'transferTasks', component: TransferTasksMain, data: {  roles: "TransferTasks",  title: 'ייבוא העברות' }},

   { canActivate: [AuthGuard], path: 'payments/addTransferPayment/:contactID/:transferTaskID', component: PaymentMain, data: { mode: 'edit', roles: "payments", title: 'הוסף תשלום' }, outlet: 'popup' },

];
