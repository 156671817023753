<style>
  ngx-mat-timepicker-dialog {
    direction: ltr !important;
  }
</style>
<form [formGroup]="emergencySquadQueueForm" novalidate (ngSubmit)="save()" *ngIf="emergencySquadQueue">
  <div class="modal-header">
    <h1>עריכת פרטי תור [{{ (id > 0 ? id : 'חדש') }}] </h1>
  </div>
  <div class="modal-body">
    <div *ngIf="emergencySquadQueue" class="example-form">
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי תור</legend>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div style="width: 250px !important;" class="input-group mb-3">
              <label>שם איש:</label>
              <h3> {{ emergencySquadQueue.emergencySquadMemberName }}</h3>
            </div>
          </div>
          <!-- <date-time-input [withBorder]="true" [dateControl]="emergencySquadQueueForm.controls.from" [withLabel]="false" [timeControl]="null"></date-time-input> -->
          <date-time-input [timeControl]="emergencySquadQueueForm.controls.fromTime"  [dateControl]="emergencySquadQueueForm.controls.from"  [withLabel]="true"></date-time-input>
          <!-- <time-input [withBorder]="true" [timeControl]="emergencySquadQueueForm.controls.fromTime"
            [withLabel]="false"></time-input> -->

          <!-- <input [ngxMatTimepicker]="pickerFrom" formControlName="from" >
                    <input [ngxMatTimepicker]="pickerTill" formControlName="till" >
                     <ngx-mat-timepicker-field color="warn"
                    [format]="24"
                    [defaultTime]="'00:00'"
                    [appendToInput]="true"
                    #pickerFrom></ngx-mat-timepicker-field>

                    <ngx-mat-timepicker-field color="warn"
                     [format]="24"
                     [defaultTime]="'00:00'"
                     [appendToInput]="true"
                     #pickerTill></ngx-mat-timepicker-field> -->

          <!-- <input matInput [matDatepicker]="picker" /> -->
          <!-- <input matInput class="form-control time-picker" type="time" id="appt" name="appt" formControlName="from" />
          <mat-datepicker #picker></mat-datepicker> -->

        </fieldset>
      </div>
    </div>
  </div>
  <footer-edit-buttons [dataProvider]="emergencySquadQueuesData" [router]="router" [id]="id"
    [disabledDeleteButton]="id <= 0" [modelFormGroup]="emergencySquadQueueForm"></footer-edit-buttons>

</form>
