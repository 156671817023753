
import { Component, Input, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactsData } from './contacts.data';


@Component({
  selector: 'contact-details-dlg',
  templateUrl: './contacts-details-dlg.tmpl.html'
})


export class ContactDetailsDlg {


  dataContact: any;

  constructor(
    public matDialogRef: MatDialogRef<ContactDetailsDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public contactsData: ContactsData,) {
    if (typeof data === "number") {
      this.contactsData.get(data).subscribe(x => {
        this.dataContact = x;
      });
    } else {
      this.dataContact = data;
    }
  }

}
