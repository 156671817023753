import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'dashboard-tracking-account',
    templateUrl: 'dashboard-tracking-account.tmpl.html',
    styleUrls: ['./../dashboard.scss']
})  
export class DashboardTrackingAccount {

    @Input('accountGrp') accountGrp: any;

    @Output() goToPaymentsClick = new EventEmitter<any>();

    constructor(
    ) {
   
    }

    goToPayments() {
        this.goToPaymentsClick.emit();
    }
}
