﻿<div *ngIf="searchTransactionsListForm" [formGroup]="searchTransactionsListForm">
  <div class="multi-search" *ngIf="!payMethodID && !collectionBuilderID">
    <div class="form-row" fxLayout="row">
      <div class="input-group mb-3">
        <label>מסוף...</label>
        <select class="form-control" (change)="indexService.selectionChanged($event)" placeholder="מסוף..."
          formControlName="termID">
          <option value="">...</option>
          <option *ngFor="let api of servicesData.Apis" [value]="api.value">{{ api.name }}
          </option>
        </select>
      </div>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
        *ngIf="servicesData.StatusResponses" (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchTransactionsListForm.controls.statusResponses" [placeholder]="'סטטוס גביה...'"
        [list]="servicesData.StatusResponses">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <auto-complete apply-enter [placeholder]="'שם איש...'" (onSelect)="indexService.selectionChanged($event)"
        [controllerName]="'Contacts'" [formGroupModel]="searchTransactionsListForm"
        [idControl]="searchTransactionsListForm.controls.contactID"
        [nameControl]="searchTransactionsListForm.controls.contactName"></auto-complete>


      &nbsp;&nbsp;
      <div style="margin: 20px 20px 0px; text-align:left; white-space: nowrap;" class="example-button-row">
        <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
            class="fa fa-filter"></i></button>
        &nbsp;
        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
            class="material-icons">clear</i></button>
        &nbsp;
      </div>
    </div>
  </div>

  <div class="example-container" *ngIf="indexService">
    <cdk-virtual-scroll-viewport itemSize="10"
      [style.height.vh]="indexService?.sourceList ? (indexService.height ? indexService.height : 65) : 5"
      style="background: rgb(243 243 244)">
      <mat-table *ngIf="indexService?.sourceList; else loading" [dataSource]="indexService.sourceList"
        style="width: 100%;" (matSortChange)="indexService.sortData($event)" class="example-table" matSort
        matSortDisableClear matSortDirection="asc">
        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="openDetails(row);" aria-label="הצג עוד...">
              <mat-icon style="color: gray;">remove_red_eye</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="transactionID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="מזהה">
            {{ row.transactionID }} </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="billingDate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך
                            גביה
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="תאריך גביה">
                            {{ row.billingDate }} </mat-cell>
                    </ng-container> -->
        <ng-container matColumnDef="amountToCollect">
          <mat-header-cell *matHeaderCellDef mat-sort-header> סכום </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סכום">
            {{ row.amountToCollect | number}} {{ row.currencyIcon }} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="executionDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך
            ביצוע
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תאריך ביצוע">
            {{row.executionDate | date: 'dd-MM-yyyy'}} <u>{{row.executionDate | date:
              'hh:mm'}}</u>
            <br>
            {{ row.jewishExecutionDate}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="statusResponse">
          <mat-header-cell *matHeaderCellDef mat-sort-header> סטטוס גביה
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="סטטוס גביה">
            {{ row.statusResponseName }} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="messages">
          <mat-header-cell *matHeaderCellDef> תגובת אשראי
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תגובת אשראי">
            {{ (row.messages ? row.messages.substring(0, 60) : '') }}
            <br *ngIf="row.messages">{{ (row.errors ? row.errors.substring(0, 60) : '') }}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="severalAttempts">
          <mat-header-cell *matHeaderCellDef mat-sort-header> מס'
            נסיונות
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="מס' נסיונות">
            {{ row.severalAttempts }} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="responseTransId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
            טרמינל
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="מזהה טרמינל">
            {{ row.responseTransId }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="collectionBuilderID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> יחידה
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="יחידה">
            {{ row.collectionBuilderID }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="paymentsIds">
          <mat-header-cell *matHeaderCellDef > תשלומים </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תשלומים">
            <span *ngFor="let pId of row.paymentsIdsList">
              {{pId}}<br>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="payMethodID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> אמצעי תשלום </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="אמצעי תשלום">
            {{row.methodTitle}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="termID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> מסוף </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="מסוף">
            {{row.termName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sourceTransactionIDWhenRefund">
          <mat-header-cell *matHeaderCellDef mat-sort-header> שונות
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="שונות">
            <span *ngIf="row.sourceTransactionIDWhenRefund"> החזרת פעולה-
              {{ row.sourceTransactionIDWhenRefund }}</span>
            <span *ngIf="row.voidedStatusResponseName"> בוטל ב-
              {{ row.voidedDate | date:'dd/MM/yyyy' }}{{ row.voidedStatusResponseName
              }}</span>
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>

      <ng-container matColumnDef="groupHeader">
          <!-- <td colspan="999"  [ngStyle]="{'height': (!group.value) ? '0px' : 'height: 20px !IMPORTANT;'}" mat-cell *matCellDef="let group">
            <strong *ngIf="!group.value">{{(!group.value ? 'ממתין' : '')}}</strong>
          </td> -->
          <td colspan="999" style="height: 20px !IMPORTANT" mat-cell *matCellDef="let group">
            <strong>{{(group.value ? 'ממתין &#8681;' : 'תאריכים קודמים &#8681;' )}}</strong>
          </td>
        </ng-container>
        <ng-container matColumnDef="groupReducer">
          <td colspan="1" [ngStyle]="{'height': (group.value) ? '0px' : 'height: 20px !IMPORTANT;'}" mat-cell
            *matCellDef="let group">

          </td>
        </ng-container>

        <tr mat-row class="clickable" style="background-color: #ceced2 !important;"
          [ngStyle]="{'height': ( indexService.isGroup) ? '0px' : 'height: 20px !IMPORTANT;'}"
          *matRowDef="let row; columns: ['groupReducer','groupHeader']; when: indexService.isGroup">
        </tr>
      </mat-table>
    </cdk-virtual-scroll-viewport>
  </div>

  <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
    [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
  </mat-paginator>
</div>
