import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatList } from '@angular/material/list';
import { ContactsData } from 'src/app/contacts/contacts.data';
import { ServicesData } from '../services/services.data';




@Component({
    templateUrl: './contacts-recipts-dlg.tmpl.html'
})

export class ContactReciptsDlg implements OnInit {
    public formData: FormGroup;
    payments: any[];
    mergePayments: boolean = false;
    //public data: any;
    @ViewChild('paymentsList') paymentsList: MatList;


    constructor(
        public matDialogRef: MatDialogRef<ContactReciptsDlg>,
        @Inject(MAT_DIALOG_DATA) public data: number, //contactID       
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        public contactsData: ContactsData
    ) { }


    // selectionChanged(){
    //     console.log(this.formData);
    // }

    ngOnInit(): void {
        this.initForm();
       
    }


    private initForm(): void {
        this.formData = this.formBuilder.group({
            contactID: [this.data, Validators.required],
            paymentsIds: ['', Validators.required],
            mergePayments: [null, Validators.required],
            withoutReceipts: [],
        });
    }

    onAreaListControlChanged(e){        
        let paymentsIds = (<any>this.paymentsList).selectedOptions.selected.map(item => item.value);
        this.formData.controls['paymentsIds'].setValue(paymentsIds);  
    }

    toggleChange(e) {        
        let withoutReceipts = this.formData.controls['withoutReceipts'].value;
        this.contactsData!.getContactPayments(this.data, withoutReceipts)
        .subscribe((res: any) => {
            this.payments = res;
        });
    }

    onNoClick(): void {
        this.matDialogRef.close();
    }


    selectionChanged(e) {
        this.mergePayments = !this.mergePayments;
        this.formData.controls['mergePayments'].setValue(this.mergePayments);
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}

