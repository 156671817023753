<form  *ngIf="userAddForm && user" class="example-form" [formGroup]="userAddForm" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>עריכת פרטי משתמש
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>

      </button>
    </h2>
    <h3>חדש</h3>

  </div>
  <mat-dialog-content fxLayout="row">
    <div fxLayout="column"  style="margin-left: 10px;">
      <div fxLayout="row">
        <mat-form-field>
          <mat-label>שם משתמש:</mat-label>
          <input matInput apply-enter formControlName="userName">
        </mat-form-field>&nbsp;
        <mat-form-field>   
          <mat-label>סיסמה:</mat-label>
          <input matInput apply-enter formControlName="password">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field>
          <mat-label>שם פרטי:</mat-label>
          <input matInput apply-enter formControlName="firstName">
        </mat-form-field>&nbsp;
        <mat-form-field>   
          <mat-label>שם משפחה:</mat-label>
          <input matInput apply-enter formControlName="lastName">
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field>
          <mat-label>מחלקה/מדינה:</mat-label>
          <mat-select placeholder="מחלקה/מדינה..." formControlName="department">
            <mat-option *ngFor="let ud of servicesData.UserDepartments" [value]="ud.value">
              {{ud.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>&nbsp;
        <mat-form-field *ngIf="subjects">
          <mat-select apply-enter (selectionChange)="subjectsSelectionChange($event)" placeholder="משוייך לנושאים..."
              [formControl]="userAddForm.controls.userCommitmentSubjects" multiple>
              <mat-option *ngFor="let cs of subjects" [value]="cs.value">
                  {{cs.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      </div>

    </div>
 
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <button [disabled]="userAddForm.invalid" class="btn btn-primary navbar-btn" [mat-dialog-close]="userAddForm"><i
        class="fa fa-save"></i> שמור </button>
  </mat-dialog-actions>