import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServicesData } from '../services/services.data';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef } from '@angular/core';
import { TransferTasksData } from './transfer-tasks.data';
import { TransferTasksPrintDetailsMdl } from './transfer-task/transfer-task';
import { AlertService } from './../components/alert/alert.svc';


@Component({
  templateUrl: './transfer-tasks-exls-dlg.tmpl.html'
})
export class TransferTasksExlsDlg implements OnInit {
  public formData: FormGroup;

  shtibelMode: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private transferTasksData: TransferTasksData,
    public servicesData: ServicesData,
    private cdRef: ChangeDetectorRef,
    private alertSvc: AlertService,
  ) { }


  selectedFile: File = null;
  private newBlogForm: FormGroup;

  ngOnInit() {
    this.initForm();

  }

  private initForm(): void {
    this.formData = this.formBuilder.group({
      excelFile: [, Validators.required],
      associationAccountNumber: [, Validators.required],
    });
  }

  public isValid(): boolean {
    return (this.formData && this.formData.valid);
  }

  associationAccountNumber: string;
  fileName: string;
  excelFile: File;

  uploadXls(): void {
    let ifle = this.formData.controls.excelFile;
    if (ifle && ifle.value) {
      this.fileName = ifle.value.name;
      this.associationAccountNumber = this.formData.controls['associationAccountNumber'].value;
      let fd = new FormData();
      fd.append('excelFile', ifle.value, this.fileName);
      this.transferTasksData.saveFile(fd, 'Pagi', this.fileName, this.associationAccountNumber).subscribe(
        (res: any) => {

          if ((<any>res).key) {
            this.alertSvc.info((<any>res).value);
          }
          else {
            this.alertSvc.error((<any>res).value);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }


  onSelectFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.formData.patchValue({
      excelFile: file
    });
    this.cdRef.detectChanges();
    this.formData.get('excelFile').updateValueAndValidity();
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {

      //this.imgUrl = event.target.result;
    }
  }


}
