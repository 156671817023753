import { CommonModule } from '@angular/common';
import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@Pipe({
    name: 'currency'
})

export class CurrencyPipe implements PipeTransform {
    public transform(value: number): any {
        if (!value) return value;

        if (value === 1)
            return '$';
        if (value === 2)
            return '€';
        if (value === 3)
            return '₪';
        if (value === 4)
            return '£';
        if (value === 5)
            return '+';
        // if (value === 6)
        //     return '$a';
        if (value === 7)
            return '$c';
    }
}




@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [CurrencyPipe],
    declarations: [CurrencyPipe]
})
export class CurrencyPipeMdl { }
