<div fxLayout="column">

<div style="text-align:left">
  <button style="float: left" mat-dialog-close aria-label="Close dialog" title="יציאה"
  class="btn  btn-circle.btn-lg">
  <i class="fa fa-times"></i>
</button>
</div>
  <contactGroups-list fxLayout="row" [contactID]="data">
  </contactGroups-list>
</div>
