import { Component, OnInit } from '@angular/core';
import { DashboardDirectorReportsDataService } from './dashboard-director-reports.data';

@Component({
  selector: 'dashboard-director-reports',
  templateUrl: './dashboard-director-reports.tmpl.html',
  styleUrls: ['dashboard-director-reports.scss']
})
export class DashboardDirectorReportsComponent implements OnInit {
  constructor(
    private dashboardDirectorReportsDataService: DashboardDirectorReportsDataService
  ) {

  }


  contributionData: any;
  holdingData: any;
  designatedContributionData: any;
  buildingFundData: any;
  shoteffData: any;
  ordersIvrData: any;
  ichudData: any;

  subjects = [
    {subject:1, balance:null , subjectName: ''},
    // {subject:2, balance:null , subjectName: ''},
    {subject:3, balance:null , subjectName: ''},
    {subject:4, balance:null , subjectName: ''},
    {subject:5, balance:null , subjectName: ''},
    {subject:6, balance:null , subjectName: ''},
    {subject:7, balance:null , subjectName: ''}
  ];

  loadData() {

    this.subjects.forEach((element, index) => {
      this.dashboardDirectorReportsDataService.getDirectorReports(element.subject)
        .subscribe((x:any) => {
          element.balance = x.shekelBalance;
          element.subject = x.commitmentSubject;
          element.subjectName = x.commitmentSubjectName;
        });
    });

  }

  ngOnInit() {
    this.loadData();
  }
}
