//import { TextMaskModule } from 'angular2-text-mask';
import { DirectivesMdl } from './../directives/directives-module';
import { DateInput } from './date-input.cmp';

import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DirectivesMdl
    ],
    exports: [DateInput],
    providers: [DatePipe],
    declarations: [DateInput]
})
export class DateInputModule { }

