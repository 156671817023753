

<div [formGroup]="searchDepositsListForm">
  <div class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">
      <div class="input-group mb-3">
        <label>סוג...</label>
        <mat-select apply-enter class="form-control" (selectionChange)="indexService.selectionChanged($event)"
          placeholder="סוג..." [formControl]="searchDepositsListForm.controls.depositType">
          <mat-option *ngFor="let ht of servicesData.DepositTypes" [value]="ht.value">
            {{ht.name}}
          </mat-option>
        </mat-select>
      </div>
      <span fxFlex class="example-fill-remaining-space"></span>
      <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
        <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
            class="fa fa-filter"></i></button>
        &nbsp;
        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
            class="material-icons">clear</i></button>
        </div>
    </div>
  </div>
  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="true" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="select">
            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
              <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox apply-enter (click)="$event.stopPropagation()" (change)="$event ? rowToggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'deposits', row.depositID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['deposits', row.depositID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item (click)="depoditReport(row.depositID)">
                  <mat-icon>document-text</mat-icon>
                  <span>דו"ח להפקדה</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="depositsData" [id]="row.depositID"
                    [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="depositID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
              הפקדה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה הפקדה">
              <a [routerLink]="[ '', {outlets: {popup: [ 'deposits', row.depositID]}}]">
                {{ row.depositID }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="depositDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך">
              {{ row.depositJewishDate }} <br> {{ row.depositDate | date:'dd/MM/yyyy' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="depositType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>סוג
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סוג"> {{ row.depositTypeName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="organizationBankAccountTitle">
            <mat-header-cell *matHeaderCellDef mat-sort-header>חשבון הפקדה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="חשבון הפקדה"> {{ row.organizationBankAccountTitle }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef mat-sort-header>סכום
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סכום"> {{ row.amount |number }} {{row.currencyIcon}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef mat-sort-header>הערה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="הערה"> {{ row.comment }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="paymentsIDsList">
            <mat-header-cell *matHeaderCellDef > תשלומים </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תשלומים">
              <span *ngFor="let pId of row.paymentsIDsList" style="overflow-x: auto;">
                {{pId}},
              </span>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.depositID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
