<div fxLayout="row" fxLayoutAlign="space-between stretch">

  <div  class="p-0  col-lg-12" style="padding: 0;">
    <div class="row" style="background-color: white;">
      <div style="padding:12px;" class="col-sm-12">
        <div class="col-sm-5">
          <h1 style="margin-top: 0;">
            מערכת יארצייטים
          </h1>
        </div>
        <div class="col-sm-7 text-left">
          <button style="background-color: navy;"
            [ngStyle]="{'scale': (viwYarzeitsList == true) ? '1.2' : '1','font-weight': (viwYarzeitsList == true) ? 'bold' : 'initial'}"
            class="btn btn-primary" (click)="viwYarzeitsList = !viwYarzeitsList; viwYarzeitAffiliationsList = false">
            {{(viwYarzeitsList? 'הסתר רשימת נפטרים' : 'הצג רשימת נפטרים')}}
          </button>
          &nbsp;&nbsp;
          <button style="background-color: navy;"
            [ngStyle]="{'scale': (viwYarzeitAffiliationsList == true) ? '1.2' : '1','font-weight': (viwYarzeitAffiliationsList == true) ? 'bold' : 'initial'}"
            class="btn btn-primary"
            (click)="viwYarzeitAffiliationsList = !viwYarzeitAffiliationsList; viwYarzeitsList = false">
            {{(viwYarzeitAffiliationsList? 'הסתר יארצייטים' : 'הצג יארצייטים')}}
          </button>
          &nbsp;&nbsp;

        </div>
      </div>
    </div>
    <div class="modal-header" >
      <yarzeits-list *ngIf="viwYarzeitsList"></yarzeits-list>
    </div>
    <div>
      <yarzeit-affiliations-list *ngIf="viwYarzeitAffiliationsList"></yarzeit-affiliations-list>
    </div>
  </div>
</div>
