
import { Component, OnInit, Input } from '@angular/core';


import { TransactionsData } from './transactions.data';


@Component({
	selector: 'transactions-last-statusses',
	templateUrl: './transactions-last-statusses.tmpl.html',
	styleUrls:['./transactions.scss']
})

export class TransactionsLastStatusses implements OnInit {

	constructor(
		private transactionsData: TransactionsData
	) {
	}

	data: any;
	@Input('payMethodID') public payMethodID?: number;
	@Input('paymentID') public paymentID?: number;

	ngOnInit() {
		this.getLastTransactions();
	}


	getLastTransactions() {
    if (this.paymentID) {
			return this.transactionsData!.getLastTransactionsForPayment(this.paymentID)
				.subscribe((data: any) => {
					this.data = data;
				});
		}
		else if (this.payMethodID) {
			return this.transactionsData!.getLastTransactions(this.payMethodID)
				.subscribe((data: any) => {
					this.data = data;
				});
		}

	}

}
