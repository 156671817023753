import { ContactCompensationsData } from './../contactCompensations.data';
import { ContactsData } from './../../contacts.data';
import { AlertService } from './../../../services/alert.service';
import { ContactCompensationEditDlg } from './contactCompensation-edit-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ContactCompensation } from './contactCompensation';
import { Observable } from 'rxjs';

@Component({
    selector: 'contactCompensation-details',
    templateUrl: './contactCompensation-details.tmpl.html'
})

export class ContactCompensationDetails {

    constructor(
        private contactCompensationsData: ContactCompensationsData,
        public dialog: MatDialog
    ) {

    }
    @Output() contactCompensationChange = new EventEmitter<number>();
    @Output() onEditClick = new EventEmitter<number>();
    @Input('contactCompensation') public contactCompensation: ContactCompensation;

    editContactCompensation(cg) {
        this.onEditClick.emit(cg);
    }

    // private openAddOrEditContactCompensation(contactCompensationID: number = 0): void {      
    //     const dialogRef = this.dialog.open(ContactCompensationEditDlg, {
    //         data: { contactId: this.contactCompensation.contactID, id: this.contactCompensation.contactCompensationID, contactCompensation: this.contactCompensation }
    //     });


    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result && result.valid) {
    //             this.saveContactCompensation(result);
    //         }
    //     });
    // }

    // private saveContactCompensation(contactCompensationForm: FormGroup) {
    //     this.contactsData.update(contactCompensationForm.value)
    //         .subscribe((pm: any) => {
    //             return true;
    //         })
    // }

}



