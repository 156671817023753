import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AfterViewInit, Component, OnInit, ChangeDetectorRef, Inject, ViewEncapsulation } from '@angular/core';
import * as d3Scale from 'd3-scale';



@Component({
  selector: 'dashboard-marbe-control',
  templateUrl: 'dashboard-marbe-control.component.html',
  encapsulation: ViewEncapsulation.None
  // styleUrls: ['dashboard-marbe.component.scss', 'dashboard-marbe-control.component.scss'],


})
export class DashboardMarbeControlComponent implements OnInit, AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<DashboardMarbeControlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdref: ChangeDetectorRef,
  ) {
  }

  single: any[];
  view: any[] = [300, 300];

  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  legendPosition: string = 'bottom';

  colorScheme = {
    domain: ['Magenta', 'Blue', '#F0F8FF', '#FAEBD7', '#0000FF', '#7FFFD4', '#F0FFFF', '##0000FF', '#FFE4C4', '#808080', '#00FF00', 'Cyan', 'Yellow']
  };
  close() {
    this.dialogRef.close();
  }
  pieChartSourse: any[];


  ngOnInit() {
  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }
}
