
import { ServicesData } from './../../services/services.data';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DashboardGroupsSpecialDataService } from './dashboard-groups-special.data';
import { Group } from 'src/app/contacts/contact/contact';


@Component({
  selector: 'dashboard-groups-special',
  templateUrl: './dashboard-groups-special.tmpl.html',
  styleUrls: ['./dashboard-groups-special.scss'],
})
export class DashboardGroupsSpecialComponent implements OnInit {
  userName: string;
  model = new Array();
  constructor(
    private dashboardGroupsSpecialDataService: DashboardGroupsSpecialDataService,
    public servicesData: ServicesData,
  ) {
  }



  constructSrc(hexVal, size) {
    const constructedVal = "https://icongr.am/material/hexagon.svg?size=" + size + "&color=" + hexVal;
    return constructedVal;

  }
  signUp() {
    console.log("signing up!")
  }
  resumeRole() {
    console.log("resuming your role")
  }
  groups: Group[];

  load() {
    this.dashboardGroupsSpecialDataService.getCampignGroups(2).subscribe((x: any) => {
      this.groups = x;
    });
  }


  ngOnInit() {
    this.load();
  }
}
