import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { ShtibelMain } from './shtibel/shtibel-main.cmp';
import { HoldingsMdl } from './../holdings/holdings.mdl';
import { AutoCompleteInputModule } from './../components/auto-complete-input/auto-complete-input.mdl'; 
import { ShtibelsList } from './shtibels-list.cmp';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './../material.module';
import { AuthGuardMdl } from './../_guards/auth.guard.mdl';
import { ShtibelsRoutes } from './shtibels.routing'; 
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';

import { ShtibelsData } from './shtibels.data'; 
import { ShtibelDetails } from './shtibel/shtibel-details.cmp'; 
import { PhonePipeMdl } from '../components/pipes/phone-pipe';
import { ShtibelEdit } from './shtibel/shtibel-edit.cmp'; 


@NgModule({
	imports: [
		CommonModule, 
		AuthGuardMdl,
		FormsModule,
		RouterModule.forChild(ShtibelsRoutes),
		AutoCompleteModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		PhonePipeMdl,
		MaterialModule,
		AutoCompleteInputModule,
		MainFormModalModule

	],
	declarations: [ 
		ShtibelEdit,
		ShtibelDetails, 
		ShtibelsList,
		ShtibelMain
	],
	exports: [ 
		ShtibelEdit,
		ShtibelDetails, 
		ShtibelsList,
		ShtibelMain
	],
	providers: [
		ShtibelsData
	]
})
export class ShtibelsMdl { }

