import { Contact } from './contact/contact';
import { AlertService } from '../components/alert/alert.svc';
import { ContactsSearchInput } from '../components/contacts-search-input/contacts-search-input.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Country } from '../countries/country/country';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ContactsData } from './contacts.data';
import { ObjectsListResult } from '../services/objects-list-results';
import { ServicesData } from '../services/services.data';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { ToraxHttp } from '../utils/custom-http.svc';
import { SubscriptionLike } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { IndexService } from '../services/index-service';


@Component({
  selector: 'contacts-list-dashboard',
  templateUrl: './contacts-list-dashboard.tmpl.html',
  styleUrls: ['./contacts-list.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactsListDashboard implements OnInit {

  constructor(
    private contactsData: ContactsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private activatedRoute: ActivatedRoute,
    private toraxHttp: ToraxHttp,
    private cdref: ChangeDetectorRef,
    private alert: AlertService,
    private router: Router
  ) {
    this.selection = new SelectionModel<any>(true);
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/payMethods') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  orderButtonIsChicked: boolean = false;
  public selectionRow: SelectionModel<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('searchInp') searchInp: ContactsSearchInput;
  @ViewChild('cityID') cityID: AutoCompleteComponent;
  @ViewChild('shtibelID') shtibelID: AutoCompleteComponent;
  @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
  public selection = new SelectionModel<number>(true, []);
  stopLoadingPoints: boolean = false;
  indexService: IndexService;
  actualMinusHeight = '130';
  defaultvalues;
  groupingColumn = '';
  groupingOrderColumn = '';
  searchContactsListForm: FormGroup;
  displayedColumns: string[] = ['lastName', 'phoneNumber1'];
  contactIdWhoIsOpen?: number = null;
  contactsCount: number = 0;
  countries: Country[];
  drawerMode: 'side' | 'over';
  searchInputControl: FormControl = new FormControl();
  selectedContact: Contact;
  groupID;
  groupPriorities;
  groupSubjectsAsStr;
  selectedRowIndex: number;

  setContactIdWhoIsOpen(contactID) {
    this.contactIdWhoIsOpen = contactID;
  }



  // currentIndex(index) {
  //   console.log(index);
  //   if (index >= this.indexService.sourceList.length) {
  //     this.searchContactsListForm.controls['page'].setValue(this.page + 1);
  //     this.indexService.getList();
  //   } else if(index <= 0 && this.indexService.page > 1) {
  //     this.searchContactsListForm.controls['page'].setValue(this.page - 1);
  //     this.indexService.getList();
  //   }
  // }

  // public getContacts() {
  //   this.searchContactsListForm.controls['groupID'].setValue(this.groupID);
  //   this.searchContactsListForm.controls['groupPriorities'].setValue(this.groupPriorities);

  //   this.isLoadingResults = true;
  //   sessionStorage.setItem(this.router.url, JSON.stringify(this.searchContactsListForm.value));
  //   return this.contactsData!.getGroupContacts(this.searchContactsListForm.value)
  //     .subscribe((data: any) => {

  //       this.data = data;
  //       this.sourceList = data.list;
  //       this.page = data.page;
  //       this.pageRows = data.list.length;
  //       this.rowsCount = data.rowsCount;
  //       this.pagesCount = data.pagesCount;


  //       this.isLoadingResults = false;
  //       this.isRateLimitReached = false;

  //       //trick
  //       this.stopLoadingPoints = false;
  //     })
  // }

  private initForm() {
    this.searchContactsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [500],
      query: [],
      contactID: [],
      contactName: [],
      cityID: [],
      cityTitle: [],
      shtibelID: [],
      shtibelName: [],
   //   responsibleID: [],
     // responsibleName: [],
      groupID: [this.groupID],
      groupIDs: [],
      groupPriorities: [],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });
  }



  toogleContactFavorite(row: Contact) {
    this.contactsData.toogleContactFavorite(row.contactID)
      .subscribe((x: boolean) => {
        row.isFavorite = x;
        if (row.isFavorite) {
          this.alert.success(`${row.firstName} ${row.lastName} נוסף בהצלחה למועדפים`);
        } else {
          this.alert.info(`${row.firstName} ${row.lastName} הוסר מרשימת המועדפים`);
        }
      }
      )
  }


  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchContactsListForm.patchValue(frm);
    }

    this.searchContactsListForm.updateValueAndValidity();

    this.activatedRoute.params.forEach((params: Params) => {
      this.groupID = +params['groupID'];
      this.groupSubjectsAsStr = params['groupSubjectsAsStr'];
    });

    let params = [
      { key: "groupID", value: this.groupID },
      { key: "groupSubjectsAsStr", value: this.groupSubjectsAsStr },
    ];


    this.indexService = new IndexService(this.searchContactsListForm, params, this.contactsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);

    this.indexService.getList();

  }



  goToContact(row) {
    this.selectedRowIndex = this.indexService.sourceList.indexOf(row);
    this.selectedContact = row;
    this.matDrawer.toggle();
  }


  arrowDownEvent(row: any) {
    if (!this.selectedRowIndex) {
      this.selectedRowIndex = this.indexService.sourceList.indexOf(row);
    }
    let newRowIx = this.selectedRowIndex + 1;
    if (newRowIx >= this.indexService.sourceList.length) {
      if (this.indexService.page < ( this.indexService.sourceList.length/this.indexService.pageRows)) {
        this.searchContactsListForm.controls['page'].setValue(this.indexService.page + 1);
        this.indexService.getList();
      }
      return;
    }
    var nextrow = this.indexService.sourceList[newRowIx];
    this.selectedContact = nextrow;
    this.selectedRowIndex = newRowIx;
    this.matDrawer.open();
  }

  arrowUpEvent(row: any) {
    if (!this.selectedRowIndex) {
      this.selectedRowIndex = this.indexService.sourceList.indexOf(row);
    }
    let newRowIx = this.selectedRowIndex - 1;
    if (newRowIx <= 0) {
      if (this.indexService.page >= 1) {
        this.searchContactsListForm.controls['page'].setValue(this.indexService.page - 1);
        this.indexService.getList();
      }
      return;
    }
    this.selectedRowIndex = this.indexService.sourceList.indexOf(row);
    var nextrow = this.indexService.sourceList[newRowIx];
    this.selectedContact = nextrow;
    this.selectedRowIndex = newRowIx;
    this.matDrawer.open();
  }


}
