
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EmergencySquadQueue } from './emergency-squad-queue';
import { EmergencySquadQueuesData } from '../emergency-squad-queues.data';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';

@Component({

	selector: 'emergency-squad-queue-details',
	templateUrl: './emergency-squad-queue-details.tmpl.html'
})

export class EmergencySquadQueueDetails implements OnInit {
	constructor(
		private emergencySquadQueuesData: EmergencySquadQueuesData,
		private toraxHttp: ToraxHttp,
		private activatedRoute: ActivatedRoute
	) {

		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.emergencySquadQueue && id === this.emergencySquadQueue.emergencySquadQueueID) {
				this.loadEmergencySquadQueue();
			}
		});
	}


	public emergencySquadQueue: EmergencySquadQueue;
	private id: number;




	public loadEmergencySquadQueue(): void {
		this.emergencySquadQueuesData.get(this.id)
			.subscribe((c:any) => this.emergencySquadQueue = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['emergencySquadQueueID'];
			this.loadEmergencySquadQueue();
		});
	}
}
