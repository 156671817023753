import { ContactDetailsDlg } from '../../contacts/contacts-details-dlg.cmp';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';
import { ContactsLotteriesData } from '../contacts-lotteries.data';
import { ServicesData } from '../../services/services.data';
import { ToraxHttp } from 'src/app/utils/custom-http.svc'; 
import { SelectionModel } from '@angular/cdk/collections'; 
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms'; 
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort'; 
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'contacts-lotteries-pledges',
  templateUrl: 'contacts-lotteries-pledges.tmpl.html'  
})

    
export class ContactsLotteriesPledgesComponent implements OnInit, AfterViewInit {
  constructor(
    public contactsLotteriesData: ContactsLotteriesData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public _matDialog: MatDialog,
    private cdref: ChangeDetectorRef,
    private toraxHttp: ToraxHttp) {
  }
  options = {
    //timeOut: 3000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true
  };
  //displayedColumns: string[] = ['select','buttons','codeIchud'];
  displayedColumns: string[] = ['select', 'buttons', 'codeIchud', 'firstName', 'lastName', 'chapakName', 'note', 'recruitedContatID', 'updateDate', 'shtibelName', 'cityName', 'tickets', 'phoneNumber1', 'phoneNumber2' ];
  data: any[] = [];
 
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;


  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('cityID') cityID: AutoCompleteComponent;
  @ViewChild('shtibelID') shtibelID: AutoCompleteComponent;

  @Output() selectedRowsChange = new EventEmitter<any>();
  selection = new SelectionModel<number>(true, []);


  searchContactsLotteryListForm: FormGroup;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;


  openDetails(row) {
    this._matDialog.open(ContactDetailsDlg, {
      // panelClass: 'contacts-details',
      data: row
    });
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find((el: any) => el.contactID === row.contactID);
    if (found) {
      return true;
    }
    return false;
  }



  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  pageChange(e) {
    this.searchContactsLotteryListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged(e);
  }

  selectionChanged(e) {
    this.getContactsLotteries();
  }

  public clerFilter() {
    this.initForm();
    this.getContactsLotteries();
  }

  public getContactsLotteries() {
    if (this.paginator) {
      this.searchContactsLotteryListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    }
    //this.searchContactsListForm.controls['pageRows'].setValue(this.paginator.pageSize);
    this.isLoadingResults = true;
    sessionStorage.setItem(this.router.url, JSON.stringify(this.searchContactsLotteryListForm.value));
    return this.contactsLotteriesData!.getPledgesList(this.searchContactsLotteryListForm.value)
      .subscribe((data: any) => {
        this.data = data;
        this.sourceList = data.list;
        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isLoadingResults = false;
        this.isRateLimitReached = false;
      });
  }



  panelOpenState: boolean = false;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;

  initForm() {
    this.searchContactsLotteryListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      cityID: [],
      cityTitle: [],
      shtibelID: [],
      shtibelName: [],
      sumAmountRange: [],
      amountOk: [],
      amountReady: [],
      overPaids: [],
      balances: [],
      selectedRows: [],
      grp: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchContactsLotteryListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.contactID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {


    this.initForm();
     //var prms = <any>(this.activatedRoute.params);
    this.activatedRoute.params.forEach((params: Params) => {
      if(params){
        let keys = Object.keys(params);
        keys.forEach(key => {
          var prm = params[key] || "";
          if (prm) {
            let ctrl = this.searchContactsLotteryListForm.controls[key];
            if (ctrl) {
              ctrl.setValue(prm);
            }
          }
        });
      }
    });


    if (this.toraxHttp.previousUrl != "/dashboard/Lottery") {
      let item = sessionStorage.getItem(this.router.url);
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
        this.searchContactsLotteryListForm.patchValue(frm);
      }
    }

    this.getContactsLotteries();

    this.selection.isSelected = this.isChecked.bind(this);
  }


  ngAfterViewInit() {
  }

  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getContactsLotteries();
  }
  public search() {
  }
  addOrder(propertyName: string, desc: boolean = false) {
    const ordersControl = <FormArray>this.searchContactsLotteryListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      ordersControl.push(ord);
    }
  }
  pageEvent: PageEvent;
  pageSizeOptions;
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchContactsLotteryListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}