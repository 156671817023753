<div class="modal-header" *ngIf="commitmentEdit &&  commitmentEdit.commitment">
    <h1>
        {{ commitmentEdit.commitment.contactName }}
        <br>
        <small>עריכת פרטי התחייבות [{{ (commitmentEdit.id > 0 ? commitmentEdit.id : 'חדש') }}] </small>
        &nbsp;&nbsp;
        <i customToolTip [contentTemplate]="closedTemplate" style="float: left;" class="btn btn-danger fa fa-ban"
       *ngIf="commitmentEdit?.commitment?.closedDate">&nbsp;סגור</i>

       <ng-template #closedTemplate>
         <div style="border-width: 1px; background-color: white; padding: 6px; border-radius: 5px;">
           <h3 style="color: red;text-align: center; text-decoration: underline;">התחייבות נסגרה
           </h3>
           <div style="margin: 0;">
            <p>בתאריך: {{ commitmentEdit?.commitment?.closedDate | date:'dd/MM/yyyy' }}</p>
             <hr>
             <p>סטטוס: {{ commitmentEdit?.commitment?.closedStatusName }}</p>
             <hr>
             <p>הערה: {{ commitmentEdit?.commitment?.closedComment }}</p>
             <hr>
             <h3 class="label">לביטול או עריכה יש ללחוץ על 'פרטי סגירת התחייבות' בחלונית התצוגה
             </h3>
           </div>
         </div>
       </ng-template>
       </h1>
    <div style="background-color: #337ab7; float: left;  position: absolute; left: 15px;  top: 50px;">
        <span style="background-color: inherit;" class="label label-primary">
          {{ commitmentEdit.donationTypeName }}
        </span>
    </div>
</div>
<div class="modal-body" style="resize: both; overflow: auto;">
    <commitment-edit [contactId]="contactId" [id]="id" #commitmentEdit></commitment-edit>
</div>
<footer-edit-buttons *ngIf="commitmentEdit && commitmentEdit.commitment && commitmentEdit.commitmentForm"
    [onSaveGoToBack]="true" [navigateAfterSaving]="'commitments'" [dataProvider]="commitmentEdit.commitmentsData"
    [router]="commitmentEdit.router" [id]="commitmentEdit.id" [disabledDeleteButton]="false"
    [modelFormGroup]="commitmentEdit.commitmentForm">
</footer-edit-buttons>
