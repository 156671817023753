import { NotFoundComponent } from './../components/not-found/not-found.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { PaymentsList } from './payments-list.cmp';
import { Routes } from '@angular/router';
import { PaymentMain } from './payment/payment-main.cmp';

export const PaymentsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'payments', component: PaymentsList, data: {  roles: "payments",  title: 'תשלומים' } },
    { canActivate: [AuthGuard], path: 'treatmentsPayments/:accountLocation/:treatmentsDays/:currency/:relevantStatus', component: PaymentsList, data: {  roles: "payments",  title: 'תשלומים' } },
    { canActivate: [AuthGuard], path: 'payments/add', component: PaymentMain, data: { mode: 'edit', roles: "payments", title: 'הוסף תשלום' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'payments/:paymentID', component: PaymentMain, data: {  roles: "payments",  title: 'פרטי תשלום' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'payments/:paymentID/edit', component: PaymentMain, data: { mode: 'edit', roles: "payments",  title: 'עריכת פרטי תשלום' }, outlet: 'popup' },

    { canActivate: [AuthGuard], path: 'payments/dbPayments', component: PaymentsList, data: {  roles: "payments",  title: 'תשלומים' }}




];
