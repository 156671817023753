export class PayMethod {
    payMethodID: number;
    contactID: number;
    contactName: string;
    creditNumber: number;
    expiryMonth: number;
    expiryYear: number;
    paymentOption: number;
    termID?: number;
    termName: string;
    // billingType: number;
    // currency :number;
    // currencyIcon: string;

    fromRecordType?:string;
    fromRecordID?:number;
}

