import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable } from '@angular/core';
import { CollectionBuilder } from './collection-builder/collection-builder';


@Injectable()

export class CollectionBuildersData {
    private url = 'api/collectionBuilders';


    constructor(
        private toraxHttp: ToraxHttp
    ) { }


    topCollectionBuilders() {
      return this.toraxHttp.get(`${this.url}/topCollectionBuilders`);
    }

    topWaitingPayments() {
      return this.toraxHttp.get(`${this.url}/topWaitingPayments`);
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any)  {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number)  {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number)  {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(collectionBuilder: CollectionBuilder) {
        return this.toraxHttp.update (this.url, collectionBuilder);
    }

    getAdd(termID?: number, paymentID?: number) {
        return this.toraxHttp.get(`${this.url}/add${(termID != null || paymentID != null) ? `?termID=${termID}&paymentID=${paymentID}` : ``}`);
    }

    publishCreditCardsCollection(collectionBuilderID: number)  {
       // console.log('publishCreditCardsCollection clicked!', collectionBuilderID);
        return this.toraxHttp.get(`${this.url}/publishCreditCardsCollection?collectionBuilderID=${collectionBuilderID}`);
    }

    publishMasavCollection(collectionBuilderID: number, billingDate: Date, makeAsSucceeded: boolean)  {
          return this.toraxHttp.get (`${this.url}/publishMasavCollection?collectionBuilderID=${collectionBuilderID}&billingDate=${billingDate}&makeAsSucceeded=${makeAsSucceeded}`);
    }


    publishMasavRefundCollection(collectionBuilderID: number, billingDate: Date, makeAsSucceeded: boolean)  {
      return this.toraxHttp.get (`${this.url}/publishMasavRefundCollection?collectionBuilderID=${collectionBuilderID}&billingDate=${billingDate}&makeAsSucceeded=${makeAsSucceeded}`);
}


}
