import { PaymentsPlan, HoldingsSetting, ClosedPaymentsPlanMdl } from './paymentsPlan/paymentsPlan';
import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';


@Injectable()

export class PaymentsPlansData {

  private url = 'api/paymentsPlans';
  constructor(
    private toraxHttp: ToraxHttp,
  ) { }


  getList(baseQuery: any): any {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  checkWhetherToAddPayments(paymentsPlanID: number) {
    return this.toraxHttp.get(`${this.url}/checkWhetherToAddPayments/${paymentsPlanID || 0}`);
  }


  getPaymentsPlanClosedDetails(paymentsPlanID: number) {
    return this.toraxHttp.get(`${this.url}/getPaymentsPlanClosedDetails/${paymentsPlanID || 0}`);
  }

  closePaymentsPlan(closedPaymentsPlanMdl: ClosedPaymentsPlanMdl) {
    return this.toraxHttp.post(`${this.url}/closedPaymentsPlan`, closedPaymentsPlanMdl);
  }
  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }


  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  getAddCopy(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/addCopy`);
  }

  getAdd(commitmentId, contactId, paymentsPlanType) {
    return this.toraxHttp.getWithResponce(`${this.url}/add/${commitmentId}/${contactId}/${paymentsPlanType}`);
  }


  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }

  getHoldingsSetting(id: number) {
    return this.toraxHttp.get(`${this.url}/getHoldingsSetting/${id}`);
  }

  saveHoldingsSetting(holdingsSetting: HoldingsSetting) {
    return this.toraxHttp.update(`${this.url}/saveHoldingsSetting`, holdingsSetting);
  }

  update(paymentsPlan: PaymentsPlan) {
    return this.toraxHttp.update(this.url, paymentsPlan);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
}



  public updatedEvent = new EventEmitter<any>();

  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit({ id: id, type: "PaymentsPlan" });
    }
  }
}

