import { TishGroupMain } from './tishGroup/tishGroup-main.cmp';
import { TishGroupDlg } from './tishGroup/tishGroup-edit-dlg.cmp';
import { TishGroupDetails } from './tishGroup/tishGroup-details.cmp';
import { TishGroupEdit } from './tishGroup/tishGroup-edit.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { MaterialModule } from './../material.module';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { TishGroupsData } from './tishGroups.data';
import { TishGroupsList } from './tishGroups-list.cmp';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { TishGroupsRoutes } from './tishGroups.routing';
import { ModelInfoModule } from '../components/model-info/model-info.mdl';
import { InputInfoModule } from '../components/input-info/input-info.module';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(TishGroupsRoutes),
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PaginationModule,
		PanelBoxModule,
		AutoCompleteModule,
		FooterEditButtonsMdl,
		MaterialModule,
		MainFormModalModule,
    ModelInfoModule,
    InputInfoModule,
    CustomToolTipModule
	],
	declarations: [
		TishGroupsList,
		TishGroupEdit,
		TishGroupDetails,
		TishGroupDlg,
		TishGroupMain
	],
	exports: [
		TishGroupsList,
		TishGroupEdit,
		TishGroupDetails,
		TishGroupDlg,
		TishGroupMain
	],
	providers: [
		TishGroupsData
	]
})
export class TishGroupsMdl { }

