import { Commitment } from './../../commitments/commitment/commitment';


export class GroupToSort {
  level: number = 0;
  parent: GroupToSort;
  expanded: boolean = true;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}


export class Contact {
  contactID: number = 0;
  firstName: string;
  lastName: string;
  codeIchud: number;
  passport: string;
  perfix: string;
  suffix: string;
  shtibelID: number;
  shtibelName: string;
  isFavorite: boolean;
  addresses: Address[];
  phones: Phone[];
  emails: Email[];
  defaultAddressID: number | null;
  defaultPhoneID: number | null;
  defaultEmailID: number | null;
  contactGroups: ContactGroup[];
  contactEvents: ContactEvent[];
  commitments: Commitment[]
  contactName: string;
}

export class Shtibel {
  shtibelID: number;
  shtibelName: string;
  street: string;
  houseNum: number;
  aptNum: number;
  entry: string;
  zip: number;
  pob: number;
  cityID: number;
}

export class Address {
  addressID: number;
  contactID: number;
  street: string;
  houseNum: number;
  aptNum: number;
  entry: string;
  zip: string;
  pPob: number;
  cityID: number;
  stateID: number;
  countryID: number;
  forPost: string;
  addressTitle: string;
  deleted: boolean;
}

export class Phone {
  phoneID: number;
  contactID: number;
  number: string;
  type: number;
  deleted: boolean;
}

export class Email {
  emailID: number;
  contactID: number;
  address: string;
  deleted: boolean;


}

export class Group {
  groupID: number;
  groupName: string;
}

export class ContactGroup {
  contactGroupID: number;
  contactID: number;
  groupID;
  groupName: string;
  contactName: string;
  comment: string;
  priority: number;
  priorityName: string;
  // isDeleted: boolean = false;
}



export class ContactEvent {
  contactEventID: number;
  contactID: number;
  contactName: string;
  description: string;
  contactEventDate: Date;
  // isDeleted: boolean = false;
}

export class ContactEventsEditModel {
  contactID: number;
  contactName: string;
  contactEvents: any[];
}



export class ContactGroupsEditModel {
  contactID: number;
  contactName: string;
  contactGroups: any[];
}

export class ContactReciptsMdl {
  contactID: number
  paymentsIds;
  mergePayments: boolean
}
