import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { DashboardMarbesDataService } from './dashboard-marbe.data';
import { AfterViewInit, Component, OnInit, Input, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import * as d3Scale from 'd3-scale';
import { MatDialog } from '@angular/material/dialog';
import { DashboardMarbeControlComponent } from './dashboard-marbe-control.component';
import { DashboardMarbeCitiesComponent } from './dashboard-marbe-cities.component';
import { DashboardMarbeMoniesComponent } from './dashboard-marbe-monies.component';

declare const Hebcal: any;

@Component({
  selector: 'dashboard-marbe-main',
  templateUrl: 'dashboard-marbe-main.component.html',
  styleUrls: ['dashboard-marbe.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class DashboardMarbeMainComponent implements OnInit, AfterViewInit, OnDestroy {

  animal: string;
  name: string;
  routeQueryParams$: Subscription;
  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dashboardMarbesDataService: DashboardMarbesDataService,
  ) {
  }


  @ViewChild('citiesCharts') public citiesCharts: DashboardMarbeCitiesComponent;
  @ViewChild('moniesCharts') public moniesCharts: DashboardMarbeMoniesComponent;

  lineChartModel;
  model;

  styleObject(item) {
    console.log("item",item)
    let color = "";
    if (item.value > 1.3) {
      color = "rgb(73, 158, 45)";
    } else if (item.value > 1.2) {
      color = "rgb(139 189 122)";
    } else {
      color = "#f0e0c3";
    }
    let ret = `-webkit-linear-gradient(right, ${color} ${item.value*100}%  ${item.value
      }%, #fff ${item.value*100}%)`;
    return ret;
  }

  openDlg() {
    this.location.replaceState(`/marbe?dialog=true`);
    const dialogRef = this.dialog.open(DashboardMarbeControlComponent, {
      data: this.model.quantityPerUnits,
      width: '98%'
    });

    dialogRef.afterClosed().subscribe(result => {
      var snapshot = this.activatedRoute.snapshot;
      snapshot.queryParams = {};

      if (this.location.path().indexOf("/marbe") > -1) {
        this.location.replaceState(`/marbe`);
      }
    });

  }

  loadModel() {
    this.dashboardMarbesDataService.get()
      .subscribe((x: any) => {
        this.model = x;
      });
  }

  loadLineChartModel() {
    this.dashboardMarbesDataService.getLineChartModel()
      .subscribe((x: any) => {
        this.lineChartModel = [
          {
            "name": "lineChartModel",
            "series": [...x]
          }
        ];
        this.lineChartViewWidth = x.length > 10 ? x.length * 40 : 380;
        this.lineChartView = [this.lineChartViewWidth, 150];
      })
  }

  xAxisTickFormatting(e) {
    let dt = new Date(e);
    let jewisDate = Hebcal.HDate(dt).toString('h');
    let lst = jewisDate.split(' ');

    let jewishDay = lst[0].replace(/'/, '');
    let splc = lst.slice(1).slice(0, lst.length - 2);
    let jewishMonth = splc.join(' ');
    // let hebDate = hebcal.HDate(dt.getFullYear() - 1, dt.getMonth()); //TODO minus 1 ?????

    //console.log(jewishDay +' '+ jewishYear +' ' +jewishMonth);
    return jewishDay + ' ' + jewishMonth;
  }

  lineChartView: any[] = [1700, 150];
  lineChartViewWidth = 1700;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  ngOnInit() {
    this.loadData();
  }

  subscription: Subscription;
  intervalId;


  ngAfterViewInit() {
    // This is METHOD 1
    const source = interval(600000);
    this.subscription = source.subscribe(() => this.loadData());

    // This is METHOD 2
    this.intervalId = setInterval(() => {
      //console.log(x);
    }, 600000);
  }

  ngOnDestroy() {
    // For method 1
    //this.routeQueryParams$.unsubscribe();
    this.subscription && this.subscription.unsubscribe();

    // For method 2
    clearInterval(this.intervalId);
  }


  loadData() {
    this.loadModel();
    this.loadLineChartModel();
    if(this.citiesCharts){
      this.citiesCharts.loadCitiesChart();
    } ;
    if(this.moniesCharts){
      this.moniesCharts.loadMoneySums();
    } ;
  }
}
