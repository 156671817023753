import { ContactsLotteriesPledgesComponent } from './contacts-pledges-lotteries/contacts-lotteries-pledges.component';
// import { PhoneMain } from './../phones/phone/phone-main.cmp';
import { AuthGuard } from './../_guards/auth.guard'; 
import { Routes } from '@angular/router';
import { ContactsLotteriesListComponent } from './contacts-lotteries-list.component';



export const contactsLotteriesRoutings: Routes = [
    {
        canActivate: [AuthGuard], path: 'lotteries', data: { roles: "Lottery", title: "רשימות כרטיסי הגרלה" },
        children: [
            { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: ContactsLotteriesListComponent, data: { roles: "Lottery", title: "רשימות כרטיסי הגרלה" } },       
            { canActivate: [AuthGuard], path: 'pledgesTickets', component: ContactsLotteriesPledgesComponent, data: { roles: "Lottery", title: "התחייבויות כרטיסים" } }
        ],
    },
];

        