import { DirectivesMdl } from './../directives/directives-module';

import { FooterEditButtons } from './footer-edit-buttons.cmp';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule,  DirectivesMdl],
    exports: [FooterEditButtons],
    declarations: [FooterEditButtons]
})
export class FooterEditButtonsMdl { }
