import { ContactDetailsDlg } from './contacts-details-dlg.cmp';
import { Contact } from './contact/contact';
import { AuthenticationService } from '../_guards/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ContactsSearchInput } from '../components/contacts-search-input/contacts-search-input.component';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ContactsData } from './contacts.data';
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService } from '../components/alert/alert.svc';
import { MatTable } from '@angular/material/table';
import { IndexService } from '../services/index-service';
//import * as signalR from '@aspnet/signalr';

@Component({
  selector: 'contacts-min-list',
  templateUrl: 'contacts-min-list.tmpl.html',
})

export class ContactsMinList implements OnInit  {
  constructor(
    public contactsData: ContactsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private alert: AlertService,
    private cdref: ChangeDetectorRef) {
  }

  @Input('chotenID') chotenID?: number | null = null;
  @Input('fatherID') fatherID?: number | null = null;

  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInp') searchInp: ContactsSearchInput;
  @ViewChild('cityID') cityID: AutoCompleteComponent;
  @ViewChild('shtibelID') shtibelID: AutoCompleteComponent;
  @ViewChild('table') table: MatTable<any>;
  @Output() selectedRowsChange = new EventEmitter<any>();



  orderButtonIsChicked: boolean = false;
  selection = new SelectionModel<number>(true, []);
  stopLoadingPoints: boolean = false;
  searchContactsListForm: FormGroup;
  data: any[] = [];
  contactGroupStatuses: any[];
  onlySortRecentlyOpenedByCurUser = false;
  defaultvalues;
  indexService: IndexService;
  displayedColumns: string[] = ['contactID', 'perfix', 'lastName', 'firstName', 'suffix', 'fatherViewName', 'chotenName', 'cityName', 'phoneNumber1', 'phoneNumber2', 'shtibelName', 'codeIchud'];



  openSelectedRow(contactID) {
    this.router.navigate(['contacts', contactID]);
  }


  initForm() {
    this.searchContactsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      fatherID: [this.fatherID],
      chotenID: [this.chotenID],
      cityID: [],
      cityTitle: [],
      friendsInStiebel:[null],
      shtibelID: [],
      shtibelName: [],
      groupID: [],
      groupIDs: [],
      groupPriorities: [],
      groupStatuses: [],
      onlySortRecentlyOpenedByCurUser: [false, Validators.required],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });


    let frm = this.searchContactsListForm;
    this.defaultvalues = Object.keys(this.searchContactsListForm.value)
      .filter(key => frm.controls[key].validator == Validators.required)
      .reduce((obj, key) => {
        obj[key] = frm.controls[key].value;
        return obj;
      }, {});
  }

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();

    let params = [
      { key: "fatherID", value: this.fatherID },
      { key: "chotenID", value: this.chotenID },
    ];


    this.indexService = new IndexService(this.searchContactsListForm, params, this.contactsData, this.router, this.defaultvalues, '', '', '');
    this.indexService.getList();

  }

}
