import { CommitmentMain } from './commitment/commitment-main.cmp';
import { PaymentsPlanMain } from './../paymentsPlans/paymentsPlan/paymentsPlan-main.cmp';
import { PaymentMain } from './../payments/payment/payment-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { CommitmentsList } from './commitments-list.cmp';
import { CommitmentsListDashboardDlg } from './commitments-list-dashboard-dlg.cmp';
import { Routes } from '@angular/router';


export const CommitmentsRoutes: Routes = [


  { canActivate: [AuthGuard], path: 'commitments', component: CommitmentsList, data: { roles: "commitments", title: 'רשימת התחייבויות/הסכמים' } },
  { canActivate: [AuthGuard], path: 'commitments/add', component: CommitmentMain, data: {  mode: "edit", roles: "commitments", title: 'הוסף התחייבות/הסכם' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'commitments/:commitmentID', component: CommitmentMain, data: { roles: "commitments", title: 'פרטי התחייבות/הסכם' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'commitments/:commitmentID/edit', component: CommitmentMain, data: {  mode: "edit", roles: "commitments", title: 'עריכת פרטי התחייבות/הסכם' }, outlet: 'popup' },
  { path: 'commitments/beforeEnd', component: CommitmentsListDashboardDlg, data: { title: 'התחייבויות לפני סיום' } },
  { path: 'commitments/closeds', component: CommitmentsListDashboardDlg, data: { title: 'התחייבויות שנסגרו' } },

  { canActivate: [AuthGuard], path: 'commitmentsTreatment/:subject', component: CommitmentsList, data: { roles: "commitmentsTreatment", title: 'התחייבויות לגביה' } },

    { canActivate: [AuthGuard], path: 'commitments/:commitmentID/payments/add', component: PaymentMain, data: {   mode: "edit", roles: "payments", title: 'הוסף תשלום' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'commitments/:commitmentID/paymentsPlans/add', component: PaymentsPlanMain, data: {   mode: "edit", roles: "paymentsplans", title: 'הוסף תוכנית תשלום' }, outlet: 'popup' },
];
