<div style="cursor: initial" *ngIf="yarzeit">
  <div class="example-full-width" fxLayout="row" fxLayoutAlign="start center">
    <a fxLayout="column" style="padding: 4px" (click)="editYarzeit(yarzeit)">
      <i class="fa fa-pencil"></i>
    </a> &nbsp;&nbsp;
    <model-info fxLayout="column" style="padding: 4px" [modelInfo]="yarzeit"></model-info>
    &nbsp;&nbsp;
    <div style="padding-right:7px" fxLayout="column" fxFlex>

      <div fxLayout="row" fxLayoutAlign="end end">
        <label class="label" style="    color: blue; font-weight: bold; font-size: 100%; background-color: white; border: solid; border-color: gray; border-radius: 0px 10px 10px 0px; border-width: 1px 1px 1px 0;">{{yarzeit.jewishDay}} {{yarzeit.jewishMonth}}</label>
      </div>
      <div fxLayout="row">
        <h3>
          יא"צ
          <span>{{ yarzeit.yarzeitType }}</span>
          <span style="font-weight: bold;"> {{ yarzeit.firstName }} {{ yarzeit.lastName }}</span>
          <span *ngIf="yarzeit.motherName && (yarzeit.yarzeitType  == 'שוויגער' || yarzeit.yarzeitType  == 'אמא')"> בת ר' {{
            yarzeit.motherName}}</span>
          <span *ngIf="yarzeit.fatherName && (yarzeit.yarzeitType  == 'אבא' || yarzeit.yarzeitType  == 'שווער')"> בן ר' {{
            yarzeit.fatherName}}</span>
        </h3>
      </div>
      <div fxLayout="row">
        <span> נלב"ע
          {{ yarzeit.jewishDeathDate }}
          <span
            *ngIf="yarzeit.burialPlace && (yarzeit.yarzeitType  == 'שוויגער' || yarzeit.yarzeitType  == 'אמא')">ומנוחתה
            כבוד {{ yarzeit.burialPlace }}</span>
          <span
            *ngIf="yarzeit.burialPlace && (yarzeit.yarzeitType  == 'אבא' || yarzeit.yarzeitType  == 'שווער')">ומנוחתו
            כבוד {{ yarzeit.burialPlace }}</span>

        </span>
      </div>
    </div>
  </div>
</div>
