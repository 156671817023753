
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'payment-bankTransfer-details',
    templateUrl: './payment-bankTransfer-details.tmpl.html',
    styleUrls:['./../../payments.scss']
})


export class PaymentBankTransferDetails implements OnInit {
 
    touchedRows: any;
    constructor() { }
    @Input('paymentForm') public paymentForm: FormGroup;
     


    ngOnInit(): void {
      this.touchedRows = [];   
    }
  
    ngAfterOnInit() {
      //this.control = this.paymentForm.get('chicDetails') as FormArray;
    }
   
    
    

}
