<div *ngIf="model">
  <div class="content">
    <div class="row">
      <div class="col-sm-4">
        <h1>

        </h1>
      </div>
      <div class="col-sm-8">

      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
      </div>
      <div class="col-lg-6">

        <panel-box title="יומן פגישות" [showClose]="false" [showCollapse]="false">
          <div class="panel-content">
            <table class="table table-striped no-margins">
              <tr *ngFor="let item of model.appointmentsToday">
                <td>
                  <a [routerLink]="['appointments', item.appointmentID]">
                    {{ item.appointmentID }}</a>
                </td>
                <!-- <td>{{ item.lastName }}</td>
                                    <td>{{ item.firstName }}</td>
                                    <td>{{ item.amount | currency:'usd':true:'1.0-0' }}</td> -->
              </tr>
            </table>
          </div>
        </panel-box>
        <panel-box *ngIf="userName &&  !userName.includes('שלמה יהודה א')" title="פעילויות ממתינות" [showClose]="false"
          [showCollapse]="false">
          <div class="panel-content">
            <table class="table table-striped no-margins">
              <tr *ngFor="let item of model.waitingActivities">
                <td>
                  <a routerLink="contacts/{{ item.contactID }}">
                    <i class="fa fa-folder-open"></i>
                  </a>
                </td>
                <td>{{ item.activityDate | date:'dd/MM/yyyy' }}</td>
                <td>{{ item.contactName }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </table>
          </div>
        </panel-box>
      </div>
      <div class="col-lg-3">
        <panel-box *authGuardDirective="'receipts'" title="הדפסות" [showClose]="false" [showCollapse]="false">
          <div class="panel-content" fxFlex="column">
            <!-- <button fxLayout="row"  (click)="printWaitingReceipts()"
                            class="btn btn-outline btn-success  dim" type="button">
                            <i style="color:#1ab394" class="fa fa-print"></i>&nbsp;הדפס קבלות שהונפקו וממתינות ל - {{ model.pendingReceiptsCount }} אנשים - ישראל</button> -->
                        <button fxLayout="row"
                            (click)="createAndNonPrintWaitingReceipts()" class="btn btn-outline btn-success  dim"
                            type="button">
                            <i style="color:#ed5565" class="fa fa-exclamation-triangle"></i>&nbsp;צור קבלות
                            ממתינות - ישראל</button>
                            <hr>
            <!-- <button fxLayout="row"

                            (click)="createAndPrintWaitingIL_Year_Receipts()" class="btn btn-outline btn-success  dim"
                            type="button">
                            <i style="color:#8f55ed" class="fa fa-exclamation-triangle"></i>&nbsp;צור והדפס קבלות
                            שנתיות - ישראל</button> -->
            <button fxLayout="row" (click)="createWaitingIL_Year_Receipts()" class="btn btn-outline btn-success  dim"
              type="button">
              <i style="color:#ed55ae" class="fa fa-exclamation-triangle"></i>&nbsp;צור קבלות
              שנתיות - ישראל</button>
            <hr>

            <button fxLayout="row" (click)="printIL_Year_Receipts()" class="btn btn-outline btn-success  dim"
              type="button">
              <i style="color:#55ed7b" class="fa fa-exclamation-triangle"></i>&nbsp;הדפס קבלות שנתיות "מקור" -
              ישראל</button>
            <table *ngIf="model.lastReceiptsPrintedIL" class="table table-striped no-margins">
              <tr *ngFor="let item of model.lastReceiptsPrintedIL">
                <td>
                  <a (click)="cancelILReceiptsPrint(item.receiptsIdsLlist)">
                    <i class="fa fa-undo"></i>&nbsp;לחץ לביטול הפעולה</a>
                </td>
                <td> {{ item.count }} הדפסות משעה {{ item.hour }}:{{ item.minute }}
                </td>
              </tr>
            </table>

            <hr>
            <button fxLayout="row" (click)="printWaitingIchudIlReceipts()" class="btn btn-outline btn-success  dim"
              type="button">
              <i style="color:#1ab394" class="fa fa-print"></i>&nbsp;הדפס קבלות ממתינות ל-{{
              model.pendingIchudIlReceiptsCount }} אנשים - איחוד ישראל</button>
            <button fxLayout="row" (click)="createAndPrintWaitingReceipts()" class="btn btn-outline btn-success  dim"
              type="button">
              <i style="color:#ed5565" class="fa fa-exclamation-triangle"></i>&nbsp;צור והדפס קבלות
              ממתינות - איחוד ישראל</button>
            <button fxLayout="row" (click)="createAndPrintWaitingIchudIL_Year_Receipts()"
              class="btn btn-outline btn-success  dim" type="button">
              <i style="color:#8f55ed" class="fa fa-exclamation-triangle"></i>&nbsp;צור והדפס קבלות
              שנתיות - איחוד ישראל</button>
            <table fxLayout="row" class="table table-striped no-margins">
              <tr *ngFor="let item of model.lastReceiptsPrintedIchudIL">
                <td>
                  <a (click)="cancelIchudIlReceiptsPrint(item.receiptsIdsLlist)">
                    <i class="fa fa-undo"></i>&nbsp;לחץ לביטול הפעולה</a>
                </td>
                <td> {{ item.count }} הדפסות משעה {{ item.hour }}:{{ item.minute }}
                </td>
              </tr>
            </table>

            <hr>
            <button fxLayout="row" (click)="printWaitingUSAReceipts()" class="btn btn-outline btn-success  dim"
              type="button">
              <i style="color:#1ab394" class="fa fa-print"></i>&nbsp;הדפס קבלות ממתינות ל-{{
              model.pendingUsaReceiptsCount }} אנשים - USA</button>
            <button fxLayout="row" (click)="createAndPrintWaitingReceipts()" class="btn btn-outline btn-success  dim"
              type="button">
              <i style="color:#ed5565" class="fa fa-exclamation-triangle"></i>&nbsp;צור והדפס קבלות
              ממתינות - USA</button>
            <table fxLayout="row" *ngIf="model.lastReceiptsPrintedUSA" class="table table-striped no-margins">
              <tr *ngFor="let item of model.lastReceiptsPrintedUSA">
                <td>
                  <a (click)="cancelUSAReceiptsPrint(item.receiptsIdsLlist)">
                    <i class="fa fa-undo"></i>&nbsp;לחץ לביטול הפעולה</a>
                </td>
                <td> {{ item.count }} הדפסות משעה {{ item.hour }}:{{ item.minute }}
                </td>
              </tr>
            </table>
            <hr>
            <button fxLayout="row" (click)="printWaitingUKReceipts()" class="btn btn-outline btn-success  dim"
              type="button">
              <i style="color:#1ab394" class="fa fa-print"></i>&nbsp;הדפס קבלות ממתינות ל-{{
              model.pendingUkReceiptsCount }} אנשים - UK</button>
            <button fxLayout="row" (click)="createAndPrintWaitingReceipts()" class="btn btn-outline btn-success  dim"
              type="button">
              <i style="color:#ed5565" class="fa fa-exclamation-triangle"></i>&nbsp;צור והדפס קבלות
              ממתינות - UK</button>
            <table fxLayout="row" *ngIf="model.lastReceiptsPrintedUK" class="table table-striped no-margins">
              <tr *ngFor="let item of model.lastReceiptsPrintedUK">
                <td>
                  <a (click)="cancelUKReceiptsPrint(item.receiptsIdsLlist)">
                    <i class="fa fa-undo"></i>&nbsp;לחץ לביטול הפעולה</a>
                </td>
                <td> {{ item.count }} הדפסות משעה {{ item.hour }}:{{ item.minute }}
                </td>
              </tr>
            </table>
          </div>
        </panel-box>
      </div>

    </div>

  </div>
</div>




<!-- <panel-box title="התחייבויות אחרונות" [showClose]="false" [showCollapse]="false">
                <div class="panel-content">
                    <table class="table table-striped no-margins">
                        <tr *ngFor="let item of model.topNewCommitments">
                            <td>
                                <a [routerLink]="['commitments', item.commitmentID]">{{ item.contactID }}</a>
                            </td>
                            <td>{{ item.lastName }}</td>
                            <td>{{ item.firstName }}</td>
                            </tr>
                    </table>
                </div>
            </panel-box> -->

<!-- <div>
            <div class="col-lg-6">
                <div class="widget style1 lazur-bg" style="background-color: lightblue">
                    <div class="row">
                        <div>
                            <a style="color: white;" [routerLink]="['/commitments', 'beforeEnd']">
                                <div class="col-xs-4"><i class="fa fa-money fa-5x"></i></div>
                                <div class="col-xs-8 text-right"><span>&nbsp;התחייבויות לפני סיום</span>
                                    <h2 class="font-bold">&nbsp;התחייבויות לפני סיום</h2>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="widget style1 lazur-bg" style="background-color: gray">
                    <div class="row">
                        <div>
                            <a style="color: white;" [routerLink]="['/commitments', 'closeds']">
                                <div class="col-xs-4"><i class="fa fa-money fa-5x"></i></div>
                                <div class="col-xs-8 text-right"><span>&nbsp;התחייבויות שנסגרו</span>
                                    <h2 class="font-bold">&nbsp;התחייבויות שנסגרו</h2>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div> -->
<!-- <panel-box title="התחייבויות אחרונות" [showClose]="false" [showCollapse]="false">
                            <div class="panel-content">
                                <table class="table table-striped no-margins">
                                    <tr *ngFor="let item of model.topNtopNewCommitments">
                                        <td>
                                            <a [routerLink]="[ '', {outlets: {popup: [ 'commitments', item.commitmentID]}}]">{{
                                                item.commitmentID }}</a>
                                        </td>
                                        <td>{{ item.lastName }}</td>
                                        <td>{{ item.firstName }}</td>
                                    </tr>
                                </table>
                            </div>
                        </panel-box> -->

<!-- <panel-box title="גביות בהמתנה" [showClose]="false" [showCollapse]="false">
                        <div class="panel-content">
                            <table class="table table-striped no-margins">
                                <tr *ngFor="let item of model.waitingCollectionBuilders">
                                    <td>
                                        <a routerLink="collectionBuilders/{{ item.collectionBuilderID }}">
                                            <i class="fa fa-folder-open"></i>
                                        </a>
                                    </td>
                                    <td>{{ item.collectionDate | date:'dd/MM/yyyy' }}</td>
                                    <td>{{ item.termName }}</td>
                                    <td>{{ item.count }}</td>
                                </tr>
                            </table>
                        </div>
                    </panel-box> -->
<!-- <div class="col-lg-4">
                    <panel-box title="התחייבויות לטיפול" [showClose]="false" [showCollapse]="false">
                        <span class="panel-tools">
                            </span>
                        <div class="panel-content">
                        </div>
                    </panel-box>
                </div> -->
<!-- <ivr-donation-user-tasks-list></ivr-donation-user-tasks-list> -->
