import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    
    selector: 'panel-box-btn',
    templateUrl: './panel-box-btn.tmpl.html'
})
export class PanelBoxBtnComponent {
    constructor() { }

    @Input() icon: string;
    @Input() text: string;
    @Output() btnClick = new EventEmitter();

    btnClicked() {
        this.btnClick.emit();
    }
} 