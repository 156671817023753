<div [formGroup]="searchLetterSettingsListForm">
  <div class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">

    </div>
  </div>
  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="select">
            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
              <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox apply-enter (click)="$event.stopPropagation()" (change)="$event ? rowToggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'letterSettings', row.letterSettingID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['letterSettings', row.letterSettingID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="letterSettingsData" [id]="row.letterSettingID"
                    [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="letterSettingID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
              מכתב </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה מכתב">
              <a [routerLink]="[ '', {outlets: {popup: [ 'letterSettings', row.letterSettingID]}}]">
                {{ row.letterSettingID }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="letterName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> כותרת מכתב
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="כותרת מכתב">
              {{row.letterName}}
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.letterSettingID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
