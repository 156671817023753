import { Component} from '@angular/core';

@Component({
    selector: 'tishGroup-main',
    templateUrl: './tishGroup-main.tmpl.html'
})

export class TishGroupMain {
    editMode: boolean = null; 

    public setVal(val:boolean) {
        this.editMode = val;  
    }
}
  