import { LoginViewModel } from './../_models/user';
import { UserIdleService } from 'angular-user-idle';
import { LocationStrategy } from '@angular/common';
import { AlertService } from '../components/alert/alert.svc';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { User } from '../_models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  constructor(
    private http: ToraxHttp,
    private router: Router,
    private alertService: AlertService,
    private location: LocationStrategy,
    private userIdle: UserIdleService,
  ) {

    //  setTimeout(() => {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentUser.subscribe(x => {
      if (x) {
        this.initUserIde();
      }
    })
    // }, 300);
  }


  isAuthorized(roles: string): boolean {
    const currentUser = this.currentUserValue;
    const rls = roles.split(',').map((x) => x.toLowerCase());
    if (rls && currentUser?.roles) {
      if (currentUser.roles.indexOf("SystemAdministrator") > -1) {
        return true;
      }

      const uRls = currentUser.roles.map((x) => x.toLowerCase());
      for (let i = 0; i < rls.length; i++) {
        if (uRls.indexOf(rls[i]) >= 0) {
          return true;
        }
      }
      return false;
    }
    return false;

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(loginViewModel: LoginViewModel) {
    return this.http.post(`/login`, loginViewModel)
      .pipe(map((user: any) => {
        // login successful if there's a jwt token in the response
        if (user) { // && user.token
          // let updateDate = "16/12/2020 15:00";
          // // store user details and jwt token in local storage to keep user logged in between page refreshes
          // let lastUpdateDate = localStorage.getItem("lastUpdateDate");
          // if (!lastUpdateDate || lastUpdateDate == null || lastUpdateDate == undefined || lastUpdateDate != updateDate) {
          //     localStorage.clear();
          //     localStorage.setItem("lastUpdateDate", updateDate);
          //     location.reload(true);
          // }

          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      }));
  }

  resetTimer() {
    if (this.currentUserValue) {
      this.userIdle.resetTimer();
      let isRemoving = this.remove50Alert();
      if (isRemoving) {
        this.alertService.success(`חזרת בהצלחה!`);
      }
    }
  }

  private remove50Alert(): boolean {
    let item = this.alertService.alertList.find(x => x.index == 50);
    if (item) {
      let ix = this.alertService.alertList.indexOf(item);
      this.alertService.alertList.splice(ix);
      return true;
    }
    return false;
  }

  userIdleIsLoadded = false;
  initUserIde() {
    if (!this.userIdleIsLoadded) {
      this.userIdleIsLoadded = true;

      this.userIdle.startWatching();

      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe(count => {
        if (this.currentUserValue && count == 1) {
          this.alertService.info(`זמן החיבור עומד להסתיים,\nלחץ כדי להשאר`, 10, 50);
        }
      });

      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        if (this.location.path().indexOf("/login") == -1) {
          this.remove50Alert();

          this.alertService.info("תם זמן החיבור!");
          this.logoutMe();
        }
      });


      this.userIdle.ping$.subscribe(() => {
        // if (!this.currentUser && this.location.path().indexOf("/login") == -1) {
        //  this.logoutMe();
        //}
      })
    } else {
      this.userIdle.resetTimer();
    }
  }


  logoutMe(loginUrl: string = "login", returnUrl = "") {
    this.logout()
      .pipe(first())
      .subscribe(
        (x) => {
          /// this.userIdle.stopWatching();
          //console.log("loginUrl", loginUrl);
          if (!returnUrl) {
            returnUrl = this.location.path();
            if (returnUrl.indexOf("returnUrl=") == -1) {
              if (returnUrl.indexOf('/') == 0) {
                returnUrl = returnUrl.substring(1, returnUrl.length);
              }
            }
          }

          this.router.navigateByUrl(`${loginUrl}${(returnUrl ? `?returnUrl=${returnUrl}` : '')}`);
        },
        (err) => {
          console.error(err);
        });
  }


  logout() {
    localStorage.removeItem('currentUser');
    return this.http.get('/logout')
      .pipe(map(() => {
        return this.currentUserSubject.next(null);
      }));
  }
}
