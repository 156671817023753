import { CommitmentsData } from './../commitments.data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';


@Component({
    selector: 'commitment-details-dlg',
    templateUrl: './commitment-details-dlg.tmpl.html'
})
export class CommitmentDetailsDlg implements OnInit {
    constructor(     
        public matDialogRef: MatDialogRef<CommitmentDetailsDlg>,        
        @Inject(MAT_DIALOG_DATA)
        public data: number, //commitmentID
        private commitmentsData: CommitmentsData
    ) { }

    commitment;
    ngOnInit(): void {
        this.commitmentsData.getCommitmentInfoPlus(this.data)
            .subscribe((c: any) => { 
                this.commitment = c;  
            });
    } 
}

