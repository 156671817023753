﻿
  <div [formGroup]="searchContactsListForm" style="width: 100%;">
  <mat-table #table *ngIf="indexService?.sourceList" style="width: 100%;"
    [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)"  matSort
    matSortDisableClear matSortDirection="asc">

    <ng-container matColumnDef="contactID">
      <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="מזהה">
        <a [routerLink]="[ '/contacts', row.contactID]">
          {{ row.contactID }}
        </a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="perfix">
      <mat-header-cell *matHeaderCellDef mat-sort-header> תואר
      </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="תואר" > {{row.perfix}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> פרטי </mat-header-cell>
      <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="פרטי">
        {{row.firstName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> משפחה </mat-header-cell>
      <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="משפחה">
        {{row.lastName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="suffix">
      <mat-header-cell *matHeaderCellDef mat-sort-header > סיומת
      </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="סיומת" > {{row.suffix}}
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="fatherViewName">
      <mat-header-cell *matHeaderCellDef > שם האב </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="שם האב" >
        <a [routerLink]="[ '/contacts', row.fatherID]">
          {{ row.fatherViewName }}
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="chotenName">
      <mat-header-cell *matHeaderCellDef > שם החותן </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="שם החותן" >
        <a [routerLink]="[ '/contacts', row.chotenID]">
          {{ row.chotenName }}
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cityName">
      <mat-header-cell *matHeaderCellDef> עיר </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="עיר"> {{row.cityName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phoneNumber1">
      <mat-header-cell *matHeaderCellDef > טלפון 1 </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="טלפון 1" > <span style="direction: ltr;">
          {{row.phoneNumber1 | phone }} </span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="phoneNumber2">
      <mat-header-cell *matHeaderCellDef > טלפון 2 </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="טלפון 2" ><span style="direction: ltr;">
          {{row.phoneNumber2 | phone }} </span></mat-cell>
    </ng-container>

    <ng-container matColumnDef="shtibelName">
      <mat-header-cell *matHeaderCellDef > שטיבל </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="שטיבל" > {{row.shtibelName}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="codeIchud">
      <mat-header-cell *matHeaderCellDef  mat-sort-header> קוד איחוד
      </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="קוד איחוד" >
        {{row.codeIchud}}
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>

  </mat-table>
</div>
