<div class="example-form" [formGroup]="contactForm" enter-tab>
    <div style="padding: 0 15px;">
        <div fxLayout="row" fxLayout.xs="column">
            <fieldset class="form-group">
                <legend>פרטים אישיים</legend>
                <div fxLayout="column" fxFlex="100">
                    <div class="form-row" fxLayout="row">
                        <div style="padding-left: 5px" fxLayout="column">
                            <auto-complete-input apply-enter cdkFocusInitial [placeholder]="'תואר'"
                                [controllerName]="'Perfixes'" [stringValueControl]="contactForm.controls.perfix"
                                [formGroupModel]="contactForm">
                            </auto-complete-input>&nbsp;
                            <div class="input-group mb-3">
                                <label>שם פרטי:</label>
                                <input type="text" class="form-control" apply-enter formControlName="firstName"
                                    placeholder="שם פרטי...">
                            </div>&nbsp;
                            <div class="input-group mb-3">
                                <label>שם משפחה:</label>
                                <input type="text" class="form-control" apply-enter formControlName="lastName"
                                    lastName="שם משפחה...">
                            </div>&nbsp;
                            <auto-complete-input apply-enter [placeholder]="'סיומת'" [controllerName]="'Suffixes'"
                                [stringValueControl]="contactForm.controls.suffix" [formGroupModel]="contactForm">
                            </auto-complete-input>
                        </div>
                        <div style="padding-right: 5px; direction: ltr; text-align: left !important" fxLayout="column">
                            <auto-complete-input [leftToRight]="true" apply-enter [placeholder]="'Local perfix'"
                                [controllerName]="'LocalPerfixes'"
                                [stringValueControl]="contactForm.controls.localPerfix" [formGroupModel]="contactForm">
                            </auto-complete-input>&nbsp;
                            <div class="input-group mb-3">
                                <label>Local first name:</label>
                                <input type="text" class="form-control" apply-enter formControlName="localFirstName"
                                    placeholder="Local first name...">
                            </div>&nbsp;
                            <div class="input-group mb-3">
                                <label>Local last name:</label>
                                <input type="text" class="form-control" apply-enter formControlName="localLastName"
                                    placeholder="Local last name...">
                            </div>&nbsp;
                            <auto-complete-input apply-enter [leftToRight]="true" [placeholder]="'Local suffix'"
                                [controllerName]="'LocalSuffixes'"
                                [stringValueControl]="contactForm.controls.localSuffix" [formGroupModel]="contactForm">
                            </auto-complete-input>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <label>מספר זהות:</label>
                        <input type="text" class="form-control" apply-enter formControlName="passport"
                            placeholder="מספר זהות...">
                    </div>&nbsp;
                    <date-input apply-enter [placeholder]="'תאריך לידה...'"
                        [dateControl]="contactForm.controls.birthDate">
                        Loading...
                    </date-input>
                    <mat-checkbox apply-enter fxLayout="row" formControlName="bornAfterDark">
                        תאריך לידה לאחר השקיעה
                    </mat-checkbox>
                    <mat-checkbox apply-enter fxLayout="row" formControlName="birthDateInaccurate">
                        תאריך לידה משוער
                    </mat-checkbox>
                </div>
            </fieldset>
            &nbsp;&nbsp;
            <fieldset class="form-group">
                <legend>נתוני משפחה</legend>
                <div fxFlex="100" fxLayout="column">
                    <div fxLayout="row">
                        <auto-complete apply-enter [placeholder]="'קוד אב'" [controllerName]="'Contacts'"
                            [formGroupModel]="contactForm" [idControl]="contactForm.controls.fatherID"
                            [nameControl]="contactForm.controls.fatherName">
                        </auto-complete>
                        &nbsp;&nbsp;
                        <div class="input-group mb-3">
                            <label>שם האב (טקסטואלי):</label>
                            <input type="text" class="form-control" apply-enter formControlName="fatherName"
                                placeholder="שם האב (טקסטואלי)...">
                        </div>
                    </div>
                    <div fxLayout="row">
                        <auto-complete apply-enter [placeholder]="'קוד חותן'" [controllerName]="'Contacts'"
                            [formGroupModel]="contactForm" [idControl]="contactForm.controls.chotenID"
                            [nameControl]="contactForm.controls.chotenName">
                        </auto-complete>
                        &nbsp;&nbsp;
                        <div class="input-group mb-3">
                            <label>שם האשה:</label>
                            <input type="text" class="form-control" apply-enter formControlName="wifeName"
                                placeholder="שם האשה...">
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div class="input-group mb-3">
                            <label>שם האם:</label>
                            <input type="text" class="form-control" apply-enter formControlName="motherName"
                                placeholder="שם האם...">
                        </div>
                        &nbsp;&nbsp;
                        <div class="input-group mb-3">
                            
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div class="input-group mb-3">
                            <label>שם פרטי לקוויטל:</label>
                            <input type="text" class="form-control" apply-enter formControlName="kvitelFirstName"
                                placeholder="שם פרטי לקוויטל...">
                        </div>
                        &nbsp;&nbsp;
                        <div class="input-group mb-3">
                            <label>שם משפחה לקוויטל:</label>
                            <input type="text" class="form-control" apply-enter formControlName="kvitelLastName"
                                placeholder="שם משפחה לקוויטל...">
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div class="input-group mb-3">
                            <label>שם האם לקוויטל:</label>
                            <input type="text" class="form-control" apply-enter formControlName="kvitelMotherName"
                                placeholder="שם האם לקוויטל...">
                        </div>
                        &nbsp;&nbsp;
                        <div class="input-group mb-3">
                            
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <fieldset class="form-group">
                <legend>קשרים ומוסדות</legend>
                <div fxLayout="column">
                    <auto-complete apply-enter fxFlex fxLayout="column" [placeholder]="'שטיבל'"
                        [controllerName]="'Shtibels'" [formGroupModel]="contactForm"
                        [idControl]="contactForm.controls.shtibelID" [nameControl]="contactForm.controls.shtibelName">
                    </auto-complete>
                    <auto-complete apply-enter fxFlex fxLayout="column" [placeholder]="'שטיבל לקביעות'"
                    [controllerName]="'Shtibels'" [formGroupModel]="contactForm"
                    [idControl]="contactForm.controls.holdingsShtibelID" [nameControl]="contactForm.controls.holdingsShtibelName">
                </auto-complete>
                <mat-checkbox apply-enter placeholder="'נספח לשטיבל'"
                formControlName="appendixToShtibel">נספח לשטיבל</mat-checkbox>
                
                <auto-complete apply-enter fxFlex fxLayout="column" [placeholder]="'אחראי החזקת ביהמד'"
                        [controllerName]="'Contacts'" [formGroupModel]="contactForm"
                        [idControl]="contactForm.controls.responsibleID"
                        [nameControl]="contactForm.controls.responsibleName">
                    </auto-complete>


                    <div class="input-group mb-3">
                        <label>קוד איחוד:</label>
                        <input type="text" class="form-control" apply-enter formControlName="codeIchud"
                            placeholder="קוד איחוד...">
                    </div>
                </div>
            </fieldset>
            &nbsp;&nbsp;
            <fieldset class="form-group">
                <legend>פרטי ברירת מחדל לקבלות</legend>
                <div fxLayout="column">
                    <div class="input-group mb-3">
                        <label>שם ב"מ לקבלות:</label>
                        <input type="text" class="form-control" apply-enter
                            formControlName="defaultContactTitleForReceipts" placeholder="שם ב''מ לקבלות...">
                    </div>&nbsp;
                    <mat-checkbox apply-enter placeholder="'כלול כותרת מכתב להנל'"
                        formControlName="defaultDetailsLetterAsReceipt">הצג שם זה גם במכתב תודה</mat-checkbox>

                   <addresses-select-pick *ngIf="contactForm.controls.contactID.value > 0" fxFlex
                        (addressAdd)="addressAddEvent($event)" (addressChange)="addressChangeEvent($event)"
                        [contactID]="contactForm.controls.contactID.value" [placeHolder]="'כתובת במ לקבלה'"
                        [addresIdControl]="contactForm.controls.defaultReceiptsAddressID">
                    </addresses-select-pick>
                </div>
            </fieldset>
            &nbsp;&nbsp;
            <fieldset class="form-group">
                <legend>הגדרות ושונות</legend>
                <div fxLayout="column">
                    <div class="input-group mb-3">
                        <label>שפה-איזור:</label>

                        <select class="form-control" apply-enter formControlName="language" placeholder="שפה-איזור...">
                            <option [value]="">...</option>
                            <option *ngFor="let language of servicesData.Languages" [value]="language.value">
                                {{language.name}}
                            </option>
                        </select>
                    </div>

                    <div class="input-group mb-3">
                        <label>סוג:</label>
                        <select class="form-control" apply-enter formControlName="contactType" placeholder="סוג..."
                            required>
                            <option [value]="">...</option>
                            <option *ngFor="let contactType of servicesData.ContactTypes" [value]="contactType.value">
                                {{contactType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="input-group mb-3">
                        <label>שיוך לגור:</label>
                        <select class="form-control" apply-enter formControlName="affiliation"
                            placeholder="שיוך לגור..." required>
                            <option [value]="">...</option>
                            <option *ngFor="let affiliation of servicesData.ContactAffiliations"
                                [value]="affiliation.value">
                                {{affiliation.name}}
                            </option>
                        </select>
                    </div>

                    <div class="input-group mb-3">
                        <label>מצב אישי:</label>
                        <select class="form-control" apply-enter formControlName="personalStatus"
                            placeholder="מצב אישי...">
                            <option [value]="">...</option>
                            <option *ngFor="let personalStatus of servicesData.PersonalStatuses"
                                [value]="personalStatus.value">
                                {{personalStatus.name}}
                            </option>
                        </select>
                    </div>

                    <div class="input-group mb-3">
                        <label>כהן/לוי/ישראל:</label>
                        <select class="form-control" apply-enter formControlName="team" placeholder="כהן/לוי/ישראל...">
                            <option [value]="">...</option>
                            <option *ngFor="let team of servicesData.Teams" [value]="team.value">
                                {{team.name}}
                            </option>
                        </select>
                    </div>
                    <date-input [placeholder]="'תאריך פטירה...:'"  apply-enter [dateControl]="contactForm.controls.endDate"></date-input> 
                </div>
            </fieldset>
        </div>
    </div>
</div>