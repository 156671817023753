﻿<div [formGroup]="searchCollectionBuildersListForm">
  <div class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">

      <div class="col-sm-12">
        <div class="form-row" fxLayout="row" fxLayout.xs="column">
          <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'" [withLabel]="true"
            *ngIf="servicesData.Apis" (selectChange)="indexService.selectionChanged($event)"
            [listControl]="searchCollectionBuildersListForm.controls.termIDs" [placeholder]="'מסוף...'"
            [list]="servicesData.Apis">
          </mat-select-multiple-dropdown>
          &nbsp;&nbsp;
          <date-input-range [placeHolderStart]="'מתאריך'" [placeHolderEnd]="'עד לתאריך'"
            [dateRangeCtrl]="searchCollectionBuildersListForm.controls.collectionDateRange">Loading...
          </date-input-range>
          &nbsp;&nbsp;
          <div style="margin: 20px 20px 0px; text-align:left; white-space: nowrap;" class="example-button-row">
            <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()">
              <i class="fa fa-filter"></i></button> &nbsp;
            <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()">
              <i class="material-icons">clear</i></button> &nbsp;
            <button style="margin: 3px;" mat-mini-fab color="primary" title="הוסף" (click)="addNew()"
              aria-label="הוסף חדש">
              <i class="material-icons">add</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">

          <ng-container matColumnDef="collectionBuilderID">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מזהה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה" fxHide fxShow.gt-xs>

              <a [routerLink]="['', {outlets: {popup: ['collectionBuilders', row.collectionBuilderID]}}]">
                {{ row.collectionBuilderID }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="collectionDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך"> {{
              row.collectionDate | date:'dd/MM/yyyy' }} <br>{{ row.jewishCollectionDate }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="termID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מסוף </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מסוף"> {{ row.termName }} </mat-cell>
          </ng-container>


          <ng-container matColumnDef="plannedCount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שורות בהמתנה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שורות בהמתנה"><span style="color:red">
                {{ row.plannedCount }} </span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="plannedSum">
            <mat-header-cell *matHeaderCellDef mat-sort-header> סכום לגביה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סכום לגביה">
              <span style="color:red">{{ row.plannedSum | number }}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="succeededCount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שורות שנגבו </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שורות שנגבו">
              <span style="color:green">
                {{ row.succeededCount }} </span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="succeededSum">
            <mat-header-cell *matHeaderCellDef mat-sort-header> סכום שנגבה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סכום שנגבה">
              <span style="color:green">
                {{ row.succeededSum | number}} </span> </mat-cell>
          </ng-container>
          <ng-container matColumnDef="exludesCount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שורות שבוטלו </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label=" שורות שבוטלו">
              {{ row.exludesCount}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="exludesSum">
            <mat-header-cell *matHeaderCellDef mat-sort-header> סכום שבוטל </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label=" סכום שבוטל">

              {{ row.exludesSum | number}} </mat-cell>
          </ng-container>
          <!-- <ng-container matColumnDef="plannedSumResult">
                        <mat-header-cell *matHeaderCellDef> ממתין לגביה </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="ממתין לגביה"><span style="color:red"
                                *ngIf="(row.plannedSum - row.succeededSum) > 0">{{ (row.plannedSum - row.succeededSum) |
                                number}}
                            </span> </mat-cell>
                    </ng-container> -->

          <ng-container matColumnDef="contactID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שם איש </mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">
              <a *ngIf="row.contactID" [routerLink]="[ '/contacts', row.contactID]">{{
                row.contactName }}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="paymentID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תשלום </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תשלום">
              <a *ngIf="row.paymentID" [routerLink]="['', {outlets: {popup: ['payments', row.paymentID]}}]">{{
                row.paymentID
                }}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="createDate">
            <mat-header-cell *matHeaderCellDef> תאריך יצירת רשומה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך יצירת רשומה"> {{
              row.createDate | date:'dd/MM/yyyy' }} <br>{{ row.jewishCreateDate }} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"
            (dblclick)="openSelectedRow(row.collectionBuilderID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>


  <ng-template #loading>
    <h3 style="text-align: center;">
      טוען נתונים...
    </h3>
  </ng-template>

