import { AlertService } from "./../components/alert/alert.svc";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { ServicesData } from "../services/services.data";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToraxHttp } from "../utils/custom-http.svc";
import { IndexService } from "../services/index-service";
import { HonorsData } from "./honors.data";
import { SubscriptionLike } from "rxjs";

@Component({
  selector: "honors-list",
  templateUrl: "honors-list.tmpl.html",
})
export class HonorsList implements OnInit, OnDestroy {
  constructor(
    private honorsData: HonorsData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
    private alert: AlertService
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/honors') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  displayedColumns: string[] = ["select", "buttons", "honorID", "contactName", "receivingContactName", "honorsSetTitle", "honorTypeDescription", "honorDate", "contributionAmount", "reasonComment", "commitmentID", "doCreateLetter"];

  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  searchHonorsListForm: FormGroup;

  actualMinusHeight = '185';
  @Input("contactID") public contactID?: number | null = null;
  @Input("honorsSetID") public honorsSetID?: number | null = null;

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  isChecked(row: any): boolean {
    const found = this.selection.selected.find(
      (el: any) => el.honorID === row.honorID
    );
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  addNew() {
    this.router.navigate([{ outlets: { popup: ["add"] } }]);
  }

  openSelectedRow(honorID) {
    this.router.navigate(["", { outlets: { popup: ["honors", honorID] } }]);
  }

  public createAndPrintHonorsLetters(): void {
    let ids = this.searchHonorsListForm.controls["selectedRows"].value
      .map((x) => x)
      .join();
    if (!ids) {
      alert("לא נבחרו רשומות להדפסה");
    } else {
      this.honorsData
        .createAndPrintHonorsLetters(ids)
        .subscribe((data) => {
          let popupWinindow: any = window.open(
            "",
            "_blank",
            "scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
          );
          popupWinindow.document.open();
          popupWinindow.document.write(
            '<html><head><style type="text/css">.MsoNormal { margin-top: 0px; margin-bottom: 0px;} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { page-break-after: always; } }  </style></head><body  onload="window.print()"><div>' +
            (<any>data).value +
            "</div></body></html>"
          );
          popupWinindow.document.close();
        });
    }
  }


  private initForm() {
    this.searchHonorsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      honorsSetID: [],
      honorsSetTitle: [],
      honorTypeID: [],
      honorTypeDescription: [],
      selectedRows: [],
      orders: this.formBuilder.array([]),
    });

    this.selection.changed.subscribe((x) => {
      this.searchHonorsListForm.controls["selectedRows"].setValue(
        x.source.selected.map((x: any) => x.honorID)
      );
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {
    this.initForm();

    this.activatedRoute.params.forEach((params: Params) => {
      this.contactID = +params["contactID"] || null;
      this.honorsSetID = +params["honorsSetID"] || null;
    });
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchHonorsListForm.patchValue(frm);
    }

    this.searchHonorsListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
      { key: "contactID", value: this.contactID },
      { key: "honorsSetID", value: this.honorsSetID },
    ];
    this.indexService = new IndexService(this.searchHonorsListForm, params, this.honorsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();

    if(this.honorsSetID > 0){
      this.actualMinusHeight = '480';
    }

    if (this.contactID) {
      this.actualMinusHeight = '362';
    }
  }
}
