import { ContactsData } from 'src/app/contacts/contacts.data';
import { ServicesData } from 'src/app/services/services.data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatList } from '@angular/material/list';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'short-year-info-dlg',
  templateUrl: './short-year-info-dlg.tmpl.html',
  styleUrls: ['./short-year-info-dlg.scss']

})

export class ShortYearInfoDlg {


  constructor(
    public matDialogRef: MatDialogRef<ShortYearInfoDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any, //לקבל נתונים מהדיאלוג
    public servicesData: ServicesData,
    public contactsData: ContactsData

  ) {
  }
  ngOnInit(): void {
    this.yearsList = ['תשפ', 'תשפא', 'תשפב', 'תשפג', 'תשפד', 'תשפה'];
    this.contactID = this.data.contactID;
    this.selectedYear = this.data.year;
    this.contactName = this.data.contactName;
    this.loadData();
  }
  loadData() {
    this.contactsData.getContactShortYearInfo(this.contactID, this.selectedYear).subscribe((res) => {
      this.dataList = res;
    });
  }
  yearsList: string[] = [];
  contactID;
  contactName = '';
  selectedYear = 'תשפד';
  dataList: any[] = [];

  selectYear(y) {
    this.selectedYear = y;
    this.loadData();
  }
}



