import { RegistrationEventsData } from './../registrationEvents.data';
import { ServicesData } from 'src/app/services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup , Validators  } from '@angular/forms';
import { RegistrationEvent } from './registrationEvent';

@Component({
    selector: 'registrationEvent-edit',
    templateUrl: './registrationEvent-edit.tmpl.html'
})

export class RegistrationEventEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public registrationEventsData: RegistrationEventsData,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,

    ) {
    }

    registrationEvent: RegistrationEvent;
    id: number;
    registrationEventForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.registrationEventsData.getEdit(this.id || 0)
                .subscribe((c: any) => {
                    this.registrationEvent = c;
                    this.registrationEventForm.patchValue(c);
                });
        }
        // else {
        //     this.registrationEventsData.getAdd(this.contactId)
        //         .subscribe((c:any)  => {
        //             this.registrationEvent = c;
        //             this.registrationEventForm.patchValue(c);
        //         });
        // }
    }

    private initForm() {
        this.registrationEventForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            registrationEventID: [this.id || 0, Validators.required],
            title: [, Validators.required],
            date: [, Validators.required],
            price: [],
            currency: [, Validators.required],
            termID: [10, Validators.required],
            registrationEventType: [, Validators.required],
            lastClearingDateOn:[]
        });
      //  this.registrationEventForm.controls['price'].disable();
    }



    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['registrationEventID'];
        });
        this.initForm();
        this.load();
    }
}



