import { Contact } from './../../contact/contact';

export class ContactGroup {
    contactGroupID: number;
    contactID: number;
    contactName: string;
    groupName: string;
    priorityName: string;
}

