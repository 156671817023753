import { CollectionBuilderMain } from './collection-builder/collection-builder-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { CollectionBuilderEdit } from './collection-builder/collection-builder-edit.cmp';

import { DateInputRangeModule } from '../components/dateInput-range/date-input-range.mdl';
//import { MultiSelectDropdownModule } from './../components/multi-select-dropdown/multi-select-dropdown.mdl'; 
import { MaterialModule } from '../material.module';
//import { PayMethodsMdl } from '../payMethods/payMethods.mdl';
import { AmountInputRangeModule } from '../components/amountInput-range/amount-input-range.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { TransactionsMdl } from '../transactions/transactions.mdl';
import { CreateMasavFileDlg } from './createMasavFileDlg.cmp';
import { DateInputModule } from '../components/dateInput/date-input.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';


import { CollectionBuildersData } from './collection-builders.data';
import { CollectionBuildersList } from './collection-builders-list.cmp'; 
import { CollectionBuilderDetails } from './collection-builder/collection-builder-details.cmp';
import { CollectionBuildersRoutes } from './collection-builder.routing';
import { CollectionBuildersSmallList } from './collection-builders-small-list.cmp';


@NgModule({
    imports: [
        CommonModule, 
        PanelBoxModule,  
        RouterModule.forChild(CollectionBuildersRoutes),
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,  
        DateInputModule, 
        FooterEditButtonsMdl, 
        DateInputRangeModule,
        AutoCompleteModule,
        TransactionsMdl,
        FooterEditButtonsMdl,
        AmountInputRangeModule,  
        MaterialModule,       
        MainFormModalModule   
    ],
    declarations: [
        CollectionBuilderMain,
        CollectionBuildersList,
        CollectionBuildersSmallList,
        CollectionBuilderEdit,
        CollectionBuilderDetails,
        CreateMasavFileDlg
    ],
    exports: [        
        CollectionBuildersList,
        CollectionBuildersSmallList,
    ],
    providers: [
        CollectionBuildersData
    ],
    entryComponents: [
        CreateMasavFileDlg
    ]
})
export class CollectionBuildersMdl { }



