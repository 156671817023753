 
import { AuthGuard } from './../../_guards/auth.guard';
import { Routes } from '@angular/router'; 
import { DashboardMarbeMainComponent } from './dashboard-marbe-main.component';

export const DashboardMarbeRoutes: Routes = [
    {
        canActivate: [AuthGuard], path: 'marbe', data: { roles: "marbe", title: 'מרבה תורה' },
        children: [
            { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: DashboardMarbeMainComponent, data: { title: 'מרבה תורה' } },
             // { canActivate: [AuthGuard], path: 'control', component: DashboardMarbeTabsComponent, data: {  title: 'בקרת תשלומים' } },


        ]
    }];
