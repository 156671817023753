
import { Injectable } from '@angular/core';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';

@Injectable()
export class DashboardLocatorDataService {
    private url = 'api/dashboardLocator';
    constructor(
        private toraxHttp: ToraxHttp

    ) { }

    get() {
        return this.toraxHttp.get(`${this.url}/locatorModel`);
    }
}
