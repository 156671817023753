﻿<div *ngIf="honorType">


    <div class="modal-header">
        <div class="row">
            <div class="col-sm-6">
                <h2>פרטי סוג כיבוד [{{ (id > 0 ? id : 'חדש') }}] </h2>
            </div>
            <div class="col-sm-6 text-left">

            </div>

        </div>
    </div>
    <div class="modal-body">
        <dl class="dl-horizontal">
            <dt>תחום</dt>
            <dd> {{ honorType.honorDepartmentName }} </dd>
            <hr>
            <dt>תיאור</dt>
            <dd>{{ honorType.honorTypeDescription }}</dd>
            <hr>
            <dt>טקסט למכתב</dt>
            <dd>{{ honorType.letterText }}</dd>
            <hr>
            <dt>תבנית מכתב</dt>
            <dd><a    [routerLink]="[ '', {outlets: {popup: [ 'letterSettings', honorType.letterSettingID]}}]">{{ honorType.letterSettingTitle }}</a></dd>
            <hr>
            <dt>תבנית מכתב בהתחייבות</dt>
            <dd><a    [routerLink]="[ '', {outlets: {popup: [ 'letterSettings', honorType.commitmentLetterSettingID]}}]">{{ honorType.commitmentLetterSettingTitle }}</a></dd>
            <hr>
        </dl>
    </div>
</div>
