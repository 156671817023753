<!-- <h3 style="color: blue; position: absolute; left:15px" class="label">שים לב! תשלומים המוצגים במדור סטנציה מחושבים לפי
  ערך השקל</h3> -->
  <div [formGroup]="searchRegistrationsListForm">
  <div class="ibox-content m-b-sm border-bottom">
    <div *ngIf="contactID && registrationsDebitBalance != 0" class="row" style="padding: 10px 15px;">
      <span [ngStyle]="{'color': (registrationsDebitBalance < 0 ? 'green' : 'red')}"
        style="font-size: 15px; font-weight: 500;">יתרות: {{ registrationsDebitBalance | number }}</span>
    </div>

    <div *ngIf="!contactID" class="row" style="padding: 10px 15px;">
      <div class="example-container" *ngIf="!contactID ||contactID == 0">
        <div fxLayout="row">
          <div class="input-group mb-3">
            <label>אמצעי תשלום...</label>
            <mat-select apply-enter class="form-control" (selectionChange)="indexService.selectionChanged($event)"
              placeholder="אמצעי תשלום..." [formControl]="searchRegistrationsListForm.controls.paymentOptions" multiple>
              <mat-option [value]="1">אשראי</mat-option>
              <mat-option [value]="2">מזומן</mat-option>
              <mat-option [value]="3">שיקים</mat-option>
              <mat-option [value]="4">הוראות קבע</mat-option>
              <mat-option [value]="5">העברה בנקאית</mat-option>
              <mat-option [value]="6">קופה רושמת</mat-option>
              <mat-option [value]="7">Vouchers</mat-option>
              <mat-option [value]="8">תוכנה חיצונית</mat-option>
              <mat-option [value]="9">שווי כסף</mat-option>
            </mat-select>
          </div>
          &nbsp;&nbsp;
          <auto-complete apply-enter [controllerName]="'RegistrationEvents'" [placeholder]="'אירוע...'"
            [formGroupModel]="searchRegistrationsListForm"
            [idControl]="searchRegistrationsListForm.controls.registrationEventID"
            [nameControl]="searchRegistrationsListForm.controls.registrationEventTitle">
          </auto-complete>
          &nbsp;&nbsp;
          <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש...'"
            [formGroupModel]="searchRegistrationsListForm" [idControl]="searchRegistrationsListForm.controls.contactID"
            [nameControl]="searchRegistrationsListForm.controls.contactName"></auto-complete>
          &nbsp;&nbsp;
          <auto-complete apply-enter [controllerName]="'Shtibels'" [placeholder]="'שטיבל...'"
            [formGroupModel]="searchRegistrationsListForm" [idControl]="searchRegistrationsListForm.controls.shtibelID"
            [nameControl]="searchRegistrationsListForm.controls.shtibelName"></auto-complete>
          &nbsp;&nbsp;
          <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
            (selectChange)="indexService.selectionChanged($event)"
            [listControl]="searchRegistrationsListForm.controls.registrationEventTypes" [placeholder]="'סוג רשומה...'"
            [list]="servicesData.RegistrationEventTypes">
          </mat-select-multiple-dropdown>
          &nbsp;&nbsp;
          <div class="input-group mb-3">
            <label>מס' ארונית...</label>
            <input apply-enter class="form-control" (selectionChange)="indexService.selectionChanged($event)"
              placeholder="מס' ארונית..." formControlName="aronitNumber" />
          </div>
          &nbsp;&nbsp;
          <div class="input-group mb-3">
            <label>יתרות חוב...</label>
            <mat-select apply-enter class="form-control" (selectionChange)="indexService.selectionChanged($event)"
              placeholder="יתרות חוב..." formControlName="withDebtBalance">
              <mat-option [value]="">הכל</mat-option>
              <mat-option [value]="true">כולל</mat-option>
              <mat-option [value]="false">לא כולל</mat-option>
            </mat-select>
          </div>
          &nbsp;
          <span fxFlex class="example-fill-remaining-space"></span>
          <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
            <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
                class="fa fa-filter"></i></button>
            &nbsp;
            <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
                class="material-icons">clear</i></button>
            &nbsp;
            <button mat-mini-fab color="primary" *authGuardDirective="'registrations_add'" title="ייצוא לאקסל" (click)="downloadExcel()">
              <i class="fa fa-file-excel-o"></i></button>
            &nbsp;
            <button mat-mini-fab color="primary" *authGuardDirective="'registrations_add'" title="ייצוא לאקסל כולל יתרות" (click)="downloadExcelWithTotals()">
              <i class="fa fa-file-excel-o"></i></button>
            &nbsp;
            <button mat-mini-fab color="primary" title="תיעוד תשלומי קופה רושמת" (click)="openUpdateCashRegisterDlg()">
              <i class="fa fa-check"></i></button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'registrations', row.registrationID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['registrations', row.registrationID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="registrationsData" [id]="row.registrationID"
                    [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="registrationID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
              רישום </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה רישום">
              <a [routerLink]="[ '', {outlets: {popup: [ 'registrations', row.registrationID]}}]">
                {{ row.registrationID }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שם איש </mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">
              <a [routerLink]="[ '/contacts', row.contactID]">{{ row.firstName }} {{ row.lastName }}</a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="shtibelName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שטיבל </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שטיבל">
              {{row.shtibelName}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="registeringPhone">
            <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון
              רישום
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="טלפון רישום" style="direction: ltr">
              {{row.registeringPhone | phone}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="callerNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון
              מתקשר
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="טלפון מתקשר" style="direction: ltr">
              {{row.callerNumber | phone}}
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="registrationEventTitle">
            <mat-header-cell *matHeaderCellDef> אירוע </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="אירוע">
              <a [routerLink]="[ '', {outlets: {popup: [ 'registrationEvents', row.registrationEventID]}}]">
                {{ row.registrationEventTitle }}
                {{( row.registrationEventType == 4 ? ' [' + (row.aronitNumber ? row.aronitNumber : ' ') + ']' : '') }}
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="eventDate">
            <mat-header-cell *matHeaderCellDef> תאריך אירוע </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך אירוע"> {{ row.jewishEventDate }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מקומות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מקומות"> {{ row.quantity }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="commitmentID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> התחייבות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="התחייבות">
              <a *ngIf="row.commitmentID" [routerLink]="[ '', {outlets: {popup: [ 'commitments', row.commitmentID]}}]">
                {{ row.commitmentID }}</a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="paymentPayMethodTitle">
            <mat-header-cell *matHeaderCellDef> באמצעות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="באמצעות">
              <a *ngIf="row.paymentPayMethodID"
                [routerLink]="[ '', {outlets: {popup: [ 'payMethods', row.paymentPayMethodID]}}]">
                {{ row.paymentPayMethodTitle }}</a>
            </mat-cell>
          </ng-container>

          <!-- <ng-container matColumnDef="paymentPayMethodID">
                        <mat-header-cell *matHeaderCellDef> באמצעות
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" data-label="באמצעות">
                            <div *ngIf="row.paymentPayMethodID">
                                <a *ngIf="row.paymentPayMethodID != 88 && row.paymentPayMethodID != 89 && row.paymentPayMethodID != 420 && row.paymentPayMethodID != 1310 && row.paymentPayMethodID != 3580 && row.paymentPayMethodID != 6232 && row.paymentPayMethodID != 6990"
                                [routerLink]="[ '', {outlets: {popup: [ 'payMethods', row.paymentPayMethodID]}}]">
                                    {{ row.payMethodTitle }}</a>
                                <span *ngIf="row.paymentPayMethodID == 88">מזומן</span>
                                <span *ngIf="row.paymentPayMethodID == 89">שיקים</span>
                                <span *ngIf="row.paymentPayMethodID == 420">העברה בנקאית</span>
                                <span *ngIf="row.paymentPayMethodID == 1310">קופה רושמת</span>
                                <span *ngIf="row.paymentPayMethodID == 3580">Vouchers</span>
                                <span *ngIf="row.paymentPayMethodID == 6232">תוכנה חיצונית</span>
                                <span *ngIf="row.paymentPayMethodID == 6990">שווי כסף</span>
                            </div>
                        </mat-cell>
                    </ng-container> -->

          <ng-container matColumnDef="sumPrice">
            <mat-header-cell *matHeaderCellDef at-sort-header mat-sort-header> ס''ה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="ס''ה">
              {{ row.sumPrice | number }}</mat-cell>
          </ng-container>


          <ng-container matColumnDef="amountPaid">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שולם
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שולם">
              {{ row.amountPaid | number }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="amountToPay">
            <mat-header-cell *matHeaderCellDef mat-sort-header> יתרה לתשלום
            </mat-header-cell>
            <mat-cell [ngStyle]="{'color':(row.amountToPay > 0 ? 'red' : 'green')}" *matCellDef="let row"
              data-label="יתרה לתשלום">
              {{ row.amountToPay | number }} {{ row.currencyIcon }} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.registrationID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
