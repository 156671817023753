import { VouchersOrganizationMain } from './vouchersOrganization/vouchersOrganization-main.cmp';
import { VouchersOrganizationDetails } from './vouchersOrganization/vouchersOrganization-details.cmp';
import { VouchersOrganizationEdit } from './vouchersOrganization/vouchersOrganization-edit.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { AmountAndCurrencyInputModule } from './../components/amount-and-currency-input/amount-and-currency-input.module';
import { VouchersOrgPaymentEditDlg } from './vouchers-org-payment/vouchers-org-payment-edit-dlg.cmp';
import { MaterialModule } from './../material.module';
import { VouchersOrganizationsRoutes } from './vouchersOrganizations.routing';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { VouchersOrganizationsData } from './vouchersOrganizations.data';
import { VouchersOrganizationsList } from './vouchersOrganizations-list.cmp';
import { VouchersOrgPaymentDlg } from './vouchers-org-payment/vouchers-org-payment-dlg.cmp';
import { DateInputModule } from '../components/dateInput/date-input.mdl';
import { PaymentsMdl } from '../payments/payments.mdl';
import { PayMethodsMdl } from '../payMethods/payMethods.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';

@NgModule({
	imports: [
    CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		PageHeadingModule,
		PaginationModule,
		PanelBoxModule,
		FooterEditButtonsMdl,
		RouterModule.forChild(VouchersOrganizationsRoutes),
		MaterialModule,
		DateInputModule,
		AmountAndCurrencyInputModule,
		MainFormModalModule,
		PaymentsMdl,
		PayMethodsMdl
	],
	declarations: [
		VouchersOrganizationsList,
		VouchersOrganizationEdit,
		VouchersOrganizationDetails,
		VouchersOrgPaymentDlg,
		VouchersOrgPaymentEditDlg,
		VouchersOrganizationMain
	],
	exports: [
		VouchersOrganizationsList,
		VouchersOrganizationEdit,
		VouchersOrganizationDetails
	],
	providers: [
		VouchersOrganizationsData
	],
	entryComponents: [
		VouchersOrgPaymentEditDlg,
		VouchersOrgPaymentDlg,
	]

})
export class VouchersOrganizationsMdl { }

