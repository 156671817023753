import { ActivatedRoute, Params } from '@angular/router';
import { DashboardSecretaryGeneralDataService } from './dashboard-secretary-general.data';
import { PaymentsViewDlg } from './../dashboard-payments-view-dlg.cmp';
import { ServicesData } from './../../services/services.data';
import { AlertService } from './../../components/alert/alert.svc';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { ModalService } from './../../components/modal/modal.svc';
import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
    selector: 'dashboard-secretary-general',
    templateUrl: './dashboard-secretary-general.tmpl.html', 
})
export class DashboardSecretaryGeneralComponent implements OnInit {
    //userName: string;
    model: any = {};

    constructor(
        private dashboardSecretaryGeneralDataService: DashboardSecretaryGeneralDataService,
        public servicesData: ServicesData,
        private toraxHttp: ToraxHttp,
        private alert: AlertService,
        private modal: ModalService,
        private activatedRoute: ActivatedRoute,
        ) {
    }



    private openPaymentsView(paymentCommitmentsIds: number[]): void {
        this.modal.show({
            Header: 'פרטי תשלומים',
            BodyComponentType: PaymentsViewDlg,
            Actions: [
                { Text: 'חזור', CssClass: 'btn-default', Action: () => true },
            ],
            Data: paymentCommitmentsIds
        });
    }

    // private openCommitmentView(commitmentItem: any): void {
    //     this.modal.show({
    //         Header: 'פרטי התחייבות',
    //         BodyComponentType: CommitmentViewDlg,
    //         Actions: [
    //             { Text: 'חזור', CssClass: 'btn-default', Action: () => true },
    //         ],
    //         Data: commitmentItem
    //     });
    // }


    // groupedHighPayments() {
    //     this.dashboardSecretaryGeneralDataService.getGroupedHighPayments().subscribe(list => {
    //         this.model.groupedHighPayments = list;
    //     });
    // }

    getCommitmentsAboutToExpire() {
        this.dashboardSecretaryGeneralDataService.getCommitmentsAboutToExpire().subscribe(list => {
            this.model.grpCommitmentsAboutToExpire = list;
        });
    }

    getClosedCommitments() {
        this.dashboardSecretaryGeneralDataService.getClosedCommitments().subscribe(list => {
            this.model.groupClosedCommitments = list;
        });
    }

    ngOnInit() {
        // this.servicesData.getConnectedUserName()
        //     .subscribe(user => {
        //         this.userName = <string>user;
        //     });
        }

}