import { TishGroupsData } from './../tishGroups.data';
import { ServicesData } from './../../services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common'; 
import { TishGroup } from './tishGroup';
export class PaymentOption { }
export class Api { }

@Component({
    
    selector: 'tishGroup-edit',
    templateUrl: './tishGroup-edit.tmpl.html'
})

export class TishGroupEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private tishGroupsData: TishGroupsData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData
    ) {
    }


    tishGroup: TishGroup;
    id: number;
    tishEventID: number;
    tishGroupForm: FormGroup;


    public load(): void {

        if (this.id > 0) {
            this.tishGroupsData.getEdit(this.id)
                .subscribe((tg:any) => {
                    this.tishGroup = tg;
                    this.fillForm(tg);
                });
        } else {
            this.tishGroupsData.getAdd(this.tishEventID)
                .subscribe((tg:any) => {
                    this.tishGroup = tg;
                    this.fillForm(tg);
                });
        }
    }

    private fillForm(tg: TishGroup): void {
        this.tishGroupForm.patchValue(tg);
    }

    public save(): void {
        console.log('save run');
        if (this.tishGroupForm.valid) {
            this.tishGroupsData.update(this.tishGroupForm.value)
                .subscribe((c: any) => {
                    this.location.back();
                    //this.router.navigate(['tishGroups', c.id], { relativeTo: this.activatedRoute });
                });
        } else {
            console.log('not valid');
            console.log(this.tishGroupForm.errors);
        }
    }

    public cancel(): void {
        this.location.back();
    }

    private initForm() {
        this.tishGroupForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            tishGroupID: [this.id || 0, Validators.required],
            tishGroupName: [],
            tishEventID: [, Validators.required],
            tishEventTitle: [],
            description: [],
            comment: [],
        });

    }

    private tishEvents;

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['tishGroupID'];
            this.tishEventID = +params['tishEventID'];
        });
        this.servicesData.getList("TishEvents").subscribe(e => { this.tishEvents = e; }, error => { });
        this.initForm();
        this.load();
        // if (this.id > 0) {
        // } else {
        //     this.tishGroup = new TishGroup();
        //     this.tishGroup.tishGroupID = 0;
        // }
    }
}





