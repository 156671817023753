<div class="row" style="background-color: white;">
  <div style="padding:12px;" class="col-sm-12">
    <div class="col-sm-5">
      <h1 style="margin-top: 0;">
        כיתת הכוננות
      </h1>
    </div>
    <div class="col-sm-7 text-left">

      <button style="background-color: navy;" class="btn btn-primary"
        (click)="viwQueuesList = !viwQueuesList; viwMembersList = false">
        {{(viwQueuesList? 'הסתר רשומות תורים' : 'הצג תורים')}} </button>
      <button style="background-color: navy;" class="btn btn-primary"
        (click)="viwMembersList = !viwMembersList; viwQueuesList = false">
        {{(viwMembersList? 'הסתר רשומות חברים' : 'הצג חברים')}} </button>

    </div>
  </div>
</div>
<div>
  <emergency-squad-queues-list *ngIf="viwQueuesList"></emergency-squad-queues-list>
</div>
<div>
  <emergency-squad-members-list *ngIf="viwMembersList"></emergency-squad-members-list>
</div>
