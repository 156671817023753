import { CommitmentsSelectPick } from '../../commitments/commitments-select-pick/commitments-select-pick.cmp';
import { Commitment } from './../../commitments/commitment/commitment';
import { FormGroup, FormArray, RequiredValidator, ValidatorFn, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ServicesData } from 'src/app/services/services.data';

@Component({
    selector: '[payment-commitment-edit]',
    templateUrl: 'payment-commitment-edit.component.html',
    styleUrls: ['./../payments.scss'],

})
export class PaymentCommitmentEditComponent implements OnInit {

    constructor(
        public servicesData: ServicesData,
        private cdRef: ChangeDetectorRef) {
    }

    subject: number;
    terms;
    commitmentReasons;
    commitment: Commitment;

    @Input('ix') public ix: number;
    @Input('contactID') public contactID: number;
    @Input('paymentFormGroup') public paymentFormGroup: FormGroup;
    @Input('paymentCommitmentFormGroup') public paymentCommitmentFormGroup: FormGroup;
    @Input('openNewPaymentCommitment') public openNewPaymentCommitment: boolean = false;


    @Output() reloadSubjectTerms = new EventEmitter<any>();
    @Output() subjectUpdate = new EventEmitter<any>();


    // get pcFormGroup(): FormGroup {
    //     return this.paymentCommitmentFormGroup as FormGroup;
    // }


    showComponent = true;
    commitmentChangeEvent(commitment) {
        this.showComponent = false;
        let index = this.ix;
        setTimeout(x => {
            this.showComponent = true;
            this.reloadSubjectTerms.emit({ ix: index, subject: commitment.commitment.commitmentSubject });
        });
        //this.paymentCommitmentFormGroup.patchValue(commitment.commitment);
        this.paymentCommitmentFormGroup.controls['commitmentID'].setValue(commitment.id);
        this.paymentCommitmentFormGroup.controls['commitmentSubject'].setValue(commitment.commitment.commitmentSubject);
        this.paymentCommitmentFormGroup.controls['commitmentReasonID'].setValue(commitment.commitment.commitmentReasonID);
        this.paymentCommitmentFormGroup.controls['designation'].setValue(commitment.commitment.designation);
        this.paymentCommitmentFormGroup.controls['designationDetails'].setValue(commitment.commitment.designationDetails);
        this.paymentCommitmentFormGroup.controls['hebYear'].setValue(commitment.commitment.hebYear);
              //        this.contactID = commitment.contactID;
    }


    removepaymentCommitment(i: number) {
        const control = <FormArray>this.paymentFormGroup.controls['paymentCommitments'];
        control.removeAt(i);
    }

    public selectSubject(e: any) {

        if (e && e.value) {
            this.subject = +e.value;
        } else {
            this.subject = +e;
        }


        this.subjectUpdate.emit(this.subject);
        if (this.subject) {
            if (this.subject == 3) {
                this.paymentCommitmentFormGroup.controls['designation'].setValidators(Validators.required);

                this.paymentCommitmentFormGroup.controls['designation'].enable();
                this.paymentCommitmentFormGroup.controls['designationDetails'].enable();

                this.paymentCommitmentFormGroup.controls['designation'].updateValueAndValidity();
            } else {
                this.paymentCommitmentFormGroup.controls['designation'].setValidators(null);

                this.paymentCommitmentFormGroup.controls['designation'].disable();
                this.paymentCommitmentFormGroup.controls['designationDetails'].disable();

                this.paymentCommitmentFormGroup.controls['designation'].updateValueAndValidity();
            }

            this.paymentCommitmentFormGroup.controls['commitmentSubject'].setValue(this.subject);


        } else {
            this.subject = null;
            this.paymentCommitmentFormGroup.controls['commitmentSubject'].setValue(null);
        }
    }


    private loadReasons() {
        let subject = this.paymentCommitmentFormGroup.controls['commitmentSubject'].value;
        if (subject) {
            this.servicesData.getList(`CommitmentReasons/${subject}`).subscribe(c => {
                this.commitmentReasons = c,
                    error => { }
            });
        }
    }


    commitmentSelect(commitment) {
        if (commitment) {
            this.commitment = commitment;

            let sbjct = this.commitment.commitmentSubject;
            if (sbjct && sbjct > 0) {
                this.selectSubject(sbjct)
            }
        }
    }

    ngOnInit(): void {
        let subjectControl = this.paymentCommitmentFormGroup.controls['commitmentSubject'];
        if (subjectControl.value == 0) {
            subjectControl.setValue(null);
        }
        let sbjct = subjectControl.value;

        subjectControl.valueChanges.subscribe(x => {
            if (this.openNewPaymentCommitment) {
                this.loadReasons();

                this.paymentCommitmentFormGroup.updateValueAndValidity();
                this.cdRef.detectChanges();
            }
        });
        if (sbjct && sbjct > 0) {
            this.selectSubject(sbjct);
        }

        //  console.log("payment", this.openNewPaymentCommitment);
        //   console.log("payment", this.showComponent);
    }

}