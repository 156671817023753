import { PayMethodsData } from './payMethods.data';
import { PayMethod } from './payMethod/payMethod';
import { AlertService } from '../components/alert/alert.svc';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { ServicesData } from '../services/services.data';


@Component({
    selector: 'payMethods-select-pick',
    templateUrl: './payMethods-select-pick.tmpl.html'
})

export class PayMethodsSelectPick implements OnInit {

    constructor(
        private servicesData: ServicesData,
        public dialog: MatDialog,
        private cdRef: ChangeDetectorRef,
    ) {

    }

    @Input('contactID') public contactID: number;
    @Input('payMethodIdControl') public payMethodIdControl: FormControl;
    @Input('placeHolder') public placeHolder: string;
    @Input('withLabel') public withLabel: boolean = true;
    @Input('loadOnlyCc') public loadOnlyCc: boolean = false;

    @Input('fromRecordType') public fromRecordType?: string;
    @Input('fromRecordID') public fromRecordID?: number;


    @Output() payMethodSelect = new EventEmitter<any>();
    @Output() payMethodAdd = new EventEmitter<any>();
    @Output() payMethodChange = new EventEmitter<any>();


    public selectedPayMethod: PayMethod;
    payMethods;


    public getPayMethods(selectedPmId?): void {
        this.servicesData.getList(`PayMethods/${this.contactID}/${this.loadOnlyCc}/${selectedPmId | this.payMethodIdControl.value}`).subscribe(
            x => {
                this.payMethods = x;
                this.payMethodIdControl.setValue(selectedPmId);
            });
    }

    // payMethodSelection(e) {
    //     if (e && e.target) {
    //         this.payMethodSelect.emit(+e.target.value);
    //     }
    // }

    payMethodAddEvent(payMethod) {
        this.payMethods = null;
        this.getPayMethods();
        if (payMethod && payMethod.payMethodID > 0) {
            this.payMethodIdControl.setValue(payMethod.payMethodID);
        }
        this.payMethodAdd.emit(payMethod);
        this.cdRef.detectChanges();
    }

    payMethodChangeEvent(payMethod) {
        this.payMethods = null;
        this.getPayMethods(payMethod.payMethodID);
        if (payMethod && payMethod.payMethodID > 0) {
            this.payMethodIdControl.setValue(payMethod.payMethodID);
        }
        this.payMethodChange.emit(payMethod);
        this.cdRef.detectChanges();
    }

    payMethodSelectEvent(payMethod) {
         if (this.selectedPayMethod && this.selectedPayMethod.payMethodID > 0) {
            this.payMethodIdControl.setValue(this.selectedPayMethod.payMethodID);
        } else if(!this.selectedPayMethod){
            this.payMethodIdControl.setValue("");
        }
        //  this.payMethodSelect.emit(payMethod);
    }

    ngOnInit() {
        this.payMethodIdControl.valueChanges.subscribe(x => {
            if (this.payMethods && this.payMethodIdControl && this.payMethodIdControl.value > 0) {
                this.selectedPayMethod = this.payMethods.find(pm => pm.payMethodID == this.payMethodIdControl.value);
                if (this.selectedPayMethod) {
                    this.payMethodSelect.emit(this.selectedPayMethod); //מחליף את האירוע payMethodSelectEvent
                }
            }
        });
        this.getPayMethods(this.payMethodIdControl.value);

    }
}



