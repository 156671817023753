<form>
  <div *ngIf="paymentsPlan">
    <div class="modal-header">
      <h1 style="margin: 0; width: 100%;"> <small>עריכת תוכנית תשלום [{{ (id > 0 ? id : 'חדש') }}]</small> <small
          *ngIf="paymentsPlan.commitment">עבור {{ paymentsPlan.paymentsPlan.commitmentTitle }} </small>
        <br>
        {{ paymentsPlan.contactName }}
        &nbsp;&nbsp;
         <i customToolTip [contentTemplate]="closedTemplate" style="float: left;" class="btn btn-danger fa fa-ban"
        *ngIf="paymentsPlan.closedDate">&nbsp;סגור</i>

        <ng-template #closedTemplate>
          <div style="border-width: 1px; background-color: white; padding: 6px; border-radius: 5px;">
            <h3 style="color: red;text-align: center; text-decoration: underline;">תוכנית נסגרה
            </h3>
            <div style="margin: 0;">
              <p>בתאריך: {{ paymentsPlan.closedDate | date:'dd/MM/yyyy' }} - {{paymentsPlan.jewishClosedDate}}</p>
              <hr>
              <p>סטטוס: {{ paymentsPlan.closedStatusName }}</p>
              <hr>
              <p>הערה: {{ paymentsPlan.closedComment }}</p>
              <hr>
              <h3 class="label">לביטול או עריכה יש ללחוץ על 'פרטי סגירת תוכנית' בחלונית התצוגה
              </h3>
            </div>
          </div>
        </ng-template>
      </h1>
    </div>
    <div class="modal-body">
      <div *ngIf="paymentsPlan" class="example-form" [formGroup]="paymentsPlanForm" novalidate>
        <div class="col-sm-12">
          <fieldset class="form-group">
            <legend>פרטי תוכנית</legend>
            <div fxLayout="row">
              <div fxFlex fxLayout="column">
                <p>
                  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(value)]="amountWay">
                    <mat-button-toggle value="pulseAmount">
                      <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="amountWay == 'pulseAmount'">
                        radio_button_checked</mat-icon>
                      <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="amountWay == 'globalAmount'">
                        radio_button_unchecked</mat-icon>
                      סכום לחיוב בודד
                    </mat-button-toggle>
                    <mat-button-toggle value="globalAmount">
                      <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="amountWay == 'globalAmount'">
                        radio_button_checked</mat-icon>
                      <mat-icon style="vertical-align: sub; font-size: 18px;" *ngIf="amountWay == 'pulseAmount'">
                        radio_button_unchecked</mat-icon>
                      סכום כללי
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                  <span class="example-fill-remaining-space"></span>
                  <span
                    style="white-space: nowrap; float: left;color: blue; position: absolute; left:15px; text-align: end; vertical-align:bottom;"
                    *ngIf="qtyDescription && amountWay == 'globalAmount' && paymentsPlanForm.controls.globalAmount.value">
                    {{qtyDescription}}
                    <!-- {{lastQtyDescription}} -->
                  </span>
                </p>
                <div *ngIf="amountWay == 'globalAmount'" class="form-row" fxLayout="row" fxLayout.xs="column">
                  <div class="input-group mb-3">
                    <label>סכום כללי:</label>
                    <input class="form-control" apply-enter formControlName="globalAmount" placeholder="סכום כללי">
                  </div>&nbsp;
                  <div class="input-group mb-3">
                    <label>מטבע:</label>
                    <select apply-enter class="form-control narrow" formControlName="currency" placeholder="מטבע">
                      <option [value]="null">...</option>
                      <option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                        {{c.name}}
                      </option>
                    </select>
                  </div>&nbsp;
                  <div class="input-group mb-3">
                    <label>מס תשלומים:</label>
                    <input class="form-control" apply-enter formControlName="qty" placeholder="מס תשלומים:">
                  </div>
                </div>
                <div *ngIf="amountWay == 'pulseAmount'" class="form-row" fxLayout="row" fxLayout.xs="column">
                  <div class="input-group mb-3">
                    <label>סכום לתשלום:</label>
                    <input class="form-control" apply-enter formControlName="pulseAmount" placeholder="סכום לתשלום:">
                  </div>&nbsp;
                  <div class="input-group mb-3">
                    <label>מטבע:</label>
                    <select apply-enter class="form-control narrow" formControlName="currency" placeholder="מטבע">
                      <option [value]="">...</option>
                      <option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                        {{c.name}}
                      </option>
                    </select>
                  </div>&nbsp;
                  <div class="input-group mb-3">
                    <label>תוספת אדר אלול:</label>
                    <input class="form-control" apply-enter formControlName="specialtyMonthsAmount"
                      placeholder="תוספת אדר אלול:">
                  </div>
                </div>

                <h4 style="color:red; text-align: right;" fxFlex *ngIf="!unequalAmount">
                  סכום לא תואם עם סכומים בפיצול ההתחייבויות
                </h4>
                <div fxLayout="row">
                  <div fxLayout="column">
                    <payMethods-select-pick [withLabel]="true" (payMethodSelect)="payMethodSelection($event)"
                      (payMethodChange)="payMethodChangeEvent($event)" (payMethodAdd)="payMethodChangeEvent($event)"
                      [contactID]="paymentsPlan.contactID" [placeHolder]="'אמצעי תשלום'"
                      [payMethodIdControl]="paymentsPlanForm.controls.payMethodID" [fromRecordType]="'PaymentsPlan'"
                      [fromRecordID]="paymentsPlanForm.controls.paymentsPlanID.value">
                    </payMethods-select-pick>

                  </div>

                  <mat-error fxLayout="row" style="color:blue" *ngIf="payMethodAmountNotification">
                    {{payMethodAmountNotification}}
                  </mat-error>
                  <mat-error fxLayout="row" style="color:blue" *ngIf="payMethodDateNotification">
                    {{payMethodDateNotification}}
                  </mat-error>
                  <div fxLayout="row"
                    *ngIf="([88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(+payMethodID) == -1) && terms">
                    <div fxLayout="column">
                      <div fxLayout="column" matTooltip="יש לבחור מסוף לסליקת אשראי, לאחר בחירת נושא ההתחייבות"
                        class="input-group mb-3">
                        <label>מסוף:</label>
                        <mat-select class="form-control" placeholder="מסוף:" formControlName="defaultTermID">
                          <mat-option [value]="">...</mat-option>
                          <mat-option *ngFor="let api of terms" [value]="api.id">
                            {{api.title}}
                          </mat-option>
                        </mat-select>
                      </div>
                      <input-info fxLayout="row" style="align-self: end;"
                        [control]="paymentsPlanForm.controls.defaultTermID">
                        יש לעדכן מסוף לסליקת אשראי,<br>לאחר בחירת נושא ההתחייבות
                      </input-info>
                    </div>
                  </div>

                </div>
              </div>
              <div fxLayout="column">
                <span
                  style="margin-top: -25px; font-size: 14px; text-decoration: underline; text-align: end; white-space: nowrap;">
                  <i *ngIf="!paymentsPlanForm.controls.holdingsSetting" (click)="createNewHoldingsSetting()"
                    matTooltip="ערוך תוכנית רשומות החזקת ביהמ''ד" class="fa fa-pencil"> &nbsp;ערוך תוכנית רשומות החזקת
                    ביהמ"ד</i>
                  <i *ngIf="paymentsPlanForm.controls.holdingsSetting" (click)="removeHoldingsSetting()"
                    matTooltip="בטל הגדרת רשומות החזקת ביהמ''ד" class="fa fa-pencil"> &nbsp;בטל הגדרת רשומות החזקת
                    ביהמ"ד</i>
                </span>
                <fieldset class="form-group" style="margin-right: 15px;"
                  *ngIf="paymentsPlanForm.controls.holdingsSetting">
                  <paymentsPlan-holdings-setting [formData]="paymentsPlanForm.controls.holdingsSetting">
                  </paymentsPlan-holdings-setting>
                </fieldset>
              </div>
            </div>

            <hr>
            <div fxLayout="row">
              <div fxLayout="column">
                <fieldset class="form-group">
                  <label class="header-label">תקופת תוכנית</label>
                  <div class="form-row" fxLayout="row" fxLayout.xs="column">
                    <date-input [placeholder]="'החל מתאריך:'" [dateControl]="paymentsPlanForm.controls.fromDate">
                      Loading...</date-input>
                    &nbsp;
                    <div class="input-group mb-3">
                      <label>תדירות חיוב:</label>
                      <mat-select apply-enter class="form-control" (selectionChange)="selectType($event)"
                        formControlName="paymentsPlanType" placeholder="תדירות חיוב">
                        <mat-option *ngFor="let ppt of servicesData.PaymentsPlanTypes" [value]="ppt.value">
                          {{ppt.name}}
                        </mat-option>
                      </mat-select>
                    </div>&nbsp;
                    <div *ngIf="!specialTypeSelected" class="input-group mb-3">
                      <label>בדילוג:</label>
                      <input class="form-control narrow" apply-enter formControlName="repeats" placeholder="בדילוג:">
                      <!-- <small *ngIf="paymentsPlanTypeName">&nbsp;כל&nbsp;{{ paymentsPlanTypeName.slice(0, -1) }}</small> -->
                    </div>
                  </div>
                  <div *ngIf="!specialTypeSelected" class="form-row" fxLayout="row" fxLayout.xs="column">
                    <mat-button-toggle-group style="height: fit-content; margin-top: 23px;"
                      formControlName="hebrewDate">
                      <mat-button-toggle [value]="true">
                        <mat-icon style="vertical-align: sub; font-size: 18px;"
                          *ngIf="paymentsPlanForm.controls.hebrewDate.value == true">
                          radio_button_checked</mat-icon>
                        <mat-icon style="vertical-align: sub; font-size: 18px;"
                          *ngIf="paymentsPlanForm.controls.hebrewDate.value != true">
                          radio_button_unchecked</mat-icon>
                        כל חודש עברי (יהודי)
                      </mat-button-toggle>
                      <mat-button-toggle [value]="false">
                        <mat-icon style="vertical-align: sub; font-size: 18px;"
                          *ngIf="paymentsPlanForm.controls.hebrewDate.value != true">
                          radio_button_checked</mat-icon>
                        <mat-icon style="vertical-align: sub; font-size: 18px;"
                          *ngIf="paymentsPlanForm.controls.hebrewDate.value == true">
                          radio_button_unchecked</mat-icon>
                        כל חודש לועזי
                      </mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                  <div *ngIf="!specialTypeSelected" class="form-row" fxLayout="row" fxLayout.xs="column">
                    <div class="input-group mb-3"
                      *ngIf="jewishDayOptions && paymentsPlanForm.controls.hebrewDate.value == true">
                      <label>יום בחודש עברי:</label>
                      <select class="form-control" apply-enter formControlName="dayOfMonth"
                        placeholder="יום בחודש עברי">
                        <option *ngFor="let d of jewishDayOptions" [value]="d.value">
                          {{d.label}}
                        </option>
                      </select>
                    </div>
                    <div class="input-group mb-3"
                      *ngIf="dayOptions && paymentsPlanForm.controls.hebrewDate.value == false">
                      <label>יום בחודש לועזי:</label>
                      <select apply-enter class="form-control" formControlName="dayOfMonth"
                        placeholder="יום בחודש לועזי">
                        <option *ngFor="let d of dayOptions" [value]="d.value">
                          {{d.label}}
                        </option>
                      </select>
                    </div>
                  </div>

                </fieldset>
              </div>
              &nbsp;&nbsp;
              <div fxLayout="column">
                <fieldset class="form-group">
                  <label class="header-label">הגבלת תשלומים</label>
                  <div class="form-row" fxLayout="row" fxLayout.xs="column"
                    style="margin-bottom: -20px; white-space: nowrap; vertical-align: middle;">
                    <div class="input-group mb-3" *ngIf="amountWay != 'globalAmount'">
                      <label>מס תשלומים:</label>
                      <input class="form-control" apply-enter formControlName="qty" placeholder="מס תשלומים:">
                    </div>
                    <span *ngIf="amountWay != 'globalAmount'"
                      style="margin: 30px auto; align-self: center; text-align: center">&nbsp;או</span>&nbsp;&nbsp;
                    <date-input fxFlex="70" [placeholder]="'עד לתאריך:'"
                      [dateControl]="paymentsPlanForm.controls.tillDate">
                      Loading...
                    </date-input>
                  </div>
                  <span fxLayout="row" style="white-space: nowrap ;color: blue; vertical-align:bottom;"
                    *ngIf="amountWay != 'globalAmount'">המוקדם מביניהם!
                  </span>
                </fieldset>
              </div>
            </div>
            <hr>
            <div fxLayout="column" style="overflow: auto">
              <label class="header-label">שיוך להתחייבויות:</label>
              <div style="position: relative; width: 100%; top: -36px; text-align: center;" class="form-row">
                <span class="example-fill-remaining-space"></span>
                <div style="background: white; float: left;">
                  <h4 style="color: blue; margin: 4px;" *ngIf="paymentsPlan.isOpenOtherCommitmentsForContact">
                    <span style="font-size: 22px;">☜</span> שים לב! קיימות התחייבויות נוספות פתוחות
                    לאיש
                    <span style="font-size: 22px;">☞</span>
                    <!-- 🢠 -->
                  </h4>
                  <button class="btn btn-circle btn-success"
                    *ngIf="id == 0 && paymentsPlanForm.controls.pulseAmount.value && paymentsPlanForm.controls.payPlanCommitments.length > 1"
                    matTooltip="חשב תשלומים באופן שווה" (click)="calculatePayPlanCommitmentsAmounts()">
                    <i class="fa fa-calculator"></i>
                  </button>
                  &nbsp;
                  <!-- *ngIf="paymentsPlan.isOpenOtherCommitmentsForContact" -->
                  <button apply-enter type="button" (click)="addPayPlanCommitmentControl(null,false)">
                    הוסף מתוך התחייבויות פתוחות
                  </button>
                  &nbsp;
                  <button type="button" (click)="addPayPlanCommitmentControl(null,true)">
                    הוסף התחייבות חדשה
                  </button>
                </div>
              </div>
              <table class="table table-border" style="margin-top: -16px;"
                *ngIf="paymentsPlanForm.controls.payPlanCommitments.length > 0">
                <thead class="text-nowrap">
                  <th class="narrow"> ... </th>
                  <th>נושא</th>
                  <th class="narrow">שנה</th>
                  <th>סיבה</th>
                  <th class="narrow">סכום יחסי לתש'</th>
                  <th class="narrow">אדר/אלול</th>
                  <th class="narrow">ייעוד מיוחד</th>
                  <th class="narrow">תיאור ייעוד</th>
                  <th class="narrow">...</th>

                </thead>
                <tbody formArrayName="payPlanCommitments">
                  <ng-container
                    *ngFor="let payPlanCommitmentFormGroup of paymentsPlanForm.controls.payPlanCommitments.controls; let i=index"
                    [formGroupName]="i">
                    <tr pay-plan-commitment-edit (changeList)="updateAmounts($event)"
                      (reloadSubjectTerms)="reloadSubjectTermsEvent($event)"
                      [openNewPayPlanCommitment]="payPlanCommitmentFormGroup.controls.openNewPayPlanCommitment.value"
                      [contactID]="contactId" [ix]="i" [paymentsPlanFormGroup]="paymentsPlanForm"
                      [payPlanCommitmentFormGroup]="payPlanCommitmentFormGroup">
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </fieldset>
        </div>

        <div class="col-sm-12">
          <fieldset class="form-group">
            <legend>מידע נוסף</legend>
            <div fxLayout="row">
              <div fxLayout="column">
                <label class="header-label">פרטי קבלות בתוכנית תשלום זו <small>(ברירת מחדל)</small>:</label>
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                  <label>שם לקבלות:</label>
                  <input class="form-control" apply-enter formControlName="defaultContactTitleForReceipts"
                    placeholder="שם לקבלות:">
                </div>&nbsp;
                <addresses-select-pick *ngIf="paymentsPlanForm.controls.contactID.value > 0" fxFlex
                  (addressAdd)="addressAddEvent($event)" (addressChange)="addressChangeEvent($event)"
                  [contactID]="paymentsPlanForm.controls.contactID.value" [placeHolder]="'כתובת לקבלה'"
                  [addresIdControl]="paymentsPlanForm.controls.defaultReceiptAddressID">
                </addresses-select-pick>&nbsp;
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <mat-checkbox apply-enter formControlName="defaultDetailsLetterAsReceipt">
                  הצג שם זה גם במכתב תודה
                </mat-checkbox>

              </div>
            </div>

            <div fxLayout="column" style="margin-right: 100px; width: -webkit-fill-available;">

              <div class="input-group mb-3" style="min-width: 100%" >
                <label>הערה:</label>
                <textarea class="form-control comment" style="min-width: 100%" apply-enter rows="3"
                  placeholder="הערה..." formControlName="comment"></textarea>
              </div>
            </div>
          </div>

          </fieldset>
          <!-- &nbsp;
          <fieldset class="form-group" fxLayout="column" [disabled]="true">
            <legend>פרטי סגירת תוכנית</legend>
            <div fxLayout="column">
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <date-input [placeholder]="'תאריך סגירת התוכנית'" [dateControl]="paymentsPlanForm.controls.closedDate">
                  Loading...
                </date-input>
              </div>
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                  <label>סטטוס:</label>
                  <select class="form-control" apply-enter placeholder="סטטוס:"
                    [formControl]="paymentsPlanForm.controls.closedStatus">
                    <option value="">...</option>
                    <option *ngFor="let cs of servicesData.ClosedStatuses" [value]="cs.value">
                      {{cs.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row" fxLayout="row" fxLayout.xs="column">
                <div class="input-group mb-3">
                  <label>הערת סגירה:</label>
                  <textarea class="form-control comment" apply-enter rows="1" placeholder="הערת סגירה..."
                    formControlName="closedComment"></textarea>
                </div>
              </div>
            </div>
          </fieldset> -->
        </div>


        <!-- <div class="col-sm-12" style="margin-top: 20px; padding: 0;">
                    <button (click)="openHoldingsSetting()">ערוך הגדרות</button>
                    <fieldset class="form-group" *ngIf="paymentsPlanForm.controls.holdingsSetting.value">
                        <paymentsPlan-holdings-setting
                            [holdingsSetting]="paymentsPlanForm.controls.holdingsSetting.value">
                        </paymentsPlan-holdings-setting>
                    </fieldset>
                </div> -->
      </div>
    </div>


  </div>
  <footer-edit-buttons [confirmationMessageBeforeSaving]="confirmationMessageBeforeSaving" [errorMsg]="errorMsg"
    [dataProvider]="paymentsPlansData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
    [modelFormGroup]="paymentsPlanForm">
    <!-- [displaySaveButton]="id == 0"  -->
    <!-- <ng-template #customHtmlButtons >

        <button *ngIf="id > 0" type="button" apply-enter [disabled]="!paymentsPlanForm.valid" (click)="saveChanges(paymentsPlanForm)"
                class="btn btn-primary navbar-btn">
                <i class="fa fa-save"></i> שמור שינויים
            </button>
        </ng-template> -->
  </footer-edit-buttons>
</form>
