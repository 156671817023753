import { filter, tap, take } from 'rxjs/operators';
import { Router, RouterEvent, NavigationStart } from '@angular/router';
import { Payment } from 'src/app/payments/payment/payment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentsData } from '../payments.data';
import { Component, OnInit, Inject } from '@angular/core';


@Component({
    selector: 'payment-details-dlg',
    templateUrl: './payment-details-dlg.tmpl.html'
})
export class PaymentDetailsDlg   {
    constructor(
        public matDialogRef: MatDialogRef<PaymentDetailsDlg>,        
        @Inject(MAT_DIALOG_DATA)
        public data: Payment ,        
        router: Router
    ) {  
 
        // Close dialog ref on route changes
        router.events.pipe(
          filter((event: RouterEvent) => event instanceof NavigationStart),
          tap(() => this.matDialogRef.close()),
          take(1),
        ).subscribe();
      }
}

