import { TishEventDetails } from './tishEvent/tishEvent-details.cmp';
import { TishEventMap } from './tishEvent/tishEvent-map.cmp';
import { TishEventMain } from './tishEvent/tishEvent-main.cmp';
import { TishInvitedMain } from './../tishInviteds/tishInvited/tishInvited-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { TishEventsList } from './tishEvents-list.cmp';


export const TishEventsRoutes: Routes = [
  { canActivate: [AuthGuard], path: 'tishEvents', component: TishEventsList, data: { roles: "tishEvents", title: 'אירועים' } },
  { canActivate: [AuthGuard], path: 'tishEvents/add', component: TishEventMain, data: { mode: "edit", roles: "tishevents_add", title: 'הוסף אירוע' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'tishEvents/:tishEventID', component: TishEventDetails, data: { roles: "tishEvents", title: 'פרטי אירוע' } },
  { canActivate: [AuthGuard], path: 'tishEvents/:tishEventID/edit', component: TishEventMain, data: { mode: "edit", roles: "tishEvents", title: 'עריכת פרטי אירוע' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'tishEvents/:tishEventID/map', component: TishEventMap, data: { roles: "tishEvents", title: 'מפת אירוע' } },


  { canActivate: [AuthGuard], path: 'tishEvents/:tishEventID/tishAreas/add', component: TishEventMain, data: { mode: "edit", roles: "tishAreas", title: 'הוסף איזור' }, outlet: 'popup' },

  { canActivate: [AuthGuard], path: 'tishEvents/:tishEventID/tishInviteds/add', component: TishInvitedMain, data: { mode: "edit", roles: "tishInviteds", title: 'הוסף זימון' }, outlet: 'popup' },

];

