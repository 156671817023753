import { MaterialModule } from './../material.module';
import { AuthorizeTransactionsRoutes } from './authorizeTransactions.routing';
import { AuthorizeTransactionsList } from './authorizeTransactions-list.cmp';
import { AuthorizeTransactionsData } from './authorizeTransactions.data';  

import { DateInputModule } from '../components/dateInput/date-input.mdl';
import { HoldingsMdl } from '../holdings/holdings.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
    imports: [
        CommonModule, 
         FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule, 
        DateInputModule,
        RouterModule.forChild(AuthorizeTransactionsRoutes), 
        MaterialModule
    ],
    declarations: [
        AuthorizeTransactionsList,
    ],
    exports: [
        AuthorizeTransactionsList,
    ],
    providers: [
        AuthorizeTransactionsData
    ]
})
export class AuthorizeTransactionsMdl { }

