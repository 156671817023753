
import { style } from '@angular/animations';

import { Component, OnInit, ViewChild, HostListener, ElementRef, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
    selector: 'date-time-input',
    templateUrl: 'date-time-input.tmpl.html',
    styleUrls: ['./date-time-input.css']
})



export class DateTimeInput implements OnInit, AfterViewInit {
    dt?;
    time?;
    constructor() { }

    @Input('withBorder') withBorder: Boolean = false;

    @Input('dateControl') dateControl: FormControl;
    @Input('timeControl') timeControl: FormControl;
    @Input('rangeTemplate') rangeTemplate?: boolean = false;
    @Input('placeholder') placeholder?: string = 'בחר תאריך...';

    @ViewChild('dateInput') dateInput: ElementRef;
    @ViewChild('timeInput') timeInput: ElementRef;


    @Output() dateChange = new EventEmitter<Date>();

    // public onChange(e) {
    //     if (e) {
    //         e.preventDefault();
    //     }
    //     // this.dateChange.emit(this.dateControl.value);
    // }

    public obserDate: Observable<ElementRef> = new Observable<ElementRef>();


    ngOnInit() {
        if (this.dateControl && this.dateControl.value != null) {
            var tempDate = (<Date>this.dateControl.value).toString();
            if (new Date(this.dateControl.value) > new Date('01-01-01')) {
                this.dateControl.setValue(tempDate);
                this.dt = new Date(tempDate);
            }
        }
        if (this.timeControl && this.timeControl.value != null) {
            var tempTime = (<any>this.timeControl.value).toString();
            if (this.dateControl.value) {
                this.timeControl.setValue(tempTime);
                this.time = this.timeControl.value;
            }

        }
    }

    ngAfterViewInit(): void {
        let dateChangeEvent = this.dateChange;
        let dateCtrl = this.dateControl;
        let heJewish = (<any>$).bililite.flexcal.tol10n(['he-jewish', { dateFormat: 'dddd MM YYYY' }]);
        let georgi = (<any>$).bililite.flexcal.tol10n(['en', { dateFormat: 'dd/mm/yyyy' }]);
        let mn: any;
        mn = jQuery(this.dateInput.nativeElement);
        mn.removeClass('hasDatepicker');
        mn.flexcal({
            calendars: [
                [
                    'he',
                    'לועזי',
                    {
                        closeText: "סגור",
                        prevText: "&#x3C;הקודם",
                        nextText: "הבא&#x3E;",
                        currentText: "היום",
                        monthNames: ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
                        monthNamesShort: ["ינו", "פבר", "מרץ", "אפר", "מאי", "יוני", "יולי", "אוג", "ספט", "אוק", "נוב", "דצמ"],
                        dayNames: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
                        dayNamesShort: ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "שבת"],
                        dayNamesMin: ["א'", "ב'", "ג'", "ד'", "ה'", "ו'", "שבת"],
                        dateFormat: "dd/mm/yyyy",
                        isRTL: true,
                    }
                ],
                [
                    'he-jewish',
                    'עברי',
                    {
                        currentText: "היום",
                        isRTL: true,
                    }
                ],
            ],
            position: 'br',
            duration: 1,
            changeMonth: true,
            changeYear: true,
            // buttons: ['today commit'],
            set: this.formatDate(heJewish, georgi, dateCtrl, dateChangeEvent),
            commit: this.formatDate(heJewish, georgi, dateCtrl, dateChangeEvent, true),
            hidden: function (e, d) {
                if (e.target.value == "") {
                    $(this).siblings("#jewishDate").text("");
                }
                //dcc.emit(e.target.value);
            },
            create: function (e, d) {
                if (e.target.value && e.target.value != "") {
                    d = new Date(e.target.value);
                    let j = (<any>$(this)).flexcal('format', d, heJewish);
                    $(this).siblings("#jewishDate").text(j);
                    let g = (<any>$(this)).flexcal('format', d, georgi);

                }
            },
        });
        //  this.ndt.nativeElement.click();


    }



    private formatDate(heJewish: any, georgi: any, dateCtrl: FormControl, dcc: any, emitChangeEvent: boolean = false) {
        return function (e, d) {
            let j = (<any>$(this)).flexcal('format', d, heJewish);
            $(this).siblings("#jewishDate").text(j);
            let g = (<any>$(this)).flexcal('format', d, georgi);
            dateCtrl.setValue(g);
            if (emitChangeEvent) {
                dcc.emit(g);
            }
        };
    }
}

