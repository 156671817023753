import { AmountRangeClass } from './../../components/amountInput-range/amount-input-range.class';
import { DashboardDirectorDataService } from './dashboard-director.data';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ServicesData } from 'src/app/services/services.data';
import { DateRangeClass } from 'src/app/components/dateInput-range/date-input-range.class';
import { DateInputRange } from 'src/app/components/dateInput-range/date-input-range.cmp';

// import * as shape from 'd3-shape';
// import * as d3 from 'd3';

import * as d3Scale from 'd3-scale';

@Component({
    selector: 'dashboard-director-charts',
    templateUrl: './dashboard-director-charts.tmpl.html',
})
export class DashboardDirectorChartsComponent implements OnInit {
    constructor(
        private dashboardDirectorDataService: DashboardDirectorDataService,
        private formBuilder: FormBuilder,
        private router: Router,
        private servicesData: ServicesData
    ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    window.localStorage.setItem('previousUrl', event.url);
                }
            });
          //לצורך איפוס בשעת פיתוח כשמשתנים שמות השדות  sessionStorage.setItem("dashboard/commitments/dbCommitments", "");
    }
    @ViewChild('fromDateRange') fromDateRange: DateInputRange;
    @ViewChild('doneOnRange') doneOnRange: DateInputRange;

    commitmentsChartSearchModelForm: FormGroup;
    paymentsChartSearchModelForm: FormGroup;

    loadPosModeByCommitments() {
        sessionStorage.setItem(`dashboard/commitments/dbCommitments`, JSON.stringify(this.commitmentsChartSearchModelForm.value));
        this.dashboardDirectorDataService.getPosModeByCommitments(this.commitmentsChartSearchModelForm.value)
            .subscribe(db => {
                this.fillCommitmentsPosMode(db);
            });
    }

    loadPosModeByPayments() {
        sessionStorage.setItem(`dashboard/payments/dbPayments`, JSON.stringify(this.paymentsChartSearchModelForm.value));

        this.dashboardDirectorDataService.getPosModeByPayments(this.paymentsChartSearchModelForm.value)
            .subscribe(db => {
                let status = this.paymentsChartSearchModelForm.controls['statusPayments'].value;
                if (status != null && status.length > 0) {
                    this.colorScheme.domain = [];
                    status.forEach(element => {
                        if (element == 1) {
                            this.colorScheme.domain.push(this.statusWaiting);
                        } else if (element == 2) {
                            this.colorScheme.domain.push(this.statusSucceeded);
                        }
                        else if (element == 3) {
                            this.colorScheme.domain.push(this.statusFailed);
                        }
                    });
                }
                this.fillPaymentsPosMode(db);
            });
    }
    chartWidth = 1700;
    fillPaymentsPosMode(model) {
        this.chartList = model.chartList;
        this.chartWidth = (this.chartList.length * 140)
        this.view = [this.chartWidth, 150];

        this.sumWaiting = model.sumWaitingPayments;
        this.sumSucceeded = model.sumSucceededPayments;
        this.sumFailed = model.sumFailedPayments;
        
        this.fromCommitmentsSumSucceededPayments = model.fromCommitmentsSumSucceededPayments;
        this.fromCommitmentsSumWaitingPayments = model.fromCommitmentsSumWaitingPayments;
    }

    fillCommitmentsPosMode(model) {
        this.sumCommitmentsGlobalAmount = model.sumCommitmentsGlobalAmount;
        this.sumCommitmentsPaid = model.sumCommitmentsPaid;
        this.sumCommitmentsBalance = model.sumCommitmentsBalance;
    }

    topNewCommitments;

    chartList;
    sumWaiting;
    sumSucceeded;
    sumFailed;

    sumCommitmentsGlobalAmount;
    sumCommitmentsPaid;
    sumCommitmentsBalance;
    fromCommitmentsSumSucceededPayments;
    fromCommitmentsSumWaitingPayments;
    view: any[] = [1700, 150];

    
    
    statusWaiting = '#5AA454';
    statusSucceeded = '#C7B42C';
    statusFailed = '#A10A28';
    
    colorScheme = {
        domain: [this.statusSucceeded, this.statusWaiting, this.statusFailed]
    };
    
    colorScale: any;

    generateColorScale() {
        if (this.chartList) {
            const values: number[] = this.chartList.map((s: any) => s.value);
            return d3Scale.scaleLinear()
                .domain([Math.min(...values), Math.max(...values)])
                .range(['rgb(161, 10, 40)', 'rgb(38, 139, 77)']);
            //.range(['#542c52', '#f703e7']);
        }
    }

    customColors = (name) => {
        if (this.chartList) {
            const value = (<any>this.chartList.find((s: any) => s.name === name)).value;
            return this.colorScale(value);
        }
    }

    navigatToCommitments() {
        sessionStorage.setItem("dashboard/commitments/dbCommitments", JSON.stringify(this.commitmentsChartSearchModelForm.value));
        this.router.navigateByUrl(`/commitments`);
    }

    navigatToPayments(statusPayment) {
        this.paymentsChartSearchModelForm.controls['statusPayments'].setValue([statusPayment]);
        sessionStorage.setItem("dashboard/payments/dbPayments", JSON.stringify(this.paymentsChartSearchModelForm.value));
        this.router.navigateByUrl(`/payments`);
    }

    private initCommitmentsChartForm() {
        let commitmentSubjects = new Array();
        commitmentSubjects.push(4);

        let dt = new Date();
        let dateRange = new DateRangeClass();
        dateRange.start = new Date(2016, 1);
        dateRange.end = new Date(dt.getFullYear(), dt.getMonth());
        dateRange.includeNulls = false;
        let dateRangeForm = this.formBuilder.group({
            start: [dateRange.start],
            end: [dateRange.end],
            includeNulls: [dateRange.includeNulls, Validators.required]
        });

        let amountRange = new AmountRangeClass();
        amountRange.min = 0;
        amountRange.max = null;
        amountRange.includeNulls = false;
        let amountRangeForm = this.formBuilder.group({
            min: [amountRange.min],
            max: [amountRange.max],
            includeNulls: [amountRange.includeNulls, Validators.required]
        });

         //התחייבויות מקושרות לא מוכללות
         let fundraisingTypes = new Array();
         fundraisingTypes.push(4);
 
         //קבוצות לא מוכללות
         let groupIdsToExludeContacts = new Array();
         groupIdsToExludeContacts.push(33);


        this.commitmentsChartSearchModelForm = this.formBuilder.group({
            commitmentSubjects: [commitmentSubjects],
            fromDateRange: dateRangeForm,
            globalAmountRange: amountRangeForm,
            groupIdsToExludeContacts: [groupIdsToExludeContacts],
            fundraisingTypes: [fundraisingTypes]
        });
    }

    private initPaymentsChartForm() {
        let statusPayments = new Array();
        statusPayments.push(1);
        statusPayments.push(2);
        statusPayments.push(3);

        let commitmentSubjects = new Array();
        commitmentSubjects.push(4);

        let accountLocations = new Array();
        accountLocations.push(1);
        accountLocations.push(2);
        accountLocations.push(3);

        let dt = new Date();
        let dateRange = new DateRangeClass();
        dateRange.start = new Date(2016, 1);
        dateRange.end = new Date(dt.getFullYear(), dt.getMonth());
        dateRange.includeNulls = false; //כולל לא תאריכים עקב בעיות שלא לכולם יש תאריך ביצוע
        let dateRangeForm = this.formBuilder.group({
            start: [dateRange.start],
            end: [dateRange.end],
            includeNulls: [dateRange.includeNulls, Validators.required]
        });

         //התחייבויות מקושרות לא מוכללות
        let fundraisingTypes = new Array();
        fundraisingTypes.push(4);

        //קבוצות לא מוכללות
        let groupIdsToExludeContacts = new Array();
        groupIdsToExludeContacts.push(33);


        this.paymentsChartSearchModelForm = this.formBuilder.group({
            statusPayments: [statusPayments],
            commitmentSubjects: [commitmentSubjects],
            accountLocations: [accountLocations],
            groupIdsToExludeContacts: [groupIdsToExludeContacts],
            doneOnRange: dateRangeForm,
            fundraisingTypes: [fundraisingTypes]
        });
    }

    private getMonth(dt: Date) {
        let m = dt.getMonth() + 2;
        return `${m}`.padStart(2, '0');
    }

    private getDay(dt: Date) {
        let d = dt.getDay() + 1;
        return `${d}`.padStart(2, '0');
    }

    clearCommitmentsForm() {
        this.fromDateRange.startInput.ndt.nativeElement.value = '';
        this.fromDateRange.endInput.ndt.nativeElement.value = '';
        this.fromDateRange.startInput.ndtJewish.nativeElement.textContent = '';
        this.fromDateRange.endInput.ndtJewish.nativeElement.textContent = '';
        this.initCommitmentsChartForm();
        this.loadPosModeByCommitments();
    }
    clearPaymentsForm() {
        this.doneOnRange.startInput.ndt.nativeElement.value = '';
        this.doneOnRange.endInput.ndt.nativeElement.value = '';
        this.doneOnRange.startInput.ndtJewish.nativeElement.textContent = '';
        this.doneOnRange.endInput.ndtJewish.nativeElement.textContent = '';
        this.initPaymentsChartForm();
        this.loadPosModeByPayments();
    }
    ngOnInit() {
        this.initCommitmentsChartForm();
        this.initPaymentsChartForm();

        let commitmentsItem = sessionStorage.getItem(`dashboard/commitments/dbCommitments`);
        if (commitmentsItem) {
            let frm = JSON.parse(sessionStorage.getItem(`dashboard/commitments/dbCommitments`) || "");
            this.commitmentsChartSearchModelForm.patchValue(frm);
        }
        //this.loadPosModeByCommitments();

        let paymentsItem = sessionStorage.getItem(`dashboard/payments/dbPayments`);
        if (paymentsItem) {
            let frm = JSON.parse(sessionStorage.getItem(`dashboard/payments/dbPayments`) || "");
            this.commitmentsChartSearchModelForm.patchValue(frm);
        }
     //   this.loadPosModeByPayments();


    }
}
