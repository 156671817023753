<div class="modal-header" *ngIf="payMethodEdit &&  payMethodEdit.payMethod">
    <h1> <small>עריכת פרטי אמצעי תשלום [{{ (payMethodEdit.id > 0 ? payMethodEdit.id : 'חדש') }}] </small>
        <br>
        {{ payMethodEdit.payMethod.contactName }}
    </h1>
</div>

<div class="modal-body">
    <payMethod-edit #payMethodEdit [contactID]="contactId" [payMethodID]="id" [model]="payMethod">
    </payMethod-edit>
</div>

<footer-edit-buttons  [onSaveGoToBack]="true" [dataProvider]="payMethodEdit.payMethodsData" [router]="router"
    [id]="payMethodEdit.id" [disabledDeleteButton]="false" [modelFormGroup]="payMethodEdit.payMethodForm">
</footer-edit-buttons>


