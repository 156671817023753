import { first } from 'rxjs/operators';
import { Commitment } from '../commitment/commitment';
import { AlertService } from '../../components/alert/alert.svc';
import { CommitmentsData } from '../commitments.data';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ServicesData } from '../../services/services.data';


@Component({
    selector: 'commitments-select-pick',
    templateUrl: './commitments-select-pick.tmpl.html',
    styleUrls: ['./commitments-select-pick.scss']
})

export class CommitmentsSelectPick implements OnInit {

    constructor(
        private servicesData: ServicesData,
        public dialog: MatDialog
    ) {

    }

    @Input('contactID') public contactID: number;
    @Input('includeIsFundraisings') public includeIsFundraisings: boolean = false;
    @Input('onlyOpenCommitments') public onlyOpenCommitments: boolean = true;
    @Input('commitmentIdControl') public commitmentIdControl: FormControl;
    @Input('placeHolder') public placeHolder: string;
    @Input('withLabel') public withLabel: boolean = true;
    @Output() commitmentAdd = new EventEmitter<Commitment>();
    @Output() commitmentChange = new EventEmitter<Commitment>();
    @Output() commitmentSelect = new EventEmitter<Commitment>();


    public selectedCommitment: Commitment;
    public commitments;
    public getCommitments(): void {       
        let cid = this.commitmentIdControl && this.commitmentIdControl.value > 0 ? this.commitmentIdControl.value : null;
        this.servicesData.getList(`ContactCommitments/${this.contactID}/${this.includeIsFundraisings}/${this.onlyOpenCommitments}/${cid}`).subscribe(
            c => {
                this.commitments = c;
            },
            error => { });
    }

    openAddOrEditCommitment() {
        // console.log("selectedCommitment",this.selectedCommitment)  ;
    }
    commitmentAddEvent(commitment) {
        this.commitments = null;
        this.getCommitments();
        if (commitment && commitment.id > 0) {
            this.commitmentIdControl.setValue(commitment.id);
        }
        this.commitmentAdd.emit(commitment);
    }

    commitmentChangeEvent(commitment) {
        this.commitments = null;
        this.getCommitments();
        if (commitment && commitment.id > 0) {
            this.commitmentIdControl.setValue(commitment.id);
        }
        this.commitmentChange.emit(commitment);
    }

    commitmentSelectEvent() {
        this.commitmentSelect.emit(this.selectedCommitment);
    }

    // private setCommitmentIDValue() {
    //     if(this.commitmentIdControl.value){
    //         this.selectedCommitment = this.commitments.find(x => x.commitmentID == this.commitmentIdControl.value);
    //     }
    //     //this.commitmentIdControl.setValue(commitmentID);
    // }



    ngOnInit() {

        this.commitmentIdControl.valueChanges.subscribe(x => {
            if (this.commitments) {
                this.selectedCommitment = this.commitments.find(x => x.commitmentID == this.commitmentIdControl.value);
                this.commitmentSelect.emit(this.selectedCommitment); //מחליף את האירוע commitmentSelectEvent
            }
        });
        this.getCommitments();
    }
}



