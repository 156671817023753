<div [formGroup]="searchYarzeitsListForm" style="border:solid">

  <div class="example-container" *ngIf="indexService" fxLayout="column">
    <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
      [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
      <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
        [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
        matSort matSortDisableClear matSortDirection="asc">

        <!-- <ng-container matColumnDef="select">
            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
              <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox apply-enter (click)="$event.stopPropagation()" (change)="$event ? rowToggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container> -->

        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'yarzeits', row.yarzeitID]}}]">
                <mat-icon>pageview</mat-icon>
                <span>פרטים מלאים</span>
              </a>

              <a mat-menu-item [routerLink]="['', {outlets: {popup: ['yarzeits', row.yarzeitID, 'edit']}}]">
                <mat-icon>edit</mat-icon>
                <span>עריכה</span>
              </a>

              <a mat-menu-item>
                <mat-icon>information</mat-icon>
                <model-info style="padding: 4px" [dataProvider]="yarzeitsData" [id]="row.yarzeitID"
                  [title]="'מידע רשומה'">
                </model-info>
              </a>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="yarzeitID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="מזהה">
            <a [routerLink]="[ '', {outlets: {popup: [ 'yarzeits', row.yarzeitID]}}]">
              {{ row.yarzeitID }}
            </a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="relevantLastName">
          <mat-header-cell *matHeaderCellDef>שם</mat-header-cell>
          <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם">
            <a [routerLink]="[ '/contacts', row.relevantContactID]">{{ row.relevantLastName }} {{ row.relevantFirstName
              }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="relevantCityName">
          <mat-header-cell *matHeaderCellDef>עיר</mat-header-cell>
          <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="עיר">
            {{ row.relevantCityName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nextYarzeitDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך יארצייט</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="תאריך יארצייט">
            {{row.jewishDay}} {{row.jewishMonth}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="yarzeitType">
          <mat-header-cell *matHeaderCellDef> יחס</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="יחס">
            יא"צ {{ row.yarzeitType }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="yarzeitsDetails">
          <mat-header-cell *matHeaderCellDef> פרטים</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="פרטים">
            <span style="font-weight: bold;"> {{ row.firstName }} {{ row.lastName }}</span>
            <span *ngIf="row.motherName && (row.yarzeitType  == 'שוויגער' || row.yarzeitType  == 'אמא')">
              בת ר' {{
              row.motherName}}</span>
            <span *ngIf="row.fatherName && (row.yarzeitType  == 'אבא' || row.yarzeitType  == 'שווער')"> בן
              ר' {{
              row.fatherName}}</span>
            נלב"ע
            {{ row.jewishDeathDate }}
            <span *ngIf="row.burialPlace && (row.yarzeitType  == 'שוויגער' || row.yarzeitType  == 'אמא')">ומנוחתה
              כבוד {{ row.burialPlace }}</span>
            <span *ngIf="row.burialPlace && (row.yarzeitType  == 'אבא' || row.yarzeitType  == 'שווער')">ומנוחתו
              כבוד {{ row.burialPlace }}</span>

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.yarzeitID)">
        </mat-row>
      </mat-table>
    </cdk-virtual-scroll-viewport>
  </div>
  <div fxFlex="15">
    <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
      [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
    </mat-paginator>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
