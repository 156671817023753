import { CommonModule } from '@angular/common';
import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@Pipe({
    name: 'phone'
})

export class PhonePipe implements PipeTransform {
    public transform(value: string, args: string[]): any {
        if (!value) return value;

        let s2 = ("" + value).replace(/\D/g, '');
        if (value.length <= 9) {
            let m = s2.match(/^(\d{2})(\d{1,7})$/);
            return (!m) ? null : m[1] + "-" + m[2];

        } else if (value.length <= 10) {
            let m2 = s2.match(/^(\d{3})(\d{1,10})$/);
            return (!m2) ? null : m2[1] + "-" + m2[2];
        }
        else {
            let m3 = s2.match(/^(\d{3})(\d{3})(\d{1,10})$/);
            return (!m3) ? null : "(" + m3[1] + ")" + m3[2] + "-" + m3[3];
        }
    }
}




@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [PhonePipe],
    declarations: [PhonePipe]
})
export class PhonePipeMdl { }
