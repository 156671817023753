import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { HonorTypesList } from './honor-types-list.cmp';  
import { HonorTypeMain } from './honor-type/honor-type-main.cmp';

export const HonorTypesRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'honorTypes', component: HonorTypesList, data: {  roles: "honorTypes",  title: 'רשימת סוגי כיבודים' } },
    { canActivate: [AuthGuard], path: 'honorTypes/add', component: HonorTypeMain, data: { mode: 'edit', roles: "honorTypes", title: 'הוסף סוג כיבוד' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'honorTypes/:honorTypeID', component: HonorTypeMain, data: {  roles: "honorTypes",  title: 'פרטי סוג כיבוד' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'honorTypes/:honorTypeID/edit', component: HonorTypeMain, data: { mode: 'edit', roles: "honorTypes",  title: 'עריכת פרטי סוג כיבוד' }, outlet: 'popup' }
]; 
