<form class="example-form" [formGroup]="contactCompensationForm" novalidate>
    <div *ngIf="contactCompensation" matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>{{ (id > 0 ? 'עריכת פרטי' : 'הוספת') }} פעילות יח"צ</small>
            <br>
            {{ contactCompensation.contactName }}
        </h2>

    </div>
    <mat-dialog-content style="padding:0px 24px 24px 24px">
        <div *ngIf="contactCompensation" fxLayout="column" style="display: block;">
          
            <div class="input-group mb-3">
                <label>נושא:</label>
                <input class="form-control" apply-enter formControlName="subject" placeholder="נושא..." />
            </div> 
            <div class="input-group mb-3"  style="width: 100% !important;" >
                <label>תיאור:</label>
                <textarea rows="4" class="comment-white" placeholder="תיאור..."
                formControlName="description"></textarea>
            </div>
            <div class="input-group mb-3">
                <date-input [placeholder]="'תאריך:'" apply-enter
                    [dateControl]="contactCompensationForm.controls.compensationDate"></date-input>
            </div>
          
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="end">
        <button [disabled]="contactCompensationForm.invalid" class="btn btn-primary navbar-btn"
            [mat-dialog-close]="contactCompensationForm"><i class="fa fa-save"></i> שמור </button>
        <button class="btn btn-warning navbar-btn" *ngIf="contactCompensation.contactCompensationID > 0"
            [mat-dialog-close]="contactCompensationForm" (click)="delete()"><i class="fa fa-warning"></i> מחק
        </button>
     

    </mat-dialog-actions>