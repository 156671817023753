<div [formGroup]="searchPayMethodsListForm">
  <div *ngIf="!contactID || contactID == 0" class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">
      <div class="input-group mb-3">
        <label>הכל...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)" placeholder="הכל..."
          formControlName="isOneTime">
          <mat-option [value]="">הכל</mat-option>
          <mat-option [value]="false">לשימוש חוזר</mat-option>
          <mat-option [value]="true">חד פעמי</mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'" *ngIf="paymentOptionsList"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchPayMethodsListForm.controls.paymentOptions" [placeholder]="'אמצעי תשלום...'"
        [list]="paymentOptionsList">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>מסוף...</label>
        <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)" placeholder="מסוף..."
          formControlName="termID">
          <mat-option [value]="">...</mat-option>
          <mat-option *ngFor="let api of servicesData.Apis" [value]="api.value">
            {{ api.name }}
          </mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>מס' כרטיס/חשבון:</label>
        <input class="form-control" formControlName="ccNumber" />
      </div>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>חודש תוקף:</label>
        <input class="form-control narrow" formControlName="expiryMonth" />
      </div>
      &nbsp;&nbsp;
      <div class="input-group mb-3">
        <label>שנה תוקף:</label>
        <input class="form-control narrow" formControlName="expiryYear" />
      </div> &nbsp;&nbsp;
      <span fxFlex class="example-fill-remaining-space"></span>
      <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
        <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
            class="fa fa-filter"></i></button>
        &nbsp;
        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
            class="material-icons">clear</i></button>
        &nbsp;
        <button mat-mini-fab color="primary" title="ייצוא לאקסל" (click)="exportExcel()">
          <i class="fa fa-file-excel-o"></i></button>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center" class="min-action-header" style="padding: 3px 20px;">
    <span class="example-fill-remaining-space"></span>
    <button fxLayout="column" (click)="loadAll()"
      style="min-width: 10px  !IMPORTANT; min-height: 10px  !IMPORTANT;  line-height: 10px; padding:4px; background-color: blue; color:white"
      matTooltip="הצג גם הסטוריה" mat-stroked-button>
      <mat-icon>history</mat-icon>
    </button>
  </div>

  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">

              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['payMethods', row.payMethodID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['payMethods', row.payMethodID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="payMethodsData" [id]="row.payMethodID"
                    [title]="'מידע רשומה'"></model-info>
                </a>
              </mat-menu>

            </mat-cell>

          </ng-container>

          <ng-container matColumnDef="payMethodID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה">
              <a [routerLink]="[ '', {outlets: {popup: [ 'payMethods', row.payMethodID]}}]">
                {{ row.payMethodID }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שם איש </mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">
              <a [routerLink]="[ '/contacts', row.contactID]">{{ row.firstName }}
                {{ row.lastName }}</a>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="paymentOption">
            <mat-header-cell *matHeaderCellDef mat-sort-header> סוג </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סוג">
              {{row.paymentOptionName}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="cardHebName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> סוג אשראי </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="חב' אשראי">
              {{row.cardHebName}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="methodTitle">
            <mat-header-cell *matHeaderCellDef>כותרת </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="כותרת">
              {{row.methodTitle}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="termName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מסוף </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מסוף">
              {{row.termName}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="accountLocation">
            <mat-header-cell *matHeaderCellDef mat-sort-header> חשבון </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="חשבון">
              {{row.accountLocationName}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="isOneTime">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שמור במערכת </mat-header-cell>
            <mat-cell [ngStyle]="{'color':(row.isOneTime > 0 ? 'red' : 'green')}" *matCellDef="let row"
              data-label="שמור במערכת">
              <i *ngIf="!row.isOneTime" class="fa fa-check-square"></i>
              <i *ngIf="row.isOneTime" class="fa fa-window-close-o"></i>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="lastExecutionDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> פעילות אחרונה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="פעילות אחרונה">
              {{row.lastExecutionDate | date:'dd/MM/yyyy'}}<br>{{ row.jewishLastExecutionDate }}
            </mat-cell>
          </ng-container>



          <ng-container matColumnDef="createDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> נוצר בתאריך </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="נוצר בתאריך">
              {{row.createDate | date:'dd/MM/yyyy'}}<br>{{ row.jewishCreateDate }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="inactiveDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך סגירה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך סגירה">
              {{row.inactiveDate | date:'dd/MM/yyyy'}} <br>{{ row.jewishInactiveDate }}
            </mat-cell>
          </ng-container>



          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;let index = dataIndex;"
            [ngClass]="{alternate: index % 2 != 0 }" (dblclick)="openSelectedRow(row.paymentID)">
          </mat-row>

          <ng-container matColumnDef="groupHeader">
            <!-- <td colspan="999"  [ngStyle]="{'height': (!group.value) ? '0px' : 'height: 20px !IMPORTANT;'}" mat-cell *matCellDef="let group">
              <strong *ngIf="!group.value">{{(!group.value ? 'ממתין' : '')}}</strong>
            </td> -->
            <td colspan="999" style="height: 20px !IMPORTANT" mat-cell *matCellDef="let group">
              <strong>{{(group.value ? 'מוסתר &#8681;' : 'פתוח &#8681;' )}}</strong>
            </td>
          </ng-container>
          <ng-container matColumnDef="groupReducer">
            <td colspan="1" [ngStyle]="{'height': (group.value) ? '0px' : 'height: 20px !IMPORTANT;'}" mat-cell
              *matCellDef="let group">

            </td>
          </ng-container>

          <tr mat-row class="clickable" style="background-color: #ceced2 !important;"
            [ngStyle]="{'height': ( indexService.isGroup) ? '0px' : 'height: 20px !IMPORTANT;'}"
            *matRowDef="let row; columns: ['groupReducer','groupHeader']; when: indexService.isGroup">
          </tr>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>
