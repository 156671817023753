import { MaterialModule } from './../../material.module';
import { DirectivesMdl } from './../../components/directives/directives-module';
import { FooterEditButtonsMdl } from './../../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../../components/pagination/pagination.mdl';
import { PanelBoxModule } from './../../components/panel-box/panel-box.mdl';
import { PageHeadingModule } from './../../components/page-heading/page-heading.mdl';
import { ContactGroupEditDlg } from './contactGroup/contactGroup-edit-dlg.cmp';
import { ContactGroupsList } from './contactGroups-list.cmp';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactGroupsData } from './contactGroups.data';
//import { TextMaskModule } from 'angular2-text-mask';
import { ContactGroupDetails } from './contactGroup/contactGroup-details.cmp';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { ContactGroupListDlg } from './contactGroups-list-dlg.cmp';
import { AutoCompleteModule } from 'src/app/components/‏‏auto-complete/auto-complete.mdl';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,
        MaterialModule,
        AutoCompleteModule
    ],
    declarations: [
        ContactGroupsList,
        ContactGroupDetails,
        ContactGroupEditDlg,
        ContactGroupListDlg
    ],
    exports: [
        ContactGroupsList,
        ContactGroupDetails,
        ContactGroupEditDlg,
        ContactGroupListDlg
    ],
    providers: [
        ContactGroupsData
    ],
    entryComponents: [
        ContactGroupEditDlg,
    ]
})
export class ContactGroupsMdl { }



