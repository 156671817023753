
<div *ngIf="expectPaymentsInMonths" fxLayout="column" class="model-section">
  <dl class="dl-horizontal">
    <dt>צפי תשלומים בחודשים</dt>
    <dd></dd>
    <div fxLayout="row" style="overflow-x: scroll;">
      <div *ngFor="let item of expectPaymentsInMonths.itemsList" style="margin:5px">
        <mat-card fxLayout="column" class="example-card">
          <mat-card-header style="padding: 5px !IMPORTANT;">
            <mat-card-title>
              <div fxLayout="row">
                <!-- <span class="example-fill-remaining-space"></span> -->
                <h4 style="font-weight: 400; font-size: 14px; text-align: center;">{{item.name}}</h4>
              </div>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content style="padding: 5px 15px !IMPORTANT;">
            <h1 style="font-size: 24px; margin: 20px 10px 10px 10px !IMPORTANT;"> {{item.value | number}}
              {{item.currency}}</h1>
            <br>
            <section class="example-section" style="padding: 0px !IMPORTANT;">
              <mat-progress-bar class="example-margin" [color]="color" [mode]="mode" [value]="item.percentage">
              </mat-progress-bar>
              <div> {{item.percentage | number }}% </div>
            </section>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <hr fxLayout="row">
  </dl>
</div>



<!--
<div  loading-status [stopLoadingWhen]="daysList" class="model-section">
  <div class="example-button-row" style="white-space: nowrap; padding: 10px; text-align: center;" fxLayout="row">
    <button style="color: white; background-color: navy;" title="קודם" (click)="prevWeek()"><i
        class="fa fa-arrow-right"></i></button>
    <span class="example-fill-remaining-space"></span>
    <h1>{{fromTitle}} - {{tillTitle}}</h1>
    <span class="example-fill-remaining-space"></span>
    <button style="color: white; background-color: navy;" title="הבא" (click)="nextWeek()"><i
        class="fa fa-arrow-left"></i></button>

  </div>
  <div class="scroll-container" fxLayout="row" fxLayout.xs="row" fxLayoutAlign="flex-start stretch">

    <div fxFlex *ngFor="let day of daysList" [appScrollableItem]="day.date" (click)="selectDate(day)" style="padding:5px"
      [ngClass]="{'focus-calendar-day':  selectedDate && selectedDate.date == day.date}">

      <mat-card fxLayout="column" class="example-card" style="cursor: pointer;">
        <mat-card-header style="padding: 5px !IMPORTANT;">
          <mat-card-title>
            <div fxLayout="row">
              <span class="calendar-header">
                יום {{day.jewishHebDayInWeek}} {{day.parasha}}
              </span>
              <span class="example-fill-remaining-space"></span>
              <span class="calendar-header">
                {{day.date | date:'dd/MM/yyyy'}}
              </span>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="padding: 5px 15px !IMPORTANT;">
          <h1 class="calendar-center-title"> {{day.jewishDate }}</h1>
          <br>
          <hr>
          <div fxLayout="column">
            <div fxLayout="row" class="calendar-amount-row">
              <mat-icon matPerfix style="color: green;">
                check_circle_outline</mat-icon>
              <span fxflex *ngIf="day.succeededAmount"> {{ day.succeededAmount | number:'1.0-0'}} ₪ </span>
            </div>
            <hr>
            <div fxLayout="row" class="calendar-amount-row">
              <mat-icon matPerfix style="color: rgb(240, 206, 14)">
                warning
              </mat-icon>
              <span fxflex *ngIf="day.waitingAmount"> {{ day.waitingAmount | number:'1.0-0'}} ₪ </span>
            </div>
            <hr>
            <div fxLayout="row" class="calendar-amount-row">
              <mat-icon matPerfix style="color: red;">close
              </mat-icon>
              <span fxflex *ngIf="day.failedAmount"> {{ day.failedAmount | number:'1.0-0'}} ₪ </span>
            </div>
            <hr>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div *ngIf="daysList" fxLayout="row" style="padding: 0px 16px; background-color: #f2d42b0d;">
  <h2 *ngIf="selectedDate; else choiceTitle" class="font-bold" style="text-align: center; margin: 5px auto">
    {{selectedDateTitle}}
    <br>
    {{selectedDate.date | date:'dd/MM/yyyy'}}
  </h2>
  <ng-template #choiceTitle>
    <h3 style="text-align: center; margin: 5px auto"> בחר יום כדי להציג נתונים</h3>
  </ng-template>
</div>

<div *ngIf="selectedDate">
  <dashboard-tracking-accounts [selectedDate]="selectedDate"></dashboard-tracking-accounts>
</div> -->
