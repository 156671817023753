import { ServicesData } from 'src/app/services/services.data';
import { CitiesData } from './../cities.data';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { Location } from '@angular/common';
import { City } from './city';

@Component({
    
    selector: 'city-edit',
    templateUrl: './city-edit.tmpl.html'
})

export class CityEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private citiesData: CitiesData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData,
  
    ) {
    }

    city: City;
    id: number;
    contactId: number;
    cityForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.citiesData.getEdit(this.id || 0)
                .subscribe((c:any)  => {
                    this.city = c;
                    this.cityForm.patchValue(c);
                });
        } else {
            this.citiesData.getAdd(this.contactId)
                .subscribe((c:any)  => {
                    this.city = c;
                    this.cityForm.patchValue(c);
                });
        }
    }

    private initForm() {
        this.cityForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            cityID: [this.id || 0, Validators.required],
            cityName: [],
            cityNameEn:[],
            stateID: [],
        });
    }



    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['cityID'];
        });
        this.initForm();
        this.load();
    }
}



