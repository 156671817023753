import { Component, OnInit } from '@angular/core';
import { DepositsData } from './deposits-data';
import { th } from 'date-fns/locale';

@Component({
  selector: 'deposits-waitings-warnings',
  templateUrl: './deposits-waitings-wornings.tmpl.html',
})
export class DepositsWaitingsWarnings implements OnInit{

  depositBookModelsShotef: any[];
  depositBookModelsKerenHabinyan: any[];

  constructor(
    private depositsData: DepositsData
    ) { }


  load() {
    this.depositsData.groupWatingsToDeposits_IL()
      .subscribe((x: any[]) => {
        this.depositBookModelsShotef = x.filter(x => x.commitmentSubject == 5);
        this.depositBookModelsKerenHabinyan = x.filter(x => x.commitmentSubject == 4);
      });
    }

  ngOnInit(): void {
    this.load();
  }
}
