<div style="z-index: 999; text-align:left; position: absolute; left: 0; font-size: 24px" class="example-button-row">
    <button mat-fab color="primary" title="הוסף" (click)="addNew()" aria-label="הוסף חדש">
        <i class="material-icons">add</i>
    </button> &nbsp;
</div>
<form  [formGroup]="searchUsersListForm">

    <div class="example-container">
        <div class="bounds">
            <div class="content" style="height: 70%; overflow: auto">
                <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                    class="example-table" matSort matSortDisableClear matSortDirection="asc">
                   
                    <ng-container matColumnDef="buttons">
                        <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <button mat-icon-button (click)="openDetails(row);" aria-label="עריכה...">
                                <i style="color: gray;" matTooltip="עריכה" class="fa fa-pencil"></i>
                            </button>
                        </mat-cell>
                    </ng-container>
                   
                    <ng-container matColumnDef="userName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> שם משתמש </mat-header-cell>
                        <mat-cell  *matCellDef="let row" data-label="משתמש">
                            {{row.userName}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> שם משפחה </mat-header-cell>
                        <mat-cell  *matCellDef="let row" data-label="שם משפחה">
                            {{row.lastName}}
                        </mat-cell>
                     </ng-container>
                    <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> שם פרטי </mat-header-cell>
                        <mat-cell  *matCellDef="let row" data-label="שם פרטי">
                            {{row.firstName}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="departmentName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> מחלקה/מדינה </mat-header-cell>
                        <mat-cell  *matCellDef="let row" data-label="מחלקה/מדינה">
                            {{row.departmentName}}
                        </mat-cell>
                      </ng-container>

                   


                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                        (dblclick)="openSelectedRow(row.userID)">
                    </mat-row>
                </mat-table>
            </div>
        </div>

        <!-- <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
            [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator> -->
    </div>
</form>