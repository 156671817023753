﻿<div *ngIf="campaignAttached">


    <div class="modal-header">
        <div class="row">
            <div class="col-sm-6">
                     <h2>{{ campaignAttached.attachedName }} </h2> 
            </div>
        
        </div>
    </div>
    <div class="modal-body">
        <dl class="dl-horizontal">
           
            <dt>שם קמפיין</dt>
            <dd *ngIf="campaignAttached.campaign"> {{ campaignAttached.campaign.campaignName }} </dd>
            <hr>
            <dt>כמות התחייבות</dt>
            <dd> {{ campaignAttached.count }} </dd>
            <hr>
            <dt>כמות שולם</dt>
            <dd>{{ campaignAttached.countPaid }} </dd>
            <hr> 
            <dt>מזהה איש</dt>
            <dd>{{ campaignAttached.contactID }} </dd>
            <hr> 
            <dt>הערה</dt>
            <dd>{{ campaignAttached.comment }} </dd>
            <hr> 
        </dl> 
    </div>
</div>