
//import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TimeInput } from './time-input.cmp';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import { DateInputModule } from '../dateInput/date-input.mdl';
@NgModule({
    imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatTimepickerModule
],
    exports: [
        TimeInput
    ]
    ,
    declarations: [
        TimeInput
    ]
})
export class TimeInputModule { }

