import { RegistrationMain } from './registration/registration-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { RegistrationsGroupList } from './registrations-group-list.cmp';
import { Routes } from '@angular/router';
import { RegistrationsList } from './registrations-list.cmp'; 

export const RegistrationsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'registrations', component: RegistrationsList, data: { roles: "registrations", title: 'רישומים' } },
    { canActivate: [AuthGuard], path: 'registrations/:contactID/registrations/add', component: RegistrationMain, data: { mode: "edit", roles: "registrations_add", title: 'הוסף רישום' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'registrations/:registrationID', component: RegistrationMain, data: { roles: "registrations", title: 'פרטי רישום' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'registrations/:registrationID/edit', component: RegistrationMain, data: { mode: "edit", roles: "registrations", title: 'עריכת פרטי רישום' }, outlet: 'popup' },

    { canActivate: [AuthGuard], path: 'registrations/groupRegistrations', component: RegistrationsGroupList, data: { title: 'רשימות מקובץ לאנשים וסוגים' } },
];

