import { Routes } from "@angular/router";
import { CommitmentsList } from "src/app/commitments/commitments-list.cmp";
import { AuthGuard } from "src/app/_guards";
import { DashboardDirectorReportsComponent } from "./dashboard-director-reports.cmp";



export const DashboardDirectorReportsRouting: Routes = [
  {
    canActivate: [AuthGuard], path: 'directorReports', data: { roles: "dashboardDirectorReports", title: 'כספים לגביה' },
    children: [
      { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: DashboardDirectorReportsComponent, data: { title: 'כספים לגביה' } },
      { canActivate: [AuthGuard], path: 'commitmentsTreatment/:subject', component: CommitmentsList, data: { roles: "commitmentsTreatment", title: 'התחייבויות לגביה' } },

    ]
}];
