import { CurrencyPipeMdl } from './../components/pipes/currency-pipe';
import { TransferTasksMain } from './transfer-tasks-main.cmp';
import { TransferTasksList } from './transfer-tasks-list.cmp';
import { MaterialModule } from './../material.module';
import { TransferTasksRoutes } from './transfer-tasks.routing';
import { DateMonthInputModule } from './../components/dateMonth-input/dateMonth-input.mdl';
import { DateMonthRangeModule } from './../components/dateMonth-range/dateMonth-range.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { TransferTasksData } from './transfer-tasks.data';
import { DateInputRangeModule } from '../components/dateInput-range/date-input-range.mdl';
import { TransferTasksExlsDlg } from './transfer-tasks-exls-dlg.cmp';
import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { TransferTaskConseContactDlg } from './transfer-task/transfer-task-conse-contact-dlg.cmp';
import { PaymentsMdl } from '../payments/payments.mdl';


@NgModule({
  imports: [
    AutoCompleteModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PageHeadingModule,
    PanelBoxModule,
    DateMonthRangeModule,
    DateMonthInputModule,
    MaterialModule,
    CurrencyPipeMdl,
    DateInputRangeModule,
    ModelInfoModule,
    RouterModule.forChild(TransferTasksRoutes),
    PaymentsMdl

  ],
  declarations: [
    TransferTasksList,
    TransferTasksExlsDlg,
    TransferTasksMain,
    TransferTaskConseContactDlg,
  ],
  exports: [
    TransferTasksList,
  ],
  providers: [
    TransferTasksData
  ],
  entryComponents: [
    TransferTasksExlsDlg,
  ]
})
export class TransferTasksMdl { }



