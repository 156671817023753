<form [formGroup]="emergencySquadMemberForm" novalidate (ngSubmit)="save()" *ngIf="emergencySquadMember">
    <div class="modal-header">
        <h1>עריכת פרטי חבר [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div *ngIf="emergencySquadMember" class="example-form">
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>פרטי חבר</legend>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div style="width: 250px !important;" class="input-group mb-3">
                            <label>שם איש:</label>
                            <h3> {{ emergencySquadMember.contactName }}</h3>
                        </div>
                    </div>


                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="emergencySquadMembersData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
        [modelFormGroup]="emergencySquadMemberForm"></footer-edit-buttons>

</form>
