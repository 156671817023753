
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CurrencyPipeMdl } from './../../components/pipes/currency-pipe';
import { DashboardMarbesDataService } from './dashboard-marbe.data';
import { AuthGuardMdl } from './../../_guards/auth.guard.mdl';
import { MaterialModule } from './../../material.module';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DashboardMarbeRoutes } from './dashboard-marbe.routing';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardMarbeMainComponent } from './dashboard-marbe-main.component';
import { DashboardMarbeControlComponent } from './dashboard-marbe-control.component';
import { DashboardMarbeCitiesComponent } from './dashboard-marbe-cities.component';
import { DashboardMarbeMoniesComponent } from './dashboard-marbe-monies.component';
import { DashboardMarbeShtibelsComponent } from './dashboard-marbe-shtibels.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(DashboardMarbeRoutes),
    ScrollingModule,
    AuthGuardMdl,
    MaterialModule,
    CurrencyPipeMdl,
    //NgxChartsModule,

  ],
  declarations: [
    DashboardMarbeCitiesComponent,
    DashboardMarbeMainComponent,
    DashboardMarbeControlComponent,
    DashboardMarbeMoniesComponent,
    DashboardMarbeShtibelsComponent,
  ],
  entryComponents: [
    DashboardMarbeControlComponent,
    DashboardMarbeShtibelsComponent
  ],
  exports: [
    DashboardMarbeMainComponent,
    DashboardMarbeControlComponent,
    DashboardMarbeShtibelsComponent,
  ],
  providers: [DashboardMarbesDataService]
})
export class DashboardMarbeModule {

}
