<ng-container [formGroup]="payPlanCommitmentFormGroup">
    <td style="padding: 1px;">
        <commitment-add-action [editIcon]="true" [commitment]="commitment"
            [commitmentID]="payPlanCommitmentFormGroup.controls.commitmentID.value"
            [contactID]="payPlanCommitmentFormGroup.controls.contactID.value"
            (commitmentChange)="commitmentChangeEvent($event)">
        </commitment-add-action>
        &nbsp; &nbsp;
        {{ (payPlanCommitmentFormGroup.controls.commitmentID.value  > 0 ? payPlanCommitmentFormGroup.controls.commitmentID.value : (payPlanCommitmentFormGroup.controls.openNewPayPlanCommitment.value ? '[חדש]' : 'בחר...')) }}
    </td>
    <td style="padding: 1px;" *ngIf="!payPlanCommitmentFormGroup.controls.openNewPayPlanCommitment.value && showComponent" colspan="3">
        <commitments-select-pick  [withLabel]="false" (commitmentSelect)="commitmentSelect($event)" [includeIsFundraisings]="false"
            (commitmentChange)="commitmentChangeEvent($event)" (commitmentAdd)="commitmentChangeEvent($event)"
            [contactID]="payPlanCommitmentFormGroup.controls.contactID.value" [placeHolder]="'התחייבויות'"
            [commitmentIdControl]="payPlanCommitmentFormGroup.controls.commitmentID">
        </commitments-select-pick>
    </td>
    <td style="padding: 1px;" *ngIf="payPlanCommitmentFormGroup.controls.openNewPayPlanCommitment.value"
        [ngClass]="{'has-error': payPlanCommitmentFormGroup.controls.commitmentSubject.invalid}">
        <mat-select class="form-control" style="width: 100% !important;" (selectionChange)="selectSubject($event)"
            formControlName="commitmentSubject">
            <mat-option [value]="null">...</mat-option>
            <mat-option *ngFor="let cs of servicesData.CommitmentSubjects" [value]="cs.value">
                {{cs.name}}
            </mat-option>
        </mat-select>
    </td>
    <td style="padding: 1px;" *ngIf="payPlanCommitmentFormGroup.controls.openNewPayPlanCommitment.value"
        [ngClass]="{'has-error': payPlanCommitmentFormGroup.controls.hebYear.invalid}">
        <mat-select style="width: 84px; min-width: 84px;" class="form-control" formControlName="hebYear">
            <mat-option value="''">...</mat-option>
            <mat-option value="תשעו">תשעו</mat-option>
            <mat-option value="תשעז">תשעז</mat-option>
            <mat-option value="תשעח">תשעח</mat-option>
            <mat-option value="תשעט">תשעט</mat-option>
            <mat-option value="תשפ">תשפ</mat-option>
            <mat-option value="תשפא">תשפא</mat-option>
            <mat-option value="תשפב">תשפב</mat-option>
            <mat-option value="תשפג">תשפג</mat-option>
            <mat-option value="תשפד">תשפד</mat-option>
            <mat-option value="תשפה">תשפה</mat-option>
            <mat-option value="תשפו">תשפו</mat-option>
            <mat-option value="תשפז">תשפז</mat-option>
            <mat-option value="תשפח">תשפח</mat-option>
            <mat-option value="תשפט">תשפט</mat-option>
            <mat-option value="תשצ">תשצ</mat-option>
            <mat-option value="תשצא">תשצא</mat-option>
            <mat-option value="תשצב">תשצב</mat-option>
            <mat-option value="תשצג">תשצג</mat-option>
        </mat-select>

    </td>
    <td style="padding: 1px;" *ngIf="payPlanCommitmentFormGroup.controls.openNewPayPlanCommitment.value"
        [ngClass]="{'has-error': payPlanCommitmentFormGroup.controls.commitmentReasonID.invalid}">
        <mat-select apply-enter class="form-control narrow" style="width: 100% !important;"
            formControlName="commitmentReasonID">
            <mat-option [value]="">...</mat-option>
            <mat-option *ngFor="let cr of commitmentReasons" [value]="cr.commitmentReasonID">
                {{cr.name}}
            </mat-option>
        </mat-select>
    </td>

    <td style="padding: 1px;" [ngClass]="{'has-error': payPlanCommitmentFormGroup.controls.partialPulseAmount.invalid}">
        <input type="text" class="form-control narrow" style="width: 100% !important;" placeholder="סכום יחסי לתש'..."
            formControlName="partialPulseAmount" />
    </td>
    <td style="padding: 1px;"
        [ngClass]="{'has-error': payPlanCommitmentFormGroup.controls.partialSpecialtyMonthsAmount.invalid}">
        <input type="text" class="form-control narrow" placeholder="סכום אדר-אלול..." style="width: 100% !important;"
            formControlName="partialSpecialtyMonthsAmount" />
    </td>

    <td style="padding: 1px;" [ngClass]="{'has-error': payPlanCommitmentFormGroup.controls.designation.invalid}">
        <input type="text" class="form-control narrow" formControlName="designation" style="width: 100% !important;"
            placeholder="ייעוד מיוחד..." />
    </td>
    <td style="padding: 1px;" [ngClass]="{'has-error': payPlanCommitmentFormGroup.controls.designationDetails.invalid}">
        <input [disabled]="!payPlanCommitmentFormGroup.controls.designation.value" type="text"
            class="form-control narrow" formControlName="designationDetails" style="width: 100% !important;"
            placeholder="תיאור ייעוד..." />
    </td>

    <td style="padding: 1px; margin: 0 auto;">
        <mat-icon class="delete" (click)="removepayPlanCommitment(ix)">delete_forever</mat-icon>
    </td>


</ng-container>