import { TishTableMain } from './tishTable/tishTable-main.cmp';
import { TishTableMap } from './tishTable/tishTable-map.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { TishTablesList } from './tishTables-list.cmp';


export const TishTablesRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'tishTables', component: TishTablesList, data: {  roles: "tishTables",  title: 'שולחנות' } },
    { canActivate: [AuthGuard], path: 'tishTables/add', component: TishTableMain, data: { mode: 'edit', roles: "tishTables", title: 'הוסף שולחן' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'tishTables/:tishTableID', component: TishTableMain, data: {  roles: "tishTables",  title: 'פרטי שולחן' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'tishTables/:tishTableID/edit', component: TishTableMain, data: { mode: 'edit', roles: "tishTables",  title: 'עריכת פרטי שולחן' }, outlet: 'popup' },

    { canActivate: [AuthGuard], path: 'tishTables/:tishTableID/map', component: TishTableMap, data: {  roles: "tishTables",  title: 'מפת שולחן' } }
]; 
