<div class="alert alert-{{alert.type}}" role="alert">
  <button type="button" class="close" (click)="remove()" aria-label="Close">
    <span aria-hidden="true"><i class="fa fa-times"></i></span>
  </button>
  <div style="background-color: aliceblue; padding: 0 10px; font-weight: bold;">
 <i class="fa fa-exclamation-circle"></i>&nbsp;הודעת מערכת</div>
  <!-- <div  style="font-weight: bold;" *ngIf="alert.type == 'danger'">פעולה נכשלה!</div> -->
  <div style="padding: 10px;" [innerHTML]="(alert.type == 'danger' && alert.msg.trim() == 'Error:' ? 'בקשה לא חוקית' : alert.msg)"></div>
  <div style="padding: 10px;">{{ alert.statusCode }}</div>
  <div style="padding: 10px;">{{ alert.path }}</div>
  <div fxLayout="row" fxLayoutAlign="center center" style="width:100%; text-align: end; padding: 10px; border-top: solid 0.5px;">
    <button mat-stroked-button (click)="remove()" style="background: aliceblue; font-size: 24px; line-height: 38px;"> <i class="fa fa-check"></i>&nbsp;אישור</button>


  </div>

</div>
