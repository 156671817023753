<form [formGroup]="depositForm" novalidate (ngSubmit)="save()" *ngIf="deposit">
    <div class="modal-header">
        <h1>עריכת פרטי הפקדה [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div *ngIf="deposit" class="example-form">
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>פרטי הפקדה</legend>


                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="depositsData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
        [modelFormGroup]="depositForm"></footer-edit-buttons>

</form>
