import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { CommitmentReasonsData } from './../commitmentReasons.data';
import { CommitmentReason } from './commitmentReason';
import { ServicesData } from './../../services/services.data';

@Component({
   
    selector: 'commitmentReason-edit',
    templateUrl: './commitmentReason-edit.tmpl.html'
})

export class CommitmentReasonEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public commitmentReasonsData: CommitmentReasonsData,
        public sd: ServicesData,
        private formBuilder: FormBuilder,
        private location: Location,
    ) {
    }

    commitmentReason: CommitmentReason;
    id: number;
    commitmentReasonForm: FormGroup;

    public load(): void {
        if (this.id) {
            this.commitmentReasonsData.getEdit(this.id)
                .subscribe((c:any) => {
                    this.commitmentReason = c;
                    this.commitmentReasonForm.patchValue(c);
                });
        }
    }

    public save(): void {
       // console.log('save run');
        if (this.commitmentReasonForm.valid) {
            this.commitmentReasonsData.update(this.commitmentReasonForm.value)
                .subscribe((c: any) => {
                    this.router.navigateByUrl(`/commitment-reasons/${c.id}`);
                },
                error => {});
        } else {
            console.log(this.commitmentReasonForm.errors);
        }
    }

    private initForm() {
        this.commitmentReasonForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            commitmentReasonID: [this.id || 0, Validators.required],
            commitmentSubject:[, Validators.required],
            reason: ['', Validators.required],
        });
    }

    public cancel(): void {
        this.location.back();
    }

    ngOnInit() {
        this.initForm();
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['commitmentReasonID'];
            if (this.id > 0) {
                this.load();
            } else {
                this.commitmentReason = new CommitmentReason();
                //  this.commitmentReason.commitmentReasonID = 0;
            }
        });
    }
}



