import { PaymentsData } from './../payments.data';
import { CommitmentsData } from './../../commitments/commitments.data';


import { Component, OnInit, Input } from '@angular/core';


@Component({
	selector: 'commitment-fundraising-info',
	templateUrl: './commitment-fundraising-info.tmpl.html'
})

export class CommitmentFundraisingInfo implements OnInit {

	constructor(
		private paymentsData: PaymentsData
	) {
	}
	
 @Input('commitmentID') public commitmentID: number;

	data: any;
 
	ngOnInit() {
		this.getCommitmentFundraisingInfo();
	}


	getCommitmentFundraisingInfo() {
		if (this.commitmentID) {
			return this.paymentsData!.getCommitmentFundraisingInfo(this.commitmentID)
				.subscribe((data: any) => {
					this.data = data;
				});
		}

	}


}
