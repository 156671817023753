import { TishInvitedMain } from './tishInvited/tishInvited-main.cmp';
import { TishInvitedsListMap } from './tishInviteds-list-map.cmp';

import { TishInvitedMap } from './tishInvited/tishInvited-map.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { ContactsSearchInputModule } from './../components/contacts-search-input/contacts-search-input.module';
import { MaterialModule } from './../material.module';
import { TishInvitedsRoutes } from './tishInviteds.routing';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { TishInvitedsData } from './tishInviteds.data';
import { TishInvitedsList } from './tishInviteds-list.cmp';
import { TishInvitedEdit } from './tishInvited/tishInvited-edit.cmp';
import { TishInvitedDetails } from './tishInvited/tishInvited-details.cmp';
import { TishInvitedRowMap } from './tishInvited/tishInvited-row-map.cmp';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { ModelInfoModule } from '../components/model-info/model-info.mdl';
import { InputInfoModule } from '../components/input-info/input-info.module';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';
import { PaginationMiniModule } from '../components/pagination-mini/pagination-mini.mdl';
import { TishInvitedsSubs } from './tishInvited/tishInviteds-subs/tishInviteds-subs.cmp';
import { AutoCompleteInputModule } from '../components/auto-complete-input/auto-complete-input.mdl';
import { ContactsSelectPickMdl } from '../contacts/contacts-select-pick/contacts-select-pick.mdl';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PanelBoxModule,
    PageHeadingModule,
    AutoCompleteModule,
    FooterEditButtonsMdl,
    RouterModule.forChild(TishInvitedsRoutes),
    MaterialModule,
    MainFormModalModule,
    ModelInfoModule,
    AutoCompleteInputModule,
    InputInfoModule,
    ContactsSearchInputModule,
    CustomToolTipModule,
    PaginationMiniModule,
    ContactsSelectPickMdl
  ],
  declarations: [
    TishInvitedsList,
    TishInvitedEdit,
    TishInvitedDetails,
    TishInvitedMap,
    TishInvitedRowMap,
    TishInvitedsListMap,
    TishInvitedMain,
    TishInvitedsSubs

  ],
  exports: [
    TishInvitedsList,
    TishInvitedEdit,
    TishInvitedDetails,
    TishInvitedMap,
    TishInvitedRowMap,
    TishInvitedsListMap,
    TishInvitedMain
  ],
  providers: [
    TishInvitedsData
  ]
})
export class TishInvitedsMdl { }

