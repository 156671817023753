import { AlertService } from "../components/alert/alert.svc";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { ServicesData } from "../services/services.data";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToraxHttp } from "../utils/custom-http.svc";
import { IndexService } from "../services/index-service";
import { SubscriptionLike } from "rxjs";
import { DepositsData } from "./deposits-data";

@Component({
  selector: "deposits-list",
  templateUrl: "deposits-list.tmpl.html",
})
export class DepositsList implements OnInit, OnDestroy {
  constructor(
    private depositsData: DepositsData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
		private cdref: ChangeDetectorRef
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/deposits') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  displayedColumns: string[] = ["select", "buttons", "depositID", "organizationBankAccountTitle",'amount',"depositDate","depositType",'comment',"paymentsIDsList"];

  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  searchDepositsListForm: FormGroup;

  actualMinusHeight = '255';

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  isChecked(row: any): boolean {
    const found = this.selection.selected.find(
      (el: any) => el.depositID === row.depositID
    );
    if (found) {
      return true;
    }
    return false;
  }
  depoditReport(depositID){
    this.depositsData.depoditReport(depositID)
    .subscribe(blob => {
      let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
      popupWinindow.document.open();
      // tslint:disable-next-line:max-line-length
      popupWinindow.document.write('<html dir="rtl"><head><style type="text/css"> .deposit-details { padding: 6px; font-family: system-ui; width: auto; background-color: #f2f2f2; border-radius: 5px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); } .deposit-details h2 { font-size: 20px; margin-bottom: 10px; } .deposit-details p { font-size: 16px; margin-bottom: 15px; } .deposit-details .amount { font-weight: bold; color: #007bff; } .deposit-details .date { color: #666; } tr.payments>td{padding:4px; line-height:20px} tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { } </style></head><body onload="window.print()"><div>' + (<any>blob).value + '</div></body></html>');
      popupWinindow.document.close();
      this.toraxHttp.updatedEvent.emit(depositID);
    });
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

	addNew(depositType) {
		this.router.navigate(['', { outlets: { popup: ['deposits', 'add', depositType] } }]);
	}



	activeTabIndex = 0;
	public itemSelect(event) {
		this.activeTabIndex = event.index;
		this.setPosAttr(event.index);

		this.cdref.detectChanges();
	}
  openSelectedRow(depositID) {
    this.router.navigate(["", { outlets: { popup: ["deposits", depositID] } }]);
  }


	setPosAttr(ix) {
    // 	let pos = "";
    // 	switch (ix) {
    // 		case 0:
    // 			pos = "pendings";
    // 			break;
    // 		case 1:
    // 			pos = "paids";
    // 			break;
    // 		case 2:
    // 			pos = "accepteds";
    // 			break;
    // 	}
    // 	this.location.replaceState(`${this.router.url}#${pos}`);
     }

  private initForm() {
    this.searchDepositsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      depositID: [],
      depositType: [],
      comment: [],
      depositDate: [],
      selectedRows: [],
      orders: this.formBuilder.array([]),
    });

    this.selection.changed.subscribe((x) => {
      this.searchDepositsListForm.controls["selectedRows"].setValue(
        x.source.selected.map((x: any) => x.depositID)
      );
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {
    this.initForm();

    this.activatedRoute.params.forEach((params: Params) => {

    });

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchDepositsListForm.patchValue(frm);
    }

    this.searchDepositsListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
    ];
    this.indexService = new IndexService(this.searchDepositsListForm, params, this.depositsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();

  }
}
