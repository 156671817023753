import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from "rxjs";
import { Deposit } from './deposit/deposit';

@Injectable()

export class DepositsData {


  private url = 'api/deposits';


  constructor(
    private toraxHttp: ToraxHttp
  ) { }

  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }

  getDepositBookRowsGroupModels_IL() {
    return this.toraxHttp.get(`${this.url}/depositBookRowsGroupModels_IL`);
  }

  groupWatingsToDeposits_IL() {
    return this.toraxHttp.get(`${this.url}/groupWatingsToDeposits_IL`);
  }

  depoditReport(depoditID: number) {
    return this.toraxHttp.getWithResponce(`${this.url}/depoditReport/${depoditID}`);
  }

  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  update(deposit: Deposit) {
    return this.toraxHttp.update(this.url, deposit);
  }

  getAdd() {
    return this.toraxHttp.get(`${this.url}/add`);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}
