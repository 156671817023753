import { DirectivesMdl } from './../../components/directives/directives-module';

import { MaterialModule } from '../../material.module';
import { CommitmentsSelectPick } from './commitments-select-pick.cmp';
import { CommitmentAddAction } from './commitment-add-action.cmp';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        MaterialModule,

        DirectivesMdl
       ],
    declarations: [
        CommitmentAddAction,
        CommitmentsSelectPick
    ],
    exports: [
        CommitmentAddAction,
        CommitmentsSelectPick
    ],

    entryComponents: [
        CommitmentAddAction,
        CommitmentsSelectPick
    ]
})
export class CommitmentsSelectPickMdl { }


