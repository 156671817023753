import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {  PaginationMiniComponent } from './pagination-mini.cmp';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule,
        RouterModule],
    exports: [PaginationMiniComponent],
    declarations: [PaginationMiniComponent]
    //providers: [],
})
export class PaginationMiniModule { }
