
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmergencySquadQueue } from './emergency-squad-queue';
import { EmergencySquadQueuesData } from '../emergency-squad-queues.data';
import { ServicesData } from 'src/app/services/services.data';

@Component({
  selector: 'emergency-squad-queue-edit',
  templateUrl: './emergency-squad-queue-edit.tmpl.html'
})

export class EmergencySquadQueueEdit implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private emergencySquadQueuesData: EmergencySquadQueuesData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
  ) {
  }

  emergencySquadQueue: EmergencySquadQueue;
  id: number;
  emergencySquadMemberId: number;
  emergencySquadQueueForm: FormGroup;

  public load(): void {

    if (this.id > 0) {
      this.emergencySquadQueuesData.getEdit(this.id || 0)
        .subscribe((c: any) => {
          this.emergencySquadQueue = c;
          this.emergencySquadQueueForm.patchValue(c);
        });
    } else {
      this.emergencySquadQueuesData.getAdd(this.emergencySquadMemberId)
        .subscribe((c: any) => {
          this.emergencySquadQueue = c;
          this.emergencySquadQueueForm.patchValue(c);
        });
    }
  }




  private initForm() {
    this.emergencySquadQueueForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      emergencySquadQueueID: [this.id || 0, Validators.required],
      emergencySquadMemberID: [this.emergencySquadMemberId, Validators.required],
      emergencySquadMemberName: [],
      from: [new Date(), Validators.required],
      fromTime: [, Validators.required],
      till: [],
      tillTime: [],
      entry: [],

      entryTime: [],
      exit: [],
      exitTime: [],

    });
    this.emergencySquadQueueForm.controls['from'].valueChanges.subscribe(x => {
      console.log(x);
    });
    this.emergencySquadQueueForm.controls['emergencySquadMemberID'].valueChanges.subscribe(x => {
      this.relationTypeCalc(this.emergencySquadQueueForm);
    });

    //this.emergencySquadQueueForm.setValidators(this.receivingContactValidation);
  }
  private relationTypeCalc(fb: FormGroup) {
    let cid = +fb.controls['emergencySquadMemberID'].value;

    fb.updateValueAndValidity();
  }



  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['emergencySquadQueueID'];
      this.emergencySquadMemberId = +params['emergencySquadMemberID'];
    });
    this.initForm();
    this.load();
  }
}



