﻿<div *ngIf="tishInvited">
    <div class="modal-header">
        <h2>פרטי מוזמן [{{ (id > 0 ? id : 'חדש') }}] </h2>
    </div>
    <div class="modal-body">
        <dl class="dl-horizontal">
            <dt>כותרת אירוע</dt>
            <dd>{{ tishInvited.tishEventTitle }}</dd>
            <hr>
            <dt>מוזמן</dt>
            <dd>{{ tishInvited.tishInvitedName }}</dd>
            <hr>
            <dt>מארח</dt>
            <dd>{{ tishInvited.hostContactName }}</dd>
            <hr>
            <dt>שם כרטיס</dt>
            <dd>{{ tishInvited.ticketName }}</dd>
            <hr>
            <dt>מקום</dt>
            <dd>{{ tishInvited.tishTableName }}-{{ tishInvited.placeNumber }}</dd>
            <hr>
            <dt>איזור</dt>
            <dd>{{ tishInvited.tishAreaName }}</dd>
            <hr>
            <dt>קבוצה</dt>
            <dd>{{ tishInvited.tishGroupName }}</dd>
            <hr>
            <dt>כרטיסים נוספים</dt>
            <dd>{{ tishInvited.quantityAdditionalTickets }}</dd>
            <hr>
        </dl>
    </div>
</div>
