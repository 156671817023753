<mat-card fxLayout="column" class="example-card">
  <mat-card-header fxLayout="row">
    <mat-card-title fxLayout="row">
      <h3 style="TEXT-DECORATION: UNDERLINE;">אירועים אישיים</h3>
      <span class="example-fill-remaining-space"></span>
      <button (click)="openAddOrEditContactEvent()"
        style="min-width: 10px  !IMPORTANT; min-height: 10px  !IMPORTANT;  line-height: 10px; padding:4px"
        matTooltip="הוסף אירוע אישי" mat-stroked-button>
        <mat-icon style="color: GRAY;">add_box</mat-icon>
      </button>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content style="width: 100%;">
    <div class="example-full-width">
      <div class="example-list" *ngIf="contactEvents" class="example-full-width">
        <div fxLayoutAlign="start center" class="example-box" *ngFor="let cg of contactEvents">
          <contactEvent-details fxLayout="column" fxFlex (onEditClick)="openAddOrEditContactEvent(cg)"
            (contactEventChange)="contactEventChangeEvent(cg.contactEventID)" [contactEvent]="cg">
          </contactEvent-details>
        </div>
      </div>
      <div class="example-list" *ngIf="yarzeits" class="example-full-width">
        <div fxLayoutAlign="start center" class="example-box" style="background-color: #e7eaec7d;
        padding: 8px 2px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        margin-top: 5px;" *ngFor="let cg of yarzeits">
          <contact-event-yarzeits-details fxLayout="column" fxFlex (onEditClick)="openAddOrEditYarzeit(cg)"
            (yarzeitChange)="yarzeitChangeEvent(cg.yarzeitID)" [yarzeit]="cg">
          </contact-event-yarzeits-details>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
