import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()

export class ReceiptsData {


    public url = 'api/receipts';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }



    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}
