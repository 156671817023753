export class CollectionBuilder {
    collectionBuilderID: number;
    collectionDate: Date;
    collectionDateIsTill: boolean;
    cityID: number;
    contactID: number;
    transactions: Transaction[];
}
export class Transaction {
    transactionID: number; 
}

export class CreateMasavFileModel {
    billingDate: Date = new Date();
    makeAsSucceeded: boolean = true;
}
