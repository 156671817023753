import { TishPlaceMain } from './tishPlace/tishPlace-main.cmp';
import { TishPlaceDetails } from './tishPlace/tishPlace-details.cmp';
import { TishPlaceEdit } from './tishPlace/tishPlace-edit.cmp';
import { TishPlaceDlg } from './tishPlace/tishPlace-edit-dlg.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { MaterialModule } from './../material.module';
import { TishPlacesRoutes } from './tishPlaces.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';


import { TishPlacesData } from './tishPlaces.data';
import { TishPlacesList } from './tishPlaces-list.cmp';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { TishPlaceMap } from './tishPlace/tishPlace-map.cmp';
import { TishInvitedsMdl } from '../tishInviteds/tishInviteds.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { TishPlaceMoveDlg } from './tishPlace/tishPlace-move-dlg.cmp';

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(TishPlacesRoutes),
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PaginationModule,
		PanelBoxModule,
		AutoCompleteModule,
		FooterEditButtonsMdl,
		MaterialModule,
		MainFormModalModule,
		TishInvitedsMdl
	],
	declarations: [
		TishPlacesList,
		TishPlaceEdit,
		TishPlaceDetails,
		TishPlaceMap,
		TishPlaceDlg,
		TishPlaceMoveDlg,
		TishPlaceMain,
	],
	exports: [
		TishPlacesList,
		TishPlaceEdit,
		TishPlaceDetails,
		TishPlaceMap,
		TishPlaceDlg,
    TishPlaceMoveDlg,
		TishPlaceMain,
	],
	providers: [
		TishPlacesData
	],
	entryComponents: [
		TishPlaceDlg,
    TishPlaceMoveDlg,
	]
})
export class TishPlacesMdl { }

