<div class="alert alert-{{alert.type}}" role="alert" *ngIf="(alert.type != 'danger' || alert.msg.trim() != 'Error:'); else globalError">

    <button type="button" class="close" (click)="remove()" aria-label="Close">
        <span aria-hidden="true"><i class="fa fa-times"></i></span>
    </button>
    <!-- <div  style="font-weight: bold;" *ngIf="alert.type == 'danger'">פעולה נכשלה!</div> -->
    <div>{{ (alert.type == 'danger' && alert.msg.trim() == 'Error:' ? 'בקשה לא חוקית' : alert.msg) }}</div>
    <div>{{ alert.statusCode }}</div>
    <div>{{ alert.path }}</div>
</div>



<ng-template #globalError>
  ...
  </ng-template>
