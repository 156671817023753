<form [formGroup]="honorsSetForm" novalidate (ngSubmit)="save()" *ngIf="honorsSet">
    <div class="modal-header">
        <h1>עדכון כיבודים - שבת/חג/אירוע [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body" style="resize: both; overflow: auto;">
        <div class="example-form">
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>עריכת סידרה</legend>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>תיאור:</label>
                            <input apply-enter class="form-control" formControlName="description">
                        </div>
                        &nbsp;&nbsp;
                        <date-input [placeholder]="'תאריך:'" [dateControl]="honorsSetForm.controls.honorsSetDate">
                            Loading...</date-input>
                    </div>
                    <hr>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                            <button apply-enter type="button" (click)="addHonor()">הוסף כיבוד
                            </button>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <table class="table table-border" style="margin-top: -16px;"
                            *ngIf="honorsSetForm.controls.honors.length > 0">
                            <thead class="text-nowrap">
                                <th>כיבוד</th>
                                <th>איש</th>
                                <th>מקבל</th>
                                <th>יחס/קרבה</th>
                                <th>סיבה/הערה</th>
                                <th>סכום מי-שברך</th>
                                <th>מטבע</th>
                                <th>התחייבות</th>
                                <th>מכתב</th>
                                <th>תאריך כיבוד</th>
                                <th>...</th>
                            </thead>
                            <tbody formArrayName="honors">
                                <tr *ngFor="let honor of honorsSetForm.controls.honors.controls; let i=index"
                                    [formGroupName]="i">
                                    <td style="padding: 1px;">
                                        <auto-complete [withLabel]="false" [withSubTitle]="true" apply-enter [controllerName]="'HonorTypes'"
                                        [placeholder]="'כיבוד...'"
                                        [formGroupModel]="honorsSetForm.controls.honors.controls[i]"
                                        [idControl]="honorsSetForm.controls.honors.controls[i].controls.honorTypeID"
                                        [nameControl]="honorsSetForm.controls.honors.controls[i].controls.honorTypeDescription">
                                    </auto-complete>
<!--
                                    <select class="form-control" formControlName="honorTypeID">
                                            <option value="">...</option>
                                            <option *ngFor="let ht of servicesData.HonorTypes" [value]="ht.id">
                                                {{ht.title}} <small>{{ht.subTitle}}</small>
                                            </option>
                                        </select> -->
                                    </td>
                                    <td style="padding: 1px;">
                                        <auto-complete [withLabel]="false" apply-enter [controllerName]="'Contacts'"
                                            [placeholder]="'איש'"
                                            [formGroupModel]="honorsSetForm.controls.honors.controls[i]"
                                            [idControl]="honorsSetForm.controls.honors.controls[i].controls.contactID"
                                            [nameControl]="honorsSetForm.controls.honors.controls[i].controls.contactName">
                                        </auto-complete>
                                    </td>
                                    <td style="padding: 1px;">
                                        <auto-complete [withLabel]="false" apply-enter [controllerName]="'Contacts'"
                                            [placeholder]="'מקבל'"
                                            [formGroupModel]="honorsSetForm.controls.honors.controls[i]"
                                            [idControl]="honorsSetForm.controls.honors.controls[i].controls.receivingContactID"
                                            [nameControl]="honorsSetForm.controls.honors.controls[i].controls.receivingContactName">
                                        </auto-complete>
                                    </td>
                                    <td style="padding: 1px;">
                                        <select class="form-control narrow" formControlName="relationTypeID">
                                            <option value="">...</option>
                                            <option *ngFor="let rt of servicesData.RelationTypes" [value]="rt.id">
                                                {{rt.title}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style="padding: 1px;">
                                        <input type="text" class="form-control" formControlName="reasonComment">
                                    </td>
                                    <td style="padding: 1px;">
                                        <input type="number" class="form-control narrow" placeholder="סכום..."
                                            formControlName="contributionAmount" />
                                    </td>
                                    <td style="padding: 1px;">
                                        <select style="max-width: 60px" class="form-control narrow"
                                            formControlName="currency">
                                            <option value="">...</option>
                                            <option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                                                {{ c.name }}
                                            </option>
                                        </select>
                                    </td>
                                    <td style="padding: 1px;"
                                        *ngIf="(!honorsSetForm.controls.honors.controls[i].controls.commitmentID) || honorsSetForm.controls.honors.controls[i].controls.commitmentID.value <= 0">
                                        <input type="checkbox" value="false" formControlName="doOpenCommitment">
                                    </td>
                                    <td style="padding: 1px;" *ngIf="honor.controls.commitmentID.value > 0">
                                        {{ honor.controls.commitmentID.value }}
                                    </td>
                                    <td style="padding: 1px;"
                                        *ngIf="(!honorsSetForm.controls.honors.controls[i].controls.contactLetterID) || honorsSetForm.controls.honors.controls[i].controls.contactLetterID.value <= 0">
                                        <input type="checkbox" value="false" formControlName="doCreateLetter">
                                    </td>
                                    <td style="padding: 1px;" *ngIf="honor.controls.contactLetterID.value > 0">
                                        {{ honor.controls.contactLetterID.value }}
                                    </td>
                                    <td style="padding: 1px;">
                                        <date-input [withLabel]="false" [placeholder]="'תאריך'"
                                            [dateControl]="honorsSetForm.controls.honors.controls[i].controls.honorDate">
                                            Loading...</date-input>
                                    </td>
                                    <td style="padding: 1px;">
                                        <a *ngIf="!honorsSetForm.controls.honors.controls[i].controls.isDeleted.value"
                                            (click)="deleteItem(i)"><i class="fa fa-trash-o"></i>&nbsp;מחיקה</a>
                                        <a *ngIf="honorsSetForm.controls.honors.controls[i].controls.isDeleted.value"
                                            (click)="unDeleteItem(i)"><i class="fa fa-undo"></i>&nbsp;בטל</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [displaySaveButton]="false" [onSaveGoToBack]="true" [navigateAfterSaving]="'honorsSets'" [dataProvider]="honorsSetsData"
        [router]="router" [id]="id" [disabledDeleteButton]="false" [modelFormGroup]="honorsSetForm">
        <ng-template #customHtmlButtons>

            <button type="button" apply-enter [disabled]="!honorsSetForm.valid" (click)="save(honorsSetForm)"
                    class="btn btn-primary navbar-btn">
                    <i class="fa fa-save"></i> שמור
                </button>
            </ng-template>
    </footer-edit-buttons>
</form>
