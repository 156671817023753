import { ToraxHttp } from "../../utils/custom-http.svc";
import { Injectable } from "@angular/core";



@Injectable({ providedIn: 'root' })

export class AutoCompleteDataService {
  private url = "api/Services";

  constructor(private toraxHttp: ToraxHttp) {}

  search(sourceList: string, query: string) {
    if(query.startsWith("+"))
    {
      query = query.replace("+","%2B");
    }
    return this.toraxHttp.get(
      `${this.url}/get${sourceList}?query=${query}&rowsCount=${20}`
    );
  }
}
