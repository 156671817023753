import { AlertService } from "../components/alert/alert.svc";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { ServicesData } from "../services/services.data";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToraxHttp } from "../utils/custom-http.svc";
import { IndexService } from "../services/index-service";
import { SubscriptionLike, from, groupBy, map, mergeMap, toArray } from "rxjs";
import { DepositsData } from "./deposits-data";
import * as _ from 'lodash';
import { DepositEditDlg } from "./deposit/deposit-edit-dlg.cmp";
import { PaymentsData } from "../payments/payments.data";

class DepositBookRowsGroupModel {
  currencyIcon: string;
  depositTypeName: string;
  amount: number;
  count: number;
  accountLocationName: string;
}
@Component({
  selector: "deposits-main",
  templateUrl: "deposits-main.tmpl.html",
})

export class DepositsMain implements OnInit {

  groupedDepositPayments: {
    currency: any; //grouped them by currency
    currencyIcon: any; // using lodash to sum quantity
    payMethodID: any; // using lodash to sum quantity
    depositType: any; // using lodash to sum quantity
    paymentsIDs: any;
    amount: any;
  }[];
  depositBookModels: DepositBookRowsGroupModel[];
  depositBookModelsCash: DepositBookRowsGroupModel[];
  depositBookModelsChecks: DepositBookRowsGroupModel[];
  constructor(
    private depositsData: DepositsData,
    public router: Router,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
    private cdref: ChangeDetectorRef,
  ) {

  }




  defaultvalues: DepositBookRowsGroupModel[];
  selectedItems;
  searchDepositsListForm: FormGroup;
  idFuturePayments?: boolean;
  saveIsDisable = false;

  itemSelect(e) {
    this.activeTabIndex = e.index;
    this.updateTabIndex();

  }

  paymentsMode = false;
  depositsMode = false;
  private updateTabIndex() {
    this.selectedPayMethodID = null;
    this.idFuturePayments = null;
    this.cdref.detectChanges();

    if (this.activeTabIndex === 0) {
      this.selectedPayMethodID = 88;
      this.idFuturePayments = null;

    } else if (this.activeTabIndex === 1) {
      this.selectedPayMethodID = 89;
      this.idFuturePayments = false;

    } if (this.activeTabIndex === 2) {
      this.selectedPayMethodID = 89;
      this.idFuturePayments = true;

    }
  }

  showPayments() {
    this.paymentsMode = true;
    this.depositsMode = false;
  }
  showDeposits() {
    this.paymentsMode = false;
    this.depositsMode = true;
  }
  //@Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);

  selectedPaymentsRowsChange_88($event) {
    from($event)
      .pipe(
        groupBy(// Group them by category and return the appropriate Arrays
          (val: any) => { val.currency, val.payMethodID }
        ),
        mergeMap(group => {
          return group.pipe(toArray());
        }),
        mergeMap((array) => {// Take each from above array and group each array by manDate
          return from(array).pipe(groupBy(
            val => val.currency,
          ),
            mergeMap(group => {
              return group.pipe(toArray()); // return the group values as Arrays
            })
          );
        }),
        map((val: any) => { //For each array returned , calculate the sum and map it to the Object you wanted
          let amount = 0;
          val.map(v => {
            amount = amount + v.amount;
          });
          return {
            currency: val[0].currency,//grouped them by currency
            currencyIcon: val[0].currencyIcon, // using lodash to sum quantity
            payMethodID: val[0].payMethodID, // using lodash to sum quantity
            depositType: val[0].payMethodID, // סוג ההפקדה הוא גם מזהה האמצעי תשלום
            paymentsIDs: val.map((p: any) => p.paymentID).join(','),
            amount: _.sumBy(val, 'amount'), // using lodash to sum Amount
          }
        }),
        toArray() //finally combine all returned objects to an array
      ).subscribe(
        val => {
          console.log(val);
          this.groupedDepositPayments = val;
        }
      );

  }
  selectedPaymentsRowsChange_89($event) {
    from($event)
      .pipe(
        groupBy(// Group them by category and return the appropriate Arrays
          (val: any) => { val.currency, val.payMethodID }
        ),
        mergeMap(group => {
          return group.pipe(toArray());
        }),
        mergeMap((array) => {// Take each from above array and group each array by manDate
          return from(array).pipe(groupBy(
            val => val.currency,
          ),
            mergeMap(group => {
              return group.pipe(toArray()); // return the group values as Arrays
            })
          );
        }),
        map((val: any) => { //For each array returned , calculate the sum and map it to the Object you wanted
          let amount = 0;
          val.map(v => {
            amount = amount + v.amount;
          });
          return {
            currency: val[0].currency,//grouped them by currency
            currencyIcon: val[0].currencyIcon, // using lodash to sum quantity
            payMethodID: val[0].payMethodID, // using lodash to sum quantity
            depositType: val[0].payMethodID, // סוג ההפקדה הוא גם מזהה האמצעי תשלום
            paymentsIDs: val.map((p: any) => p.paymentID).join(','),
            amount: _.sumBy(val, 'amount'), // using lodash to sum Amount
          }
        }),
        toArray() //finally combine all returned objects to an array
      ).subscribe(
        val => {
          console.log(val);
          this.groupedDepositPayments = val;
        }
      );

  }


  openAddOrEditDeposit(deposit): void {
    this.saveIsDisable = true;
    setTimeout(() => {
      const dialogRef = this._matDialog.open(DepositEditDlg, {
        data: { deposit: deposit }
      });


      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.saveDeposit(result);
        }
      });
    }, 200);
  }


  saveDeposit(deposit) {
    this.depositsData.update(deposit.value).subscribe((deposit: any) => {

      //לצורך ריענון הרשומות
      this.updateTabIndex();

      this.depositsData.updatedEvent.emit(deposit.depositID);
      this._matDialog.closeAll();
      var ixs = this.groupedDepositPayments.findIndex(x => x.currency == deposit.currency);
      this.groupedDepositPayments.splice(ixs);
      this.saveIsDisable = false;

    },
      error => {
        console.log("error", error);

      });
  }

  //ערכי ברירת מחדל
  activeTabIndex = 0;
  selectedPayMethodID = 88;


  ngOnInit() {

    this.load();



  }
  load() {
    this.depositsData.getDepositBookRowsGroupModels_IL()
      .subscribe((x: DepositBookRowsGroupModel[]) => {
        this.depositBookModelsCash = x.filter(x => x.depositTypeName == "מזומן");
        this.depositBookModelsChecks = x.filter(x => x.depositTypeName == "שיקים");
      });


  }
}
