<div style="padding: 10px;">
    <form *ngIf="commitmentsChartSearchModelForm" [formGroup]="commitmentsChartSearchModelForm" novalidate>
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
                התחייבויות
            </mat-toolbar-row>
        </mat-toolbar>
        <mat-card>
            <div style="overflow-x: auto; padding: 10px;" class="form-row" fxLayout="row" fxLayout.xs="column">
                <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
                    *ngIf="servicesData.CommitmentSubjects"
                    [listControl]="commitmentsChartSearchModelForm.controls.commitmentSubjects"
                    [placeholder]="'נושא...'" [list]="servicesData.CommitmentSubjects">
                </mat-select-multiple-dropdown>
                &nbsp;&nbsp;
                <date-input-range #fromDateRange [placeHolderStart]="'החל מתאריך:'" [placeHolderEnd]="'עד לתאריך:'"
                    [dateRangeCtrl]="commitmentsChartSearchModelForm.controls.fromDateRange">Loading...
                </date-input-range>
                &nbsp;&nbsp;
                <span class="panel-tools ">
                    <button class="btn btn-circle" (click)="loadPosModeByCommitments();" title="רענן"><i
                            class="fa fa-refresh"></i></button>
                    <button class="btn btn-circle" (click)="clearCommitmentsForm()" title="רענן"><i
                            class="fa fa-remove"></i></button>
                </span>
            </div>
            <hr>
            <div *ngIf="sumCommitmentsGlobalAmount" fxlayoutalign="space-between center border-bottom" class="px-16 py-24" fxlayout="column"
                fxlayout.gt-xs="row" fxlayoutalign="center" style="padding: 10px">
                <div class="ng-tns-c258-2050" fxflex="0 1 auto">
                    <div class="h4 secondary-text">
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
                            <div fxFlex="30" class="right">סה"כ התחייבויות</div>
                            <div fxFlex="70" class="h3  m-0 font-weight-300 text-nowrap left" style="text-align: left">
                                <span style="color:royalblue" *ngIf="sumCommitmentsGlobalAmount; else loading"
                                    class="secondary-text left p-10">{{ sumCommitmentsGlobalAmount | currency:'USD':'symbol-narrow':'1.2-2' }}</span>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
                            <div fxFlex="30" class="right">מתוכם שולמו</div>
                            <div fxFlex="70" class="h3 m-0 font-weight-300 text-nowrap left" style="text-align: left">
                                <span style="color:rgb(38, 226, 21)" *ngIf="sumCommitmentsPaid; else loading"
                                    class="secondary-text left p-10">{{ sumCommitmentsPaid | currency:'USD':'symbol-narrow':'1.2-2' }}</span>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
                            <div fxFlex="30" class="right">נותר לתשלום</div>
                            <div fxFlex="70" class="h3  m-0 font-weight-300 text-nowrap left" style="text-align: left">
                                <span style="color:red" *ngIf="sumCommitmentsBalance; else loading"
                                    class="secondary-text left p-10">{{ sumCommitmentsBalance | currency:'USD':'symbol-narrow':'1.2-2' }}</span>
                            </div>
                        </div>
                        <div style="text-align: end" fxLayout="row" fxLayout.xs="column" fxFlexFill>
                            <div fxFlex="100" class="left p-10">
                                <a class="btn btn-outline btn-success dim  left" (click)="navigatToCommitments()">
                                    <i class="fa fa-hand-o-left"></i>&nbsp;עבור לרשימה</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </form>
    <br>
    <form *ngIf="paymentsChartSearchModelForm" [formGroup]="paymentsChartSearchModelForm" novalidate>
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
                תשלומים
            </mat-toolbar-row>
        </mat-toolbar>
        <mat-card>
            <div style="overflow-x: auto; padding: 10px;" class="form-row" fxLayout="row" fxLayout.xs="column">
                <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
                    *ngIf="servicesData.CommitmentSubjects"
                    [listControl]="paymentsChartSearchModelForm.controls.commitmentSubjects" [placeholder]="'נושא...'"
                    [list]="servicesData.CommitmentSubjects">
                </mat-select-multiple-dropdown>
                &nbsp;&nbsp;
                <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
                    *ngIf="servicesData.AccountLocations"
                    [listControl]="paymentsChartSearchModelForm.controls.accountLocations" [placeholder]="'חשבון...'"
                    [list]="servicesData.AccountLocations">
                </mat-select-multiple-dropdown>
                &nbsp;&nbsp;

                <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
                    *ngIf="servicesData.StatusPayments"
                    [listControl]="paymentsChartSearchModelForm.controls.statusPayments" [placeholder]="'סטטוס...'"
                    [list]="servicesData.StatusPayments"></mat-select-multiple-dropdown>
                &nbsp;&nbsp;
                <date-input-range #doneOnRange [placeHolderStart]="'החל מתאריך:'" [placeHolderEnd]="'עד לתאריך:'"
                    [dateRangeCtrl]="paymentsChartSearchModelForm.controls.doneOnRange">Loading...
                </date-input-range>
                &nbsp;&nbsp;
                <span class="panel-tools">
                    <button class="btn btn-circle" (click)="loadPosModeByPayments();" title="רענן"><i
                            class="fa fa-refresh"></i></button>
                    <button class="btn btn-circle" (click)="clearPaymentsForm()" title="רענן"><i
                            class="fa fa-remove"></i></button>
                </span>
            </div>
            <hr> 
            <div  *ngIf="sumSucceeded" fxlayoutalign="space-between center border-bottom" class="px-16 py-24" fxlayout="column"
                fxlayout.gt-xs="row" fxlayoutalign="center" style="padding: 10px">
                <div class="ng-tns-c258-2050" fxflex="0 1 auto">
                    <div class="h4 secondary-text">
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
                            <div fxFlex="30" class="right">כסף שנכנס</div>
                            <div fxFlex="70" class="h3  m-0 font-weight-300 text-nowrap left" style="text-align: left">
                                <a title="לחץ כדי לעבור לרשימה" (click)="navigatToPayments(2)"><span
                                        style="color:rgb(38, 226, 21)" *ngIf="sumSucceeded; else loading"
                                        class="secondary-text left p-10">{{ sumSucceeded | currency:'USD':'symbol-narrow':'1.2-2' }}</span></a>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
                            <div fxFlex="30" class="right">כסף עתידי</div>
                            <div fxFlex="70" class="h3 m-0 font-weight-300 text-nowrap left" style="text-align: left">
                                <a title="לחץ כדי לעבור לרשימה" (click)="navigatToPayments(1)"><span
                                        style="color:rgb(186, 233, 99)" *ngIf="sumWaiting; else loading"
                                        class="secondary-text left p-10">{{ sumWaiting | currency:'USD':'symbol-narrow':'1.2-2' }}</span></a>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
                            <div fxFlex="30" class="right">תשלומים שנכשלו</div>
                            <div fxFlex="70" class="h3  m-0 font-weight-300 text-nowrap left" style="text-align: left">
                                <a title="לחץ כדי לעבור לרשימה" (click)="navigatToPayments(3)"> <span style="color:red"
                                        *ngIf="sumFailed >= 0; else loading"
                                        class="secondary-text left p-10">{{ sumFailed | currency:'USD':'symbol-narrow':'1.2-2' }}</span></a>
                            </div>
                        </div>
                        <!-- <mat-divider></mat-divider> -->
                        <div *ngIf="chartList; else loading"
                            style="vertical-align: middle; text-align: center; font-family: 'Open Sans', Rubik, Arial, sans-serif; ; font-weight: 400 !IMPORTANT; padding: 0px 15px 15px 15px;">
                            <div fxLayout="column">
                                <div fxLayout="row" fxLayout.xs="column" fxFlexFill>
                                    <dl class="dl-horizontal">
                                        <dt style="margin-right: 15px;">תצוגת גרף</dt>
                                        <dd></dd>
                                        <div fxLayout="row" style="overflow-x: scroll;">
                                            <div [style.height.px]="200" [style.min-width.px]="chartWidth"
                                                style="margin:5px">
                                                <!-- <ngx-charts-bar-vertical-2d [scheme]="colorScheme" [results]="chartList"
                                                    [view]="view" [legend]="false" [xAxisLabel]="xAxisLabel"
                                                    [yAxisLabel]="yAxisLabel" [barPadding]="2" [yScaleMax]="0"
                                                    [gradient]="true" [xAxis]="true" [yAxis]="true" [legend]="false"
                                                    [showXAxisLabel]="true" [showYAxisLabel]="true">
                                                </ngx-charts-bar-vertical-2d> -->

                                            </div>

                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </mat-card> 
    </form>
</div>

<ng-template #loading>
    <h4>
        <i style="color:royalblue" class="fa fa-refresh fa-spin"></i>
    </h4>
</ng-template>