import { DashboardTrackingAccountsDataService } from './dashboard-tracking-accounts-dataService';

import { Location } from '@angular/common';
import { MatTabGroup } from '@angular/material/tabs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, Input, EventEmitter, Output, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { DateRangeClass } from 'src/app/components/dateInput-range/date-input-range.class';

@Component({
    selector: 'dashboard-tracking-accounts',
    templateUrl: 'dashboard-tracking-accounts.tmpl.html',
    styleUrls: ['./../dashboard.scss']
})
export class DashboardTrackingAccounts implements OnInit {

    constructor(
        private dashboardTrackingAccountsDataService: DashboardTrackingAccountsDataService,
        private router: Router,
        private cdref: ChangeDetectorRef,
        private location: Location,
        private formBuilder: FormBuilder
    ) {
    }

    userName: string;
    model: any = {};
    collectionStatusModelForm: FormGroup;
    datesList: any[];
    activeTabIndex;
    perfix = "tabs_";

    @Output() goToPaymentsClick = new EventEmitter<any>();
    //@Input('grp') public grp: string;
    @Input('selectedDate') public selectedDate: any;
    @Input('accountLocation') public accountLocation: number;

    private initForm(row) {
        let relevantPaymentDateRange = new DateRangeClass();
        if (row.relevantPaymentDate) {
            relevantPaymentDateRange.start = row.relevantPaymentDate;
            relevantPaymentDateRange.end = row.relevantPaymentDate;
            relevantPaymentDateRange.includeNulls = false;
        }

        this.collectionStatusModelForm = this.formBuilder.group({
            relevantStatusPayments: [row.relevantStatusPayment ? [row.relevantStatusPayment] : null],
            relevantPaymentDateRange: [row.relevantPaymentDate ? relevantPaymentDateRange : null],
            accountLocations: [row.accountLocation ? [row.accountLocation] : null],
            currency: [row.currency ? row.currency : null],
            paymentsForTreatment: [row.paymentsForTreatment ? row.paymentsForTreatment : false],
        });

    }


    // public tabSelect(event) {
    //     this.activeTabIndex = event.index;
    //     this.selectGrp();

    //     sessionStorage.setItem(`${this.pathStr}`, `${this.activeTabIndex}`);
    //     this.cdref.detectChanges();
    // }

    // selectGrp() {
    //     switch (this.activeTabIndex) {
    //         case (0):
    //             this.grp = "איחוד-ישראל";
    //             break;
    //         case (1):
    //             this.grp = "המרכז העולמי-ישראל";
    //             break;
    //         case (2):
    //             this.grp = "המרכז העולמי-ארהב";
    //             break;
    //         case (3):
    //             this.grp = "המרכז העולמי-לונדון";
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // get pathStr() {
    //     return `${this.perfix}${this.location.path()}`;
    // }

    ngOnInit(): void {

        setTimeout(() => {
            this.getTrackings();
        }, 150);
    }

    getTrackings() {
        if (this.selectedDate) {
            this.dashboardTrackingAccountsDataService.getPaymentsTrackings(this.selectedDate)
                .subscribe((x: any) => {
                    this.model = x;
                });
        }

    }
    getTitle(status) {
        let ret = "";
        switch (+status) {
            case 1:
                ret = "תשלומים ממתינים";
                break;
            case 2:
                ret = "תשלומים";
                break;
            case 3:
                ret = "תשלומים שנכשלו";
                break;
            default:
                ret = "";
                break;
        }
        return `${ret} ל-${this.selectedDate.dayInMonth}/${this.selectedDate.month}/${this.selectedDate.year}`;
    }

    goToPayments(row: any = null) {
        if (row == null) {
            row = {
                paymentsForTreatment: true,
                accountLocation: this.model.userAccountLocation
            }
        }
        this.initForm(row)
        sessionStorage.setItem(`dashboard/payments/dbCollectionStatus`, JSON.stringify(this.collectionStatusModelForm.value));
        this.router.navigate(['/payments']);
    }
}
