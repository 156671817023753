<div class="input-group mb-3" style="white-space: nowrap; width: 180px;">
    <label  *ngIf="withLabel" style="width: 100%;">{{ placeholder }}</label>
    <div class="form-row" fxLayout="row" style="white-space: inherit">
        <select  style="width: 84px; min-width: 84px;" class="form-control" [formControl]="yearInputControl"
        [(ngModel)]="year">
            <option [value]="''">...</option>
            <option [value]="5776">תשעו</option>
            <option [value]="5777">תשעז</option>
            <option [value]="5778">תשעח</option>
            <option [value]="5779">תשעט</option>
            <option [value]="5780">תשפ</option>
            <option [value]="5781">תשפא</option>
            <option [value]="5782">תשפב</option>
            <option [value]="5783">תשפג</option>
            <option [value]="5784">תשפד</option>
            <option [value]="5785">תשפה</option>
            <option [value]="5786">תשפו</option>
            <option [value]="5787">תשפז</option>
            <option [value]="5788">תשפח</option>
            <option [value]="5789">תשפט</option>
            <option [value]="5790">תשצ</option>
            <option [value]="5791">תשצא</option>
            <option [value]="5792">תשצב</option>
            <option [value]="5793">תשצג</option>
        </select>
        &nbsp;
        <select *ngIf="monthsList" style="width: 84px; min-width: 84px;" class="form-control" [formControl]="monthInputControl"
        [(ngModel)]="month">
            <option [value]="''">...</option>
            <option *ngFor="let m of monthsList | sort :'order'" [value]="m.value">
                {{m.label}}
            </option>
        </select>
    </div>
</div>
