import { Compensation } from './compensation/compensation';
import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { ObjectsListResult } from '../services/objects-list-results';

@Injectable()

export class CompensationsData {
    private url = 'api/compensations';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }



    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getEdit(id: number)  {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(contactId:number)  {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
    }

    update(compensation: Compensation) {
        return this.toraxHttp.update(this.url, compensation);
    }


     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

