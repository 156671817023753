import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { TishInvitedsData } from './../tishInviteds.data';
import { TishInvited } from './tishInvited';
import { ToraxHttp } from './../../utils/custom-http.svc';

@Component({	
	selector: 'tishInvited-details',
	templateUrl: './tishInvited-details.tmpl.html'
})

export class TishInvitedDetails implements OnInit {
	constructor(
		private TishInvitedsData: TishInvitedsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.tishInvited && id === this.tishInvited.tishInvitedID) {
				this.loadTishInvited();
			}
		});
	}

	public tishInvited: TishInvited;
	private id: number;
	private contactId: number;

	public loadTishInvited(): void {
		this.TishInvitedsData.get(this.id)
			.subscribe((c:any) => this.tishInvited = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['tishInvitedID'];
			this.contactId = +params['contactID'];
			this.loadTishInvited();
		});
	}
}

