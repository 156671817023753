<form [formGroup]="groupForm" novalidate>
    <div class="modal-header">
        <h1>עריכת פרטי קבוצה [{{ (id > 0 ? id : 'חדשה') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">
                <panel-box title="עריכת פרטי קבוצה" [showClose]="false" [showCollapse]="false">
                    <span class="panel-tools"></span>
                    <div class="panel-content">
                        <div class="form-horizontal">
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="groupName">שם קבוצה</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" formControlName="groupName" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="groupColor">צבע</label>
                                <div class="col-sm-10">
                                    <input type="color"
                                    id="color-picker-link"
                                    (change)="setColor($event)"
                                    fornControlName="groupColor">
                     
<!--                      
                     <input fornControlName="groupColor"
                                        [style.background]="groupForm.controls.groupColor.value" autocomplete="off"
                                        [colorPicker]="groupColorValue" [cpPosition]="'bottom'"
                                        (colorPickerChange)="groupForm.controls.groupColor.setValue($event)"
                                        placeholder="בחר צבע..." /> -->
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <label class="control-label col-sm-2" for="userDepartment">לתצוגה ב:</label>
                                <div class="col-sm-10">
                                    <select class="form-control" formControlName="groupDepartment">
                                        <option value="">...</option>
                                        <option *ngFor="let ud of servicesData.UserDepartments" [value]="ud.value">
                                            {{ud.name}}
                                        </option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="showOnDashboard">לתצוגה בלוח בקרה:</label>
                                <div class="col-sm-10">
                                    <input type="checkbox" formControlName="showOnDashboard">
                                    <small *ngIf="!groupForm.controls.showOnDashboard.valid"
                                        class="text-danger">*</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-2">משויך לנושאים:</label>
                                <div class="col-sm-10">
                                    <mat-form-field>
                                        <mat-select apply-enter (selectionChange)="subjectsSelectionChange($event)"
                                            placeholder="נושאים (ברירת מחדל)..."
                                            [formControl]="groupForm.controls.groupSubjectsVM" multiple>
                                            <mat-option *ngFor="let cs of servicesData.CommitmentSubjects"
                                                [value]="cs.value">
                                                {{cs.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>


                        </div>
                    </div>
                </panel-box>
            </div>
            <div class="col-sm-6">
                <panel-box title="משתמשים מורשים" [showClose]="false" [showCollapse]="false">
                    <span class="panel-tools"></span>
                    <div class="panel-content">
                        <div class="row" style="margin:0;">
                            <a class="btn btn-w-m btn-info" (click)="addGroupUser()">הוסף</a>
                        </div>
                        <hr>
                        <div class="divTable" style="width: auto;">
                            <div class="divTableHeading">
                                <div style="padding: 10px 4px 10px 0px;" class="divTableCell">
                                    שם
                                </div>
                                <div style="padding: 10px 4px 10px 0px;" class="divTableCell">
                                    ...
                                </div>
                            </div>
                            <div class="divTableBody" formArrayName="groupUsers">
                                <div class="divTableRow" [formGroupName]="i"
                                    *ngFor="let cgCtrl of fcGroupUsers.controls; let i=index">
                                    <div class="divTableCell">
                                        <select class="form-control" formControlName="gxUserName">
                                            <option value="">...</option>
                                            <option *ngFor="let cs of servicesData.GxUsers" [value]="cs.id">
                                                {{cs.title}}
                                            </option>
                                        </select>
                                        <small *ngIf="!groupForm.controls.groupUsers.controls[i].controls.groupID.valid"
                                            class="text-danger">*</small>
                                    </div>
                                    <div class="divTableCell">
                                        <a *ngIf="!groupForm.controls.groupUsers.controls[i].controls.isDeleted.value"
                                            (click)="deleteItem(i)"><i class="fa fa-trash-o"></i>&nbsp;מחיקה</a>
                                        <a *ngIf="groupForm.controls.groupUsers.controls[i].controls.isDeleted.value"
                                            (click)="unDeleteItem(i)"><i class="fa fa-undo"></i>&nbsp;בטל</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </panel-box>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="groupsData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
        [modelFormGroup]="groupForm"></footer-edit-buttons>

</form>