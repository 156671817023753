import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { CommitmentReasonMain } from './commitmentReason/commitmentReason-main.cmp';
import { CommitmentReasonEdit } from './commitmentReason/commitmentReason-edit.cmp';
import { CommitmentReasonDetails } from './commitmentReason/commitmentReason-details.cmp';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { CommitmentReasonsRoutes } from './commitmentReasons.routing';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';

import { CommitmentReasonsData } from './commitmentReasons.data';
import { CommitmentReasonsList } from './commitmentReasons-list.cmp';
import { MaterialModule } from '../material.module';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		RouterModule.forChild(CommitmentReasonsRoutes),
		FooterEditButtonsMdl,
		MainFormModalModule,
    MaterialModule
     	],
	declarations: [
		CommitmentReasonsList,
		CommitmentReasonEdit,
		CommitmentReasonMain,
		CommitmentReasonDetails
	],
	exports: [
		CommitmentReasonsList,
		CommitmentReasonEdit,
		CommitmentReasonMain,
		CommitmentReasonDetails
	],
	providers: [
		CommitmentReasonsData
	]
})
export class CommitmentReasonsMdl { }

