import { AuthenticationService } from './../_guards/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';

import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { TishAreasData } from './tishAreas.data';

import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';

import { AlertService } from '../components/alert/alert.svc';
//import * as signalR from '@aspnet/signalr';

@Component({
	selector: 'tishAreas-list',
	templateUrl: 'tishAreas-list.tmpl.html',
})

export class TishAreasList implements OnInit, AfterViewInit {
	constructor(
		public tishAreasData: TishAreasData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private router: Router,
		public _matDialog: MatDialog,
		private authService: AuthenticationService,

		private alert: AlertService,
		private cdref: ChangeDetectorRef) {
	}


	@ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@Input('tishEventID') public tishEventID?: number;
	@Input('groupID') public groupID?: number;

	@Output() selectedRowsChange = new EventEmitter<any>();
	orderButtonIsChicked: boolean = false;
	selection = new SelectionModel<number>(true, []);
	resultsLength = 0;
	stopLoadingPoints: boolean = false;
	isLoadingResults = false;
	isRateLimitReached = false;
	searchTishAreasListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;
	panelOpenState: boolean = false;
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;
	pageEvent: PageEvent;
	pageSizeOptions;
	displayedColumns: string[] = ['buttons', 'tishAreaID', 'tishAreaName', 'areaColor', 'tishEventTitle', 'description', 'comment'];


	data: any[] = [];
	addNew() {
		this.router.navigate(['', { outlets: { popup: ['/tishAreas', 'add'] } }]);
	}
	onlySortRecentlyOpenedByCurUser = false;
	topCurUserOpenings() {
		this.onlySortRecentlyOpenedByCurUser = !this.onlySortRecentlyOpenedByCurUser;
		this.searchTishAreasListForm.controls['onlySortRecentlyOpenedByCurUser'].setValue(this.onlySortRecentlyOpenedByCurUser);

		this.getTishAreas();

	}
	topOpenings() {
		this.orderButtonIsChicked = !this.orderButtonIsChicked;
		if (this.orderButtonIsChicked) {
			this.addOrder('openingDateTime', true, 1);
		} else {
			this.removeOrder('openingDateTime');
		}

		this.getTishAreas();
	}


	openSelectedRow(tishAreaID) {
	 	this.router.navigate(['', { outlets: { popup: ['tishAreas', tishAreaID]}}]);
	}

	isChecked(row: any): boolean {
		const found = this.selection.selected.find((el: any) => el.tishAreaID === row.tishAreaID);
		if (found) {
			return true;
		}
		return false;
	}

	rowToggle(row) {
		this.selection.toggle(row);
		row.selected = !row.selected;
	}

	pageChange(e) {
		this.searchTishAreasListForm.controls['pageRows'].setValue(e.pageSize || 20);
		this.selectionChanged(e);
	}

	selectionChanged(e) {
		this.getTishAreas();
	}

	public clerFilter() {
		this.initForm();
		this.getTishAreas();
	}

	public getTishAreas() {
		if (this.paginator) {
			this.searchTishAreasListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
		}
		if (this.tishEventID && this.tishEventID > 0) {
			this.searchTishAreasListForm.controls['tishEventID'].setValue(this.tishEventID);
		}

		this.isLoadingResults = true;
		sessionStorage.setItem(this.router.url, JSON.stringify(this.searchTishAreasListForm.value));
		return this.tishAreasData!.getList(this.searchTishAreasListForm.value)
			.subscribe((data: any) => {
				this.data = data;
				this.sourceList = data.list;

				this.page = data.list.length;
				this.pageRows = data.list.length;
				this.rowsCount = data.rowsCount;

				this.isLoadingResults = false;
				this.isRateLimitReached = false;

				//trick
				this.stopLoadingPoints = false;


				this.selection.isSelected = this.isChecked.bind(this);
			});
	}

	initForm() {
		this.searchTishAreasListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			tishEventID: [],
			areaID: [],
			groupID: [],
			tishAreaName: [],
			tishAreaID: [],
			selectedRows: [],
			orders: this.formBuilder.array([])
		});

		this.selection.changed.subscribe(x => {
			this.searchTishAreasListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.tishAreaID));
			this.selectedRowsChange.emit(x.source.selected);
		});
	}

	ngOnInit() {
		this.stopLoadingPoints = true;
		this.initForm();
		let item = sessionStorage.getItem(this.router.url);
		if (item) {
			let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
			this.searchTishAreasListForm.patchValue(frm);
		}
		this.getTishAreas();
	}

	ngAfterViewInit() {
		this.cdref.detectChanges();
	}

	sortData(e) {
		this.addOrder(e.active, e.direction != "asc");
		this.getTishAreas();
	}

	public search() {
	}

	removeOrder(propertyName: string) {
		const ordersControl = <FormArray>this.searchTishAreasListForm.controls['orders'];
		let i = null;
		ordersControl.controls.forEach((ctrl, ix) => {
			if ((<any>ctrl).value.key == propertyName) {
				i = ix;
			}
		});
		if (i >= 0) {
			ordersControl.removeAt(i);
		}
	}

	addOrder(propertyName: string, desc: boolean = false, priorityOrder: number = null) {
		const ordersControl = <FormArray>this.searchTishAreasListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			if (priorityOrder >= 1) {
				ordersControl.insert(priorityOrder - 1, ord);
			} else {

				ordersControl.push(ord);
			}
		}
	}

	setPageSizeOptions(setPageSizeOptionsInput: string) {
		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}

	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}

	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchTishAreasListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}

	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}
}
