import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'payment-externalSoftware-details',
    templateUrl: './payment-externalSoftware-details.tmpl.html',
    styleUrls:['./../../payments.scss']
})


export class PaymentExternalSoftwareDetails implements OnInit {

  touchedRows: any;
  constructor() { }
  @Input('paymentForm') public paymentForm: FormGroup;
   


  ngOnInit(): void {
    this.touchedRows = [];   
  }

  ngAfterOnInit() {
    //this.control = this.paymentForm.get('chicDetails') as FormArray;
  }
 
}
