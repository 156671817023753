import { DashboardDirectorDataService } from './dashboard-director.data';
import { ServicesData } from './../../services/services.data';
import { AlertService } from './../../components/alert/alert.svc';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { ModalService } from './../../components/modal/modal.svc';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'dashboard-director',
    templateUrl: './dashboard-director.tmpl.html', 
})
export class DashboardDirectorComponent implements OnInit {
    userName: string;
    model: any = {};

    constructor(
        public servicesData: ServicesData,
    ) {
    }



    // groupedHighPayments() {
    //     this.dashboardDirectorDataService.getGroupedHighPayments().subscribe(list => {
    //         this.model.groupedHighPayments = list;
    //     });
    // }

    // getCommitmentsAboutToExpire() {
    //     this.dashboardDirectorDataService.getCommitmentsAboutToExpire().subscribe(list => {
    //         this.model.grpCommitmentsAboutToExpire = list;
    //     });
    // }

    // getClosedCommitments() {
    //     this.dashboardDirectorDataService.getClosedCommitments().subscribe(list => {
    //         this.model.groupClosedCommitments = list;
    //     });
    // }

    ngOnInit() {
        // this.servicesData.getConnectedUserName()
        //     .subscribe(user => {
        //         this.userName = <string>user;
        //     });
    }
}
