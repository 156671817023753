import { RegistrationEventMain } from './registrationEvent/registrationEvent-main.cmp';
import { RegistrationEventDetails } from './registrationEvent/registrationEvent-details.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { RegistrationEventsList } from './registrationEvents-list.cmp';

export const RegistrationEventsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'registrationEvents', component: RegistrationEventsList, data: {  roles: "registrationEvents",  title: 'רשימת אירועים' } },
    { canActivate: [AuthGuard], path: 'registrationEvents/add', component: RegistrationEventMain, data: { mode: 'edit', roles: "registrationEvents", title: 'הוסף אירוע' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'registrationEvents/:registrationEventID', component: RegistrationEventMain, data: {  roles: "registrationEvents",  title: 'פרטי אירוע' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'registrationEvents/:registrationEventID/edit', component: RegistrationEventMain, data: { mode: 'edit', roles: "registrationEvents",  title: 'עריכת פרטי אירוע' }, outlet: 'popup' }
]; 