import { DirectivesMdl } from './../directives/directives-module';
import { MatOptionModule } from '@angular/material/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSelectMultipleDropdownComponent } from './mat-select-multiple-dropdown.component';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatOptionModule,
		DirectivesMdl
	],
	exports: [
		MatSelectMultipleDropdownComponent
	],
	declarations: [
		MatSelectMultipleDropdownComponent
	]
})

export class MatSelectMultipleDropdownModule { }
