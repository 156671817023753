import { TishTablesMdl } from './../tishTables/tishTables.mdl';
import { TishPlacesMdl } from './../tishPlaces/tishPlaces.mdl';
import { TishInvitedsMdl } from './../tishInviteds/tishInviteds.mdl';
import { TishGroupsMdl } from './../tishGroups/tishGroups.mdl';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { TishEventMain } from './tishEvent/tishEvent-main.cmp';
import { TishEventMap } from './tishEvent/tishEvent-map.cmp';
import { TishEventDetails } from './tishEvent/tishEvent-details.cmp';
import { TishEventEdit } from './tishEvent/tishEvent-edit.cmp';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { TishEventsRoutes } from './tishEvents.routing';
import { TabsMdl } from './../components/tabs/tabs.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';

import { TishEventsData } from './tishEvents.data';
import { TishEventsList } from './tishEvents-list.cmp';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';

//import { AgmCoreModule } from '@agm/core';
import { PinchZoomModule } from '../components/ivypinch-pro/pinch-zoom.module';
import { AuthGuardMdl } from '../_guards/auth.guard.mdl';
import { MaterialModule } from '../material.module';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';
import { TishAreasMdl } from '../tishAreas/tishAreas.mdl';
// custom configuration Hammerjs

// export class HammerConfig extends HammerGestureConfig {
// 	overrides = <any> {
// 		// I will only use the swap gesture so
// 		// I will deactivate the others to avoid overlaps
// 		'pinch': { enable: false },
// 		'rotate': { enable: false }
// 	}}


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    PanelBoxModule,
    PageHeadingModule,
    AutoCompleteModule,
    TabsMdl,
    AuthGuardMdl,
    DateInputModule,
    FooterEditButtonsMdl,
    RouterModule.forChild(TishEventsRoutes),
    // AgmCoreModule.forRoot({
    // 	apiKey: 'AIzaSyDmYsBwl2N8VzeqTVb53LIKkIxHPYOiIiM'
    // }),
    DragDropModule,
    HammerModule,
    MaterialModule,
    MainFormModalModule,
    PinchZoomModule,
    TishGroupsMdl,
    TishInvitedsMdl,
    TishTablesMdl,
    TishAreasMdl,
    TishPlacesMdl,
    CustomToolTipModule,

  ],
  declarations: [
    TishEventEdit,
    TishEventsList,
    TishEventDetails,
    TishEventMap,
    TishEventMain
  ],
  exports: [
    TishEventEdit,
    TishEventsList,
    TishEventDetails,
    TishEventMap,
    TishEventMain
  ],
  entryComponents: [
  ],

  providers: [
    TishEventsData
  ]
})
export class TishEventsMdl { }
