<form  *ngIf="city" [formGroup]="cityForm" novalidate (ngSubmit)="save()">
    <div class="modal-header">
        <h1>עריכת פרטי עיר [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">
                <panel-box title="עריכת פרטי עיר" [showClose]="false" [showCollapse]="false">
                    <span class="panel-tools"></span>
                    <div class="panel-content">
                        <div class="form-horizontal">
                            <div class="form-group">
                                <label class="control-label col-sm-3">שם עיר:</label>
                                <div class="col-sm-9">
                                    <h3> {{ city.cityName }}</h3>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </panel-box>
            </div>
        </div>
    </div>
    <footer-edit-buttons [onSaveGoToBack]="true" [navigateAfterSaving]="'cities'" [dataProvider]="citiesData" [router]="router"
        [id]="id" [disabledDeleteButton]="false" [modelFormGroup]="cityForm"></footer-edit-buttons>
</form>