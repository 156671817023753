import { Phone } from './phone/phone';
import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { ObjectsListResult } from '../services/objects-list-results';

@Injectable()

export class PhonesData {

    private url = 'api/phones';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }


    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }


    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(contactId: number, phoneType :string) {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}/${phoneType}`);
    }

    update(phone: Phone) {
        return this.toraxHttp.update(this.url, phone);
    }

    // setDefaultPhoneID(contactID: number, phoneID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/setDefaultPhoneID?ContactID=${contactID}&PhoneID=${phoneID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;

    // }

    setPhonePriority(contactID: number, prevPhoneID: number, curPhoneID: number, newCurPriority: number) {
        let ret = this.toraxHttp.postWithResponce(`${this.url}/setPhonePriority?contactID=${contactID}&prevPhoneID=${prevPhoneID}&curPhoneID=${curPhoneID}&newCurPriority=${newCurPriority}`, null);
        // this.onUpdated(contactID);
        return ret;
    }

    // removeDefaultPhoneID(contactID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/removeDefaultPhoneID?ContactID=${contactID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;
    // }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();

    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

