import { TishTableDlg } from './tishTable-edit-dlg.cmp';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { TishInvited } from './../../tishInviteds/tishInvited/tishInvited';
import { TishInvitedsData } from './../../tishInviteds/tishInviteds.data';
import { TishPlaceDlg } from './../../tishPlaces/tishPlace/tishPlace-edit-dlg.cmp';
import { TishPlace } from './../../tishPlaces/tishPlace/tishPlace';
import { TishTablesData } from './../tishTables.data';
import { TishPlacesData } from './../../tishPlaces/tishPlaces.data';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { TishTable } from './tishTable';
import { FormGroup, FormArray } from '@angular/forms';
declare var jQuery: any;

@Component({

  selector: 'tishTable-static-map',
  templateUrl: './tishTable-static-map.tmpl.html',
  styleUrls: ['./../tishTables.scss']
})

export class TishTableStaticMap implements OnInit, AfterViewInit { //, AfterViewInit


  constructor(
    private tishTablesData: TishTablesData,
    private tishPlacesData: TishPlacesData,
    private tishInvitedsData: TishInvitedsData,
    private toraxHttp: ToraxHttp,
    public dialog: MatDialog,
  ) {
    this.toraxHttp.updatedEvent.subscribe((id) => {
      if (id > 0 && this.tishTable && id === this.tishTable.tishTableID) {
        this.load();
      }
    });
  }
  @Input('tishTableID') public tishTableID: number;
  @Input('tishEventID') public tishEventID: number;


  tishTableForm: FormGroup;
  get tishPlaces(): FormGroup {
    return this.tishTableForm.controls['tishPlaces'] as FormGroup;

  }
  addPlaceDlg() {
    let tp = new TishPlace();
    tp.tishTableID = this.tishTable.tishTableID;
    tp.tishTableName = this.tishTable.tishTableName;
    tp.narrowTable = this.tishTable.narrowTable;


    setTimeout(() => {
      const dialogRef = this.dialog.open(TishPlaceDlg, {
        data: tp
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.createTishPlace(result);
        }
      });
    }, 200);

  }

  createTishPlace = (formData: FormGroup): void => {
    if (formData.valid) {
      var tpData = this.tishPlacesData;
      var ttFrm = this.tishTableForm;
      var tt = this.tishTable;
      this.tishPlacesData.update(<TishPlace>formData.value)
        .subscribe((x: any) => {
          this.tishPlacesData.getEdit(x.value)
            .subscribe(tp => {
              const tishPlacesControls = <any>ttFrm.controls['tishPlaces'];
              let pFrm = tpData.initForm();
              pFrm.removeControl("tishInvited");//TODO

              let p: any = tp;

              pFrm.patchValue(p);
              tt.tishPlaces.push(p.value);
              tishPlacesControls.push(pFrm);
              tt.countPlaces += 1;
              ttFrm.controls['countPlaces'].patchValue(tt.countPlaces);
            });
        });
    }
  }

  deleteTishPlace = (tpDlg: TishPlaceDlg): void => {
    if (tpDlg.formData.valid) {
      this.tishPlacesData.delete(tpDlg.formData.value.TishTableID).subscribe((c: any) => {
        return true;
      })
    }
  }

  editTableDlg() {
    let tt = this.tishTable;
    tt.countPlaces = this.tishTable.countPlaces;

    setTimeout(() => {
      const dialogRef = this.dialog.open(TishTableDlg, {
        data: tt
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.saveTishTable(result);
        }
      });
    }, 200);
  }



  saveTishTable = (formData: FormGroup): void => {
    if (formData.valid) {
      this.tishTable = formData.value;
      this.tishTableForm = formData;
      this.save();
    }
  }

  isLock;
  transform: string;
  id: number = 0;
  tishTable: TishTable;
  visibleToolsPanel = false;


  public load(): void {
    this.tishTablesData.getMap(this.id)
      .subscribe((tg: any) => {
        this.tishTable = tg;
        this.transform = tg.transformRotate;

        const tishPlacesControls = <FormArray>this.tishTableForm.controls['tishPlaces'];
        var len = tishPlacesControls.length;
        for (var i = 0; i < len; i++) {
          tishPlacesControls.removeAt(0);
        }
        (<TishTable>this.tishTable).tishPlaces.forEach(place => {
          if (place != null) {
            let plceFrm = this.tishPlacesData.initForm();
            if (place.tishInvited == null) {
              place.tishInvited = new TishInvited();
              place.tishInvited.tishEventID = this.tishEventID;
            }
            else {
              //הוספת Form מוזמן למקום
            }
            let p :any = place;
            plceFrm.patchValue(p);
            plceFrm.controls["tishInvited"] = this.tishInvitedsData.initForm();
            plceFrm.controls["tishInvited"].patchValue(p.tishInvited);
            plceFrm.controls["tishInvitedID"].patchValue(p.tishInvited.tishInvitedID || 0);
            plceFrm.controls["areaColor"].patchValue(p.areaColor);

            //
            tishPlacesControls.push(plceFrm);
          }
        });
        this.tishTableForm.patchValue(this.tishTable);
      });
  }

  ngOnInit() {
    this.id = this.tishTableID;
    if (this.id) {
      this.tishTableForm = this.tishTablesData.initForm();
      this.load();
    }
  }

  public save() {
    if (this.tishTableForm.valid) {
      this.tishTablesData.update(this.tishTableForm.value)
        .subscribe((c: any) => {
          this.id = c.id;
          if (this.id) {
            this.tishTableForm = this.tishTablesData.initForm();
            this.load();
          }
        });
    } else {
      console.log('not valid');
      console.log(this.tishTableForm.errors);
    }
  }

  ngAfterViewInit() {
    this.visibleToolsPanel = false;
  }

}
