import { GroupMain } from './group/group-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { GroupEdit } from './group/group-edit.cmp';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { GroupsRoutes } from './groups.routing';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';

import { GroupsData } from './groups.data';
import { GroupsList } from './groups-list.cmp'; 
import { GroupDetails  } from './group/group-details.cmp';
import { ColorPickerModule } from 'ngx-color-picker'; 

@NgModule({
	imports: [
		CommonModule,		
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		RouterModule.forChild(GroupsRoutes),
		FooterEditButtonsMdl,
		ColorPickerModule,
		MatFormFieldModule,
		MatSelectModule,
		MatOptionModule,
MainFormModalModule
	],
	declarations: [
		GroupsList,
		GroupEdit,
		GroupDetails,
		GroupMain
	],
	exports: [
		GroupsList,
		GroupEdit,
		GroupDetails,
		GroupMain
	],
	providers: [
		GroupsData
	]
})
export class GroupsMdl { }

