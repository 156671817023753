<div *ngIf="commitment">
        <div>
            <div>
                <table *ngIf="commitment.commitmentGlobalAmount > 0">
                    <tr>
                            <td>התחייבות {{ commitment.commitmentDonationTypeName }} </td>
                            <td> {{ commitment.commitmentSubjectName }} </td>
                            <td>{{ commitment.commitmentOrAppointmentStrDate }}</td>
                        <td>{{ commitment.commitmentReasonName }}</td>
                        <td>
                            {{ commitment.commitmentGlobalAmount | number }}{{ commitment.commitmentCurrencyIcon }}
                        </td>
                        <td *ngIf="commitment.qty">ב-{{ commitment.qty }} תשלומים</td>
                    </tr>
                </table>
                <table *ngIf="commitment.commitmentPulseAmount > 0">
                    <tr>
                        <td>התחייבות {{ commitment.commitmentDonationTypeName }} </td>
                        <td> {{ commitment.commitmentSubjectName }} </td>
                        <td>{{ commitment.commitmentOrAppointmentStrDate }}</td>
                        <td>{{ commitment.commitmentReasonName }}</td>
                        <td>
                            {{ commitment.commitmentPulseAmount | number }}{{ commitment.commitmentCurrencyIcon }}
                        </td>
                        <td *ngIf="commitment.qty">למשך-{{ commitment.qty }} תשלומים</td>
                        <td *ngIf="commitment.commitmentTillDate">תאריך סיום-{{ commitment.commitmentTillDate }}</td>
                    </tr>
                </table>
                <table *ngIf="!commitment.commitmentPulseAmount && !commitment.commitmentGlobalAmount">
                        <tr>
                            <td>תרומות מזדמנות</td>
                            <td>{{ commitment.commitmentSubjectName }} </td>
                            <td>{{ commitment.commitmentOrAppointmentStrDate }}</td>
                            <td>{{ commitment.commitmentReasonName }}</td>
                        </tr>
                    </table>
            </div>
            <hr>
            <div>
                <h3>פירוט תשלומים</h3>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>תאריך</th>
                            <th>באמצעות</th>
                            <th class="narrow">סכום</th>
                            <th>סטטוס</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of commitment.paymentCommitments">
                            <td>{{ p.doneOn | date:'dd/MM/yyyy' }} {{ p.jewishDoneOn }}</td>
                            <td>{{ p.payMethodTitle }}</td>
                            <td><span *ngIf="p.partialAmount != p.amount"> {{
                                        p.partialAmount | number }} מתוך </span> {{
                                    p.amount | number }} {{ p.currencyIcon }}</td>
                            <td>{{ p.statusPaymentName }}</td>
                            <td colspan="3" *ngIf="commitment.commitmentBalance > 0">יתרה:{{ commitment.commitmentBalance  | number }}{{ commitment.commitmentCurrencyIcon }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr style="border: 1px solid;">
    </div>
