<mat-card style="white-space: nowrap;">
    <mat-card-header fxLayout="row">
        <mat-card-title fxLayout="row">
            {{(title? title: 'מידע רשומה')}}
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div *ngIf="data; else noData">
            <p>
                <span *ngIf="data.createDate"><b>נקלט בתאריך:</b>
                    <br>
                    {{ data.createDate | date:'dd/MM/yyyy hh:mm' }}
                </span>
                <span *ngIf="data.createBy"><b>ע"י:</b>
                    {{ data.createBy }}
                </span>
            <p>
            <p>
                <span *ngIf="data.changeDate"><b>עריכה אחרונה:</b>
                    <br>
                    {{ data.changeDate | date:'dd/MM/yyyy hh:mm' }}
                </span>
                <span *ngIf="data.changeBy"><b>ע"י:</b>
                    {{ data.changeBy }}
                </span>
            <p>
        </div>
    </mat-card-content>
</mat-card>


<ng-template #noData>
    לא ניתן להציג
</ng-template>