import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { ObjectsListResult } from '../services/objects-list-results';


@Injectable()

export class AuthorizeTransactionsData {
    private url = 'api/authorizeTransactions';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }

   
    getList(baseQuery: any)  {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }


}