import { Component} from '@angular/core';

@Component({
    selector: 'yarzeit-main',
    templateUrl: './yarzeit-main.tmpl.html'
})

export class YarzeitMain {
    editMode: boolean = null;

    public setVal(val:boolean) {
        this.editMode = val;
    }
}
