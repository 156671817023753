import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp'; 
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ServicesData } from './../../services/services.data';

import { IvrDonationUserTasksData } from './../ivr-donation-user-tasks.data';
import { IvrDonationUserTask } from './ivr-donation-user-task';

export class PaymentOption { }
export class Api { }



@Component({    
    selector: 'ivr-donation-user-task-edit',
    templateUrl: './ivr-donation-user-task-edit.tmpl.html'
})

export class IvrDonationUserTaskEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private ivrDonationUserTasksData: IvrDonationUserTasksData,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData
    ) {

    }

    @ViewChild('bankBranchesCmp') bankBranchesCmp: AutoCompleteComponent;

    ivrDonationUserTask: IvrDonationUserTask;
    id: number;
    ivrDonationUserTaskForm: FormGroup;



    public load(): void {
        if (this.id > 0) {
            this.ivrDonationUserTasksData.getEdit(this.id)
                .subscribe((pm:any)  => {
                    this.ivrDonationUserTask = pm;
                    this.fillForm(pm);
                });
        }
        // else {
        //     this.ivrDonationUserTasksData.getAdd()
        //         .subscribe(pm => {
        //             this.ivrDonationUserTask = pm;
        //             this.fillForm(pm);
        //         });
        // }
    }

    private fillForm(pm: IvrDonationUserTask): void {
        this.ivrDonationUserTaskForm.patchValue(pm);
    }



    private initForm() {

        this.ivrDonationUserTaskForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            ivrDonationUserTaskID: [this.id, Validators.required],
            doneDate: [],
            comment: [],
        });

    }

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['ivrDonationUserTaskID'] || 0;
        });
        this.initForm();
        this.load();
    }
}





