import { saveAs } from 'file-saver-es';
import { AuthenticationService } from '../../_guards/auth.service';
import { DashboardSecretaryDataService } from './dashboard-secretary.data';
import { AlertService } from './../../components/alert/alert.svc';
import { ToraxHttp } from './../../utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ContactReciptsDlg } from 'src/app/contacts/contacts-recipts-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { PrintReciptsDlg } from './print-recipts-dlg.cmp';

@Component({
  selector: 'dashboard-secretary',
  templateUrl: './dashboard-secretary.tmpl.html',
})
export class DashboardSecretaryComponent implements OnInit {
  userName: string;
  model: any = {};
  widget5: any = {};

  constructor(
    private dashboardSecretaryDataService: DashboardSecretaryDataService,
    private toraxHttp: ToraxHttp,
    private alert: AlertService,
    private authService: AuthenticationService,
    public dialog: MatDialog,
  ) {
    this.toraxHttp.updatedEvent.subscribe(() => {
      if (this.authService.isAuthorized("SystemAdministrator,DashboardSecretary")) {
        this.load();
      }
    });
  }
  fileDownload() {
    this.dashboardSecretaryDataService.getDocument().subscribe((blob: any) => {
      var blb = new Blob(blob, { type: "application/pdf;charset=windows-1252" });
      saveAs(blb, 'SomeFileDownloadName.pdf');
    });
  }

  createWaitingIL_Year_Receipts(): void {
    this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
    this.dashboardSecretaryDataService.createWaitingIL_Year_Receipts()
      .subscribe(data => {
        // tslint:disable-next-line:max-line-length
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
        popupWinindow.document.close();
      });
  }


  printIL_Year_Receipts(): void {
    this.alert.info('המתן בבקשה,');
    let year = new Date().getFullYear();

    this.dashboardSecretaryDataService.printIL_Year_Receipts(year)
      .subscribe(data => {
        // tslint:disable-next-line:max-line-length
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: A4; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
        popupWinindow.document.close();
      });
  }

  // createAndPrintWaitingIL_Year_Receipts(): void {
  //     this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
  //     this.dashboardSecretaryDataService.createAndPrintWaitingIL_Year_Receipts()
  //         .subscribe(data => {
  //             // tslint:disable-next-line:max-line-length
  //             let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
  //             popupWinindow.document.open();
  //             // tslint:disable-next-line:max-line-length
  //             popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
  //             popupWinindow.document.close();
  //         });
  // }
  createAndPrintWaitingIchudIL_Year_Receipts(): void {
    this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
    this.dashboardSecretaryDataService.createAndPrintWaitingIchudIL_Year_Receipts()
      .subscribe(data => {
        // tslint:disable-next-line:max-line-length
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
        popupWinindow.document.close();
      });
  }


  createAndNonPrintWaitingReceipts(): void {
    const dialogRef = this.dialog.open(PrintReciptsDlg, {
      data: 1,
      width: '40%'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.value) {
        let val = result.value;
        this.dashboardSecretaryDataService.createAndNonPrintWaitingReceipts(val.accountLocation, val.commitmentSubject, val.registrationEventType, val.withPaymentsPlan)
          .subscribe(data => {
            // tslint:disable-next-line:max-line-length
            let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
            popupWinindow.document.open();
            // tslint:disable-next-line:max-line-length
            popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
            popupWinindow.document.close();
          });
      }
    });

  }



  // createAndPrintWaitingUSAReceipts() {
  //     this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
  //     this.dashboardSecretaryDataService.createAndPrintWaitingUSAReceipts()
  //         .subscribe(data => {
  //             // tslint:disable-next-line:max-line-length
  //             let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
  //             popupWinindow.document.open();
  //             // tslint:disable-next-line:max-line-length
  //             popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
  //             popupWinindow.document.close();
  //         });
  // }

  // createAndPrintWaitingUKReceipts() {
  //     this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
  //     this.dashboardSecretaryDataService.createAndPrintWaitingUKReceipts()
  //         .subscribe(data => {
  //             // tslint:disable-next-line:max-line-length
  //             let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
  //             popupWinindow.document.open();
  //             // tslint:disable-next-line:max-line-length
  //             popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
  //             popupWinindow.document.close();
  //         });
  // }

  // createAndPrintWaitingIchudIlReceipts() {
  //     this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
  //     this.dashboardSecretaryDataService.createAndPrintWaitingIchudIlReceipts()
  //         .subscribe(data => {
  //             // tslint:disable-next-line:max-line-length
  //             let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
  //             popupWinindow.document.open();
  //             // tslint:disable-next-line:max-line-length
  //             popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
  //             popupWinindow.document.close();
  //         });
  // }

  printWaitingReceipts(): void {
    const dialogRef = this.dialog.open(PrintReciptsDlg, {
      data: 1,
      width: '40%'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.value) {
        let val = result.value;
        this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
        this.dashboardSecretaryDataService.printWaitingReceipts(val.accountLocation, val.commitmentSubject, val.registrationEventType, val.withPaymentsPlan)
          .subscribe(data => {
            // tslint:disable-next-line:max-line-length
            let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
            popupWinindow.document.open();
            // tslint:disable-next-line:max-line-length
            popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
            popupWinindow.document.close();
          });
      }
    });

  }


  printWaitingUSAReceipts() {
    this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
    this.dashboardSecretaryDataService.printWaitingUSAReceipts()
      .subscribe(data => {
        // tslint:disable-next-line:max-line-length
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
        popupWinindow.document.close();
      });
  }

  printWaitingUKReceipts() {
    this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
    this.dashboardSecretaryDataService.printWaitingUKReceipts()
      .subscribe(data => {
        // tslint:disable-next-line:max-line-length
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
        popupWinindow.document.close();
      });
  }

  printWaitingIchudIlReceipts() {
    this.alert.info('המתן בבקשה,\nמקסימום דפים להדפסה 20');
    this.dashboardSecretaryDataService.printWaitingIchudIlReceipts()
      .subscribe(data => {
        // tslint:disable-next-line:max-line-length
        let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        // tslint:disable-next-line:max-line-length
        popupWinindow.document.write('<html><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
        popupWinindow.document.close();
      });
  }


  cancelILReceiptsPrint(receiptsIdsStr: string) {
    let receiptsIdsList = receiptsIdsStr.split(',').map(function (item) {
      return parseInt(item, 10);
    });
    this.alert.info('המתן בבקשה,\nמבטל הדפסות...');
    this.dashboardSecretaryDataService.cancelILReceiptsPrint(receiptsIdsList)
      .subscribe(() => {
        this.alert.info('פעולה הסתיימה');
      }
      );
  }

  cancelUSAReceiptsPrint(receiptsIdsStr: string) {
    let receiptsIdsList = receiptsIdsStr.split(',').map(function (item) {
      return parseInt(item, 10);
    });
    this.alert.info('המתן בבקשה,\nמבטל הדפסות...');
    this.dashboardSecretaryDataService.cancelUSAReceiptsPrint(receiptsIdsList)
      .subscribe(() => {
        this.alert.info('פעולה הסתיימה');
      }
      );
  }

  cancelUKReceiptsPrint(receiptsIdsStr: string) {
    let receiptsIdsList = receiptsIdsStr.split(',').map(function (item) {
      return parseInt(item, 10);
    });
    this.alert.info('המתן בבקשה,\nמבטל הדפסות...');
    this.dashboardSecretaryDataService.cancelUKReceiptsPrint(receiptsIdsList)
      .subscribe(() => {
        this.alert.info('פעולה הסתיימה');
      }
      );
  }

  cancelIchudIlReceiptsPrint(receiptsIdsStr: string) {
    let receiptsIdsList = receiptsIdsStr.split(',').map(function (item) {
      return parseInt(item, 10);
    });
    this.alert.info('המתן בבקשה,\nמבטל הדפסות...');
    this.dashboardSecretaryDataService.cancelIchudIlReceiptsPrint(receiptsIdsList)
      .subscribe(() => {
        this.alert.info('פעולה הסתיימה');
      }
      );
  }

  load() {
    if (this.authService.isAuthorized("SystemAdministrator,DashboardSecretary")) {
      this.dashboardSecretaryDataService.get().subscribe(db => {
        this.fillValues(db);
      });
    }
  }


  fillValues(db: any) {
    this.model = db;
    this.topNewCommitments = (<any>db).topNewCommitments;
  }

  topNewCommitments: any[];

  ngOnInit() {
    this.load();
  }


}
