import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable } from '@angular/core';
import { IvrAssignmentUserTask } from './ivr-assignment-user-task/ivr-assignment-user-task';


@Injectable()

export class IvrAssignmentUserTasksData {


    private url = 'api/ivrAssignmentUserTasks';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }



    delete(id: number) {
        let isconfirm = confirm("האם אתה בטוח שברצונך למחוק?\nלא ניתן לבטל את המחיקה");
        if (isconfirm) {
            return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
        }
        return this.toraxHttp.getNull();
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getAudioByts(ivrAssignmentUserTaskID: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'audio/wav');
        return this.toraxHttp.postAndDownload(`${this.url}/getAudioByts/${ivrAssignmentUserTaskID}`, {});
    }


    exportToExcel(baseQuery: any) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
    }
    downloadPdf(formQry: any) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadPdf`, formQry);
    }




    getAdd() {
        return this.toraxHttp.get(`${this.url}/add`);
    }


    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(ivrAssignmentUserTask: IvrAssignmentUserTask) {
        return this.toraxHttp.update(this.url, ivrAssignmentUserTask);
    }


    saveImage(fd: FormData, ivrAssignmentUserTaskID: number, imageName: string) {
        return this.toraxHttp.post(`${this.url}/postFiles/${ivrAssignmentUserTaskID}/${imageName}`, fd);
    }
    recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
}

}
