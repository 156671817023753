import { ModalService } from './modal.svc';
import { AfterViewInit, Directive, ElementRef, ContentChild, ComponentFactoryResolver, ViewChild, ViewContainerRef, OnChanges, ContentChildren, QueryList, EventEmitter, AfterContentInit, AfterContentChecked, Injectable, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';

// @Injectable({
// 	providedIn: 'root'
//   })
@Directive({
	selector: '[routerModal]'
})
export class RouterModal implements AfterViewInit {
	@ContentChild(RouterOutlet) outlet: RouterOutlet;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	) { }

	private modalBody: any;
	private windowResizeEv: any;

	ngAfterViewInit() {
		this.outlet.activateEvents.subscribe(($event: any) => {
			this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
			this.handleHeight();
		});

		this.handleHeight();

		this.outlet.deactivateEvents.subscribe(($event: any) => {
			this.renderer.setStyle(this.el.nativeElement, 'display', '');
			// this.renderer.setElementClass(this.el.nativeElement, 'modal-open', false);
			if (this.windowResizeEv) {
				this.windowResizeEv();
			}
		});
	}

	f = () => {
		let bodyHeight = (window.innerHeight - 220);
		if (this.el) {
			this.modalBody = this.el.nativeElement.querySelector('div.modal-body');
		}
		if (this.modalBody) {
			this.renderer.setStyle(this.modalBody, 'max-height', (bodyHeight) + 'px');
		}
	}

	
	private handleHeight() {
		this.f();
		this.windowResizeEv = this.renderer.listen('window', 'resize', this.f);

	}
}