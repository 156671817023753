import { MatDialog } from '@angular/material/dialog';

import { ContactsData } from 'src/app/contacts/contacts.data';
import { ServicesData } from 'src/app/services/services.data';

import { Component, Input, OnInit } from '@angular/core';

import { CommitmentsListDashboardDlg } from 'src/app/commitments/commitments-list-dashboard-dlg.cmp';
import { Contact } from './contact';

@Component({
    selector: 'contact-details-dashboard',
    templateUrl: './contact-details-dashboard.tmpl.html',
    styleUrls: ['./../contacts-list.scss']
})



export class ContactDetailsDashboard implements OnInit {
    constructor(
        public servicesData: ServicesData,
        public contactsData: ContactsData,
        public dialog: MatDialog,
    ) {

    }
    @Input('contact') public contact: Contact;
    @Input('groupSubjectsAsStr') groupSubjectsAsStr: any;//נושאים לתצוגה בקבוצה בברירת מחדל
    // @ViewChild('commentBox', {static: false}) public commentBox: ElementRef;
    // @ViewChild('priorityBox') public priorityBox: ElementRef;




    // private openContactInfo(contact: any, letAll: boolean): void {
    //     var tmpGroupSubjectsAsStr: string = (!letAll ? this.groupSubjectsAsStr : "");
    //     if (contact.commitments.length == 0) {
    //         alert("עדיין לא מעודכנים ההתחייבויות בתוכנה");
    //     } else{
    //         this.modal.show({
    //             Header: 'פרטי התחייבויות',
    //             BodyComponentType: CommitmentsListViewDlg,
    //             Actions: [
    //                 { Text: 'חזור', CssClass: 'btn-default', Action: () => true },
    //             ],
    //             Data: { 'contactID': contact.contactID, 'groupSubjectsAsStr': tmpGroupSubjectsAsStr }
    //         });
    //     }

    // }
    subjects: any[];
    activeContactSubjects(contactID) {
        this.contactsData.activeContactSubjects(contactID)
            .subscribe((subjects: any[]) => {
                this.subjects = subjects;
            });
    }

    openSubjectCommitments(subject, subjectName) {

        setTimeout(() => {
            const dialogRef = this.dialog.open(CommitmentsListDashboardDlg, {
                data: {
                    contact: this.contact,
                    contactID: this.contact.contactID,
                    subject: subject,
                    subjectName: subjectName,
                    width: '100vw',
                    maxWidth: '100vw',
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result && result.valid) {
                    //   this.saveAddress(result);
                }
            });
        }, 200);


    }
    // cgIdInEditMode;
    // cgSave(contactGroupID, comment, priority) {
    //     if (comment == undefined) {
    //         comment = this.commentBox.nativeElement.value;
    //     }
    //    // priority = (<any>this.priorityBox).value;
    //     this.contactsData.saveContactGroupComment(contactGroupID, comment, priority)
    //         .subscribe(
    //             data => {
    //                 let cg = this.contact.contactGroups.filter(x => x.contactGroupID == (<any>data).key);
    //                 if (cg != null) {
    //                     cg[0].comment = data.value.comment;
    //                     cg[0].priority = data.value.priority;
    //                     cg[0].priorityName = data.value.priorityName;


    //                     this.cgIdInEditMode = null;
    //                 }

    //             });
    // }


    public printAllContactInformation(): void {
        alert("הדפסת דוח עדיין לא הוגדרה במערכת");
        // this.contactsData.printAllContactInformation(contactID)
        //     .subscribe(
        //         data => {
        //             // tslint:disable-next-line:max-line-length
        //             let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        //             popupWinindow.document.open();
        //             // tslint:disable-next-line:max-line-length
        //             popupWinindow.document.write('<html>' + (<any>data).value + '</html>');
        //             popupWinindow.document.close();
        //         }
        //     )
    }
    ngOnInit() {
        setTimeout(() => {
            this.activeContactSubjects(this.contact.contactID);
        }, 150);

    }
}
