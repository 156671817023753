import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { ErrorHandler, Injectable, Injector } from '@angular/core'; 


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) {
        
    (function () {
        const userAgent = window.navigator.userAgent;
        if (userAgent.indexOf("Android") > -1) {
          var oldError = console.error;
          console.error = function () {
            // DO MESSAGE HERE.
           // alert(message);
            oldError.apply(console, arguments);
          };
        }
      })();
    }

    handleError(error: any): void { 
        const toraxHttp = this.injector.get(ToraxHttp);
       // const servicesData = this.injector.get(ServicesData);
        // servicesData.logConsole(error)
        // .pipe(first())
        // .subscribe(
        //   s => {
        //     console.error("s");
        //   },
        //   e => {
        //     console.error("e");
        //   });
       toraxHttp.handleError(error); 
    }

}
