
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServicesData } from '../../services/services.data';

@Component({
  selector: 'contact-edit-personal',
  templateUrl: './contact-edit-personal.tmpl.html'
})



export class ContactEditPersonal implements OnInit {

  @Input('fg') public contactForm: FormGroup;
  @Input('contactID') public contactID: number;
  constructor(public servicesData: ServicesData
  ) {

  } 
  ngOnInit(): void {
 
  }


  addressAddEvent(address) {
    this.contactForm.controls['defaultReceiptsAddressID'].setValue(address.addressID);
  }
  addressChangeEvent(address) {
    this.contactForm.controls['defaultReceiptsAddressID'].setValue(address.addressID);
  }

}



