<form style="white-space: nowrap;" [formGroup]="formData" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      יבוא אקסל העברות בנק
      <br>

    </h2>
  </div>
  <mat-dialog-content style="padding:0px 24px 24px 24px">
    <div fxLayout="column" style="display: block;">
      <div class="panel-content">
        <div class="form-row" fxLayout="column">
          <div class="input-group mb-3">
            <label>בחר קובץ (עד 198 רשומות בלבד):</label>
            <input type='file' class="form-control" (change)="onSelectFile($event)">
          </div>
        </div>
        <div class="form-row" fxLayout="column">
          <div class="input-group mb-3">
            <label>מספר חשבון עמותה:</label>
            <input autocomplete="off" class="form-control" apply-enter formControlName="associationAccountNumber"
              placeholder="מספר חשבון עמותה...">
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">

    <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn" (click)="uploadXls()"><i
        class="fa fa-exclamation"></i> העלאה</button>

  </mat-dialog-actions>
</form>
