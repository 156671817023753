<form  class="example-form" [formGroup]="countryForm" novalidate>
    <div *ngIf="country" matDialogTitle class="modal-header">
        <h2>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>{{ (id > 0 ? 'עריכת פרטי' : 'הוספת') }} מדינה</small>
            <br>
            {{ country.countryName }}
        </h2>

    </div>
    <mat-dialog-content>

        <div *ngIf="country" fxFlex="100" fxLayout="column">
            <mat-form-field>
                <mat-label>שם מדינה:</mat-label>
                <input matInput apply-enter formControlName="countryName" placeholder="שם מדינה..." />
            </mat-form-field>&nbsp;
            <mat-form-field>
                <mat-label>שם מדינה אנגלית:</mat-label>
                <input matInput apply-enter formControlName="countryNameEn" placeholder="שם מדינה אנגלית..." />
            </mat-form-field>&nbsp;
            <mat-form-field>
                <mat-label>איזור:</mat-label>
                <input matInput apply-enter formControlName="region" placeholder="איזור..." />
            </mat-form-field>
        </div>
        <div *ngIf="country" fxFlex="100" fxLayout="column">
            <div class="row" style="margin:0;">
                <a class="btn btn-w-m btn-info" (click)="addDefaultTerm()">הוסף ברירת מחדל</a>
            </div>
            <hr>
            <div class="divTable" style="width: auto;">
                <div class="divTableHeading">
                    <div style="padding: 10px 4px 10px 0px;" class="divTableCell">
                        נושא
                    </div>
                    <div style="padding: 10px 4px 10px 0px;" class="divTableCell">
                        מסוף
                    </div>
                    <div style="padding: 10px 4px 10px 0px;" class="divTableCell">
                        ...
                    </div>
                </div>
                <div class="divTableBody" formArrayName="defaultTerms">
                    <div [formGroupName]="i" *ngFor="let cfCtrl of fcDefaultTerms.controls; let i=index">
                        <defaultTerm-row [i]="i" [countryForm]="countryForm" [defaultTermtFormGroup]="cfCtrl">
                        </defaultTerm-row>
                    </div>


                </div>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions class="modal-footer" align="end">
        <button [disabled]="countryForm.invalid" class="btn btn-primary navbar-btn" [mat-dialog-close]="countryForm"><i
                class="fa fa-save"></i> שמור </button>
    </mat-dialog-actions>
</form>