import { Directive, Renderer2, Input, AfterViewInit, ElementRef, OnChanges, OnInit } from '@angular/core';

@Directive({ selector: '[loading-status]' })
export class LoadingStatusBarDirective implements OnInit, OnChanges {

    @Input('stopLoadingWhen') stopLoadingWhen;

    loadingBarElement: HTMLDivElement;
    parentElement: ElementRef;
    changeDetected: boolean;

    constructor(public elementRef: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        if (this.stopLoadingWhen == null || this.stopLoadingWhen == undefined || this.stopLoadingWhen == false) {
            this.setLoadingBar();
        }
    }

    ngOnChanges(changes) {
        if (this.loadingBarElement && this.stopLoadingWhen && changes && changes.stopLoadingWhen) {
            this.removeLoadingBar();
        }
    }

    private removeLoadingBar() {
        this.renderer.removeChild(this.parentElement, this.loadingBarElement);
    }

    private setLoadingBar() {
        this.parentElement = this.elementRef.nativeElement.parentElement;
        this.loadingBarElement = this.renderer.createElement('div');
        this.loadingBarElement.innerHTML = `
      <div class="circles">
          <div class="primary" style="border: solid blue 1.5px;"></div>
          <div class="primary" style="border: solid blue 1.5px;"></div>
          <div class="primary" style="border: solid blue 1.5px;"></div>
          <div class="primary" style="border: solid blue 1.5px;"></div>
      </div>
      `

        this.renderer.appendChild(this.parentElement, this.loadingBarElement);
    }
}
