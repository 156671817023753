//import { NgxDeviceInfoModule} from "ngx-device-info";

//import { AngularDeviceInformationService } from 'angular-device-information';


import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorIntl, MAT_PAGINATOR_DEFAULT_OPTIONS } from "@angular/material/paginator";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { UserIdleModule } from "angular-user-idle";
import { appRoutes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthorizeTransactionsMdl } from "./authorizeTransactions/authorizeTransactions.mdl";
import { CampaignAttachedsMdl } from "./campaign-attached/campaign-attacheds.mdl";
import { CampaignsMdl } from "./campaign/campaigns.mdl";
import { CitiesMdl } from "./cities/cities.mdl";
import { CollectionBuildersMdl } from "./collection-builders/collection-builders.mdl";
import { CommitmentReasonsMdl } from "./commitment-reasons/commitmentReasons.mdl";
import { CommitmentsMdl } from "./commitments/commitments.mdl";
import { AlertCenterModule } from "./components/alert-center/alert-center.mdl";
import { AlertModule } from "./components/alert/alert.mdl";
import { DashboardPanelBoxModule } from "./components/dashboard-panel-box/dashboard-panel-box.mdl";
import { FriendlyTitleModule } from "./components/friendly-title/friendly-title.mdl";
import { MenuListItemComponent } from "./components/menu-list-item/menu-list-item.component";
import { NavService } from "./components/menu-list-item/nav.service";
import { ModalModule } from "./components/modal/modal.mdl";
import { NavbarTopModule } from "./components/navbar-top/navbar-top.mdl";
import { NotFoundComponent } from "./components/not-found/not-found.cmp";
import { SignalrMainModule } from "./components/signalr/signalr-main.module";
import { TabsMdl } from "./components/tabs/tabs.mdl";
import { ContactsLotteriesModule } from "./contacts-lotteries/contacts-lotteries.module";
import { ContactsMdl } from "./contacts/contacts.mdl";
import { CountriesMdl } from "./countries/countries.mdl";
import { DashboardMarbeModule } from "./dashboard/dashboard-marbe/dashboard-marbe.module";
import { DashboardMdl } from "./dashboard/dashboard.mdl";
import { GroupsMdl } from "./groups/groups.mdl";
import { HoldingsMdl } from "./holdings/holdings.mdl";
import { HonorTypesMdl } from "./honor-types/honor-types.mdl";
import { HonorsSetsMdl } from "./honors-sets/honors-sets.mdl";
import { HonorsMdl } from "./honors/honors.mdl";
import { IvrDonationUserTasksMdl } from "./ivrDonationUserTasks/ivr-donation-user-tasks.mdl";
import { LetterSettingsMdl } from "./letter-settings/letter-settings.mdl";
import { LoginMdl } from "./login/login.mdl";
import { MaterialModule } from "./material.module";
import { FilterMenuPipe } from "./navbar-side-filterMenu-pipe";
import { PaymentsMdl } from "./payments/payments.mdl";
import { PayMethodsMdl } from "./payMethods/payMethods.mdl";
import { PeleCardTransactionsMdl } from "./pele-card-transactions/pele-card-transactions.mdl";
import { ProcessesTypesMdl } from "./processes-types/processes-types.mdl";
import { ProcessesMdl } from "./processes/processes.mdl";
import { ReceiptsMdl } from "./receipts/receipts.mdl";
import { RegistrationEventsMdl } from "./registrationEvents/registrationEvents.mdl";
import { RegistrationsMdl } from "./registrations/registrations.mdl";
import { ReportsCenterMdl } from "./reports-center/reports-center.mdl";
import { ScreenModule } from "./screen/screen.mdl";
import { GlobalErrorHandler } from "./services/global-error-handler";
import { ServicesData } from "./services/services.data";
import { ShtibelsMdl } from "./shtibels/shtibels.mdl";
import { TermsMdl } from "./terms/terms.mdl";
import { TishEventsMdl } from "./tishEvents/tishEvents.mdl";
import { TransactionsMdl } from "./transactions/transactions.mdl";
import { TransferTasksMdl } from "./transfer-tasks/transfer-tasks.mdl";
import { UsersMdl } from "./users/users.mdl";
import { ToraxHttp } from "./utils/custom-http.svc";
import { UtilsModule } from "./utils/utils.mdl";
import { VouchersOrganizationsMdl } from "./vouchersOrganizations/vouchersOrganizations.mdl";
import { VouchersOrgPaymentPacksMdl } from "./vouchersOrgPaymentPacks/vouchersOrgPaymentPacks.mdl";
import { AuthGuardMdl } from "./_guards/auth.guard.mdl";
import { getDutchPaginatorIntl } from "./_helpers/dutch-paginator-intl";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { DepositsMdl } from "./deposits/deposits.mdl";
import { YarzeitsMdl } from "./yarzeits/yarzeits.mdl";
import { GlobalHelper } from "./_helpers/global-helper";
import { BrowserModule } from "@angular/platform-browser";
import { IvrAssignmentUserTasksMdl } from "./ivrAssignmentUserTasks/ivr-assignment-user-tasks.mdl";
import { EmergencySquadsMdl } from "./emergency-squads/emergency-squads.mdl";
//import { NgxPrintModule } from "ngx-print";


@NgModule({
  imports: [
    BrowserModule ,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes), //, { scrollPositionRestoration: 'top' }
    MaterialModule,
    // NavbarSideMdl,
    NavbarTopModule,
    DashboardPanelBoxModule,
    ModalModule,
    AlertModule,
    AlertCenterModule,
    UtilsModule,
    TabsMdl,
    LoginMdl,
    AuthGuardMdl,
    ProcessesMdl,
    PayMethodsMdl,
    LetterSettingsMdl,
    PaymentsMdl,
    CollectionBuildersMdl,
    HoldingsMdl,
    TransferTasksMdl,
    CommitmentsMdl,
    PayMethodsMdl,
    TransactionsMdl,
    AuthorizeTransactionsMdl,
    PeleCardTransactionsMdl,
    ShtibelsMdl,
    HonorsMdl,
    HonorTypesMdl,
    HonorsSetsMdl,
    RegistrationsMdl,
    ProcessesTypesMdl,
    CommitmentReasonsMdl,
    GroupsMdl,
    ReceiptsMdl,
    VouchersOrgPaymentPacksMdl,
    VouchersOrganizationsMdl,
    TishEventsMdl,
    DashboardMdl,
    RegistrationEventsMdl,
    UsersMdl,
    CitiesMdl,
    CountriesMdl,
    TermsMdl,
    DashboardMarbeModule,
   // DashboardLotteryModule,
    ContactsMdl,
    ContactsLotteriesModule,
    FriendlyTitleModule,
    IvrAssignmentUserTasksMdl,
    IvrDonationUserTasksMdl,
     UserIdleModule.forRoot({ idle: 480, timeout: 540, ping: 60 })  ,
    SignalrMainModule,
    ScreenModule,
    CampaignsMdl,
    CampaignAttachedsMdl,
    ScreenModule,
    ReportsCenterMdl,
    DepositsMdl,
    YarzeitsMdl,
    EmergencySquadsMdl
   // NgxPrintModule
   //NgxDeviceInfoModule
    //  // Fuse, FuseConfig & FuseMockAPI
    //  FuseModule,
    //  FuseConfigModule.forRoot(FUSE_APP_CONFIG),
  ],


  declarations: [
    AppComponent,
    NotFoundComponent,
    FilterMenuPipe,
    MenuListItemComponent
   ],
  providers: [
    ToraxHttp,
    ServicesData,
    GlobalHelper,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'none', showFirstLastButtons: true, hidePageSize: false, pageSizeOptions:[10, 25, 30, 50, 100] } },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    NavService,
    //AngularDeviceInformationService

  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }


