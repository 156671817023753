import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from "rxjs";
import { Shtibel } from './shtibel/shtibel';
import { ObjectsListResult } from '../services/objects-list-results';


@Injectable()

export class ShtibelsData {
    public url = 'api/shtibels';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }


    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    getGabaisList(baseQuery: any) {
        return this.toraxHttp.update(`${this.url}/GabaisList`, baseQuery);
    }

    downloadHoldingsPivotReportExls(year: number, shtibelID?: number) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadHoldingsPivotReportExls/${year}/${shtibelID}`, {});
    }

    exportListExcel(baseQuery: any)  {
        return this.toraxHttp.postWithResponce(`${this.url}/downloadExls`, baseQuery);
    }

    exportGabaisExcel(baseQuery: any)  {
        return this.toraxHttp.postWithResponce(`${this.url}/downloadGabaisExls`, baseQuery);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    update(shtibel: Shtibel) {
        return this.toraxHttp.update(this.url, shtibel);
    }

    getAdd()  {
        return this.toraxHttp.get(`${this.url}/add`);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }

}
