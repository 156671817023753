import { GroupMain } from './group/group-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { GroupsList } from './groups-list.cmp'; 
import { GroupDetails } from './group/group-details.cmp';

export const GroupsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'groups', component: GroupsList, data: {  roles: "groups",  title: 'רשימת קבוצות' } },
    { canActivate: [AuthGuard], path: 'groups/add', component: GroupMain, data: { mode: 'edit', roles: "groups", title: 'הוסף קבוצה' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'groups/:groupID', component: GroupMain, data: {  roles: "groups",  title: 'פרטי קבוצה' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'groups/:groupID/edit', component: GroupMain, data: { mode: 'edit', roles: "groups",  title: 'עריכת פרטי קבוצה' }, outlet: 'popup' }
]; 