import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './../material.module';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';


import { AutoCompleteInputModule } from './../components/auto-complete-input/auto-complete-input.mdl';

import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { AddressesSelectPick } from './addresses-select-pick.cmp';
import { AddressAddAction } from './address/address-add-action.cmp';
import { AddressEditDlg } from './address/address-edit-dlg.cmp';
import { AddressesList } from './addresses-list.cmp';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { AddressesData } from './addresses.data';
import { AddressDetails } from './address/address-details.cmp';
import {A11yModule} from '@angular/cdk/a11y';
import {BidiModule} from '@angular/cdk/bidi';
import {ObserversModule} from '@angular/cdk/observers';
import {OverlayModule} from '@angular/cdk/overlay';
import {PlatformModule} from '@angular/cdk/platform';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';


@NgModule({
    imports: [
        CommonModule,
        ////BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,
        AutoCompleteModule,
        ModelInfoModule,
        A11yModule,
        BidiModule,
        ObserversModule,
        OverlayModule,
        PlatformModule,
        PortalModule,
        ScrollingModule,
        AutoCompleteInputModule,
        MaterialModule
        ],
    declarations: [
        AddressesList,
        AddressDetails,
        AddressEditDlg,
        AddressAddAction,
        AddressesSelectPick,
    ],
    exports: [
        AddressesList,
        AddressAddAction,
        AddressesSelectPick
      //  AddressesShortEdit
    ],
    providers: [
        AddressesData
    ],
    entryComponents: [
        AddressesList,
        AddressAddAction,
        AddressesSelectPick,
        AddressEditDlg
       // AddressDlg,
    ]
})
export class AddressesMdl { }



