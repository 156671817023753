import { ServicesData } from 'src/app/services/services.data';
//import { AutoCompleteComponent } from '../../../components/auto-complete/auto-complete.cmp';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { TishInvited } from '../tishInvited';

@Component({
  selector: 'tishInviteds-subs',
  templateUrl: './tishInviteds-subs.tmpl.html',
  //styleUrls: ['./../../payments.scss']
})


export class TishInvitedsSubs implements OnInit, AfterViewInit {

  control: FormArray;
  mode: boolean;
  touchedRows: any;
  constructor(private fb: FormBuilder, private servicesData: ServicesData) { }

  @Input('tishInvitedForm') public tishInvitedForm: FormGroup;
  @Input('tishInviteds') public tishInviteds: any[];
  @Input('tishInvited') public tishInvited: any;



  ngOnInit(): void {
    this.touchedRows = [];
    // if (this.tishInvited.tishInvitedID == 0 && this.tishInviteds.length == 0) {
    //   this.addRow();
    // }
  }

  ngAfterViewInit(): void {
    if (this.tishInviteds && this.tishInvited.tishInvitedID > 0) {
      const arr = <FormArray>this.tishInvitedForm.controls['tishInviteds'];
      this.tishInviteds.forEach(invitedsSub => {
      //  invitedsSub.isChild = invitedsSub.invitedStatus == 'ילד';
        let invitedForm = this.initialForm(invitedsSub);
        arr.push(invitedForm);
      });
    }
  }

  initialForm(row: any = null): FormGroup {
    let frm = this.fb.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      tishInvitedID: [row?.tishInvitedID || 0, Validators.required],
      contactID: [row?.contactID, Validators.required],
      tishInvitedName: [row?.tishInvitedName],
     // hostContactID: [(row?.hostContactID > 0 ? row?.hostContactID : this.tishInvited.contactID), Validators.required],
      hostInvitedID: [row?.hostInvitedID, Validators.required],
      hostContactName: [],
      tishGroupID: [row?.tishGroupID],
      tishGroupName: [],
      tishAreaID: [row?.tishAreaID],
      tishAreaName: [],
      tishEventID: [row?.tishEventID, Validators.required],
      tishEventTitle: [],

      isPlace: [row?.isPlace ?? false, Validators.required],
      invitedStatus: [row?.invitedStatus],
      specialInvitedPerfix: [(row?.specialInvitedPerfix ? row?.specialInvitedPerfix : row?.perfix)],
      specialInvitedFirstName: [(row?.specialInvitedFirstName ? row?.specialInvitedFirstName : row?.firstName)],
      specialInvitedLastName: [(row?.specialInvitedLastName ? row?.specialInvitedLastName : row?.lastName), (!row.contactID && row.isChild ? Validators.required: null)],
      specialInvitedSuffix: [(row?.specialInvitedSuffix ? row?.specialInvitedSuffix : row?.suffix)],
      ticketName: [],
      comment: [],
      quantityAdditionalTickets: [null], //לע"ע בוטל
      isChild: [row?.isChild, Validators.required],
      doSendletter:[(row?.doSendletter ? row.doSendletter : 0), Validators.required],
      invitedConfirmed:[row?.invitedConfirmed ],
      yomKipur:[ row?.yomKipur , Validators.required],
      ticketPrinted: [row?.ticketPrinted, Validators.required],
      marking1: [row?.marking1, Validators.required],
      marking2: [row?.marking2, Validators.required],
      contributorRating: [row?.contributorRating],
      isDeleted: [row?.isDeleted, Validators.required],
    });
    // frm.controls['invitedStatus'].valueChanges.subscribe(x => {
    //   console.log(x);
    //   frm.controls['isChild'].setValue(x == 'ילד');
    // });
    //frm.controls['invitedStatus'].setValue(row?.invitedStatus);

    return frm;
  }
addChildRow(){
  this.addRow(true);
}
addContactRow(){
  this.addRow(false);
}

  addRow(isChild:boolean) {
    const arr = this.tishInvitedForm.get('tishInviteds') as FormArray;

    let inv = new TishInvited();
    inv.contactID = 0;
    inv.tishEventID = this.tishInvited.tishEventID;
    inv.tishAreaID = this.tishInvited.tishAreaID;
    inv.tishGroupID =null; //this.tishInvited.tishGroupID;
   // inv.hostContactID = this.tishInvited?.contactID;
    inv.hostInvitedID = this.tishInvited?.tishInvitedID;
    inv.tishEventID = this.tishInvited.tishEventID;
    inv.isPlace =  isChild ? false: true;
    inv.invitedStatus = isChild ? 'אורח' :null;
    inv.specialInvitedPerfix =  isChild ?'הילד היקר' :null;
    inv.specialInvitedFirstName = null;
    inv.specialInvitedLastName = isChild ? (this.tishInvited?.lastName == '' || this.tishInvited?.lastName == null) ? this.tishInvitedForm.controls['lastName'].value : this.tishInvited?.lastName: null;
    inv.specialInvitedSuffix =  isChild ?'נ"י':null;
    inv.quantityAdditionalTickets = null;
    inv.isChild = isChild;
   // inv.contributorRating = this.tishInvited?.contributorRating;  בוטל לבקשת משה יוס'ל שלא יעדכן אוטומטי מעמד תורם לאורחים ע"פ המארח
   inv.yomKipur = false; // = this.tishInvited?.yomKipur;
   inv.ticketPrinted = false; ////this.tishInvited?.ticketPrinted;
   inv.marking1 = false; //this.tishInvited?.marking1;
   inv.marking2 = false; //this.tishInvited?.marking2;
   inv.doSendletter = false; //this.tishInvited?.doSendletter;
   inv.isDeleted = false;

    arr.push(this.initialForm(inv));
  }

  // deleteItem(index: number) {
  //   let inviteds = this.tishInvitedForm.get('tishInviteds') as FormArray;
  //   let form = (<FormGroup>inviteds.controls[index]);
  //   form.disable();
  //   form.controls["isDeleted"].setValue(true);
  // }

  // unDeleteItem(index: number) {
  //   let inviteds = this.tishInvitedForm.get('tishInviteds') as FormArray;
  //   let form = (<FormGroup>inviteds.controls[index]);
  //   form.enable();
  //   form.controls["isDeleted"].setValue(false);
  // }
  deleteItem(index: number) {
    let inviteds = this.tishInvitedForm.get('tishInviteds') as FormArray;
    let form = (<FormGroup>inviteds.controls[index]);
    form.disable();
    form.controls["isDeleted"].setValue(true);
  }
  unDeleteItem(index: number) {
    let inviteds = this.tishInvitedForm.get('tishInviteds') as FormArray;
    let form = (<FormGroup>inviteds.controls[index]);
    form.enable();
    form.controls["isDeleted"].setValue(false);
  }


  // deleteRow(index: number) {
  //   const control = this.tishInvitedForm.get('tishInviteds') as FormArray;
  //   control.removeAt(index);
  // }

  editRow(group: FormGroup) {
    group.get('iEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('iEditable').setValue(false);
  }

  // saveUserDetails() {
  //   console.log(this.tishInvitedForm.value);
  // }

  get getFormControls() {
    const control = this.tishInvitedForm.get('tishInviteds') as FormArray;
    return control;
  }

  submitForm() {
    const control = this.tishInvitedForm.get('tishInviteds') as FormArray;
    this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    //  console.log(this.touchedRows);
  }

  toggleTheme() {
    this.mode = !this.mode;
  }

}
