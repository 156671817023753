import { style } from '@angular/animations';

import { Component, OnInit, ViewChild, HostListener, ElementRef, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
    selector: 'amount-input',
    templateUrl: 'amount-input.tmpl.html',
    styleUrls: ['./amount-input.scss']
})



export class AmountInput implements OnInit  {
    amount?;
    constructor() {
     
     } 


    @Input('amountControl') amountControl: FormControl;
    @Input('rangeTemplate') rangeTemplate?: boolean = false;      
    @Input('placeholder') placeholder?: string = 'סכום...';

    @ViewChild('amountInp') ndt: ElementRef;

    
    @Output() amountChange = new EventEmitter<number>();


    public obserAmount: Observable<ElementRef> = new Observable<ElementRef>();


    ngOnInit() {
        if (this.amountControl) {
            this.amount = this.amountControl.value;
        }
    }


}

            