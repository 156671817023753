
import { Component, OnInit, Output } from '@angular/core';
import { JewishWeekDay } from './jewish-week-day-class';
import { EventEmitter } from  '@angular/core';

@Component({
  selector: 'jewish-week-days-picker',
  templateUrl: './jewish-week-days-picker.component.html',
  styleUrls: ['./jewish-week-days-picker.component.css']
})


export class JewishWeekDaysPickerComponent implements OnInit {
  weekDays: JewishWeekDay[] = [];
  weekNumber: number;
  year: number;

  @Output() selectedDayChange = new EventEmitter<Date>();
  ngOnInit(): void {
    this.initializeDate();
  }

  initializeDate(date: Date = null): void {
    let dt = date != null ? date : new Date();
    this.year = dt.getFullYear();
    this.weekNumber = this.getWeekNumberFromDate(dt);
    this.initializeWeekDays();

  }

  initializeWeekDays(): void {
    let list = this.getDaysForWeekNumber(this.year, this.weekNumber);
    for (let i = 0; i < list.length; i++) {
      let day = new JewishWeekDay(list[i]);
      this.weekDays.push(day)
    }

  }


  getDaysForWeekNumber(year: number, weekNumber: number): Date[] {
    const days: Date[] = [];
    // Calculate the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);
    // Adjust to the first day of the given week number
    // Subtract 1 from weekNumber to handle the first week correctly
    // Adjust for the start of the week (e.g., Sunday or Monday) by subtracting firstDayOfYear.getDay()
    const daysToAdd = (weekNumber - 1) * 7 - firstDayOfYear.getDay();
    const firstDayOfWeek = new Date(firstDayOfYear.setDate(firstDayOfYear.getDate() + daysToAdd));

    // Iterate through the week, adding each day to the array
    for (let i = 0; i < 7; i++) {
      const day = new Date(firstDayOfWeek);
      day.setDate(day.getDate() + i);
      days.push(day);
    }

    return days;
  }

  getWeekNumberFromDate(date: Date): number {
    // Create a copy of the date object to avoid modifying the original date
    const currentDate = new Date(date.getTime());

    // Set the hours to ensure the time part doesn't affect the calculation
    currentDate.setHours(0, 0, 0, 0);

    // Get the first day of the year
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

    // Calculate the number of days between the current date and the start of the year
    const daysSinceStartOfYear = (currentDate.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000);

    // Calculate the week number
    // Add 1 because the calculation should start from 1, not 0
    // Add the start day of the year to the calculation to adjust for the first week
    const weekNumber = Math.ceil((daysSinceStartOfYear + startOfYear.getDay() + 1) / 7);

    return weekNumber;
  }

  // getWeekDaysFromDate(date: Date): Date[] {
  //   const result: Date[] = [];

  //   // Find the day of the week for the given date
  //   const dayOfWeek = date.getDay();

  //   // Calculate the start of the week (Sunday)
  //   const startDate = new Date(date);
  //   startDate.setDate(date.getDate() - dayOfWeek);

  //   // Generate the list of week days
  //   for (let i = 0; i < 7; i++) {
  //     const currentDate = new Date(startDate);
  //     currentDate.setDate(startDate.getDate() + i);
  //     result.push(currentDate);
  //   }

  //   return result;
  // }

  selectDay(date) {
    this.selectedDayChange.emit(date);
  }
  prev() {
    this.weekDays = [];
    this.weekNumber = (this.weekNumber - 1);
    this.initializeWeekDays();
  }

  next() {
    this.weekDays = [];
    this.weekNumber = (this.weekNumber + 1);
    this.initializeWeekDays();
  }
}
