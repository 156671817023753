import { ServicesData } from './../../services/services.data';
import { TishAreasData } from './../tishAreas.data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@Component({

    templateUrl: './tishArea-edit-dlg.tmpl.html'
})
export class TishAreaDlg implements OnInit {
    public formData: FormGroup;


    constructor(
        public dialogRef: MatDialogRef<TishAreaDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        private tishAreasData: TishAreasData,

    ) { }

    ngOnInit(): void {
        this.formData = this.tishAreasData.initForm();
        this.formData.patchValue(this.data);
    }

    public curentColor = '#cccccc';
    public onChangeColor(color: string): void {

        this.curentColor = color;
        this.formData.controls.areaColor.setValue(color);
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}
