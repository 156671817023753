import { Payment } from 'src/app/payments/payment/payment';
import { PaymentsData } from './../../payments/payments.data';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, EventEmitter, Output } from '@angular/core';


@Component({
    selector: 'vouchers-org-payment-dlg',
    templateUrl: './vouchers-org-payment-dlg.tmpl.html'
})

export class VouchersOrgPaymentDlg {

    constructor(
        public dialog: MatDialog
    ) {

    }
    @Output() paymentChange = new EventEmitter<any>();
    @Input('payment') public payment: Payment;

    paymentChangeEvent(payment) {
        this.paymentChange.emit(payment);
    }
}



