import { ServicesData } from './../../services/services.data';
import { TishPlacesData } from './../tishPlaces.data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { I } from '@angular/cdk/keycodes';

const dropDownRequired = c => {
  if (!c.value || c.value === 'undefined') {
    return { required: true };
  }
  return null;
};

@Component({

  templateUrl: './tishPlace-edit-dlg.tmpl.html'
})
export class TishPlaceDlg implements OnInit {
  public formData: FormGroup;

  //private isRight?: boolean;
  constructor(
    public dialogRef: MatDialogRef<TishPlaceDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private tishPlacesData: TishPlacesData,
    private cdref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
      this.load();
  }
  load() {
    if (this.data.tishPlaceID) {
      this.tishPlacesData.getEdit(this.data.tishPlaceID)
        .subscribe((c: any) => {
          this.formData = this.tishPlacesData.initForm();
          this.formData.removeControl("tishInvited"); //TODO פיתרון זמני
          this.data = c;
          this.formData.patchValue(this.data);
          this.cdref.detectChanges();
        });
    }

  }



  public isValid(): boolean {
    return (this.formData && this.formData.valid);
  }
}
