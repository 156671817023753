<a mat-list-item  (click)="onItemSelected(item)"
   [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}" class="menu-list-item">
   <i class="fa {{item.iconName}}"></i>  &nbsp;
   {{item.displayName}} &nbsp;


     <i style="margin-top: -12px; color: #e86e30; scale: 1;" *ngIf="item.notificationAlert == true"   customToolTip [contentTemplate]="ttTemplate" class="fa fa-bell"></i>


     <ng-template #ttTemplate>

      <deposits-waitings-warnings></deposits-waitings-warnings>
    </ng-template>

  <span *ngIf="item.children && item.children.length">
    <i style="margin-right: 5px;" [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" class="fa arrow"></i>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>
