import { Contact } from './../../contact/contact';

export class ContactEvent {
    contactEventID: number;
    contactID: number;
    contactName: string;
    contactEventType: number;
    description: string;
    fromDateTime: Date;
    tillDateTime?: Date;
    fromTime?: number;
    tillTime?: number;
    fromJewishDate: string;
    tillJewishDate: string;
    eventTypeName: string;
    fromTimeStr: string;
    tillTimeStr: string;

}

