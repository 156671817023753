import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardPanelBoxComponent } from './dashboard-panel-box.cmp';

@NgModule({
    imports: [CommonModule],
    exports: [DashboardPanelBoxComponent],
    declarations: [DashboardPanelBoxComponent],
    providers: [],
})
export class DashboardPanelBoxModule { }
