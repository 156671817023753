 
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { CampaignAttachedsList } from './campaign-attacheds-list.cmp';
import { CampaignAttachedMain } from './campaign-attached/campaign-attached-main.cmp';

export const CampaignAttachedsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'campaignAttacheds', pathMatch: 'full', component: CampaignAttachedsList, data: {  roles: "campaignAttacheds",  title: 'רשימת משתתף' } },
    { canActivate: [AuthGuard], path: 'campaignAttacheds/add', component: CampaignAttachedMain, data: { mode: 'edit', roles: "campaignAttacheds", title: 'הוסף משתתף' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'campaigns/:campaignID/campaignAttacheds/add', component: CampaignAttachedMain, data: {   mode: "edit", roles: "campaignAttacheds", title: 'הוסף משתתף' }, outlet: 'popup' },           
  
    { canActivate: [AuthGuard], path: 'campaignAttacheds/:campaignAttachedID', component: CampaignAttachedMain, data: {  roles: "campaignAttacheds",  title: 'פרטי משתתף' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'campaignAttacheds/:campaignAttachedID/edit', component: CampaignAttachedMain, data: { mode: 'edit', roles: "campaignAttacheds",  title: 'עריכת פרטי משתתף' }, outlet: 'popup' }
]; 

 