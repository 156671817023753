﻿<div *ngIf="campaign" loading-status [stopLoadingWhen]="campaign != null">
    
        <div class="modal-header" fxLayoutAlign="start start" fxLayout="row" fxLayout.xs="column">
            <div fxFlex="35" style="white-space: nowrap;">
                <h1 #hebTitle style="width: fit-content;">
                    [{{ campaign.campaignID }}]{{ campaign.campaignName}} &nbsp;&nbsp; <small> {{
                        campaign.campaignStartDate | date:'dd/MM/yyyy' }}</small>
                </h1>
                <h2>
                    יעד: {{ campaign.destination }}
                </h2>
                <div style="float: left;">
                    <h2 style="color: green;" *ngIf="campaign.visibleDestination == true; else dist"><br>מציג יעד</h2>
                    <ng-template #dist>
                        <h2 style="color: red;" #dist>לא מציג יעד</h2>
                    </ng-template>
                </div>
            </div>
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="end start">

                <a class="btn btn-primary"
                    [routerLink]="['', {outlets: {popup: ['campaigns', id, 'campaignAttacheds', 'add']}}]">
                    <i class="fa fa-plus"></i>&nbsp;הוסף משתתף</a>
                &nbsp;&nbsp; 
                <!-- <a class="btn btn-primary" 
                [routerLink]="['', {outlets: {popup: ['campaigns', id, 'edit']}}]">
                    <i class="fa fa-pencil"></i>&nbsp;עריכת פרטי קמפיין</a> -->

            </div>
        </div>
        <div class="row modal-body" *ngIf="!rerender" style="height: auto;">
            <campaign-attacheds-list [campaignID]="campaign.campaignID"></campaign-attacheds-list>
        </div>
     
</div>