
import { AuthorizeTransactionsData } from './authorizeTransactions.data';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ServicesData } from '../services/services.data';
import { ToraxHttp } from '../utils/custom-http.svc';

@Component({
	selector: 'authorizeTransactions-list',
	templateUrl: './authorizeTransactions-list.tmpl.html'
})

export class AuthorizeTransactionsList implements OnInit {

	constructor (
		private authorizeTransactionsData: AuthorizeTransactionsData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private router: Router,
		public _matDialog: MatDialog,
		private toraxHttp: ToraxHttp
	) {
	}

	displayedColumns: string[] = ['buttons', 'toraxTermID', 'transId', 'transactionID', 'settleAmount', 'submitTimeLocal', 'transactionStatus', 'accountNumber', 'accountType', 'hasReturnedItemsSpecified'];
	data: any[] = [];
	resultsLength = 0;
	isRateLimitReached = false;
	searchAuthorizeTransactionsListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;

	@ViewChild(MatAccordion, {static: true}) accordion: MatAccordion;
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;
	@Input('payMethodID') public payMethodID?: number;
	@Input('collectionBuilderID') public collectionBuilderID?: number;
	@ViewChild('payMethodGroups') payMethodGroups: MatSelect;




	public clerFilter() {
		this.initForm();
		this.getAuthorizeTransactions();
	}
	public getAuthorizeTransactions() {
		this.searchAuthorizeTransactionsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);

		if (this.payMethodID && this.payMethodID > 0) {
			this.searchAuthorizeTransactionsListForm.controls['payMethodID'].setValue(this.payMethodID);
		}
		if (this.collectionBuilderID && this.collectionBuilderID > 0) {
			this.searchAuthorizeTransactionsListForm.controls['collectionBuilderID'].setValue(this.collectionBuilderID);
		}

		sessionStorage.setItem(this.router.url, JSON.stringify(this.searchAuthorizeTransactionsListForm.value));

		return this.authorizeTransactionsData!.getList(this.searchAuthorizeTransactionsListForm.value)
			.subscribe((data: any) => {
				this.data = data;
				this.sourceList = data.list;
				this.page = data.list.length;
				this.pageRows = data.list.length;
				this.rowsCount = data.rowsCount;
				this.isRateLimitReached = false;
			});
	}

	public changePage(page: number) {
		this.searchAuthorizeTransactionsListForm.controls['page'].setValue(page);
		this.getAuthorizeTransactions();
	}

	private initForm() {
		this.searchAuthorizeTransactionsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			contactID: [],
			commitmentID: [],
			statusResponses: [null],
			payMethodGroup: [null],
			payMethodID: [null],
			collectionBuilderID: [null],
		});
	}

	ngOnInit() {
		this.initForm();
		if (this.payMethodID == null) {
			let item = sessionStorage.getItem(this.router.url);
			if (item) {
				let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
				this.searchAuthorizeTransactionsListForm.patchValue(frm);
			}
		}
		this.getAuthorizeTransactions();
	}

	selectionChanged(e) {
		this.getAuthorizeTransactions();
	}

	pageChange(e) {
		this.searchAuthorizeTransactionsListForm.controls['pageRows'].setValue(e.pageSize || 20);
		this.selectionChanged(e);
	}
	sortData(e) {
		this.addOrder(e.active, e.direction != "asc");
		this.getAuthorizeTransactions();
	}
	search() {
		this.getAuthorizeTransactions();
	}
	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchAuthorizeTransactionsListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
	}
	pageEvent: PageEvent;
	pageSizeOptions;
	setPageSizeOptions(setPageSizeOptionsInput: string) {
		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}
	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}
	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchAuthorizeTransactionsListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}
	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}
}
