<div>
    <div   class="form-horizontal">
        <div [formGroup]="searchForm" *ngIf="searchForm">
            <div class="form-group">
                <label class="control-label col-sm-5" for="registrationEventID">בחר אירוע:</label>
                <div class="col-sm-7">
                    <auto-complete  [controllerName]="'RegistrationEvents'" [formGroupModel]="searchForm" [idControl]="searchForm.controls.registrationEventID" [nameControl]="searchForm.controls.registrationEventTitle"></auto-complete>
                </div>
            </div>
            <div  class="form-group">
                <label class="control-label col-sm-5" for="contactID">הכנס מזהה איש לחיפוש:</label>
                <div class="col-sm-7">
                    <input apply-enter style="z-index:-2" type="number" formControlName="contactID" #searchInput class="form-control" (keyup)="onKey($event)" />
                </div>
            </div>
        </div>
        <div style="background-color: #e5fae4; padding: 50px;" [formGroup]="updateCashRegisterForm" novalidate (ngSubmit)="postUpdateCashRegister()" *ngIf="updateCashRegisterModel">
            <div class="form-group">
                <label class="control-label col-sm-5">פרטים לעדכון:</label>
                <div class="col-sm-7">
                    [{{ updateCashRegisterModel.contactID }}] {{ updateCashRegisterModel.firstName }} {{ updateCashRegisterModel.lastName }}
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-sm-5"></label>
                <div class="col-sm-7">
                    {{ updateCashRegisterModel.amountToPay | currency:'ILS':true:'1.0-0' }}
                </div>
            </div>
            <button apply-enter style="float: left" *ngIf="updateCashRegisterModel && updateCashRegisterModel.amountToPay > 0" #saveButton (click)="postUpdateCashRegister()" type="button" class="btn btn-w-m btn-info left">עדכן</button>
            <!-- <a *ngIf="updateCashRegisterModel && updateCashRegisterModel.amountToPay > 0" style="z-index:-1; float: left;" class="btn btn-w-m btn-info" #saveButton (click)="postUpdateCashRegister()">עדכן</a> -->
        </div>
        <h4 style="color:red" *ngIf="updateCashRegisterModel && updateCashRegisterModel.amountToPay <= 0">לא נמצא סכום לחיוב</h4>

    </div>
    <div style="border: solid; padding: 5px;" *ngIf="topUpdatesInCashRegisters">
        <h4 style="border: solid; border-width: 0 0 1px 0;">תיעוד תשלומים אחרונים</h4>
        <div  class="div-list" *ngFor="let r of topUpdatesInCashRegisters">
            {{r.lastName}}
            {{r.firstName}}
            ( {{r.amountPaid |number }} )
        </div>
    </div>
</div>

<ng-template #loading>
    <h1>
            <i style="color: gray" class="fa fa-refresh fa-spin"></i>  
    </h1>
</ng-template>