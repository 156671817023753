



<div fxLayout="row" fxFlexFill>
  <div style="padding: 0;margin: 0;" *ngIf="!payMethod || !payMethod.payMethodID">
    <a #matMenuTrigger fxLayout="column"
      style="min-width: 10px  !IMPORTANT; min-height: 10px  !IMPORTANT;  line-height: 10px; padding:4px"
      matTooltip="הוסף אמצעי תשלום" mat-raised-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
      (mouseover)="menuTrigger.openMenu()">
      <mat-icon style="color: GRAY;">add_box</mat-icon>
    </a>
    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <span (mouseleave)="menuTrigger.closeMenu()">
        <button mat-menu-item (click)="openAddOrEditPayMethod(1)">
          <mat-icon>credit_card</mat-icon>
          <span>אשראי</span>
        </button>
        <button mat-menu-item (click)="openAddOrEditPayMethod(4)">
          <mat-icon>account_balance</mat-icon>
          <span>הו"ק בנקאי</span>
        </button>
      </span>
    </mat-menu>
  </div>
  <div fxFlex>
  </div>
  <div>
    <a style="padding: 4px" *ngIf="payMethod && payMethod.payMethodID > 0"
      (click)="selectCurrent(payMethod.paymentOption)">
      <i matTooltip="בחר" class="fa fa-check-circle-o"></i>
    </a>
    &nbsp;&nbsp;
    &nbsp;&nbsp;
    <a style="padding: 4px" *ngIf="payMethod && payMethod.payMethodID > 0"
      (click)="openAddOrEditPayMethod(payMethod.paymentOption)">
      <i matTooltip="עריכה ופרטים נוספים" class="fa fa-pencil"></i>
    </a>
  </div>
</div>
