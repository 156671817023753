import { ivrAssignmentUserTasksList } from './ivr-assignment-user-tasks-list.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { IvrAssignmentUserTaskMain } from './ivr-assignment-user-task/ivr-assignment-user-task-main.cmp';


export const IvrAssignmentUserTasksRoutes: Routes = [
  { canActivate: [AuthGuard], path: 'ivrAssignmentUserTasks', component: ivrAssignmentUserTasksList, data: { roles: "ivrAssignmentUserTasks", title: 'משימות תחזוקה טלפוניות' } },
  { canActivate: [AuthGuard], path: 'ivrAssignmentUserTasks/add', component: IvrAssignmentUserTaskMain, data: { roles: "ivrAssignmentUserTasks", title: 'הוסף משימה' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'ivrAssignmentUserTasks/:ivrAssignmentUserTaskID/edit', component: IvrAssignmentUserTaskMain, data: { roles: "ivrAssignmentUserTasks", title: 'עריכת פרטי משימה' }, outlet: 'popup' }
];