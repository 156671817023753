
import { MaterialModule } from '../material.module';
import { ReceiptsRoutes } from './receipts.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { ReceiptsList } from './receipts-list.cmp';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PaginationModule } from '../components/pagination/pagination.mdl';

import { ReceiptsData } from './receipts.data';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(ReceiptsRoutes),
        PaginationModule,
        AutoCompleteModule,
        MaterialModule,
    ],
    exports: [
        ReceiptsList,
    ],
    declarations: [
        ReceiptsList,
    ],
    providers: [
        ReceiptsData
    ],
    entryComponents: [
    ]
})
export class ReceiptsMdl { }

