import { ServicesData } from '../../services/services.data';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Input, EventEmitter, Output, Inject, OnInit } from '@angular/core';
import { ReportsCenterData } from '../reports-center.data';

@Component({
    selector: 'rc-payments-plans',
    templateUrl: './rc-payments-plans.tmpl.html'
})

export class PaymentsPlansReportsDlg implements OnInit {

    constructor(

        public dialogRef: MatDialogRef<PaymentsPlansReportsDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        private reportsCenterData: ReportsCenterData
    ) { }

    public formData: FormGroup;


    ngOnInit(): void {
        this.initForm();
    }


    public exportPdf(): void {
      this.reportsCenterData.getListReport(this.formData.value)
          .subscribe(
            data => {
              let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"');
              popupWinindow.document.open();
              popupWinindow.document.write('<html>' + (<any>data).value + '</html>');
              popupWinindow.document.close();
              this.dialogRef.close();
              }
          )
  }


    public exportExcel(): void {

      this.reportsCenterData.exportExcel(this.formData.value)
        .subscribe(blob => {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `דוח תוכניות תשלום - פלוס.xlsx`;
          link.click();
          this.dialogRef.close();
        });
    }



  private initForm() {
    this.formData = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      paymentsPlanID: [],
      contactName: [],
      paymentsPlanTypes: [null],
      fromDateRange: [],
      tillDateRange: [],
      pulseAmountRange: [],
      commitmentID: [],
      withTillDate: [null],
      cityIDs: [null],
      shtibelID: [null],
      friendsInStiebel:[null],
      commitmentSubjects:[],
      shtibelName: [],
      orderByShtibel: [false,Validators.required],
      withPaymentsForTreatment:[],
      activePaymentsPlans:[],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });
    }


    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}



