import { DashboardChartsSubTabsComponent } from './dashboard-charts-sub-tabs.component'; 
import { DashboardChartsTabsComponent } from './dashboard-charts-tabs.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {  CurrencyPipeMdl } from './../../components/pipes/currency-pipe';
import { DashboardChartsDataService } from './dashboard-charts.data';
import { AuthGuardMdl } from './../../_guards/auth.guard.mdl';
import { MaterialModule } from './../../material.module'; 
// import { NgxChartsModule } from '@swimlane/ngx-charts'; 
import { DashboardChartsRoutes } from './dashboard-charts.routing';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
// Angular Imports
import { NgModule } from '@angular/core';
import { DashboardChartsControlComponent } from './dashboard-charts-control.component';  
import { DashboardChartsMainComponent } from './dashboard-charts-main.component';

@NgModule({
    imports: [
        CommonModule, 
        FormsModule,
        ReactiveFormsModule,        
        RouterModule.forChild(DashboardChartsRoutes),
        // NgxChartsModule,
        ScrollingModule,
        AuthGuardMdl,
        MaterialModule,
        CurrencyPipeMdl, 

    ],
    declarations: [  
        DashboardChartsControlComponent,
        DashboardChartsTabsComponent,
        DashboardChartsSubTabsComponent, 
        DashboardChartsMainComponent, 
        
    ],
    entryComponents:[DashboardChartsControlComponent],
    exports: [
    ],
    // entryComponents: [
    //     DashboardChartsComponent
    // ],
    providers: [DashboardChartsDataService]
})
export class DashboardChartsModule {

}
 