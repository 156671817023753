import { TishGroupMain } from './tishGroup/tishGroup-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { TishGroupsList } from './tishGroups-list.cmp'; 

export const TishGroupsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'tishGroups', component: TishGroupsList, data: {  roles: "tishGroups",  title: 'קבוצות באירוע' } },
    { canActivate: [AuthGuard], path: 'tishGroups/add', component: TishGroupMain, data: { mode: 'edit', roles: "tishGroups", title: 'הוסף קבוצה' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'tishGroups/:tishGroupID', component: TishGroupMain, data: {  roles: "tishGroups",  title: 'פרטי קבוצה' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'tishGroups/:tishGroupID/edit', component: TishGroupMain, data: { mode: 'edit', roles: "tishGroups",  title: 'עריכת פרטי קבוצה' }, outlet: 'popup' }
]; 

