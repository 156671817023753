import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from './auth.service';
import { Directive, TemplateRef, ViewContainerRef, OnChanges, Input, Injectable } from '@angular/core';

@Directive({
  selector: '[authGuardDirective]'
})

@Injectable({ providedIn: 'root' })
export class AuthGuardDirective implements OnChanges {
  @Input() authGuardDirective: string; // input value should be as the selector

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public authenticationService: AuthenticationService
  ) { }

  ngOnChanges() {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      const rls = this.authGuardDirective.split(',').map((x) => x.toLowerCase());
      // check if route is restricted by role  - כאשר יש לניתוב תפקידים
      if (rls) {

        if (currentUser.roles.indexOf("SystemAdministrator") > -1) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          return;
        }

        const uRls = currentUser.roles.map((x) => x.toLowerCase());

        for (let i = 0; i < rls.length; i++) {
          if (uRls.indexOf(rls[i]) >= 0) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
          }
        }

        this.viewContainer.clear();
        return;
      }
      // כאשר אין לניתוב תפקידים מאופשר לכולם
      // authorised so return true
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
  }

}
