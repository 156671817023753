import { FormGroup } from '@angular/forms';
import { CommitmentEdit } from './commitment-edit.cmp';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Commitment } from './commitment';


@Component({
    selector: 'commitment-edit-dlg',
    templateUrl: './commitment-edit-dlg.tmpl.html'

})

export class CommitmentEditDlg implements OnInit, AfterViewInit {

    constructor(
        public dialogRef: MatDialogRef<CommitmentEditDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {

    }

    commitmentForm: FormGroup;
    public id: number;
    public commitment: Commitment;
    public contactId: number;

    @ViewChild('commitmentEdit') commitmentEdit: CommitmentEdit;

    ngAfterViewInit(): void {
        //  console.log( "commitmentEdit", this.commitmentEdit);
    }

    ngOnInit(): void {
        if (this.data) {
            this.commitment = this.data.commitment;
            this.contactId = this.data.contactId; 
            this.id = this.commitment.commitmentID || 0; 
        }
    }



}



