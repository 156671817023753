import { CommonModule } from '@angular/common';
import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@Pipe({
    name: 'sort'
})

export class SortPipe implements PipeTransform {
   
  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}



@NgModule({
  imports: [CommonModule],
  exports: [SortPipe],
  declarations: [SortPipe]
})
export class SortPipeMdl { }
