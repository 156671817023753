import { NgModule } from '@angular/core';

import { Tabs } from './tabs.cmp';
import { singleTab } from './single-tab.cmp';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    exports: [singleTab, Tabs],
    declarations: [singleTab, Tabs]
})
export class TabsMdl { }
