import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServicesData } from './../services/services.data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef } from '@angular/core';
import { HoldingsData } from './holdings.data';
import { HoldingsPrintDetailsMdl } from './holding/holding';


@Component({
  templateUrl: './holdings-exls-dlg.tmpl.html'
})
export class HoldingsExlsDlg implements OnInit, AfterViewInit {
  public formData: FormGroup;

  shtibelMode: boolean = false;
  contactMode: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private holdingsData: HoldingsData,
    public servicesData: ServicesData,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngAfterViewInit(): void {
    if (this.data && this.data.shtibelID && this.data.shtibelID) {

      this.formData.controls['contactID'].setValue(this.data.contactID);
      this.formData.controls['contactName'].setValue(this.data.contactName);


      if (this.data.contactID.value == null) {

        this.formData.controls['shtibelID'].setValue(this.data.shtibelID);
        this.formData.controls['shtibelName'].setValue(this.data.shtibelName);
        this.formData.controls['cityID'].setValue(this.data.cityID);
        this.formData.controls['cityName'].setValue(this.data.cityName);

        this.shtibelMode = true;
        this.contactMode = false;

      } else {

        this.formData.controls['shtibelID'].setValue(null);
        this.formData.controls['shtibelName'].setValue(null);
        this.formData.controls['cityID'].setValue(null);
        this.formData.controls['cityName'].setValue(null);


        this.shtibelMode = false;
        this.contactMode = true;

      }

    }

    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    if(this.data && this.data.contactID > 0){
      this.contactMode = true;
    }
    this.initForm();
  }


  private initForm(): void {
    this.formData = this.formBuilder.group({
      contactID: [this.data?.contactID], //, Validators.required
      contactName: [this.data?.contactName], //, Validators.required
      shtibelID: [], //, Validators.required
      shtibelName: [], //, Validators.required
      cityID: [], //, Validators.required
      cityName: [], //, Validators.required
      dateMonthsRange: [null, Validators.required],
      isPrint: [false, Validators.required],
      friendsInStiebel: [null],
      //   onlySucceeded: [null],
      statusPayments: [null],
    });
  }

  public isValid(): boolean {
    return (this.formData && this.formData.valid);
  }





  public getPivotHoldingsExls(holdingsPrintHtmlDlg: any): void {

    if (holdingsPrintHtmlDlg) {
      this.holdingsData.getPivotHoldingsExls(<HoldingsPrintDetailsMdl>holdingsPrintHtmlDlg)
        .subscribe(blob => {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `דוח החזקת לפי חודשים.xlsx`;
          link.click();
        });
    }
  }



  getPivotHoldingsHtml(holdingsPrintHtmlDlg: any) {
    if (holdingsPrintHtmlDlg) {
      this.holdingsData.getPivotHoldingsHtml(<HoldingsPrintDetailsMdl>holdingsPrintHtmlDlg)
        .subscribe(data => {
          let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write((<any>data).value);
          //  popupWinindow.document.close();
          return true;
        });
    }
  }



  // public getPivotHoldingsHtml(holdingsPrintHtmlDlg: any): void {

  //   this.holdingsData.getPivotHoldingsHtml(this.formData.value)
  //     .subscribe(blob => {
  //       let link = document.createElement('a');
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = `דוח החזקת לפי חודשים.pdf`;
  //       link.click();
  //     });
  // }





}
