import { EmergencySquadQueueMain } from './emergency-squad-queue/emergency-squad-queue-main.cmp';
import { AuthGuard } from '../../_guards/auth.guard';
import { Routes } from '@angular/router';
import { EmergencySquadQueuesList } from './emergency-squad-queues-list.cmp';

export const EmergencySquadQueuesRoutes: Routes = [
  { canActivate: [AuthGuard], path: 'emergencySquadQueues', component: EmergencySquadQueuesList, data: { roles: "emergencySquadQueues", title: 'רשימת תורים' } },
  { canActivate: [AuthGuard], path: 'emergencySquadQueues/add', component: EmergencySquadQueueMain, data: { mode: 'edit', roles: "emergencySquadQueues", title: 'הוסף תור' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'emergencySquadQueues/:emergencySquadQueueID', component: EmergencySquadQueueMain, data: { roles: "emergencySquadQueues", title: 'פרטי תור' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'emergencySquadQueues/:emergencySquadQueueID/edit', component: EmergencySquadQueueMain, data: { mode: 'edit', roles: "emergencySquadQueues", title: 'עריכת פרטי תור' }, outlet: 'popup' }
];
