import { PaymentCommitment } from './../payments/payment/payment';
import { PayMethod } from '../payMethods/payMethod/payMethod';
import { Payment } from '../payments/payment/payment';

export class CcChargeModel {
    payMethodID: number;
    payMethodTitle: string;
    contactID: number;
    paymentID: number;
    amount;
    currency;
    termID;
    termName;
    payMethod: PayMethod;
    paymentOption:number;
    payment: Payment;
    authorizationNumber: string;
    currencyIcon: any;
    contactName: any;
    paymentCommitments:any[]
}
