import { Component, OnInit, Input, AfterViewInit, ElementRef, QueryList, ViewChildren, Output, EventEmitter } from '@angular/core';
import { TishInvited } from './tishInvited';

declare var jQuery: any;

@Component({    
    selector: 'tishInvited-row-map',
    templateUrl: './tishInvited-row-map.tmpl.html'
})

export class TishInvitedRowMap implements OnInit, AfterViewInit {
    constructor( 
    ) {
    }

    @Input('tishInvited') public tishInvited: TishInvited;
    
    @Output() dragInvited = new EventEmitter<boolean>(); 
    @Output() dropedElement = new EventEmitter<boolean>(); 


    @ViewChildren('invitedElmDrag') invitedElmDrags: QueryList<ElementRef>;

    private id: number;



    ngOnInit(): void {
    }




    ngAfterViewInit(): void {
        let drgInvtd = this.dragInvited; 
        this.invitedElmDrags.forEach(element => {
            let invitedElm = jQuery(element.nativeElement);


            // invitedElm.addClass("ui-widget ui-helper-clearfix ui-corner-all") //ui-widget-content 
            //     .find(".portlet-header")
            //     .addClass("ui-widget-header ui-corner-all")
            //     .prepend("<span class='ui-icon ui-icon-minusthick portlet-toggle'></span>");


            invitedElm.draggable({

                
                snap: ".ui-widget-header",
                // stack: ".products",
                containment: ".map-wrapper",
                scroll: false,
                revert: "invalid",
                opacity: .4,
                // create: function () { $(this).data('position', $(this).position()) },
                // cursorAt: { left: 15 },
                // cursor: 'move',


                start: function (e, ui) {
                    drgInvtd.emit(true);
                    $(this).stop(true, true);
                    let jsnTishPlace = JSON.stringify(null);
                    let jsnSourceTable = JSON.stringify(null);
                    let jsnInvited = JSON.stringify(this.tishInvited);


                    let srcObj: any = {
                        "sourcePlace": jsnTishPlace,
                        "sourceTable": jsnSourceTable,
                        "invited": jsnInvited
                    };
                    $(this).data('srcObj', srcObj);
                }
            });
        });



    }
}
