import { ToraxHttp } from '../../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from "rxjs";
import { EmergencySquadQueue } from './emergency-squad-queue/emergency-squad-queue';

@Injectable()

export class EmergencySquadQueuesData {
  private url = 'api/emergencySquadQueues';


  constructor(
    private toraxHttp: ToraxHttp
  ) { }

  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }


  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  update(emergencySquadQueue: EmergencySquadQueue) {
    return this.toraxHttp.update(this.url, emergencySquadQueue);
  }

  getAdd(emergencySquadMemberId: number) {
    return this.toraxHttp.get(`${this.url}/add/${emergencySquadMemberId || 0}`);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}
