
import { I } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { Group } from 'src/app/groups/group/group';
import { DashboardGroupsSpecialDataService } from '../dashboard-groups-special.data';


@Component({
  selector: 'group-special',
  templateUrl: 'group-special.tmpl.html',
  styleUrls: ['./../dashboard-groups-special.scss']
})


export class GroupSpecialComponent implements OnInit {


  constructor(
    private dashboardGroupsSpecialDataService: DashboardGroupsSpecialDataService
  ) {
  }


  @Input('group') public group: Group;
  data: any;

  load() {
    if (this.group) {
      this.dashboardGroupsSpecialDataService.get(this.group.groupID).subscribe(x => {
        this.data = x
      });
    }
  }

  ngOnInit() {
    this.load();
  }


}
