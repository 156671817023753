import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; 
import { Component, Inject, AfterViewInit } from '@angular/core';
import { CommitmentsData } from './commitments.data';

@Component({
	selector: 'commitments-list-dashboard-dlg',
	templateUrl: './commitments-list-dashboard-dlg.tmpl.html',
	styleUrls:['./commitments.scss']
})

export class CommitmentsListDashboardDlg implements AfterViewInit {
	constructor(
		public dialogRef: MatDialogRef<CommitmentsListDashboardDlg>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private commitmentsData: CommitmentsData,
	) {
	}
	contact; 
	contactID; 
	subject;
	subjectName;
	commitmentsList;
	isLoadingResults: boolean = false;

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.data) {
				this.contact = this.data.contact;
				this.contactID = this.data.contactID;
				this.subject = this.data.subject;
				this.subjectName = this.data.subjectName;

				this.isLoadingResults = true;
				this.commitmentsData.contactSubjectCommitments(this.contactID, this.subject)
					.subscribe((x: any) => {
						this.commitmentsList = x;
						this.isLoadingResults = false;
					}),
					() => {
						this.isLoadingResults = false;
					};
			}
		}, 150);
	}
}
