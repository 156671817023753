import { Component} from '@angular/core';

@Component({
    selector: 'paymentsPlan-main',
    templateUrl: './paymentsPlan-main.tmpl.html'
})

export class PaymentsPlanMain {
   editMode: boolean = null;

    setVal(val:boolean) {
        this.editMode = val;
    }

    changeMode(e){
      this.setVal(e);
    }
}
