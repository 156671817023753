import { ToraxHttp } from './../utils/custom-http.svc';
import { ObjectsListResult } from './../services/objects-list-results';
import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from "rxjs";
import { Yarzeit } from './yarzeit/yarzeit';

@Injectable()

export class YarzeitsData {
  private url = 'api/yarzeits';


  constructor(
    private toraxHttp: ToraxHttp
  ) { }

  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }
  getYarzeitAffiliationsList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/yarzeitAffiliationsList`, baseQuery);
  }
  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }

  createAndPrintYarzeitsLetters(yarzeitIDs) {
    return this.toraxHttp.get(`${this.url}/createAndPrintYarzeitsLetters/${yarzeitIDs}`);
  }

  createAndPrintYarzeitLetter(yarzeitID: number) {
    return this.toraxHttp.get(`${this.url}/createAndPrintYarzeitLetter?yarzeitID=${yarzeitID}`);
  }

  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  update(yarzeit: Yarzeit) {
    return this.toraxHttp.update(this.url, yarzeit);
  }

  getAdd() {
    return this.toraxHttp.get(`${this.url}/add`);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}
