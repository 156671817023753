<div [formGroup]="searchHonorsListForm">
  <div *ngIf="!contactID || contactID == 0" class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">
      <div class="input-group mb-3">
        <label>סוג...</label>
        <mat-select apply-enter class="form-control" (selectionChange)="indexService.selectionChanged($event)"
          placeholder="סוג..." [formControl]="searchHonorsListForm.controls.honorTypeID">
          <mat-option *ngFor="let ht of servicesData.HonorTypes" [value]="ht.id">
            {{ht.title}}
          </mat-option>
        </mat-select>
      </div>
      &nbsp;&nbsp;
      <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש...'"
        (onSelect)="indexService.selectionChanged($event)" [formGroupModel]="searchHonorsListForm"
        [idControl]="searchHonorsListForm.controls.contactID" [nameControl]="searchHonorsListForm.controls.contactName">
      </auto-complete>
      &nbsp;&nbsp;
      <auto-complete apply-enter [controllerName]="'HonorsSets'" [placeholder]="'סידרה...'"
        (onSelect)="indexService.selectionChanged($event)" [formGroupModel]="searchHonorsListForm"
        [idControl]="searchHonorsListForm.controls.honorsSetID"
        [nameControl]="searchHonorsListForm.controls.honorsSetTitle"></auto-complete>
      &nbsp;&nbsp;
      <span fxFlex class="example-fill-remaining-space"></span>
      <div *ngIf="!contactID" fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
        <button class="btn btn-outline btn-success  dim" type="button"
        style="width: min-content; font-weight: 500; color:#ff4081" *ngIf="selection && selection.selected.length >= 1"
        (click)="createAndPrintHonorsLetters()">
        הדפס מכתבים ל-{{selection.selected.length}} רשומות נבחרות
      </button>
      &nbsp;  <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
            class="fa fa-filter"></i></button>
        &nbsp;
        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
            class="material-icons">clear</i></button>
      </div>
    </div>
  </div>
  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="select">
            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
              <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox apply-enter (click)="$event.stopPropagation()" (change)="$event ? rowToggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'honors', row.honorID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['honors', row.honorID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="honorsData" [id]="row.honorID"
                    [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="honorID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
              כיבוד </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה כיבוד">
              <a [routerLink]="[ '', {outlets: {popup: [ 'honors', row.honorID]}}]">
                {{ row.honorID }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="contactName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>שם איש</mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש מקבל">
              <a [routerLink]="[ '/contacts', row.contactID]">{{ row.contactName }}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="receivingContactName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>שם מקבל</mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם מקבל">
              <a [routerLink]="[ '/contacts', row.receivingContactID]">{{ row.receivingContactName }}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="honorsSetTitle">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שבת/אירוע </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שבת/אירוע">
              <a [routerLink]="[ '', {outlets: {popup: [ 'honorsSets', row.honorsSetID]}}]">{{ row.honorsSetTitle }}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="honorTypeDescription">
            <mat-header-cell *matHeaderCellDef mat-sort-header> כיבוד
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="כיבוד">
              {{row.honorTypeDescription}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="honorDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך">
              {{ row.honorJewishDate }} <br> {{ row.honorDate | date:'dd/MM/yyyy' }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="contributionAmount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מחיר </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מחיר"> {{ row.contributionAmount | number}}{{
              row.currencyIcon }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="reasonComment">
            <mat-header-cell *matHeaderCellDef mat-sort-header>סיבה/הערה
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סיבה/הערה"> {{ row.reasonComment }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="commitmentID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> התחייבות
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="התחייבות">
              <a *ngIf="row.commitmentID" [routerLink]="[ '', {outlets: {popup: [ 'commitments', row.commitmentID]}}]">
                {{ row.commitmentID }}</a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="doCreateLetter">
            <mat-header-cell *matHeaderCellDef mat-sort-header>מכתב
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מכתב">
              <i style="padding: 4px" matTooltip="שליחת מכתב" *ngIf="row.doCreateLetter" fxLayout="column"
                class="fa fa-envelope-o"></i>
              {{ row.contactLetterID }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.honorID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
