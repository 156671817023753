﻿<div *ngIf="registration">
  <div class="modal-header">
    <h2>פרטי רישום [{{ (id > 0 ? id : 'חדש') }}] </h2>
  </div>
  <div class="modal-body">
    <dl class="dl-horizontal" style="white-space: nowrap;">
      <dt>שם</dt>
      <dd>
        <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', registration.contactID]}}]">{{
          registration.contactName }}</a>
      </dd>
      <hr>
      <dt>שטיבל</dt>
      <dd>{{ registration.shtibelName }}</dd>
      <hr>
      <dt>טלפון רישום</dt>
      <dd>{{ registration.registeringPhone | phone }}</dd>
      <hr>
      <dt>טלפון מתקשר</dt>
      <dd>{{ registration.callerNumber | phone }}</dd>
      <hr>
      <dt>אירוע</dt>
      <dd><b>{{ registration.registrationEventTitle }}
          {{( registration.registrationEventType == 4 ? ' [' + (registration.aronitNumber !=
          null?registration.aronitNumber:' ') + ']' : '') }}
        </b>
      </dd>
      <hr>
      <dt>מס' מקומות</dt>
      <dd>{{ registration.quantity }}</dd>
      <hr>
      <dt>התחייבות</dt>
      <dd>
        <a *ngIf="registration.commitmentID"
          [routerLink]="[ '', {outlets: {popup: [ 'commitments', registration.commitmentID]}}]">
          {{ registration.commitmentID }}</a>
      </dd>
      <hr>
      <dt>אמצעי תשלום</dt>
      <dd>
        <div *ngIf="registration.paymentPayMethodID">
          <a *ngIf="isLink(registration.paymentPayMethodID)"
            [routerLink]="[ '', {outlets: {popup: [ 'payMethods', registration.paymentPayMethodID]}}]">
            {{ registration.paymentPayMethodTitle }}</a>
          <span style="white-space: normal;" *ngIf="!isLink(registration.paymentPayMethodID)">
            {{ registration.paymentPayMethodTitle }}</span>
        </div>
        <div *ngIf="!registration.paymentPayMethodID && registration.commitmentPayMethodID">
          בעת הרישום: <a *ngIf="isLink(registration.commitmentPayMethodID)"
            [routerLink]="[ '', {outlets: {popup: [ 'payMethods', registration.commitmentPayMethodID]}}]">
            {{ registration.commitmentPayMethodTitle }}</a>
          <span style="white-space: normal;" *ngIf="!isLink(registration.commitmentPayMethodID)">
            {{ registration.commitmentPayMethodTitle }}</span>
        </div>
      </dd>
      <hr>
      <dt>סכום ששולם</dt>
      <dd>{{ registration.amountPaid | number }}</dd>
      <hr>
    </dl>
    <!-- </mat-card-content>
            </mat-card> -->
  </div>
</div>
