import { ToraxHttp } from './../../utils/custom-http.svc';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardDirectorReportsDataService {
    private url = 'api/dashboardDirectorReports';
    constructor(
        private toraxHttp: ToraxHttp

    ) { }

    getDirectorReports(subject: number) {
      return this.toraxHttp.get(`${this.url}/directorReports/${subject}`);
  }

}
