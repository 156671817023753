import { AutoCompleteInputDataService } from './auto-complete-input.data';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { map, debounceTime, distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Component,  AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: "auto-complete-input",
    templateUrl: "auto-complete-input.tmpl.html",
    styleUrls: ["./auto-complete-input.scss"],
})
export class AutoCompleteInputComponent implements AfterViewInit {

    constructor(
        private data: AutoCompleteInputDataService
    ) {
    }


    noResults: boolean;
    loading: boolean;
    sourceList: string;
    filterValues: Observable<string>;
    public searchResults: string[] = [];


    @ViewChild('txtSearch', { static: true }) txtSearch: ElementRef;
    @ViewChild("matAutocomplete") matAutocomplete: MatAutocomplete;


    @Input('withLabel') public withLabel: boolean = true;

    @Input("appearance") public appearance: string = "outline";
    @Input("leftToRight") public leftToRight: boolean = false;
    @Input("formGroupModel") public formGroupModel: FormGroup;
    @Input('stringValueControl') public stringValueControl: FormControl;
    @Input('controllerName') public controllerName: string;
    @Input('placeholder') placeholder?: string = '...';

    @Output() onSelect = new EventEmitter<string>();

    public itemSelect(item) {
        // if (e) {
        //     e.preventDefault();
        // }
        if (item) {
            this.activeItem = item;
            this.stringValueControl.setValue(this.activeItem);

            this.onSelect.emit(this.activeItem);
        }
        return false;
    }

    isAutofilled;
    autofilled(e) {
        //     console.log("autofilled",e);
    }
    public activeItem: string;
    public isActive(value): boolean {
        return (value === this.activeItem);
    }

    public setActive(event, item): void {
        this.itemSelect(item);
    }

    ngAfterViewInit() {
        this.sourceList = this.controllerName;

        const typeahead = this.stringValueControl.valueChanges.pipe(
            map((e: any) => e),
            filter(text => {
                return text && text.length >= 1;
            }),
            debounceTime(400),
            distinctUntilChanged(),
            switchMap((x) =>
                this.data.search(this.sourceList, (<any>this.txtSearch.nativeElement).value))
        );

        typeahead.subscribe(data => {
            this.searchResults = <string[]>data;
        });
    }

}

