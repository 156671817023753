
<main-form-modal (toggleChange)="setVal($event)">
    <ng-template #customHtmlElements>
        <div [ngSwitch]="editMode">

            <emergency-squad-member-edit *ngSwitchCase="true"></emergency-squad-member-edit>
            <emergency-squad-member-details *ngSwitchCase="false"></emergency-squad-member-details>
        </div>
    </ng-template>
</main-form-modal>
