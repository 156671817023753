import { Component, Input, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';

@Component({
    
    selector: 'panel-box',
    templateUrl: 'panel-box.tmpl.html',
    // styles: ['panel-box.css'],
    encapsulation: ViewEncapsulation.None
})
export class PanelBoxComponent implements OnInit {
    constructor(private el: ElementRef) { }

    @Input() title: string;
    @Input() showClose: boolean = true;
    @Input() showCollapse: boolean = true;
    @Input() showTitle: boolean = true;
    @Input() modelInfo: any = null;


    ngOnInit() {

    }

    collapse() {
        let el = (<any>event).target
        var button = el;
        let ibox = el.closest('div.ibox')
        var content = $(ibox).find('div.ibox-content');
        content.slideToggle(200);
        $(button).toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        $(ibox).toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            $(ibox).resize();
            $(ibox).find('[id^=map-]').resize();
        }, 50);
    }

    close() {
        var el = (<any>event).target
        this.el.nativeElement.remove();
    }
}
