import { LocationStrategy } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services';
import { LoginViewModel } from '../_models';

@Component({
  selector: 'login-page',
  templateUrl: './login.component.html'
})

export class LoginComponent implements AfterViewInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;

  isMobile: boolean;
  // userName;
  // password;
  returnUrl: string;
  errorLoginMsg = '';

  loginModel = new LoginViewModel('', '', '');

  // @ViewChild('UserName') UserName: HTMLInputElement;
  // @ViewChild('Password') Password: HTMLInputElement;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private _platform: Platform,
    private location: LocationStrategy,

  ) {

    this.isMobile = false;
    this.router.events.subscribe(() => {
    });
    if (this.authenticationService.currentUserValue) {
      // this.router.navigate(['/']);
    }
  }

  ngAfterViewInit(): void {
    this.wait(2000);
    if (this._platform.ANDROID || this._platform.IOS) {
      this.isMobile = true;
      this.androidLoginTitle = "מעביר...";
      let un;
      let ps;
      for (let index = 0; index < 3 && !un && !ps; index++) {
       // console.log(`before ix:[${index}]`);
        this.wait(1000);  //1 seconds in milliseconds
        un = localStorage.getItem('android-un');
        ps = localStorage.getItem('android-ps');
      //  console.log(`after ix:[${index}], un:[${un}], ps:[${ps}]`);
      }
      if (!un && !ps) {
        this.showNormal = true;
        this.androidLoginTitle = '';
      }
      this.loginModel.userName = un;
      this.loginModel.password = ps;
      this.onSubmit();
    }

  }

  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
  showNormal: boolean = false;
  androidLoginTitle = '';


  // tooglePassword() {
  //     let x = (<any>this.Password).nativeElement;
  //     if (x.type === "password") {
  //         x.type = "text";
  //     } else {
  //         x.type = "password";
  //     }
  // }

  // tempReloadPage(){
  //   //  Location.reload()

  // }
  backClickedE() {
    this.location.back();
  }
  // convenience getter for easy access to form fields
  get form() {
    return this.loginForm;
  }

  onSubmit() {
    this.loginModel.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.loginForm = this.formBuilder.group({
      userName: [this.loginModel.userName, Validators.required],
      password: [this.loginModel.password, Validators.required],
      returnUrl: [this.loginModel.returnUrl], //this.returnUrl
      windowHeight: [],
      windowWidth: []
    });

    // alert(`${formData.value.userName},${formData.value.password}`);
    this.loading = true;
    //  console.log("f", f);
    //console.log(this.loginModel.userName, this.loginModel.password);

    this.form.controls['userName'].setValue(this.loginModel.userName);
    this.form.controls['password'].setValue(this.loginModel.password);

    //לצורך בדיקת המסכים
    this.form.controls['windowHeight'].setValue(window.innerHeight);
    this.form.controls['windowWidth'].setValue(window.innerWidth);

    //this.form.controls['password'].setValue(this.loginModel.returnUrl);

    this.errorLoginMsg = '';
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      this.errorLoginMsg = "כניסה נכשלה!";
      this.loading = false;
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.form.value)
      .subscribe(
        () => {
          // let url = this.loginModel.returnUrl;
          // let rx = new RegExp("\(popup:[^)(]+\)");
          // let baseUrl = url.replace(rx, "");
          // let popupUrl = "";
          // if (url.match(rx)) {
          //     popupUrl = url.match(rx)[0];
          //     popupUrl = popupUrl.replace("popup:", "");
          //     let popupUrlList = popupUrl.split("/");
          //     console.log(popupUrlList);
          //     baseUrl = baseUrl.replace("(", "").replace(")", "");
          //     this.router.navigateByUrl(this.loginModel.returnUrl);
          //    // this.router.navigate([baseUrl, { outlets: { popup: popupUrlList } }]);

          // } else {
          //     this.router.navigate(url.split("/"));
          // }
          this.router.navigateByUrl(this.loginModel.returnUrl);
          this.loading = false;
          localStorage.removeItem('android-un');
          localStorage.removeItem('android-ps');
          this.androidLoginTitle = '';
          this.showNormal = true;
        }, error => {
          this.errorLoginMsg = "כניסה נכשלה!";
          this.loading = false;
        })
  }
}
