<div matDialogTitle class="modal-header">
    <div *ngIf="payMethodEdit &&  payMethodEdit.payMethod">
        <h1>
            <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
                class="btn  btn-circle.btn-lg">
                <i class="fa fa-times"></i>
            </button>
            <small>עריכת פרטי אמצעי תשלום [{{ (payMethodEdit.id > 0 ? payMethodEdit.id : 'חדש') }}]
            </small>
            <br>
            {{ payMethodEdit.payMethod.contactName }}
        </h1>
    </div>
</div>
<mat-dialog-content>
    <payMethod-edit #payMethodEdit [fromRecordType]="data.payMethod.fromRecordType" [contactID]="data.contactId" [payMethodID]="data.id"
        [model]="data.payMethod" [payOption]="data.payMethod?.paymentOption"></payMethod-edit>
</mat-dialog-content>
<mat-dialog-actions class="modal-footer" align="end">
    <button mat-button  [disabled]="!payMethodEdit.payMethodForm.valid" [mat-dialog-close]="payMethodEdit.payMethodForm" class="btn btn-primary navbar-btn"
        type="button"><i class="fa fa-save"></i> שמור </button>
    <button (click)="closeAndCancelDlg()" class="btn btn-warning navbar-btn"><i class="fa fa-warning"></i> ביטול
    </button>
</mat-dialog-actions>
