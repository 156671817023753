import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { DashboardMarbesDataService } from './dashboard-marbe.data';
import { AfterViewInit, Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import * as d3Scale from 'd3-scale';
import { MatDialog } from '@angular/material/dialog';
import { ColumnsChartModel } from './dashboard-marbe-chart-model';
import { DashboardMarbeShtibelsComponent } from './dashboard-marbe-shtibels.component';

declare const Hebcal: any;

@Component({
  selector: 'dashboard-marbe-cities',
  templateUrl: 'dashboard-marbe-cities.component.html',
  styleUrls: ['dashboard-marbe-cities.scss'], //'dashboard-marbe.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DashboardMarbeCitiesComponent implements OnInit {
  constructor(

    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private dashboardMarbesDataService: DashboardMarbesDataService,
  ) { }

  ngOnInit() {
    this.loadCitiesChart();
  }
  citiesModel: ColumnsChartModel;
  shtibelsModel: ColumnsChartModel;

  styleObject(item) {
    let color = "";
    if (item.value > 70) {
      color = "rgb(73, 158, 45)";
    } else if (item.value > 50) {
      color = "rgb(139 189 122)";
    } else {
      color = "#f0e0c3";
    }
    let ret = `-webkit-linear-gradient(bottom, ${color} ${item.value}%  ${item.value
      }%, #fff ${item.value}%)`;
    return ret;
  }


  loadShtibls(cityID) {
    this.dashboardMarbesDataService.getShtibelsChartModel(cityID)
    .subscribe((x: ColumnsChartModel) => {
      this.shtibelsModel = x;
    });

  }





  public loadCitiesChart() {
    this.dashboardMarbesDataService.getCitiesChartModel()
      .subscribe((x: ColumnsChartModel) => {
        this.citiesModel = x;
      });
  }

}
