import { Address } from './../../contacts/contact/contact';
import { FormGroup } from '@angular/forms';
import { AlertService } from './../../components/alert/alert.svc';
import { FormBuilder } from '@angular/forms';
import { AddressEditDlg } from './address-edit-dlg.cmp';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AddressesData } from './../addresses.data';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';


@Component({
    selector: 'address-add-action',
    templateUrl: './address-add-action.tmpl.html'
})

export class AddressAddAction {

    constructor(
        private addressesData: AddressesData,
        private formBuilder: FormBuilder,
        private alertSvc: AlertService,
        public dialog: MatDialog
    ) {

    }
    @Input('contactID') public contactID: number;
    @Input('address') public address: Address;
    @Output() addressChange = new EventEmitter<any>();

    // onEditClick(obj: Address) {
    //     this.openAddOrEditAddress(obj);
    // }

    public openAddOrEditAddress(): void {
        setTimeout(() => {
            if (!this.address) {
                this.address = new Address();
                this.address.contactID = this.contactID;
            }

            const dialogRef = this.dialog.open(AddressEditDlg, {
                data: { contactId: this.address.contactID | this.contactID, id: this.address.addressID, address: this.address }
            });


            dialogRef.afterClosed().subscribe(result => {
                if (result && result.valid) {
                    this.saveAddress(result);
                }
            });
        }, 200);
    }


    private saveAddress(addressForm: FormGroup) {
        this.addressesData.update(addressForm.value)
            .subscribe((adr: any) => {
              this.addressChange.emit(adr);
                return true;
            })
    }

}



