import { ContactEventsData } from './contactEvents.data';
import { AlertService } from './../../components/alert/alert.svc';
import { ContactEventEditDlg } from './contactEvent/contactEvent-edit-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { ContactEvent } from './contactEvent/contactEvent';

@Component({
	selector: 'contactEvents-list',
	templateUrl: './contactEvents-list.tmpl.html'
})
export class ContactEventsList implements OnInit {


	constructor(
		private contactEventsData: ContactEventsData,
		private formBuilder: FormBuilder,
		private alertSvc: AlertService,
		public dialog: MatDialog
	) {
	}

	contactEvents: any[];
  yarzeits:any[];
	searchContactEventsListForm: FormGroup;
	tempNewEmail;
	tempNewContactEvent;



	viwHistory = false;
	@Input('contactID') public contactID: number;
	//@Input('contactEvents') public contactEvents: ContactEvent[];


	contactEventChangeEvent() {
		this.getContactEvents();
	}

	onEditClick(obj: ContactEvent) {
		this.openAddOrEditContactEvent(obj);
	}

	public getYarzeits(): void {
		this.searchContactEventsListForm.controls['contactID'].setValue(this.contactID);
		this.contactEventsData!.getYarzeits(this.contactID)
			.subscribe((x: any[]) => {
				this.yarzeits = x;
			}
			);
	}

	public getContactEvents(): void {
		this.searchContactEventsListForm.controls['contactID'].setValue(this.contactID);
		this.contactEventsData!.getContactEvents(this.contactID)
			.subscribe((x: any[]) => {
				this.contactEvents = x;
			}
			);
	}

	openAddOrEditContactEvent(contactEvent?: ContactEvent): void {
		setTimeout(() => {
			if (!contactEvent) {
			contactEvent = new ContactEvent();
			contactEvent.contactID = this.contactID;
		}
		const dialogRef = this.dialog.open(ContactEventEditDlg, {
			data: { contactId: contactEvent.contactID, id: contactEvent.contactEventID, contactEvent: contactEvent },
			// width: '90%'

		});


		dialogRef.afterClosed().subscribe(result => {
			if (result && result.valid) {
				this.saveContactEvent(result);
			}
		});
	},200);
	}

	private saveContactEvent(contactEventForm: FormGroup) {
		this.contactEventsData.update(contactEventForm.value)
			.subscribe((pm: any) => {
				this.getContactEvents();
				return true;
			})
	}


	private initForm() {
		this.searchContactEventsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			contactID: [this.contactID, Validators.required],
			selectedRows:[],
			orders: this.formBuilder.array([]),
		});

	}

	ngOnInit(): void {
		this.initForm();
		this.getContactEvents();
		this.getYarzeits();
	}


  private openAddOrEditYarzeit(yarzeitID: number = 0): void {
    // const dialogRef = this.dialog.open(ContactEventEditDlg, {
    //     data: { contactId: this.yarzeit.contactID, id: this.yarzeit.yarzeitID, yarzeit: this.yarzeit }
    // });


    // dialogRef.afterClosed().subscribe(result => {
    //     if (result && result.valid) {
    //         this.saveYarzeit(result);
    //     }
    // });
}



}
