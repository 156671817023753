import { CommonModule } from '@angular/common';
import { ModalService } from './modal.svc';
import { ModalContainerComponent } from './modal-container.cmp';
import { NgModule } from '@angular/core';

import { RouterModal } from './router-modal.dir';

@NgModule({
	imports: [CommonModule],
	exports: [RouterModal, ModalContainerComponent],
	declarations: [RouterModal, ModalContainerComponent],
	providers: [ModalService]
})

export class ModalModule { }
 