﻿<div *ngIf="emergencySquadQueue">
  <div class="modal-header">
    <div class="row">
      <div class="col-sm-4">
        <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', emergencySquadQueue.emergencySquadMemberID]}}]">
          <h2>{{ emergencySquadQueue.emergencySquadMemberName }}</h2>
        </a>
      </div>

      <div class="col-sm-8 text-left">
        <!-- <a class="btn btn-primary" (click)="createAndPrintEmergencySquadQueueLetter()"><i class="fa fa-print"></i>&nbsp;{{
          (emergencySquadQueue.contactLetterID > 0 ? 'הדפס מכתב' : 'צור מכתב') }} </a> -->
      </div>

    </div>
  </div>
  <div class="modal-body" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <div fxLayout="row" style="overflow: auto;" fxLayout.xs="row wrap">
      <mat-card *ngIf="emergencySquadQueue" fxLayout="column" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3 style="font-weight: bold; font-size: 120%; text-decoration: underline;"> {{ 11111111111 }} </h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%;">
          <dl class="dl-horizontal" style="white-space: nowrap;">


          </dl>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
