<div class="form-row" style="display: inline-flex;">
    <div fxLayout="column" class="input-group mb-3">
        <label>{{placeholderAmount}}:</label>
        <input apply-enter  [first-focus]="firstFocus"  class="form-control" [formControl]="amountControl">
    </div>&nbsp;
    <div fxLayout="column" class="input-group mb-3">
        <label>{{placeholderCurrency}}:</label>
        <mat-select class="form-control narrow" [formControl]="currencyObjontrol">
            <mat-option [value]="null">...</mat-option>
            <mat-option *ngFor="let c of servicesData.Currencies" [value]="c">
                <b> {{c.name}} </b>
            </mat-option>
        </mat-select>
    </div>&nbsp;
    <div fxLayout="column" style="white-space: nowrap;"
        *ngIf="(idControl && idControl.value > 0) || (currencyObjontrol && currencyObjontrol.value && currencyObjontrol.value.ilsRate != 1)">

        <div class="form-row" style="display: inline-flex;">
            <div   class="input-group mb-3">
                <label fxLayout="row">שער ₪:</label><br>
                <div class="form-row" style="display: inline-flex;border: none; padding: 6px;">
                        <i *ngIf="!isInEditShekelRate" fxLayout="column" style="color: #c8c8c8; padding-top: 3px;"
                        (click)="isInEditShekelRate = !isInEditShekelRate" class="fa fa-pencil"></i>
                    <i *ngIf="isInEditShekelRate" fxLayout="column" style="color: #c8c8c8; padding-top: 3px;"
                        (click)="isInEditShekelRate = !isInEditShekelRate" class="fa fa-check"></i>
                    &nbsp;
                    <input *ngIf="isInEditShekelRate" fxLayout="column" class="form-control narrow"
                        [formControl]="shekelRateControl">
                    <span fxLayout="column" style="text-decoration: underline;"
                        *ngIf="!isInEditShekelRate">{{shekelRateControl.value | number}}</span>
                </div>
            </div>
            &nbsp;&nbsp;
            <span class="example-fill-remaining-space"></span>
            &nbsp;&nbsp;
            <div  class="input-group mb-3">
                <label fxLayout="row">ערך שקלי:</label><br>
                <div class="form-row" style="display: inline-flex;border: none; padding: 6px;">
                    <i *ngIf="!isInEditShekelValue" fxLayout="column" style="color: #c8c8c8; padding-top: 3px;"
                        (click)="isInEditShekelValue = !isInEditShekelValue" class="fa fa-pencil"></i>
                    <i *ngIf="isInEditShekelValue" fxLayout="column" style="color: #c8c8c8; padding-top: 3px;"
                        (click)="isInEditShekelValue = !isInEditShekelValue" class="fa fa-check"></i>
                    &nbsp;
                    <input *ngIf="isInEditShekelValue" (change)="calculateShekelRate()" fxLayout="column"
                        class="form-control narrow" [formControl]="shekelValueControl">
                    <span fxLayout="column" style="text-decoration: underline;"
                        *ngIf="!isInEditShekelValue">{{shekelValueControl.value | number}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
