<fieldset  class="fieldset"  *ngIf="paymentForm.controls">
<legend> פרטי העברה
</legend>
<div [formGroup]="paymentForm" style="margin: 0 auto;">
  <table class="table table-border">
    <thead class="text-nowrap">
      <th>תאריך העברה</th>
      <th>מס' בנק</th>
      <th>מס' סניף</th>
      <th>מס' חשבון</th>
      <th>מס' אסמכתא</th>
      <th>חשבון עמותה</th>
    </thead>
    <tbody>
      <ng-container>
        <tr>
          <td>
            <date-input [withJewishDate]="false" [withLabel]="false" [placeholder]="'תאריך העברה:'"
              [dateControl]="paymentForm.controls.doneOn">Loading...</date-input>
          </td>
          <td>
            <input class="form-control narrow" formControlName="bankNumber" apply-enter />
          </td>
          <td>
            <input class="form-control narrow" formControlName="bankBranchNumber" apply-enter />
          </td>
          <td>
            <input class="form-control narrow" formControlName="bankAcountNumber" apply-enter />
          </td>
          <td>
            <input class="form-control narrow" formControlName="transferReference" apply-enter />
          </td>
          <td>
            <input class="form-control narrow" formControlName="associationAccountNumber" apply-enter />
          </td>

        </tr>

      </ng-container>
    </tbody>
  </table>
  <span style="color: blue;">יש להכניס מס' אסמכתא המופיע בחשבון העמותה</span>
</div>
</fieldset>
