import { ToraxHttp } from '../utils/custom-http.svc';
import { Injectable  } from '@angular/core';

@Injectable()

export class PeleCardTransactionsData {
    private url = 'api/peleCardTransactions';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }


    getList(baseQuery: any)  {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }


}
