import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { JewishWeekDaysPickerComponent } from "./jewish-week-days-picker.component";
import { BrowserModule } from "@angular/platform-browser";
import { MaterialModule } from "src/app/material.module";

@NgModule({
    imports: [
      BrowserModule ,
        FormsModule,
        MaterialModule,

    ],
    declarations: [
      JewishWeekDaysPickerComponent,
    ],
    exports: [
      JewishWeekDaysPickerComponent,
    ],


    providers: [
    ]
})
export class JewishWeekDaysPickerModule {

}
