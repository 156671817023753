import { ToraxHttp } from './../../utils/custom-http.svc';
import { TishGroupsData } from './../tishGroups.data';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router'; 
import { TishGroup } from './tishGroup'; 

@Component({
	
	selector: 'tishGroup-details',
	templateUrl: './tishGroup-details.tmpl.html'
})

export class TishGroupDetails implements OnInit {
	constructor(
		private TishGroupsData: TishGroupsData,
		private activatedRoute: ActivatedRoute,
		private toraxHttp: ToraxHttp
	) {
		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.tishGroup && id === this.tishGroup.tishGroupID) {
				this.loadTishGroup();
			}
		});
	}

	public tishGroup: TishGroup;
	private id: number;

	public loadTishGroup(): void {
		this.TishGroupsData.get(this.id)
			.subscribe((tg:any) => this.tishGroup = tg);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = +params['tishGroupID'];
			this.loadTishGroup();
		});
	}
}

