import { Subscription } from 'rxjs';
import { SgnlContent } from './signalr-models';
import { Component, Input, OnInit } from '@angular/core';
import { SignalMainRService } from './signalr-main.service';

@Component({
    selector: 'signalr-main',
    templateUrl: 'signalr-main.component.html', 
    styleUrls: ['signalr-main.component.scss']
})

export class SignalrMainComponent implements OnInit {
    signalRSubscription: Subscription;
    signalContent: SgnlContent;

    constructor(
        private signalrService: SignalMainRService
    ) {
        this.signalRSubscription = this.signalrService.getMessage().subscribe(
            (cntnt) => {
                this.signalContent = cntnt;
              });
    }

    ngOnInit(): void {
         
    }

    
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.signalrService.disconnect();
    this.signalRSubscription.unsubscribe(); 
  }

}
