import { ServicesData } from './../services/services.data';
import { ContactsData } from './../contacts/contacts.data';
import { RegistrationsData } from './registrations.data';

import { Component, OnInit, ViewChild, HostListener, ElementRef, Input, Output, AfterViewInit, EventEmitter, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';


@Component({
    templateUrl: 'registrations-updateCashRegister-dlg.tmpl.html',
})



export class UpdateCashRegisterDlg implements OnInit {
    constructor(
        private registrationsData: RegistrationsData,
        private formBuilder: FormBuilder,
        //    public servicesData: ServicesData
    ) {

    }


    @ViewChild('saveButton') saveButton: ElementRef;


    searchForm: FormGroup;
    updateCashRegisterForm: FormGroup;
    updateCashRegisterModel: any = null;
    topUpdatesInCashRegisters: [];

    onKey(event: any) { // without type info
        if (event.keyCode === 13) {
            this.getTopUpdatesInCashRegister();
            let contactID = +this.searchForm.controls["contactID"].value;
            let registrationEventID = +this.searchForm.controls["registrationEventID"].value;
            this.registrationsData.getRegistrationRow(registrationEventID, contactID)
                .subscribe((cn: any) => {
                    if (cn && cn.amountToPay > 0) {
                        this.updateCashRegisterModel = cn;
                        this.initForm();
                    } else {

                    }
                    this.searchForm.controls["contactID"].setValue(null);
                });
        }

    }

    getTopUpdatesInCashRegister() {
        let registrationEventID = +this.searchForm.controls["registrationEventID"].value;
        this.registrationsData.getTopUpdatesInCashRegister(registrationEventID)
            .subscribe((r: any) => {
                if (r) {
                    this.topUpdatesInCashRegisters = r;
                }
            });
    }



    postUpdateCashRegister() {
        if (this.updateCashRegisterForm.valid) {
            this.saveButton.nativeElement.disabled = true;
            this.registrationsData.postUpdateCashRegister(this.updateCashRegisterForm.value)
                .subscribe(x => {
                    this.updateCashRegisterModel = null;
                    this.updateCashRegisterForm = this.formBuilder.group({});
                    this.searchForm.controls["contactID"].setValue(null);
                    this.saveButton.nativeElement.disabled = false;
                },
                    error => {
                        this.searchForm.controls["contactID"].setValue(null);
                        this.saveButton.nativeElement.disabled = false;
                    });
        } else {
            console.log('not valid');
            console.log(this.updateCashRegisterForm.errors);
        }
    }

    private initSearchForm() {
        this.searchForm = this.formBuilder.group({
            registrationEventID: [, Validators.required],
            registrationEventTitle: [],
            contactID: [, Validators.required],
        }
        );
    }


    private initForm() {
        this.updateCashRegisterForm = this.formBuilder.group({
            contactID: [this.updateCashRegisterModel.contactID, Validators.required],
            commitmentID: [this.updateCashRegisterModel.commitmentID, Validators.required],
            amount: [this.updateCashRegisterModel.amountToPay, Validators.required],
        });
    }

    ngOnInit() {
        this.initSearchForm();
    }

}

