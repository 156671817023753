import { Location } from '@angular/common'; 
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ServicesData } from './../../services/services.data';
import { IvrAssignmentUserTasksData } from './../ivr-assignment-user-tasks.data'; 
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { IvrAssignmentUserTask } from './ivr-assignment-user-task';
import { AlertService } from './../../components/alert/alert.svc';

@Component({
    selector: 'ivr-assignment-user-task-edit-win',
    templateUrl: './ivr-assignment-user-task-edit-win.tmpl.html'
})

export class IvrAssignmentUserTaskEditWin implements OnInit {
    imgUrl: any;
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public servicesData: ServicesData,
        private ivrAssignmentUserTasksData: IvrAssignmentUserTasksData,
        private formBuilder: FormBuilder,
        private alert: AlertService,
        private location: Location
    ) {
    }

    contactEdit: boolean = false;
    //contactId: number;
    id: number;
    ivrAssignmentUserTask: IvrAssignmentUserTask;
    ivrAssignmentUserTaskForm: FormGroup;
    fileName: string;
    imageFile: File;


    public load(): void {
        if (this.id > 0) {
            this.ivrAssignmentUserTasksData.get(this.id)
                .subscribe((x: any) => {
                    this.ivrAssignmentUserTask = x;
                    this.fillForm(x);
                });
        }
        else {
            this.ivrAssignmentUserTask = new IvrAssignmentUserTask();
            this.fillForm(this.ivrAssignmentUserTask);
        }
    }

    private fillForm(x: IvrAssignmentUserTask): void {
        this.ivrAssignmentUserTaskForm.patchValue(x);
        setTimeout(() => {
            this.getAudioByts();
        }, 150);
    }


    private initForm() {
        this.ivrAssignmentUserTaskForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            ivrAssignmentUserTaskID: [this.id | 0, Validators.required],
            ivrAssignmentDetailID: [],
            doneDate: [null],
            doneByUserName: [],
            identifiedContactID: [],
            identifiedContactTitle: [],
            contactID: [],
            contactTitle: [],
            comment: [],
            subject: [, Validators.required],
            userName: [],
            imageType: [],
            imageName: [],
            imageBytes: [],
            imageFile: [],
            priority:[1, Validators.required]
        });
    }

    ngOnInit() {
        this.ivrAssignmentUserTask = new IvrAssignmentUserTask(); //treck
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['ivrAssignmentUserTaskID'] || 0;
          //  this.contactId = +params['contactID'];
        });
        this.initForm();
        this.load();
    }
 



    saveTask() {
        this.ivrAssignmentUserTasksData.update(this.ivrAssignmentUserTaskForm.value).subscribe(
            (tsk: any) => {
                if (tsk && tsk.id) {
                    let ifle = this.ivrAssignmentUserTaskForm.controls.imageFile;
                    if (ifle && ifle.value) {
                        this.fileName =  ifle.value.name;
                        let fd = new FormData();
                        fd.append('imageFile',  ifle.value, this.fileName);
                        this.ivrAssignmentUserTasksData.saveImage(fd, tsk.id, this.fileName).subscribe(
                            (res: any) => {
                                this.alert.success('תמונה נשמרה');
                             },
                            (error) => {
                                console.log(error);
                                this.alert.error('שגיאה בשמירת תמונה');
                            }
                        );
                    }
                    this.location.back();
                           
                }
            }
        )
    }


    public getAudioByts(): void {
        if (this.ivrAssignmentUserTask.ivrAssignmentDetailID > 0) {
            this.ivrAssignmentUserTasksData.getAudioByts(this.id)
                .subscribe(blob => {
                    let elmnt = (<any>document.getElementById("audioFile"));
                    if (elmnt) {
                        elmnt.src = window.URL.createObjectURL(blob);
                    }
                }),
                error => { };
        }
    }
}





