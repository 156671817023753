import { ToraxHttp } from './../../utils/custom-http.svc';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardChartsDataService {
    
    private url = 'api/dashboardCharts';
    constructor(
        private toraxHttp: ToraxHttp

    ) { }
    
    getSiteChartModel(grp: string) {
        return this.toraxHttp.get(`${this.url}/getSiteChartModel/${grp}`);
    }
    
    
    getGrpSums() {
        return this.toraxHttp.get(`${this.url}/getGrpSums`);
    }

    getGlobalInfoControlModel(grp: string) {
        return this.toraxHttp.get(`${this.url}/getGlobalInfoControlModel/${grp}`);
    }

    getExpectPaymentsInDays(accountLocation,commitmentSubject,year,month) {
        return this.toraxHttp.get(`${this.url}/getExpectPaymentsInDays/${accountLocation}/${commitmentSubject}/${year}/${month}`);
    } 

    getLineChartModel(grp: string) {
        return this.toraxHttp.get(`${this.url}/getLineChartModel/${grp}`);
    }
    
    getCitiesChartModel(grp: string, loadAllCities: boolean = false) {
        return this.toraxHttp.get(`${this.url}/getCitiesChartModel/${grp}/${loadAllCities}`);
    }
    
    getShtibelsChartModel(cityID?, cityName: string = "") {
        return this.toraxHttp.get(`${this.url}/getShtibelsChartModel/${cityID}/${cityName}`);
    }
    
    getNewShtibelsChartModel(cityID?) { //cityID?, cityName: string = ""
        return this.toraxHttp.get(`${this.url}/getNewShtibelsChartModel/${cityID}`);///${cityID}/${cityName}
    }
}
