<div style="position: fixed; bottom: 0px; right: 10px; z-index: 9999;">
    <button matTooltip="'פעולות...'"  style="height: 30px;" [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <div *ngIf="signalContent?.model">
        <message-details [model]="signalContent.model"></message-details>
    </div>
    <mat-menu #menu="matMenu">
        <div>
            <order-charge-details [model]="signalContent?.model"></order-charge-details>
        </div>
    </mat-menu>
</div>