import { PayMethodsData } from '../payMethods.data';
import { PayMethodEditDlg } from './payMethod-edit-dlg.cmp';
import { PayMethod } from './payMethod';
import { FormGroup } from '@angular/forms';
import { AlertService } from '../../components/alert/alert.svc';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { AlertCenterService } from 'src/app/components/alert-center/alert-center.svc';


@Component({
  selector: 'payMethod-add-action',
  templateUrl: './payMethod-add-action.tmpl.html',
  styles: [`
		::ng-deep .mat-option-text {
			display: block !IMPORTANT;
		}
		`]
})

export class PayMethodAddAction {

  constructor(
    private payMethodsData: PayMethodsData,
    public dialog: MatDialog,
    public alertCenterService: AlertCenterService
  ) {

  }
  @Input('contactID') public contactID: number;
  @Input('payMethod') public payMethod: PayMethod;

  @Input('fromRecordType') public fromRecordType?: string;
  @Input('fromRecordID') public fromRecordID?: number;

  @Output() payMethodChange = new EventEmitter<any>();
  @Output() payMethodAdd = new EventEmitter<any>();

  // onEditClick(obj: PayMethod) {
  //     this.openAddOrEditPayMethod(obj);
  // }

  public selectCurrent(option: number): void {

    let cnfrm = confirm("בחרת אשראי שאינו מיועד לשימוש חוזר\nהאם אתה בטוח?");
    if (cnfrm == true) {
      this.payMethodChange.emit(this.payMethod);
    }
  }

  public openAddOrEditPayMethod(option: number): void {
    setTimeout(() => {
      if (!this.payMethod) {
        this.payMethod = new PayMethod();
        this.payMethod.paymentOption = option;
        this.payMethod.contactID = this.contactID;
      }
      if(this.fromRecordType){
        this.payMethod.fromRecordType = this.fromRecordType;
      }
      if(this.fromRecordID){
        this.payMethod.fromRecordID = this.fromRecordID;
      }
                  const dialogRef = this.dialog.open(PayMethodEditDlg, {
        data: { contactId: this.payMethod.contactID | this.contactID, id: this.payMethod.payMethodID, payMethod: this.payMethod }
      });



      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.savePayMethod(result);
        }
      });
    }, 200);
  }


  private savePayMethod(payMethodForm: FormGroup) {
    this.payMethodsData.update(payMethodForm.value)
      .subscribe((p: any) => {
        this.payMethodChange.emit(p.item2);
        this.payMethodAdd.emit(p.item2);

        return true;
      })
  }

}



