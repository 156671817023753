import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertService } from './alert.svc';
import { AlertComponent } from './alert.cmp';
import { AlertItemComponent } from './alert-item.cmp';

@NgModule({
    imports: [CommonModule],
    exports: [AlertComponent],
    declarations: [AlertComponent, AlertItemComponent],
    providers: [AlertService],
})
export class AlertModule { }
