﻿<div *ngIf="letterSetting">
    <div class="modal-header">
        <h2>פרטי מכתב [{{ (id > 0 ? id : 'חדש') }}] </h2>
    </div>
    <div class="modal-body">
        <dl class="dl-horizontal">
            <dt>שם</dt>
            <dd>{{ letterSetting.letterName }}</dd>
            <hr>
            <dt>חשבון (ב"מ)</dt>
            <dd>{{ letterSetting.accountLocation }}</dd>
            <hr>
            <dt>נושא (ב"מ)</dt>
            <dd>{{ letterSetting.commitmentSubject }}</dd>
            <hr>
            <dt>סיבות (ב"מ)</dt>
            <dd>{{ letterSetting.commitmentReasonIds }}</dd>
            <hr>
          <dt>תבנית כתובת</dt>
            <dd>
                <div innerHTML="{{letterSetting.titleAndShippingAddressTemplate}}"></div>
            </dd>
            <hr>
            <dt>תבנית מכתב</dt>
            <dd>
                <div [innerHTML]="letterSetting.mainTemplate"></div>
            </dd>
            <hr>
            <dt>תבנית תשלום</dt>
            <dd>
                <div [innerHTML]="letterSetting.paymentTemplate"></div>
            </dd>
            <hr>
            <dt>שוליים</dt>
            <dd style="white-space: nowrap;"><b>R:</b>{{ letterSetting.marginR }}px   <b>L:</b>{{ letterSetting.marginL }}px   <b>T:</b>{{ letterSetting.marginT }}px   <b>B:</b>{{ letterSetting.marginB }}px</dd>
            <hr>
            <dt>פורמט תאריך</dt>
            <dd>{{ letterSetting.letterDateFormat }}</dd>
            <hr>
            <dt>גירסת עיצוב</dt>
            <dd>{{ letterSetting.templateVertion }}</dd>
            <hr>
            <dt>גודל דף</dt>
            <dd>{{ letterSetting.pageSize }}</dd>
            <hr>
            <dt>פונט ב"מ</dt>
            <dd>{{ letterSetting.fontName }}</dd>
            <hr>

        </dl>
    </div>
</div>
