<fieldset class="fieldset"  *ngIf="paymentForm.controls">
<legend> פרטי תשלום בתוכנה חיצונית
</legend>
<div [formGroup]="paymentForm" style="margin: 0 auto;">
  <table class="table table-border">
    <thead class="text-nowrap">
      <th>תאריך ביצוע</th>
      <th>פרטים</th> 
    </thead>
    <tbody>
      <ng-container>
        <tr>
          <td>
            <date-input [withJewishDate]="false" [withLabel]="false" [placeholder]="'תאריך ביצוע:'"
              [dateControl]="paymentForm.controls.doneOn">Loading...</date-input>
          </td>
          <td>
            <input autocomplete="off" class="form-control"
            placeholder="פרטים..." apply-enter
            formControlName="externalSoftwarePayMethod">
          </td> 
        </tr>

      </ng-container>
    </tbody>
  </table> 
</div> 
</fieldset>