<div [formGroup]="searchRegistrationEventsListForm">
  <div  *ngIf="indexService" class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">
      <auto-complete apply-enter (onSelect)="indexService.selectionChanged($event)"
        [controllerName]="'RegistrationEvents'" [placeholder]="'אירוע...'"
        [formGroupModel]="searchRegistrationEventsListForm"
        [idControl]="searchRegistrationEventsListForm.controls.registrationEventID"
        [nameControl]="searchRegistrationEventsListForm.controls.registrationEventTitle">
      </auto-complete>

      &nbsp;&nbsp;
      <mat-select-multiple-dropdown [keyProperty]="'value'" [titleProperty]="'name'"
        (selectChange)="indexService.selectionChanged($event)"
        [listControl]="searchRegistrationEventsListForm.controls.registrationEventTypes" [placeholder]="'סוג רשומה...'"
        [list]="servicesData.RegistrationEventTypes">
      </mat-select-multiple-dropdown>
      &nbsp;&nbsp;
      <span fxFlex class="example-fill-remaining-space"></span>
      <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">


        <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
            class="fa fa-filter"></i></button>
        &nbsp;&nbsp;
        <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
            class="material-icons">clear</i></button>
        &nbsp;&nbsp;
        <button mat-mini-fab color="primary" title="ייצוא לאקסל" (click)="downloadExcel()">
          <i class="fa fa-file-excel-o"></i></button>
        &nbsp;&nbsp;
        <button mat-mini-fab color="primary" title="ייצוא לאקסל מלא" (click)="downloadHeavyExcel()">
          <i class="fa fa-file-excel-o"></i></button>
      </div>
    </div>
  </div>
  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="true" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item (click)="openDetails(row);">
                  <mat-icon>remove_red_eye</mat-icon>
                  <span>תצוגה מהירה</span>
                </a>
                <a mat-menu-item
                  [routerLink]="[ '', {outlets: {popup: [ 'registrationEvents', row.registrationEventID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים ופעולות</span>
                </a>

                <a mat-menu-item
                  [routerLink]="[ '', {outlets: {popup: [ 'registrationEvents', row.registrationEventID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>
                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="registrationEventsData"
                    [id]="row.registrationEventID" [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="registrationEventID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
              אירוע </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה אירוע">
              {{ row.registrationEventID }}
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שם אירוע </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שם אירוע"> {{ row.title }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="registrationEventType">
            <mat-header-cell *matHeaderCellDef mat-sort-header> סוג </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סוג"> {{
              row.registrationEventTypeName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תאריך"> {{ row.date |
              date:'dd/MM/yyyy' }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מחיר</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מחיר"> {{ row.price | number }} {{ row.currency |
              currency }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sumRegistrations">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מס' רישומים </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מס' רישומים"> {{
              row.sumRegistrations }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sumDoses">
            <mat-header-cell *matHeaderCellDef mat-sort-header>מס' מנות</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מס' מנות"> {{ row.sumDoses }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sumPrice">
            <mat-header-cell *matHeaderCellDef mat-sort-header> ס''ה לתשלום</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="ס''ה לתשלום"> {{ row.sumPrice |
              number }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sumAmountPaid">
            <mat-header-cell *matHeaderCellDef mat-sort-header> ס''ה שולם</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="ס''ה שולם"> {{ row.sumAmountPaid |
              number}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sumAmountToPay">
            <mat-header-cell *matHeaderCellDef mat-sort-header>ס''ה יתרה לתשלום </mat-header-cell>
            <mat-cell [ngStyle]="{'color':(row.sumAmountToPay > 0 ? 'red' : 'green')}" *matCellDef="let row"
              data-label="ס''ה יתרה לתשלום"> {{
              row.sumAmountToPay | number }}
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="percentToPay">
            <mat-header-cell *matHeaderCellDef>נותר לגביה</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="נותר לגביה"> {{ row.percentToPay | percent }}
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"
            (dblclick)="openSelectedRow(row.registrationEventID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>
