import { PayMethodEdit } from './payMethod-edit.cmp';
import { Payment } from '../../payments/payment/payment';
import { ServicesData } from '../../services/services.data';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Inject } from '@angular/core';
import { ModalService } from '../../components/modal/modal.svc';
import { AlertService } from '../../components/alert/alert.svc';
import { PayMethod } from './payMethod';
import { PayMethodsData } from '../payMethods.data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './payMethod-edit-dlg.tmpl.html'
})

export class PayMethodEditDlg implements OnInit {

  public contactId: number;
  public id: number;
  //public payMethodForm: FormGroup;
  public payMethod: PayMethod;


  @ViewChild('payMethodEdit', { static: true }) payMethodEdit: PayMethodEdit;

  constructor(
    public dialogRef: MatDialogRef<PayMethodEditDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public servicesData: ServicesData
  ) { }

  ngOnInit(): void {

  }
  closeAndCancelDlg() {
    this.payMethodEdit.payMethodForm.reset();
    this.dialogRef.close();
  }
  public isValid(): boolean {

    return (this.payMethodEdit.payMethodForm && this.payMethodEdit.payMethodForm.valid);
  }
}
