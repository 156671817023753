import { YarzeitMain } from './yarzeit/yarzeit-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { YarzeitsList } from './yarzeits-list.cmp';
import { YarzeitsMain } from './yarzeits-main.cmp';

export const YarzeitsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'yarzeits', component: YarzeitsMain, data: {  roles: "yarzeits",  title: 'רשימת נפטרים' } },
     { canActivate: [AuthGuard], path: 'yarzeits/add', component: YarzeitMain, data: { mode: 'edit', roles: "yarzeits", title: 'הוסף נפטר' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'yarzeits/:yarzeitID', component: YarzeitMain, data: { mode: 'edit', roles: "yarzeits",  title: 'עריכת פרטי נפטר' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'yarzeits/:yarzeitID/edit', component: YarzeitMain, data: { mode: 'edit', roles: "yarzeits",  title: 'עריכת פרטי נפטר' }, outlet: 'popup' }
];
