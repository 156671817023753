import { ServicesData } from './../../services/services.data';
import { ProcessesData } from './../processes.data'; 
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms'; 
import { Process } from './process';
@Component({
    
    selector: 'process-edit',
    templateUrl: './process-edit.tmpl.html'
})

export class ProcessEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private processesData: ProcessesData,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
    ) {

    }

    contactID: number;
    process: Process;
    id: number;
    processForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.processesData.getEdit(this.id)
                .subscribe((p:any) => {
                    this.process = p;
                    this.processForm.patchValue(p);
                });
        }
        else {
            this.processesData.getAdd(this.contactID)
                .subscribe((p:any) => {
                    this.process = p;
                    this.processForm.patchValue(p);
                });
        }
    }

    private initForm() {
        this.processForm = this.formBuilder.group({ createBy:[], createDate:[], changeBy:[], changeDate:[],            
            processID: [this.id, Validators.required],
            description: ['', Validators.required],
            processTypeID: [, Validators.required],
            contactID: [this.contactID, Validators.required],
        });
    }

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.contactID = +params['contactID'] || 0;
            this.id = +params['processID'] || 0;
        });
        this.initForm();
        this.load();

    }
}