<div *ngIf="model; else loading" class="model-section">
  <div fxLayout="column" style="margin: 15px 15px 15px 0px;">
    <h1 style="text-align: right;">צפי הכנסות</h1>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
      <dl class="dl-horizontal" style="white-space: nowrap; ">
        <dt>ממוצע חודשי <small><small>(מיחידות)</small></small></dt>
        <dd>
          {{ model.unitsValueMonthAvg |number}} ₪
        </dd>
        <hr>
        <dt>ממוצע חודשי <small><small>(מתרומות כללי)</small></small></dt>
        <dd>
          {{ model.otherValueMonthAvg |number}} ₪
        </dd>
        <hr>
        <dt><b>ס"ה ממוצע חודשי</b></dt>
        <dd>
          <b>{{ model.totalMonthAvg |number}} ₪</b>
        </dd>
        <hr style="margin-bottom: 50px;">

        <hr>
        <dt><b>ממוצע שנתי </b><small><small>(מיחידות)</small></small></dt>
        <dd>
          <b>{{ model.unitsValueYearAvg |number}} ₪</b>
        </dd>
        <hr style="margin-bottom: 50px;">

        <hr>
        <dt>מזומן</dt>
        <dd>
          {{ model.otherValueCash |number}} ₪
        </dd>
        <hr>



      </dl>

    </div>
    <h1 style="border: solid; line-height: 45px;" fxLayout="row" fxLayout.xs="column"  >
      סך ההכנסות הכולל של המגבית
      <br>
      <span style="font-weight: 700;">{{ ((model.totalMonthAvg * 36) + model.otherValueCash) |number}} ₪</span>
    </h1>

  </div>
</div>



<ng-template #loading>
  <h3 * style="text-align: center;">
    טוען נתונים
    <i style="color:royalblue" class="fa fa-refresh fa-spin"></i>
  </h3>
</ng-template>
