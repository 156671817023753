<div loading-status [stopLoadingWhen]="commitment != null" class="dialog-content-wrapper">
    <div *ngIf="commitment">


        <mat-toolbar matDialogTitle class="mat-accent m-0">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">{{commitment.firstName}} {{commitment.lastName}} </span>
                <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <div mat-dialog-content style="padding: 0px !important;">
            <commitment-details-view [commitment]="commitment"></commitment-details-view>
        </div>

        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">


        </div>
    </div>
</div>