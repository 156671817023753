<form [formGroup]="tishAreaForm" novalidate (ngSubmit)="save()" *ngIf="tishArea">
  <div class="modal-header">
    <h1>עריכת פרטי איזור [{{ (id > 0 ? id : 'חדש') }}] </h1>
  </div>
  <div class="modal-body">
    <div *ngIf="registration" class="example-form">
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי איזור</legend>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div style="width: 250px !important;" class="input-group mb-3">
              <label>שם אירוע:</label>
              <h3> {{ registration.contactName }}</h3>
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>שם איזור:</label>
              <input type="text" class="form-control" formControlName="tishAreaName" />
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>תיאור איזור:</label>
              <input type="text" class="form-control" formControlName="description" />
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>צבע:</label>
              <input type="text" class="form-control" formControlName="areaColor" />
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <label>הערה:</label>
            <textarea rows="4" class="comment" placeholder="הערה..." formControlName="comment"></textarea>
          </div>
        </fieldset>
      </div>
  </div>
</div>
<footer-edit-buttons [dataProvider]="tishAreasData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0"
  [modelFormGroup]="tishAreaForm"></footer-edit-buttons>

</form>
