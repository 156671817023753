
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { AlertService } from '../components/alert/alert.svc';
import { ToraxHttp } from 'src/app/utils/custom-http.svc';
import { filter } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { HoldingsData } from './holdings.data';
import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';



@Component({
  selector: 'holdings-list',
  templateUrl: 'holdings-list.tmpl.html',
})



export class HoldingsList implements OnInit {
  constructor(
    private holdingsData: HoldingsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
  ) {
  }

  displayedColumns: string[] = ['select', 'firstName', 'motherName', 'lastName', 'amount', 'month', 'year', 'shtibelName', 'paymentIDs', 'statusPayment', 'numberOfPrintSeries'];
  data: any[] = [];

  resultsLength = 0;
  private isLoadingResults = true;
  isRateLimitReached = false;
  searchHoldingsListForm: FormGroup;
  selectionAmount;
  sourceList;
  pageRows = 20;
  rowsCount;
  page;
  selection = new SelectionModel<number>(true, []);
  includeOldRecords = true;
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input('contactID') public contactID?: number;
  @Input('commitmentID') commitmentID?: number = null;
  @Input('paymentsPlanID') paymentsPlanID?: number = null;
  @Input('shtibelID') shtibelID?: number;
  @Input('responsibleID') responsibleID?: number;
  @Input('jewishYear') jewishYear?: number;

  @Input('includeRelatedHoldings') public includeRelatedHoldings: boolean = false;
  @Output() selectedRowsChange = new EventEmitter<any>();


  defaultFormValues = {
    page: 1,
    pageRows: this.pageRows,
  }

  public clerFilter() {
    this.initForm();
    this.getHoldings();
  }

  private getHoldings() {
    this.searchHoldingsListForm.controls['page'].setValue(this.paginator.pageIndex + 1);
    this.searchHoldingsListForm.controls['pageRows'].setValue(this.paginator.pageSize);



    if (this.jewishYear && this.jewishYear > 0) {
      this.searchHoldingsListForm.controls['jewishYear'].setValue(this.jewishYear);
    }
    if (this.contactID && this.contactID > 0) {
      this.searchHoldingsListForm.controls['contactID'].setValue(this.contactID);
    }
    if (this.responsibleID && this.responsibleID > 0) {
      this.searchHoldingsListForm.controls['responsibleID'].setValue(this.responsibleID);
    }
    if (this.commitmentID && this.commitmentID > 0) {
      this.searchHoldingsListForm.controls['commitmentID'].setValue(this.commitmentID);
    }
    if (this.paymentsPlanID && this.paymentsPlanID > 0) {
      this.searchHoldingsListForm.controls['paymentsPlanID'].setValue(this.paymentsPlanID);
    }
    if (this.shtibelID && this.shtibelID > 0) {
      this.searchHoldingsListForm.controls['shtibelID'].setValue(this.shtibelID);
    }


    if (this.contactID == null) {
      sessionStorage.setItem(this.router.url, JSON.stringify(this.searchHoldingsListForm.value));
    }
    this.isLoadingResults = true;
    console.log(this.searchHoldingsListForm.value);

    return this.holdingsData!.getList(this.searchHoldingsListForm.value)
      .subscribe((data: any) => {
        this.data = data;
        this.sourceList = data.list;
        this.page = data.list.length;
        this.pageRows = data.list.length;
        this.rowsCount = data.rowsCount;

        this.isLoadingResults = false;
        this.isRateLimitReached = false;

      });
  }

  private initForm() {
    this.searchHoldingsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      jewishYear: [],
      shtibelID: [],
      friendsInStiebel: [null],
      shtibelName: [],
      responsibleID: [],
      responsibleName: [],
      statusPayments: [],
      isPrint: [],
      commitmentID: [],
      paymentsPlanID: [],
      dateMonthsRange: [null],
      includeRelatedHoldings: [false, Validators.required],
      includeOldRecords: [false, Validators.required],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });

    this.selection.changed.subscribe(x => {
      this.searchHoldingsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.holdingID));
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  public downloadHoldingsPivotReportExls(year: number, responsibleID?: number, contactID?: number): void {
    if (year > 0) {

      this.holdingsData.downloadHoldingsPivotReportExls(year, responsibleID, contactID)
        .subscribe(blob => {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `דוח החזקת ביהמ''ד לאחראי.xlsx`;
          link.click();
        });
    } else {
      alert("יש לבחור שנה לפני הורדת קובץ");
    }
  }

  includeRelatedHoldingsChanged(e) {
    this.includeRelatedHoldings = !this.includeRelatedHoldings;
    this.searchHoldingsListForm.controls['includeRelatedHoldings'].setValue(this.includeRelatedHoldings);
    this.getHoldings();
  }

  panelOpenState;
  rows: any[];
  loadingIndicator: boolean;
  reorderable: boolean;
  public referrerCmpUrl = "";

  ngOnInit() {
    this.initForm();
    this.getHoldings();
  }

  pageChange(e) {
    this.searchHoldingsListForm.controls['pageRows'].setValue(e.pageSize || 20);
    this.selectionChanged(e);
  }

  selectionChanged(e) {
    this.getHoldings();
  }

  sortData(e) {
    this.addOrder(e.active, e.direction != "asc");
    this.getHoldings();
  }

  public search() {
    this.getHoldings();
  }

  addOrder(propertyName: string, desc: boolean = false) {
    const ordersControl = <FormArray>this.searchHoldingsListForm.controls['orders'];

    let ord = this.getOrder(propertyName);
    if (ord != null) {
      ord.controls["value"].setValue(desc);
    } else {
      ord = this.initOrder(propertyName, desc);
      ordersControl.push(ord);
    }
  }
  pageEvent: PageEvent;
  pageSizeOptions;
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  initOrder(propertyName: string, desc: boolean = false) {
    let propName = propertyName;
    let des = desc;
    return this.formBuilder.group({
      key: [propName],
      value: [des],
    });
  }

  getOrder(columnName: string): any {
    let lst = <FormArray>this.searchHoldingsListForm.controls['orders'];
    if (lst == null) return null;

    for (var i = 0; i < lst.length; i++) {
      if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
        return lst.controls[i];
      }
    }
    return null;
  }

  orderType(columnName: string) {
    let order = this.getOrder(columnName);
    return order != null ? order.controls["value"].value : null;
  }
}
