<div [formGroup]="searchEmergencySquadMembersListForm">
  <div  class="ibox-content m-b-sm border-bottom">
    <div class="row" style="padding: 10px 15px;">
      <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש...'"
        (onSelect)="indexService.selectionChanged($event)" [formGroupModel]="searchEmergencySquadMembersListForm"
        [idControl]="searchEmergencySquadMembersListForm.controls.contactID" [nameControl]="searchEmergencySquadMembersListForm.controls.contactName">
      </auto-complete>
      <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
          class="fa fa-filter"></i></button>
      &nbsp;
      <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
          class="material-icons">clear</i></button>
    </div>
  </div>
</div>
<div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
  <div fxFlex>
    <cdk-virtual-scroll-viewport testCDK [full-height]="true" #test itemSize="10"
      [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
      <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
        [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
        matSort matSortDisableClear matSortDirection="asc">
        <ng-container matColumnDef="select">
          <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
            <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
              *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox apply-enter (click)="$event.stopPropagation()" (change)="$event ? rowToggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a mat-menu-item
                [routerLink]="[ '', {outlets: {popup: [ 'emergencySquadMembers', row.emergencySquadMemberID]}}]">
                <mat-icon>pageview</mat-icon>
                <span>פרטים מלאים</span>
              </a>

              <a mat-menu-item
                [routerLink]="['', {outlets: {popup: ['emergencySquadMembers', row.emergencySquadMemberID, 'edit']}}]">
                <mat-icon>edit</mat-icon>
                <span>עריכה</span>
              </a>

              <a mat-menu-item [routerLink]="['', {outlets: {popup: ['emergencySquadMembers', row.emergencySquadMemberID, 'emergencySquadQueues', 'add']}}]">
                <mat-icon>calendar-days</mat-icon>
                <span>הוסף תור</span>
              </a>


              <a mat-menu-item>
                <mat-icon>information</mat-icon>
                <model-info style="padding: 4px" [dataProvider]="emergencySquadMembersData"
                  [id]="row.emergencySquadMemberID" [title]="'מידע רשומה'">
                </model-info>
              </a>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="emergencySquadMemberID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה
            חבר </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="מזהה חבר">
            <a [routerLink]="[ '', {outlets: {popup: [ 'emergencySquadMembers', row.emergencySquadMemberID]}}]">
              {{ row.emergencySquadMemberID }}
            </a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="contactName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>שם איש</mat-header-cell>
          <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם איש">
            <a [routerLink]="[ '/contacts', row.contactID]">{{ row.firstName }} {{ row.lastName }}</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="phoneNumber1">
          <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון 1
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="טלפון 1" style="direction: ltr">
            {{row.phoneNumber1 | phone}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phoneNumber2">
          <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון 2
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="טלפון 2" style="direction: ltr">
            {{row.phoneNumber2 | phone}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cityName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> עיר
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="עיר">
            {{row.cityName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="shtibelName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> שטיבל
          </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="שטיבל">
            {{row.shtibelName}}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"
          (dblclick)="openSelectedRow(row.emergencySquadMemberID)">
        </mat-row>
      </mat-table>
    </cdk-virtual-scroll-viewport>
  </div>
  <div fxFlex="15">
    <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
      [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
    </mat-paginator>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
