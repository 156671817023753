<form *ngIf="deposit" class="example-form" [formGroup]="depositForm" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      <small>{{ (id > 0 ? 'עריכת פרטי' : 'הוספת') }} הפקדה</small>
      <br>
    </h2>
  </div>
  <mat-dialog-content>
    <fieldset class="form-group">
      <legend>פרטי הפקדה</legend>

      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>סוג הפקדה:</label>
          <mat-select apply-enter class="form-control" formControlName="depositType" placeholder="סוג הפקדה"
            [disabled]="true">
            <mat-option *ngFor="let c of servicesData.DepositTypes" [value]="c.value">
              {{c.name}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>סכום להפקדה:</label>
          <input type="text" class="form-control" [disabled]="true" [value]="depositForm.controls.amount.value">
        </div> &nbsp;
        <div class="input-group mb-3">
          <label>מטבע:</label>
          <mat-select apply-enter class="form-control narrow" formControlName="currency" placeholder="מטבע"
            [disabled]="true">
            <mat-option *ngFor="let c of servicesData.Currencies" [value]="c.value">
              {{c.name}}
            </mat-option>
          </mat-select>
        </div>

      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>חשבון להפקדה:</label>
          <mat-select class="form-control" formControlName="organizationBankAccountID">
            <mat-option value="">...</mat-option>
            <mat-option *ngFor="let ob of servicesData.OrganizationBankAccounts" [value]="ob.id">
              {{ob.title}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <date-input apply-enter [placeholder]="'תאריך להפקדה'" [dateControl]="depositForm.controls.depositDate">
          Loading...</date-input>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <textarea rows="3" class="comment" placeholder="הערה..." formControlName="comment"></textarea>
      </div>
    </fieldset>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <button [disabled]="depositForm.invalid" class="btn btn-primary navbar-btn" apply-enter
      [mat-dialog-close]="depositForm"><i class="fa fa-save"></i> שמור </button>
    <button class="btn btn-warning navbar-btn" *ngIf="deposit.depositID > 0 && deposit.isActive"
      [mat-dialog-close]="depositForm" (click)="removeDeposit()"><i class="fa fa-warning"></i> ביטול
    </button>
  </mat-dialog-actions>

</form>
