
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';

@Component({
  selector: 'contactGroups-list-dlg',
  templateUrl: './contactGroups-list-dlg.tmpl.html'
})

export class ContactGroupListDlg   {
  constructor(
    public dialogRef: MatDialogRef<ContactGroupListDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

}



