import { MaterialModule } from './../../material.module';
import { DirectivesMdl } from './../../components/directives/directives-module';
import { DateInputModule } from './../../components/dateInput/date-input.mdl';
import { ModelInfoModule } from './../../components/model-info/model-info.mdl';
import { FooterEditButtonsMdl } from './../../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../../components/pagination/pagination.mdl';
import { PanelBoxModule } from './../../components/panel-box/panel-box.mdl';
import { PageHeadingModule } from './../../components/page-heading/page-heading.mdl';

import { ContactAppointmentsList } from './contactAppointments-list.cmp';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactAppointmentsData } from './contactAppointments.data';
import { ContactAppointmentDetails } from './contactAppointment/contactAppointment-details.cmp';
import { ContactAppointmentEditDlg } from './contactAppointment/contactAppointment-edit-dlg.cmp';
import { ContactAppointmentListDlg } from './contactAppointments-list-dlg.cmp';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,
        MaterialModule,
        ModelInfoModule,
        DateInputModule,
        DirectivesMdl
    ],
    declarations: [
        ContactAppointmentsList,
        ContactAppointmentDetails,
        ContactAppointmentEditDlg,
        ContactAppointmentListDlg
    ],
    exports: [
        ContactAppointmentsList,
        ContactAppointmentListDlg
    ],
    providers: [
        ContactAppointmentsData
    ],
    entryComponents: [
        ContactAppointmentEditDlg,
    ]
})
export class ContactAppointmentsMdl { }



