<form  [formGroup]="searchCitiesListForm">
    <div class="ibox-content m-b-sm border-bottom">

        <div class="example-container">

        </div>
        <div class="example-container">
            <div class="bounds">
                <div class="content" style="height: 70%; overflow: auto">
                    <mat-table [dataSource]="sourceList" style="width: 100%;" (matSortChange)="sortData($event)"
                        class="example-table" matSort matSortDisableClear matSortDirection="asc">


                        <ng-container matColumnDef="cityID">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>מזהה
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="מזהה">
                                <a [routerLink]="[ '', {outlets: {popup: [ 'cities', row.cityID]}}]"><i class="fa fa-folder-open"></i></a>
                                &nbsp;{{row.cityID}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="cityName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>שם עיר</mat-header-cell>
                            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם עיר">
                                {{ row.cityName }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="cityNameEn">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>שם עיר אנ.</mat-header-cell>
                            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם עיר אנ.">
                                {{ row.cityNameEn }}</mat-cell>
                        </ng-container>


                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                            (dblclick)="openSelectedRow(row.cityID)">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
            <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
                [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
        </div>
    </div>

</form>