<form class="example-form" [formGroup]="contactAppointmentForm" novalidate>
  <div *ngIf="contactAppointment" matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      <small>{{ (id > 0 ? 'עריכת פרטי' : 'הוספת') }} פגישה</small>
      <br>
      {{ contactAppointment.contactName }}
    </h2>

  </div>
  <mat-dialog-content style="padding:0px 24px 24px 24px">
    <div *ngIf="contactAppointment" fxLayout="column" style="display: block;">
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>נושא:</label>
          <input class="form-control" apply-enter formControlName="subject" placeholder="נושא..." />
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>תיאור:</label>
          <input class="form-control" apply-enter formControlName="description" placeholder="תיאור..." />
        </div>
      </div>
      <date-input [placeholder]="'תאריך:'" apply-enter [dateControl]="contactAppointmentForm.controls.startDateTime">
      </date-input>
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>מיקום:</label>
          <input class="form-control" apply-enter formControlName="location" placeholder="מיקום..." />
        </div>
      </div>
      <!-- <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>סטטוס:</label>
          <input class="form-control" apply-enter formControlName="status" placeholder="סטטוס..." />
        </div>
      </div> -->
      <div class="form-row" fxLayout="row" fxLayout.xs="column">
        <div class="input-group mb-3">
          <label>סיכום פגישה:</label>
          <textarea style="width: fit-content" matInput apply-enter formControlName="summary" class="comment" mat-maxlength="250" max-rows="6">
          </textarea>
        </div>
      </div>


      <!-- <panel-box title="משתמשים מורשים" [showClose]="false" [showCollapse]="false">
        <span class="panel-tools"></span>
        <div class="panel-content">
            <div class="row" style="margin:0;">
                <a class="btn btn-w-m btn-info" (click)="addGroupUser()">הוסף</a>
            </div>
            <hr>
            <div class="divTable" style="width: auto;">
                <div class="divTableHeading">
                    <div style="padding: 10px 4px 10px 0px;" class="divTableCell">
                        שם
                    </div>
                    <div style="padding: 10px 4px 10px 0px;" class="divTableCell">
                        ...
                    </div>
                </div>
                <div class="divTableBody" formArrayName="groupUsers">
                    <div class="divTableRow" [formGroupName]="i"
                        *ngFor="let cgCtrl of fcGroupUsers.controls; let i=index">
                        <div class="divTableCell">
                            <select class="form-control" formControlName="gxUserName">
                                <option value="">...</option>
                                <option *ngFor="let cs of servicesData.GxUsers" [value]="cs.id">
                                    {{cs.title}}
                                </option>
                            </select>
                            <small *ngIf="!groupForm.controls.groupUsers.controls[i].controls.appointmentID.valid"
                                class="text-danger">*</small>
                        </div>
                        <div class="divTableCell">
                            <a *ngIf="!groupForm.controls.groupUsers.controls[i].controls.isDeleted.value"
                                (click)="deleteItem(i)"><i class="fa fa-trash-o"></i>&nbsp;מחיקה</a>
                            <a *ngIf="groupForm.controls.groupUsers.controls[i].controls.isDeleted.value"
                                (click)="unDeleteItem(i)"><i class="fa fa-undo"></i>&nbsp;בטל</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </panel-box> -->
    </div>


  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <button [disabled]="contactAppointmentForm.invalid" class="btn btn-primary navbar-btn"
      [mat-dialog-close]="contactAppointmentForm"><i class="fa fa-save"></i> שמור </button>
    <button class="btn btn-danger navbar-btn" *ngIf="contactAppointment.appointmentID > 0"
      [mat-dialog-close]="contactAppointmentForm" (click)="delete()"><i class="fa fa-trash-o"></i> מחק
    </button>

    <!-- <button mat-button mat-dialog-close [mat-dialog-close]="contactAppointmentForm" class="btn btn-danger navbar-btn"><i
                class="fa fa-trash-o"></i> מחק לנצח
        </button> -->

  </mat-dialog-actions>
