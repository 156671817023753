import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { TermsList } from './terms-list.cmp';

export const TermsRoutes: Routes = [
    {
        canActivate: [AuthGuard], path: 'terms', data: {  roles: 'SystemAdministrator',  title: 'מסופים' },
         children: [
            { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: TermsList, data: {  roles: 'SystemAdministrator', title: 'מסופים' } },
          ]
    }
];

//export const appRoutingProviders: any[] = [];

