import { TishTable } from './../../tishTables/tishTable/tishTable'; 

export class TishEvent {
    tishEventID: number;
    countTables: number;
    tishTables:any[] = [];
    tishEventTitle;
    description;
    tishTablesIds:number[];
  
}



export class CloneTishEventModel {
    oldTishEventID: number;
    tishEventTitle;
    description;
    comment;
    eventDate; 

}
