﻿<div *ngIf="vouchersOrgPaymentPack">
    <div class="row">
        <div style="padding-right: 0px;" class="col-sm-12">
            <panel-box [showTitle]="false">
                <div class="row">
                    <div class="col-sm-6">
                        <h1>
                            [{{ vouchersOrgPaymentPack.vouchersOrgPaymentPackID }}]
                            {{ vouchersOrgPaymentPack.organizationName }}
                        </h1>
                        <mat-card fxLayout="row" class="example-card">
                            <mat-card-content style="width: 100%;">
                                <dl class="dl-horizontal">
                                    <dt>סכום</dt>
                                    <dd>{{ vouchersOrgPaymentPack.amount | number }}</dd>
                                    <hr>
                                    <dt>תאריך</dt>
                                    <dd> {{ vouchersOrgPaymentPack.billingDate |date }}</dd>
                                    <hr>
                                </dl>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-sm-6 text-left">

                    </div>
                </div>
            </panel-box>
        </div>
    </div>
    <mat-card fxLayout="row" class="example-card">
        <mat-card-content style="width: 100%;">
            <h3 fxLayout="column" fxFlex style="TEXT-DECORATION: UNDERLINE;">תשלומי שוברים</h3>
            <!-- <payments-list [vouchersOrgPaymentPackID]="vouchersOrgPaymentPack.vouchersOrgPaymentPackID">Loading... 
            </payments-list>-->
        </mat-card-content>
    </mat-card>
</div>