import { ContactCompensationsData } from './contactCompensations.data';
import { ContactsData } from './../contacts.data';
import { AlertService } from './../../components/alert/alert.svc';
import { ContactCompensationEditDlg } from './contactCompensation/contactCompensation-edit-dlg.cmp';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { ContactCompensation } from './contactCompensation/contactCompensation';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'contactCompensations-list',
	templateUrl: './contactCompensations-list.tmpl.html'
})
export class ContactCompensationsList implements OnInit {


	constructor(
		private contactCompensationsData: ContactCompensationsData,
		private formBuilder: FormBuilder,
		private alertSvc: AlertService,
		public dialog: MatDialog
	) {
	}

	contactCompensations: any[];
	searchContactCompensationsListForm: FormGroup;
	tempNewEmail;
	tempNewContactCompensation;

	viwHistory = false;
	@Input('contactID') public contactID: number;
	//@Input('contactCompensations') public contactCompensations: ContactCompensation[];


	contactCompensationChangeEvent() {
		this.getContactCompensations();
	}

	onEditClick(obj: ContactCompensation) {
		this.openAddOrEditContactCompensation(obj);
	}

	public getContactCompensations(): void {
		this.searchContactCompensationsListForm.controls['contactID'].setValue(this.contactID);
		this.contactCompensationsData!.getContactCompensations(this.contactID)
			.subscribe((x: any[]) => {
				this.contactCompensations = x;
			}
			);
	}

	openAddOrEditContactCompensation(contactCompensation?: ContactCompensation): void {
		setTimeout(() => {
			if (!contactCompensation) {
				contactCompensation = new ContactCompensation();
				contactCompensation.contactID = this.contactID;
			}
			const dialogRef = this.dialog.open(ContactCompensationEditDlg, {
				data: { contactId: contactCompensation.contactID, id: contactCompensation.contactCompensationID, contactCompensation: contactCompensation }
			});


			dialogRef.afterClosed().subscribe(result => {
				if (result && result.valid) {
					this.saveContactCompensation(result);
				}
			});
		}, 200);
	}

	private saveContactCompensation(contactCompensationForm: FormGroup) {
		this.contactCompensationsData.update(contactCompensationForm.value)
			.subscribe((pm: any) => {
				this.getContactCompensations();
				return true;
			})
	}


	private initForm() {
		this.searchContactCompensationsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			contactID: [this.contactID, Validators.required],
			selectedRows: [],
			orders: this.formBuilder.array([]),
		});

	}

	ngOnInit(): void {
		this.initForm();
		this.getContactCompensations();
	}




}