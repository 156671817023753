import { DashboardTrackingAccountsDataService } from './dashboard-tracking-accounts/dashboard-tracking-accounts-dataService';
import { SumPipeModule } from './../components/pipes/sum-pipe';
import { DashboardTrackingAccountsCalendar } from './dashboard-tracking-accounts/dashboard-tracking-accounts-calendar.component';
import { DashboardTrackingAccount } from './dashboard-tracking-accounts/dashboard-tracking-account.component';
import { DashboardTrackingAccounts } from './dashboard-tracking-accounts/dashboard-tracking-accounts.component';
// import { DashboardLotteryModule } from './dashboard-lottery/dashboard-lottery.module';
import { MaterialModule } from './../material.module';
import { AuthGuardMdl } from './../_guards/auth.guard.mdl';
import { DateInputRangeModule } from './../components/dateInput-range/date-input-range.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FuseWidgetModule } from './../components/widget/widget.module';
import { TabsMdl } from './../components/tabs/tabs.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { PhonePipeMdl } from './../components/pipes/phone-pipe';
import { DashboardSecretaryGeneralDataService } from './dashboard-secretary-general/dashboard-secretary-general.data';
import { DashboardSecretaryDataService } from './dashboard-secretary/dashboard-secretary.data';
import { DashboardDirectorComponent } from './dashboard-director/dashboard-director.cmp';
import { DashboardLocatorComponent } from './dashboard-locator/dashboard-locator.cmp';
import { DashboardSecretaryComponent } from './dashboard-secretary/dashboard-secretary.cmp';
import { DashboardSecretaryGeneralComponent } from './dashboard-secretary-general/dashboard-secretary-general.cmp';
import { PaymentsViewDlg } from './dashboard-payments-view-dlg.cmp';
import { DashboardPanelBoxModule } from './../components/dashboard-panel-box/dashboard-panel-box.mdl';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PanelBoxModule } from './../components/panel-box/panel-box.mdl';
import { NgModule } from '@angular/core';
import { DashboardDirectorDataService } from './dashboard-director/dashboard-director.data';
import { DashboardRouting } from './dashboard.routing';
import { TabsLargeMdl } from '../components/tabs-large/tabs-large.mdl';
// import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardDirectorChartsComponent } from './dashboard-director/dashboard-director-charts.cmp';
import { ToraxHttp } from '../utils/custom-http.svc';
import { PrintReciptsDlg } from './dashboard-secretary/print-recipts-dlg.cmp';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { DashboardGroupsComponent } from './‏‏dashboard-groups/dashboard-groups.cmp';
import { DashboardGroupsDataService } from './‏‏dashboard-groups/dashboard-groups.data';
import { CollectionBuildersMdl } from '../collection-builders/collection-builders.mdl';
import { DashboardLocatorDataService } from './dashboard-locator/dashboard-locator.data';
import { DashboardChapakModule } from '../dashboard-chapak/dashboard-chapak.module';
import { DashboardChartsModule } from './dashboard-charts/dashboard-charts.module';
import { ScreenModule } from '../screen/screen.mdl';
import { DashboardGroupsSpecialComponent } from './dashboard-groups-special/dashboard-groups-special.cmp';
import { GroupSpecialComponent } from './dashboard-groups-special/group-special/group-special.cmp';
import { DashboardGroupsSpecialDataService } from './dashboard-groups-special/dashboard-groups-special.data';
import { DashboardDirectorReportsModule } from './dashboard-director-reports/dashboard-director-reports.mdl';
import { DashboardTree } from './dashboard-tree/dashboard-tree.cmp';

@NgModule({
    imports: [
        CommonModule,
        PanelBoxModule,
        DashboardPanelBoxModule,
        PaginationModule,
        FormsModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        TabsLargeMdl,
        PhonePipeMdl,
        RouterModule.forChild(DashboardRouting),
        TabsMdl,
        MaterialModule,
        AuthGuardMdl,
        FuseWidgetModule,
        ScrollingModule,
        DateInputModule,
        DateInputRangeModule,
        // DashboardLotteryModule,
        DashboardChartsModule,
        DashboardChapakModule,
        SumPipeModule,
        CollectionBuildersMdl,
        ScreenModule,
        DashboardDirectorReportsModule
    ],
    declarations: [
        DashboardLocatorComponent,
        PaymentsViewDlg,
        DashboardSecretaryComponent,
        DashboardDirectorChartsComponent,
        DashboardSecretaryGeneralComponent,
        DashboardDirectorComponent,
        DashboardDirectorChartsComponent,
        DashboardTrackingAccount,
        DashboardTrackingAccounts,
        DashboardTrackingAccountsCalendar,
        DashboardGroupsComponent,
        DashboardGroupsSpecialComponent,
        GroupSpecialComponent,
        PrintReciptsDlg,
        DashboardTree
    ],
    providers: [
        DashboardSecretaryGeneralDataService,
        DashboardSecretaryDataService,
        DashboardDirectorDataService,
        DashboardGroupsDataService,
        DashboardLocatorDataService,
        DashboardGroupsSpecialDataService,
        DashboardTrackingAccountsDataService,
        ToraxHttp
    ],
    entryComponents: [
        PaymentsViewDlg,
    ]
})
export class DashboardMdl { }
