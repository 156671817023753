import { TishTableMain } from './tishTable/tishTable-main.cmp';
import { TishTableMap } from './tishTable/tishTable-map.cmp';
import { TishTableDlg } from './tishTable/tishTable-edit-dlg.cmp';
import { TishTableDetails } from './tishTable/tishTable-details.cmp';
import { TishTableEdit } from './tishTable/tishTable-edit.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';
import { MaterialModule } from './../material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { TishTablesData } from './tishTables.data';
import { TishTablesList } from './tishTables-list.cmp';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { TishTablesRoutes } from './tishTables.routing';
import { TishPlacesMdl } from '../tishPlaces/tishPlaces.mdl';
import { TishTableMoveDlg } from './tishTable/tishTable-move-dlg.cmp';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { TishTableStaticMap } from './tishTable/tishTable-static-map.cmp';

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(TishTablesRoutes),
		FormsModule,
		ReactiveFormsModule,
		PageHeadingModule,
		PaginationModule,
		PanelBoxModule,
		AutoCompleteModule,
		FooterEditButtonsMdl,
		MaterialModule,
		TishPlacesMdl,
    MainFormModalModule
	],
	declarations: [
		TishTableMain,
		TishTableMap,
		TishTablesList,
		TishTableEdit,
		TishTableDetails,
		TishTableDlg,
		TishTableMoveDlg,
    TishTableStaticMap

	],
	exports: [
		TishTableMain,
		TishTableMap,
		TishTablesList,
		TishTableEdit,
		TishTableDetails,
		TishTableDlg,
		TishTableMoveDlg,
    TishTableStaticMap
	],
	providers: [
		TishTablesData

	],
	entryComponents: [
		TishTableDlg,
	]
})
export class TishTablesMdl { }

