﻿<div style="padding-top:3px" class="table-responsive">
    <!-- <h3>תשלומים נוספים בסידרה</h3>
    <hr> -->
    <table loading-status [stopLoadingWhen]="payments && payments.length > 0" class="table table-striped">
        <thead>
            <tr>
                <th class="narrow">...</th>
                <th class="narrow">סכום</th>
                <th>תאריך</th>
                <th class="narrow">סטטוס</th>
                <th>באמצעות</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of payments">
                <td>
                    {{ p.paymentID }} </td>
                <td>{{ p.amount | number }} {{ p.currencyIcon }}</td>
                <td>{{ p.billingDate | date:'dd/MM/yyyy' }} {{ p.jewishBillingDate}}</td>
                <td>{{ p.statusPaymentName }}</td>
                <td>
                    <a *ngIf="isLink(p.payMethodID)"
                    [routerLink]="['', {outlets: {popup: ['payMethods', row.payMethodID]}}]">
                        {{ p.payMethodTitle }}</a>
                    <span style="white-space: normal;" *ngIf="!isLink(p.payMethodID)">
                        {{ p.payMethodTitle }}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
