<div style="cursor: initial" *ngIf="contactCompensation">
    <div class="example-full-width" fxLayout="row" fxLayoutAlign="start center">
        <a fxLayout="column" style="padding: 4px" (click)="editContactCompensation(contactCompensation)">
            <i class="fa fa-pencil"></i>
        </a> &nbsp;&nbsp;
        <model-info fxLayout="column" style="padding: 4px" [modelInfo]="contactCompensation"></model-info>
        &nbsp;&nbsp;
         <div style="padding-right:7px" fxLayout="column" fxFlex>
            <div fxLayout="row">
                <h3>{{ contactCompensation.description }}</h3>
            </div>
            <div fxLayout="row">
                <span
                style="padding: 0px 5px 0px 5px;">{{ contactCompensation.compensationDate | date:'dd/MM/yyyy' }}
                {{ contactCompensation.compensationJewishDate }}</span>
            <span style="padding: 0px 5px 0px 5px;">{{ contactCompensation.subject }}</span>

            </div>
        </div>
    </div>
</div>