import { MaterialModule } from './../material.module';
import { DirectivesMdl } from './../components/directives/directives-module';
import { BrowserModule } from '@angular/platform-browser';

//import { PayMethodsMdl } from './../payMethods/payMethods.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { NgModule } from '@angular/core';  
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';

import { CcChargeDlg } from './cc-charge-dlg.cmp';
import { CcChargeData } from './cc-charge.data';

@NgModule({
    imports: [
        CommonModule,        
      //  //BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        PanelBoxModule,
        AutoCompleteModule,
        FooterEditButtonsMdl,
        //PayMethodsMdl, 
        MaterialModule,
        DirectivesMdl
    ],
    declarations: [
        CcChargeDlg,
    ],
    exports: [
        CcChargeDlg,
    ],
    providers: [
        CcChargeData
    ],
    entryComponents: [
        CcChargeDlg
    ]
})
export class CcChargeMdl { }

                      

  