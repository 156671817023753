<div *ngIf="matTable && displayedColumns && dataSource && dataSource.data">

  <div class="content" style="height: 70%; overflow: auto" loading-status
  [stopLoadingWhen]="stopLoadingPoints">
  <ng-content></ng-content>
    <!-- <mat-table   [dataSource]="dataSource" style="overflow-x: scroll;" (matSortChange)="sortData($event)"
      class="example-table" matSort matSortDisableClear matSortDirection="asc">
     
     
    </mat-table> -->
  </div>
  <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false" [length]="rowsCount"
    [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>

</div>


<!-- <mat-paginator [pageSizeOptions]="pagination" [pageSize]="pageSize" 
  [ngStyle]="{ 'min-width': + tableMinWidth + 'px' }"></mat-paginator>
  </div> -->