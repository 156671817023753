<form  *ngIf="termEditForm && term" class="example-form" [formGroup]="termEditForm" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>עריכת פרטי מסוף
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>

      </button>
    </h2>
    <h3>[{{ term.termID }}] {{ term.termName }}</h3>

  </div>
  <mat-dialog-content fxLayout="row">
    <div fxLayout="column"  style="margin: 10px;">
       <div fxLayout="row">        
        <mat-form-field>
          <mat-select apply-enter (selectionChange)="subjectsSelectionChange($event)" placeholder="משוייך לנושאים..."
              [formControl]="termEditForm.controls.termCommitmentSubjects" multiple>
              <mat-option *ngFor="let cs of servicesData.CommitmentSubjects" [value]="cs.value">
                  {{cs.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <button [disabled]="termEditForm.invalid" class="btn btn-primary navbar-btn" [mat-dialog-close]="termEditForm"><i
        class="fa fa-save"></i> שמור </button>
  </mat-dialog-actions>