import { HonorsSetDetails } from './honors-set/honors-set-details.cmp';
import { HonorsSetMain } from './honors-set/honors-set-main.cmp';
import { HonorsSetsList } from './honors-sets-list.cmp';
import { AuthGuard } from './../_guards/auth.guard';  
import { Routes } from '@angular/router'; 

export const HonorsSetsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'honorsSets', pathMatch: 'full', component: HonorsSetsList, data: {  roles: "honorsSets",  title: 'רשימת סדרות כיבודים' } },
    { canActivate: [AuthGuard], path: 'honorsSets/add', component: HonorsSetMain, data: { mode: 'edit', roles: "honorsSets", title: 'הוסף סדרת כיבודים' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'honorsSets/:honorsSetID', component: HonorsSetMain, data: {  roles: "honorsSets",  title: 'פרטי סדרת כיבודים' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'honorsSets/:honorsSetID/edit', component: HonorsSetMain, data: { mode: 'edit', roles: "honorsSets",  title: 'עריכת פרטי סדרת כיבודים' }, outlet: 'popup' }
]; 