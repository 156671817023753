import { ServicesData } from './../../services/services.data';
import { TishTablesData } from './../tishTables.data';

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { TishTable } from './tishTable';
export class PaymentOption { }
export class Api { }

@Component({

    selector: 'tishTable-edit',
    templateUrl: './tishTable-edit.tmpl.html'
})

export class TishTableEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private tishTablesData: TishTablesData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData
    ) {
    }

    public tishGroupsUrl = "";
    public tishAreasUrl = "";
    tishTable: TishTable;
    id: number = 0;
    tishEventID: number;
    tishTableForm: FormGroup;


    public load(): void {

        if (this.id > 0) {
            this.tishTablesData.getMap(this.id)
                .subscribe((tg:any) => {
                    this.tishTable = tg;
                    this.fillForm(tg);
                });
        }
        else {
            this.tishTablesData.getAdd(this.tishEventID)
                .subscribe((tg:any) => {
                    this.tishTable = tg;
                    this.fillForm(tg);
                });
        }
    }

    private fillForm(tg: TishTable): void {
        this.tishTableForm.patchValue(tg);
    }

    public save(): void {
       // console.log('save run');
        if (this.tishTableForm.valid) {
            this.tishTablesData.update(this.tishTableForm.value)
                .subscribe((c: any) => {
                    this.location.back();
                    //this.router.navigate(['tishTables', c.id], { relativeTo: this.activatedRoute });
                });
        } else {
           // console.log('not valid');
            console.log(this.tishTableForm.errors);
        }
    }

    public cancel(): void {
        this.location.back();
    }

    public initForm() {
        this.tishTableForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            tishTableID: [0, Validators.required],
            tishEventID: [, Validators.required],
            tishEventTitle: [],
            tishAreaID: [],
            tishAreaName: [],
            tishGroupID: [],
            tishGroupName: [],
            countPlaces: [, Validators.required],
            tishTableName: ['', Validators.required],
            comment: [],
            tishTableWidth: [],
            tishTableHeight: [],
            tishTableLeft: [],
            tishTableTop: [],
            tishPlaces: this.formBuilder.array([]),
            chainingLocation: [],
            chainingRoundAndSize: [],
            narrowTable: [false, Validators.required],
        });

    }
    private tishEvents: Array<any>;
    private tishAreas: Array<any>;
    private tishGroups: Array<any>;


    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['tishTableID'];
            this.tishEventID = +params['tishEventID'];
        });

        //this.servicesData.getList("TishEvents").subscribe(e => { this.tishEvents = e; }, error => { });

        this.initForm();

        this.load();
        // if (this.id > 0) {
        // } else {
        //     this.tishTable = new TishTable();
        //     this.tishTable.tishTableID = 0;
        // }

        // this.tishTableForm.controls['tishEventID'].valueChanges.subscribe(data => {
        //     this.tishAreasUrl = `TishAreas/${data}`;
        //     this.tishGroupsUrl = `TishGroups/${data}`;
        // });
    }
}





