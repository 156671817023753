import { ServicesData } from './../../services/services.data';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption, MatList } from '@angular/material/list';
import { TermsData } from '../terms.data';
import { Component, OnInit, Input, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ElementSchemaRegistry } from '@angular/compiler';

@Component({
    selector: 'term-edit-dlg',
    templateUrl: './term-edit-dlg.tmpl.html'
})
  
export class TermEditDlg implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<TermEditDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private termsData: TermsData,
        private formBuilder: FormBuilder,
        private cdref: ChangeDetectorRef,
        public servicesData: ServicesData,
    ) {

    }
    @ViewChild('selectionList') private selectionList: MatList;


    term: any; 
    contactId: number;
    termEditForm: FormGroup; 


    private fillForm(p: any): void {
        this.termEditForm.patchValue(p);

    }

    onAreaListControlChanged(e) {
        let paymentsIds = (<any>this.selectionList).selectedOptions.selected.map(item => item.value);
        this.termEditForm.controls['termRoles'].setValue(paymentsIds);
    }

    subjectsSelectionChange(event) {
        const groupSubjectsArray = <FormArray>this.termEditForm.controls['termCommitmentSubjects'];
        let str = groupSubjectsArray.value.join();
        this.termEditForm.controls['commitmentSubjects'].setValue(str);
    }


    private initForm() {
        this.termEditForm = this.formBuilder.group({
            termID: [, Validators.required],
            termCommitmentSubjects: [, Validators.required],
            commitmentSubjects: [null, Validators.required],
        });
    }


    public load(): void {
        if (this.data && this.data != '') {
            this.termsData.get(+this.data)
                .subscribe((p: any) => {
                    this.term = p;
                    this.fillForm(p);
                });
        }
    }

    // public loadSubjects(): void {
    //     this.termsData.getSubjects()
    //         .subscribe((x: any) => {
    //             this.subjects = x;
    //         });
    // }


    ngOnInit() {
        // this.termName = this.data.termName;
        this.initForm();
        this.load();
        this.cdref.detectChanges();
        //this.loadSubjects();
    }
}



