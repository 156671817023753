<ul  loading-status [stopLoadingWhen]="accountGrp" *ngFor="let currencyGrp of accountGrp.trackingCurrencyModels" class=" todo-list m-t"
    style="direction: rtl; margin:0 !important">
    <li class="li-account">
        <div fxLayout="column">
            <div fxLayout="row" style="border-color: #c1c1c1; border: solid; border-width: 0 0 1px 0;">
                <div  fxFlex="60px" class="account-logo" fxLayoutAlign="center stretch" fxLayout="column">
                    <img  fxLayout="row"  *ngIf="accountGrp.accountLocation == 1"  style="height: 28px" src="./../../../assets/images/il-32.png" /> 
                    <img  fxLayout="row"  *ngIf="accountGrp.accountLocation == 2"  style="height: 28px" src="./../../../assets/images/usa-32.png" /> 
                    <img  fxLayout="row"  *ngIf="accountGrp.accountLocation == 3"  style="height: 28px" src="./../../../assets/images/uk-32.png" /> 
                    <img  fxLayout="row"  *ngIf="accountGrp.accountLocation == 4"  style="height: 28px" src="./../../../assets/images/il-32.png" /> 
                    <h3 style="margin: 0 auto;">{{(accountGrp.accountLocationName.replace('חשבון - ', ''))}}</h3>
                </div>
                
                <div  fxLayout="column" fxFlex style="padding: 5px 10px;">
                    <h2 fxLayout="row" style="font-weight: 500; white-space: nowrap;">
                        {{(currencyGrp.trackingPaymentOptionModels | sum:'amount') | number}}
                        {{currencyGrp.currencyIcon}}
                        &nbsp;&nbsp; <small style="margin-top: 10px"> <small>({{currencyGrp.countPayments}} רשומות)</small></small>
                    </h2> 
                        <span fxLayout="row" class="option-span"
                            *ngFor="let optionGrp of currencyGrp.trackingPaymentOptionModels"
                            [matTooltip]="('לחץ למעבר לתשלומי ' + optionGrp.paymentOptionName)">
                            {{optionGrp.paymentOptionName}}: {{optionGrp.amount | number }}{{currencyGrp.currencyIcon}}           
                        </span> 
                </div> 
            </div>

        </div>



    
    </li>
</ul>