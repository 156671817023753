import { CommitmentReasonMain } from './commitmentReason/commitmentReason-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { CommitmentReasonsList } from './commitmentReasons-list.cmp';  

 
    export const CommitmentReasonsRoutes: Routes = [
        { canActivate: [AuthGuard], path: 'commitmentReasons', component: CommitmentReasonsList, data: {  roles: "commitmentReasons",  title: 'רשימת סיבות התחייבויות' } },
        { canActivate: [AuthGuard], path: 'commitmentReasons/add', component: CommitmentReasonMain, data: { mode: 'edit', roles: "commitmentReasons", title: 'הוסף סיבת התחייבות' }, outlet: 'popup' },           
        { canActivate: [AuthGuard], path: 'commitmentReasons/:commitmentReasonID', component: CommitmentReasonMain, data: {  roles: "commitmentReasons",  title: 'פרטי סיבת התחייבות' } , outlet: 'popup'  },
        { canActivate: [AuthGuard], path: 'commitmentReasons/:commitmentReasonID/edit', component: CommitmentReasonMain, data: { mode: 'edit', roles: "commitmentReasons",  title: 'עריכת פרטי סיבת התחייבות' }, outlet: 'popup' }
    ]; 