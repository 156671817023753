import { ServicesData } from './../services/services.data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';


@Component({
    templateUrl: './payments-cancellationReceipt-dlg.tmpl.html'
})


export class PaymentsCancellationReceiptDlg implements OnInit {
    public formData: FormGroup;

    public data: any;

    constructor(
        private formBuilder: FormBuilder,
        public servicesData: ServicesData
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.formData = this.formBuilder.group({
            paymentID: [this.data.cancellationReceiptModel.paymentID, Validators.required],
            cancellationReason: [, Validators.required]
        });
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }
}
