import { RegistrationEvent } from './registrationEvent/registrationEvent';
import { ToraxHttp } from '../utils/custom-http.svc';
import { FormGroup } from '@angular/forms';
import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from "rxjs";
import { ObjectsListResult, ObjectListItem } from '../services/objects-list-results';
import { AlertService } from '../components/alert/alert.svc';

@Injectable()

export class RegistrationEventsData {




    private url = 'api/registrationEvents';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }

    getList(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }
    chargePayments(baseQuery: any) {
        return this.toraxHttp.post(`${this.url}/chargePayments`, baseQuery);
    }

    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    get(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}`);
    }

    downloadExcel(baseQuery: any) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
    }

    downloadHeavyExcel(baseQuery: any) {
        return this.toraxHttp.postAndDownload(`${this.url}/downloadHeavyExls`, baseQuery);
    }
    update(registrationEvent: RegistrationEvent) {
        return this.toraxHttp.update(this.url, registrationEvent);
    }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();


    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

