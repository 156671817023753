import { MaterialModule } from '../material.module';
import { MainFormModalModule } from '../components/main-form-modal/main-form-modal.mdl';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputModule } from '../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { AmountAndCurrencyInputModule } from '../components/amount-and-currency-input/amount-and-currency-input.module';
import { ModelInfoModule } from '../components/model-info/model-info.mdl';
import { EmergencySquadsRoutes } from './emergency-squads.routing';
import { EmergencySquadQueuesMdl } from './emergency-squad-queues/emergency-squad-queues.mdl';
import { EmergencySquadMembersMdl } from './emergency-squad-members/emergency-squad-members.mdl';
import { EmergencySquadsMain } from './emergency-squads-main.cmp';
import { TimeInputModule } from '../components/timeInput/time-input.mdl';
import { DateTimeInputModule } from '../components/dateTimeInput/date-time-input.mdl';


@NgModule({
	imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		DateInputModule,
		AutoCompleteModule,
		RouterModule.forChild(EmergencySquadsRoutes),
		MaterialModule,
		MainFormModalModule,
		AmountAndCurrencyInputModule,
    ModelInfoModule,
    EmergencySquadQueuesMdl,
    EmergencySquadMembersMdl,
    DateTimeInputModule

	],
	declarations: [
    EmergencySquadsMain
	],
	exports: [

    EmergencySquadsMain
	],
	providers: [
	]
})
export class EmergencySquadsMdl { }

