import { CompensationEditDlg } from './compensation/compensation-edit-dlg.cmp';
import { MaterialModule } from './../material.module';
import { FooterEditButtonsMdl } from '../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';

import { CompensationsData } from './compensations.data';
import { DateInputModule } from '../components/dateInput/date-input.mdl';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        DateInputModule,
        FooterEditButtonsMdl,
       MaterialModule
    ],
    declarations: [
        CompensationEditDlg
    ],
    exports: [
        CompensationEditDlg
    ],
    providers: [
        CompensationsData
    ],
    entryComponents: [
        CompensationEditDlg
    ]
})
export class CompensationsMdl { }



