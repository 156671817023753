<div>
    <form [formGroup]="searchContactsLotteryListForm">
        <div class="example-container" style="background-color: white; border-bottom: 10px solid rgb(243, 243, 244)">
            <div style="z-index: 10; position: relative; left: 0;  top:0px;" class="example-button-row">
                <h3 style="color: blue; position: absolute; width: 100%; text-align: left; top: -7px;" class="label">
                    <span *ngIf="searchContactsLotteryListForm.controls.cityTitle.value"> עיר:{{
                                searchContactsLotteryListForm.controls.cityTitle.value }}&nbsp;</span>
                    <span *ngIf="searchContactsLotteryListForm.controls.shtibelName.value"> שטיבל:{{
                                searchContactsLotteryListForm.controls.shtibelName.value }}&nbsp;</span>
                </h3>
            </div>

            <div class="example-button-row" style="white-space: nowrap;" fxLayout="row"
                style="position: absolute; left: 5px; top: -30px; z-index: 9999;">
                <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן"
                    (click)="selectionChanged($event)"><i class="fa fa-filter"></i></button>
                <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
                        class="material-icons">clear</i></button>


            </div>
            <div class="column" style="padding-top: 12px; overflow: auto;">
                <div fxLayout="row" class="scrolling-wrapper">
                    <contacts-search-input style="background-color: white; " fxFlex fxLayout="row"
                        (onChangeTxt)="selectionChanged($event)" matInput apply-enter #searchInp
                        [query]="searchContactsLotteryListForm.controls.query">
                    </contacts-search-input>
                    &nbsp;&nbsp;
                    <auto-complete style="background-color: white;" [smallTemplate]="true" fxLayout="row" apply-enter
                        #cityID [placeholder]="'עיר...'" (onSelect)="selectionChanged($event)"
                        [controllerName]="'Cities'" [formGroupModel]="searchContactsLotteryListForm"
                        [idControl]="searchContactsLotteryListForm.controls.cityID"
                        [nameControl]="searchContactsLotteryListForm.controls.cityTitle">
                    </auto-complete>
                    &nbsp;&nbsp;
                    <auto-complete style="background-color: white;" [smallTemplate]="true" fxLayout="row" apply-enter
                        #shtibelID [placeholder]="'שטיבל...'" (onSelect)="selectionChanged($event)"
                        [controllerName]="'Shtibels'" [formGroupModel]="searchContactsLotteryListForm"
                        [idControl]="searchContactsLotteryListForm.controls.shtibelID"
                        [nameControl]="searchContactsLotteryListForm.controls.shtibelName">
                    </auto-complete>
                </div>
            </div>  
        </div>

        <div class="example-container">
            <div class="bounds">
                <div class="content" style="height: 70%; overflow: auto">
                    <mat-table [dataSource]="sourceList" style="overflow-x: scroll;" (matSortChange)="sortData($event)"
                        class="example-table" matSort matSortDisableClear matSortDirection="asc">

                        <ng-container matColumnDef="select">
                            <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
                                <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                                    *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <mat-checkbox apply-enter (click)="$event.stopPropagation()"
                                    (change)="$event ? rowToggle(row) : null" [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="buttons">
                            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <button title="הצג עוד..." style="max-height: 21px;" mat-icon-button
                                    (click)="openDetails(row);" aria-label="הצג עוד...">
                                    <mat-icon style="color: gray;">remove_red_eye</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="codeIchud">
                            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> קוד איחוד
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="קוד איחוד" fxHide fxShow.gt-xs>
                                {{ row.codeIchud }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="firstName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> פרטי
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="פרטי"> {{row.firstName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="lastName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> משפחה
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="משפחה"> {{row.lastName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="chapakName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> חפ"ק
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="חפק"> {{row.chapakName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="note">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> הערה
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="הערה"> {{row.note}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="recruitedContatID">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה מתרים
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="מזהה מתרים"> {{row.recruitedContatID}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="updateDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך עדכון
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="תאריך עדכון"> {{row.updateDate  | date: 'dd-MM-yyyy'}}
                            </mat-cell>
                        </ng-container>
                    
                      
                        <ng-container matColumnDef="shtibelName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> שטיבל </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="שטיבל"> {{row.shtibelName}}
                            </mat-cell>
                        </ng-container>
                          <ng-container matColumnDef="cityName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> עיר </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="עיר"> {{row.cityName}} </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="tickets">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> סך כרטיסים </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="סך כרטיסים">
                                {{ row.tickets }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="phoneNumber1">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון 1
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="טלפון 1"> {{row.phoneNumber1}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="phoneNumber2">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> טלפון 2
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="טלפון 2"> {{row.phoneNumber2}}
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
            <h1 *ngIf="!sourceList && !isLoadingResults" style="color: gray; text-align: center;">
                הקלד בשורת החיפוש כדי להציג רשומות...
            </h1>
            <h1 *ngIf="isLoadingResults" style="color: gray; text-align: center;">
                <i class="fa fa-refresh  fa-spin"></i>
            </h1>
            <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
                [length]="rowsCount" [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
        </div>
    </form>
</div>