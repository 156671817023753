import { DashboardChartsTabsComponent } from './dashboard-charts-tabs.component';
import { AuthGuard } from './../../_guards/auth.guard';
import { Routes } from '@angular/router';
import { DashboardChartsControlComponent } from './dashboard-charts-control.component';

export const DashboardChartsRoutes: Routes = [
    {
        canActivate: [AuthGuard], path: 'charts', data: { roles: "charts", title: 'מרבה תורה' },
        children: [
            { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: DashboardChartsTabsComponent, data: { title: 'מרבה תורה' } },
             // { canActivate: [AuthGuard], path: 'control', component: DashboardChartsTabsComponent, data: {  title: 'בקרת תשלומים' } },


        ]
    }];
