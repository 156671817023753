<form *ngIf="data && formData" class="example-form" [formGroup]="formData" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      {{ (data.tishTableID > 0 ? 'עריכת פרטי' : 'הוספת') }} מקום
      <br>
      <small>שולחן: <b>{{ data.tishTableName }}</b> מקום: <b>{{ data.placeNumber }}</b> </small>
    </h2>
  </div>
  <mat-dialog-content>
    <div style="display: block;">

      <div [formGroup]="formData" *ngIf="data" class="panel-content">
        <div>
          <!-- <div class="input-group mb-3">
                        <hr> <label >מיקום:</label>

                        <div [hidden]="data.narrowTable" >
                            לא מוגדר:
                            <input type="radio" formControlName="isRight" value="" />
                            <br> ימין:
                            <input type="radio" formControlName="isRight" value="true" />
                            <br> שמאל:
                            <input type="radio" formControlName="isRight" value="false" />
                            <br>
                            <h3 class="label">שים לב! ערך זה לא ישפיע כאשר השולחן הוא צר,
                                <br>במקרה זה המקומות יהיו רק בצד האי זוגי (1,3,5..)</h3>



                        </div>
                        <hr>
                    </div> -->

          <auto-complete apply-enter [placeholder]="'מוזמן'" [controllerName]="'TishInviteds/' + data.tishEventID"
            [formGroupModel]="formData" [idControl]="formData.controls.tishInvitedID"
            [nameControl]="formData.controls.tishInvitedName"></auto-complete>


          <auto-complete apply-enter [placeholder]="'קבוצה'" [controllerName]="'TishGroups/' + data.tishEventID"
            [formGroupModel]="formData" [idControl]="formData.controls.tishGroupID"
            [nameControl]="formData.controls.tishGroupName"></auto-complete>
          <auto-complete apply-enter [placeholder]="'איזור'" [controllerName]="'TishAreas/' + data.tishEventID"
            [formGroupModel]="formData" [idControl]="formData.controls.tishAreaID"
            [nameControl]="formData.controls.tishAreaName"></auto-complete>

          <div class="input-group mb-3" *ngIf="formData.controls.tishInvitedID.value == null">
            <label for="isActive">פעיל:</label>
            <mat-slide-toggle formControlName="isActive">
            </mat-slide-toggle>
          </div>
          <div class="input-group mb-3">
            <label for="comment">הערה:</label>
            <textarea class="form-control comment" apply-enter rows="1" style="width: 100%;" placeholder="הערה..."
              formControlName="comment"></textarea>
          </div>
          <div class="input-group mb-3">
            <label for="isMarginActive" style="white-space: nowrap;">הוסף מרווח:</label>
            <mat-slide-toggle formControlName="isMarginActive">
            </mat-slide-toggle>
          </div>
          <fieldset *ngIf="formData.controls.isMarginActive.value == true">
            <legend>פרטי מרווח</legend>
            <div>
              <div class="input-group mb-3">
                <label>מיקום רווח:</label>
                <mat-select class="form-control" (selectChange)="indexService.selectionChanged($event)"
                  placeholder="מיקום רווח..." formControlName="marginSide">
                  <mat-option [value]="false">לפני</mat-option>
                  <mat-option [value]="true">אחרי</mat-option>
                </mat-select>
                <!-- <div style="white-space: nowrap;">
                  לפני: <input type="radio" formControlName="marginSide" value="false" />
                  אחרי: <input type="radio" formControlName="marginSide" value="true" />
                </div> -->
              </div>
              <div class="input-group mb-3">
                <label for="marginPoint">רוחב (px):</label>
                <div>
                  <input type="text" class="form-control" formControlName="marginPoint" />
                </div>
              </div>
              <div class="input-group mb-3">
                <label for="marginType">סוג:</label>
                <mat-select placeholder="סוג..." class="form-control" formControlName="marginType">
                  <mat-option *ngFor="let c of servicesData.MarginTypes" [value]="c.value">
                    {{ c.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </fieldset>
        </div>

      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="float: left; padding: 20px;">
    <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn"
      [mat-dialog-close]="formData"><i class="fa fa-save"></i> שמור </button>
    <button class="btn btn-warning navbar-btn" *ngIf="data.tishPlaceID > 0" mat-dialog-close><i
        class="fa fa-warning"></i> ביטול
    </button>

  </mat-dialog-actions>

</form>
