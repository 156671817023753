<form [formGroup]="campaignAttachedForm" novalidate (ngSubmit)="save()" *ngIf="campaignAttached">
    <div class="modal-header">
        <h1 style="margin: 0;"> <small>עריכת פרטי מצטרף [{{ (id > 0 ? id : 'חדש') }}] </small>
            <br>
            {{ campaignAttached.attachedName }}
        </h1>
    </div>
    <div class="modal-body">
        <div class="example-form">
            <div class="col-sm-12">
                <fieldset class="form-group" fxLayout="column">
                    <legend>פרטי מצטרף</legend>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>שם מצטרף:</label>
                            <input autocomplete="off" class="form-control" apply-enter formControlName="attachedName"
                                placeholder="שם מצטרף...">
                        </div>
                    </div>                     
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>כמות:</label>
                            <input autocomplete="off" class="form-control" apply-enter formControlName="count"
                                placeholder="כמות...">
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <div class="input-group mb-3">
                            <label>כמות ששולמו:</label>
                            <input autocomplete="off" class="form-control" apply-enter formControlName="countPaid"
                                placeholder="כמות ששולמו...">
                        </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.xs="column">
                        <textarea rows="3" class="comment" placeholder="הערה..."
                            formControlName="comment"></textarea>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
    <footer-edit-buttons [onSaveGoToBack]="true" [navigateAfterSaving]="'campaignAttacheds'"
        [dataProvider]="campaignAttachedsData" [router]="router" [id]="id" [disabledDeleteButton]="false"
        [modelFormGroup]="campaignAttachedForm">
    </footer-edit-buttons>
</form>