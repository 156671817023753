 
import { VouchersOrgPaymentPackMain } from './vouchersOrgPaymentPack/vouchersOrgPaymentPack-main.cmp';
import { VouchersOrgPaymentPacksList } from './vouchersOrgPaymentPacks-list.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';  


export const VouchersOrgPaymentPacksRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'vouchersOrgPaymentPacks', component: VouchersOrgPaymentPacksList, data: {  roles: "vouchersOrgPaymentPacks",  title: 'שולחנות' } },
    { canActivate: [AuthGuard], path: 'vouchersOrgPaymentPacks/add', component: VouchersOrgPaymentPackMain, data: { mode: 'edit', roles: "vouchersOrgPaymentPacks", title: 'הוסף שולחן' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'vouchersOrgPaymentPacks/:vouchersOrgPaymentPackID', component: VouchersOrgPaymentPackMain, data: {  roles: "vouchersOrgPaymentPacks",  title: 'פרטי שולחן' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'vouchersOrgPaymentPacks/:vouchersOrgPaymentPackID/edit', component: VouchersOrgPaymentPackMain, data: { mode: 'edit', roles: "vouchersOrgPaymentPacks",  title: 'עריכת פרטי שולחן' }, outlet: 'popup' },
];
 

