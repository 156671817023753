import { ScrollableItemDirective } from './../../components/directives/scrollable-item.directive';
import { DashboardTrackingAccountsDataService } from './dashboard-tracking-accounts-dataService';
import { Location } from '@angular/common';
import { MatTabGroup } from '@angular/material/tabs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, Input, EventEmitter, Output, OnInit, ViewChild, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { DateRangeClass } from 'src/app/components/dateInput-range/date-input-range.class';
import { LotteryChartModel } from '../dashboard-lottery/dashboard-lottery-chart-model';

@Component({
    selector: 'dashboard-tracking-accounts-calendar',
    templateUrl: 'dashboard-tracking-accounts-calendar.tmpl.html',
    styleUrls: ['./../dashboard.scss']
})
export class DashboardTrackingAccountsCalendar implements OnInit {
    expectPaymentsInMonths: LotteryChartModel;

    constructor(
        private dashboardTrackingAccountsDataService: DashboardTrackingAccountsDataService,
        private router: Router,
        private cdref: ChangeDetectorRef,
        private location: Location,
        private formBuilder: FormBuilder
    ) {
      this.loadExpectPaymentsInMonths(1);
    }

    userName: string;
    model: any = {};
    collectionStatusModelForm: FormGroup;
    daysList: any[];
    selectedDate: any;
    @Output() goToPaymentsClick = new EventEmitter<any>();
    @ViewChild('tabs', { static: true }) tabs: MatTabGroup;
    @ViewChildren(ScrollableItemDirective) scrollableItems: QueryList<ScrollableItemDirective>

    loadExpectPaymentsInMonths(accountLocation:number) {
      this.dashboardTrackingAccountsDataService.getExpectPaymentsInMonths(accountLocation)
          .subscribe((x: LotteryChartModel) => {
              this.expectPaymentsInMonths = x;

          });
  }
    selectDate(dayParams) {
        this.selectedDate = null;
        this.cdref.detectChanges();
        this.selectedDate = dayParams;
        this.selectedDateTitle = `${this.selectedDate.jewishDate}`.trim();
        this.handleDayClick();
    }

    handleDayClick() {
        const item = this.scrollableItems.find(x => x.key === this.selectedDate.date)
        item.scrollIntoView();
    }
    // isSelectedDate(date) {
    //     if (date && this.selectedDate && this.selectedDate.date == date.date) {
    //         console.log("true",date);
    //         return true;
    //     }
    //     else { return false; }
    // }

    private initForm(row) {
        let relevantPaymentDateRange = new DateRangeClass();
        if (row.relevantPaymentDate) {
            relevantPaymentDateRange.start = row.relevantPaymentDate;
            relevantPaymentDateRange.end = row.relevantPaymentDate;
            relevantPaymentDateRange.includeNulls = false;
        }

        this.collectionStatusModelForm = this.formBuilder.group({
            relevantStatusPayments: [row.relevantStatusPayment ? [row.relevantStatusPayment] : null],
            relevantPaymentDateRange: [row.relevantPaymentDate ? relevantPaymentDateRange : null],
            accountLocations: [row.accountLocation ? [row.accountLocation] : null],
            currency: [row.currency ? row.currency : null],
            paymentsForTreatment: [row.paymentsForTreatment ? row.paymentsForTreatment : false],
        });

    }



    weeksAfterOrBefor = 0;
    ngOnInit(): void {
        this.getDatesLists();
    }

    private getDatesLists() {
        this.dashboardTrackingAccountsDataService.getDatesLists(this.weeksAfterOrBefor)
            .subscribe((x: any) => {
                this.daysList = x;
                let firstDay = this.daysList[0];
                let lastDay = this.daysList[6];
                this.fromTitle = `${firstDay.jewishHebDayInMonth} ${(firstDay.jewishMonthWord != lastDay.jewishMonthWord ? firstDay.jewishMonthWord : '')}`.trim();
                this.tillTitle = `${lastDay.jewishHebDayInMonth} ${lastDay.jewishMonthWord}`.trim();
            });
    }
    fromTitle = "";
    tillTitle = "";
    selectedDateTitle = "";

    prevWeek() {
        this.weeksAfterOrBefor--;
        console.log("weeksAfterOrBefor", this.weeksAfterOrBefor);
        this.getDatesLists();
    }
    nextWeek() {
        this.weeksAfterOrBefor++;
        console.log("weeksAfterOrBefor", this.weeksAfterOrBefor);
        this.getDatesLists();
    }

    // goToPayments(row: any = null) {
    //     if (row == null) {
    //         row = {
    //             paymentsForTreatment: true,
    //             accountLocation: this.model.userAccountLocation
    //         }
    //     }
    //     this.initForm(row)
    //     sessionStorage.setItem(`dashboard/payments/dbCollectionStatus`, JSON.stringify(this.collectionStatusModelForm.value));
    //     this.router.navigate(['/payments']);
    // }
}
