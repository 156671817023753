
import { AlertService } from "./../components/alert/alert.svc";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { ServicesData } from "../services/services.data";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToraxHttp } from "../utils/custom-http.svc";
import { IndexService } from "../services/index-service";
import { YarzeitsData } from "./yarzeits.data";
import { SubscriptionLike } from "rxjs";

@Component({
  selector: "yarzeits-list",
  templateUrl: "yarzeits-list.tmpl.html",
  styleUrls: ["./yarzeits.scss"],
})


export class YarzeitsList implements OnInit, OnDestroy {
  constructor(
    private yarzeitsData: YarzeitsData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
    private cdref: ChangeDetectorRef,
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/yarzeits') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }
  selectedRowIndex?: number;
  selectedRow: any;
  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  displayedColumns: string[] = ["buttons", "yarzeitID", "lastName", "jewishDeathDate"];

  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  searchYarzeitsListForm: FormGroup;

  actualMinusHeight = '260';

  rerender = false;
  @Input("contactID") public contactID?: number | null = null;

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  highlight(selectedRow: any, index: number) {
    if (index >= 0 && index <= this.indexService.sourceList.length - 1) {
      this.selectedRowIndex = index;
      this.selectedRow = selectedRow;
      console.log(this.selectedRowIndex, this.selectedRow);
      this.rerender = false;
      this.cdref.detectChanges();
      this.rerender = true;


    }
  }

  arrowUpEvent(selectedRow: any, index: number) {
    this.highlight(selectedRow, index - 1);
  }

  arrowDownEvent(selectedRow: any, index: number) {
    this.highlight(selectedRow, index + 1);
  }

  isChecked(row: any): boolean {
    const found = this.selection.selected.find(
      (el: any) => el.yarzeitID === row.yarzeitID
    );
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  addNew() {
    this.router.navigate(['', { outlets: { popup: ['yarzeits', 'add'] } }]);
  }

  openSelectedRow(yarzeitID) {
    this.router.navigate(["", { outlets: { popup: ["yarzeits", yarzeitID] } }]);
  }

  public createAndPrintYarzeitsLetters(): void {
    let ids = this.searchYarzeitsListForm.controls["selectedRows"].value
      .map((x) => x)
      .join();
    if (!ids) {
      alert("לא נבחרו רשומות להדפסה");
    } else {
      this.yarzeitsData
        .createAndPrintYarzeitsLetters(ids)
        .subscribe((data) => {
          let popupWinindow: any = window.open(
            "",
            "_blank",
            "scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
          );
          popupWinindow.document.open();
          popupWinindow.document.write(
            '<html><head><style type="text/css">.MsoNormal { margin-top: 0px; margin-bottom: 0px;} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { page-break-after: always; } }  </style></head><body  onload="window.print()"><div>' +
            (<any>data).value +
            "</div></body></html>"
          );
          popupWinindow.document.close();
        });
    }
  }

  selectDay(day) {

    this.searchYarzeitsListForm.controls["selectedDate"].setValue(day._georgDate);
    this.indexService.getList();
  }
  private initForm() {
    this.searchYarzeitsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      fatherID: [],
      chotenID: [],
      contactName: [],
      yarzeitTypes: [],
      yarzeitsDateRange: [null],
      selectedDate: [],
      selectedRows: [],
      orders: this.formBuilder.array([]),
    });

    this.selection.changed.subscribe((x) => {
      this.searchYarzeitsListForm.controls["selectedRows"].setValue(
        x.source.selected.map((x: any) => x.yarzeitID)
      );
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  panelOpenState: boolean = false;
  toogleState() {
    this.panelOpenState = !this.panelOpenState;
    if (this.panelOpenState) {
      this.searchYarzeitsListForm.controls["selectedDate"].setValue(null);
    } else {
      let cDt = new Date().toJSON().slice(0, 10);
      this.searchYarzeitsListForm.controls["selectedDate"].setValue(cDt);
    }
  }

  ngOnInit() {


    this.initForm();

    if (!this.contactID) {
      this.activatedRoute.params.forEach((params: Params) => {
        //this.contactID = +params["contactID"] || null;
      });
      let item = sessionStorage.getItem(this.router.url);
      if (item) {
        let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
        this.searchYarzeitsListForm.patchValue(frm);
      }
      let rng = this.searchYarzeitsListForm.controls["yarzeitsDateRange"];
      let day = this.searchYarzeitsListForm.controls["selectedDate"];
      if (!day && !rng.value) {
        let cDt = new Date().toJSON().slice(0, 10);
        this.searchYarzeitsListForm.controls["selectedDate"].setValue(cDt);
      }
      this.searchYarzeitsListForm.updateValueAndValidity();
      this.selection.isSelected = this.isChecked.bind(this);
      let params = [
        // { key: "contactID", value: this.contactID },
      ];
      this.indexService = new IndexService(this.searchYarzeitsListForm, params, this.yarzeitsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
      this.indexService.getList();




    }
  }
}
