import { ServicesData } from './../../services/services.data';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption, MatList } from '@angular/material/list';
import { UsersData } from '../users.data';
import { Component, OnInit, Input, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ElementSchemaRegistry } from '@angular/compiler';

@Component({
    selector: 'user-add-dlg',
    templateUrl: './user-add-dlg.tmpl.html'
})

export class UserAddDlg implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<UserAddDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private usersData: UsersData,
        private formBuilder: FormBuilder,
        private cdref: ChangeDetectorRef,
        public servicesData: ServicesData,
    ) {

    }
    @ViewChild('selectionList') private selectionList: MatList;


    user: any;
    communicationType;
    contactId: number;
    userAddForm: FormGroup;
    subjects: any[];


    selectAll() {
        (<any>this.selectionList).options.forEach(element => {
            element.selected = true;
        });
    }
    unSelectAll() {
        (<any>this.selectionList).options.forEach(element => {
            element.selected = false;
        });
    }
    refresh() {
        this.load();
    }

    private fillForm(p: any): void {
        this.userAddForm.patchValue(p);
    }

    onAreaListControlChanged(e) {
        let paymentsIds = (<any>this.selectionList).selectedOptions.selected.map(item => item.value);
        this.userAddForm.controls['userRoles'].setValue(paymentsIds);
    }

    subjectsSelectionChange(event) {
        const groupSubjectsArray = <FormArray>this.userAddForm.controls['userCommitmentSubjects'];
        let str = groupSubjectsArray.value.join();
        this.userAddForm.controls['commitmentSubjects'].setValue(str);
    }


    private initForm() {
        this.userAddForm = this.formBuilder.group({
            userName: [, Validators.required],
            firstName: [, Validators.required],
            lastName: [, Validators.required],
            password: [, Validators.required],
            department: [, Validators.required],
            userCommitmentSubjects: [, Validators.required],
            commitmentSubjects: [null, Validators.required],      
        });
    }


    // public load(): void {
    //     this.user = {};
    //     this.fillForm(this.user);
    // } 
    public load(): void {
        this.user = { userName: this.data };
        this.fillForm(this.user);
    }

    public loadSubjects(): void {
        this.usersData.getSubjects()
            .subscribe((x: any) => {
                this.subjects = x;
            });
    }


    ngOnInit() {
        // this.userName = this.data.userName;
        this.initForm();
        this.load();
        this.cdref.detectChanges();
        this.loadSubjects();
    }
}



