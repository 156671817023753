<div *ngIf="yearsList" class="row col-lg-12 col-md-12 col-sm-12" style="padding: 0;">
  <button style="float: left; position: absolute; left: 0;" mat-dialog-close aria-label="Close dialog" title="סגור"
  class="btn  btn-circle.btn-lg">
  <i class="fa fa-times"></i>
</button>
  <div class="row col-lg-3 col-md-3  col-sm-3"
    style="height: 100%; padding: 50px 15px 20px 15px; display: grid;min-width: 110px; background: #f3f5f6; border-left: solid; border-left-width: 1px;">

    <button class="btn yearBtn" *ngFor="let year of yearsList" label="{{year}}" (click)="selectYear(year)"
      [ngClass]="{'rightArrowDiv': selectedYear == year }">
      {{year}}
    </button>

  </div>
  <div class="col-lg-8 col-md-8 col-sm-8" style="padding:15px 30px 0px 25px;">
    <h1 style="white-space:noWrap">סיכום שנתי מקוצר {{selectedYear}}</h1>
    <h3 style="font-weight: bold;"> {{contactName}}</h3>
    <h5>תרומות ששולמו, מחושב בש"ח</h5>
    <table *ngIf="dataList" style="margin-top: 20px; font-size: larger;  line-height: 40px">
      <tr style="border-bottom: solid 2px;  line-height: 30px">
        <th>נושא</th>
        <th>סכום</th>
      </tr>
      <tr *ngFor="let d of dataList" style="border-bottom: solid 1px; ">
        <td>{{d.commitmentSubjectName}}</td>
        <td>{{d.amount | number}} ₪</td>
      </tr>
    </table>
  </div>
  <label class="label" style="overflow: visible; position: absolute; bottom: 0px; left: 40px;">* נושאים נכללים: החזקת ביהמ"ד, שוטף (עליות), קרן הבנין</label>

</div>
