 import { AmountInputRange } from './amount-input-range.cmp';
import { AmountInputModule } from '../amount-input/amount-input.mdl';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AmountInputModule, 
    ],
    exports: [
        AmountInputRange
    ],
    declarations: [
        AmountInputRange
    ]
})
export class AmountInputRangeModule { }

