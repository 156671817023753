<form  [formGroup]="vouchersOrganizationForm" novalidate   *ngIf="vouchersOrganization">
    <div class="modal-header">
        <h1>עריכת פרטי ארגון [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">
                <panel-box title="פרטי ארגון" [showClose]="false" [showCollapse]="false">
                    <span class="panel-tools"></span>
                    <div class="panel-content">
                        <div class="form-horizontal">
                            <div>
                                <div class="form-group">
                                    <label class="control-label col-sm-2" for="organizationName">שם ארגון:</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" formControlName="organizationName" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </panel-box>
            </div>
        </div>
    </div>
    <footer-edit-buttons [onSaveGoToBack]="true" [dataProvider]="vouchersOrganizationsData" [router]="router" [id]="id" [disabledDeleteButton]="false"
        [modelFormGroup]="vouchersOrganizationForm"></footer-edit-buttons>
</form>