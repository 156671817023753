
import { AuthGuard } from '../../_guards/auth.guard';
import { Routes } from '@angular/router';
import { EmergencySquadMembersList } from './emergency-squad-members-list.cmp';
import { EmergencySquadMemberMain } from './emergency-squads-member/emergency-squad-member-main.cmp';
import { EmergencySquadQueueMain } from '../emergency-squad-queues/emergency-squad-queue/emergency-squad-queue-main.cmp';

export const EmergencySquadMembersRoutes: Routes = [
  { canActivate: [AuthGuard], path: 'emergencySquadMembers', component: EmergencySquadMembersList, data: { roles: "emergencySquadMembers", title: 'רשימת תורים' } },
  { canActivate: [AuthGuard], path: 'emergencySquadMembers/add', component: EmergencySquadMemberMain, data: { mode: 'edit', roles: "emergencySquadMembers", title: 'הוסף תור' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'emergencySquadMembers/:emergencySquadMemberID', component: EmergencySquadMemberMain, data: { roles: "emergencySquadMembers", title: 'פרטי תור' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'emergencySquadMembers/:emergencySquadMemberID/edit', component: EmergencySquadMemberMain, data: { mode: 'edit', roles: "emergencySquadMembers", title: 'עריכת פרטי תור' }, outlet: 'popup' },


  { canActivate: [AuthGuard], path: 'emergencySquadMembers/:emergencySquadMemberID/emergencySquadQueues/add', component: EmergencySquadQueueMain, data: { mode: "edit", roles: "emergencySquadQueues", title: 'הוסף תור' }, outlet: 'popup' },


];
