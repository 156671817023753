import { Injectable } from '@angular/core';

@Injectable()
export class AlertService {

    public alertList: Alert[] = [];

    // public success(msg: string, ttl: number = 5) {
    //     this.addAlert(msg, '', '', 'success', ttl);
    // }
    public info(msg: string, ttl: number = 3, index = null) {
        this.addAlert(msg, '', '', 'info', ttl, index);
    }
    public error(err: any, ttl: number = 7) {
        if(err){
            this.addAlert(err.Message || err.message || err, err.ActionPath || '', err.StatusCode || '', 'danger', ttl);
        }
    }

    public success(successInfo: any, ttl: number = 3) {
        if (successInfo) {
            this.addAlert(successInfo.Message || successInfo.message || successInfo, successInfo.ActionPath || '', successInfo.StatusCode || '', 'success', ttl);
        }
    }

    public addAlert(msg: string, path: string = '', statusCode: string = '', type: string = 'info', ttl: number = 5, index: number = null) {
        const alert = new Alert(msg, path, statusCode, type, ttl, index);

        if (alert.ttl) {
            this.removeOnTtl(alert);
        }

        this.alertList.push(alert);
    }
    public removeByAlert(alert) {
        const ix = this.alertList.indexOf(alert);
        this.removeByIndex(ix);
    }
    private removeByIndex(ix) {
        if (ix >= 0) {
            this.alertList.splice(ix, 1);
        }
    }

    public removeOnTtl(alert: Alert) {
        setTimeout(() => {
            this.removeByAlert(alert);
        }, alert.ttl * 1000);
    }

    constructor() { }
}

export class Alert {
    constructor(
        public msg: string,
        public path: string = '',
        public statusCode: string = '',
        public type: string,
        public ttl: number,
        public index?: number
    ) { }
}
