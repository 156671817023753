<div fxLayout="column" [formGroup]="tishTableForm" class="draggable_wp"
    [ngStyle]="{'width': (tishTableForm.controls.narrowTable.value) ? '65px' : '75px'}" style="position: relative; z-index: 20px !important;"
    >

    <div fxLayout="row" style="margin: 0;padding: 0;background: #39387929;">
        <span fxFlex class="table-name">{{tishTableForm.controls.tishTableName.value}}</span>

        <i class="actionIcon fa fa-unlock-alt hidden-print" *ngIf="visibleToolsPanel"
            (click)="visibleToolsPanel = !visibleToolsPanel"></i>


        <i class="actionIcon fa fa-save hidden-print" (click)="save()"></i>
    </div>

    <div class="el">
        <div *ngFor="let tpForm of tishPlaces.controls" class="placeContainer col-sm-5"
            style="margin: 0; padding: 0; border: 0px;"
            [ngStyle]="{'float': (tpForm.controls.placeNumber.value % 2) == 0 ? 'right' : 'left'}">
            <tishPlace-map [tishEventID]="tishTableForm.controls.tishEventID.value" (dragInvited)="onDragInvited($event)" (dropedElement)="onDropedElementFn($event)"  [searchTishPlaceNumber]="(tishTableForm.controls.tishTableName.value == searchTishTableName ? searchTishPlaceNumber : null)" [tishPlaceForm]="tpForm"></tishPlace-map>
        </div>
    </div>
</div>
