import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServicesData } from '../../../services/services.data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ContactsData } from '../../contacts.data';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';
import { tr } from 'date-fns/locale';
import { cwd } from 'process';


@Component({
  templateUrl: './achas-iledoros-dlg.tmpl.html'
})
export class AchasIledorosDlg implements OnInit {
  public formData: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contactsData: ContactsData,
    public servicesData: ServicesData,
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AchasIledorosDlg>,
  ) { }

  @ViewChild('bankBranchesCmp') bankBranchesCmp: AutoCompleteComponent;

  ngOnInit(): void {
    this.initForm();

  }
  activeTabIndex = 0;
  payOption = null;
  private initForm(): void {
    console.log(this.data);
    this.formData = this.formBuilder.group({
      //    achasIledorosKlitas:[this.data?.achasIledorosKlitas || 0,  Validators.required],
      contactID: [this.data?.contactID], //, Validators.required
      commitmentReasonID: [this.data?.commitmentReasonID, Validators.required],
      amount: [this.data?.amount, Validators.required],
      currency: [this.data?.currency || 3, Validators.required],
      dayInMonth: [this.data?.dayInMonth || 20, Validators.required],
      qty: [this.data?.qty],
      paymentOption: [],
      oldPayMethodID: [],
      comment: [],
      //פרטי אשראי
      creditNumber: [],
      expiryStr: [],
      cvv: [],
      //פרטי הו"ק בנקאי
      bankAcountNumber: [],
      bankBranchNumber: [],
      bankBranchName: [],
      bankNumber: [],
      bankName: [],
      bankAcountOwner: [, this.hoksVlidators],
      amountLimit: [],
      dateLimit: [],

      commitmentOnly: [false, Validators.required],

    });
    if (!this.data.payMethodID) {
      this.activeTabIndex = 1;
      this.payOption = 1;
      this.formData.controls['paymentOption'].setValue(this.payOption);
    } else {
      this.activeTabIndex = 0;
      this.payOption = null;
      this.formData.controls['paymentOption'].setValue(this.payOption);

    }

    this.formData.controls.commitmentReasonID.valueChanges.subscribe((value) => {
      switch (value) {
        case 113:
          if (!this.formData.controls.amount.value) {
            this.formData.controls.amount.setValue(250);
            this.formData.controls.qty.setValue(40);
          }
          break;
        case 114:
          this.formData.controls.amount.setValue(125);
          this.formData.controls.qty.setValue(40);

          break;
        case 115:
          this.formData.controls.amount.setValue(null);
          this.formData.controls.qty.setValue(null);

          break;
        case 116:
          this.formData.controls.amount.setValue(600);
          this.formData.controls.qty.setValue(60);

          break;
        case 117:
          this.formData.controls.amount.setValue(null);
          this.formData.controls.qty.setValue(null);
          break;
        default:
          break;
      }
    });

  }

  public itemSelect(id: number) {
    this.bankBranchesCmp.sourceList = `BankBranches/${id}`;
  }
  slideChange(e) {
    console.log(e);
  }
  tabItemSelect(e) {
    console.log(e);

    let ix = +e.index;
    switch (ix) {
      case 1:
        this.payOption = 1;
        this.formData.controls['paymentOption'].setValue(1);
        break;
      case 2:
        this.payOption = 4;
        this.formData.controls['paymentOption'].setValue(4);
        break;
      default:
        this.payOption = null;
        this.formData.controls['paymentOption'].setValue(null);
        break;
    }
    //יש להתייחס לאמצעי תשלום  כדלהלן
    // ריק = אמצעי קיים
    //1 = כרטיס אשראי
    //4 = הוראת קבע

  }
  selectionChanged(e) {

    if (e.checked) {
      this.formData.controls['oldPayMethodID'].setValue(this.data?.payMethodID);
    } else {
      this.formData.controls['oldPayMethodID'].setValue(null);
    }
    console.log(this.formData.controls['oldPayMethodID'].value);
  }

  creditCardVlidators = c => {
    if (!c.value && this.payOption == 1) {
      return { required: true };
    }
    return null;
  };

  oldPmVlidators = c => {
    if (!c.value && this.payOption == null) {
      return { required: true };
    }
    return null;
  };

  hoksVlidators = c => {
    if (!c.value && this.payOption == 4) {
      return { required: true };
    }
    return null;
  };

  public isValid(): boolean {
    let ret = false;

    if (this.formData.controls.commitmentOnly.value == false) {

      switch (this.payOption) {
        case null:
          ret = this.formData.controls['oldPayMethodID'].value != null
            && this.formData.controls['qty'].value != null;
          break;
        case 1:
          ret = this.formData.controls['creditNumber'].value != null
            && this.formData.controls['expiryStr'].value != null
            && this.formData.controls['qty'].value != null;

          break;
        case 4:
          ret = this.formData.controls['bankNumber'].value != null
            && this.formData.controls['bankBranchNumber'].value != null
            && this.formData.controls['bankAcountNumber'].value != null
            && this.formData.controls['qty'].value != null;

          break;
        default:
          break;
      }
    } else {
      ret = true;
    }
    return ret;
  }

  loading = false;

  saveAchasIledoros() {
    this.loading = true;
    if (this.isValid()) {


      this.contactsData.updateAiData(this.formData.value)
        .subscribe((x: any) => {

          this.dialogRef.close();
        }, error => {
          this.loading = false;
        })

    } else {

      this.loading = false;
      alert('אנא מלא את כל השדות המסומנים בכוכבית *');
    }

  }
}
