<div class="input-group md-3"  [formGroup]="formGroupModel">
  <label *ngIf="withLabel">{{placeholder}}:</label>
  <input #txtSearch apply-enter style="min-width: 158px;" [matAutocomplete]="auto" [placeholder]="placeholder" class="form-control" (cdkAutofill)="isAutofilled = $event.isAutofilled" [formControl]="stringValueControl" />
  <mat-autocomplete [autoActiveFirstOption]="true" #auto="matAutocomplete" #matAutocomplete>
    <mat-option [ngClass]="{'leftToRight': leftToRight}" *ngFor="let item of searchResults"
      (onSelectionChange)="setActive($event, item)" [ngClass]="{active: isActive(item)}" [value]="item">
      {{ item }}
    </mat-option>
  </mat-autocomplete>
</div>
