import { CommitmentMain } from './../commitments/commitment/commitment-main.cmp';
import { PaymentsPlanMain } from './../paymentsPlans/paymentsPlan/paymentsPlan-main.cmp';
import { PayMethodMain } from './../payMethods/payMethod/payMethod-main.cmp';
import { PaymentMain } from './../payments/payment/payment-main.cmp';
import { ContactMain } from './contact/contact-main.cmp';
import { HonorMain } from './../honors/honor/honor-main.cmp';
import { RegistrationMain } from './../registrations/registration/registration-main.cmp';
import { ContactDetails } from './contact/contact-details.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';

import { ContactsListDashboard } from './contacts-list-dashboard.cmp';
import { ContactsList } from './contacts-list.cmp';
import { ContactsGroupList } from './contacts-group-list.cmp';

  import { ContactTreeComponent } from './contact-tree/contact-tree.component';
import { TishInvitedMain } from '../tishInviteds/tishInvited/tishInvited-main.cmp';



  export const contactsRoutings: Routes = [


  // לצורך בדיקה של העץ בלבד{ canActivate: [AuthGuard], path: 'contacts', component: ContactTreeComponent, data: { roles: "contacts", title: 'רשימות אנ"ש' } },

  { canActivate: [AuthGuard], path: 'contacts', component: ContactsList, data: { roles: "contacts", title: 'רשימות אנ"ש' } },
  { canActivate: [AuthGuard], path: 'contactsGroups/:groupID', component: ContactsGroupList, data: { roles: "contacts", title: 'רשימות אנ"ש' } },
  { canActivate: [AuthGuard], path: 'contactsGroups/:groupID/:groupStatusID', component: ContactsGroupList, data: { roles: "contacts", title: 'רשימות אנ"ש' } },
  { canActivate: [AuthGuard], path: 'contacts/add', component: ContactMain, data: { mode: "edit", roles: "contacts", title: 'הוסף איש' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/:contactID', component: ContactDetails, data: { roles: "contacts", title: 'פרטי איש' } },
  { canActivate: [AuthGuard], path: 'contacts/:contactID/edit', component: ContactMain, data: { mode: "edit", roles: "contacts", title: 'עריכת פרטי איש' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/groupContacts/:groupID/:groupSubjectsAsStr', component: ContactsListDashboard, data: { roles: "Contacts", title: "רשימות אנ\"ש" } },


  { canActivate: [AuthGuard], path: 'contacts/:contactID/payments/add', component: PaymentMain, data: { mode: "edit", roles: "payments", title: 'הוסף תרומה/תשלום' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/:contactID/paymentsPlans/add', component: PaymentsPlanMain, data: { mode: "edit", roles: "paymentsPlans", title: 'הוסף תוכנית תשלום' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/:contactID/paymentsPlans/add/:paymentsPlanType', component: PaymentsPlanMain, data: { mode: "edit", roles: "paymentsPlans", title: 'הוסף תוכנית תשלום' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/:contactID/payMethods/add', component: PayMethodMain, data: { mode: "edit", roles: "payMethods", title: 'הוסף אמצעי תשלום' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/:contactID/payMethods/add/:paymentOption', component: PayMethodMain, data: { mode: "edit", roles: "payMethods", title: 'הוסף אמצעי תשלום' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/:contactID/commitments/add', component: CommitmentMain, data: { mode: "edit", roles: "Commitments", title: 'הוסף התחייבות/הסכם' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/:contactID/honors/add', component: HonorMain, data: { mode: "edit", roles: "Honors", title: 'הוסף כיבוד' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/:contactID/registrations/add', component: RegistrationMain, data: { mode: "edit", roles: "registrations", title: 'הוסף רישום' }, outlet: 'popup' },
  { canActivate: [AuthGuard], path: 'contacts/:contactID/tishInviteds/addInvited/:tishEventID', component: TishInvitedMain, data: { mode: "edit", roles: "tishInviteds", title: 'הוסף זימון' }, outlet: 'popup' },


  { canActivate: [AuthGuard], path: 'paymentsPlans/:paymentsPlanID', component: PaymentsPlanMain, data: {  roles: "paymentsPlans",  title: 'פרטי תוכנית תשלום' } , outlet: 'popup'  },
  { canActivate: [AuthGuard], path: 'payments/:paymentID', component: PaymentMain, data: {  roles: "payments",  title: 'פרטי תשלום' } , outlet: 'popup'  },
  { canActivate: [AuthGuard], path: 'commitments/:commitmentID', component: CommitmentMain, data: {  roles: "commitments",  title: 'פרטי התחייבות' } , outlet: 'popup'  },

];
