import { ContactDetailsDlg } from './contacts-details-dlg.cmp';
import { Contact } from './contact/contact';
import { AuthenticationService } from './../_guards/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ContactsSearchInput } from '../components/contacts-search-input/contacts-search-input.component';
import { AutoCompleteComponent } from '../components/‏‏auto-complete/auto-complete.cmp';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ContactsData } from './contacts.data';
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, HostListener } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService } from '../components/alert/alert.svc';
import { MatTable } from '@angular/material/table';
import { IndexService } from '../services/index-service';
//import * as signalR from '@aspnet/signalr';

@Component({
  selector: 'contacts-list',
  templateUrl: 'contacts-list.tmpl.html',
  styleUrls: ['./contacts-list.scss']
})

export class ContactsList implements OnInit, AfterViewInit {
  constructor(
    public contactsData: ContactsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    public _matDialog: MatDialog,
    private alert: AlertService,
    private cdref: ChangeDetectorRef) {
  }



  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInp') searchInp: ContactsSearchInput;
  @ViewChild('cityID') cityID: AutoCompleteComponent;
  @ViewChild('shtibelID') shtibelID: AutoCompleteComponent;
  @ViewChild('table') table: MatTable<any>;
  @Output() selectedRowsChange = new EventEmitter<any>();



  orderButtonIsChicked: boolean = false;
  selection = new SelectionModel<number>(true, []);
  stopLoadingPoints: boolean = false;
  searchContactsListForm: FormGroup;
  data: any[] = [];
  contactGroupStatuses: any[];
  onlySortRecentlyOpenedByCurUser = false;
  groupingColumn = 'openingJewishDate';
  groupingOrderColumn = 'openingDateTime';
  defaultvalues;
  indexService: IndexService;
  displayedColumns: string[] = ['select', 'buttons', 'contactID', 'perfix', 'lastName', 'firstName', 'suffix', 'fatherViewName', 'chotenName', 'streetLine', 'cityName', 'phoneNumber1', 'phoneNumber2', 'shtibelName', 'codeIchud'];

  addNew() {
    this.router.navigate(['', { outlets: { popup: ['contacts', 'add'] } }]);
  }

  public error(): void {
    console.log('error copy');
  }
  public success(): void {
    console.log('success copy');
  }
  topCurUserOpenings() {
    this.onlySortRecentlyOpenedByCurUser = !this.onlySortRecentlyOpenedByCurUser;
    this.searchContactsListForm.controls['onlySortRecentlyOpenedByCurUser'].setValue(this.onlySortRecentlyOpenedByCurUser);

    this.indexService.getList();
  }

  topOpenings() {
    this.orderButtonIsChicked = !this.orderButtonIsChicked;
    if (this.orderButtonIsChicked) {
      this.indexService.addOrder(this.groupingOrderColumn, true);
    } else {
      this.onlySortRecentlyOpenedByCurUser = false;
      this.searchContactsListForm.controls['onlySortRecentlyOpenedByCurUser'].setValue(this.onlySortRecentlyOpenedByCurUser);
      this.indexService.removeOrder(this.groupingOrderColumn);
    }

    this.indexService.getList()
  }

  toogleContactFavorite(row: Contact) {
    this.contactsData.toogleContactFavorite(row.contactID)
      .subscribe((x: boolean) => {
        row.isFavorite = x;
        if (row.isFavorite) {
          this.alert.success(`${row.firstName} ${row.lastName} נוסף בהצלחה למועדפים`);
        } else {
          this.alert.info(`${row.firstName} ${row.lastName} הוסר מרשימת המועדפים`);
        }
      }
      )
  }

  openSelectedRow(contactID) {
    this.router.navigate(['contacts', contactID]);
  }

  openDetails(row) {
    this._matDialog.open(ContactDetailsDlg, {
      // panelClass: 'contact-details',
      data: row,
      width: '90%'
    });
  }

  initForm() {
    this.searchContactsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [],
      contactName: [],
      cityID: [],
      cityTitle: [],
      friendsInStiebel:[null],
      shtibelID: [],
      shtibelName: [],
      groupID: [],
      groupIDs: [],
      groupPriorities: [],
      groupStatuses: [],
      onlySortRecentlyOpenedByCurUser: [false, Validators.required],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });


    this.searchContactsListForm.controls['groupIDs'].valueChanges.subscribe(x => {
      this.servicesData.getList(`ContactGroupStatuses/${x}`)
        .subscribe(
          (c: any) => {
            this.contactGroupStatuses = c;
          },
          (x) => {
            //console.log("xxxxxxxx", x);
          });
    });

    let frm = this.searchContactsListForm;
    this.defaultvalues = Object.keys(this.searchContactsListForm.value)
      .filter(key => frm.controls[key].validator == Validators.required)
      .reduce((obj, key) => {
        obj[key] = frm.controls[key].value;
        return obj;
      }, {});
  }

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();



    let params = [
      // { key: "searchInp", value: this.searchInp },
      // { key: "cityID", value: this.cityID },
      // { key: "shtibelID", value: this.shtibelID }
    ];


    this.indexService = new IndexService(this.searchContactsListForm, params, this.contactsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchContactsListForm.patchValue(frm);
      if (frm.orders) {
        frm.orders.forEach(x => { this.indexService.addOrder(x.key, x.value) });
      }
    }

    if (this.searchContactsListForm.controls['orders'].value.indexOf(this.groupingOrderColumn)) {
      this.orderButtonIsChicked = true;
    }

    if (this.searchContactsListForm.controls['onlySortRecentlyOpenedByCurUser'].value == true) {
      this.onlySortRecentlyOpenedByCurUser = true;
    }

    this.indexService.selection.changed.subscribe(x => {
      this.searchContactsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.contactID));
      this.selectedRowsChange.emit(x.source.selected);
    });

    this.indexService.getList();

  }

  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  printSticks(state: string = "") {
    //console.log(this.selection);
    let contactIDs = this.searchContactsListForm.controls['selectedRows'].value.map(x => x).join();

    this.contactsData.printSticks(contactIDs, state)
      .subscribe(
        data => {
          let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWinindow.document.open();
          popupWinindow.document.write('<html dir="rtl"><head><style type="text/css"> tr.payments:nth-child(even){background-color: #f2f2f2} @page { size: letter; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; margin: 0px } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; page-break-inside: avoid; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
          popupWinindow.document.close();
          return true;
        }
      )
  }
}
