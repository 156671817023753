import { Payment } from 'src/app/payments/payment/payment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentsData } from '../payments.data';
import { Component, OnInit, Inject, Input } from '@angular/core';


@Component({
    selector: 'payment-short-details',
    templateUrl: './payment-short-details.tmpl.html'
})
export class PaymentShortDetails    {
    @Input() payment: Payment;


    exludePayMethodsIDs = [88, 89, 420, 1310, 3580, 6232, 6990, 42340];

    isLink(pmId) {
        return this.exludePayMethodsIDs.indexOf(pmId) < 0;
    }

}

