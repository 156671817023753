<div [formGroup]="searchTishInvitedsListForm">

  <div *ngIf="!contactID" class="column" style="padding-top: 12px; overflow: auto">
    <div fxLayout="row" class="scrolling-wrapper">

      <contacts-search-input style="background-color: white" fxFlex fxLayout="row"
        (onChangeTxt)="indexService.selectionChanged($event)" matInput #searchInp
        [query]="searchTishInvitedsListForm.controls.query">
      </contacts-search-input>
      &nbsp;&nbsp;
      <mat-select class="form-control small-input" formControlName="groupID" placeholder="קבוצה..."
        (selectionChange)="indexService.selectionChanged($event)">
        <mat-option [value]="null">בחר קבוצה...</mat-option>
        <mat-option *ngFor="let cs of tishGroups" [value]="cs.id">
          {{cs.title}}
        </mat-option>
      </mat-select>
      &nbsp;&nbsp;
      <mat-select class="form-control small-input" formControlName="invitedStatus" placeholder="סטטוס..."
        (selectionChange)="indexService.selectionChanged($event)">
        <mat-option [value]="null">בחר סטטוס...</mat-option>
        <mat-option *ngFor="let cs of tishInvitedStatuses" [value]="cs.id">
          {{cs.title}}
        </mat-option>
      </mat-select>
      &nbsp;&nbsp;
      <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש...'" [smallTemplate]="true"
        fxLayout="row" [formGroupModel]="searchTishInvitedsListForm"
        [idControl]="searchTishInvitedsListForm.controls.contactID"
        [nameControl]="searchTishInvitedsListForm.controls.contactName"></auto-complete>
      &nbsp;&nbsp;
      <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'מארח...'" [smallTemplate]="true"
        fxLayout="row" [formGroupModel]="searchTishInvitedsListForm"
        [idControl]="searchTishInvitedsListForm.controls.hostContactID"
        [nameControl]="searchTishInvitedsListForm.controls.hostContactName"></auto-complete>
      &nbsp;&nbsp;
      <mat-select class="form-control small-input" apply-enter formControlName="notEmbedded"
        (selectionChange)="indexService.selectionChanged($event)" placeholder="סטטוס שיבוץ...">
        <mat-option [value]="null">הכל</mat-option>
        <mat-option [value]="false">משובצים</mat-option>
        <mat-option [value]="true">לא-משובצים</mat-option>

      </mat-select>
      &nbsp;&nbsp;
      <mat-select class="form-control small-input" apply-enter formControlName="isPlaces"
        (selectionChange)="indexService.selectionChanged($event)" placeholder="סוג מוזמנים...">
        <mat-option [value]="null">כל המוזמנים</mat-option>
        <mat-option [value]="true">מוזמנים לשיבוץ</mat-option>
        <mat-option [value]="false">מוזמנים לא לשיבוץ</mat-option>

      </mat-select>
      &nbsp;&nbsp;
      <mat-select class="form-control small-input" apply-enter formControlName="doSendletters"
        (selectionChange)="indexService.selectionChanged($event)" placeholder="שליחת מכתבים...">
        <mat-option [value]="null">כולם</mat-option>
        <mat-option [value]="true">נשלחו מכתבים</mat-option>
        <mat-option [value]="false">לא נשלחו מכתבים</mat-option>

      </mat-select>
      &nbsp;&nbsp;
      <mat-select class="form-control small-input" apply-enter formControlName="invitedConfirmeds"
        (selectionChange)="indexService.selectionChanged($event)" placeholder="אישור השתתפות...">
        <mat-option [value]="null">כולם</mat-option>
        <mat-option [value]="true">אישרו השתתפות</mat-option>
        <mat-option [value]="false">לא אישרו השתתפות</mat-option>
      </mat-select>
      &nbsp;&nbsp;
      <div style="text-align:left; white-space: nowrap;" class="example-button-row">
        <button mat-mini-fab class="mini" color="primary" title="סנן" (click)="indexService.getList()">
          <i class="fa fa-filter"></i></button>
        <button mat-mini-fab class="mini" color="primary" title="נקה סינונים" (click)="indexService.clerFilter()">
          <i class="material-icons">clear</i></button>
      </div>
    </div>
  </div>

  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">

          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['tishInviteds', row.tishInvitedID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['tishInviteds', row.tishInvitedID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>
                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="tishInvitedsData" [id]="row.tishInvitedID"
                    [title]="'מידע רשומה'">
                  </model-info>
                </a>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tishInvitedID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה">
              <a [routerLink]="['', {outlets: {popup: ['tishInviteds', row.tishInvitedID]}}]">
                {{ row.tishInvitedID }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tishEventTitle">
            <mat-header-cell *matHeaderCellDef mat-sort-header> אירוע
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="אירוע" style="font-weight: bold;"> {{row.tishEventTitle}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tableName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שולחן </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שולחן">
              {{row.tableName}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="placeNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מקום </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מקום">
              <mat-icon *ngIf="row.isPlace == true" style="color: grey;">event_seat</mat-icon>
              &nbsp; {{row.placeNumber}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tishInvitedName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> שם מוזמן
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שם מוזמן">
              <a [routerLink]="[ '/contacts', row.contactID]">{{row.tishInvitedName }}</a>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="hostContactName">
            <mat-header-cell *matHeaderCellDef> מארח </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מארח">
              <a [routerLink]="[ '/tishInviteds', row.hostInvitedID]">{{row.hostContactName }}</a>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="tishGroupName">
            <mat-header-cell *matHeaderCellDef> קבוצה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="קבוצה">
              {{row.tishGroupName }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="invitedStatus">
            <mat-header-cell *matHeaderCellDef> סטטוס </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="סטטוס">
              {{row.invitedStatus }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ticketName">
            <mat-header-cell *matHeaderCellDef> שם כרטיס </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="שם כרטיס">
              {{row.ticketName}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef> הערה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="הערה"><span>
                {{row.comment}}</span> </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.tishInvitedID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>


  <ng-template #loading>
    <h3 style="text-align: center;">
      טוען נתונים...
    </h3>
  </ng-template>
