import { DateMonthInputModule } from './../dateMonth-input/dateMonth-input.mdl';
import { DateMonthRange } from './dateMonth-range.cmp';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DateMonthInputModule,
    ],
    exports: [
        DateMonthRange
    ],
    declarations: [
        DateMonthRange
    ]
})
export class DateMonthRangeModule { }

