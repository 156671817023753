<div [formGroup]="searchHonorTypesListForm">
  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" style="padding: 4px 10px;border-bottom: thin solid #243747;margin: 0;" class="min-action-header">
    <div class="input-group mb-3">
      <label>תחום...</label>
      <mat-select apply-enter class="form-control" placeholder="תחום..."
        [formControl]="searchHonorTypesListForm.controls.honorDepartmentID">
        <mat-option *ngFor="let ht of servicesData.HonorDepartments" [value]="ht.id">
          {{ht.name}}
        </mat-option>
      </mat-select>
    </div>
    &nbsp;&nbsp;
    <span fxFlex class="example-fill-remaining-space"></span>
    <div fxLayoutAlign="start center" style="text-align:left" class="example-button-row">
      <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
          class="fa fa-filter"></i></button>
      &nbsp;
      <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
          class="material-icons">clear</i></button>
      &nbsp;
      <button mat-mini-fab color="primary" title="הוסף" (click)="addNew()" aria-label="הוסף חדש">
        <i class="material-icons">add</i></button>
    </div>
  </div>
  <div class="example-container" *ngIf="indexService" fxLayout="column" fxFlexFill>
    <div fxFlex>
      <cdk-virtual-scroll-viewport testCDK [full-height]="!contactID" #test itemSize="10"
        [ngStyle]="{'height':  'calc(100vh - '+ actualMinusHeight +'px)'}">
        <mat-table #table style="width: 100%;" *ngIf="indexService?.sourceList; else loading"
          [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
          matSort matSortDisableClear matSortDirection="asc">
          <ng-container matColumnDef="buttons">
            <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'honorTypes', row.honorTypeID]}}]">
                  <mat-icon>pageview</mat-icon>
                  <span>פרטים מלאים</span>
                </a>

                <a mat-menu-item [routerLink]="['', {outlets: {popup: ['honorTypes', row.honorTypeID, 'edit']}}]">
                  <mat-icon>edit</mat-icon>
                  <span>עריכה</span>
                </a>

                <a mat-menu-item>
                  <mat-icon>information</mat-icon>
                  <model-info style="padding: 4px" [dataProvider]="honorTypesData" [id]="row.honorTypeID"
                    [title]="'מידע רשומה'">
                  </model-info>

                </a>

              </mat-menu>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="honorTypeID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="מזהה">
              <a [routerLink]="[ '', {outlets: {popup: [ 'honorTypes', row.honorTypeID]}}]">
                {{ row.honorTypeID }}
              </a>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="honorDepartmentName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>סוג</mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="סוג">
              {{ row.honorDepartmentName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="honorTypeDescription">
            <mat-header-cell *matHeaderCellDef mat-sort-header>תיאור</mat-header-cell>
            <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="תיאור">
              {{ row.honorTypeDescription }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="letterText">
            <mat-header-cell *matHeaderCellDef> טקסט למכתב </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="טקסט למכתב">
              {{row.letterText }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="letterSettingTitle">
            <mat-header-cell *matHeaderCellDef mat-sort-header>תבנית מכתב
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="תבנית מכתב">
              {{row.letterSettingTitle}}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.honorTypeID)">
          </mat-row>
        </mat-table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div fxFlex="15">
      <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
        [length]="indexService.rowsCount" [pageSize]="indexService.pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
