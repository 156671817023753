import { ToraxHttp } from './../../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';
import { ContactAppointment } from './contactAppointment/contactAppointment';

@Injectable()

export class ContactAppointmentsData {

    private url = 'api/appointments';
    constructor(
        private toraxHttp: ToraxHttp,
    ) { }

    getContactAppointments(contactID: number) {
        return this.toraxHttp.get(`${this.url}/getAppointments/${contactID}`);
    }

    get(contactAppointmentID: number) {
        return this.toraxHttp.get(`${this.url}/${contactAppointmentID || 0}`);
    }

    delete(id: number) {
        return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
    }


    getEdit(id: number) {
        return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
    }

    getAdd(contactId: number) {
        return this.toraxHttp.get(`${this.url}/add/${contactId || 0}`);
    }

    update(contactAppointment: ContactAppointment) {
        return this.toraxHttp.update(this.url, contactAppointment);
    }

    setContactAppointmentPriority(contactID: number, prevContactAppointmentID: number, curContactAppointmentID: number, newCurPriority: number) {
        let ret = this.toraxHttp.postWithResponce(`${this.url}/setContactAppointmentPriority?contactID=${contactID}&prevContactAppointmentID=${prevContactAppointmentID}&curContactAppointmentID=${curContactAppointmentID}&newCurPriority=${newCurPriority}`, null);
        // this.onUpdated(contactID);
        return ret;
    }

    // removeDefaultContactAppointmentID(contactID: number) {
    //     let ret = this.toraxHttp.postWithResponce(`${this.url}/removeDefaultContactAppointmentID?ContactID=${contactID}`, null);
    //     this.onUpdated(contactID);
    //     return ret;
    // }

     recIn(id: number) {
      return this.toraxHttp.recIn(this.url, id);
  }

 public updatedEvent = new EventEmitter<number>();

    private onUpdated(id: number): void {
        if (this.updatedEvent) {
            this.updatedEvent.emit(id);
        }
    }
}

