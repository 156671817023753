<form  *ngIf="objectsListResult" [formGroup]="searchCommitmentReasonsListForm" novalidate>
    <panel-box title="סיבות להתחייבויות" [showClose]="false" [showCollapse]="false">
        <span class="panel-tools">
            <panel-box-btn (btnClick)="getCommitmentReasons()" icon="fa-refresh"></panel-box-btn>
            <panel-box-btn (btnClick)="addNew()" icon="fa-plus"></panel-box-btn>
            
        </span>
 

        <div class="panel-content">
            <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>תיאור - סיבה</th>
                        <th>משוייך לנושא</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of objectsListResult.list">
                        <td>
                            <a [routerLink]="['', {outlets: {popup:  ['commitmentReasons', c.commitmentReasonID]}}]">{{ c.commitmentReasonID }}</a>
                        </td>
                        <td>
                            {{ c.reason }}
                        </td>
                        <td>
                            {{ c.commitmentSubjectName }}
                        </td>
                    </tr>


                </tbody>
            </table>
            <pagination [currentPage]="objectsListResult.page" [pagesCount]="objectsListResult.pagesCount"
                (change)="changePage($event)"></pagination>
        </div>
    </panel-box>
</form>