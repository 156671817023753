<div fxLayout="row">
  <span class="example-fill-remaining-space"></span>
  <button (click)="loadData()" class="btn btn-circle" style="margin-left: 15px;" matTooltip="רענן" title="רענן"><i
      class="fa fa-refresh"></i></button>
</div>
<div fxLayout="row">

  <div *ngIf="monthModel; else loading" class="model-section">
    <div fxLayout="column" style="margin: 15px 15px 15px 0px;">
      <div fxLayout="row" fxLayout.xs="column">
        <dl class="dl-horizontal">
          <dt>סה"כ</dt>
          <dd>{{ monthModel.sumTotal | number }}</dd>
          <hr>
          <dt>החזקת ביהמ"ד</dt>
          <dd>{{ monthModel.countPayTickets }}</dd>
          <hr>
          <dt>כרטיסי בונוס</dt>
          <dd>{{ monthModel.countBonusTickets }}</dd>
          <hr>
          <dt>ס"ה משתתפים</dt>
          <dd>
            <span (click)="goToList()">
              <a class="btn btn-success dim" style="margin: 0 !important; padding: 0 !important;">
                לכל הרשימה &nbsp;<i class="fa fa-hand-o-left"></i></a>
              {{ monthModel.totalParticipants }}
            </span>
          </dd>
          <hr>

          <!-- <dt></dt> -->
          <dd> <span>
              <!-- <button mat-raised-button  routerLink="control" [queryParams]="{dialog: true}"> בקרת תשלומים</button> -->

              <a class="btn  btn-success dim" style="margin: 0 !important; padding: 0 !important;"
                (click)="openControlDlg()">
                בקרת תשלומים והתחייבויות &nbsp;<i class="fa fa-external-link fa-rotate-270"></i></a>
            </span></dd>
          <hr>
          <dt>רכישות באתר</dt>
          <dd *ngIf="siteChartModel">
            {{ siteChartModel.value }}
          </dd>
          <hr>
        </dl>
      </div>
    </div>
  </div>
  <mat-card style="width: 100%;margin: 10px;" *ngIf="monthModel; else loading">

    <mat-card-title fxLayout="row" style="background-color: rgb(164, 172, 151);">

      <h1>{{monthModel.sumTotal | number}} <small><i class="fa fa-shekel"></i></small></h1>

      <span class="example-fill-remaining-space"></span>
      <h3 style="text-decoration:underline;">{{monthModel.date | date : 'MMMM YYYY'}}</h3>

    </mat-card-title>
    <div fxLayout="row">
      <div fxFlex="50">

        <mat-card fxLayout="column" fxLayoutAlign="space-between center"
          style="width: 100%; margin-bottom: 5px;border-radius:0" *ngFor="let s of monthModel.subjectTotals">
          <!-- <mat-icon mat-list-icon>folder</mat-icon> -->
          <div fxLayout="row" style="width: 100%;">
            <div fxLayout="column" fxLayoutAlign="center">
              <h2>
                <small style="margin-bottom: 2px">
                  {{s.subjectNane}}
                  <br>
                </small>
                {{s.subjectsSumTotal | number }} <small><i class="fa fa-shekel"></i></small>

              </h2>
            </div>
            <div fxLayout="column" #containerRef fxFlex fxLayoutAlign="center end">
              <!-- <ngx-charts-pie-chart
              [scheme]="colorScheme" [results]="s.statusTotals" [legendPosition]="'below'" [legend]="false"
              [gradient]="false" [tooltipDisabled]="false" [labels]="false">
            </ngx-charts-pie-chart> -->
            </div>
            <div fxLayout="column" fxLayoutAlign="top center">
              <button mat-icon-button disabled aria-label="Example icon-button with a heart icon">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <div fxLayout="row" style="width: 100%" fxFlexFill>
            <div *ngIf="s.succeeded" [fxFlex]="s.succeeded" [title]="s.succeededAmount" style="background-color: green;height: 3px">
              <!-- {{(console.log(s.customStatusses))}} -->
           </div>
          <div *ngIf="s.waiting" [fxFlex]="s.waiting" [title]="s.waitingAmount" style="background-color: goldenrod;height: 3px">

          </div>
          <div *ngIf="s.failed" [fxFlex]="s.failed" [title]="s.failedAmount" style="background-color: red;height: 3px">

          </div>

            <!-- <mat-progress-bar style="width: 100%;" [value]="0.5">
            </mat-progress-bar> -->

          </div>

        </mat-card>
        <!-- <hr> -->


      </div>
      <div fxFlex="50" #containerRef>
        <!-- <ngx-charts-pie-chart [view]="[containerRef.offsetWidth, containerRef.offsetHeight]" [scheme]="colorScheme"
          [results]="pieChartSourse" [legendPosition]="'below'" [legend]="false" [gradient]="false"
          [tooltipDisabled]="false" [labels]="false">
        </ngx-charts-pie-chart>  -->
      </div>
    </div>

  </mat-card>
</div>

<ng-template #loading>
  <h3 *ngIf="selectedCityName" style="text-align: center;">
    טוען נתונים
    <i style="color:royalblue" class="fa fa-refresh fa-spin"></i>
  </h3>
</ng-template>
