import { MatTabGroup } from '@angular/material/tabs';
import { Subscription, interval } from 'rxjs';
import { Location } from '@angular/common';
import { DashboardChartsDataService } from './dashboard-charts.data';
import { ChartsChartModel } from './dashboard-charts-chart-model';
import { AfterViewInit, Component, OnInit, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { CLOSE_SQUARE_BRACKET } from '@angular/cdk/keycodes';

declare const Hebcal: any;


@Component({
  selector: 'dashboard-charts-tabs',
  templateUrl: 'dashboard-charts-tabs.component.html',
  styleUrls: ['dashboard-charts.component.scss']
})
export class DashboardChartsTabsComponent implements OnInit {

  constructor(
    private cdref: ChangeDetectorRef,
    private location: Location,
    private dashboardChartsDataService: DashboardChartsDataService
  ) {
  }
  @ViewChild('tabs', { static: true }) tabs: MatTabGroup;

  grp;
  activeTabIndex;
  perfix = "tabs_";
  grpSums;





  public tabSelect(event) {
    this.activeTabIndex = event.index;
    this.selectGrp();

    sessionStorage.setItem(`${this.pathStr}`, `${this.activeTabIndex}`);
    this.cdref.detectChanges();
  }

  selectGrp() {
    switch (this.activeTabIndex) {
      case (0):
        this.grp = "ישראל";
        break;
      case (1):
        this.grp = "ארהב";
        break;
      case (2):
        this.grp = "אנגליה";
        break;
      case (3):
        this.grp = "בלגיה";
        break;
      case (4):
        this.grp = "שוויץ";
        break;
      default:
        break;
    }
  }

  get pathStr() {
    return `${this.perfix}${this.location.path()}`;
  }


  loadGrpSums() {
    this.dashboardChartsDataService.getGrpSums()
      .subscribe((x) => {
        this.grpSums = x;
      });
  }

  ngOnInit() {
    this.activeTabIndex = sessionStorage.getItem(`${this.pathStr}`) || 0;
    this.selectGrp();
    this.loadGrpSums();
  }
}