import { ShtibelsList } from './shtibels-list.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router'; 
import { ShtibelDetails } from './shtibel/shtibel-details.cmp'; 
import { ShtibelMain } from './shtibel/shtibel-main.cmp';

export const ShtibelsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'shtibels', component: ShtibelsList, data: {  roles: "shtibels",  title: 'רשימת שטיבלך' } },
    { canActivate: [AuthGuard], path: 'shtibels/add', component: ShtibelMain, data: { mode: 'edit', roles: "shtibels", title: 'הוסף שטיבל' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'shtibels/:shtibelID', component: ShtibelMain, data: {  roles: "shtibels",  title: 'פרטי שטיבל' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'shtibels/:shtibelID/edit', component: ShtibelMain, data: { mode: 'edit', roles: "shtibels",  title: 'עריכת פרטי שטיבל' }, outlet: 'popup' }
]; 