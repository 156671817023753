import { ServicesData } from './../../services/services.data';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup,  FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common'; 
import { VouchersOrganization } from './vouchersOrganization';
import { VouchersOrganizationsData } from '../vouchersOrganizations.data';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';

export class PaymentOption { }
export class Api { }



@Component({

    selector: 'vouchersOrganization-edit',
    templateUrl: './vouchersOrganization-edit.tmpl.html'
})

export class VouchersOrganizationEdit implements OnInit {
    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private vouchersOrganizationsData: VouchersOrganizationsData,
        private formBuilder: FormBuilder,
        private location: Location,
        public servicesData: ServicesData
    ) {

    }

    @ViewChild('bankBranchesCmp') bankBranchesCmp: AutoCompleteComponent;

    vouchersOrganization: VouchersOrganization;
    id: number;
    contactId: number;
    vouchersOrganizationForm: FormGroup;



    public load(): void {
        if (this.id > 0) {
            this.vouchersOrganizationsData.getEdit(this.id)
                .subscribe((vo: any) => {
                    this.vouchersOrganization = vo;
                    this.fillForm(vo);
                });
        } else {
            this.vouchersOrganizationsData.getAdd(this.contactId)
                .subscribe((vo: any) => {
                    this.vouchersOrganization = vo;
                    this.fillForm(vo);
                });
        }
    }

    private fillForm(vo: VouchersOrganization): void {
        this.vouchersOrganizationForm.patchValue(vo);
    }



    private initForm() {
        this.vouchersOrganizationForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            vouchersOrganizationID: [this.id, Validators.required],
            organizationName: [, Validators.required],
            contactID: [, Validators.required],
            contactName: []
        });
    }

    ngOnInit() {
        this.activatedRoute.params.forEach((params: Params) => {
            this.id = +params['vouchersOrganizationID'];
        });
        this.initForm();
        this.load();
    }
}





