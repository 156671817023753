 <div class="col-xs-12 col-xl-6" style="outline: none;" >
    <div class="mortgages-container" style="outline: none;" [ngStyle]="{'border-right-color': group.groupColor}">
      <div fxLayout="column" fxFlexFill *ngIf="data; else loading">

        <div fxLayout="row">

          <div>
            <h1 [ngStyle]="{'border-color': data.groupColor}"
              style="font-weight: bold; font-size: 200%; padding: 10px 0 10px 20px; margin: 0; width: auto; ">
              {{data.groupName}}
              <br>
              <small style="font-size: 50%;">({{data.joiningAmount | number:'1.0-0'}}$)</small>
            </h1>
          </div>
          <div fxFlex>

          </div>
          <div fxLayout="column" style="margin-left: 10px; font-size: 22px; line-height: 38px">
            <div *ngIf="data.totalPaid" fxLayout="row" style="border-bottom: solid; border-color: #d2af56; border-width: 2px;">
              <span style="font-weight: 700;">שולם עד כעת:&nbsp;&nbsp;&nbsp;</span>
              <span class="example-fill-remaining-space"></span>
              <span style="color: green; font-weight: bold;">
                {{data.totalPaid | number:'1.0-0'}}$
              </span>
            </div>
              <div *ngIf="data.destinationGroupAmount" fxLayout="row" style="border-bottom: solid; border-color: #d2af56; border-width: 2px;">
              <span style="font-weight: 700;">יעד:&nbsp;&nbsp;&nbsp;</span>
              <span class="example-fill-remaining-space"></span>
              <span>
                {{data.destinationGroupAmount | number:'1.0-0'}}$
              </span>
            </div>
            <div *ngIf="data.sumMoney" fxLayout="row" style="border-bottom: solid; border-color: #d2af56; border-width: 2px;">
              <span style="font-weight: 700;">סכום כולל:&nbsp;&nbsp;&nbsp;</span>
              <span class="example-fill-remaining-space"></span>
              <span >
                {{data.sumMoney | number:'1.0-0'}}$
              </span>
            </div>

          </div>
        </div>


        <mat-chip-list fxLayout="row" style="padding-top:15px">
          <mat-chip style="white-space: nowrap;" fxLayout="row" *ngFor="let stts of data.viwGroupStatusSums">
            <dt>{{stts.status}}&nbsp;|&nbsp;</dt>
            <dd style="margin-right: 0px !important; margin-left: 0px !important;">
              <span *ngIf="stts.sumMoney > 0">{{ (stts.sumMoney > 0 ? stts.sumMoney : '') | number:'1.0-0'
                }}$&nbsp;&nbsp;&nbsp;</span>
              <span *ngIf="data.groupName.indexOf('פוטנציאל') > -1">עבור לרשימה&nbsp;&nbsp;&nbsp;</span>
              <span *ngIf="data.groupName.indexOf('לא רלוונטי') > -1">עבור לרשימה&nbsp;&nbsp;&nbsp;</span>
              <a [routerLink]="['/contactsGroups',   stts.groupID,   stts.contactGroupStatusID ]">
                <i style="color:blue" class="fa fa-chevron-circle-left"></i>
              </a>
            </dd>
            <hr>
          </mat-chip>
        </mat-chip-list>
      </div>


  </div>
  <ng-template #loading>
    <h3 style="text-align: center;">
      <div class="bar-circles">
        <div class="primary" style="border: solid blue 1.5px;"></div>
        <div class="primary" style="border: solid blue 1.5px;"></div>
        <div class="primary" style="border: solid blue 1.5px;"></div>
      </div>
    </h3>
  </ng-template>
</div>
