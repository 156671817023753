import { PaymentsPlanMain } from './paymentsPlan/paymentsPlan-main.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { PaymentsPlansList } from './paymentsPlans-list.cmp';
import { Routes } from '@angular/router';
import { PaymentMain } from '../payments/payment/payment-main.cmp';


export const PaymentsPlansRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'paymentsPlans', component: PaymentsPlansList, data: {  roles: "paymentsPlans",  title: 'תוכניות תשלום' } },
    { canActivate: [AuthGuard], path: 'paymentsPlans/add', component: PaymentsPlanMain, data: { mode: 'edit', roles: "paymentsPlans", title: 'הוסף תוכנית תשלום' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'paymentsPlans/:paymentsPlanID', component: PaymentsPlanMain, data: {  roles: "paymentsPlans",  title: 'פרטי תוכנית תשלום' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'paymentsPlans/:paymentsPlanID/edit', component: PaymentsPlanMain, data: { mode: 'edit', roles: "paymentsPlans",  title: 'עריכת פרטי תוכנית תשלום' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'paymentsPlans/:paymentsPlanID/addCopy', component: PaymentsPlanMain, data: { mode: 'edit', roles: "paymentsPlans",  title: 'העתקת תוכנית תשלום' }, outlet: 'popup' },


  { canActivate: [AuthGuard], path: 'paymentsPlans/:paymentsPlanID/payments/add', component: PaymentMain, data: { mode: "edit", roles: "payments", title: 'הוסף תרומה/תשלום' }, outlet: 'popup' },

];
