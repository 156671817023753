import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';


import { TasksData } from './tasks.data';

import { ServicesData } from '../services/services.data';
import { ObjectsListResult } from '../services/objects-list-results';
import { ToraxHttp } from '../utils/custom-http.svc';
import { Router } from '@angular/router';

@Component({
	selector: 'tasks-list',
	templateUrl: './tasks-list.tmpl.html'
})

export class TasksList implements OnInit {
	constructor(
		private tasksData: TasksData,
		private formBuilder: FormBuilder,
		private toraxHttp: ToraxHttp,
		public router: Router
	) {
		// this.toraxHttp.updatedEvent.subscribe(() => {
		// 	this.search();
		// });
	}

	public objectsListResult: ObjectsListResult;
	searchTasksListForm: FormGroup;

	@Input('contactID') public contactID?: number | null = null;

	public getTasks(): void {
		if (this.contactID && this.contactID > 0) {
			this.searchTasksListForm.controls['contactID'].setValue(this.contactID);
		}
		
		this.tasksData.getList(this.searchTasksListForm.value).subscribe(
			(r:any) => {
				this.objectsListResult = r;
			},
			() => { });
	}

	public changePage(page: number) {
		this.searchTasksListForm.controls['page'].setValue(page);
		this.search();
	}

	public search() {
		if (this.contactID == null) {
			sessionStorage.setItem(this.router.url, JSON.stringify(this.searchTasksListForm.value));
		}
		this.getTasks();
	}


    public printReceiptHtml(taskId: number): void {
        this.tasksData.printReceiptHtml(taskId)
            .subscribe(data => {
                let popupWinindow: any = window.open('', '_blank', 'scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no, onload="window.print()"');
                popupWinindow.document.open();
                popupWinindow.document.write('<html><head><style type="text/css"> tr.tasks:nth-child(even){background-color: #f2f2f2} @page { size: A4; margin: 0; } @media print { html, body { width: 210mm; height: 297mm; } .page { margin: 0; border: initial; border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; page-break-inside: avoid; } } </style></head><body  onload="window.print()"><div>' + (<any>data).value + '</div></body></html>');
                popupWinindow.document.close();
            });
    }

	private initForm() {
		this.searchTasksListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			contactID: [],
			contactName: []
		});
	}


	ngOnInit(): void {
		this.initForm();
		if (this.contactID == null) {
			let item = sessionStorage.getItem(this.router.url);
			if (item) {
				let frm = JSON.parse(sessionStorage.getItem(this.tasksData.url) || "");
				this.searchTasksListForm.patchValue(frm);
			}
		}
		this.getTasks();
	}
}