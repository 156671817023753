
import { BrowserModule } from '@angular/platform-browser';

import { ScreenComponent  } from './screen.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../material.module';
import { CampaignsData } from '../campaign/campaigns.data';
import { CampaignAttachedsData } from '../campaign-attached/campaign-attacheds.data';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		MaterialModule,
	],
	declarations: [
		ScreenComponent,
	],
	exports:[ScreenComponent],
	providers: [CampaignsData,CampaignAttachedsData]
})
export class ScreenModule { }

