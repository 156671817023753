import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IndexService } from '../services/index-service';
import { ReceiptsData } from './receipts.data';


@Component({
	selector: 'receipts-list',
	templateUrl: 'receipts-list.tmpl.html'
})

export class ReceiptsList implements OnInit {
	constructor(
		public router: Router,
		public receiptsData: ReceiptsData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		public _matDialog: MatDialog,
	) {
	}

	indexService: IndexService;
	 displayedColumns = ['buttons',   'receiptNumber', 'receiptCreatedDate', 'datePrint'];



	@Input('contactID') public contactID?: number | null = null;
	@Input('paymentID') public paymentID?: number | null = null;
	@Input('paymentsPlanID') public paymentsPlanID?: number | null = null;
	@Input('commitmentID') public commitmentID?: number | null = null;




	@Output() selectedRowsChange = new EventEmitter<any>();
	public selection = new SelectionModel<number>(true, []);

	searchReceiptsListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;


	isChecked(row: any): boolean {
		const found = this.selection.selected.find((el: any) => el.receiptID === row.receiptID);
		if (found) {
			return true;
		}
		return false;
	}

	private initForm() {
		this.searchReceiptsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [30],
			query: [],
			contactID: [],
			selectedRows: [],
			contactName: [],
			commitmentID: [],
			paymentsPlanID: [],
			paymentID: [],
			orders: this.formBuilder.array([])
		});

		this.selection.changed.subscribe(x => {
			this.searchReceiptsListForm.controls['selectedRows'].setValue(x.source.selected.map((x: any) => x.receiptID));
			this.selectedRowsChange.emit(x.source.selected);
		});


		let frm = this.searchReceiptsListForm;
		this.defaultvalues = Object.keys(this.searchReceiptsListForm.value).map(function (k) {
			let x = frm.controls[k];
			if (x.validator == Validators.required) {
				 k = x.value;
			}
		});

	}

	defaultvalues: any[];
	selectedItems;

	ngOnInit() {
		this.initForm();

	   if (this.contactID == null && this.commitmentID == null) {

				let item = sessionStorage.getItem(this.router.url);
				if (item) {
					let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
					this.searchReceiptsListForm.patchValue(frm);
				}
			}


		let params = [
			{ key: "contactID", value: this.contactID },
			{ key: "paymentID", value: this.paymentID },
			{ key: "paymentsPlanID", value: this.paymentsPlanID },
			{ key: "commitmentID", value: this.commitmentID },
				];

		this.indexService = new IndexService(this.searchReceiptsListForm, params, this.receiptsData, this.router, this.defaultvalues);

	}

}

