import { MaterialModule } from './../../material.module';

import { CommonModule } from '@angular/common';
import {  NgModule } from '@angular/core';
import { MainFormModal } from './main-form-modal.cmp';
import { PreviousRouteService } from 'src/app/_helpers/previous-route.service';

@NgModule({
    imports: [CommonModule,MaterialModule],
    exports: [MainFormModal],
    providers:[PreviousRouteService],
    declarations: [MainFormModal],
})
export class MainFormModalModule { }

