import { Message, SgnlContent } from './signalr-models';
import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { last } from 'rxjs/operators';
import { AlertService } from '../alert/alert.svc';

@Injectable({
  providedIn: 'root'
})
export class SignalMainRService {
  private message$: Subject<SgnlContent>;
  private connection: signalR.HubConnection;
  public connectionId: string;

  constructor(private alertSvc: AlertService,
  ) {
    this.message$ = new Subject<SgnlContent>();
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/notify`)
      .build();
    this.connect();
  }

  private connect() {

    this.connection.start()
      .then(() => console.log('Connection started'))
      //.then(() => this.getConnectionId())
      .catch(err => console.log('Error while starting connection: ' + err));

    this.connection.on("model", x => {
      let content = new SgnlContent("model", x);
      this.message$.next(content);
    });
    this.connection.on("message", (x: any) => {
      let content = new SgnlContent("message", x);
      //this.alertSvc.info(x.messageText);
      this.message$.next(content);
    });
  }

  public getConnectionId = () => {
    this.connection.invoke('getconnectionid').then(
      (data) => {
        console.log(data);
        this.connectionId = data;
      }
    );
  }

  public getMessage(): Observable<any> {
    return this.message$;
  }

  public disconnect() {
    console.log("!! disconnect !! !! disconnect !! !! disconnect !! !! disconnect !! !! disconnect !! !! disconnect !! ");
    this.connection.stop();
  }
}
