<form [formGroup]="vouchersOrgPaymentPackForm" novalidate (ngSubmit)="save()" *ngIf="vouchersOrgPaymentPack">
  <div class="modal-header">
    <h1>עריכת פרטי תקבול [{{ (id > 0 ? id : 'חדש') }}] </h1>
  </div>
  <div class="modal-body">
    <panel-box title="פרטי ארגון" [showClose]="false" [showCollapse]="false">
      <span class="panel-tools"></span>
      <div class="panel-content">
        <div class="row">
          <div class="col-sm-6 form-horizontal">
            <div class="form-group">
              <label class="control-label col-sm-2">שם ארגון:</label>
              <div class="col-sm-10">
                <h3> {{ vouchersOrgPaymentPack.organizationName }}</h3>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2">סכום:</label>
              <div class="col-sm-10 form-inline">
                <input type="text" class="form-control" placeholder="סכום..." formControlName="amount" />
                <small *ngIf="!vouchersOrgPaymentPackForm.controls.amount.valid" class="text-danger">*</small>
                <select class="form-control" formControlName="currency">
                  <option value="">...</option>
                  <option *ngFor="let c of servicesData.Currencies" [value]="c.value">
                    {{ c.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2" for="accountLocation">עבור חשבון:</label>
              <div class="col-sm-10 form-inline">
                <select class="form-control" formControlName="accountLocation">
                  <option value="">...</option>
                  <option *ngFor="let al of servicesData.AccountLocations" [value]="al.value">
                    {{al.name}}
                  </option>
                </select>
                <small *ngIf="!vouchersOrgPaymentPackForm.controls.accountLocation.valid" class="text-danger">*</small>
                <h3 class="label">בחירת עמותה הכרחית לצורך הנפקת קבלה מתאימה</h3>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2" for="billingDate">תאריך תשלום:</label>
              <div class="col-sm-10">
                <date-input [dateControl]="vouchersOrgPaymentPackForm.controls.billingDate">Loading...
                </date-input>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2" for="bankNumber">בנק:</label>
              <div class="col-sm-10">
                <auto-complete apply-enter [controllerName]="'Banks'" (onSelect)="itemSelect($event)"
                  [formGroupModel]="vouchersOrgPaymentPackForm"
                  [idControl]="vouchersOrgPaymentPackForm.controls.bankNumber"
                  [nameControl]="vouchersOrgPaymentPackForm.controls.bankName"></auto-complete>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2" for="bankBranchNumber">סניף:</label>
              <div class="col-sm-10">
                <auto-complete #bankBranchesCmp [controllerName]="'BankBranches'"
                  [formGroupModel]="vouchersOrgPaymentPackForm"
                  [idControl]="vouchersOrgPaymentPackForm.controls.bankBranchNumber"
                  [nameControl]="vouchersOrgPaymentPackForm.controls.bankBranchName"></auto-complete>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2" for="bankAcountNumber">מס' חשבון:</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" formControlName="bankAcountNumber" />
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2" for="chicNumber">מס' שיק:</label>
              <div class="col-sm-10">
                <input type="number" class="form-control" formControlName="chicNumber" />
                <small *ngIf="!vouchersOrgPaymentPackForm.controls.chicNumber.valid" class="text-danger">*</small>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2" for="nameOfChic">שם שיק:</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" formControlName="nameOfChic" />
                <small *ngIf="!vouchersOrgPaymentPackForm.controls.nameOfChic.valid" class="text-danger">*</small>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2" for="transferReference">מס' אסמכתא:</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" formControlName="transferReference" />
                <small *ngIf="!vouchersOrgPaymentPackForm.controls.transferReference.valid"
                  class="text-danger">*</small>
                <h3 class="label">בהעברה בנקאית יש להכניס מס' אסמכתא המופיע בחשבון העמותה</h3>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-2" for="transferReference">מס' חשבון בעמותה:</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" formControlName="associationAccountNumber" />
                <small *ngIf="!vouchersOrgPaymentPackForm.controls.associationAccountNumber.valid"
                  class="text-danger">*</small>

              </div>
            </div>

            <div class="form-group">
              <label class="control-label col-sm-2" for="statusPayment">סטטוס:</label>
              <div class="col-sm-10">
                <select class="form-control" formControlName="statusPayment">
                  <option value="">...</option>
                  <option *ngFor="let sp of servicesData.StatusPayments" [value]="sp.value">
                    {{ sp.name }}
                  </option>
                </select>
                <small *ngIf="!vouchersOrgPaymentPackForm.controls.statusPayment.valid" class="text-danger">*</small>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <textarea rows="4" class="comment" placeholder="הערה..." formControlName="comment"></textarea>
              </div>
            </div>
          </div>
          <div *ngIf="vouchersOrgPaymentPack" fxFlex="100" fxLayout="column">
            <button (click)="selectAll()">בחר הכל
            </button>
            <button (click)="unSelectAll()">הסר הכל
            </button>
            <button (click)="refresh()">בטל
            </button>

            <div fxLayout="row" class="example-margin" *ngIf="vouchersOrgPaymentPack.voucherPaymentsVM">
              <mat-selection-list #selectionList formControlName="VoucherPaymentIDs" multiple>
                <mat-list-option *ngFor="let p of vouchersOrgPaymentPack.voucherPaymentsVM" [value]="p.paymentID"
                  [selected]='p.selected'>
                  {{ p.contactName }}<span class="tab"></span>{{ p.payMethodTitle }}<span class="tab"></span>#{{
                  p.voucherNumber }}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
          <!-- <div class="col-sm-6" formArrayName="voucherPaymentsVM">


                 <div [formGroupName]="i" class="well" style="padding: 4px !IMPORTANT;" *ngFor="let payment of vouchersOrgPaymentPackForm.controls.voucherPaymentsVM.controls; let i=index">
                            <div>
                                <div class="col-sm-3" style="text-align: left;">
                                    <a *ngIf="id == 0" (click)="removePayment(i)">
                                        <i class="fa fa-trash-o"></i>
                                    </a>&nbsp;
                                    <a *ngIf="vouchersOrgPaymentPackForm.controls.voucherPaymentsVM.controls[i].controls.paymentID.value == null" (click)="addprop(payment)">
                                        <i class="fa fa-check-circle-o"></i>
                                    </a>
                                    <a *ngIf="vouchersOrgPaymentPackForm.controls.voucherPaymentsVM.controls[i].controls.paymentID.value === 0 || vouchersOrgPaymentPackForm.controls.voucherPaymentsVM.controls[i].controls.paymentID.value > 0" (click)="addprop(payment)">
                                        <i class="fa fa-circle-o"></i>
                                    </a> &nbsp;
                                </div>
                                <div class="control-group">
                                    <label>
                                        [{{payment.voucherNumber}}] {{payment.amount | number}}{{payment.currency | currency}} {{payment.contactName}} {{payment.payMethodTitle}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </panel-box>
  </div>
  <footer-edit-buttons [onSaveGoToBack]="true" [dataProvider]="vouchersOrgPaymentPacksData" [router]="router" [id]="id"
    [disabledDeleteButton]="false" [modelFormGroup]="vouchersOrgPaymentPackForm"></footer-edit-buttons>
</form>
