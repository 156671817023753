import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhonesData } from '../phones.data';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { Phone } from './phone';

@Component({
    selector: 'phone-edit-dlg',
    templateUrl: './phone-edit-dlg.tmpl.html'
})

export class PhoneEditDlg implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<PhoneEditDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private phonesData: PhonesData,
        private formBuilder: FormBuilder
    ) {

    }
    @Input('id') public id: number;
    communicationType;
    phone: Phone;
     contactId: number;

    phoneForm: FormGroup;

    public load(): void {
        if (this.id > 0) {
            this.phonesData.getEdit(this.id || 0)
                .subscribe((p: any) => {
                    this.phone = p;
                    this.fillForm(p);
                });
        } else if(this.phone){
            this.phonesData.getAdd(this.phone.contactID, this.phone.communicationType)
                .subscribe((p: any) => {
                    this.phone = p;
                    this.fillForm(this.phone);
                });
        }
    }

    private fillForm(p: Phone): void {
        this.phoneForm.patchValue(p);
    }

    setInactiveDate() {
        this.phoneForm.controls['inactiveDate'].setValue(new Date());
        this.phoneForm.controls['isActive'].setValue(false);
    }

    clearInactiveDate() {
        this.phone.inactiveDate = null;
        this.phone.isActive = true;
        this.phoneForm.controls['inactiveDate'].setValue(this.phone.inactiveDate);
        this.phoneForm.controls['isActive'].setValue( this.phone.isActive);
    }

    // updateInactiveDate(e:boolean) {
    //     if (e) {
    //         this.phoneForm.controls['inactiveDate'].setValue(null);
    //     } else {
    //         this.phoneForm.controls['inactiveDate'].setValue(new Date());

    //     }
    // }
    private initForm() {
        this.phoneForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            phoneID: [this.id, Validators.required],
            contactID: [this.contactId, Validators.required],
            number: [, Validators.required],
            type: [, Validators.required],
            priority: [1, Validators.required],
            private: [false, Validators.required],
            notToAlfon: [false, Validators.required],
            inactiveDate: [],
            isActive: [true, Validators.required],
            comment: [],
            noLtzintok: [false, Validators.required],
        });
           //this.phoneForm.controls['isActive'].valueChanges.subscribe(x => this.updateInactiveDate(x));
  
    }


    ngOnInit() {
        // this.activatedRoute.params.forEach((params: Params) => {
        //     this.id = +params['phoneID'] || 0;
        //     this.contactId = +params['contactID'];
        // });
        this.id = this.data.phone.phoneID;
        this.contactId = this.data.phone.contactID;
        this.phone = this.data.phone;
        this.initForm();
        this.load();
      }
}



