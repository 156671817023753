import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HoldingsData } from './holdings.data';
import { ServicesData } from 'src/app/services/services.data';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Input, AfterViewInit, Inject } from '@angular/core';
import { Holding } from './holding/holding';

@Component({
  selector: 'holdings-payment-edit-dlg',
  templateUrl: './holdings-payment-edit-dlg.tmpl.html',
  // styleUrls: ['./../../payments.scss']
})


export class HoldingsPaymentEditDlg implements OnInit {
  constructor(
    private holdingsData: HoldingsData,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<HoldingsPaymentEditDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }


  sourceList;
  holdingsPcForm: FormGroup; //טופס עדכון
  isLoadingResults: boolean = false;
  //סכום לחישוב ולידציה
  actualSum: number = 0;
  actualPymentSum: number = 0;
  balance: number = 0;
  actualCurrency;
  holdingContactID;
  holdingContactName;
  private getHoldings() {

    this.isLoadingResults = true;

    return this.holdingsData!.getHoldingsPaymentToEdit(this.data.paymentCommitmentID)
      .subscribe((x: any) => {
        if (x) {
          this.sourceList = x.holdings;
          this.actualSum = x.actualSum;
          this.actualPymentSum = x.actualPymentSum;
          this.actualCurrency = x.actualCurrency;
          this.holdingContactID = x.holdingContactID;
          this.holdingContactName = x.holdingContactName;

          const holdingsPayments = <FormArray>this.holdingsPcForm.controls['holdings'];
          this.sourceList.forEach(holding => {
            let hForm = this.initHoldingPcRowForm(holding);
            holdingsPayments.push(hForm);
          });
        }
        this.isLoadingResults = false;
      });
  }

  unequalAmount: boolean = true;
  holdingsPcFormFormValidation = c => {
    this.unequalAmount = true;
    let sumAmount = 0;
    const holdingsControl = this.getHoldingsControls;
    holdingsControl.controls
      .filter((ctrl: FormGroup) => !ctrl.controls['isDeleted'].value)
      .forEach((x: FormGroup) => {
        sumAmount += parseFloat(x.controls['amount'].value || 0);
      });


    this.balance = (this.actualPymentSum - sumAmount);

    if (holdingsControl.length > 0 && (this.actualPymentSum !== sumAmount)) {
      this.unequalAmount = false;
      return { required: true };
    }

    if (holdingsControl.length === 0) {
      return { required: true };
    }


    return null;
  };



  initHoldingPcRowForm(h: any) {
    let holdingPcRowForm = this.formBuilder.group({
      holdingID: [h.holdingID | 0, Validators.required],
      paymentCommitmentID: [h.paymentCommitmentID | 0, Validators.required],
      year: [h.year, Validators.required],
      month: [h.month, Validators.required],
      amount: [h.amount, Validators.required],
      currency: [h.currency, Validators.required],
      holdingContactID: [h.holdingContactID],
      holdingContactName: [h.holdingContactName],
      isDeleted: [false, Validators.required],
    });
    holdingPcRowForm.controls.isDeleted.valueChanges.subscribe(x => {
      if (x && x == true) {
        holdingPcRowForm.controls.year.setValue(0);
        holdingPcRowForm.controls.month.setValue(0);
        holdingPcRowForm.controls.amount.setValue(0);
        holdingPcRowForm.controls.currency.setValue(0)
      }
    });
    return holdingPcRowForm;
  }


  private initForm() {
    this.holdingsPcForm = this.formBuilder.group({
      holdings: this.formBuilder.array([])
    });
    this.holdingsPcForm.setValidators(this.holdingsPcFormFormValidation);
  }


  get getHoldingsControls() {
    let control = this.holdingsPcForm.get('holdings') as FormArray;
    return control;
  }
  isLapYear(jewishYear: number) {
    if ((((jewishYear * 7) + 1) % 19) < 7) {
      return true;
    }
    else {
      return false;
    }
    //this.cdref.detectChanges();
  }
  addRow() {
    let holdings = this.holdingsPcForm.get('holdings') as FormArray;
    let lastRec = holdings.value.pop();

    var h = new Holding();

    if (lastRec?.year && lastRec?.month) {
      if (!this.isLapYear(lastRec.year) && lastRec?.month == 12) { //שנה פשוטה
        h.year = lastRec.year + 1;
        h.month = '1';
      } else if (this.isLapYear(lastRec.year) && lastRec?.month == 13) { //שנה מעוברת
        h.year = lastRec.year + 1;
        h.month = '1';
      } else { //שאר חודשי השנים
        h.year = lastRec.year;
        h.month = lastRec.month + 1;
      }
      h.amount = lastRec?.amount;
      h.holdingContactID = lastRec?.holdingContactID;
      h.holdingContactName = lastRec?.holdingContactName;
      h.contactID = lastRec?.contactID;
    }
    h.currency = lastRec?.currency ?? this.actualCurrency;;
    h.paymentCommitmentID = this.data.paymentCommitmentID;
    // h.currency = this.actualCurrency;
    holdings.push(this.initHoldingPcRowForm(h));
  }

  deleteRow(index: number) {
    let holdings = this.holdingsPcForm.get('holdings') as FormArray;
    let form = (<FormGroup>holdings.controls[index]);

    form.disable();
    form.controls["isDeleted"].setValue(true);
  }
  cancelDeleteRow(index: number) {
    let holdings = this.holdingsPcForm.get('holdings') as FormArray;
    let form = (<FormGroup>holdings.controls[index]);
    form.enable();
    form.controls["isDeleted"].setValue(false);
  }

  ngOnInit(): void {
    this.initForm();
    this.getHoldings();
  }
}
