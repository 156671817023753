
<main-form-modal (toggleChange)="setVal($event)">
    <ng-template #customHtmlElements>
        <div [ngSwitch]="editMode">

            <paymentsPlan-edit *ngSwitchCase="true"></paymentsPlan-edit>
            <paymentsPlan-details (onCopy)="changeMode($event)" *ngSwitchCase="false"></paymentsPlan-details>
        </div>
    </ng-template>
</main-form-modal>
