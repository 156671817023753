﻿<form [formGroup]="payMethodForm" novalidate (ngSubmit)="testCcAndCreateToken()" *ngIf="payMethod">
  <div class="modal-header">
    <div class="row">
      <div style="padding: 0px;" class="col-sm-4">
        <a [routerLink]="[ '', {outlets: {popup: [ 'contacts', payMethod.contactID]}}]">
          <h2>{{ payMethod.contactName }}</h2>
        </a>
      </div>
      <div class="col-sm-8 text-left">
        <i *ngIf="payMethod.token" class="fa fa-check-circle-o" style="color: green;"></i>
        <a class="btn btn-info" (click)="testCcAndCreateToken()">
          <i class="fa fa-credit-card"></i>&nbsp;בדיקת תקינות</a>

      </div>
    </div>
  </div>
  <div class="modal-body" style="resize: both; overflow: auto;" fxLayout="row" fxLayoutAlign="start start"
    fxLayoutGap="15px">
    <div fxLayout="column" fxLayout.xs="row wrap">
      <mat-card class="example-card" style="width:100%;height: 100%;" fxLayoutAlign="start stretch"
      fxLayout="column" fxFlex="100">
      <mat-card-header>
        <mat-card-title fxLayout="row">
            <h3>פרטי אמצעי תשלום [{{ payMethod.payMethodID }}]</h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%; white-space: nowrap;">
          <dl class="dl-horizontal">
            <dt>אמצעי תשלום</dt>
            <dd>{{ payMethod.methodTitle }}</dd>
            <hr>
            <dt>מסוף <b>ברירת מחדל</b></dt>
            <dd>{{ payMethod.termName }}</dd>
            <hr>
            <dt>שמור לשימושים חוזרים</dt>
            <dd>
              <i *ngIf="!payMethod.isOneTime" style="color:green" class="fa fa-check-square"></i>
              <i *ngIf="payMethod.isOneTime" style="color:red" class="fa fa-window-close-o"></i>
            </dd>
            <hr>
            <dt *ngIf="payMethod.amountLimit">הגבלת סכום (בהו"ק בנקאית)</dt>
            <dd *ngIf="payMethod.amountLimit" style="color:red"><b>{{ payMethod.amountLimit | number}}</b></dd>
            <hr *ngIf="payMethod.amountLimit">
            <dt *ngIf="payMethod.dateLimit">הגבלת תאריך (בהו"ק בנקאית)</dt>
            <dd *ngIf="payMethod.dateLimit" style="color:red"><b>{{ payMethod.dateLimit  | date:'dd/MM/yyyy' }}</b></dd>
            <hr *ngIf="payMethod.dateLimit">
            <dt *ngIf="payMethod.inactiveDate">תאריך סגירה</dt>
            <dd *ngIf="payMethod.inactiveDate" style="color:red"><b> {{ payMethod.inactiveDate  | date:'dd/MM/yyyy' }} <br>{{ payMethod.jewishInactiveDate }}</b></dd>
            <hr *ngIf="payMethod.inactiveDate">
            <dt>הערה</dt>
            <dd class="comment">{{ payMethod.comment }}</dd>
            <hr>
          </dl>
        </mat-card-content>
      </mat-card>
      <mat-card style="overflow: auto;" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3>פרטים נוספים (נדרש בלונדון)</h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="padding: 0 !important;">
          <dl class="dl-horizontal">
            <dt>שם פרטי</dt>
            <dd>{{ payMethod.firstName }}</dd>
            <hr>
            <dt>שם משפחה</dt>
            <dd>{{ payMethod.lastName }}</dd>
            <hr>
            <dt>מיקוד (Zip)</dt>
            <dd>{{ payMethod.zip }}</dd>
            <hr>
            <dt>חברה</dt>
            <dd>{{ payMethod.company }}</dd>
            <hr>
            <dt>כתובת</dt>
            <dd>{{ payMethod.address }}</dd>
            <hr>
            <dt>עיר (City)</dt>
            <dd>{{ payMethod.city }}</dd>
            <hr>
            <dt>מדינה (State)</dt>
            <dd>{{ payMethod.state }}</dd>
            <hr>
            <dt>ארץ (Country)</dt>
            <dd>{{ payMethod.country }}</dd>
            <hr>
            <dt>אימייל</dt>
            <dd>{{ payMethod.email }}</dd>
            <hr>
            <dt>מס' פקס</dt>
            <dd>{{ payMethod.faxNumber }}</dd>
            <hr>
            <dt>מס' טלפון</dt>
            <dd>{{ payMethod.phoneNumber }}</dd>
            <hr>
          </dl>
        </mat-card-content>
      </mat-card>
    </div>
    <div style="overflow: auto; width: 100%;" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
      <mat-card style="overflow: auto;" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3>תנועות מקומיות</h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="padding: 0 !important;">
          <transactions-list style="overflow: auto;" [payMethodID]="payMethod.payMethodID">Loading...
          </transactions-list>
        </mat-card-content>
      </mat-card>
      <mat-card style="overflow: auto;" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3>תנועות מסוף Authorize</h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="padding: 0 !important;">
          <authorizeTransactions-list style="overflow: auto;" [payMethodID]="payMethod.payMethodID">
            Loading...
          </authorizeTransactions-list>
        </mat-card-content>
      </mat-card>
      <mat-card style="overflow: auto;" class="example-card">
        <mat-card-header fxLayout="row">
          <mat-card-title fxLayout="row">
            <h3>תנועות מסוף פלא-כארד</h3>
            <span class="example-fill-remaining-space"></span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="padding: 0 !important;">
          <pele-card-transactions-list style="overflow: auto;" [payMethodID]="payMethod.payMethodID">
            Loading...
          </pele-card-transactions-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
