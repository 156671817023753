import { ProcessesTypeMain } from './processes-type/processes-type-main.cmp';
import { ProcessesTypesList } from './processes-types-list.cmp';
 
import { AuthGuard } from '../_guards/auth.guard';
import { Routes } from '@angular/router';  

export const ProcessesTypesRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'processestypes', component: ProcessesTypesList, data: {  roles: "processestypes",  title: 'שולחנות' } },
    { canActivate: [AuthGuard], path: 'processestypes/add', component: ProcessesTypeMain, data: { mode: 'edit', roles: "processestypes", title: 'הוסף שולחן' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'processestypes/:processtypeID', component: ProcessesTypeMain, data: {  roles: "processestypes",  title: 'פרטי שולחן' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'processestypes/:processtypeID/edit', component: ProcessesTypeMain, data: { mode: 'edit', roles: "processestypes",  title: 'עריכת פרטי שולחן' }, outlet: 'popup' },
];
