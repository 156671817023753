import { AuthenticationService } from '../../_guards/auth.service';
import { FormGroup } from '@angular/forms';
import { ServicesData } from 'src/app/services/services.data';
import { Component, OnInit } from '@angular/core';
import { DashboardLocatorDataService } from './dashboard-locator.data';


@Component({
    templateUrl: './dashboard-locator.tmpl.html',
    styleUrls:['./../dashboard.scss']
})
export class DashboardLocatorComponent implements OnInit {
    userName: string;
    model: any;
    collectionStatusModelForm: FormGroup;

    constructor(
        public servicesData: ServicesData,
        private authService: AuthenticationService,
        private dashboardLocatorDataService: DashboardLocatorDataService,
    ) {
    }


    ngOnInit() {
        if (this.authService.isAuthorized("SystemAdministrator,dashboardLocator")) {
            this.dashboardLocatorDataService.get().subscribe(db => {
                this.fillValues(db);
            });
        }
    }


    fillValues(db: any) {
        this.model = db;
    }
}
