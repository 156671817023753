import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMenu'
})
export class FilterMenuPipe implements PipeTransform {

  transform(value: any, userRoles?: string[]): any {
    if (!value) {
      return false;
    }

    if (!userRoles) {
      return value.filter(item => false);
    }
    else if (userRoles.indexOf("SystemAdministrator") > -1)
      return value.filter(item => true);
    else {
      return value.filter(
        item => {
          if (item.roles) {
            for (let ur of userRoles) {
              if (item.roles.toLowerCase().indexOf(ur.toLowerCase()) >= 0) {
                return true;
              }
            }
            return false; //כאשר לא נכלל בשום תפקיד
          } else if (item.children) {
            for (let ur of userRoles) {
              for (let child of item.children) {
                if (child.roles && child.roles.toLowerCase().indexOf(ur.toLowerCase()) >= 0) {
                  return true;
                }
              }
            }
            return false; //כאשר גם אף אחד מהילדים לא נכלל בשום תפקיד
          } else {
            return true;
          }
        });
    }
  }
}