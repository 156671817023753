
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';

@Component({
  selector: 'contactAppointments-list-dlg',
  templateUrl: './contactAppointments-list-dlg.tmpl.html'
})

export class ContactAppointmentListDlg   {
  constructor(
    public dialogRef: MatDialogRef<ContactAppointmentListDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

}



