<div style="cursor: initial" *ngIf="contactAppointment">
  <div class="example-full-width" fxLayout="row" fxLayoutAlign="start center">
      <a fxLayout="column" style="padding: 4px" (click)="editContactAppointment(contactAppointment)">
          <i class="fa fa-pencil"></i>
      </a> &nbsp;&nbsp;
      <model-info fxLayout="column" style="padding: 4px" [modelInfo]="contactAppointment"></model-info>
      &nbsp;&nbsp;
       <div style="padding-right:7px" fxLayout="column" fxFlex>
          <div fxLayout="row">
              <h3>{{ contactAppointment.subject}}
                <small>{{ contactAppointment.description}}</small>
              </h3>
          </div>
          <div fxLayout="row">
              <span style="padding: 0px 5px 0px 5px;">
                <i class="fa fa-calendar-o"></i>&nbsp;{{ contactAppointment.startDateTime | date:'dd/MM/yyyy hh:mm' }}<br>
             <b>{{contactAppointment.location}}</b>
              </span>
              &nbsp;&nbsp;&nbsp;
          <span *ngIf="contactAppointment.summary" style="padding: 0px 5px 0px 5px;background: #e4edf3;width: 100%;">
            <i class="fa fa-file-text-o"></i>
             {{ contactAppointment.summary }}
          </span>

          </div>
      </div>
  </div>
</div>


<!--
<div style="cursor: initial" *ngIf="contactAppointment">
    <div class="example-full-width" fxLayout="row" fxLayoutAlign="start center">
        <a fxLayout="column" style="padding: 4px" (click)="editContactAppointment(contactAppointment)">
            <i class="fa fa-pencil"></i>
        </a> &nbsp;&nbsp;
        <model-info fxLayout="column" style="padding: 4px" [modelInfo]="contactAppointment"></model-info>
        &nbsp;&nbsp;
         <div style="padding-right:7px" fxLayout="column" fxFlex>
            <div fxLayout="row">
                <h3>{{ contactAppointment.description }}</h3>
            </div>
            <div fxLayout="row"><span>{{ contactAppointment.fromDateTime | date:'dd/MM/yyyy' }} {{contactAppointment.fromTimeStr}}
                {{contactAppointment.fromJewishDate}}</span> - <span
                *ngIf="contactAppointment.tillDateTime">{{ contactAppointment.tillDateTime | date:'dd/MM/yyyy' }}
                {{contactAppointment.tillTimeStr}} {{contactAppointment.tillJewishDate}}</span></div>
        </div>
    </div>
</div> -->
