
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class GlobalHelper {

   screenVHHeight: string;
  constructor() { }

  iOS() {
    console.log('navigator.platform', navigator.userAgent);
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  public get ScreenVHHeight(): string {
    return this.screenVHHeight;
  }

  public setScreenVHHeight() {

    if (this.iOS() == true) {

      this.screenVHHeight = `53`;
    } else {
      this.screenVHHeight = `100`; //${window.innerHeight}
    }
    // this.screenVHHeight = `100`;
  }
}
