import { TermEditDlg } from './term/term-edit-dlg.cmp';

import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { TermsData } from './terms.data';

import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { ToraxHttp } from '../utils/custom-http.svc';


@Component({
	selector: 'terms-list',
	templateUrl: 'terms-list.tmpl.html'
})

export class TermsList implements OnInit {


	@Output() selectedRowsChange = new EventEmitter<any>();
	constructor(
		private termsData: TermsData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private router: Router,
		public _matDialog: MatDialog,
	) {
	}

	displayedColumns: string[] = ['buttons', 'termName'];
	data: any[] = [];

	resultsLength = 0;
	isRateLimitReached = false;

	@ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	searchTermsListForm: FormGroup;
	sourceList;
	pageRows = 20;
	rowsCount;
	page;

	public clerFilter() {
		this.initForm();
		this.getTerms();
	}

	addNew() {
		this.router.navigate([{ outlets: { popup: ['add'] } }]);
	}

	openSelectedRow(row) {
		this.openDetails(row);
	}

	openDetails(row) {
		setTimeout(() => {
			const dialogRef = this._matDialog.open(TermEditDlg, {
				data: row.termID
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result && result.valid) {
					this.saveTerm(result);
				}
			});
		}, 200);
	}

	private saveTerm(termEditForm: FormGroup) {
		this.termsData.update(termEditForm.value)
			.subscribe((pm: any) => {
				this.getTerms();
				return true;
			})
	}



	private getTerms() {
		this.searchTermsListForm.controls['page'].setValue(1);

		sessionStorage.setItem(this.router.url, JSON.stringify(this.searchTermsListForm.value));

		return this.termsData!.getList(this.searchTermsListForm.value)
			.subscribe((data: any) => {
				this.data = data;
				this.sourceList = data.list;
				this.page = data.list.length;
				this.pageRows = data.list.length;
				this.rowsCount = data.rowsCount;


				this.isRateLimitReached = false;

			});
	}


	private initForm() {
		this.searchTermsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			termName: [],
			termNameEn: [],
			selectedRows: [],
			orders: this.formBuilder.array([])
		});
	}


	panelOpenState;
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;
	ngOnInit() {
		this.initForm();
		let item = sessionStorage.getItem(this.router.url);
		if (item) {
			let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
			this.searchTermsListForm.patchValue(frm);
		}

		this.getTerms();
	}
	selectionChanged(e) {
		this.getTerms();
	}

	pageChange(e) {
		this.searchTermsListForm.controls['pageRows'].setValue(e.pageSize || 20);
		this.selectionChanged(e);
	}
	sortData(e) {
		this.addOrder(e.active, e.direction != "asc");
		this.getTerms();
	}
	public search() {
	}
	addOrder(propertyName: string, desc: boolean = false) {
		const ordersControl = <FormArray>this.searchTermsListForm.controls['orders'];

		let ord = this.getOrder(propertyName);
		if (ord != null) {
			ord.controls["value"].setValue(desc);
		} else {
			ord = this.initOrder(propertyName, desc);
			ordersControl.push(ord);
		}
	}
	pageEvent: PageEvent;
	pageSizeOptions;
	setPageSizeOptions(setPageSizeOptionsInput: string) {
		this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
	}
	initOrder(propertyName: string, desc: boolean = false) {
		let propName = propertyName;
		let des = desc;
		return this.formBuilder.group({
			key: [propName],
			value: [des],
		});
	}
	getOrder(columnName: string): any {
		let lst = <FormArray>this.searchTermsListForm.controls['orders'];
		if (lst == null) return null;

		for (var i = 0; i < lst.length; i++) {
			if ((<FormGroup>lst.controls[i]).controls["key"].value == columnName) {
				return lst.controls[i];
			}
		}
		return null;
	}
	orderType(columnName: string) {
		let order = this.getOrder(columnName);
		return order != null ? order.controls["value"].value : null;
	}
}