import { ToraxHttp } from '../../utils/custom-http.svc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Deposit } from '../../deposits/deposit/deposit';
import { DepositsData } from '../deposits-data';

@Component({

	selector: 'deposit-details',
	templateUrl: './deposit-details.tmpl.html'
})

export class DepositDetails implements OnInit {
	constructor(
    private depositsData: DepositsData,
		private toraxHttp: ToraxHttp,
		private activatedRoute: ActivatedRoute
	) {

		this.toraxHttp.updatedEvent.subscribe((id) => {
			if (id > 0 && this.deposit && id === this.deposit.depositID) {
				this.loadDeposit();
			}
		});
	}


	public deposit: Deposit;
	private id: number;




	public loadDeposit(): void {
		this.depositsData.get(this.id)
			.subscribe((c:any) => this.deposit = c);
	}

	ngOnInit(): void {
		this.activatedRoute.params.forEach((params: Params) => {
			this.id = + params['depositID'];
			this.loadDeposit();
		});
	}
}
