<div class="row" style="background-color: white;">
  <div style="padding:12px;" class="col-sm-12">
    <div class="col-sm-5">
      <h1 style="margin-top: 0;">
        העברות בנקאיות
      </h1>
    </div>
    <div class="col-sm-7 text-left">
      <button style="background-color: navy;" class="btn btn-primary" (click)="openImportExlsDlg()">
        ייבוא אקסל</button>
      <button style="background-color: navy;" class="btn btn-primary"
        (click)="viwTransferTasksList = !viwTransferTasksList; viwHistoryList = false">
        {{(viwTransferTasksList? 'הסתר רשומות' : 'הצג רשומות')}} </button>
    </div>
  </div>
</div>
<div style="padding-top: 15px;">
  <transfer-tasks-list *ngIf="viwTransferTasksList"></transfer-tasks-list>
</div>
