import { AuthGuard } from './../_guards/auth.guard';
import { TransactionsList } from './transactions-list.cmp';
import { Routes } from '@angular/router';

export const TransactionsRoutes: Routes = [
    {
        canActivate: [AuthGuard], path: 'transactions', data: {  roles: "transactions",  title: 'פעולות' },
        children: [
            { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: TransactionsList, data: { title: 'פעולות' } },
        ]
    }
];


//export const appRoutingProviders: any[] = [];
