<div [formGroup]="searchYarzeitsListForm">


  <button style="position: absolute; left:10px; top:50px" class="btn" (click)="toogleState()">
    {{(panelOpenState ? 'חזור לחיפוש רגיל' : 'חיפוש מורחב')}}
  </button>
  <div *ngIf="!panelOpenState" class="row ibox-content border-bottom" style="DISPLAY: FLEX;padding: 10px 15px;">
    <jewish-week-days-picker style="margin: auto;" (selectedDayChange)="selectDay($event)"></jewish-week-days-picker>

  </div>
  <div  *ngIf="panelOpenState"  class="row ibox-content border-bottom" style="DISPLAY: FLEX;padding: 10px 15px;">
    <date-input-range [placeHolderStart]="'מתאריך:'" [placeHolderEnd]="'עד לתאריך:'"
      [dateRangeCtrl]="searchYarzeitsListForm.controls.yarzeitsDateRange">
      Loading...
    </date-input-range>
    <auto-complete apply-enter [controllerName]="'Contacts'" [placeholder]="'איש...'"
      (onSelect)="indexService.selectionChanged($event)" [formGroupModel]="searchYarzeitsListForm"
      [idControl]="searchYarzeitsListForm.controls.contactID"
      [nameControl]="searchYarzeitsListForm.controls.contactName">
    </auto-complete>
    &nbsp;&nbsp;
    <span class="example-fill-remaining-space"></span>
    <div *ngIf="!contactID" style="text-align:left" class="example-button-row">
      &nbsp; <button mat-mini-fab color="primary" title="סנן" (click)="indexService.getList()"> <i
          class="fa fa-filter"></i></button>
      &nbsp;
      <button mat-mini-fab color="primary" title="נקה סינונים" (click)="indexService.clerFilter()"><i
          class="material-icons">clear</i></button>
      &nbsp;

      <button mat-mini-fab color="primary" title="הוסף" (click)="addNew()" aria-label="הוסף">
        <i class="material-icons">add</i>
      </button>

    </div>
  </div>

  <div class="p-0  example-container" *ngIf="indexService">
    <div class="p-0  col-lg-12" style="padding: 0;">
      <div class="p-0 col-lg-4" style="padding: 0;">
        <cdk-virtual-scroll-viewport testCDK [full-height]="true" #test itemSize="10"
          [ngStyle]="{'height':  'calc(100vh - ' + actualMinusHeight +'px)'}">
          <mat-table #table style="width: 100%;font-size: larger" *ngIf="indexService?.sourceList; else loading"
            [dataSource]="indexService.sourceList" (matSortChange)="indexService.sortData($event)" class="example-table"
            matSort matSortDisableClear matSortDirection="asc">
            <!-- <ng-container matColumnDef="select">
              <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
                <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                  *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-checkbox apply-enter (click)="$event.stopPropagation()" (change)="$event ? rowToggle(row) : null"
                  [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="buttons">
              <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <a mat-menu-item [routerLink]="[ '', {outlets: {popup: [ 'yarzeits', row.yarzeitID]}}]">
                    <mat-icon>pageview</mat-icon>
                    <span>פרטים מלאים</span>
                  </a>

                  <a mat-menu-item [routerLink]="['', {outlets: {popup: ['yarzeits', row.yarzeitID, 'edit']}}]">
                    <mat-icon>edit</mat-icon>
                    <span>עריכה</span>
                  </a>

                  <a mat-menu-item>
                    <mat-icon>information</mat-icon>
                    <model-info style="padding: 4px" [dataProvider]="yarzeitsData" [id]="row.yarzeitID"
                      [title]="'מידע רשומה'">
                    </model-info>
                  </a>
                </mat-menu>
              </mat-cell>
            </ng-container>


            <ng-container matColumnDef="yarzeitID">
              <mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="מזהה">
                <a [routerLink]="[ '', {outlets: {popup: [ 'yarzeits', row.yarzeitID]}}]">
                  <i class="fa fa-fire" [matTooltip]="row.yarzeitID"></i>
                </a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="lastName">
              <mat-header-cell *matHeaderCellDef>שם</mat-header-cell>
              <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="שם">
                <span *ngIf="!row.contactID">{{ row.lastName }} {{ row.firstName }}</span>
                <a *ngIf="row.contactID" [routerLink]="[ '/contacts', row.contactID]">{{ row.lastName }} {{
                  row.firstName }}</a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="jewishDeathDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> תאריך פטירה</mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="תאריך פטירה">
                {{ row.jewishDeathDate }}
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns; let i= index"
              (dblclick)="openSelectedRow(row.yarzeitID)" [ngClass]="{'highlight': selectedRowIndex == i}"
              (click)="highlight(row,i)" tabindex="999">
              <!-- (keydown.arrowdown)="arrowDownEvent(row,selectedRowIndex)"
              (keydown.arrowup)="arrowUpEvent(row,selectedRowIndex)" -->
            </mat-row>
          </mat-table>
        </cdk-virtual-scroll-viewport>

        <mat-paginator (page)="indexService.pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false"
          [length]="indexService.rowsCount" [pageSize]="indexService.pageRows"
          [pageSizeOptions]="[10, 25, 30, 50, 100]">
        </mat-paginator>
      </div>
      <div class="col-lg-8" *ngIf="rerender && selectedRow && selectedRow.contactID">
        <cdk-virtual-scroll-viewport testCDK [full-height]="true" #test itemSize="10"
          [ngStyle]="{'height':  'calc(100vh - '+ (actualMinusHeight - 40)+'px)'}">
          <div class="row" style="text-align: center;">
            <h1><b>{{selectedRow.firstName}} {{selectedRow.lastName}}</b>
              <BR>{{(selectedRow.gender ? 'בן' : 'בת')}} ר' {{selectedRow.fatherName}}
            </h1>
            <h2>{{(selectedRow.gender ? '' : 'אשת ר\' ' + selectedRow.spouseName)}}
              <br>{{selectedRow.originCity}} - {{selectedRow.hometown}}
              <BR>נלב"ע {{selectedRow.jewishDeathDate}}<BR>
              מנוחת{{(selectedRow.gender ? 'ו' : 'ה' )}} כבוד {{selectedRow.burialPlace}}
            </h2>
          </div>
          <!-- <div class="row">
        <yarzeit-list  [contactID]="selectedRow.contactID"></yarzeit-list>
        </div> -->
          <fieldset class="form-group" style="background: white;">
            <legend style="border:solid; border-width: 0 0 1px; border-radius: 5px; background: #f3f5f6;">בנים</legend>
            <div fxLayout="row">
              <contacts-min-list style="width: 100%;" [fatherID]="selectedRow.contactID"></contacts-min-list>
            </div>
          </fieldset>
          <fieldset class="form-group" style="background: white; margin-top: 12px;">
            <legend style="border:solid; border-width: 0 0 1px; border-radius: 5px; background: #f3f5f6;">חתנים</legend>
            <div fxLayout="row">
              <contacts-min-list style="width: 100%;" [chotenID]="selectedRow.contactID"></contacts-min-list>
            </div>
          </fieldset>
        </cdk-virtual-scroll-viewport>

      </div>
    </div>
  </div>


  <ng-template #loading>
    <h3 style="text-align: center;">
      טוען נתונים...
    </h3>
  </ng-template>
