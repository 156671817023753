<div class="divTableRow" [formGroup]="defaultTermtFormGroup">
    <div class="divTableCell">
        <select class="form-control"  formControlName="commitmentSubject">
            <option value="">...</option>
            <option *ngFor="let cs of servicesData.CommitmentSubjects" [value]="cs.value">
                {{cs.name}}
            </option>
        </select>
        <small *ngIf="!defaultTermtFormGroup.controls.countryID.valid"
            class="text-danger">*</small>
    </div>
    <div class="divTableCell">
        <select class="form-control" formControlName="termID">
            <option value="">...</option>
            <option *ngFor="let term of terms" [value]="term.id">
                {{term.title}}
            </option>
        </select>
        <small *ngIf="!defaultTermtFormGroup.controls.countryID.valid"
            class="text-danger">*</small>
    </div>
    <div class="divTableCell">
        <a *ngIf="!defaultTermtFormGroup.controls.isDeleted.value"
            (click)="deleteItem(i)"><i class="fa fa-trash-o"></i>&nbsp;מחיקה</a>
        <a *ngIf="defaultTermtFormGroup.controls.isDeleted.value"
            (click)="unDeleteItem(i)"><i class="fa fa-undo"></i>&nbsp;בטל</a>
    </div>
</div>