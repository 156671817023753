import { LoaderBarService } from './../../components/loader-bar/loader-bar.service';
import { PaymentsPlanHoldingsSettingDlg } from './paymentsPlan-holdings-setting-dlg.cmp';
import { ToraxHttp } from '../../utils/custom-http.svc';
import { ModalService } from '../../components/modal/modal.svc';
import { PaymentsPlansData } from './../paymentsPlans.data';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PaymentsPlan, HoldingsSetting, ClosedPaymentsPlanMdl } from './paymentsPlan';
import { PaymentsList } from 'src/app/payments/payments-list.cmp';
import { HoldingsList } from 'src/app/holdings/holdings-list.cmp';
import { FormGroup } from '@angular/forms';
import { PaymentsPlanClosedDlg } from './paymentsPlan-closed-dlg.cmp';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ServicesData } from 'src/app/services/services.data';
import { AlertService } from '../../components/alert/alert.svc';


@Component({
  selector: 'paymentsPlan-details',
  templateUrl: './paymentsPlan-details.tmpl.html'
})

export class PaymentsPlanDetails implements OnInit {
  constructor(
    private paymentsPlansData: PaymentsPlansData,
    private activatedRoute: ActivatedRoute,
    private toraxHttp: ToraxHttp,
    public dialog: MatDialog,
    private servicesData: ServicesData,
    public location: Location,
    private media: MediaObserver,
    private alert: AlertService,
  ) {
    this.toraxHttp.updatedEvent.subscribe((id) => {
      if (id > 0 && this.paymentsPlan && id === this.paymentsPlan.paymentsPlanID) {
        this.loadPaymentsPlan();
      }
    });
    // this.mediaWatcher = this.media.media$.subscribe((mediaChange: MediaChange) => {
    //   console.log(mediaChange.mqAlias);
    // });
  }
  mediaWatcher: Subscription;

  expendPayments = false;
  expendHoldings = false;

  @ViewChild('paymentsTab') public paymentsTab: PaymentsList;
  @ViewChild('holdingsTab') public holdingsList: HoldingsList;
  @Output() public onCopy = new EventEmitter<boolean>();

  isHoldingPaymentsPlan = false;
  //	showHoldings: boolean = false;

  public paymentsPlan: PaymentsPlan;
  private id: number;

  exludePayMethodsIDs = [88, 89, 420, 1310, 3580, 6232, 6990, 42340];

  isLink(pmId) {
    return this.exludePayMethodsIDs.indexOf(pmId) < 0;
  }

  addCopy() {
    this.location.replaceState(`contacts/${this.paymentsPlan.contactID}(popup:paymentsPlans/${ this.id}/addCopy)`);
    this.onCopy.emit(true);
  }

  checkWhetherToAddPayments() {
    this.paymentsPlansData.checkWhetherToAddPayments(this.id)
      .subscribe((x: boolean) => {
        if (x) {
          this.alert.success(`תשלומים עודכנו`);
        } else {
          this.alert.info(`בדוק את הנתונים`);
        }
      }
      )
  }



  openHoldingsSettingDlg(): void {
    setTimeout(() => {
      let holdingsSetting = new HoldingsSetting();
      holdingsSetting.holdingsSettingID = this.paymentsPlan.holdingsSettingID;
      holdingsSetting.paymentsPlanID = this.id;
      holdingsSetting.contactID = this.paymentsPlan.contactID;

      const dialogRef = this.dialog.open(PaymentsPlanHoldingsSettingDlg, {
        data: holdingsSetting
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.reHoldingsSettingForPaymentsPlan(result);
        }
      });
    }, 200);
  }

  private reHoldingsSettingForPaymentsPlan(holdingsSettingForm) {
    console.log(holdingsSettingForm);
    console.log(holdingsSettingForm.value);
    this.paymentsPlansData.saveHoldingsSetting(holdingsSettingForm.value)
      .subscribe((x: any) => {
        this.loadPaymentsPlan()
        return true;
      })
  }




  openClosedPaymentsPlanDlg(): void {
    setTimeout(() => {
      let closedPaymentsPlanMdl = new ClosedPaymentsPlanMdl();
      closedPaymentsPlanMdl.paymentsPlanID = this.id;


      const dialogRef = this.dialog.open(PaymentsPlanClosedDlg, {
        data: closedPaymentsPlanMdl
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          this.closePaymentsPlan(result);
        }
      });
    }, 200);
  }

  private closePaymentsPlan(PaymentsPlanClosedForm: FormGroup) {
    this.paymentsPlansData.closePaymentsPlan(PaymentsPlanClosedForm.value)
      .subscribe((x: any) => {
        this.loadPaymentsPlan()
        return true;
      })
  }

  public loadPaymentsPlan(): void {
    this.paymentsPlansData.get(this.id)
      .subscribe((c: any) => {
        this.paymentsPlan = c
        if (this.paymentsTab) {
          this.paymentsTab.indexService.getList();
        }
      });
  }


  // viwHoldings() {
  // 	this.loaderBarService.show();
  // 	//this.showHoldings = !this.showHoldings;
  // }


  // public itemSelect(event) {
  // 	this.activeTabIndex = event.index;
  // 	sessionStorage.setItem(`${this.pathStr}`, `${this.activeTabIndex}`);
  // 	this.cdref.detectChanges();
  // }

  ngOnInit(): void {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = + params['paymentsPlanID'] | 0;
    });
    this.loadPaymentsPlan();
    //this.activeTabIndex = +sessionStorage.getItem(`${this.pathStr}`) || 0;
  }

}


