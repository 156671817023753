import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { ObjectsListResult } from '../services/objects-list-results';

@Injectable({
    providedIn: 'root',
  })

export class TransactionsData {
  public url = 'api/transactions';

    constructor(
        private toraxHttp: ToraxHttp
    ) { }


    getList(baseQuery: any)  {
        return this.toraxHttp.post(`${this.url}/List`, baseQuery);
    }

    getLastTransactions(payMethodID: number) {
          return this.toraxHttp.get(`${this.url}/getLastTransactions/${payMethodID}`);
    }

    getLastTransactionsForPayment(paymentID: number) {
          return this.toraxHttp.get(`${this.url}/getLastTransactionsForPayment/${paymentID}`);
    }

}
