<button mat-stroked-button  (click)="matDialogRef.close()" aria-label="Close dialog">
    <mat-icon>arrow_forward</mat-icon>
</button>
<div *ngIf="data; else loading"> 
<payment-short-details [payment]="data"></payment-short-details>
</div>
<ng-template #loading>
    <h1>
        <i style="color: gray" class="fa fa-refresh fa-spin"></i>
    </h1>
</ng-template>