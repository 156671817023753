import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { ProcessesList } from './processes-list.cmp'; 
import { ProcessMain } from './process/process-main.cmp';

export const ProcessesRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'processes', component: ProcessesList, data: {  roles: "processes",  title: 'שולחנות' } },
    { canActivate: [AuthGuard], path: 'processes/add', component: ProcessMain, data: { mode: 'edit', roles: "processes", title: 'הוסף שולחן' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'processes/:processID', component: ProcessMain, data: {  roles: "processes",  title: 'פרטי שולחן' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'processes/:processID/edit', component: ProcessMain, data: { mode: 'edit', roles: "processes",  title: 'עריכת פרטי שולחן' }, outlet: 'popup' },
];
