<form  [formGroup]="processForm" novalidate (ngSubmit)="save()" *ngIf="processType">
    <div class="modal-header">
        <h1>עריכת פרטי סוג תהליך [{{ (id > 0 ? id : 'חדש') }}] </h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-6">
                <panel-box title="עריכת פרטי סוג תהליך" [showClose]="false" [showCollapse]="false">
                    <span class="panel-tools"></span>
                    <div class="panel-content">
                        <div class="form-horizontal">
                            <div class="form-group">
                                <label class="control-label col-sm-2" for="title">כותרת-נושא:</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" formControlName="title" /></div>
                            </div>
                        </div>
                    </div>
                </panel-box>
            </div>
        </div>
    </div>
    <footer-edit-buttons [dataProvider]="processTypesData" [router]="router" [id]="id" [disabledDeleteButton]="id <= 0" [modelFormGroup]="processForm"></footer-edit-buttons>
</form>