import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[testCDK]'
})
export class TestCDKDirective {

  /**
   *
   */
  @Input('full-height') fullHeight: boolean = false;

  // @Input() testCDK;
  // height: any;

  /** If '(cdkObserveContent)' is removed from the template the following event will never fire */

  @HostListener('cdkObserveContent', ['$event'])
  onContentChanges(e) {
    if (!this.fullHeight) {
      let ne = this._elementRef.nativeElement;
      let tbl = ne.querySelector("mat-table");
      let prnt = <any>document.documentElement.querySelector("mat-tab-group");
      if (prnt) {
        let prntH = prnt.offsetHeight * 0.65;
        if (tbl) {
          let h = tbl.offsetHeight + 30;
          if (h > prntH) {
            h = prntH;
          }
          this.renderer.setStyle(ne, "height", `${h}px`);
        }
      }
    }
  }
  constructor(private _elementRef: ElementRef, private renderer: Renderer2) {

  }

}