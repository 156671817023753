<form *ngIf="data" class="example-form" [formGroup]="holdingsPcForm" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="סגור ללא שמירה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      <span *ngIf="actualSum || actualPymentSum">עריכת פרטי פרטי החזקת ביהמ"ד
        <small>
          <br>השווה לסכום {{actualPymentSum | number}} {{actualCurrency| currency}}</small>
        <small style="color:red" *ngIf="!unequalAmount">
          <br>סכום הרשומות אינו תואם את סכום התשלום</small>
        <small style="color:red" *ngIf="balance != 0">
          <br>יתרה להוספה {{balance | number}}</small>
      </span>
    </h2>
  </div>

  <mat-dialog-content style="padding:0px 24px 24px 24px">
    <div fxFlex="100" fxLayout="column">



      <table class="table table-border">
        <thead class="text-nowrap">
          <th>שנה-חודש</th>
          <th class="narrow">סכום</th>
          <th>איש לזיכוי</th>
          <th>...</th>
        </thead>
        <tbody>
          <ng-container formArrayName="holdings" *ngFor="let holding of getHoldingsControls.controls ; let i=index">
            <tr [formGroupName]="i">
              <td>
                <dateMonth-input fxLayout="row" [withLabel]="false" [monthInputControl]="holding.controls.month"
                  [yearInputControl]="holding.controls.year">
                </dateMonth-input>
              </td>
              <td>
                <input class="form-control narrow" formControlName="amount" apply-enter />
              </td>
              <td>
                <auto-complete [withLabel]="false" apply-enter [controllerName]="'Contacts'"
                  [placeholder]="'איש לזיכוי'" [formGroupModel]="getHoldingsControls.controls[i]"
                  [idControl]="getHoldingsControls.controls[i].controls.holdingContactID"
                  [nameControl]="getHoldingsControls.controls[i].controls.holdingContactName">
                </auto-complete>
              </td>

              <td>
                <mat-icon *ngIf="!holding.controls.isDeleted.value" class="delete" (click)="deleteRow(i)">delete_forever
                </mat-icon>
                <mat-icon *ngIf="holding.controls.isDeleted.value" color="gray" (click)="cancelDeleteRow(i)">undo
                </mat-icon>
              </td>
            </tr>

          </ng-container>
        </tbody>
      </table>
      <div class="action-container">
        <button style="color: blue;" matTooltip="לחץ כדי להוסיף שורה" apply-enter color="primary" (click)="addRow()"><i
            class="fa fa-plus"></i></button>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
    <button [disabled]="holdingsPcForm.invalid" class="btn btn-primary navbar-btn"
      [mat-dialog-close]="holdingsPcForm"><i class="fa fa-save"></i> שמור </button>

  </mat-dialog-actions>
</form>
