import { ServicesData } from '../../services/services.data';
import { PayMethodsData } from '../payMethods.data';
import { Component, Input, OnInit, ViewChild, Output, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { PayMethod } from './payMethod';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';

@Component({
  selector: 'payMethod-edit',
  templateUrl: './payMethod-edit.tmpl.html'
})


export class PayMethodEdit implements OnInit {
  constructor(
    public servicesData: ServicesData,
    public router: Router,
    public payMethodsData: PayMethodsData,
    private formBuilder: FormBuilder,
    private cdref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {

  }
  // @Input('fg') public payMethodForm: FormGroup;
  @Input('model') public payMethod?: PayMethod;
  @Input('contactID') public contactID?: number;
  @Input('payMethodID') public id: number;
  @Input('payOption') public payOption: number;
  @ViewChild('bankBranchesCmp') bankBranchesCmp: AutoCompleteComponent;
  masks;
  isOneTime = false;
  @Output('payMethodForm') public payMethodForm: FormGroup;


  @Input('fromRecordType') public fromRecordType?: string;



  creditCardVlidators = c => {
    if ((!c.value || c.value === 'undefined') && this.payOption == 1) {
      return { required: true };
    }
    return null;
  };

  hoksVlidators = c => {
    if ((!c.value || c.value === 'undefined') && this.payOption == 4) {
      return { required: true };
    }
    return null;
  };


  public itemSelect(id: number) {
    this.bankBranchesCmp.sourceList = `BankBranches/${id}`;
  }


  public selectPayOption(event?: any) {

    if (this.id > 0 && event == null) {
      return;
    }

    this.payOption = event != null ? event.value : this.payMethodForm.controls['paymentOption'].value;
    if (this.payOption == 1) {
      this.payMethodForm.controls['bankAcountNumber'].setValidators(null);
      this.payMethodForm.controls['bankBranchNumber'].setValidators(null);
      this.payMethodForm.controls['bankBranchName'].setValidators(null);
      this.payMethodForm.controls['bankNumber'].setValidators(null);
      this.payMethodForm.controls['bankName'].setValidators(null);
      this.payMethodForm.controls['bankAcountOwner'].setValidators(null);

      this.payMethodForm.controls['bankAcountNumber'].setValue(null);
      this.payMethodForm.controls['bankBranchNumber'].setValue(null);
      this.payMethodForm.controls['bankBranchName'].setValue(null);
      this.payMethodForm.controls['bankNumber'].setValue(null);
      this.payMethodForm.controls['bankName'].setValue(null);
      this.payMethodForm.controls['bankAcountOwner'].setValue(null);
    }
    else if (this.payOption == 4) {
      this.payMethodForm.controls['termID'].setValue(8);

      this.payMethodForm.controls['expStr'].setValidators(null);
      this.payMethodForm.controls['creditNumber'].setValidators(null);

      this.payMethodForm.controls['expStr'].setValue(null);
      this.payMethodForm.controls['creditNumber'].setValue(null);
    }
  }




  public load(): void {
    if (this.id > 0) {
      this.payMethodsData.getEdit(this.id)
        .subscribe((pm: any) => {
          this.payMethod = pm;
          this.fillForm(pm);
          if(this.fromRecordType){
            this.payMethodForm.controls['fromRecordType'].setValue(this.fromRecordType);
          }
          this.cdref.detectChanges();
        });
    } else {
      this.payMethodsData.getAdd(this.contactID, this.payOption)
        .subscribe((pm: any) => {
          this.payMethod = pm;
          this.fillForm(pm);
          if(this.fromRecordType){
            this.payMethodForm.controls['fromRecordType'].setValue(this.fromRecordType);
          }
          this.cdref.detectChanges();
        });
    }
  }

  private fillForm(pm: PayMethod): void {
    this.payOption = pm.paymentOption;
    this.payMethodForm.patchValue(pm);
    this.isOneTime = !this.payMethodForm.controls['isOneTime'].value;
    this.selectPayOption();

  }


  selectionChanged(e) {
    this.payMethodForm.controls['isOneTime'].setValue(this.isOneTime);
  }
  private initForm() {
    this.payMethodForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      payMethodID: [this.id, Validators.required],
      contactID: [this.contactID, Validators.required],
      creditNumber: [, this.creditCardVlidators],
      expStr: [, Validators.required],
      cvv: [],
      creditType:[],
      isOneTime: [true, Validators.required],
      paymentOption: [, Validators.required],
      termID: [], //, Validators.required
      bankAcountNumber: [, this.hoksVlidators],
      bankBranchNumber: [, this.hoksVlidators],
      bankBranchName: [],
      bankNumber: [, this.hoksVlidators],
      bankName: [],
      bankAcountOwner: [, this.hoksVlidators],
      amountLimit:[],
      inactiveDate:[],
      comment:[],
      dateLimit:[],
      address: [],
      city: [],
      company: [],
      country: [],
      email: [],
      faxNumber: [],
      phoneNumber: [],
      state: [],
      zip: [],
      firstName: [],
      lastName: [],
      fromRecordType: ['PayMethods'], //special
      recordID: [] //special
    });

  }

  ngOnInit() {
    this.initForm();
    this.masks = {
      cardExpiry: [/[0-1]/, /\d/, "/", /[1-2]/, /\d/]
    };

    if (!this.contactID || ! this.payOption) {
      this.activatedRoute.params.forEach((params: Params) => {
        this.contactID = +params['contactID'];
        this.payOption = +params['paymentOption'];

      });
    }


    this.load();

    this.cdref.detectChanges();
  }
}



