
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { HonorsSetsData } from './honors-sets.data';

import { Component, OnInit, ViewChild, Input, } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { Router } from '@angular/router';
import { IndexService } from '../services/index-service';
import { ToraxHttp } from '../utils/custom-http.svc';
import { SubscriptionLike } from 'rxjs';


@Component({
	selector: 'honors-sets-list',
	templateUrl: 'honors-sets-list.tmpl.html'
})

export class HonorsSetsList implements OnInit {
	constructor(
		private honorsSetsData: HonorsSetsData,
		private formBuilder: FormBuilder,
		public servicesData: ServicesData,
		private router: Router,
    private toraxHttp: ToraxHttp,
		public _matDialog: MatDialog,
	) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/honorsSets') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
	}

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  expandedRowID;
	displayedColumns: string[] = ['buttons', 'honorsSetID', 'description', 'honorsSetParash', 'honorsSetDate'];


  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  resultsLength = 0;
  isRateLimitReached = false;
  actualMinusHeight = '185';
	searchHonorsSetsListForm: FormGroup;

	addNew() {
		this.router.navigate(['', { outlets: { popup: ['honorsSets', 'add'] } }]);
	}

	openSelectedRow(honorsSetID) {
		this.router.navigate(['', { outlets: { popup: ['honorsSets', honorsSetID] } }]);
	}


	private initForm() {
		this.searchHonorsSetsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [20],
			query: [],
			selectedRows: [],
      honorsSetID: [],
      honorsSetDateRange: [null],
			orders: this.formBuilder.array([]),
		});
	}


	ngOnInit() {
    this.initForm();

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchHonorsSetsListForm.patchValue(frm);
    }
    this.searchHonorsSetsListForm.updateValueAndValidity();
    let params = [];
    this.indexService = new IndexService(this.searchHonorsSetsListForm, params, this.honorsSetsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();

	}
}
