import { SpinnerService } from './../services/spinner.service';
import { ServicesData } from 'src/app/services/services.data';
import { HttpCancelService } from './httpcancel.service';
import { Router, ActivationEnd } from '@angular/router';
import { Injectable, Inject, Injector, ChangeDetectorRef } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Location } from '@angular/common';
import { catchError, first, takeUntil, tap } from 'rxjs/operators';

import { AuthenticationService } from '../services';

import { HttpClient, HttpErrorResponse } from '@angular/common/http'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
    private servicesData: ServicesData,
    private spinnerService: SpinnerService
  ) {
  }



  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.show();

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.spinnerService.hide();
        }
      }, (error) => {
        this.spinnerService.hide();
      }),

      catchError(err => {

        // this.servicesData.logConsole(err)
        //   .pipe(first())
        //   .subscribe(
        //     s => {
        //       console.log("s", s);
        //     },
        //     e => {
        //       console.log("e", e);
        //     });

        if (err.status === 401 || err.status === 101) {
          // auto logout if 401 response returned from api
          console.error("err status", err.status);
          this.authenticationService.logout()
            .pipe(first())
            .subscribe(
              () => {
                this.router.navigate(['/login'], { queryParams: { returnUrl: this.location.path() } });
              },
              () => {
                console.error("error");
              });
        }
        let error = "";
        if (err && err.error) {
          error = err.error.message;
        }
        else if (err) {
          error = err.statusText;
        }

        return throwError(error);

      }))
}
}
