<mat-tab-group *ngIf="grpSums" [selectedIndex]="activeTabIndex" (selectedTabChange)="tabSelect($event)">
  <mat-tab>
    <ng-template mat-tab-label>
        <img style="height: 28px;" src="./../../../assets/images/il-32.png" />
        &nbsp;&nbsp;<h3 style="margin: 0 auto;">ישראל</h3>
        &nbsp;({{ grpSums.il }})
      </ng-template>
    <dashboard-charts-main *ngIf="activeTabIndex == 0" [grp]="'ישראל'"></dashboard-charts-main>

  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <img style="height: 28px" src="./../../../assets/images/usa-32.png" />
      &nbsp;&nbsp;<h3 style="margin: 0 auto;">ארה"ב</h3>
      &nbsp;({{ grpSums.usa }})
    </ng-template>
    <dashboard-charts-main *ngIf="activeTabIndex == 1" [grp]="'ארהב'"></dashboard-charts-main>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <img style="height: 28px" src="./../../../assets/images/uk-32.png" />
      &nbsp;&nbsp;<h3 style="margin: 0 auto;">אנגליה</h3>
      &nbsp;({{ grpSums.uk }})
   </ng-template>
    <dashboard-charts-main *ngIf="activeTabIndex == 2" [grp]="'אנגליה'"></dashboard-charts-main>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <img style="height: 28px" src="./../../../assets/images/uk-32.png" />
      &nbsp;&nbsp;<h3 style="margin: 0 auto;">בלגיה</h3>
      &nbsp;({{ grpSums.belgium }})
   </ng-template>
    <dashboard-charts-main *ngIf="activeTabIndex == 3" [grp]="'בלגיה'"></dashboard-charts-main>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <img style="height: 28px" src="./../../../assets/images/uk-32.png" />
      &nbsp;&nbsp;<h3 style="margin: 0 auto;">שוויץ</h3>
      &nbsp;({{ grpSums.switzerland }})
   </ng-template>
    <dashboard-charts-main *ngIf="activeTabIndex == 4" [grp]="'שוויץ'"></dashboard-charts-main>
  </mat-tab>
</mat-tab-group>