import { ModalService } from '../components/modal/modal.svc';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { PayMethodsData } from './payMethods.data';
import { Component, OnInit, ViewChild, Input, OnDestroy, } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ServicesData } from '../services/services.data';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IndexService } from '../services/index-service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SelectionModel } from '@angular/cdk/collections';
import { ToraxHttp } from '../utils/custom-http.svc';
import { SubscriptionLike } from 'rxjs';


@Component({
  selector: 'payMethods-list',
  templateUrl: 'payMethods-list.tmpl.html'
})

export class PayMethodsList implements OnInit, OnDestroy {

  constructor(
    private payMethodsData: PayMethodsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    private router: Router,
    private toraxHttp: ToraxHttp,
    public _matDialog: MatDialog,
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/payMethods') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  displayedColumns: string[] = ['buttons', 'payMethodID', 'lastName', 'paymentOption', 'cardHebName', 'methodTitle', 'termName', 'accountLocation', 'isOneTime', 'lastExecutionDate', 'createDate', 'inactiveDate'];


  viwHistory = false;
  @Input('contactID') public contactID?: number | null = null;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  orderButtonIsChicked: boolean = false;
  public selection = new SelectionModel<number>(true, []);
  stopLoadingPoints: boolean = false;
  defaultvalues;
  groupingColumn = 'InactiveDate';
  groupingOrderColumn = 'inactiveDate';
  searchPayMethodsListForm: FormGroup;
  indexService: IndexService;
  actualMinusHeight = '230';

  paymentOptionsList = [
    { value: 1, name: "אשראי" },
    { value: 4, name: "הוראות קבע" },
  ];

  addNew() {
    this.router.navigate([{ outlets: { popup: ['add'] } }]);
  }

  loadAll() {
    this.viwHistory = !this.viwHistory;
    this.searchPayMethodsListForm.controls['withHistory'].setValue(this.viwHistory);

    this.indexService.getList();
  }


  openSelectedRow(payMethodID) {
    this.router.navigate(['', { outlets: { popup: ['payMethods', payMethodID] } }]);
  }


  public exportExcel(): void {
    this.payMethodsData.exportExcel(this.searchPayMethodsListForm.value)
      .subscribe(blob => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `דוח כרטיסי אשראי.xlsx`;
        link.click();
      });
  }




  private initForm() {
    this.searchPayMethodsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [30],
      query: [],
      termID: [],
      ccNumber: [],
      expiryMonth: [],
      expiryYear: [],
      isOneTime: [],
      paymentOptions: [],
      contactID: [],
      withHistory: [false, Validators.required],
      selectedRows: [],
      orders: this.formBuilder.array([])
    });




    let frm = this.searchPayMethodsListForm;
    this.defaultvalues = Object.keys(this.searchPayMethodsListForm.value)
      .filter(key => frm.controls[key].validator == Validators.required)
      .reduce((obj, key) => {
        obj[key] = frm.controls[key].value;
        return obj;
      }, {});
  }



  topOpenings() {
    this.orderButtonIsChicked = true;
    this.indexService.addOrder(this.groupingOrderColumn, true);
  }

  ngOnInit() {
    this.stopLoadingPoints = true;
    this.initForm();

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchPayMethodsListForm.patchValue(frm);
    }

    this.searchPayMethodsListForm.updateValueAndValidity();

    let params = [
      { key: "contactID", value: this.contactID },
    ];


    this.indexService = new IndexService(this.searchPayMethodsListForm, params, this.payMethodsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.topOpenings();
    this.indexService.OnLoaddedEnd.subscribe(x => this.setFocus(x));

    this.indexService.getList();

    if (this.contactID) {
      this.actualMinusHeight = '362';
    }
  }


  setFocus(e) {
    setTimeout(x => {

      let ix1 = this.indexService.sourceList?.findIndex(row => row.groupName == "isPastDate false");
      if (this.viewPort) {
        this.viewPort.scrollToIndex(ix1);
      }
    }, 500)
  }

}
