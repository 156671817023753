
import { AuthGuard } from '../_guards/auth.guard';
import { Routes } from '@angular/router';
import { DepositMain } from './deposit/deposit-main.cmp';
import { DepositsMain } from './deposits-main.cmp';
import { DepositsList } from './deposits-list.cmp';

export const DepositsRoutes: Routes = [
  { canActivate: [AuthGuard], path: 'deposits-main', component: DepositsMain, data: {  roles: "deposits",  title: 'ניהול הפקדות' } },
  { canActivate: [AuthGuard], path: 'deposits', component: DepositsList, data: {  roles: "deposits",  title: 'רשימת הפקדות' } },
  { canActivate: [AuthGuard], path: 'deposits/add', component: DepositMain, data: { mode: 'edit', roles: "deposits", title: 'הוסף הפקדה' }, outlet: 'popup' },
    { canActivate: [AuthGuard], path: 'deposits/:depositID', component: DepositMain, data: {  roles: "deposits",  title: 'פרטי הפקדה' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'deposits/:depositID/edit', component: DepositMain, data: { mode: 'edit', roles: "deposits",  title: 'עריכת פרטי הפקדה' }, outlet: 'popup' }
];
