import { Contact } from './../../contacts/contact/contact';


export class Phone {

    

    phoneID: number;
    contactID: number;
    contactName: string;
    number: string;
    type: number;
    contact: Contact;
    inactiveDate: Date;
    isActive: boolean;
    communicationType: string;
    inactiveDateStr: string;
    inactiveJewishDate: string;
    comment:string;
}

