import { AfterViewInit } from '@angular/core';
import { DateInput } from './../dateInput/date-input.cmp';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateRangeClass } from './date-input-range.class';

@Component({
    selector: 'date-input-range',
    templateUrl: 'date-input-range.tmpl.html',
})



export class DateInputRange implements OnInit, AfterViewInit {
    constructor(
        private formBuilder: FormBuilder
    ) { }

    @Input('dateRangeCtrl') public dateRangeCtrl: FormControl;
    @Input('placeHolderStart') placeHolderStart?: string = "החל מ...";
    @Input('placeHolderEnd') placeHolderEnd?: string = "עד ל...";
    @ViewChild('startInput') startInput: DateInput
    @ViewChild('endInput') endInput: DateInput

    dateRangeForm: FormGroup;
    dateRangeClass = new DateRangeClass();


    private initForm() {
        let start = this.dateRangeClass && this.dateRangeClass.start ? this.dateRangeClass.start : null;
        let end = this.dateRangeClass && this.dateRangeClass.end ? this.dateRangeClass.end : null;
        let includeNulls = this.dateRangeClass && this.dateRangeClass.includeNulls ? this.dateRangeClass.includeNulls : false;
        if (this.dateRangeForm) {
            this.dateRangeForm.controls['start'].setValue(start);
            this.dateRangeForm.controls['end'].setValue(end);
            this.dateRangeForm.controls['includeNulls'].setValue(includeNulls);
        } else {
            this.dateRangeForm = this.formBuilder.group({
                start: [start],
                end: [end],
                includeNulls: [includeNulls, Validators.required]
            });
        }
    }

    ngOnInit() {
        this.initForm();
    }
    
    ngAfterViewInit() {
        this.dateRangeCtrl.valueChanges.subscribe(x => {
            if (x == null) {
                this.dateRangeClass = new DateRangeClass();
                this.initForm();
            }
        });

        this.dateRangeForm.valueChanges.subscribe(arg => {
            if (arg) {
                this.dateRangeClass.start = arg.start;
                this.dateRangeClass.end = arg.end;
                this.dateRangeClass.includeNulls = arg.includeNulls;
                this.dateRangeCtrl.setValue(this.dateRangeForm.value);
            }
        });
    }

}

