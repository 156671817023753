import { ServicesData } from '../../services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Deposit } from '../../deposits/deposit/deposit';
import { DepositsData } from '../deposits-data';

@Component({
  selector: 'deposit-edit',
  templateUrl: './deposit-edit.tmpl.html'
})

export class DepositEdit implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private depositsData: DepositsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
  ) {
  }

  deposit: Deposit;
  id: number;
  depositType: number;
  depositForm: FormGroup;

  public load(): void {
    if (this.id > 0) {
      this.depositsData.getEdit(this.id || 0)
        .subscribe((c: any) => {
          this.deposit = c;

          this.depositForm.patchValue(c);
        });
    } else {
      this.depositsData.getAdd()
        .subscribe((c: any) => {
          this.deposit = c;
          this.depositForm.patchValue(c);
        });
    }
  }


  private initForm() {
    this.depositForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      depositID: [this.id || 0, Validators.required],
      depositDate: [],
      comment: [],
      amount: [null, Validators.required],
      currency: [null, Validators.required],
      depositType: [null, Validators.required],
      paymentsIDs: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['depositID'];
      this.depositType = +params['depositType'];

    });
    this.initForm();
    this.load();
  }
}



