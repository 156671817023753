import { ContactsData } from 'src/app/contacts/contacts.data';
import { ServicesData } from 'src/app/services/services.data';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatList } from '@angular/material/list';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ContactReportModel } from './contact-report-model'
import { Observable } from 'rxjs';
import { validateBasis } from '@angular/flex-layout';

@Component({
    selector: 'contact-report-model-dlg',
    templateUrl: './contact-report-model-dlg.tmpl.html'
})

export class ContactReportModelDlg implements OnInit {


    constructor(
        public matDialogRef: MatDialogRef<ContactReportModelDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any, //contactID       
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        public contactsData: ContactsData
        
    ) {
    }

    @ViewChild('selectionList') private selectionList: MatList;

    contactReportModelForm: FormGroup;
    contactReportModel: ContactReportModel;
    contactCommitments;
    panelOpenState;

    selectAll() {
        (<any>this.selectionList).options.forEach(element => {
            element.selected = true;
        });
    }
    unSelectAll() {
        (<any>this.selectionList).options.forEach(element => {
            element.selected = false;
        });
    }


    selectionChange(e) {
        this.contactReportModelForm.controls['commitmentsIDs'].setValue(e.source.options);
    }

    private initForm() {
        this.contactReportModelForm = this.formBuilder.group({
            contactID: [this.data.contactID, Validators.required],
            contactName: [this.data.contactName],
            commitmentsIDs: [this.data.selectedCommitmentsIDs],
            //fundraisingTypes: [],
            commitmentSubjects: [],
            donationTypes: [],
            fromDateRange: [],
            includeClosedCommitments:[true,  Validators.required],
            includeHoldings:[false,  Validators.required],
            includeWaitingPayments:[true,  Validators.required], //לכללול תשלומים ממתינים?
        });
    }

    cancel()
    {
        this.matDialogRef.close();
    }
     
    ngOnInit(): void { 
        this.initForm(); 
    }

}



