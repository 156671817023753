import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { UsersList } from './users-list.cmp';

export const UsersRoutes: Routes = [
    {
        canActivate: [AuthGuard], path: 'users', data: {  roles: 'SystemAdministrator',  title: 'משתמשים' },
         children: [
            { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: UsersList, data: {  roles: 'SystemAdministrator', title: 'משתמשים' } },
          ]
    }
];

//export const appRoutingProviders: any[] = [];

