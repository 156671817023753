import { MaterialModule } from './../material.module';
import { TermsList } from './terms-list.cmp';
import { TermsRoutes } from './terms.routing';
import { RouterModule } from '@angular/router';
import { TermEditDlg } from './term/term-edit-dlg.cmp';


import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { TermsData } from './terms.data';
//import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
		RouterModule.forChild(TermsRoutes),
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,
       // TextMaskModule,

        MaterialModule
    ],
    declarations: [
        TermsList,
        TermEditDlg,
    ],
    providers: [
        TermsData
    ],
    entryComponents: [
        TermEditDlg,
    ]
})
export class TermsMdl { }



