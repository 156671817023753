<form [formGroup]="tishInvitedForm" novalidate (ngSubmit)="save()" *ngIf="tishInvited">
  <div class="modal-header">
    <div class="form-row" fxLayout="row" fxLayout.xs="column">
      <h1>עריכת פרטי מוזמן [{{ (id > 0 ? id : 'חדש') }}]</h1>

      <span class="example-fill-remaining-space"></span>
      <h2 style="background-color: white; padding: 6px; margin: 10px;border: solid gray 1px;"><b>{{
          tishInvited.tishEventTitle }}</b> </h2>
    </div>

  </div>
  <div class="modal-body">
    <div class="example-form">
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי מוזמן
            <small style="color:blue"
              *ngIf="tishInvited.invitedStatus == 'אורח' || tishInvitedForm.controls.isChild.value == true">&nbsp;<u>{{tishInvited.invitedStatus}}</u></small>
          </legend>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <contacts-select-pick [withLabel]="true" (contactSelect)="selectionChanged($event)"
              (contactChange)="selectionChanged($event)" (contactAdd)="selectionChanged($event)" [emitFullObject]="true"
              [contactID]="tishInvited.contactID" [placeHolder]="'איש...'"
              [contactIdControl]="tishInvitedForm.controls.contactID" [withSubTitle]="true"
              [contactNameControl]="tishInvitedForm.controls.tishInvitedName" [fromRecordType]="'TishInvited'"
              [formGroupModel]="tishInvitedForm">
            </contacts-select-pick>

            &nbsp;&nbsp;
            <auto-complete [placeholder]="'מארח...'" [controllerName]="'ViwTishInvitedContacts/' + tishEventID"
              [formGroupModel]="tishInvitedForm" [idControl]="tishInvitedForm.controls.hostInvitedID"
              [emitFullObject]="true" [withSubTitle]="true" [nameControl]="tishInvitedForm.controls.hostContactName">
            </auto-complete>
            &nbsp;&nbsp;

            &nbsp;&nbsp;
            <div class="input-group mb-3">
              <label>קבוצה:</label>
              <mat-select apply-enter class="form-control" placeholder="קבוצה..." formControlName="tishGroupID">
                <mat-option [value]="null">...</mat-option>
                <mat-option *ngFor="let g of tishGroups" [value]="g.id">{{g.title}}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">

              <label>שם לכרטיס:</label>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                <input class="form-control narrow" placeholder="תיאור ותואר" style="text-align: right;" apply-enter
                  formControlName="specialInvitedPerfix" />

                &nbsp;&nbsp;
                <input class="form-control" placeholder="שם פרטי" style="text-align: right;" apply-enter
                  formControlName="specialInvitedFirstName" />

                &nbsp;&nbsp;
                <input class="form-control" placeholder="שם משפחה" style="text-align: right;" apply-enter
                  formControlName="specialInvitedLastName" />

                &nbsp;&nbsp;
                <input class="form-control narrow" placeholder="סיומת" style="text-align: right;" apply-enter
                  formControlName="specialInvitedSuffix" />
              </div>
              <h3 fxLayout="row" class="label">יש להכניס נתונים מסודרים בשדות המתאימים</h3>
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <!-- <div class="input-group mb-3">
              <label>מכתב:</label>
              <mat-select apply-enter class="form-control" placeholder="נשלח מכתב?" formControlName="doSendletter">
                <mat-option [value]="false">לא נשלח מכתב</mat-option>
                <mat-option [value]="true">נשלח מכתב</mat-option>
              </mat-select>
            </div>
            &nbsp;&nbsp; -->
            <div class="input-group mb-3">
              <label>אישור השתתפות:</label>
              <mat-select apply-enter class="form-control" placeholder="לא ידוע" formControlName="invitedConfirmed">
                <mat-option [value]="null">לא ידוע</mat-option>
                <mat-option [value]="true">משתתף</mat-option>
                <mat-option [value]="false">לא משתתף</mat-option>
              </mat-select>
            </div>
            <!-- &nbsp;&nbsp;
            <div class="input-group mb-3">

              <label for="isPlace">מוזמן לשיבוץ?:</label>
              <mat-select apply-enter class="form-control" placeholder="מוזמן לשיבוץ?" formControlName="isPlace">
                <mat-option [value]="false">לא לשיבוץ</mat-option>
                <mat-option [value]="true">לשיבוץ</mat-option>
              </mat-select>

            </div> -->
            &nbsp;&nbsp;
            <auto-complete-input [leftToRight]="false" apply-enter [placeholder]="'סטטוס'"
              [controllerName]="'InvitedStatuses'" [stringValueControl]="tishInvitedForm.controls.invitedStatus"
              [formGroupModel]="tishInvitedForm">
            </auto-complete-input>

            &nbsp;&nbsp;
            <auto-complete-input [leftToRight]="false" apply-enter [placeholder]="'מעמד תורם'"
              [controllerName]="'ContributorRatings'" [stringValueControl]="tishInvitedForm.controls.contributorRating"
              [formGroupModel]="tishInvitedForm">
            </auto-complete-input>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <!-- <div class="input-group mb-3" *ngIf="tishInvitedForm.controls.isChild.value == true">

              <label for="quantityAdditionalTickets">כרטיסי כניסה
                נוספים:</label>
              <input type="text" class="form-control" formControlName="quantityAdditionalTickets" />
              <h3 class="label">מס' כרטיסים נוספים לכניסה בלבד</h3>
  &nbsp;&nbsp;
            </div> -->


            <mat-checkbox apply-enter  style="direction: ltr;"  formControlName="isChild">
              :?האם ילד
            </mat-checkbox>
            &nbsp;&nbsp;
            <mat-checkbox apply-enter  style="direction: ltr;"  formControlName="doSendletter">
              :?נשלח מכתב
            </mat-checkbox>
            &nbsp;&nbsp;
            <mat-checkbox apply-enter  style="direction: ltr;"  formControlName="isPlace">
              :?מוזמן לשיבוץ
            </mat-checkbox>
            &nbsp;&nbsp;
            <mat-checkbox apply-enter  style="direction: ltr;"  formControlName="yomKipur">
              :?יום כיפור
            </mat-checkbox>
            &nbsp;&nbsp;
            <mat-checkbox apply-enter  style="direction: ltr;"  formControlName="ticketPrinted">
              :?הודפס כרטיס
            </mat-checkbox>
            &nbsp;&nbsp;
            <mat-checkbox apply-enter  style="direction: ltr;" formControlName="marking1">
              :סימון 1
            </mat-checkbox>
            &nbsp;&nbsp;
            <mat-checkbox  apply-enter style="direction: ltr;" formControlName="marking2">
              :סימון 2
            </mat-checkbox>

            <!-- <input class="form-control narrow" placeholder="סטטוס" style="text-align: right;" apply-enter
            formControlName="invitedStatus" />
          -->
          </div>
          <hr>
          <textarea rows="2" class="comment" placeholder="הערה..." formControlName="comment"></textarea>


          <div class="form-row" fxLayout="row" *ngIf="tishInvitedForm.controls.isChild.value == false">
            <tishInviteds-subs [tishInvited]="tishInvited" [tishInvitedForm]="tishInvitedForm"
              [tishInviteds]="tishInvited.tishInviteds" fxLayout="row">
            </tishInviteds-subs>

          </div>



        </fieldset>
      </div>
    </div>
  </div>
  <footer-edit-buttons [dataProvider]="tishInvitedsData" [displaySaveButton]="false" [displayDeleteButton]="false"
    [router]="router" [id]="id" [disabledDeleteButton]="id <= 0" [modelFormGroup]="tishInvitedForm">
    <ng-template #customHtmlButtons>
      <button type="button" apply-enter [disabled]="!tishInvitedForm.valid" (click)="save(tishInvitedForm)"
        class="btn btn-primary navbar-btn">
        <i class="fa fa-save"></i> שמור
      </button>

      <button type="button" [disabled]="id <= 0" class="btn btn-danger navbar-btn" (click)="delete()">
        <i class="fa fa-ban"></i> מחק
      </button>

    </ng-template>
  </footer-edit-buttons>
</form>
