 
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { CampaignsList } from './campaigns-list.cmp';
import { CampaignMain } from './campaign/campaign-main.cmp';
import { CampaignDetails } from './campaign/campaign-details.cmp';
import { CampaignAttachedMain } from '../campaign-attached/campaign-attached/campaign-attached-main.cmp';

export const CampaignsRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'campaigns', pathMatch: 'full', component: CampaignsList, data: {  roles: "campaign",  title: 'רשימת קמפיין' } },
    { canActivate: [AuthGuard], path: 'campaigns/add', component: CampaignMain, data: { mode: 'edit', roles: "campaign", title: 'הוסף קמפיין' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'campaigns/:campaignID/campaignAttacheds/add', component: CampaignAttachedMain, data: {   mode: "edit", roles: "campaignAttached", title: 'עריכת פרטי קמפיין' }, outlet: 'popup' },   
    { canActivate: [AuthGuard], path: 'campaigns/:campaignID/edit', component: CampaignMain, data: { mode: "edit", roles: "campaign", title: 'עריכת פרטי קמפיין' }, outlet: 'popup' },
 
    { canActivate: [AuthGuard], path: 'campaigns/:campaignID', component: CampaignDetails, data: {  roles: "campaigns",  title: 'פרטי קמפיין' }   },
  ]; 

 