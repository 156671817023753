<dl *ngIf="contact" class="dl-horizontal">
  <dt>שטיבל</dt>
  <dd>{{ contact.shtibelName }}
    <span *ngIf="contact.holdingsShtibelName"><b>לקביעות:</b> {{contact.holdingsShtibelName}}</span>
    </dd>
  <hr>
  <dt>אב</dt>
  <!-- כרגע מוצג רק שם אב הטקסטואלי -->
  <dd>
    <a [routerLink]="[ '/contacts', contact.fatherID]">
      {{ contact.fatherViewName }}
    </a>
    </dd>
  <!-- <dd>{{ contact.fatherName }}</dd>   -->
  <hr>
  <dt>חותן</dt>
  <dd>
    <a [routerLink]="[ '/contacts', contact.chotenID]">
      {{ contact.chotenName }}
    </a>
  </dd>
  <hr>
  <dt>אחראי החזקת ביהמ"ד</dt>
  <dd> {{ contact.responsibleName }}</dd>
  <hr>
  <dt>שם לקוויטל</dt>
  <dd>{{ (contact.kvitelFirstName ?contact.kvitelFirstName : contact.firstName) }} בן {{ (contact.kvitelMotherName ?
    contact.kvitelMotherName : contact.motherName) }} {{
    (contact.kvitelLastName ? contact.kvitelLastName : '') }}</dd>
  <hr>
  <dt>שם האם</dt>
  <dd>{{ contact.motherName }}</dd>
  <hr>
  <dt>מס' זהות</dt>
  <dd>{{ contact.passport }}</dd>
  <hr>
  <dt>תאריך לידה</dt>
  <dd>{{ contact.jewishBirthDate }} </dd>
  <hr>
  <mat-chip-list>
    <mat-chip *ngIf="contact.personalStatusName && contact.personalStatus != 1">
      {{contact.personalStatusName}}</mat-chip>
    <mat-chip *ngIf="contact.contactTypeName  && contact.contactType != 1">
      {{contact.contactTypeName}}</mat-chip>
    <mat-chip *ngIf="contact.affiliationName  && contact.affiliation != 1">
      <span [ngStyle]="{'color':(contact.affiliationName == 'אינו אנש' ? 'DARKGRAY' : '')}">
        {{contact.affiliationName}}</span>
    </mat-chip>
    <mat-chip *ngIf="contact.teamName && contact.team != 1">
      {{contact.teamName}}</mat-chip>
    <mat-chip *ngIf="contact.languageName">
      {{contact.languageName}}</mat-chip>
  </mat-chip-list>

</dl>
