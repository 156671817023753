﻿<div [formGroup]="searchContactsListForm">

  <div class="example-container" style="background-color: white; border-bottom: 10px solid rgb(243, 243, 244)">
    <div style="z-index: 10; position: relative; left: 0;  top:0px;" class="example-button-row">
      <h3 style="color: blue; position: absolute; width: 100%; text-align: left; top: -7px;" class="label">
        <span *ngIf="searchContactsListForm.controls.cityTitle.value"> עיר:{{
          searchContactsListForm.controls.cityTitle.value }}&nbsp;</span>
        <span *ngIf="searchContactsListForm.controls.shtibelName.value"> שטיבל:{{
          searchContactsListForm.controls.shtibelName.value }}&nbsp;</span>
        <span *ngIf="searchContactsListForm.controls.groupIDs.value"> קבוצות:{{
          searchContactsListForm.controls.groupIDs.value }}&nbsp;</span>
        <span *ngIf="searchContactsListForm.controls.groupPriorities.value"> דירוג:{{
          searchContactsListForm.controls.groupPriorities.value }}&nbsp;</span>
      </h3>
    </div>

    <div class="example-button-row" style="white-space: nowrap;" fxLayout="row"
      style="position: sticky; float: left; left: 5px;  z-index: 9999;">
      <button style="margin: 3px;" mat-mini-fab color="primary" title="סנן" (click)="selectionChanged($event)"><i
          class="fa fa-filter"></i></button>
      <button style="margin: 3px;" mat-mini-fab color="primary" title="נקה סינונים" (click)="clerFilter()"><i
          class="material-icons">clear</i></button>
      <button style="margin: 3px;" mat-mini-fab color="primary" title="הוסף" (click)="addNew()" aria-label="הוסף חדש">
        <i class="material-icons">add</i>
      </button>
    </div>
    <div class="column" style="padding-top: 12px; overflow: auto">
      <div fxLayout="row" class="scrolling-wrapper">
        <button class="btn"
          style="padding: 6px; font-size: initial; line-height: 15px; border-color: blue; color: blue;"
          (click)="topOpenings()" matTooltip="נפתחו לאחרונה">
          <i *ngIf="!orderButtonIsChicked" class="fa fa-exclamation"></i>
          <i *ngIf="orderButtonIsChicked" class="fa fa-exclamation-circle"></i>
        </button>
        <button *ngIf="orderButtonIsChicked" class="btn"
          style="padding: 3px; font-size: initial; line-height: 15px; color: blue;" (click)="topCurUserOpenings()">
          <i *ngIf="onlySortRecentlyOpenedByCurUser" class="fa fa-group" matTooltip="כל המשתמשים"></i>
          <i *ngIf="!onlySortRecentlyOpenedByCurUser" class="fa fa-user" matTooltip="משתמש נוכחי"></i>
        </button>


        <contacts-search-input style="background-color: white"   fxLayout="row"
          (onChangeTxt)="selectionChanged($event)" matInput #searchInp [query]="searchContactsListForm.controls.query">
        </contacts-search-input>

        &nbsp;&nbsp;
        <auto-complete style="background-color: white;" [smallTemplate]="true" fxLayout="row" #cityID
          [placeholder]="'עיר...'" (onSelect)="selectionChanged($event)" [controllerName]="'Cities'"
          [formGroupModel]="searchContactsListForm" [idControl]="searchContactsListForm.controls.cityID"
          [nameControl]="searchContactsListForm.controls.cityTitle">
        </auto-complete>
        &nbsp;&nbsp;
        <mat-select class="form-control small-input" (selectionChange)="selectionChanged($event)"
          placeholder="קבוצות..." [formControl]="searchContactsListForm.controls.groupIDs" multiple>
          <mat-option *ngFor="let g of servicesData.Groups" [value]="g.groupID">
            {{g.groupTitle}}
          </mat-option>
        </mat-select>
        &nbsp;&nbsp;
        <mat-select class="form-control small-input" *ngIf="searchContactsListForm.controls.groupIDs.value"
          (selectionChange)="selectionChanged($event)" placeholder="בחר סטטוס..."
          [formControl]="searchContactsListForm.controls.groupStatuses" multiple>
          <mat-option *ngFor="let gp of contactGroupStatuses" [value]="gp.contactGroupStatusID">
            {{gp.name}}</mat-option>
        </mat-select>
        &nbsp;&nbsp;
        <mat-select class="form-control small-input" *ngIf="searchContactsListForm.controls.groupIDs.value"
          (selectionChange)="selectionChanged($event)" placeholder="בחר דירוג..."
          [formControl]="searchContactsListForm.controls.groupPriorities" multiple>
          <mat-option *ngFor="let gp of servicesData.ContactGroupPriorities" [value]="gp.value">
            {{gp.name}}</mat-option>
        </mat-select>
        &nbsp;&nbsp;
      <auto-complete style="background-color: white;" [smallTemplate]="true" fxLayout="row" #shtibelID
        [placeholder]="'שטיבל...'" (onSelect)="selectionChanged($event)" [controllerName]="'Shtibels'"
        [formGroupModel]="searchContactsListForm" [idControl]="searchContactsListForm.controls.shtibelID"
        [nameControl]="searchContactsListForm.controls.shtibelName">
      </auto-complete>
      &nbsp;&nbsp;
      <mat-select class="form-control small-input" (selectionChange)="selectionChanged($event)"   [disabled]="searchContactsListForm.controls.shtibelID.value != null"
      placeholder="שיוך לשטיבל..." formControlName="friendsInStiebel">
      <mat-option [value]="">כולם</mat-option>
      <mat-option [value]="true">חברים בשטיבל</mat-option>
      <mat-option [value]="false">ללא שטיבל</mat-option>
    </mat-select>
      </div>
    </div>
  </div>

  <div class="example-container">
    <!-- <div fxLayout="row" *ngIf="selection && selection.selected.length >= 1">
      <span class="example-fill-remaining-space"></span>
      הדפס מדבקות ל-{{selection.selected.length}} רשומות נבחרות
      <button class="btn btn-outline btn-success  dim" type="button"
        style="width: min-content; font-weight: 500; color:#ff4081" (click)="printSticks()">
        פורמט ישראל
      </button>
      <button class="btn btn-outline btn-success  dim" type="button"
        style="width: min-content; font-weight: 500; color:#76d81b" (click)="printSticks('London')">
        פורמט לונדון
      </button>

    </div> -->

    <div class="bounds">
      <div class="content" style="height: 70%; overflow: auto" loading-status
        [stopLoadingWhen]="stopLoadingPoints || sourceList">
        <cdk-virtual-scroll-viewport itemSize="10" style="height:calc(100vh - 150px)">
          <mat-table #table style="width: 100%;" [dataSource]="sourceList" (matSortChange)="sortData($event)"
            class="example-table" matSort matSortDisableClear matSortDirection="asc">
            <ng-container matColumnDef="select">
              <mat-header-cell style=" padding-right: 0;" *matHeaderCellDef>
                <!-- <span style="color: #FFFFFF; background-color: #ff4081;" class="label label-primary"
                  *ngIf="selection?.selected?.length > 0">{{ selection?.selected?.length }}</span> -->
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <!-- <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? rowToggle(row) : null"
                  [checked]="selection.isSelected(row)">
                </mat-checkbox>
                &nbsp; -->
                <div style="display: inline;" *authGuardDirective="'muadafim'">
                  <i *ngIf="row.isFavorite" class="fa fa-star isFavorite-icon" (click)="toogleContactFavorite(row)"
                    matTooltip="לחץ כדי להסיר ממועדפים"></i>
                  <i *ngIf="!row.isFavorite" class="fa fa-star-o isFavorite-icon" (click)="toogleContactFavorite(row)"
                    matTooltip="לחץ כדי להוסיף למועדפים"></i>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="buttons">
              <mat-header-cell *matHeaderCellDef>...</mat-header-cell>
              <mat-cell *matCellDef="let row">


                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <a mat-menu-item (click)="openDetails(row);">
                    <mat-icon>remove_red_eye</mat-icon>
                    <span>תצוגה מהירה</span>
                  </a>
                  <a mat-menu-item [routerLink]="[ '/contacts', row.contactID]">
                    <mat-icon> contacts</mat-icon>
                    <span>כרטיס איש</span>
                  </a>


                  <a mat-menu-item [routerLink]="['', {outlets: {popup: ['contacts', row.contactID, 'edit']}}]">
                    <mat-icon>edit</mat-icon>
                    <span>עריכה</span>
                  </a>
                  <a mat-menu-item>
                    <mat-icon>information</mat-icon>
                    <model-info style="padding: 4px" [title]="'מידע רשומה'" [modelInfo]="row">
                    </model-info>
                  </a>
                </mat-menu>

                <button mat-icon-button  aria-label="Example icon-button with a menu" (click)="openContactGroupsPanel(row.contactID)">
                  <i class="fa fa-group"></i>
                </button>
                <button mat-icon-button  aria-label="Example icon-button with a menu" (click)="openContactAppointmentsPanel(row.contactID)">
                  <i class="fa fa-calendar-plus-o"></i>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="contactID">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> מזהה </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="מזהה" fxHide fxShow.gt-xs>
                <a [routerLink]="[ '/contacts', row.contactID]">
                  {{ row.contactID }}
                </a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="perfix">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> תואר
              </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="תואר" fxHide fxShow.gt-xs> {{row.perfix}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="firstName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> פרטי </mat-header-cell>
              <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="פרטי">
                {{row.firstName}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> משפחה </mat-header-cell>
              <mat-cell style="font-weight: 500;" *matCellDef="let row" data-label="משפחה">
                {{row.lastName}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="suffix">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> סיומת
              </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="סיומת" fxHide fxShow.gt-xs> {{row.suffix}}
              </mat-cell>
            </ng-container>


            <ng-container matColumnDef="fatherViewName">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> שם האב </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="שם האב" fxHide fxShow.gt-xs>
                <a [routerLink]="[ '/contacts', row.fatherID]">
                  {{row.fatherViewName}}
                </a>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="chotenName">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> שם החותן </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="שם החותן" fxHide fxShow.gt-xs>
                <a [routerLink]="[ '/contacts', row.chotenID]">
                  {{row.chotenName}}
                </a>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="streetLine">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> כתובת </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="כתובת" fxHide fxShow.gt-xs><span>
                  {{row.streetLine}}</span> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cityName">
              <mat-header-cell *matHeaderCellDef  mat-sort-header> עיר </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="עיר"> {{row.cityName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="phoneNumber1">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> טלפון 1 </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="טלפון 1" fxHide fxShow.gt-xs> <span style="direction: ltr;">
                  {{row.phoneNumber1 | phone }} </span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="phoneNumber2">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> טלפון 2 </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="טלפון 2" fxHide fxShow.gt-xs><span style="direction: ltr;">
                  {{row.phoneNumber2 | phone }} </span></mat-cell>
            </ng-container>

            <ng-container matColumnDef="shtibelName">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs> שטיבל </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="שטיבל" fxHide fxShow.gt-xs> {{row.shtibelName}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="codeIchud">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs mat-sort-header> קוד איחוד
              </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="קוד איחוד" fxHide fxShow.gt-xs>
                {{row.codeIchud}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="changeDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs> עדכון אחרון
              </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="עדכון אחרון" fxHide fxShow.gt-xs>
                {{ row.changeDate | date:'dd/MM/yyyy' }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="openingJewishDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="נפתח ב">
                {{ row.openingJewishDate }}
              </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="openSelectedRow(row.contactID)">
            </mat-row>
            <ng-container matColumnDef="groupHeader">
              <td colspan="999" mat-cell *matCellDef="let group">
                <strong>{{(group.value ? group.value : 'ישן יותר')}}</strong>
              </td>
            </ng-container>
            <ng-container matColumnDef="groupReducer">
              <td colspan="1" mat-cell *matCellDef="let group">

              </td>
            </ng-container>

            <tr mat-row class="clickable" style="background-color: #ceced2 !important;"
              *matRowDef="let row; columns: ['groupReducer','groupHeader']; when: isGroup">
            </tr>

          </mat-table>
          <h1 *ngIf="!sourceList && !isLoadingResults" style="color: gray; text-align: center;">
            הקלד בשורת החיפוש כדי להציג רשומות...
          </h1>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>

    <mat-paginator (page)="pageChange($event)" [showFirstLastButtons]="true" [hidePageSize]="false" [length]="rowsCount"
      [pageSize]="pageRows" [pageSizeOptions]="[10, 25, 30, 50, 100]"></mat-paginator>
  </div>
</div>
