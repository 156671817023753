<div *ngIf="tishInvitedForm" [formGroup]="tishInvitedForm" [ngClass]="{'list-row-invited': rowTemplate}"
  class="chair-center draggableInvited invited sortable" #invited>
  <i *ngIf="tishInvited.tishInvitedID" (click)="editInvited(tishInvited.tishInvitedID)"
    style="transform: rotate(90deg); line-height: 5px; cursor: pointer;" class="fa fa-ellipsis-v hidden-print"></i>
  <i *ngIf="tishInvited.comment" style="font-size: 10px; display: inline; color: yellow;"
    class="fa fa-angle-double-down hidden-print" customToolTip [contentTemplate]="template">

  </i>
  <br>
  <ng-template #template>
    <div style="padding: 6px;"> {{tishInvited.comment}}</div>

  </ng-template>
  <label *ngIf="tishInvited"
    [ngStyle]="{
    'transform' :  transform,
    'color': ( (isChild != null && isChild == tishInvitedForm.controls.isChild.value)
    ||(contributorRating != null && contributorRating == tishInvitedForm.controls.contributorRating.value)
    ||(invitedStatus != null && invitedStatus == tishInvitedForm.controls.invitedStatus.value)
    ||(isPlace != null && isPlace == tishInvitedForm.controls.isPlace.value)
    ||(invitedConfirmed == 0 &&   tishInvitedForm.controls.invitedConfirmed.value == null)
    ||(invitedConfirmed == 1 &&   tishInvitedForm.controls.invitedConfirmed.value == true)
    ||(invitedConfirmed == 2 &&   tishInvitedForm.controls.invitedConfirmed.value == false)
    ||(tishGroupID != null && tishGroupID == tishInvitedForm.controls.tishGroupID.value)
    ||(marking1 != null && marking1 == tishInvitedForm.controls.marking1.value)
    ||(marking2 != null && marking2 == tishInvitedForm.controls.marking2.value)


    ? 'red' : 'inherit')
    }"
    class="ticketLabel">
    {{tishInvited.firstName}} {{tishInvited.lastName}}
    <i *ngIf="tishInvited.hostContactName" class="fa fa-heart" style="color: #3f51b59c;font-size: 60%;"
      [matTooltip]="('מארח: ' +tishInvited.hostContactName)"></i>
    <br *ngIf="tishInvited.cityName">
    <small>{{tishInvited.cityName}}</small>
  </label>
</div>
