import { CityMain } from './city/city-main.cmp';
import { CityDetails } from './city/city-details.cmp';
import { AuthGuard } from './../_guards/auth.guard';
import { Routes } from '@angular/router';
import { CitiesList } from './cities-list.cmp';

export const CitiesRoutes: Routes = [
    { canActivate: [AuthGuard], path: 'cities', pathMatch: 'full', component: CitiesList, data: {  roles: "cities",  title: 'רשימת ערים' } },
    { canActivate: [AuthGuard], path: 'cities/add', component: CityMain, data: { mode: 'edit', roles: "cities", title: 'הוסף עיר' }, outlet: 'popup' },           
    { canActivate: [AuthGuard], path: 'cities/:cityID', component: CityMain, data: {  roles: "cities",  title: 'פרטי עיר' } , outlet: 'popup'  },
    { canActivate: [AuthGuard], path: 'cities/:cityID/edit', component: CityMain, data: { mode: 'edit', roles: "cities",  title: 'עריכת פרטי עיר' }, outlet: 'popup' }
]; 

 