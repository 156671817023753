<div fxLayout="row" fxLayoutAlign="space-between stretch">

  <div fxLayout="column" fxFlex="80">
    <div class="row" style="background-color: white;">
      <div style="padding:12px;" class="col-sm-12">
        <div class="col-sm-5">
          <h1 style="margin-top: 0;">
            ספר קופה
          </h1>
        </div>
        <div class="col-sm-7 text-left">
          <button style="background-color: navy;"
            [ngStyle]="{'scale': (paymentsMode == true) ? '1.2' : '1','font-weight': (paymentsMode == true) ? 'bold' : 'initial'}"
            class="btn btn-primary" (click)="showPayments()">
            תשלומים להפקדה</button>
          &nbsp;&nbsp;
          <button style="background-color: navy;"
            [ngStyle]="{'scale': (depositsMode == true) ? '1.2' : '1','font-weight': (depositsMode == true) ? 'bold' : 'initial'}"
            class="btn btn-primary" (click)="showDeposits()">
            רשימת הפקדות</button>
          &nbsp;&nbsp;
        </div>
      </div>
    </div>
    <div class="modal-header">
      <div *ngIf="paymentsMode == true">



        <div class="row">
          <mat-tab-group fxFlexFill [selectedIndex]="activeTabIndex" (selectedTabChange)="itemSelect($event)">
            <mat-tab
              [disabled]='activeTabIndex != 0 && groupedDepositPayments != null && groupedDepositPayments.length >= 1'>
              <ng-template mat-tab-label>
                מזומן
              </ng-template>
              <payments-list *ngIf="activeTabIndex == 0 && selectedPayMethodID == 88"
                (selectedRowsChange)="selectedPaymentsRowsChange_88($event)" [payMethodID]="selectedPayMethodID"
                [waitingToDeposits]="true">
                Loading...</payments-list>
            </mat-tab>
            <mat-tab
              [disabled]='activeTabIndex != 1 && groupedDepositPayments != null && groupedDepositPayments.length >= 1'>
              <ng-template mat-tab-label>
                שיקים
              </ng-template>
              <payments-list *ngIf="activeTabIndex == 1 && selectedPayMethodID == 89"
                (selectedRowsChange)="selectedPaymentsRowsChange_89($event)"
                [waitingToFutureDeposits]="idFuturePayments" [payMethodID]="selectedPayMethodID"
                [waitingToDeposits]="true">
                Loading...</payments-list>
            </mat-tab>
            <mat-tab
              [disabled]='activeTabIndex != 2 && groupedDepositPayments != null && groupedDepositPayments.length >= 1'>
              <ng-template mat-tab-label>
                שיקים עתידיים
              </ng-template>
              <payments-list *ngIf="activeTabIndex == 2 && selectedPayMethodID == 89"
                (selectedRowsChange)="selectedPaymentsRowsChange_89($event)"
                [waitingToFutureDeposits]="idFuturePayments" [payMethodID]="selectedPayMethodID"
                [waitingToDeposits]="true">
                Loading...</payments-list>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div *ngIf="depositsMode == true">
        <div class="row text-center" style="position: fixed; left: 40px;z-index: 9999;">
          <!-- סינונים -->
        </div>


        <div class="row">
          <deposits-list></deposits-list>
        </div>






      </div>
    </div>

  </div>

  <div fxLayout="column" fxFlex="20" style="background-color: rgb(241 241 241);height: 100vh; border-right:solid ;">
    <div class="row" style="background-color: white;">
      <div class="col-sm-12" style="padding: 15px;">
        <div>
          <h2 style="margin-top: 0;">
            יתרות ופעולות
          </h2>
        </div>
      </div>
    </div>
    <div fxLayout="column"   style="padding: 10px; height: 100%;    margin-bottom: 100px;">
       <div   *ngIf="paymentsMode == true">
        <div class="text-center" style="position: absolute; left: 360px;z-index: 9999; display: grid;">
          <button [ngClass]="saveIsDisable" class="btn btn-outline btn-success  dim" type="button"
            style="font-weight: bold" *ngFor="let p of groupedDepositPayments" (click)="openAddOrEditDeposit(p)">
            צור הפקדה חדשה בסכום {{ p.amount | number }} {{ p.currencyIcon }}
          </button>
        </div>
      </div>
      <div fxLayout="column" fxFlex>
        <deposits-waitings-warnings></deposits-waitings-warnings>
      </div>
      <div fxLayout="column" *ngIf="depositBookModelsCash">
        <h3
        style="background-color: WHITE; padding: 36px 10px 10px 10px; font-weight: bold; font-size: xx-large; color: #1ab394; border-top: solid #1ab394;">
        יתרות מזומן </h3>
        <div *ngFor="let item of depositBookModelsCash" class="row">
          <div class="row"
            style="padding-top: 4px; padding-left: 10px; font-weight: bold; font-size: large;  border-bottom: solid #e5e5e5; width: 100%; text-align: end;">
            {{item.amount |number}} {{item.currencyIcon}}
          </div>
        </div>
      </div>

      <div fxLayout="column" *ngIf="depositBookModelsChecks">
        <h3
          style="background-color: WHITE; padding: 36px 10px 10px 10px; font-weight: bold; font-size: xx-large; color: #1ab394; border-top: solid #1ab394;">
          יתרות שיקים</h3>
        <div *ngFor="let item of depositBookModelsChecks" class="row">
          <div class="row"
            style="padding-top: 4px; padding-left: 10px; font-weight: bold; font-size: large; border-bottom: solid #e5e5e5; width: 100%; text-align: end;">
            {{item.amount |number}} {{item.currencyIcon}}
          </div>
        </div>

      </div>

    </div>

  </div>

</div>


<ng-template #loading>
  <h3 style="text-align: center;">
    טוען נתונים...
  </h3>
</ng-template>
