<mat-card fxLayout="column" class="example-card">
    <mat-card-header fxLayout="row">
        <mat-card-title fxLayout="row">
            <h3 style="TEXT-DECORATION: UNDERLINE;">שיוך לקבוצות</h3>
            <span class="example-fill-remaining-space"></span>
            <button (click)="openAddOrEditContactGroup()"
                style="min-width: 10px  !IMPORTANT; min-height: 10px  !IMPORTANT;  line-height: 10px; padding:4px"
                matTooltip="הוסף שיוך לקבוצה" mat-stroked-button>
                <mat-icon style="color: GRAY;">add_box</mat-icon>
            </button>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content style="width: 100%;">

        <div class="example-full-width">
            <div class="example-list" *ngIf="contactGroups" class="example-full-width">
                <div fxLayoutAlign="start center" class="example-box" *ngFor="let cg of contactGroups">
                    <contactGroup-details fxLayout="column" fxFlex (onEditClick)="openAddOrEditContactGroup(cg)"
                        (contactGroupChange)="contactGroupChangeEvent(cg.contactGroupID)" [contactGroup]="cg">
                    </contactGroup-details>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>