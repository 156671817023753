<div class="ibox float-e-margins">
    <div [ngStyle]="{'background-color': backgroundColor, 'color': color}" class="ibox-title">
        <a style="float:left" (click)="close()"><i class="fa fa-times"></i></a>
        <h1>{{ title }}</h1>
        <a (click)="collapse()"><i class="fa fa-chevron-down"></i></a>
        <ng-content select=".panel-title"></ng-content>
        <div>
            <ng-content select=".panel-tools"></ng-content>
        </div>
    </div>
    <div class="ibox-content" [hidden]="!expended">
        <div class="row">
            <ng-content></ng-content>
        </div>
    </div>
</div>