<mat-card *ngIf="payment" fxLayout="column" class="example-card">
    <mat-card-header fxLayout="row">
        <mat-card-title fxLayout="row">
            <h3>פרטי תשלום: [{{ payment.paymentID }}] </h3>
            <span class="example-fill-remaining-space"></span>
            <i matTooltip="תשלום לא פעיל" class="fa fa-window-close" style="float: left;color: red"  *ngIf="payment.isActive != true && payment.statusPayment != 2"></i>

        </mat-card-title>
    </mat-card-header>
    <mat-card-content style="width: 100%;">
        <dl class="dl-horizontal" style="white-space: nowrap;">
            <dt>סכום</dt>
            <dd style="font-weight: bold;" [ngStyle]="{'color': (payment.amount > 0) ? 'inherit' : 'red'}">
                {{ payment.amount | number}} {{ payment.currencyIcon }}</dd>
            <hr>
            <dt *ngIf="payment.paymentCommitments && payment.paymentCommitments.length > 1">עבור
                התחייבויות<br>(הסכמים)
            </dt>
            <dt *ngIf="payment.paymentCommitments && payment.paymentCommitments.length == 1">עבור התחייבות
                (הסכם)
            </dt>
            <dd>
                <div class="example-full-width">
                    <div *ngFor="let pc of payment.paymentCommitments">
                        <commitment-title [pc]="pc"></commitment-title>
                    </div>
                </div>
            </dd>
            <hr>
            <dt *ngIf="payment.paymentsPlanID">תוכנית תשלום</dt>
            <dd *ngIf="payment.paymentsPlanID">
                <a style="text-decoration: underline; margin-left: 10px;"
                    [routerLink]="[ '', {outlets: {popup: [ 'paymentsPlans', payment.paymentsPlanID]}}]">{{
                    payment.paymentsPlanID
                    }}</a>
            </dd>
            <hr *ngIf="payment.paymentsPlanID">
            <dt>אמצעי תשלום</dt>
            <dd>
                <a *ngIf="isLink(payment.payMethodID);else dontLink"
                    [routerLink]="[ '', {outlets: {popup: [ 'payMethods', payment.payMethodID]}}]">
                    {{ payment.payMethodTitle }}</a>
                <ng-template #dontLink><span>{{ payment.payMethodTitle }}</span></ng-template>
            </dd>
            <hr>
            <dt>תאריך תשלום</dt>
            <dd>{{ payment.relevantPaymentDate | date:'dd/MM/yyyy' }}&nbsp;&nbsp;{{ payment.jewishRelevantPaymentDate}}</dd>
            <hr>
            <dt>תאריך ביצוע</dt>
            <dd>{{ payment.doneOn | date:'dd/MM/yyyy (hh:mm)' }}&nbsp;&nbsp;{{ payment.jewishDoneOn }}</dd>
            <hr>
            <dt>סטטוס</dt>
            <dd>
                <a customToolTip [contentTemplate]="template"
                    *ngIf="payment.doneOn && payment.payMethodID && [88, 89, 420, 1310, 3580, 6232, 6990, 42340].indexOf(payment.payMethodID) == -1">
                    <mat-icon style="font-size: 10px; display: inline; color: gray;">
                        signal_cellular_4_bar
                    </mat-icon>
                </a>
                <ng-template #template>

                    <transactions-last-statusses [paymentID]="payment.paymentID">Loading...
                    </transactions-last-statusses>
                </ng-template>
                {{ payment.statusPaymentName }}
            </dd>
            <hr>
            <dt>מזהה פעולה</dt>
            <dd>{{ payment.transactionID }}</dd>
            <hr>
            <dt>קבלה</dt>
            <dd *ngIf="payment.receiptID">
                <a title="לחץ כדי להדפיס" (click)="createAndPrintReceipt(payment.paymentID)">קבלה מס'
                    {{ payment.receiptNumber }},
                    <br>
                    נוצר ב: {{ payment.receiptCreatedDate | date:'dd/MM/yyyy (hh:mm)' }} ע"י
                    {{payment.receiptCreateBy}},
                    <br>
                    הדפסה אחרונה: {{payment.receiptDatePrint | date:'dd/MM/yyyy (hh:mm)' }}, ע"י
                    {{payment.receiptChangeBy}}, עותק מס' {{payment.receiptCopyNumber}}
                </a>
            </dd>
            <hr>
            <dt>תשלום פעיל</dt>
            <dd>
                <i *ngIf="payment.isActive" class="fa fa-check-square"></i>
                <i *ngIf="!payment.isActive" class="fa fa-window-close-o"></i>
            </dd>
            <hr>
            <dt>הערה</dt>
            <dd style="min-width: 180px;">{{ payment.comment }}</dd>
            <hr>
        </dl>
    </mat-card-content>
</mat-card>
