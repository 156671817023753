import { Directive, Input, AfterViewInit, ElementRef } from '@angular/core'; 

@Directive({ selector: '[first-focus]' })
export class FirstFocusDirective implements AfterViewInit {

    @Input('first-focus') firstFocus: boolean = false;

    constructor(private el: ElementRef) {
    }
    ngAfterViewInit(): void {
        if (this.firstFocus) {
            let element = this.el.nativeElement;
            if (element && element.name == "") {
                element = this.el.nativeElement.querySelector("input,select,mat-select");
                if (!element) {
                    element = this.el.nativeElement.closest("input,select,mat-select");
                }
            }
            if (element) {                
                element.focus();
            }
        }
    }
}