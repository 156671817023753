import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';


import { CompensationsData } from './../compensations.data';
import { Compensation } from './compensation';
import { ServicesData } from './../../services/services.data';

@Component({
    templateUrl: './compensation-edit-dlg.tmpl.html'
})

export class CompensationEditDlg implements OnInit {
    public formData: FormGroup;
    constructor(
        public matDialogRef: MatDialogRef<CompensationEditDlg>,
        @Inject(MAT_DIALOG_DATA) public data: Compensation, //contactID       
        private formBuilder: FormBuilder,
        public servicesData: ServicesData,
        public compensationsData: CompensationsData,
        public activatedRoute: ActivatedRoute
    ) {

    }

   // compensation: Compensation;
    id: number;
    contactId: number;


    onNoClick(): void {
        this.matDialogRef.close();
    }


    delete() {
        this.compensationsData.delete(this.data.compensationID)
            .subscribe((c: any) => {
                return true;
                //this.matDialogRef.close();
            })
    }

    public isValid(): boolean {
        return (this.formData && this.formData.valid);
    }

    public load(): void {
        if (this.id > 0) {
            this.compensationsData.getEdit(this.id || 0)
                .subscribe((p:any) => {
                    this.data = p;
                    this.fillForm(this.data);
                });
        } else {
            this.fillForm(this.data);
            // this.compensationsData.getAdd(this.contactId)
            // .subscribe((p:any) => {
            //         this.data = p;
            //         this.fillForm(this.data);
            //     });
        }
    }

    private fillForm(p: Compensation): void {
        this.formData.patchValue(p);
    }

    private initForm() {
        this.formData = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            compensationID: [this.data.compensationID || 0, Validators.required],
            contactID: [this.data.contactID, Validators.required],
            compensationDate: [this.data.compensationDate, Validators.required], //תאריך
            description: [this.data.description, Validators.required], //תיאור תגמול
            subject: [this.data.subject, Validators.required], //הזדמנות
        });
    }

    ngOnInit() {
        // this.activatedRoute.params.forEach((params: Params) => {
        //     this.id = +params['compensationID'] || 0;
        //     this.contactId = +params['contactID'];
        // });
        this.id = this.data.compensationID || 0;
        this.initForm();
        this.load();
    }
}



