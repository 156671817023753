import { ContactGroupsData } from './contactGroups.data';
import { ContactsData } from './../contacts.data';
import { AlertService } from './../../components/alert/alert.svc';
import { ContactGroupEditDlg } from './contactGroup/contactGroup-edit-dlg.cmp';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { ContactGroup } from './contactGroup/contactGroup';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'contactGroups-list',
  templateUrl: './contactGroups-list.tmpl.html'
})
export class ContactGroupsList implements OnInit {


  constructor(
    private contactGroupsData: ContactGroupsData,
    private formBuilder: FormBuilder,
    private alertSvc: AlertService,
    public dialog: MatDialog
  ) {
  }

  contactGroups: any[];
  searchContactGroupsListForm: FormGroup;
  tempNewEmail;
  tempNewContactGroup;

  viwHistory = false;
  @Input('contactID') public contactID: number;
  //@Input('contactGroups') public contactGroups: ContactGroup[];


  contactGroupChangeEvent() {
    this.getContactGroups();
  }

  onEditClick(obj: ContactGroup) {
    this.openAddOrEditContactGroup(obj);
  }

  public getContactGroups(): void {
    this.searchContactGroupsListForm.controls['contactID'].setValue(this.contactID);
    this.contactGroupsData!.getContactGroups(this.contactID)
      .subscribe((x: any[]) => {
        this.contactGroups = x;
      }
      );
  }

  openAddOrEditContactGroup(contactGroup?: ContactGroup): void {
    setTimeout(() => {
      if (!contactGroup) {
        contactGroup = new ContactGroup();
        contactGroup.contactID = this.contactID;
      }
      const dialogRef = this.dialog.open(ContactGroupEditDlg, {
        data: { contactId: contactGroup.contactID, id: contactGroup.contactGroupID, contactGroup: contactGroup }
      });


      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          if (result.controls['deleted'].value == true) {
            this.deleteContactGroup(result.controls['contactGroupID'].value);
          } else {
            this.saveContactGroup(result);
          }
        }
      });
    }, 200);
  }

  deleteContactGroup(contactGroupID: number) {
    let cnfrm = confirm("האם אתה למחוק בכל זאת?,\nלא ניתן לבטל את הפעולה");
    if (cnfrm) {
      this.contactGroupsData.delete(contactGroupID)
        .subscribe((x: any) => {
          this.getContactGroups();
          return true;
        })
    }
  }

  private saveContactGroup(contactGroupForm: FormGroup) {
    this.contactGroupsData.update(contactGroupForm.value)
      .subscribe((pm: any) => {
        this.getContactGroups();
        return true;
      })
  }


  private initForm() {
    this.searchContactGroupsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      contactID: [this.contactID, Validators.required],
      selectedRows: [],
      orders: this.formBuilder.array([]),
      //isActive: [true, Validators.required],
    });

  }

  ngOnInit(): void {
    this.initForm();
    this.getContactGroups();
  }




}
