<form  [formGroup]="ivrAssignmentUserTaskForm" novalidate>
    <div class="modal-header"  *ngIf="ivrAssignmentUserTask">
        <div fxFlex="100">
            <h3 fxLayout="row" style="color: blue;">משימה [{{ (id > 0 ?
                id : 'חדשה') }}] </h3>
            <div fxLayout="row">
                <div fxLayout="row">
                    <div *ngIf="ivrAssignmentUserTask.ivrAssignmentDetailID > 0" fxFlex style="margin-top: 0;">
                        <h2 *ngIf=" !ivrAssignmentUserTask.contactTitle">
                            <small>מתקשר זוהה ע"י המערכת<br>כ - </small>{{
                            ivrAssignmentUserTask.identifiedContactTitle
                            ?
                            ivrAssignmentUserTask.identifiedContactTitle
                            : 'לא ידוע' }}
                        </h2>
                        <h2 *ngIf="ivrAssignmentUserTask.contactTitle" fxFlex style="margin-top: 0;">
                            {{ ivrAssignmentUserTask.contactTitle }}
                        </h2>
                    </div>
                    <div *ngIf="!ivrAssignmentUserTask.ivrAssignmentDetailID" fxFlex style="margin-top: 0;">
                        <h2>
                            נפתח ע"י: {{ ivrAssignmentUserTask.createBy }}
                        </h2>
                    </div>
                    <div style="position: absolute; left: 20px;">
                        <p>נפתח בתאריך: {{ ivrAssignmentUserTask.createDate | date: 'dd/MM/yyyy' }} <br>
                            {{ ivrAssignmentUserTask.jewishCreateDate }} {{ ivrAssignmentUserTask.createDate |
                            date:'shortTime' }}</p>
                        <p>
                        <p *ngIf="ivrAssignmentUserTask.doneByUserName">טופל ע"י: {{
                            ivrAssignmentUserTask.doneByUserName }}</p>
                        <p *ngIf="ivrAssignmentUserTask.doneDate">תאריך טיפול: {{ ivrAssignmentUserTask.doneDate
                            |
                            date: 'dd/MM/yyyy' }}<br>
                            {{ ivrAssignmentUserTask.jewishDoneDate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body" style="resize: both; overflow: auto; min-width: 850px; width: fit-content;" fxFlexFill>
      <div fxLayout="row" fxFlexFill  fxLayoutGap="15px"  fxLayout="row" fxLayout.lt-md="column"  *ngIf="ivrAssignmentUserTask">

        <ivr-assignment-user-task-edit [ivrAssignmentUserTaskForm]="ivrAssignmentUserTaskForm"
            [ivrAssignmentUserTaskID]="id" [model]="ivrAssignmentUserTask">
        </ivr-assignment-user-task-edit>
        <div class="row" *ngIf="ivrAssignmentUserTask.ivrAssignmentDetailID">
            <mat-card class="example-card" style="white-space: nowrap;">
                <mat-card-header>
                    <mat-card-title>
                        <h3>פרטי שיחה: [{{ ivrAssignmentUserTask.ivrAssignmentDetailID }}]</h3>
                        <span class="example-fill-remaining-space"></span>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="column">
                    <div class="row">
                        <dl class="dl-horizontal">
                            <dt>מספר מזוהה</dt>
                            <dd>{{ ivrAssignmentUserTask.ivrAssignmentDetail.phoneNumber | phone}}</dd>
                            <hr>
                            <dt>שם מתקשר</dt>
                            <dd *ngIf="!contactEdit" style="margin-right: 157px !important;">
                                <i style="color: #c8c8c8" (click)="contactEdit = !contactEdit" class="fa fa-pencil"></i>
                                &nbsp;
                                <span>{{ (ivrAssignmentUserTask.contactTitle ?
                                    ivrAssignmentUserTask.contactTitle :
                                    ivrAssignmentUserTask.identifiedContactTitle) }}</span>
                            </dd>
                            <dd *ngIf="contactEdit" style="margin-right: 110px !important; display:flex;">
                                <i style="color: #c8c8c8" (click)="contactEdit = !contactEdit" class="fa fa-check"></i>
                                &nbsp;
                                <auto-complete [withLabel]="false" *ngIf="contactEdit" apply-enter
                                    [controllerName]="'Contacts'" [formGroupModel]="ivrAssignmentUserTaskForm"
                                    [placeholder]="'שם מתקשר:'"
                                    [idControl]="ivrAssignmentUserTaskForm.controls.contactID"
                                    [nameControl]="ivrAssignmentUserTaskForm.controls.contactTitle">
                                </auto-complete>
                            </dd>
                            <hr>
                        </dl>
                    </div>
                    <div class="row">

                        <audio #audioFile id="audioFile" controls></audio>
                    </div>

                </mat-card-content>
            </mat-card>
        </div>
    </div>

    </div>
    <footer-edit-buttons  *ngIf="ivrAssignmentUserTask" [navigateAfterSaving]="'ivrAssignmentUserTasks'" [dataProvider]="ivrAssignmentUserTasksData"
        [router]="router" [id]="id" [disabledDeleteButton]="false" [displaySaveButton]="false"
        [modelFormGroup]="ivrAssignmentUserTaskForm">
        <ng-template #customHtmlButtons>
            <button type="button" apply-enter [disabled]="!ivrAssignmentUserTaskForm.valid"
                (click)="saveTask(ivrAssignmentUserTaskForm)" class="btn btn-primary navbar-btn">
                <i class="fa fa-save"></i> שמור
            </button>
        </ng-template>
    </footer-edit-buttons>
</form>
