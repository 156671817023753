<div class="input-group md-3">
    <label>{{placeHolder}}:</label>
    <div style="white-space: nowrap;">
        <select class="form-control" style="min-width: 180px;" [formControl]="addresIdControl"
            placeholder="placeHolder">
            <option [value]="">...</option>
            <option *ngFor="let adr of addresses" [value]="adr.addressID">
                <span style="float: right"> {{adr.title}}</span>
                <address-add-action style="padding: 10px; float: left" [address]="adr" [contactID]="contactID"
                    (addressChange)="addressChangeEvent($event)"></address-add-action>
            </option>
        </select>
        <address-add-action [contactID]="contactID" (addressChange)="addressAddEvent($event)"></address-add-action>
    </div>
</div>
