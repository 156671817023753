import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable  } from '@angular/core';

@Injectable()

export class HoldingsData {



  private url = 'api/holdings';

  constructor(
    private toraxHttp: ToraxHttp
  ) { }

  exportExcel(searchHoldingsListForm: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, searchHoldingsListForm);
  }
  downloadviwHoldingsSeriePrintExls(searchHoldingsListForm: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadviwHoldingsSeriePrintExls`, searchHoldingsListForm);
  }



  getPivotHoldingsExls(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/getPivotHoldingsExls`, baseQuery);
  }

  getPivotHoldingsHtml(baseQuery: any) {
    return this.toraxHttp.postWithResponce(`${this.url}/getPivotHoldingsHtml`, baseQuery);
  }

  downloadHoldingsPivotReportExls(year: number, responsibleID?: number, contactID?: number) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadHoldingsPivotReportExls/${year}?responsibleID=${responsibleID}&contactID=${contactID}`, {});
  }

  cancelHoldingsPrint(holdingsSeriePrintID: number) {
    return this.toraxHttp.getWithResponce(`${this.url}/cancelHoldingsPrint/${holdingsSeriePrintID}`);
  }

  printHoldingsHtml(holdingsPrintDetailsMdl: any) {
    return this.toraxHttp.postWithResponce(`${this.url}/printHoldingsHtml`, holdingsPrintDetailsMdl);
  }

  printExludedHoldingsHtml(holdingsPrintDetailsMdl: any) {
    return this.toraxHttp.postWithResponce(`${this.url}/printExludedHoldingsHtml`, holdingsPrintDetailsMdl);
  }


  getCompleteList(searchHoldingsListForm: any) {
    return this.toraxHttp.post(`${this.url}/completeHoldings`, searchHoldingsListForm);
  }

  getList(searchHoldingsListForm: any) {
    return this.toraxHttp.post(`${this.url}/List`, searchHoldingsListForm);
  }

  getSmallList(searchHoldingsListForm: any) {
    return this.toraxHttp.post(`${this.url}/smallList`, searchHoldingsListForm);
  }

  getHoldingsPaymentToEdit(paymentCommitmentID: number) {
    return this.toraxHttp.get(`${this.url}/getHoldingsPaymentToEdit/${paymentCommitmentID}`);
  }

  saveHoldingsPayment(holdingsPaymentForm: any) {
    return this.toraxHttp.post(`${this.url}/saveHoldingsPayment`, holdingsPaymentForm);
  }


  getHoldingsPrintedGroup(searchPrintsListForm: any) {
    return this.toraxHttp.post(`${this.url}/getHoldingsPrintedGroup`, searchPrintsListForm);
  }

}

