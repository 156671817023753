import { AlertService } from './../components/alert/alert.svc';
import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private alert: AlertService
    ) {

      

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by role  - כאשר יש לניתוב תפקידים
            if (route.data && route.data.roles) {
                const rls = route.data.roles.split(',').map((x) => x.toLowerCase());

                if (currentUser.roles.indexOf("SystemAdministrator") > -1)
                    return true;

                const uRls = currentUser.roles.map((x) => x.toLowerCase());

                for (let i = 0; i < rls.length; i++) {
                    if (uRls.indexOf(rls[i]) >= 0) {
                        // authorised so return true
                        return true;
                    }
                }
                this.alert.info('הגישה נכשלה');
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }
            // כאשר אין לניתוב תפקידים מאופשר לכולם
            // authorised so return true
            return true;
        }

        // not logged in so redirect to logout page with the return url
        //   this.router.navigate(['/logout'], { queryParams: { returnUrl: state.url } });
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
