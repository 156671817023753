<nav class="text-center" aria-label="Page navigation" style="transform : scale(0.5);">
    <span style="float: right;padding: 25px;"> {{ ((currentPage -1) * (pageRows||30)) + 1 }}-{{ (((currentPage -1) * (pageRows||30)) + (pageRows||30)) < rowsCount ? ((currentPage -1) * (pageRows||30)) + (pageRows||30) : rowsCount }} מתוך {{ rowsCount }}</span>
    <ul class="pagination">
        <li [ngClass]="{disabled: currentPage==1}">
            <a (click)="previousPage()" aria-label="Previous">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
        <li [ngClass]="{active: currentPage==p}" *ngFor="let p of pages()">
            <a (click)="changePage(p)">{{p}}</a>
        </li>
        <li [ngClass]="{disabled: currentPage==pagesCount}">
            <a (click)="nextPage()" aria-label="Next">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>
    </ul>
    <span style="float: left;padding: 25px;">עמוד {{ currentPage }} מתוך {{ pagesCount }} </span>

</nav>
