import { ToraxHttp } from '../../utils/custom-http.svc';
import { Injectable } from '@angular/core';
import { LotteryChartModel } from '../dashboard-lottery/dashboard-lottery-chart-model';

@Injectable()
export class DashboardTrackingAccountsDataService {
    private url = 'api/dashboardTrackingAccounts';
    constructor(
        private toraxHttp: ToraxHttp,
        private dashboardTrackingAccountsDataService: DashboardTrackingAccountsDataService,
    ) { }

    getDatesLists(weeksAfterOrBefor){
        return this.toraxHttp.get(`${this.url}/getDatesLists/${weeksAfterOrBefor}`);
    }
    getPaymentsTrackings(date){
        return this.toraxHttp.get(`${this.url}/getTrackingAccounts/${date.year}/${date.month}/${date.dayInMonth}`);
    }

    getExpectPaymentsInMonths(accountLocation: number) {
      return this.toraxHttp.get(`${this.url}/getExpectPaymentsInMonths/${accountLocation}`);
  }




}
