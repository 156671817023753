import { ContactReportModelDlg } from '../contactReports/contact-report-model-dlg.cmp';
import { ContactsData } from '../contacts.data';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';
import { Contact } from './contact';
import { saveAs } from 'file-saver-es';


@Component({
    selector: 'contact-report-action',
    templateUrl: './contact-report-action.tmpl.html'
})


export class ContactReportAction  {
    constructor(
        private contactsData: ContactsData,
        public dialog: MatDialog,
    ) {
    }


    @Input('contact') public contact: Contact;
    @Input('selectedCommitmentsIDs') public selectedCommitmentsIDs: string;


    ;

    public getContactShortInformation(): void {
        // let doDownload = true;
        const dialogRef = this.dialog.open(ContactReportModelDlg, {
            data: {
                contactID: this.contact.contactID,
                contactName: `${this.contact.firstName} ${this.contact.lastName}`,
                selectedCommitmentsIDs: this.selectedCommitmentsIDs
            }
        });


        dialogRef.afterClosed().subscribe(result => {
            if (result && result.valid) {
                this.dowloand(result.value);
            }
        });

    }
    dowloand(result: any) {
        this.contactsData.getContactShortInformation(result).subscribe((blob:any) => {
            var blb = new Blob([blob], {type: "application/pdf;charset=utf-8"});
            saveAs(blb, `דוח ${this.contact.perfix} ${this.contact.firstName} ${this.contact.lastName} ${this.contact.suffix}.pdf`);
        });
    }



}
