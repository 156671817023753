import { ContactAppointmentsData } from './../contactAppointments.data';
import { ContactsData } from './../../contacts.data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ContactAppointment, AppointmentUser as AppointmentUserVM  } from './contactAppointment';
import { ServicesData } from 'src/app/services/services.data';

@Component({
  selector: 'contactAppointment-edit-dlg',
  templateUrl: './contactAppointment-edit-dlg.tmpl.html'
})

export class ContactAppointmentEditDlg implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ContactAppointmentEditDlg>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contactAppointmentsData: ContactAppointmentsData,
    private formBuilder: FormBuilder,
    private servicesData: ServicesData
  ) {

  }
  @Input('id') public id: number;
  communicationType;
  contactAppointment: ContactAppointment;
  contactId: number;

  contactAppointmentForm: FormGroup;

  public load(): void {
    if (this.id > 0) {
      this.contactAppointmentsData.get(this.id || 0)
        .subscribe((p: any) => {
          this.contactAppointment = p;
          this.fillForm(p);
        });
    }
    else if (this.contactAppointment.contactID) {
      this.contactAppointmentsData.getAdd(this.contactAppointment.contactID)
        .subscribe((p: any) => {
          this.contactAppointment = p;
          this.fillForm(this.contactAppointment);
        });
    }
  }

  private fillForm(p: ContactAppointment): void {
    this.contactAppointmentForm.patchValue(p);
    //this.fillAppointmentUsers(p.appointmentUsers);
  }

  private initForm() {
    this.contactAppointmentForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      appointmentID: [, Validators.required],
      contactID: [this.contactId, Validators.required],
      subject: [, Validators.required],
      description: [],
      startDateTime: [new Date(), Validators.required],
      endDateTime: [],
      gxUsersIds: [],//TODO:
      allDay: [false, Validators.required],
      location: [],
      cancelDate: [],
      status: [],
      summary: [],
      colorId: [],
      processID: [],
      deleted: [false, Validators.required]

    });
  }


//   private fillAppointmentUsers(appointmentUsers: AppointmentUserVM[]) {
//     const appointmentUsersArray = <FormArray>this.contactAppointmentForm.controls['appointmentUsers'];
//     appointmentUsers.forEach((cg, i) => {
//         let cgFrm = this.initAppointmentUser();
//         cgFrm.patchValue(cg);
//         appointmentUsersArray.push(cgFrm);
//     });
//     //this.commitmentForm.controls['payments'].setValue(paymentsArray);
// }


// initAppointmentUser() {
//     let fb = this.formBuilder.group({
//         createBy: [], createDate: [], changeBy: [], changeDate: [],
//         appointmentUserID: [0, Validators.required],
//         appointmentID: [this.id || 0, Validators.required],
//         gxUserName: ['', Validators.required],
//         isDeleted: [false, Validators.required],
//     });
//     return fb;
// }

// addAppointmentUser() {
//     let cg = new AppointmentUserVM();
//     cg.appointmentID = this.id;
//     let cgFrm = this.initAppointmentUser()
//     const appointmentUsersArray = <FormArray>this.contactAppointmentForm.controls['appointmentUsers'];
//     appointmentUsersArray.push(cgFrm);
//     cgFrm.patchValue(cg);
// }

// get fcAppointmentUsers(): FormGroup {
//     return this.contactAppointmentForm.controls['appointmentUsers'] as FormGroup;
// }

// deleteItem(ix: number) {
//     let cgFrm = <FormGroup>(<FormArray>this.contactAppointmentForm.controls['appointmentUsers']).controls[ix];
//     Object.keys(cgFrm.controls)
//         .filter(key => key != 'isDeleted' && key != 'appointmentUserID')
//         .forEach(key => {
//             cgFrm.controls[key].disable();
//         });
//     cgFrm.controls['isDeleted'].enable();
//     cgFrm.controls['isDeleted'].setValue(true);
// }

// unDeleteItem(ix: number) {
//     let cgFrm = <FormGroup>(<FormArray>this.contactAppointmentForm.controls['appointmentUsers']).controls[ix];

//     Object.keys(cgFrm.controls)
//         .filter(key => key != 'isDeleted' && key != 'appointmentUserID')
//         .forEach(key => {
//             cgFrm.controls[key].enable();
//         });
//     cgFrm.controls['isDeleted'].setValue(false);
// }

delete() {
  this.contactAppointmentForm.controls['deleted'].setValue(true);
}

  ngOnInit() {
    this.id = this.data.contactAppointment.appointmentID;
    this.contactId = this.data.contactAppointment.contactID;
    this.contactAppointment = this.data.contactAppointment;
    this.initForm();
    this.load();
  }
}



