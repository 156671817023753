<div *ngIf="pc" customToolTip [contentTemplate]="template" style="cursor: pointer; padding: 2px 0"
    fxLayoutAlign="start start" class="example-box" fxLayout="row">
    <mat-icon style="margin: auto;">keyboard_arrow_left</mat-icon>
    <div class="example-full-width">
        <a [routerLink]="[ '', {outlets: {popup: [ 'commitments', pc.commitmentID]}}]"
            style="background: #f3f5f6; margin-top: 4px; overflow: hidden; max-width: 250px; white-space: pre-wrap;">
            <!-- {{ pc.commitmentTitle }} -->
            {{pc.donationTypeName}} {{pc.subjectName}}
        </a>
    </div>
</div>
<ng-template #template>
    <mat-card style="white-space: nowrap">
        <mat-card-content>
            {{ pc.commitmentTitle }}
            <br>
            {{ pc.designation }} <small>{{ pc.designationDetails }}</small>
        </mat-card-content>
    </mat-card>
</ng-template>