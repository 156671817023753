<fieldset class="fieldset" *ngIf="tishInvitedForm.controls.tishInviteds">
  <legend> {{(tishInvitedForm.controls.tishInviteds.controls.length > 1 ? 'פרטי ילדים' : 'פרטי ילד' )}}
  </legend>
  <div [formGroup]="tishInvitedForm" style="margin: 0 auto;">
    <table class="table table-border">
      <thead class="text-nowrap">
        <th>סוג קשר</th>
        <th>תואר</th>
        <th>שם פרטי</th>
        <th>שם משפחה</th>
        <th>סיומת</th>
        <th>שיבוץ?</th>
        <th>אישור השת'</th>
        <th>יום כיפור?</th>
        <th>...</th>

      </thead>
      <tbody formArrayName="tishInviteds">
        <tr [formGroupName]="i" *ngFor="let group of getFormControls.controls; let i=index">
          <td>
            <span *ngIf="getFormControls.controls[i].controls.isChild.value == true">ילד</span>
            <span *ngIf="getFormControls.controls[i].controls.isChild.value == false"></span>
          </td>
          <td *ngIf="getFormControls.controls[i].controls.isChild.value == false" colspan="4">
            <auto-complete [withLabel]="false" apply-enter [controllerName]="'Contacts'" [placeholder]="'איש'"
              [formGroupModel]="getFormControls.controls[i]"
              [idControl]="getFormControls.controls[i].controls.contactID"
              [nameControl]="getFormControls.controls[i].controls.tishInvitedName">
            </auto-complete>
          </td>
          <!-- <td style="display: none;">
            <input type="hidden" formControlName="contactID">
          </td> -->

          <td *ngIf="getFormControls.controls[i].controls.isChild.value == true">
            <input type="text" class="form-control narrow" formControlName="specialInvitedPerfix" />
          </td>
          <td *ngIf="getFormControls.controls[i].controls.isChild.value == true">
            <input type="text" class="form-control" formControlName="specialInvitedFirstName" />
          </td>

          <td *ngIf="getFormControls.controls[i].controls.isChild.value == true">
            <input [disabled]="getFormControls.controls[i].controls.tishInvitedID > 0" type="text" class="form-control"
              formControlName="specialInvitedLastName" />
          </td>

          <td *ngIf="getFormControls.controls[i].controls.isChild.value == true">
            <input [disabled]="getFormControls.controls[i].controls.tishInvitedID > 0" type="text" class="form-control narrow"
              formControlName="specialInvitedSuffix" />
          </td>
          <td>
            <mat-select apply-enter class="form-control narrow" placeholder="מוזמן לשיבוץ?" formControlName="isPlace">
              <mat-option [value]="false">לא לשיבוץ</mat-option>
              <mat-option [value]="true">לשיבוץ</mat-option>
            </mat-select>
          </td>

          <td>
            <mat-select apply-enter class="form-control narrow" placeholder="לא ידוע" formControlName="invitedConfirmed">
              <mat-option [value]="null">לא ידוע</mat-option>
              <mat-option [value]="true">משתתף</mat-option>
              <mat-option [value]="false">לא משתתף</mat-option>
            </mat-select>
          </td>
          <td>
            <mat-select apply-enter class="form-control narrow" placeholder="יום כיפור?" formControlName="yomKipur">
              <mat-option [value]="true">כן</mat-option>
              <mat-option [value]="false">לא</mat-option>
            </mat-select>
            <!-- <auto-complete-input [leftToRight]="false" apply-enter [withLabel]="false" [placeholder]="'סטטוס'"
              [controllerName]="'InvitedStatuses'" [stringValueControl]="group.controls.invitedStatus"
              [formGroupModel]="group">
            </auto-complete-input> -->
          </td>
          <td>
            <a *ngIf="!getFormControls.controls[i].controls.isDeleted.value" (click)="deleteItem(i)"><i
                class="fa fa-trash-o"></i>&nbsp;מחיקה</a>
            <a *ngIf=" getFormControls.controls[i].controls.isDeleted.value" (click)="unDeleteItem(i)"><i
                class="fa fa-undo"></i>&nbsp;בטל</a>

            <!-- <mat-icon class="delete" (click)="deleteRow(i)">delete_forever</mat-icon> -->
            <!-- <mat-icon class="done" (click)="doneRow(group)">done</mat-icon> -->
          </td>
        </tr>

      </tbody>
    </table>
    <div class="action-container" style="display: inline;">
      <button type="button" style="color: blue;" matTooltip="הוסף ילד" apply-enter color="primary"
        (click)="addChildRow()">
        <i class="fa fa-plus"></i>&nbsp;הוסף ילד</button>
      <button type="button" style="color: green;" matTooltip="הוסף אורח מרשימות אנ''ש" apply-enter
        color="primary" (click)="addContactRow()"><i class="fa fa-plus"></i>&nbsp;
        הוסף אורח מרשימות אנ''ש</button>
    </div>
  </div>
</fieldset>
