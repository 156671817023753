import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({ selector: '[apply-enter]' })
export class ApplyEnterDirective {

  constructor(private el: ElementRef) {
      this.el.nativeElement.setAttribute('enter-tab', 1);
  } 
 
}

@Directive({
  selector: '[enter-tab]',
})
export class EnterTabDirective implements AfterViewInit { 
  constructor() {
  } 
  
  ngAfterViewInit(): void { 
  } 
}
