import { ToraxHttp } from './../utils/custom-http.svc';
import { Injectable, EventEmitter } from '@angular/core';

import { Payment } from './payment/payment';
import { ObjectsListResult } from '../services/objects-list-results';


@Injectable()

export class PaymentsData {


  public url = 'api/payments';

  constructor(
    private toraxHttp: ToraxHttp
  ) { }


  paymentsYears(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/paymentsYears`, baseQuery.value);
  }
  delete(id: number) {
    return this.toraxHttp.getDeleteResponse(`${this.url}/delete/${id}`);
  }

  getList(baseQuery: any) {
    return this.toraxHttp.post(`${this.url}/List`, baseQuery);
  }

  exportExcel(baseQuery: any) {
    return this.toraxHttp.postAndDownload(`${this.url}/downloadExls`, baseQuery);
  }

  get(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}`);
  }

  refreshPaymentCurrenciesRate(paymentID: number) {
    return this.toraxHttp.get(`${this.url}/refreshPaymentCurrenciesRate/${paymentID}`);
  }

  getCommitmentFundraisingInfo(commitmentID: number) {
    return this.toraxHttp.get(`${this.url}/getCommitmentFundraisingInfo/${commitmentID}`);
  }

  createAndPrintReceipt(paymentID: number, doDownload: boolean = false, paymentGroupID: number = null) {
    if (doDownload)
      return this.toraxHttp.getWithResponceToDownload(`${this.url}/createAndPrintReceipt?paymentID=${paymentID}&doDownload=${doDownload}&groupID=${paymentGroupID}`);
    else
      return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintReceipt?paymentID=${paymentID}&doDownload=${doDownload}&groupID=${paymentGroupID}`);
  }
  createAndPrintReceiptWithTemplate(paymentID: number, templateID: number) {
    return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintReceiptWithTemplate?paymentID=${paymentID}&letterTemplateID=${templateID}`);
  }

  createAndPrintPaymentLetter(paymentID: number) {

      return this.toraxHttp.getWithResponce(`${this.url}/createAndPrintPaymentLetter?paymentID=${paymentID}`);
  }


  cancellationReceipt(cancellationReceiptModel: any) {
    return this.toraxHttp.update(`${this.url}/cancelAndPrintCancellationReceipt`, cancellationReceiptModel);
  }

  // getPaymentCommitmentsInfo(paymentCommitmentsIdsList: number[]) {
  //     return this.toraxHttp.post(`${this.url}/getPaymentCommitmentsInfo`, paymentCommitmentsIdsList);
  // }

  getEdit(id: number) {
    return this.toraxHttp.get(`${this.url}/${id || 0}/edit`);
  }

  update(payment: Payment) {
    return this.toraxHttp.update(this.url, payment);
  }

  getAddVouchersOrgPayment(organizationID: number) {
    return this.toraxHttp.getWithResponce(`${this.url}/addVouchersOrgPayment/${organizationID || 0}`);
  }

  getAddTransferPayment(contactID: number, transferTaskID: number) {
    return this.toraxHttp.getWithResponce(`${this.url}/addTransferPayment/${contactID}/${transferTaskID}`);
  }

  getAdd(commitmentID: number = null, contactID: number = null, paymentsPlanID: number = null) {
    return this.toraxHttp.getWithResponce(`${this.url}/add/${commitmentID || null}/${contactID || null}/${paymentsPlanID || null}`);
  }

  chargePayment(payment: Payment) {
    return this.toraxHttp.update(`${this.url}/chargePayment`, payment);
  }

  refundPayment(payment: Payment) {
    return this.toraxHttp.update(`${this.url}/refundPayment`, payment);
  }

  recIn(id: number) {
    return this.toraxHttp.recIn(this.url, id);
  }

  public updatedEvent = new EventEmitter<number>();


  private onUpdated(id: number): void {
    if (this.updatedEvent) {
      this.updatedEvent.emit(id);
    }
  }
}
