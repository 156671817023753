import { MaterialModule } from './../material.module';

import { ModelInfoModule } from './../components/model-info/model-info.mdl';
import { PhoneEditDlg } from './phone/phone-edit-dlg.cmp';

import { PhonesList } from './phones-list.cmp';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { PaginationModule } from '../components/pagination/pagination.mdl';
import { PhonesData } from './phones.data';
//import { TextMaskModule } from 'angular2-text-mask';
import { PhonePipeMdl } from './../components/pipes/phone-pipe';
import { PhoneDetails } from './phone/phone-details.cmp';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PageHeadingModule,
        PanelBoxModule,
        PaginationModule,
        FooterEditButtonsMdl,
        PhonePipeMdl,
        ModelInfoModule,
        MaterialModule
    ],
    declarations: [
        PhonesList,
        PhoneDetails,
        PhoneEditDlg
    ],
    exports: [
        PhonesList
    ],
    providers: [
        PhonesData
    ],
    entryComponents: [
        PhoneEditDlg,
    ]
})
export class PhonesMdl { }



