
import { PaymentsData } from './payments.data';


import { Component, OnInit, AfterViewInit, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { ToraxHttp } from '../utils/custom-http.svc';


@Component({
	selector: 'payments-short-list',
	templateUrl: 'payments-short-list.tmpl.html'
})

export class PaymentsShortList implements OnInit  {
	constructor(
		private paymentsData: PaymentsData,
		private formBuilder: FormBuilder,
		private toraxHttp: ToraxHttp
	) {
	}
	resultsLength = 0;
	isRateLimitReached = false;
	@Input('paymentGroupID') public paymentGroupID?: number | null = null;



	exludePayMethodsIDs = [88, 89, 420, 1310, 3580, 6232, 6990, 42340];
	isLink(pmId) {
		return this.exludePayMethodsIDs.indexOf(pmId) < 0;
	}


	searchPaymentsListForm: FormGroup;
	payments = null;
	pageRows = 20;
	rowsCount;
	page;

	getPayments() {
		if(!this.payments){
			if (this.paymentGroupID && this.paymentGroupID > 0) {
				this.searchPaymentsListForm.controls['paymentGroupID'].setValue(this.paymentGroupID);
			}

			return this.paymentsData!.getList(this.searchPaymentsListForm.value)
				.subscribe((data: any) => {
					//data = data;
					this.payments = data.list;
					this.isRateLimitReached = false;
				});
		}

	}

	private initForm() {
		this.searchPaymentsListForm = this.formBuilder.group({
			page: [1],
			pageRows: [30],
			query: [],
			contactID: [],
			paymentGroupID: [null],
		});
	}


	ngOnInit() {
		this.initForm();
		this.getPayments();
	}


}

