import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationComponent } from './pagination.cmp';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule,
        RouterModule],
    exports: [PaginationComponent],
    declarations: [PaginationComponent]
    //providers: [],
})
export class PaginationModule { }
