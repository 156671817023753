 
import { Component, OnInit, Inject, Input } from '@angular/core';
import { RegistrationEvent } from './registrationEvent';


@Component({
    selector: 'registrationEvent-short-details',
    templateUrl: './registrationEvent-short-details.tmpl.html'
})
export class RegistrationEventShortDetails    { 
    @Input() registrationEvent: RegistrationEvent;
   
   

}

