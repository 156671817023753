import { ContactEventsData } from './../contactEvents.data';
import { ContactsData } from './../../contacts.data';
import { AlertService } from './../../../services/alert.service';
import { ContactEventEditDlg } from './contactEvent-edit-dlg.cmp';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ContactEvent } from './contactEvent';
import { Observable } from 'rxjs';

@Component({
    selector: 'contactEvent-details',
    templateUrl: './contactEvent-details.tmpl.html'
})

export class ContactEventDetails {

    constructor(
        private contactEventsData: ContactEventsData,
        public dialog: MatDialog
    ) {

    }
    @Output() contactEventChange = new EventEmitter<number>();
    @Output() onEditClick = new EventEmitter<number>();
    @Input('contactEvent') public contactEvent: ContactEvent;

    editContactEvent(cg) {
        this.onEditClick.emit(cg);
    }

    // private openAddOrEditContactEvent(contactEventID: number = 0): void {      
    //     const dialogRef = this.dialog.open(ContactEventEditDlg, {
    //         data: { contactId: this.contactEvent.contactID, id: this.contactEvent.contactEventID, contactEvent: this.contactEvent }
    //     });


    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result && result.valid) {
    //             this.saveContactEvent(result);
    //         }
    //     });
    // }

    // private saveContactEvent(contactEventForm: FormGroup) {
    //     this.contactsData.update(contactEventForm.value)
    //         .subscribe((pm: any) => {
    //             return true;
    //         })
    // }

}



